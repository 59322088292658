import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  timelineItem: () => ({
    padding: '8px 20px',
    backgroundColor: palette.grayscale[200],
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    borderLeft: `4px solid ${palette.grayscale[400]}`,
  }),
}))

export default styles
