export function SetIsProPlan(isProPlan) {
  return {
    type: 'SET_IS_PRO_PLAN',
    isProPlan,
  }
}

export function SetPlanInfos(planInfos) {
  return {
    type: 'SET_PLAN_INFOS',
    planInfos,
  }
}

export function setAvatar(avatar) {
  return {
    type: 'SET_AVATAR',
    avatar,
  }
}

export function setLogo(logo) {
  return {
    type: 'SET_LOGO',
    logo,
  }
}

export function setUserProfile(userProfile) {
  return {
    type: 'SET_USER_PROFILE',
    userProfile,
  }
}

export function setUserProfileCorporateName(userProfileCorporateName) {
  return {
    type: 'SET_USER_PROFILE_CORPORATE_NAME',
    userProfileCorporateName,
  }
}

// Settings

export function setAllSchedules(allSchedules) {
  return {
    type: 'SET_ALL_SCHEDULES',
    allSchedules,
  }
}

export function setCostTypes(costTypes) {
  return {
    type: 'SET_COST_TYPES',
    costTypes,
  }
}

export function setProductsCategories(productsCategories) {
  return {
    type: 'SET_PRODUCTS_CATEGORIES',
    productsCategories,
  }
}

export function setIntegratorUser(integratorUser) {
  return {
    type: 'SET_INTEGRATOR_USER',
    integratorUser,
  }
}

export function setExtraFields(extraFields) {
  return {
    type: 'SET_EXTRA_FIELDS',
    extraFields,
  }
}

export function setRoiItemsTypes(roiItemsTypes) {
  return {
    type: 'SET_ROI_ITEMS_TYPES',
    roiItemsTypes,
  }
}

export function setReturnYears(returnYears) {
  return {
    type: 'SET_RETURN_YEARS',
    returnYears,
  }
}

export function setPrecifications(precifications) {
  return {
    type: 'SET_PRECIFICATIONS',
    precifications,
  }
}

export function setCommissionings(commissionings) {
  return {
    type: 'SET_COMMISSIONINGS',
    commissionings,
  }
}
