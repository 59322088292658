import { ModalLegislationWireB } from 'components'
import * as S from './styles'
import { usePlan } from 'hooks/usePlan'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setProModalOpen } from 'store/actions/proModal'

export const InfoRule14300 = () => {
  const [modalLegislationOpen, setModalLegislationOpen] = useState(false)

  const dispatch = useDispatch()
  const { isPro } = usePlan()

  const handleOpenProModal = () => {
    dispatch(setProModalOpen())
  }

  return (
    <>
      <S.Container>
        <p>
          Como a Lei 14300/22 passou a vigorar no dia 07/01/2023, os projetos
          passaram a ter a tributação do fio B na TUSD da energia,&nbsp;
          <span
            onClick={() => {
              setModalLegislationOpen(true)
            }}
          >
            saiba mais.
          </span>
        </p>
        <p>
          {!isPro() && (
            <>
              Caso queira prosseguir sem a aplicação da mesma, torne-se
              PRO&nbsp;
              <span onClick={handleOpenProModal}>clicando aqui</span>.
            </>
          )}
        </p>
      </S.Container>

      {modalLegislationOpen && (
        <ModalLegislationWireB
          open={modalLegislationOpen}
          onClose={() => {
            setModalLegislationOpen(false)
          }}
        />
      )}
    </>
  )
}
