import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'

export function useCustomizeKit(dimensioningId: number) {
  const { isLoading } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const openModalToChangeQuotationFreight = useEquipmentContainerStore(
    (state) => state.openModalToChangeQuotationFreight,
  )

  return { isLoading, openModalToChangeQuotationFreight }
}
