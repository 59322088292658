import { useState } from 'react'
import { Box, Grid, Hidden, useMediaQuery } from '@material-ui/core'
import { Spacer, Input, Dropdown } from '@77sol/core'
import { FilterIcon, SearchIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { useEquipmentCategories } from 'domains/equipments/categories/hooks/useEquipmentCategories'
import { optionsCategories } from './utils/optionsCategories'

export function Filters({
  onFilterProducts,
  openSidebarProduct,
  setOpenSidebarProduct,
}) {
  const [searchText, setSearchText] = useState('')
  const [categoriaNovaLoja, setCategoriaNovaLoja] = useState()
  const [actualFilterMobile, setActualFilterMobile] = useState('search')

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  const { data: categories } = useEquipmentCategories({ ordem: 'asc' })

  const categoriasNovaLojaFilter = optionsCategories(categories)
  const hasCategoriesFound = categoriasNovaLojaFilter.length > 1

  return (
    <Grid container alignItems="flex-end" justifyContent="flex-end">
      <Grid md={8} xs={12}>
        <Hidden smDown>
          <Box display="flex" flexDirection="row-reverse">
            {openSidebarProduct && !upXl ? (
              <FilterIcon
                width="24"
                color={palette.grayscale[700]}
                onClick={() => {
                  setOpenSidebarProduct(false)
                }}
              />
            ) : (
              <>
                {hasCategoriesFound && (
                  <Dropdown
                    value={categoriaNovaLoja}
                    onChange={(value) => {
                      setCategoriaNovaLoja(value)
                      onFilterProducts('category', value)
                    }}
                    size="small"
                    title="Categoria"
                    initialValue={categoriasNovaLojaFilter[0]?.value}
                    options={categoriasNovaLojaFilter}
                  />
                )}
                <Spacer size="24" direction="vertical" />
                <Input
                  value={searchText || ''}
                  placeholder="Pesquisar produtos"
                  onChange={setSearchText}
                  fullWidth
                  onClear={() => {
                    setSearchText('')
                    onFilterProducts('termo')
                  }}
                  startAdornment={
                    <SearchIcon width="18" color={palette.grayscale[500]} />
                  }
                  style={{ width: '200px' }}
                  onBlur={() => {
                    onFilterProducts('termo', searchText)
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.target.blur()
                    }
                  }}
                />
              </>
            )}
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Spacer size="24" direction="horizontal" />
          {actualFilterMobile === 'category' && (
            <div style={{ display: 'flex' }}>
              <FilterIcon
                width="24"
                color={palette.primary[300]}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setActualFilterMobile('search')
                }}
              />
              <Spacer size="16" direction="vertical" />
              {hasCategoriesFound && (
                <Dropdown
                  value={categoriaNovaLoja}
                  onChange={(value) => {
                    setCategoriaNovaLoja(value)
                    onFilterProducts('category', value)
                  }}
                  size="small"
                  title="Categoria"
                  fullWidth
                  initialValue="Todas"
                  options={categoriasNovaLojaFilter}
                />
              )}
            </div>
          )}
          {actualFilterMobile === 'search' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '-webkit-fill-available' }}>
                <Input
                  value={searchText || ''}
                  placeholder="Pesquisar produtos"
                  onChange={setSearchText}
                  startAdornment={
                    <SearchIcon width="18" color={palette.grayscale[500]} />
                  }
                  onBlur={() => {
                    onFilterProducts('termo', searchText)
                  }}
                  onClear={() => {
                    setSearchText('')
                    onFilterProducts('termo')
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      ev.target.blur()
                    }
                  }}
                />
              </div>
              <Spacer size="16" direction="vertical" />
              <FilterIcon
                width="24"
                color={palette.grayscale[700]}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setActualFilterMobile('category')
                }}
              />
            </div>
          )}
        </Hidden>
      </Grid>
    </Grid>
  )
}
