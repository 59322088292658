import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  position: relative;
  padding-right: 20px;
`

export const Title = styled.h2`
  display: flex;
  font-weight: 400;
  color: ${palette.primary[300]};
`

export const Icon = styled.span`
  vertical-align: middle;
  margin-right: 8px;
`

export const Close = styled.button`
  cursor: pointer;

  width: 30px;
  height: 30px;
  padding: 2px;
  border-radius: 50%;

  position: absolute;
  right: -20px;
  top: -20px;

  border: none;
  background-color: transparent;
  transition: background-color 0.2s;

  :focus {
    background-color: ${palette.grayscale[200]};
  }
`

export const Content = styled.main`
  padding-top: 20px;
`
