import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { Info } from 'lucide-react'
import * as S from './styles'

interface IModalContinueWithChangesProps {
  onContinue: () => void
}

export function ModalContinueWithChanges({
  onContinue,
}: IModalContinueWithChangesProps) {
  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )

  function handleContinue() {
    setOpenModalContinueWithChanged(false)
    onContinue()
  }

  return (
    <Modal.Root open onOpenChange={setOpenModalContinueWithChanged}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper id="modal-remove-quotation" maxWidth="650px">
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Close />
            <Modal.Title>
              Você alterou o kit gerado automaticamente. Deseja continuar?
            </Modal.Title>
            <Modal.Description>
              Antes de seguir, certifique-se de que:
            </Modal.Description>
          </Modal.Header>
          <S.StyledModalWrapper>
            <p>
              - A potência dos módulos é compatível com a capacidade do
              inversor;
            </p>
            <p>
              - As estruturas, cabos e conectores estão na quantidade adequada
              para o número de módulos.
            </p>
          </S.StyledModalWrapper>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline">
                Voltar
              </Button>
            </Modal.Close>
            <Button fullWidth onClick={handleContinue}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
