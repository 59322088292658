import React from 'react'
import { Typography } from '@77sol/core'
import { Content } from './styles'

export function LabelItem({ title, text }) {
  return (
    <>
      <Typography>{title}</Typography>

      <Content dangerouslySetInnerHTML={{ __html: text }} />
    </>
  )
}
