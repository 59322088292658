import { Wrapper } from './styles'

const URL_DESKTOP =
  'https://77solassets.s3.sa-east-1.amazonaws.com/checkout-modal/Banner-Desktop.png'

const URL_MOBILE =
  'https://77solassets.s3.sa-east-1.amazonaws.com/checkout-modal/Banner-Mobile.png'

export function Banner() {
  return (
    <Wrapper data-testid="modal-checkout-banner">
      <picture>
        <source srcSet={URL_MOBILE} media="(max-width: 640px)" />
        <img src={URL_DESKTOP} alt="Ganhe 2 vezes mais pontos no Ranking!" />
      </picture>
    </Wrapper>
  )
}
