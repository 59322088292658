import { useQuery } from '@tanstack/react-query'
import { FINANCING_PER_ORIGIN } from '../constants/endpointKeys'

import {
  type IGetFinancingPerOriginRequest,
  type IGetFinancingPerOriginResponse,
} from '../services/types'
import { getFinancingPerOrigin } from '../services/api'

interface IUseFinancingPerOriginProps extends IGetFinancingPerOriginRequest {
  onSuccess?: (data: IGetFinancingPerOriginResponse) => void
  enabled?: boolean
  onError?: (data: unknown) => void
}

export function useFinancingPerOrigin({
  financingId,
  onSuccess,
  enabled = true,
  onError,
}: IUseFinancingPerOriginProps) {
  return useQuery({
    queryKey: [FINANCING_PER_ORIGIN, financingId],
    queryFn: async () => {
      if (!financingId) throw new Error('FinancingId is required')
      return await getFinancingPerOrigin({
        financingId,
      })
        .then((response) => {
          const { data } = response
          onSuccess?.(data)
          return data?.requisitions
        })
        .catch((error: unknown) => {
          onError?.(error)
          throw new Error(error as any)
        })
    },
    enabled,
  })
}
