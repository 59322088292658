import { createRef } from 'react'

export const useScroll = () => {
  const refClientButton = createRef()
  const handleClickClientButton = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (!refClientButton.current) {
      return null
    }
    refClientButton.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
  }

  const refClient = createRef()
  const handleClickClient = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (!refClient.current) {
      return null
    }
    refClient.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }

  const refTipoDimensionamento = createRef()
  const handleClickTipoDimensionamento = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (!refTipoDimensionamento.current) {
      return null
    }
    refTipoDimensionamento.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }

  return {
    handleClickClientButton,
    refClientButton,
    handleClickClient,
    refClient,
    handleClickTipoDimensionamento,
    refTipoDimensionamento,
  }
}
