import * as S from './styles'
import errorImage from './assets/error-state.svg'
import { EmptyState, type IEmptyStateRootProps } from '@77sol-ui/molecules'
import { EmptyState as PrimitiveEmptyState } from '@77sol-ui/molecules'
import { CircleX } from 'lucide-react'

interface IErrorState extends Partial<IEmptyStateRootProps> {
  alreadyCreatedFinancingOrProposal?: boolean
  numberOfRows: number
  onButtonAction?: () => void
  title: string
  subtitle?: string
  buttonText?: string
}

export function ErrorState({
  alreadyCreatedFinancingOrProposal,
  numberOfRows,
  onButtonAction,
  title,
  subtitle,
  buttonText,
  ...props
}: IErrorState) {
  if (numberOfRows < 4) {
    return (
      <S.Wrapper height="100%" maxWidth="100%" spacing={4}>
        <CircleX strokeWidth={2} />

        <PrimitiveEmptyState.Subtitle size="lg">
          {title}
        </PrimitiveEmptyState.Subtitle>
        <S.ButtonAsLink onClick={onButtonAction}>{buttonText}</S.ButtonAsLink>
      </S.Wrapper>
    )
  }

  return (
    <EmptyState.Root {...props}>
      <S.Image src={errorImage} alt="tomada desconectada" />

      <EmptyState.Title>{title}</EmptyState.Title>

      {subtitle && <EmptyState.Subtitle>{subtitle}</EmptyState.Subtitle>}

      {buttonText && onButtonAction && (
        <EmptyState.Button onClick={onButtonAction}>
          {buttonText}
        </EmptyState.Button>
      )}
    </EmptyState.Root>
  )
}
