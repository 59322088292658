import { Button } from '@77sol-ui/atoms'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { BadgePercent } from 'lucide-react'

export function DiscountButton() {
  const setOpenModalQuotationDiscount = useEquipmentContainerStore(
    (state) => state.setOpenModalQuotationDiscount,
  )

  return (
    <Button
      variant="outline"
      onClick={() => {
        setOpenModalQuotationDiscount(true)
      }}
    >
      Desconto
      <BadgePercent size={16} />
    </Button>
  )
}
