import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333;
  font-family: DM Sans;
`

export const SubTitle = styled.small`
  font-size: 12px;
  font-weight: 400;
  color: #787486;
  font-family: DM Sans;
`
