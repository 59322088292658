import { useMutation } from '@tanstack/react-query'
import {
  type changeEquipmentQuantityProps,
  type ChangeEquipmentProps,
} from '../types'
import { quotationService } from '../api'

export function useChangeEquipmentQuotation() {
  const changeEquipmentMutation = useMutation({
    mutationFn: async (data: ChangeEquipmentProps) =>
      await quotationService.changeEquipment(data),
  })

  const changeEquipmentQuantityMutation = useMutation({
    mutationFn: async (data: changeEquipmentQuantityProps) =>
      await quotationService.changeEquipmentQuantity(data),
  })

  return {
    changeEquipmentMutation,
    changeEquipmentQuantityMutation,
  }
}
