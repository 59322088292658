import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'

export function useCompareKitCardsContainer() {
  const { dimensioningId } = useEquipmentContainerStore()

  const { data: dimensioningResponse } =
    useObserveQuery<IDimensioningGETResponse>([
      GET_DIMENSIONING,
      dimensioningId,
    ])
  const quotations = dimensioningResponse?.quotations ?? []

  return {
    quotations,
  }
}
