import { type Resolver, useForm, useFormContext } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerQuotationFormData,
} from '../types'
import { baseSchema } from '../validators/formValidator'

export function useCreateFinancingPerQuotationForm(
  defaultValues: DefaultValuesForm,
) {
  const methods = useForm<ICreateFinancingPerQuotationFormData>({
    resolver: yupResolver(
      baseSchema,
    ) as Resolver<ICreateFinancingPerQuotationFormData>,
    defaultValues,
  })

  return methods
}

export const useCreateFinancingPerQuotationFormContext = () => {
  return useFormContext<ICreateFinancingPerQuotationFormData>()
}
