import { SidebarMobile } from '@77sol-ui/organisms'
import {
  SidebarMobileWrapper,
  StyledProfileAvatar,
  StyledProfileContent,
} from './styles'
import { useSidebarMobileProfile } from './hooks/useSidebarMobileProfile'

export function SidebarMobileProfileWrapper() {
  const { userLogged, logo, handleRedirectToAccount } =
    useSidebarMobileProfile()

  return (
    <SidebarMobileWrapper>
      <StyledProfileAvatar
        src={logo}
        alt="Logo da empresa"
        onClick={handleRedirectToAccount}
      />
      <StyledProfileContent>
        <SidebarMobile.ProfileTitle>
          {userLogged[0].nome}
        </SidebarMobile.ProfileTitle>
        <SidebarMobile.ProfileSubtitle>
          {userLogged[0].display_role}
        </SidebarMobile.ProfileSubtitle>
      </StyledProfileContent>
    </SidebarMobileWrapper>
  )
}
