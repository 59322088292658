import { ButtonAsLink, Wrapper } from './styles'
import { EmptyState as PrimitiveEmptyState } from '@77sol-ui/molecules'
import { TooltipLockedToFaas } from '_Distributor/components/TooltipLockedToFaas'

export interface IProposalListProps {
  numberOfRows?: number
}

export function ProposalList({ numberOfRows = 0 }: IProposalListProps) {
  return (
    <Wrapper numberOfRows={numberOfRows} data-testid="proposals-list">
      <PrimitiveEmptyState.Root
        height="100%"
        maxWidth="100%"
        spacing={4}
        variant="table"
      >
        <PrimitiveEmptyState.Icon emoji="👋" size="lg" />
        <PrimitiveEmptyState.Title size="md">
          Crie um novo projeto
        </PrimitiveEmptyState.Title>
        <PrimitiveEmptyState.Subtitle size="md">
          Ou simule uma cotação
          <TooltipLockedToFaas>
            <ButtonAsLink data-testid="quote-equipment-button">
              aqui
            </ButtonAsLink>
          </TooltipLockedToFaas>
        </PrimitiveEmptyState.Subtitle>
        <TooltipLockedToFaas>
          <PrimitiveEmptyState.Button data-testid="new-project-button">
            Criar Projeto
          </PrimitiveEmptyState.Button>
        </TooltipLockedToFaas>
      </PrimitiveEmptyState.Root>
    </Wrapper>
  )
}
