import { palette } from '@77sol/styles'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '16px 16px 32px 16px',
    display: 'flex',
    height: '100%',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  rightBorder: {
    borderRight: `1px solid ${palette.grayscale[300]}`,
    paddingRight: '24px',
  },
  paddingLeft: {
    paddingLeft: '24px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  resize: {
    fontSize: '32px',
    color: '#464F5B',
    width: '100px',
  },
  div: {
    height: '100%',
  },
})
