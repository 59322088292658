import React from 'react'
import { Box } from '@material-ui/core'
import { Typography } from '@77sol/core'
import styles from './styles'

function Card({
  title,
  bold,
  headerChildren,
  children,
  padding,
  fullWidth,
  fullHeight,
  ...props
}) {
  const classes = styles({
    padding, bold, fullWidth, fullHeight,
  })

  return (
    <div className={classes.container} {...props}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{title}</Typography>
        {headerChildren}
      </Box>
      {children && <div className={classes.children}>{children}</div>}
    </div>
  )
}

export default Card
