import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { triggerTypes } from 'services/tracker/properties/triggerType'

export function useRequestQuotation() {
  const { setIsQuotationModalOpen } = useQuotationModalContext()
  const { logEvent } = useAmplitude()
  const history = useHistory()

  const handleQuoteEquipment = useCallback(
    ({ triggeredByDropdown = false }) => {
      logEvent(headerTracker.eventName, {
        origin: location.pathname,
        action: headerTracker.actions.quickQuoteKey,
        type: triggeredByDropdown
          ? triggerTypes.dropdownMenu.option
          : triggerTypes.button,
      })
      setIsQuotationModalOpen(true)
    },
    [],
  )

  const handleNewProposal = useCallback(({ triggeredByDropdown = false }) => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.sizingAndQuoteKey,
      type: triggeredByDropdown
        ? triggerTypes.dropdownMenu.option
        : triggerTypes.button,
    })
    history.push('/dimensionar')
  }, [])

  return { handleQuoteEquipment, handleNewProposal }
}
