import styled from 'styled-components'

export const FormWrapper = styled.form`
  & > .g-md-2 > div {
    height: 100%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1280px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    & button {
      width: 100%;
    }
  }
`
