import api from 'api'

import {
  type IGetFinancingAllValueRequest,
  type IGetFinancingAllValueResponse,
} from './types'
import { FINANCING_ALL_VALUE } from '../constants/endpointKeys'

export const getFinancingAllValue = async ({
  params,
}: IGetFinancingAllValueRequest) => {
  return await api.get<IGetFinancingAllValueResponse>(
    `${FINANCING_ALL_VALUE}${params.search}`,
    {
      params: {
        order: params?.order,
        page: params?.page,
      },
    },
  )
}
