import React from 'react'
import { Box, Fade, Modal, Backdrop } from '@material-ui/core'
import {
  Typography,
  Spacer,
  Button,
  Paper,
  Dropdown,
  TextArea,
} from '@77sol/core'
import { optionsStates } from '../../constants'
import { useStyles } from '../../styles'

export function ModalPloomes({
  modalPloomes,
  setModalPloomes,
  valuesPloomes,
  setValuesPloomes,
  actionsPloomes,
  optionsCity,
}) {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modalSm}
      open={modalPloomes}
      onClose={() => setModalPloomes(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalPloomes} style={{ border: 'none' }}>
        <Paper
          style={{ height: 'auto', width: 'fit-content', minWidth: '30vw' }}
        >
          <Box className={classes.modalPloomes}>
            <Typography color="primary" colorWeight="700" size="xsmall">
              Negociação interna
            </Typography>
            <Spacer direction="horizontal" size="23" />
            <Dropdown
              disabled
              options={optionsStates}
              initialValue={valuesPloomes.state}
            />
            <Spacer direction="horizontal" size="16" />
            <Dropdown
              title="Selecione um cidade"
              options={optionsCity}
              initialValue={valuesPloomes.city}
              onChange={(value) =>
                setValuesPloomes((state) => ({
                  ...state,
                  city: value,
                }))
              }
            />
            <Spacer direction="horizontal" size="16" />
            <TextArea
              title="Informações adicionais"
              value={valuesPloomes.info}
              onChange={(value) =>
                setValuesPloomes((state) => ({
                  ...state,
                  info: value,
                }))
              }
            />
            <Spacer direction="horizontal" size="32" />
            <Box display="flex" justifyContent="end">
              <Button variant="text" onClick={() => setModalPloomes(false)}>
                Fechar
              </Button>
              <Spacer direction="vertical" size="16" />
              <Button onClick={actionsPloomes}>Enviar</Button>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}
