import { Badge, type IBadgeProps } from './Badge'
import { BadgeContainer, type IBadgeContainerProps } from './BadgeContainer'
import { Root, type IRootProps } from './Root'
import { Content, type IContentProps } from './Content'
import { ButtonClose, type IButtonCloseProps } from './ButtonClose'
import { Flag, type IFlagProps } from './Flag'
import { ImagesContainer, type IImagesContainerProps } from './ImagesContainer'
import { Image, type IImageProps } from './Image'

const SmartCard = {
  Root,
  Badge,
  Flag,
  Content,
  BadgeContainer,
  ButtonClose,
  ImagesContainer,
  Image,
}

export {
  SmartCard,
  type IRootProps,
  type IBadgeProps,
  type IButtonCloseProps,
  type IFlagProps,
  type IImagesContainerProps,
  type IImageProps,
  type IBadgeContainerProps,
  type IContentProps,
}
