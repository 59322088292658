import { Button } from '@77sol-ui/atoms'
import { SlideMenu, ToggleSelect } from '@77sol-ui/molecules'
import styled from 'styled-components'

interface StyledToggleSelectRootProps {
  amount: number
}

export const StyledSlideTrigger = styled(Button)`
  gap: 8px;
  width: fit-content;
  font-weight: 500;
  color: #4f4f4f;
`

export const StyledSlideContent = styled(SlideMenu.Content)`
  padding: 16px;
`

export const StyledBackButton = styled(Button)`
  gap: 8px;
  width: fit-content;
  font-weight: 500;
  padding: 0;
`

export const StyledFiltersContent = styled.div`
  margin-top: 16px;

  h3 {
    font-family: DM Sans;
    font-weight: 700;
    font-size: 16;
  }

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const StyledToggleSelectRoot = styled(
  ToggleSelect.Root,
)<StyledToggleSelectRootProps>`
  width: 100%;

  & > button {
    width: calc(100% / ${({ amount }) => amount});
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
