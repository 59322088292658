import React from 'react'
import { Paper } from '@77sol/core'
import { Typography } from 'components'
import { ArrowChevronDownIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { useStyles } from './styles'

export function Filters({ label, isOpen, setIsOpen, children }) {
  const classes = useStyles()
  return (
    <Paper boxShadow="small" style={{ height: 'auto', padding: 0 }}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: 24,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography type="link_medium" color="grayscale" colorWeight="800">
          {label}
        </Typography>
        <div className={isOpen ? classes.iconButton : classes.iconExpanded}>
          <ArrowChevronDownIcon width="24" color={palette.grayscale[800]} />
        </div>
      </div>
      {children}
    </Paper>
  )
}
