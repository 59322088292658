import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { lojaNovoPedidoLoja } from '../services/api'
import { type NewOrderStorePOSTPayload } from '../services/types'

export const useAddLojaNovoPedidoLoja = () => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (payload: NewOrderStorePOSTPayload) =>
      await lojaNovoPedidoLoja.post(payload),
    onError: (error: any) => {
      onErrorOpenToast(error.message)
    },
  })
}
