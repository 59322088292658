import { SidebarMobile } from '@77sol-ui/organisms'
import styled from 'styled-components'

export const StyledProfileContent = styled(SidebarMobile.ProfileContent)`
  max-width: 60%;

  h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const StyledProfileAvatar = styled(SidebarMobile.ProfileAvatar)`
  min-width: 3.5rem;
  min-height: 3.5rem;
`

export const SidebarMobileWrapper = styled(SidebarMobile.ProfileWrapper)`
  width: 90%;
`
