const units = [
  { title: 'kilo', len: 3, thounsands: 1 },
  { title: 'Mega', len: 6, thounsands: 1000 },
  { title: 'Giga', len: 9, thounsands: 1000000 },
  { title: 'Tera', len: 12, thounsands: 1000000000 },
  { title: 'Pera', len: 15, thounsands: 1000000000000 },
  { title: 'Exa', len: 18, thounsands: 1000000000000000 },
]

const handleUnit = value => {
  let newValue = value
  const length = newValue.toFixed(0).length
  let unit = 0
  let label = ''

  for (var i = 0; i < Object.entries(units).length; ++i) {
    if (length <= units[i].len) {
      unit = units[i].thounsands
      label = units[i].title[0]
      break
    }
  }

  return { value: (newValue / unit).toFixed(2), label: label }
}

export default handleUnit
