import { Wrapper, Content } from './styles'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { ChangeFreightButton } from './components/ChangeFreightButton'
import { DownloadButtonQuotationPDF } from './components/DownloadButtonQuotationPDF'
import { RevertKitButton } from './components/RevertKitButton'
import { AddEquipmentButton } from './components/AddEquipmentButton'
import { DiscountButton } from './components/DiscountButton'
import { useIsAdmin } from 'hooks/useIsAdmin'

export function EquipmentsHeader() {
  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )
  const isAdmin = useIsAdmin()
  return (
    <Wrapper>
      {quotationSelected && <h2>{`Cotação #${quotationSelected.id}`}</h2>}

      <Content>
        <div className="content-left">
          <DownloadButtonQuotationPDF />
          <ChangeFreightButton
            state={quotationSelected?.delivery_state as string}
          />
          {isAdmin && <DiscountButton />}
        </div>

        <div className="content-right">
          <RevertKitButton />
          <AddEquipmentButton />
        </div>
      </Content>
    </Wrapper>
  )
}
