import { Button } from '@77sol-ui/atoms'
import styled from 'styled-components'

export const StyleNotifyButton = styled(Button)`
  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`
