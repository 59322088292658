import appTheme from 'app_theme'
import { copernico } from './copernico'
import { defaultConfig } from './default'
import { type WhiteLabelProperties } from './globalConfig.types'

interface EnterpriseInfos {
  default: WhiteLabelProperties
  copernico: WhiteLabelProperties
}

const enterpriseInfos: EnterpriseInfos = {
  copernico,
  default: defaultConfig,
}

export const currentEnterpriseConfigs =
  enterpriseInfos[appTheme as keyof typeof enterpriseInfos] ||
  enterpriseInfos.default
