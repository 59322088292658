import styled from 'styled-components'

export const Container = styled.section`
  margin-top: 24px;
`

export const Historic = styled.div`
  border-radius: 8px;
  margin-top: 24px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 6px;
  background: white;
  padding: 16px;

  @media (min-width: 1280px) {
    padding: 32px;
  }
`
