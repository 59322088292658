import {
  SearchIcon,
  AlignLeftIcon,
  CheckIcon,
  FileIcon,
  TimeIcon,
  SendIcon,
  ThumbsUpIcon,
  CloseXIcon,
} from '@77sol/icons/dist'

export const MAIN_STAGES = [
  {
    Icon: SendIcon,
    label: 'Solicitação',
    key: 'pre-analise',
  },
  {
    Icon: ThumbsUpIcon,
    label: 'Pré-Aprovado',
    key: 'pre-aprovado',
  },
  {
    Icon: AlignLeftIcon,
    label: 'Seguir para Aprovação',
    key: 'seguir-para-aprovacao',
  },
  {
    Icon: SearchIcon,
    label: 'Analisando dados',
    key: 'analisando-dados',
  },
  {
    Icon: SearchIcon,
    label: 'Dados Complementares',
    key: 'dados-complementares',
  },
  {
    Icon: TimeIcon,
    label: 'Aguardando Resposta',
    key: 'aguardando-resposta',
  },
  {
    Icon: FileIcon,
    label: 'Formalização',
    key: 'formalizacao',
  },
  {
    Icon: CheckIcon,
    label: 'Aprovado',
    key: 'aprovacao',
  },
  {
    Icon: CheckIcon,
    label: 'Concluído',
    key: 'concluido',
  },
]

export const CANCEL_STAGES = [
  {
    Icon: SendIcon,
    label: 'Solicitação',
    key: 'pre-analise',
  },
  {
    Icon: CloseXIcon,
    label: 'Cancelado',
    key: 'cancelado',
  },
]

export const DENY_STAGES = [
  {
    Icon: SendIcon,
    label: 'Solicitação',
    key: 'pre-analise',
  },
  {
    Icon: CloseXIcon,
    label: 'Negado',
    key: 'negado',
  },
]
