import styled from 'styled-components'
import { palette } from '@77sol/styles'
import { Modal } from '@77sol/core'
import { CloseXIcon } from '@77sol/icons/dist'

export const Container = styled(Modal)`
  background-color: white;
  max-width: 880px;
  padding: 16px !important;

  width: 94%;
  height: 97%;

  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    height: 610px;
    padding: 34px !important;
  }
`

export const CloseModal = styled(CloseXIcon)`
  height: 20px;
  cursor: pointer;
`

export const Header = styled.header`
  .nav {
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }

  h3 {
    color: ${palette.primary[300]};
    font-size: 1.2rem;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 32px;
    }
  }

  p {
    color: ${palette.grayscale[500]};
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 0.75px;

    @media (min-width: 768px) {
      font-size: 0.9rem;
    }
  }
`

export const Content = styled.div`
  margin: 28px 0;
  position: relative;
  overflow: auto;
  flex: 1;
`

export const Scroll = {
  height: '100%',
  paddingBottom: '30px',
}

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  > div {
    display: flex;
    gap: 14px;
    margin-bottom: 14px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    button {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`
