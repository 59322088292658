import { Button } from '@77sol-ui/atoms'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function CancelButton() {
  const setOpenModalToCancelProposal = useEquipmentContainerStore(
    (state) => state.setOpenModalToCancelProposal,
  )
  return (
    <Button
      variant="outline"
      onClick={() => setOpenModalToCancelProposal(true)}
    >
      Cancelar
    </Button>
  )
}
