import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

interface CreateFinancingPerValueModalData {
  isFinancingPerValueModalOpen: boolean
  setFinancingPerValueModalOpen: () => void
  setFinancingPerValueModalClose: () => void
}

interface ProviderProps {
  children: ReactNode
}

const CreateFinancingPerValueModalContext =
  createContext<CreateFinancingPerValueModalData>(
    {} as CreateFinancingPerValueModalData,
  )

function CreateFinancingPerValueModalProvider({ children }: ProviderProps) {
  const [isFinancingPerValueModalOpen, setIsFinancingPerValueModalOpen] =
    useState(false)

  const setFinancingPerValueModalOpen = useCallback(() => {
    setIsFinancingPerValueModalOpen(true)
  }, [])

  const setFinancingPerValueModalClose = useCallback(() => {
    setIsFinancingPerValueModalOpen(false)
  }, [])

  const contextValue = useMemo(
    () => ({
      isFinancingPerValueModalOpen,
    }),
    [isFinancingPerValueModalOpen],
  )

  return (
    <CreateFinancingPerValueModalContext.Provider
      value={{
        ...contextValue,
        setFinancingPerValueModalOpen,
        setFinancingPerValueModalClose,
      }}
    >
      {children}
    </CreateFinancingPerValueModalContext.Provider>
  )
}

const useCreateFinancingPerValueModalContext = () => {
  const context = useContext(CreateFinancingPerValueModalContext)
  return context
}

export {
  useCreateFinancingPerValueModalContext,
  CreateFinancingPerValueModalProvider,
}
