import { useFinancingAllValue } from 'domains/financing/financing-all-value/hooks/useFinancingAllValue'
import { useFinancingByValue } from 'domains/financing/financing-by-value/hooks/useFinancingByValue'
import { useMemo } from 'react'
import {
  formatFinancingPerValueList,
  getMinTableInfoFinancingPerValue,
} from 'views/Financing/utils'

export function useListFinancingValue({
  isOpenSimulations,
  isOpenSimulationDetails,
  onRowClick,
  financingId,
  defaultTab,
}) {
  const { search = '' } = window.location

  const {
    financingList,
    fetchNextPage,
    hasNextPage = false,
    isLoading: isLoadingAllValue,
  } = useFinancingAllValue({
    enabled: !financingId,
    staleTime: 60 * 1000, // 1min
    search,
  })

  const { data: financing, isLoading: isLoadingByValue } = useFinancingByValue({
    financingId,
    enabled: Boolean(financingId) && Boolean(defaultTab),

    onSuccess: (responseData) => {
      const currentFinancing = responseData?.data

      const id = Number(financingId)

      const hasFoundTheIdOfFinancingByValue =
        currentFinancing.financing_id === id

      const hasFoundTheIdOfFinancingByQuotation =
        Number(currentFinancing.id_origin) === id

      const urlIdIsValid =
        hasFoundTheIdOfFinancingByValue || hasFoundTheIdOfFinancingByQuotation

      if (urlIdIsValid) {
        onRowClick({
          ...currentFinancing,
          rowData: {
            ...currentFinancing,
            rowIndex: 0,
          },
        })
      }
    },
  })

  const data = useMemo(() => {
    if (!financingId && financingList) {
      return formatFinancingPerValueList(financingList)
    }

    if (financingId && financing) {
      return formatFinancingPerValueList([financing])
    }

    return []
  }, [financingList, financing, financingId])

  const { values = [], columns = [] } = getMinTableInfoFinancingPerValue(
    data,
    isOpenSimulations,
    isOpenSimulationDetails,
  )

  const isLoading =
    (!financingId && isLoadingAllValue) ||
    (Boolean(financingId) && isLoadingByValue)

  return {
    values,
    columns,
    fetchNextPage,
    hasNextPage: hasNextPage && !financingId,
    isLoading,
  }
}
