import React, { useState, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Hidden, useMediaQuery } from '@material-ui/core'
import { Typography, Input } from 'components'
import { Spacer, Paper, Button, useToast } from '@77sol/core'
import {
  ActionPlusIcon,
  CircleActionsCloseIcon,
  EditIcon,
  DeleteIcon,
} from '@77sol/icons/dist'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import palette from 'app_palette'
import smoothscroll from 'smoothscroll-polyfill'
import * as ProposalAction from 'store/actions/index'
import API from 'api'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

function OrcamentoPanel({
  budgetEdit,
  selectedQuote,
  handleGetBudget,
  noBoxShadow,
  handleShowRoi,
  isFromProposalList,
  noPadding,
}) {
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const proposal = useSelector((state) => state.Reducer1)

  const [loadingCreateBudget, setLoadingCreateBudget] = useState([false, 0])
  const [activeCustom, setActiveCustom] = useState(false)

  const [newFrontName, setNewFrontName] = useState('')
  const [newFrontValue, setNewFrontValue] = useState()

  const [budgets, setBudgets] = useState(budgetEdit)
  const [editingCost, setEditingCost] = useState('')

  const dispatch = useDispatch()

  const { onErrorOpenToast } = useToast()

  const refCustomCost = createRef()
  const handleClickCustomCost = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (!refCustomCost.current) {
      return null
    }
    refCustomCost.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    })
  }

  function formatarMoeda(e) {
    let valor = e

    valor += ''
    valor = parseInt(valor.replace(/[\D]+/g, ''))
    valor += ''
    valor = valor.replace(/([0-9]{2})$/g, ',$1')

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
    }

    if (!valor || valor === '' || Number.isNaN(valor) || valor === 'NaN') {
      return 0
    }
    return valor
  }

  const handleFinish = () => {
    API.post('/proposal/quotation/budget/finalized', {
      quotation_id: selectedQuote,
    }).then((res) => {
      dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
      if (handleShowRoi) {
        handleShowRoi()
      }
    })
  }

  const handleRemoveBudget = (id) => {
    API.delete(`/proposal/remove/quotation/budget/item/${id}`)
      .then((res) => {
        setBudgets(res.data)
      })
      .catch(() => {
        handleGetBudget(selectedQuote)
      })
  }

  const handleCreateBudgetCustom = () => {
    if (newFrontName && newFrontValue) {
      API.post('/proposal/add/quotation/budget/item', {
        quotation_id: selectedQuote,
        cost_front: newFrontName,
        total_value: newFrontValue.split('.').join('').replace(',', '.'),
        is_custom: 1,
      }).then(() => {
        setActiveCustom(false)
        handleGetBudget(selectedQuote)
        setNewFrontName('')
        setNewFrontValue()
        handleFinish()
      })
    }
  }

  const handleCreateBudget = (project, isCustom) => {
    setLoadingCreateBudget([true, project])

    API.post('/proposal/add/quotation/budget/item', {
      quotation_id: selectedQuote,
      cost_front: project,
      total_value: 0,
      is_custom: isCustom,
    }).then((res) => {
      const { id } = res.data.find((item) => item.cost_front == project)
      setEditingCost(id)
      setLoadingCreateBudget([false, 0])
      handleGetBudget(selectedQuote)
    })
  }

  const handleChangeBudgetValue = (value, name) => {
    const projectValue = budgets.reduce(
      (accum, curr) =>
        curr.cost_front_slug == 'desconto'
          ? accum - parseFloat(curr.total_value)
          : accum + parseFloat(curr.total_value),
      0,
    )
    const equipmentsValue = budgets.find(
      (item) => item.cost_front_slug == 'equipamentos',
    ).total_value
    if (name == 'Desconto') {
      if (projectValue < parseFloat(equipmentsValue)) {
        onErrorOpenToast(
          'O valor do projeto não pode ser menor que o valor dos equipamentos!',
        )
        return
      }
    }

    API.post('/proposal/add/quotation/budget/item', {
      quotation_id: selectedQuote,
      cost_front: name,
      total_value: value.split('.').join('').replace(',', '.'),
    })
      .then(() => {
        handleGetBudget(selectedQuote)
        setEditingCost('')
        handleFinish()
      })
      .catch((err) => {
        if (err?.response?.data?.message == 'discount value not allowed') {
          onErrorOpenToast(
            `O valor máximo de desconto é R$ ${err.response.data.maximum_value.toLocaleString(
              'pt-br',
              { style: 'currency', currency: 'BRL' },
            )}!`,
          )
          setEditingCost('')
          handleGetBudget(selectedQuote)
          handleFinish()
        }
      })
  }

  const handleCreateTributation = (project, percentage) => {
    setLoadingCreateBudget([true, project])

    API.post('/proposal/add/quotation/budget/item', {
      quotation_id: selectedQuote,
      cost_front: 'Tributações',
      percentage: percentage || 7,
    }).then(() => {
      setLoadingCreateBudget([false, 0])
      handleGetBudget(selectedQuote)
      handleFinish()
    })
  }

  function isEquipments(a) {
    if (a.cost_front_slug == 'equipamentos') {
      return -1
    }
    return 1
  }

  function compareItems(a, b) {
    return b.is_custom - a.is_custom
  }

  const handleLastExtra = (list) => {
    const newList = [...list]
    const newList2 = newList.filter((item) => item.is_custom === 1)
    if (newList2[newList2.length - 1]) {
      return list.find(
        (item) =>
          item.cost_front_slug == newList2[newList2.length - 1].cost_front_slug,
      )
    }
    return null
  }

  useEffect(() => {
    setBudgets(budgetEdit)
  }, [budgetEdit])

  return (
    <Grid
      style={{ width: '100%', margin: '0px', marginTop: noPadding && '16px' }}
      container
      spacing={noPadding || downSm ? 0 : 2}
    >
      <Grid item xs={12} d={12}>
        <Paper
          style={{
            height: 'auto',
            width: '100%',
            padding: noPadding ? '0px' : upXl ? '24px' : '12px',
            boxShadow: noBoxShadow && 'none',
          }}
        >
          <PerfectScrollbar style={{ overflowX: 'auto' }}>
            <Table
              style={{ border: 'none', backgroundColor: 'transparent' }}
              size="small"
              aria-label="a dense table"
            >
              {!downLg && !isFromProposalList && (
                <TableHead
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                >
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      height: '30px',
                    }}
                  >
                    <TableCell
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    />
                    <TableCell
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      {!downLg && !isFromProposalList && (
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          Valor total
                        </Typography>
                      )}
                    </TableCell>
                    {!downLg && !isFromProposalList && (
                      <TableCell
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          padding: '6px',
                        }}
                      >
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          % do valor total
                        </Typography>
                      </TableCell>
                    )}
                    {!downLg && !isFromProposalList && (
                      <TableCell
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          padding: '6px',
                        }}
                      >
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          R$/kWp
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
              )}
              <TableBody
                style={{ border: 'none', backgroundColor: 'transparent' }}
              >
                <TableRow
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    borderBottom: '1px solid #e2e6e7',
                    height: '60px',
                  }}
                  key="m"
                >
                  <TableCell
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      padding: '6px',
                    }}
                  >
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="800"
                    >
                      Projeto
                    </Typography>
                    {(!upXl || isFromProposalList) && (
                      <>
                        <Typography
                          type="link_medium"
                          color="grayscale"
                          colorWeight="800"
                        >
                          {budgets
                            .reduce(
                              (accum, curr) =>
                                curr.cost_front_slug == 'desconto'
                                  ? accum - parseFloat(curr.total_value)
                                  : accum + parseFloat(curr.total_value),
                              0,
                            )
                            .toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                        </Typography>
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          R$/kWp:{' '}
                          {budgets
                            .reduce(
                              (accum, curr) =>
                                accum + parseFloat(curr.price_kwp),
                              0,
                            )
                            .toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      padding: '6px',
                    }}
                  >
                    {!downLg && !isFromProposalList && (
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="800"
                      >
                        {budgets
                          .reduce(
                            (accum, curr) =>
                              curr.cost_front_slug == 'desconto'
                                ? accum - parseFloat(curr.total_value)
                                : accum + parseFloat(curr.total_value),
                            0,
                          )
                          .toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                      </Typography>
                    )}
                    {(!upXl || isFromProposalList) && (
                      <Typography
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                        type="link_small"
                        color="grayscale"
                        colorWeight="800"
                      >
                        100.00%
                      </Typography>
                    )}
                  </TableCell>
                  {!downLg && !isFromProposalList && (
                    <TableCell
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="800"
                      >
                        100.00%
                      </Typography>
                    </TableCell>
                  )}
                  {!downLg && !isFromProposalList && (
                    <TableCell
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="800"
                      >
                        {budgets
                          .reduce(
                            (accum, curr) => accum + parseFloat(curr.price_kwp),
                            0,
                          )
                          .toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
                {budgets
                  .sort(compareItems)
                  .sort(isEquipments)
                  .map((budget, i) => (
                    <>
                      <TableRow
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid #e2e6e7',
                          height: '60px',
                        }}
                        key={`${budget.costFront + i}a`}
                      >
                        <TableCell
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '6px',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {budget.cost_front != 'Equipamentos' &&
                              proposal.nivelLogin <= 2 && (
                                <>
                                  <DeleteIcon
                                    onClick={() =>
                                      handleRemoveBudget(budget.id)
                                    }
                                    width="14"
                                    color={palette.red[300]}
                                    style={{
                                      cursor: 'pointer',
                                      minWidth: '14px',
                                    }}
                                  />
                                  <Spacer size="8" direction="vertical" />
                                </>
                              )}
                            <Typography
                              type="text_small"
                              color="grayscale"
                              colorWeight="600"
                            >
                              {budget.cost_front}
                            </Typography>
                          </div>
                          {(!upXl || isFromProposalList) && (
                            <>
                              {editingCost == budget.id &&
                              budget.cost_front != 'Tributações' ? (
                                <div style={{ display: 'flex' }}>
                                  <CircleActionsCloseIcon
                                    onClick={() => setEditingCost('')}
                                    width="16"
                                    color={palette.grayscale[800]}
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: '8px',
                                    }}
                                  />
                                  <Input
                                    key={`input${budget.costFront}${i}a`}
                                    defaultValue={formatarMoeda(
                                      budget.total_value,
                                    )}
                                    value={
                                      isNaN(budget.total_value)
                                        ? budget.total_value
                                        : formatarMoeda(budget.total_value)
                                    }
                                    style={{ minWidth: '150px' }}
                                    setValue={(value) => {
                                      const newArray = budgets
                                      const index = newArray.findIndex(
                                        (item) => item.id == budget.id,
                                      )
                                      newArray[index].total_value =
                                        value &&
                                        value.replace('.', '').replace(',', '.')
                                      setBudgets([...newArray])
                                    }}
                                    onBlur={(e) =>
                                      handleChangeBudgetValue(
                                        e.target.value,
                                        budget.cost_front,
                                      )
                                    }
                                    submit={() => {}}
                                    currencyFormat
                                    fullWidth
                                    type={downSm ? 'tel' : 'text'}
                                    size="small"
                                    placeholder="Valor"
                                  />
                                </div>
                              ) : (
                                <Typography
                                  onClick={() => {
                                    if (
                                      !(
                                        budget.cost_front == 'Equipamentos' ||
                                        budget.cost_front == 'Tributações' ||
                                        (budget.cost_front != 'Desconto' &&
                                          proposal.nivelLogin > 2)
                                      )
                                    ) {
                                      setEditingCost(budget.id)
                                    }
                                  }}
                                  style={{
                                    cursor:
                                      budget.cost_front == 'Equipamentos' ||
                                      budget.cost_front == 'Tributações'
                                        ? 'auto'
                                        : 'pointer',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  type="link_medium"
                                  color="grayscale"
                                  colorWeight="800"
                                >
                                  {parseFloat(
                                    budget.total_value,
                                  ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}{' '}
                                  {budget.cost_front == 'Equipamentos' ||
                                  budget.cost_front == 'Tributações' ||
                                  (budget.cost_front != 'Desconto' &&
                                    proposal.nivelLogin > 2) ? (
                                    ''
                                  ) : (
                                    <EditIcon
                                      width="16"
                                      style={{ marginLeft: '8px' }}
                                    />
                                  )}
                                </Typography>
                              )}
                              <Typography
                                type="text_x_small"
                                color="grayscale"
                                colorWeight="500"
                              >
                                R$/kWp:{' '}
                                {parseFloat(budget.price_kwp).toLocaleString(
                                  'pt-br',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  },
                                )}
                              </Typography>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          key={`cell${budget.costFront}${i}a`}
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            padding: '6px',
                          }}
                        >
                          {!downLg && !isFromProposalList && (
                            <>
                              {budget.cost_front == 'Equipamentos' ||
                              budget.cost_front == 'Tributações' ? (
                                <Typography
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="600"
                                >
                                  {parseFloat(
                                    budget.total_value,
                                  ).toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </Typography>
                              ) : (
                                <>
                                  {editingCost == budget.id ? (
                                    <div style={{ display: 'flex' }}>
                                      <CircleActionsCloseIcon
                                        onClick={() => setEditingCost('')}
                                        width="16"
                                        color={palette.grayscale[800]}
                                        style={{
                                          cursor: 'pointer',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <Input
                                        key={`input${budget.costFront}${i}a`}
                                        defaultValue={formatarMoeda(
                                          budget.total_value,
                                        )}
                                        value={
                                          isNaN(budget.total_value)
                                            ? budget.total_value
                                            : formatarMoeda(budget.total_value)
                                        }
                                        style={{ minWidth: '150px' }}
                                        setValue={(value) => {
                                          const newArray = budgets
                                          const index = newArray.findIndex(
                                            (item) => item.id == budget.id,
                                          )
                                          newArray[index].total_value =
                                            value &&
                                            value
                                              .replace('.', '')
                                              .replace(',', '.')
                                          setBudgets([...newArray])
                                        }}
                                        onBlur={(e) =>
                                          handleChangeBudgetValue(
                                            e.target.value,
                                            budget.cost_front,
                                          )
                                        }
                                        submit={() => {}}
                                        currencyFormat
                                        fullWidth
                                        type={downSm ? 'tel' : 'text'}
                                        size="small"
                                        placeholder="Valor"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        if (
                                          !(
                                            budget.cost_front ==
                                              'Equipamentos' ||
                                            budget.cost_front ==
                                              'Tributações' ||
                                            (budget.cost_front != 'Desconto' &&
                                              proposal.nivelLogin > 2)
                                          )
                                        ) {
                                          setEditingCost(budget.id)
                                        }
                                      }}
                                    >
                                      <Typography
                                        type="text_small"
                                        color="grayscale"
                                        colorWeight="600"
                                      >
                                        {parseFloat(
                                          budget.total_value,
                                        ).toLocaleString('pt-br', {
                                          style: 'currency',
                                          currency: 'BRL',
                                        })}
                                      </Typography>
                                      {budget.cost_front == 'Equipamentos' ||
                                      budget.cost_front == 'Tributações' ||
                                      (budget.cost_front != 'Desconto' &&
                                        proposal.nivelLogin > 2) ? (
                                        ''
                                      ) : (
                                        <EditIcon
                                          width="16"
                                          style={{ marginLeft: '8px' }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {(!upXl || isFromProposalList) && (
                            <>
                              {budget.cost_front == 'Tributações' &&
                              editingCost == budget.id ? (
                                <div style={{ display: 'flex' }}>
                                  <CircleActionsCloseIcon
                                    onClick={() => setEditingCost('')}
                                    width="16"
                                    color={palette.grayscale[800]}
                                    style={{
                                      cursor: 'pointer',
                                      marginRight: '8px',
                                    }}
                                  />
                                  <Input
                                    label="Porcentagem"
                                    adornmentPosition="right"
                                    adornment="%"
                                    defaultValue={budget.percentage_value}
                                    type="number"
                                    style={{ minWidth: '100px' }}
                                    onBlur={(e) =>
                                      handleCreateTributation(
                                        budget,
                                        e.target.value,
                                      )
                                    }
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    cursor:
                                      budget.cost_front == 'Tributações' &&
                                      'pointer',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                  }}
                                  onClick={() => {
                                    if (
                                      budget.cost_front == 'Tributações' &&
                                      proposal.nivelLogin <= 2
                                    ) {
                                      setEditingCost(budget.id)
                                    }
                                  }}
                                >
                                  <Typography
                                    type="text_small"
                                    color="grayscale"
                                    colorWeight="600"
                                  >
                                    {budget.percentage_value}%
                                  </Typography>
                                  {budget.cost_front == 'Tributações' &&
                                    proposal.nivelLogin <= 2 && (
                                      <EditIcon
                                        width="16"
                                        style={{ marginLeft: '8px' }}
                                      />
                                    )}
                                </div>
                              )}
                            </>
                          )}
                        </TableCell>
                        {!downLg && !isFromProposalList && (
                          <TableCell
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              padding: '6px',
                            }}
                          >
                            {budget.cost_front == 'Tributações' &&
                            editingCost == budget.id ? (
                              <div style={{ display: 'flex' }}>
                                <CircleActionsCloseIcon
                                  onClick={() => setEditingCost('')}
                                  width="16"
                                  color={palette.grayscale[800]}
                                  style={{
                                    cursor: 'pointer',
                                    marginRight: '8px',
                                  }}
                                />
                                <Input
                                  label="Porcentagem"
                                  adornmentPosition="right"
                                  adornment="%"
                                  defaultValue={budget.percentage_value}
                                  type="number"
                                  style={{ minWidth: '100px' }}
                                  onBlur={(e) =>
                                    handleCreateTributation(
                                      budget,
                                      e.target.value,
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  cursor:
                                    budget.cost_front == 'Tributações' &&
                                    'pointer',
                                }}
                                onClick={() => {
                                  if (
                                    budget.cost_front == 'Tributações' &&
                                    proposal.nivelLogin <= 2
                                  ) {
                                    setEditingCost(budget.id)
                                  }
                                }}
                              >
                                <Typography
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="600"
                                >
                                  {budget.percentage_value}%
                                </Typography>
                                {budget.cost_front == 'Tributações' &&
                                  proposal.nivelLogin <= 2 && (
                                    <EditIcon
                                      width="16"
                                      style={{ marginLeft: '8px' }}
                                    />
                                  )}
                              </div>
                            )}
                          </TableCell>
                        )}
                        {!downLg && !isFromProposalList && (
                          <TableCell
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              padding: '6px',
                            }}
                          >
                            <Typography
                              type="text_small"
                              color="grayscale"
                              colorWeight="600"
                            >
                              {parseFloat(budget.price_kwp).toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                },
                              )}
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                      {handleLastExtra(
                        budgets.sort(compareItems).sort(isEquipments),
                      ) &&
                        handleLastExtra(
                          budgets.sort(compareItems).sort(isEquipments),
                        ).cost_front_slug == budget.cost_front_slug && (
                          <>
                            <div ref={refCustomCost} />
                            {activeCustom && (
                              <TableRow
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                  borderBottom: '1px solid #e2e6e7',
                                  height: '60px',
                                }}
                                key={`${budget.costFront + i}a${
                                  budget.total_value
                                }`}
                              >
                                {!upXl ? (
                                  <TableCell
                                    colspan="4"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      padding: '6px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <div style={{ display: 'flex' }}>
                                      <CircleActionsCloseIcon
                                        onClick={() => setActiveCustom(false)}
                                        width="16"
                                        color={palette.grayscale[800]}
                                        style={{
                                          cursor: 'pointer',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <Spacer size="8" direction="vertical" />
                                      <Input
                                        onBlur={() =>
                                          handleCreateBudgetCustom()
                                        }
                                        value={newFrontName}
                                        setValue={setNewFrontName}
                                        size="small"
                                        fullWidth
                                        placeholder="Título"
                                      />
                                    </div>
                                    <Input
                                      style={{ marginTop: '8px' }}
                                      value={newFrontValue}
                                      setValue={setNewFrontValue}
                                      onBlur={() => handleCreateBudgetCustom()}
                                      size="small"
                                      fullWidth
                                      currencyFormat
                                      placeholder="Valor"
                                      type={downSm ? 'tel' : 'text'}
                                    />
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        padding: '6px',
                                        display: 'flex',
                                      }}
                                    >
                                      <CircleActionsCloseIcon
                                        onClick={() => setActiveCustom(false)}
                                        width="16"
                                        color={palette.grayscale[800]}
                                        style={{
                                          cursor: 'pointer',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <Input
                                        onBlur={() =>
                                          handleCreateBudgetCustom()
                                        }
                                        value={newFrontName}
                                        setValue={setNewFrontName}
                                        size="small"
                                        placeholder="Título"
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        padding: '6px',
                                      }}
                                    >
                                      <Input
                                        value={newFrontValue}
                                        setValue={setNewFrontValue}
                                        onBlur={() =>
                                          handleCreateBudgetCustom()
                                        }
                                        submit={() => {}}
                                        size="small"
                                        currencyFormat
                                        placeholder="Valor"
                                        type={downSm ? 'tel' : 'text'}
                                      />
                                    </TableCell>
                                    {!downLg && !isFromProposalList && (
                                      <>
                                        <TableCell
                                          style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '6px',
                                          }}
                                        >
                                          {budget.percentage_value}%
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '6px',
                                          }}
                                        >
                                          {parseFloat(
                                            budget.price_kwp,
                                          ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}
                                        </TableCell>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            )}
                            {!activeCustom &&
                              budgets.find(
                                (budget) => budget.is_custom == 1,
                              ) && (
                                <TableRow
                                  style={{
                                    border: 'none',
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #e2e6e7',
                                    height: '40px',
                                  }}
                                  key="add"
                                >
                                  <TableCell
                                    colspan="4"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      padding: '6px',
                                    }}
                                  >
                                    <Button
                                      noMargin
                                      size="small"
                                      fullWidth
                                      variant="text"
                                      onClick={() => {
                                        setActiveCustom(true)
                                        handleClickCustomCost()
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <ActionPlusIcon width="16" />
                                        <Spacer
                                          size="12"
                                          direction="vertical"
                                        />
                                        Adicionar custos extras
                                      </div>
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )}
                          </>
                        )}
                      {!budgets.find((budget) => budget.is_custom == 1) &&
                        budget.cost_front == 'Equipamentos' && (
                          <>
                            <div ref={refCustomCost} />
                            {activeCustom && (
                              <TableRow
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                  borderBottom: '1px solid #e2e6e7',
                                  height: '60px',
                                }}
                                key={`${budget.costFront + i}a${
                                  budget.total_value
                                }`}
                              >
                                {!upXl || isFromProposalList ? (
                                  <TableCell
                                    colspan="4"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      padding: '6px',
                                    }}
                                  >
                                    <div style={{ display: 'flex' }}>
                                      <CircleActionsCloseIcon
                                        onClick={() => setActiveCustom(false)}
                                        width="16"
                                        color={palette.grayscale[800]}
                                        style={{
                                          cursor: 'pointer',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <Spacer size="8" direction="vertical" />
                                      <Input
                                        onBlur={() =>
                                          handleCreateBudgetCustom()
                                        }
                                        value={newFrontName}
                                        setValue={setNewFrontName}
                                        size="small"
                                        fullWidth
                                        placeholder="Título"
                                      />
                                    </div>
                                    <Input
                                      style={{ marginTop: '8px' }}
                                      value={newFrontValue}
                                      setValue={setNewFrontValue}
                                      onBlur={(e) => handleCreateBudgetCustom()}
                                      submit={() => {}}
                                      size="small"
                                      fullWidth
                                      currencyFormat
                                      placeholder="Valor"
                                      type={downSm ? 'tel' : 'text'}
                                    />
                                  </TableCell>
                                ) : (
                                  <>
                                    <TableCell
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        padding: '6px',
                                      }}
                                    >
                                      <div style={{ display: 'flex' }}>
                                        <CircleActionsCloseIcon
                                          onClick={() => setActiveCustom(false)}
                                          width="16"
                                          color={palette.grayscale[800]}
                                          style={{
                                            cursor: 'pointer',
                                            marginRight: '8px',
                                          }}
                                        />
                                        <Input
                                          onBlur={() =>
                                            handleCreateBudgetCustom()
                                          }
                                          value={newFrontName}
                                          setValue={setNewFrontName}
                                          size="small"
                                          placeholder="Título"
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        padding: '6px',
                                      }}
                                    >
                                      <Input
                                        value={newFrontValue}
                                        setValue={setNewFrontValue}
                                        onBlur={() =>
                                          handleCreateBudgetCustom()
                                        }
                                        currencyFormat
                                        placeholder="Valor"
                                      />
                                    </TableCell>
                                    {!isFromProposalList && (
                                      <Hidden lgDown>
                                        <TableCell
                                          style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '6px',
                                          }}
                                        >
                                          {budget.percentage_value}%
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '6px',
                                          }}
                                        >
                                          {parseFloat(
                                            budget.price_kwp,
                                          ).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}
                                        </TableCell>
                                      </Hidden>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            )}
                          </>
                        )}
                    </>
                  ))}
                {!budgets.find((budget) => budget.is_custom == 1) &&
                  !activeCustom &&
                  proposal.nivelLogin <= 2 && (
                    <TableRow
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        borderBottom: '1px solid #e2e6e7',
                        height: '60px',
                      }}
                      key="z"
                    >
                      <TableCell
                        colspan="4"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          padding: '6px',
                        }}
                      >
                        <Button
                          noMargin
                          size="small"
                          fullWidth
                          onClick={() => {
                            setActiveCustom(true)
                            handleClickCustomCost()
                          }}
                          isLoading={
                            loadingCreateBudget[0] &&
                            loadingCreateBudget[1] == 'CustomTitle'
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              width: '100%',
                            }}
                          >
                            <ActionPlusIcon width="16" />
                            <Spacer size="12" direction="vertical" />
                            Adicionar custos extras
                          </div>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                {!budgets.find(
                  (budget) => budget.cost_front == 'Mão de obra',
                ) &&
                !budgets.find((budget) => budget.cost_front == 'Mão de Obra') &&
                proposal.nivelLogin <= 2 ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="c"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateBudget('Mão de obra')
                        }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          (loadingCreateBudget[1] == 'Mão de obra' ||
                            loadingCreateBudget[1] == 'Mão de Obra')
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar mão de obra
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {!budgets.find(
                  (budget) => budget.cost_front == 'Operação e manutenção',
                ) && proposal.nivelLogin <= 2 ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="d"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateBudget('Operação e manutenção')
                        }}
                        style={{ textAlign: 'start' }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          loadingCreateBudget[1] == 'Operação e manutenção'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar custos de operação e manutenção
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {!budgets.find((budget) => budget.cost_front == 'Margem') &&
                proposal.nivelLogin <= 2 ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="e"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateBudget('Margem')
                        }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          loadingCreateBudget[1] == 'Margem'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar margem
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {!budgets.find(
                  (budget) => budget.cost_front == 'Margem de representante',
                ) && proposal.nivelLogin <= 2 ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="f"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateBudget('Margem de representante')
                        }}
                        style={{ textAlign: 'start' }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          loadingCreateBudget[1] == 'Margem de representante'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar margem de representante
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {!budgets.find((budget) => budget.cost_front == 'Desconto') ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="g"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateBudget('Desconto')
                        }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          loadingCreateBudget[1] == 'Desconto'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar desconto
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {!budgets.find(
                  (budget) => budget.cost_front == 'Tributações',
                ) && proposal.nivelLogin <= 2 ? (
                  <TableRow
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      borderBottom: '1px solid #e2e6e7',
                      height: '60px',
                    }}
                    key="k"
                  >
                    <TableCell
                      colspan="4"
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: '6px',
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleCreateTributation('Tributações')
                        }}
                        noMargin
                        size="small"
                        variant="outlined"
                        fullWidth
                        isLoading={
                          loadingCreateBudget[0] &&
                          loadingCreateBudget[1] == 'Tributações'
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <ActionPlusIcon width="16" />
                          <Spacer size="12" direction="vertical" />
                          Adicionar tributações
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Spacer size="48" direction="horizontal" />
      </Grid>
    </Grid>
  )
}

export default OrcamentoPanel
