import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Page, FinancialLabel } from 'components'
import {
  FilledSaveFavoriteBookmark,
  SaveFavoriteBookmarkIcon,
} from '@77sol/icons/dist'
import { LabelledDropdown, useToast } from '@77sol/core'
import palette from 'app_palette'
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { ProposalsContainer } from 'containers'
import { proposalFilterList, formatDate } from 'utils'
import API from 'api'
import styles from './styles'

const formatRows = (proposals, handleStatusChange, handleFavorite) =>
  proposals.map((proposal) => {
    const { is_favorite, id, status, created_at, financing_status } = proposal

    return {
      favorite: {
        type: 'component',
        Component: () => (
          <IconButton
            onClick={() => {
              handleFavorite(id)
            }}
          >
            {is_favorite ? (
              <FilledSaveFavoriteBookmark
                width="24px"
                color={palette.yellow[400]}
              />
            ) : (
              <SaveFavoriteBookmarkIcon
                width="24px"
                color={palette.grayscale[300]}
              />
            )}
          </IconButton>
        ),
      },
      id: `#${id}`,
      dimensionings: proposal.dimensionings_count,
      quotations: proposal.quotation_total,
      status: {
        type: 'component',
        Component: (props) => (
          <LabelledDropdown
            {...props}
            size="large"
            initialValue={status || '1'}
            options={proposalFilterList}
            onChange={(value) => handleStatusChange(value, id)}
          />
        ),
      },
      created_at: formatDate(created_at),
      financing: <FinancialLabel status={financing_status} />,
      rowData: {
        ...proposal,
      },
    }
  })

const getProposals = async (rowId, params = {}) => {
  try {
    const response = await API.get(`/project/list/proposals/${rowId}`, {
      params,
    })

    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const getProposalStructureToEdit = async (proposalToken) => {
  try {
    const { data } = await API.get(`/proposal/detail/${proposalToken}`)

    return data
  } catch (e) {
    console.log(e)
  }
}

const getFinancingSimulation = async (proposalId) => {
  try {
    const {
      data: { detail: financing },
    } = await API.get(`/financing/all/${proposalId}`)

    return financing
  } catch (e) {
    console.log(e)
  }
}

function ProjectProposals() {
  const [projectProposals, setProjectProposals] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [shouldUpdateFavorite, setShouldUpdateFavorite] = useState(false)
  const [currentProposalActive, setCurrentProposalActive] = useState('')

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const classes = styles()
  const location = useLocation()

  const { customerName = '', selectedProposalId = '' } = location?.state || {}

  const projectId =
    location?.state?.projectId || sessionStorage.getItem('@77sol:projectId')

  const theme = useTheme()
  const desktopBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (shouldUpdateFavorite) {
      const newProjectProposals = [...projectProposals]
      const proposalIndex = newProjectProposals.findIndex(
        ({ rowData: { id } }) => id === shouldUpdateFavorite,
      )
      const toggleIsFavorite =
        !newProjectProposals[proposalIndex].rowData.is_favorite

      newProjectProposals[proposalIndex] = {
        ...newProjectProposals[proposalIndex],
        favorite: {
          type: 'component',
          Component: () => (
            <IconButton
              onClick={() => {
                handleFavorite(shouldUpdateFavorite)
              }}
            >
              {toggleIsFavorite ? (
                <FilledSaveFavoriteBookmark
                  width="24px"
                  color={palette.yellow[400]}
                />
              ) : (
                <SaveFavoriteBookmarkIcon
                  width="24px"
                  color={palette.grayscale[300]}
                />
              )}
            </IconButton>
          ),
        },
        rowData: {
          ...newProjectProposals[proposalIndex].rowData,
          is_favorite: toggleIsFavorite,
        },
      }

      setProjectProposals(newProjectProposals)
      setShouldUpdateFavorite(false)
    }
  }, [shouldUpdateFavorite, projectProposals])

  const searchProposals = async (params) => {
    setIsLoading(true)
    const response = await getProposals(projectId, params)
    setIsLoading(false)

    const formatedResponse = await formatRows(
      response.proposals,
      handleStatusChange,
      handleFavorite,
    )

    const selectedProposalDetails = formatedResponse.find(
      (response) => response.rowData.id === selectedProposalId,
    )
    setCurrentProposalActive(selectedProposalDetails)
    setProjectProposals(formatedResponse)
    return formatedResponse
  }

  const handleStatusChange = async (status, id) => {
    const proposalStatus = proposalFilterList.find(
      ({ value }) => value === status,
    )
    try {
      await API.post('/proposal/status/', {
        status: proposalStatus.slug,
        id,
      })

      onSuccessOpenToast(
        `Proposta ${id} atualizada para status ${proposalStatus.label}.`,
      )
    } catch (e) {
      onErrorOpenToast(`Não foi possível atualizar a proposta ${id}.`)
    }
  }

  const handleFavorite = async (proposalId) => {
    try {
      const response = await API.put(`/proposal/favorite/${proposalId}`, {})

      setShouldUpdateFavorite(proposalId)
      const isFavorited = response?.data?.message === 'favorite proposal'

      onSuccessOpenToast(
        `Proposta #${proposalId} ${
          isFavorited ? 'favoritada' : 'desfavoritada'
        }.`,
      )
    } catch (e) {
      onErrorOpenToast(
        `Não foi possível favoritar ou desfavoritar a proposta ${proposalId}. Tente novamente.`,
      )
      setShouldUpdateFavorite(false)
    }
  }

  const title = desktopBreakpoint
    ? `Projeto #${projectId} - ${customerName}`
    : `Projeto #${projectId}`

  return (
    <Page
      className={classes.root}
      title={currentEnterpriseConfigs.titlePage('Propostas')}
    >
      <ProposalsContainer
        getFinancingSimulation={getFinancingSimulation}
        getProposalStructureToEdit={getProposalStructureToEdit}
        handleFavorite={handleFavorite}
        isLoading={isLoading}
        searchProposals={searchProposals}
        proposals={projectProposals}
        title={title}
        selectedProposal={currentProposalActive}
      />
    </Page>
  )
}

export default ProjectProposals
