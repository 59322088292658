import useWindowSize from 'hooks/useWindowSize'
import { useCallback, useState } from 'react'

export function useTooltip() {
  const [open, setOpen] = useState(false)
  const { width } = useWindowSize()

  const isMobile = width < 768

  const containerElement = document.getElementById(
    'create-financing-modal-wrapper',
  )

  const handleOpenByClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (open) {
        e.preventDefault()
      }
      setOpen(true)
    },
    [open],
  )

  const handleCloseByOutsideClick = useCallback(
    (event: Event) => {
      if (!open) {
        event.preventDefault()
      }
      setOpen(false)
    },
    [open],
  )

  const closeTooltipWhenScrolling = useCallback(() => {
    if (open) {
      setOpen(false)
    }
  }, [open])

  return {
    containerElement,
    closeTooltipWhenScrolling,
    open,
    setOpen,
    isMobile,
    handleOpenByClick,
    handleCloseByOutsideClick,
  }
}
