import { useQuery } from '@tanstack/react-query'
import { document } from '../services/api'
import { cnpj as CnpjValidator } from 'cpf-cnpj-validator'
import { GET_CNPJ_INFO } from '../constants/endpointKeys'
import { type IDocumentPayload } from '../services/types'

const canEnableRequest = (payload: IDocumentPayload, options?: any) => {
  const cnpj = payload.document.replace(/[^\w ]/g, '')
  return Boolean(CnpjValidator.isValid(cnpj) && options?.enabled)
}

export const useFetchCnpjInfo = (payload: IDocumentPayload, options?: any) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [GET_CNPJ_INFO, payload],
    queryFn: async () => await document.get(payload),
    enabled: canEnableRequest(payload, options),
    retry: false,
  })

  return { documentInfo: data, isLoading, isFetchingDocument: isFetching }
}
