import { type ISlideProps, Notification } from '@77sol-ui/organisms'
import { NotificationButton } from '../components/NotificationButton'
import { NotificationsList } from '../components/NotificationsList'
import { useOpenNotification } from '../hooks/useOpenNotification'

export interface INotificationSlideProps {
  onClickNotification?: () => void
  onOpenedSuccess?: () => void
}

export function Slide({
  onClickNotification,
  onOpenedSuccess,
  ...props
}: INotificationSlideProps & ISlideProps) {
  const { handleToggle, notificationsOpen } = useOpenNotification({
    onOpenedSuccess,
  })

  return (
    <Notification.Slide
      open={notificationsOpen}
      onOpenChange={handleToggle}
      trigger={<NotificationButton />}
      rounded={false}
      maxWidth="100vw"
      maxHeight="100dvh"
      {...props}
    >
      <NotificationsList onClickNotification={onClickNotification} />
    </Notification.Slide>
  )
}
