import { track, Identify, identify } from 'services/tracker/setup'
import { useSelector } from 'react-redux'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'

export const useAmplitude = () => {
  const { userProfile, isProPlan } = useSelector(
    (state) => state.ReducerProfile,
  )
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)

  const logEvent = (key, properties = {}) => {
    const identifyObj = new Identify()
    identifyObj.set('User Enterprise', currentEnterpriseConfigs.enterpriseName)
    identifyObj.set('User Email', userProfile?.email)
    identifyObj.set('User Type', userLogged?.user_type)
    identifyObj.set('User Role', userProfile?.display_role)
    identifyObj.set('User Plan', isProPlan ? 'PRO' : 'FREE')

    identify(identifyObj)
    track(key, properties)
  }

  const logEventError = (key, { error, info }, properties = {}) => {
    const identifyObj = new Identify()

    identifyObj.set('Error Title', String(error))
    identifyObj.set('Error Message', String(info.componentStack))

    identify(identifyObj)

    track(key, properties)
  }

  return {
    logEvent,
    logEventError,
  }
}
