import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  typography: ({ color, colorWeight }) => ({
    color: color === 'white' ? '#FFF' : palette[color][colorWeight],
  }),
  display_huge: {
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '1px',
    fontWeight: 'normal',
  },
  display_large: {
    fontSize: '50px',
    lineHeight: '64px',
    letterSpacing: '1px',
    fontWeight: 'normal',
  },
  display_medium: {
    fontSize: '40px',
    lineHeight: '56px',
    letterSpacing: '1px',
    fontWeight: 'normal',
  },
  display_small: {
    fontSize: '33px',
    lineHeight: '40px',
    letterSpacing: '1px',
    fontWeight: 'normal',
  },
  display_x_small: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '1px',
    fontWeight: 'normal',
  },
  display_huge_bold: {
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '1px',
    fontWeight: 800,
  },
  display_large_bold: {
    fontSize: '50px',
    lineHeight: '64px',
    letterSpacing: '1px',
    fontWeight: 800,
  },
  display_medium_bold: {
    fontSize: '40px',
    lineHeight: '56px',
    letterSpacing: '1px',
    fontWeight: 800,
  },
  display_small_bold: {
    fontSize: '33px',
    letterSpacing: '1px',
    lineHeight: '40px',
    fontWeight: 800,
  },
  display_x_small_bold: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '1px',
    fontWeight: 800,
  },
  display_huge_x_bold: {
    fontSize: '60px',
    lineHeight: '72px',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  display_large_x_bold: {
    fontSize: '50px',
    lineHeight: '64px',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  display_medium_x_bold: {
    fontSize: '40px',
    lineHeight: '56px',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  display_small_x_bold: {
    fontSize: '33px',
    lineHeight: '40px',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  display_x_small_x_bold: {
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '1px',
    fontWeight: 700,
  },
  text_large: {
    fontSize: '20px',
    lineHeight: '38px',
    letterSpacing: '0.75px',
    fontWeight: 'normal',
  },
  text_medium: {
    fontSize: '17px',
    lineHeight: '34px',
    letterSpacing: '0.75px',
    fontWeight: 'normal',
  },
  text_small: {
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '0.75px',
    fontWeight: 'normal',
  },
  text_x_small: {
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.25px',
    fontWeight: 'normal',
  },
  link_large: {
    fontSize: '20px',
    lineHeight: '38px',
    letterSpacing: '0.75px',
    fontWeight: 700,
  },
  link_medium: {
    fontSize: '17px',
    lineHeight: '34px',
    letterSpacing: '0.75px',
    fontWeight: 700,
  },
  link_small: {
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '0.75px',
    fontWeight: 700,
  },
  link_x_small: {
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.25px',
    fontWeight: 700,
  },
}))

export default styles
