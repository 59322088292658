export function useChangeProduct({ product, quotationId, onChangeProduct }) {
  async function handleChangeProduct(productChanged) {
    const quotation = {
      quotation: { id: quotationId },
    }
    await onChangeProduct(productChanged, product, quotation, product.qtde)
  }

  return {
    handleChangeProduct,
  }
}
