import { lojaMeuEndereco } from '../services/api'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'services/constant'

export const useLojaMeuEndereco = () => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [QUERY_KEYS.LOJA.MEU_ENDERECO],
    queryFn: async () => await lojaMeuEndereco.get(),
    staleTime: Infinity,
  })

  return { myAddressData: data, isLoading, isSuccess, isError }
}
