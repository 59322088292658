import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const StyledInputQuantity = styled.div`
  position: relative;

  .minus {
    top: 9px;
    left: 5px;
  }

  .plus {
    top: 9px;
    right: 5px;
  }

  input {
    padding-left: 32px;
    padding-right: 28px;
    text-align: center;
  }
`

export const StyledQuantityButton = styled.button`
  position: absolute;
  z-index: 2;
  padding: 4px;
  color: ${colors.gray[600]};

  &:disabled {
    cursor: not-allowed;
  }
`
