import { Tooltip } from '@77sol/core'
import { Pencil } from 'lucide-react'
import { useEditQuotationButton } from './hooks/useEditQuotationButton'

interface EditQuotationButtonProps {
  quotationId: number
}

export function EditQuotationButton({ quotationId }: EditQuotationButtonProps) {
  const { handleEditQuotation } = useEditQuotationButton({ quotationId })

  return (
    <Tooltip title="Editar cotação">
      <button onClick={handleEditQuotation}>
        <Pencil />
      </button>
    </Tooltip>
  )
}
