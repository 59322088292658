import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useToast } from '@77sol/core'
import {
  type UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { type AxiosResponse } from 'axios'
import API from 'api'
import { type FormState } from 'react-hook-form'

import {
  type IFilledFieldsState,
  type IPendingFields,
} from '../OrderPendingsTab.types'
import { GET_ORDERS } from 'domains/loja/loja-pedidos/constants/endpointKeys'
import { PAYMENT_TYPES_THAT_NEED_BILLING_LETTER } from '../constants/paymentTypes'
import { GET_SIGNALS } from 'domains/user/signal/constants/endpointKeys'

interface PendingOrderFieldsData {
  orderPendingFieldsMutation: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    FormData,
    unknown
  >
  validateIfAllFieldsAreFilled: (fields: IPendingFields) => boolean
  validatefilledFieldsState: (
    fields: IPendingFields,
    formState: FormState<IPendingFields>,
    completed: boolean,
  ) => void
  setOrderPendingInfosFieldState: (state: IFilledFieldsState) => void
  orderPendingInfosFieldState: IFilledFieldsState

  calcInitialNfValueToCompare: (
    initialNfValue: number,
    cardFee: number,
    newNfValue: string,
  ) => { hasChangedNfValue: boolean; initialNfValueWithoutTax: number }
}

interface PendingOrderFieldsProviderProps {
  children: ReactNode
}

export const PendingOrderFieldsContext = createContext<PendingOrderFieldsData>(
  {} as PendingOrderFieldsData,
)

export function PendingOrderFieldsProvider({
  children,
}: PendingOrderFieldsProviderProps) {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const queryClient = useQueryClient()

  const [orderPendingInfosFieldState, setOrderPendingInfosFieldState] =
    useState({ all: false, some: false, alreadySentToReview: false })

  const orderPendingFieldsMutation = useMutation({
    mutationFn: async (data: FormData) =>
      await API.post('loja/send-pending-data', data),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([GET_ORDERS]),
        queryClient.invalidateQueries([GET_SIGNALS]),
      ])

      onSuccessOpenToast(
        orderPendingInfosFieldState?.all
          ? 'Pedido enviado para análise!'
          : 'Campos atualizados com sucesso!',
      )

      setOrderPendingInfosFieldState({
        all: false,
        some: false,
        alreadySentToReview: false,
      })
    },
    onError: () =>
      onErrorOpenToast(
        'Houve um erro ao salvar todos os campos. Tente novamente',
      ),
  })

  const validateIfAllFieldsAreFilled = useCallback((fields: IPendingFields) => {
    const canShowBillingLetterField =
      PAYMENT_TYPES_THAT_NEED_BILLING_LETTER.includes(fields.payment_type)

    const hasAddressPaymentFile = Boolean(
      fields?.address_payment?.file !== null ||
        fields?.address_payment?.name_file !== null,
    )

    const hasBillingLetterFile = Boolean(
      fields?.billing_letter?.file ?? fields?.billing_letter?.name_file,
    )

    const formNeedsFinancialInstitution =
      fields?.payment_type === 'consorcio' ||
      fields?.payment_type === 'financiamento_77sol' ||
      fields?.payment_type === 'financiamento_cliente'

    const paymentFormIsAllFilled = formNeedsFinancialInstitution
      ? Boolean(fields?.financial_institution)
      : true

    const billingLetterIsValid =
      (!canShowBillingLetterField && !hasBillingLetterFile) ||
      (canShowBillingLetterField && hasBillingLetterFile)

    const allFieldsAreFilled =
      hasAddressPaymentFile &&
      fields?.nf_origin !== '' &&
      Boolean(fields?.nf_value) &&
      fields?.payment_type !== '' &&
      paymentFormIsAllFilled &&
      billingLetterIsValid

    setOrderPendingInfosFieldState((oldState) => ({
      ...oldState,
      all: allFieldsAreFilled,
    }))

    return allFieldsAreFilled
  }, [])

  const validatefilledFieldsState = useCallback(
    (
      fields: IPendingFields,
      formState: FormState<IPendingFields>,
      completed: boolean,
    ) => {
      const isAllFieldsFilled = validateIfAllFieldsAreFilled(fields)
      const hasSomeDirtyField = Object.keys(formState.dirtyFields).length > 0

      setOrderPendingInfosFieldState({
        all: isAllFieldsFilled,
        some: hasSomeDirtyField,
        alreadySentToReview: completed,
      })
    },
    [validateIfAllFieldsAreFilled],
  )

  function calcInitialNfValueToCompare(
    initialNfValue: number,
    cardFee: number,
    newNfValue: string,
  ) {
    const initialNfValueWithoutCardFee = Number(initialNfValue) - cardFee
    const parsedNewNfValue = parseFloat(newNfValue)
    const hasChangedNfValue = initialNfValueWithoutCardFee !== parsedNewNfValue
    return {
      hasChangedNfValue,
      initialNfValueWithoutTax: initialNfValueWithoutCardFee,
    }
  }

  const values = useMemo(
    () => ({
      orderPendingFieldsMutation,
      validateIfAllFieldsAreFilled,
      validatefilledFieldsState,
      setOrderPendingInfosFieldState,
      orderPendingInfosFieldState,
      calcInitialNfValueToCompare,
    }),
    [
      validateIfAllFieldsAreFilled,
      validatefilledFieldsState,
      setOrderPendingInfosFieldState,
      orderPendingInfosFieldState,
      calcInitialNfValueToCompare,
    ],
  )

  return (
    <PendingOrderFieldsContext.Provider value={values}>
      {children}
    </PendingOrderFieldsContext.Provider>
  )
}

export const usePendingOrderFields = () => useContext(PendingOrderFieldsContext)
