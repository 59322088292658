import { format } from 'date-fns'

const AVAILABLE_MESSAGE = 'Disponível em estoque'
const AVAILABILITY_MESSAGE = 'Previsão de disponibilidade: '

function splitName(name: string, available?: string | null) {
  const [productName, availability] = String(name).split(' - Disp. em estoque:')

  const availableDate = available
    ? format(new Date(available), 'dd/MM/yyyy')
    : availability

  return {
    productName,
    availableDate,
  }
}

export function separateProductName(name: string, available?: string | null) {
  const { productName, availableDate } = splitName(name, available)

  const availabilityMessage = availableDate
    ? AVAILABILITY_MESSAGE.concat(availableDate)
    : AVAILABLE_MESSAGE

  return {
    productName,
    availabilityMessage,
  }
}

export function formatProductName(name: string, available?: string | null) {
  const { productName, availableDate } = splitName(name, available)

  if (availableDate) {
    return `${productName} - ${AVAILABILITY_MESSAGE.concat(availableDate)}`
  }

  return productName
}
