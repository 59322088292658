import api from 'api'

import {
  type IGetFinancingPerOriginRequest,
  type IGetFinancingPerOriginResponse,
} from './types'
import { FINANCING_PER_ORIGIN } from '../constants/endpointKeys'

export const getFinancingPerOrigin = async ({
  financingId,
}: IGetFinancingPerOriginRequest) => {
  return await api.get<IGetFinancingPerOriginResponse>(FINANCING_PER_ORIGIN, {
    params: {
      origin: 'cotacao',
      id_origin: Number(financingId),
    },
  })
}
