import styled from 'styled-components'

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Content = styled.div`
  display: flex;
  gap: 16px;
`

export const Image = styled.div`
  max-width: 72px;
  max-height: 72px;
`

export const Mobile = styled.div`
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 16px;
    background-color: #073ef8;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }
  }

  .infos {
    padding: 16px;
  }
`
