import React from 'react'

/** Material UI */
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import palette from '../../app_palette'

/** Assets */
import night from './night.svg'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.primary[300],
  },
  title: {
    // fontFamily: 'NexaBold',
    color: '#FFF',
    fontSize: '40px',
    textAlign: 'center',
  },
  subtitle: {
    // fontFamily: 'NexaBold',
    color: '#FFF',
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 300,
  },
  icon: {
    width: '100px',
    marginBottom: '20px',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  href: {
    textDecoration: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #FFF',
    color: '#FFF',
    marginTop: '20px',
    textTransform: 'none',
    fontFamily: 'NexaBold',
    fontSize: '16px',
  },
}))

function UnderMaintenance({ title }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid direction="column" container alignItems="center">
          <img alt="Lua" className={classes.icon} src={night} />
          <Typography className={classes.title} variant="h6">
            {title || 'Estamos em manutenção'}
          </Typography>
          <Typography className={classes.subtitle} variant="h6">
            Voltaremos em breve.
          </Typography>
        </Grid>
      </div>
    </div>
  )
}

export default UnderMaintenance
