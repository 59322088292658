import OrderFiltersForm from './components/OrderFiltersForm'
import { Button } from '@77sol/core'
import * as S from './styles'
import { FilterIcon } from '@77sol/icons/dist'

interface OrderFiltersProps {
  isOpenFilterSlide: boolean
  setIsOpenFilterSlide: (state: boolean) => void
}

const OrderFilters: React.FC<OrderFiltersProps> = ({
  isOpenFilterSlide,
  setIsOpenFilterSlide,
}) => {
  return (
    <S.FiltersContainer isOpenSlide={isOpenFilterSlide}>
      <OrderFiltersForm />
      <div className="mobile-filter">
        <Button
          variant="text"
          startIcon={<FilterIcon />}
          onClick={() => {
            setIsOpenFilterSlide(true)
          }}
        >
          Filtrar
        </Button>
      </div>
    </S.FiltersContainer>
  )
}

export default OrderFilters
