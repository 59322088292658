import React, { useCallback } from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { RoomServiceHelpIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'

export default function Icons({ onClick }) {
  const { logEvent } = useAmplitude()

  const handleReserveEquipment = useCallback(() => {
    logEvent(ordersTracker.actions.reserveEquipment, {
      origin: 'listagem de financiamentos',
      tab: 'Cotação',
    })
    onClick('buy')
  }, [onClick])

  return (
    <Tooltip title={currentEnterpriseConfigs.orderButtonTitle}>
      <IconButton
        aria-label={currentEnterpriseConfigs.orderButtonTitle}
        onClick={handleReserveEquipment}
      >
        <RoomServiceHelpIcon width={24} color={palette.grayscale[700]} />
      </IconButton>
    </Tooltip>
  )
}
