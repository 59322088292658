import { useQuery } from '@tanstack/react-query'
import { PROPOSAL_QUOTATIONS_ALL } from '../constants/endpointKeys'
import { getProposalQuotationAll } from '../services/api'
import {
  type IGetProposalQuotationAllResponse,
  type IGetProposalQuotationAllRequest,
} from '../services/types'

interface IUseProposalQuotationAllProps
  extends IGetProposalQuotationAllRequest {
  onSuccess?: (data: IGetProposalQuotationAllResponse) => void
  onError?: (data: unknown) => void
}

export function useProposalQuotationAll({
  proposalId,
  onSuccess,
  onError,
}: IUseProposalQuotationAllProps) {
  return useQuery({
    queryKey: [PROPOSAL_QUOTATIONS_ALL, proposalId],
    queryFn: async () => {
      return await getProposalQuotationAll({ proposalId })
        .then((response) => {
          const { data } = response
          onSuccess?.(data)
          return data
        })
        .catch((error: unknown) => {
          onError?.(error)
        })
    },
  })
}
