import React from 'react'
import { Modal, Fade, Backdrop } from '@material-ui/core'
import { Button, Spacer, Paper, Typography } from '@77sol/core'
import { SendIcon } from '@77sol/icons/dist'
import { useStyles } from './styles'

export function ModalMoreOptions({
  modalMoreOptions,
  setModalMoreOptions,
  setSendModalStepFour,
  setPropostaStep,
}) {
  const classes = useStyles()

  const handleEditProposal = () => {
    setPropostaStep('structuringProposal')
    setModalMoreOptions(false)
  }

  const handleSendProposal = () => {
    setSendModalStepFour(true)
    setModalMoreOptions(false)
  }

  return (
    <Modal
      className={classes.modalSm}
      open={modalMoreOptions}
      onClose={() => setModalMoreOptions(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalMoreOptions} style={{ border: 'none' }}>
        <Paper style={{ height: 'auto', width: 'auto' }}>
          <Typography size="xsmall" color="primary" colorWeight="700">
            Como deseja prosseguir com sua proposta?
          </Typography>
          <Spacer size="24" direction="horizontal" />
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button
              id="stepFour_button_editProposal"
              onClick={() => handleEditProposal()}
              size="small"
              variant="outlined"
              style={{ padding: '15px 22px' }}
            >
              Editar proposta
            </Button>
            <Spacer size="8" direction="both" />
            <Button
              id="stepFour_button_sendProposal"
              onClick={() => handleSendProposal()}
              size="small"
              startIcon={<SendIcon />}
            >
              Enviar
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
