import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialTextField from '@material-ui/core/TextField'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './styles'

function Input({
  size,
  adornment,
  adornmentPosition,
  hint,
  validationState,
  clearble,
  setValue,
  children,
  loading,
  submit,
  currencyFormat,
  select,
  label,
  minDate,
  maxDate,
  ...props
}) {
  const classes = styles({
    size, validationState, label, adornmentPosition,
  })
  const InputClass = classNames(classes.input)

  const inputPropsDefault = {
    autoComplete: 'new-password',
    disableUnderline: true,
    inputProps: {
      min: minDate && minDate,
      max: maxDate && maxDate,
    },
  }

  const inputPropsLeft = {
    autoComplete: 'new-password',
    disableUnderline: true,
    startAdornment: (
      <InputAdornment
        position="start"
        style={{ paddingLeft: '12px', paddingTop: '8px' }}
      >
        {adornmentPosition === 'left' && adornment}
      </InputAdornment>
    ),
    inputProps: {
      min: minDate && minDate,
      max: maxDate && maxDate,
    },
  }

  const inputPropsRight = {
    autoComplete: 'new-password',
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end">
        {adornmentPosition === 'right' && adornment}
        {clearble && props.value ? (
          <CloseRoundedIcon
            onClick={() => setValue('')}
            className={classes.closeadornment}
          />
        ) : (
          ''
        )}
      </InputAdornment>
    ),
    inputProps: {
      min: minDate && minDate,
      max: maxDate && maxDate,
    },
  }

  const inputPropsLoading = {
    autoComplete: 'new-password',
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end">
        {loading ? (
          <CircularProgress
            disableShrink
            style={{ color: '#1335c6', marginRight: select ? '32px' : '0px' }}
            size={16}
            thickness={4}
          />
        ) : (
          ''
        )}
      </InputAdornment>
    ),
    inputProps: {
      min: minDate && minDate,
      max: maxDate && maxDate,
    },
  }

  const handleInputProps = () => {
    if (loading) {
      return inputPropsLoading
    }
    if (clearble || adornmentPosition === 'right') {
      return inputPropsRight
    }
    if (adornmentPosition === 'left') {
      return inputPropsLeft
    }
    if (adornmentPosition === 'none' && !clearble) {
      return inputPropsDefault
    }
  }

  function formatarMoeda(e) {
    let valor = e

    valor += ''
    valor = Number(valor.replace(/[\D]+/g, ''))
    valor += ''
    valor = valor.replace(/([0-9]{2})$/g, ',$1')

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
    }

    if (!valor || valor === '' || Number.isNaN(valor)) {
      return 0
    }
    if (valor.length === 3) {
      if (valor[0] === ',') {
        return `${0}${valor}`
      }
      return valor
    }
    return valor
  }

  return (
    <MaterialTextField
      {...props}
      className={InputClass}
      variant="filled"
      label={label}
      fullWidth
      onChange={(e) => setValue
        && setValue(
          currencyFormat ? formatarMoeda(e.target.value) : e.target.value,
        )}
      helperText={hint}
      select={select}
      autoComplete="off"
      SelectProps={{ native: true }}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          if (submit) {
            submit()
            ev.target.blur()
          }
        }
      }}
      InputProps={handleInputProps()}
    >
      {children}
    </MaterialTextField>
  )
}

Input.propTypes = {
  size: PropTypes.oneOf(['large', 'small']),
  adornment: PropTypes.string,
  adornmentPosition: PropTypes.oneOf(['left', 'right', 'none']),
  hint: PropTypes.string,
  validationState: PropTypes.oneOf(['error', 'success', 'none']),
  clearble: PropTypes.bool,
  setValue: PropTypes.func,
  loading: PropTypes.bool,
  submit: PropTypes.func,
  currencyFormat: PropTypes.bool,
  label: PropTypes.string,
  select: PropTypes.bool,
}

Input.defaultProps = {
  size: 'small',
  label: '',
  adornment: null,
  adornmentPosition: 'none',
  hint: '',
  validationState: 'none',
  clearble: false,
  setValue: null,
  loading: false,
  submit: null,
  currencyFormat: false,
  select: false,
}

export default Input
