import { Modal } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const StyledModalDescriptionWrapper = styled.div`
  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (max-width: 780px) {
    p {
      display: inline-block !important;
    }
  }
`

export const StyledModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 8px;
`
