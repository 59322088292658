import Chart from './components/Chart'
import * as S from './styles'
import { useState } from 'react'
import { PerformanceFilters } from './components/Filters'
import { type ChartFiltersValueEnum } from './components/Filters/constants'
import { type IFilters } from './types'
import { CHART_SUBTITLE, DEFAULT_FILTERS_STATE } from './constants'

export function Performance() {
  const [filters, setFilters] = useState<IFilters>(DEFAULT_FILTERS_STATE)
  const SUBTITLE = CHART_SUBTITLE[filters.chart as ChartFiltersValueEnum]

  return (
    <S.PerformanceContainer>
      <S.PerformanceInfos>
        <S.PerformanceTitle>
          <h3>Gráfico de Performance</h3>
          <p>{SUBTITLE}</p>
        </S.PerformanceTitle>

        <PerformanceFilters filters={filters} setFilters={setFilters} />
      </S.PerformanceInfos>
      <Chart filters={filters} />
    </S.PerformanceContainer>
  )
}
