import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputDescription: {
    marginBottom: '8px',
    fontSize: '14px',

    color: '#707072',
  },
  inputRequired: {
    color: '#FF0000',
    marginLeft: '2px',
  },
})
