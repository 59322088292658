import { makeStyles } from '@material-ui/styles'

const useSyles = makeStyles(() => ({
  infoIcon: {
    cursor: 'pointer',
    margin: '0 10px',
  },
}))

export default useSyles
