export enum PeriodsFiltersValueEnum {
  DIA = 'day',
  SEMANA = 'week',
  MES = 'month',
}

export enum ChartFiltersValueEnum {
  CONVERSAO = 'conversion',
  ORIGINACAO = 'origination',
  SUSTENTABILIDADE = 'sustainability',
}

export const PERIODS_FILTER = [
  {
    label: 'Dia',
    value: PeriodsFiltersValueEnum.DIA,
  },
  {
    label: 'Semana',
    value: PeriodsFiltersValueEnum.SEMANA,
  },
  {
    label: 'Mês',
    value: PeriodsFiltersValueEnum.MES,
  },
]

const DAYS_FILTER = [
  {
    label: '7 dias',
    value: 7,
  },
  {
    label: '30 dias',
    value: 30,
  },
  {
    label: '60 dias',
    value: 60,
  },
  {
    label: '90 dias',
    value: 90,
  },
]

const WEEKS_FILTER = [
  {
    label: '4 sem',
    value: 4,
  },
  {
    label: '8 sem',
    value: 8,
  },
  {
    label: '12 sem',
    value: 12,
  },
  {
    label: '24 sem',
    value: 24,
  },
]

const MONTHS_FILTER = [
  {
    label: '4 meses',
    value: 4,
  },
  {
    label: '8 meses',
    value: 8,
  },
  {
    label: '12 meses',
    value: 12,
  },
]

export const PERIODS_FILTER_ENUM = {
  day: DAYS_FILTER,
  week: WEEKS_FILTER,
  month: MONTHS_FILTER,
}

export const CHARTS_FILTER = [
  {
    label: 'Originação',
    value: ChartFiltersValueEnum.ORIGINACAO,
  },
  {
    label: 'Conversão',
    value: ChartFiltersValueEnum.CONVERSAO,
  },
]

export const CHARTS_FILTER_DISABLED = [
  {
    label: 'Sustentabilidade',
    value: ChartFiltersValueEnum.SUSTENTABILIDADE,
    disabled: true,
  },
]

export const CHART_TOOLTIP = {
  conversion:
    'Gráfico de quantia monetária (R$) de projetos e financiamentos vendidos na Plataforma 77.',
  origination:
    'Gráfico de quantia monetária (R$) de projetos e financiamentos criados pela Plataforma 77.',
}
