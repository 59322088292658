import { useState, useEffect } from 'react'
import { EmptyState, Header } from 'components'
import { TabList, TabContent, Spacer, Loading } from '@77sol/core'
import { Box } from '@material-ui/core'
import API from 'api'
import styles from './styles'
import { FinancingStatus } from './components/Contents/FinancingStatus'
import { Equipments } from './components/Contents/Equipments'
import Documents from './components/Contents/Documents'
import { ModalCancelFinancing } from './components/ModalCancelFinancing'
import { allRequesterFields } from './utils'
import {
  FINANCING_TABS_INDEX,
  FINANCING_TABS_OPTIONS,
  FINANCING_TYPE,
} from './constants'
import { FINANCING_STAGE, FINANCING_STATUS } from 'views/Financing/constants'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { useGetFinancingSimulationDetails } from 'domains/financing/requisition/hooks/useGetFinancingDetails'
import { useParams } from 'react-router-dom'
import { ApprovedFinancingAlert } from './components/ApprovedFinancingAlert'
import { queryClient } from 'services/queryClient'
import { PotentiallyVulnerableClientAlert } from './components/PotentiallyVulnerableClientAlert'
import { DeleteSimulationButton } from 'components/DeleteSimulationButton'

function SimulationDetails({
  selectedSimulationId,
  handleRefreshFinancingList,
  onClose,
  isFromProposalList,
  currentTabFinancings,
}) {
  const [selectedTab, setSelectedTab] = useState(0)
  const { simulationId } = useParams()
  const [isCancelFinancing, setIsCancelFinancing] = useState(false)
  const [loadingCancelFinancing, setLoadingCancelFinancing] = useState(false)
  const [showApprovedFinancingAlert, setShowApprovedFinancingAlert] =
    useState(false)
  const [
    showPotentiallyVulnerableClientAlert,
    setShowPotentiallyVulnerableClientAlert,
  ] = useState(false)
  const [selectedFinancingToCancel, setSelectedFinancingToCancel] = useState({})

  const { logEvent } = useAmplitude()

  const classes = styles({ isFromProposalList })
  function handleOpenAndCloseVulnerableAlert(data) {
    if (
      data?.potentially_vulnerable_client?.title &&
      data?.potentially_vulnerable_client?.description
    ) {
      setShowPotentiallyVulnerableClientAlert(true)
    } else {
      setShowPotentiallyVulnerableClientAlert(false)
    }
  }

  function handleOpenAndCloseApprovedFinancingAlert(data) {
    if (
      data?.stage === FINANCING_STAGE.FORMALIZACAO &&
      data?.status === FINANCING_STATUS.APROVADO
    ) {
      setShowApprovedFinancingAlert(true)
    } else {
      setShowApprovedFinancingAlert(false)
    }
  }

  const { data, isLoading } = useGetFinancingSimulationDetails(
    selectedSimulationId,
    {
      onSuccess: (response) => {
        const { data } = response
        handleOpenAndCloseVulnerableAlert(data)
        handleOpenAndCloseApprovedFinancingAlert(data)
      },
    },
  )
  const selectedSimulation = data?.data

  const simulationTitle =
    selectedSimulationId && `Simulação #${selectedSimulationId}`

  useEffect(() => {
    if (selectedSimulation?.notification && !simulationId) {
      API.put(`/financing/remove-notification/${selectedSimulationId}`).then(
        () => {
          queryClient.invalidateQueries()
          handleRefreshFinancingList()
        },
      )
    }
  }, [selectedSimulation, simulationId, queryClient])

  function handleCancelFinancing() {
    setSelectedFinancingToCancel(selectedSimulation)
    setIsCancelFinancing(true)

    logEvent(financingTracker.actions.openedCancelFinancingModal, {
      origin: '/financiamentos',
      financingType: FINANCING_TYPE[currentTabFinancings],
    })
  }

  const handleChangeTab = (tab) => {
    logEvent(financingTracker.actions.changedFinancingTab, {
      origin: '/financiamentos',
      financingType: FINANCING_TYPE[currentTabFinancings],
      tab: FINANCING_TABS_OPTIONS[tab],
    })
    setSelectedTab(tab)
  }

  return (
    <>
      <Box className={classes.container}>
        <Header fixedHeight onBack={onClose} title={simulationTitle}>
          {selectedSimulation?.active &&
            selectedSimulation?.status !== 'Cancelado' && (
              <DeleteSimulationButton
                isLoading={loadingCancelFinancing}
                shortText={isFromProposalList}
                onClick={handleCancelFinancing}
              />
            )}
        </Header>

        {selectedSimulation || isLoading ? (
          <div className={classes.financingContainer}>
            <Spacer size="12" direction="horizontal" />
            <Loading isLoading={isLoading}>
              <Box px={3}>
                {showPotentiallyVulnerableClientAlert && (
                  <PotentiallyVulnerableClientAlert
                    message={selectedSimulation?.potentially_vulnerable_client}
                    onClose={() =>
                      setShowPotentiallyVulnerableClientAlert(false)
                    }
                  />
                )}

                {showApprovedFinancingAlert && (
                  <ApprovedFinancingAlert
                    id={selectedSimulation?.id}
                    phone={selectedSimulation?.closer_cellphone}
                    onClose={() => setShowApprovedFinancingAlert(false)}
                  />
                )}
                <TabList
                  value={selectedTab}
                  onChange={handleChangeTab}
                  titles={FINANCING_TABS_OPTIONS}
                />
                {selectedTab === FINANCING_TABS_INDEX.STATUS && (
                  <TabContent
                    value={selectedTab}
                    index={FINANCING_TABS_INDEX.STATUS}
                  >
                    <FinancingStatus
                      selectedFinancing={selectedSimulation}
                      handleRefreshFinancingList={handleRefreshFinancingList}
                    />
                  </TabContent>
                )}
                {selectedTab === FINANCING_TABS_INDEX.REQUESTER && (
                  <TabContent
                    value={selectedTab}
                    index={FINANCING_TABS_INDEX.REQUESTER}
                  >
                    {allRequesterFields(selectedSimulation?.requester)}
                  </TabContent>
                )}
                {selectedTab === FINANCING_TABS_INDEX.EQUIPMENTS && (
                  <TabContent
                    value={selectedTab}
                    index={FINANCING_TABS_INDEX.EQUIPMENTS}
                  >
                    <Equipments products={selectedSimulation?.kit} />
                  </TabContent>
                )}
                {selectedTab === FINANCING_TABS_INDEX.DOCUMENTS && (
                  <TabContent
                    value={selectedTab}
                    index={FINANCING_TABS_INDEX.DOCUMENTS}
                  >
                    <Documents selectedFinancing={selectedSimulation} />
                  </TabContent>
                )}
              </Box>
            </Loading>
          </div>
        ) : (
          <EmptyState text="Oops! Nenhum financiamento encontrado." />
        )}
      </Box>
      {isCancelFinancing && (
        <ModalCancelFinancing
          setLoadingCancelFinancing={setLoadingCancelFinancing}
          handleRefreshFinancingList={handleRefreshFinancingList}
          onClose={onClose}
          selectedFinancingToCancel={selectedFinancingToCancel}
          setSelectedFinancingToCancel={setSelectedFinancingToCancel}
          setIsCancelFinancing={setIsCancelFinancing}
          isCancelFinancing={isCancelFinancing}
          currentTabFinancings={currentTabFinancings}
        />
      )}
    </>
  )
}

export default SimulationDetails
