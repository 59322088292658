import { Badge, Spacer, Tooltip } from '@77sol/core'
import { palette } from '@77sol/styles'
import { Box, IconButton } from '@material-ui/core'
import { InfoIcon } from '@77sol/icons/dist'
import orderStatus from 'views/Pedidos/orderStatus'

export function RemakeOrderBadge({ nome_status }) {
  return (
    <Box display="flex">
      <Badge
        label={nome_status}
        fontWeight="700"
        color={orderStatus[nome_status]?.color}
        colorWeight="200"
      />
      <Spacer direction="vertical" size="5" />
      <Box display="flex" alignItems="center">
        <Tooltip title="Pedidos vencem em 2 dias úteis">
          <IconButton size="small">
            <InfoIcon color={palette.black} width="14px" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
