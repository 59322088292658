import { ImageCrop } from '@77sol-ui/organisms'
import { Modal } from 'components/Modal'
import styled from 'styled-components'

export const WrapperStyled = styled(Modal.Wrapper)`
  z-index: 10000;
  max-width: 620px !important;
  max-height: 95vh !important;
`

export const StyledOverlay = styled(Modal.Overlay)`
  z-index: 10000;
`

export const EditorBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #232323;
  border-radius: 12px;
  padding: 20px;

  @media (min-width: 991px) {
    margin-inline: 16px;
  }
`

export const StyledActions = styled(ImageCrop.Actions)`
  margin-inline: 40px;
`

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  & .buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.75rem;
  }

  @media (min-width: 991px) {
    & .buttons {
      flex-direction: row;
    }
  }
`
