import { DeleteIcon } from '@77sol/icons/dist'
import { Button } from './styles'
import { CircularProgress } from '@material-ui/core'

export interface IDeleteSimulationButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  shortText?: boolean
  isLoading?: boolean
}

export function DeleteSimulationButton({
  onClick,
  shortText,
  isLoading,
  disabled,
  ...props
}: IDeleteSimulationButtonProps) {
  return (
    <Button onClick={onClick} disabled={disabled || isLoading} {...props}>
      {isLoading && <CircularProgress disableShrink size={16} thickness={4} />}
      {!isLoading && <DeleteIcon />}
      {!isLoading && shortText && <span>Cancelar</span>}
      {!isLoading && !shortText && <span>Cancelar financiamento</span>}
    </Button>
  )
}
