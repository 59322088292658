import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { addressEquals } from '../utils/addressEquals'
import { type IShipmentFormValues } from '../../../ShipmentForm.types'

export function useCopyDeliveryAddress() {
  const [isChecked, setChecked] = useState(false)

  const { watch, setValue, clearErrors } = useFormContext<IShipmentFormValues>()

  const deliveryAddress = watch('deliveryAddress')
  const billingAddress = watch('billingAddress')

  const isEquals = addressEquals(deliveryAddress, billingAddress)

  useEffect(() => {
    if (isChecked && !isEquals) {
      setChecked(false)
    }
  }, [isEquals, isChecked])

  function handleCheckedCopy() {
    const isCheckChanged = !isChecked

    setChecked(isCheckChanged)

    if (isCheckChanged) {
      setValue('billingAddress.complemento', deliveryAddress.complemento || '')
      setValue('billingAddress.endereco', deliveryAddress.endereco || '')
      setValue('billingAddress.numero', deliveryAddress.numero || '')
      setValue('billingAddress.bairro', deliveryAddress.bairro || '')
      setValue('billingAddress.cidade', deliveryAddress.cidade || '')
      setValue('billingAddress.estado', deliveryAddress.estado || '')
      setValue('billingAddress.cep', deliveryAddress.cep || '')
    } else {
      setValue('billingAddress.complemento', '')
      setValue('billingAddress.endereco', '')
      setValue('billingAddress.numero', '')
      setValue('billingAddress.bairro', '')
      setValue('billingAddress.cidade', '')
      setValue('billingAddress.estado', '')
      setValue('billingAddress.cep', '')
    }

    clearErrors([
      'billingAddress.endereco',
      'billingAddress.numero',
      'billingAddress.bairro',
      'billingAddress.cidade',
      'billingAddress.estado',
      'billingAddress.cep',
    ])
  }

  return {
    isCheckedCopy: isChecked,
    handleCheckedCopy,
  }
}
