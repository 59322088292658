import { Header, type IHeaderRootProps } from '@77sol-ui/organisms'
import { Profile } from './components/Profile'
import { ButtonProfile, ButtonContainer } from './styles'
import { Notification } from 'containers/Notifications'
import { LockedComponent } from 'acl/components/LockedComponent'
import { Features } from 'acl/config/features.types'
import { useHeaderDesktop } from './hooks/useHeaderDesktop'
import { QuotationButton } from 'containers/QuotationButton'
import { RequestFinancingButton } from 'containers/RequestFinancingButton'

export function Desktop({ ...props }: Omit<IHeaderRootProps, 'children'>) {
  const {
    userLogged,
    open,
    handleAccessCustomizeProposal,
    handleLogout,
    handleOpenedNotifications,
    handleOpenDropdown,
    handleAccessMyAcount,
    handleAccessMyTeam,
    handleOpenQuotationDropdown,
  } = useHeaderDesktop()

  return (
    <Header.Root {...props}>
      <Header.Content align="end" data-testid="header-content">
        <ButtonContainer>
          <QuotationButton onOpenedDropdown={handleOpenQuotationDropdown} />
          <RequestFinancingButton />
        </ButtonContainer>

        <Notification.Popover
          collisionPadding={{ right: 20 }}
          onOpenedSuccess={handleOpenedNotifications}
        />

        <Header.DropdownRoot open={open} onOpenChange={handleOpenDropdown}>
          <ButtonProfile data-testid="header-dropdown">
            <Profile
              title={userLogged[0]?.nome}
              subtitle={userLogged[0]?.display_role}
            />
          </ButtonProfile>

          <Header.DropdownContent
            sideOffset={10}
            align="end"
            data-testid="header-dropdown-content"
            onCloseAutoFocus={(e: Event) => {
              e.preventDefault()
            }}
          >
            <LockedComponent feature={Features.CONFIGURACOES}>
              <Header.DropdownItem
                data-testid="dropdown-item-my-account"
                onClick={handleAccessMyAcount}
              >
                Minha Conta
              </Header.DropdownItem>
            </LockedComponent>
            <LockedComponent feature={Features.MEUTIME}>
              <Header.DropdownItem
                data-testid="dropdown-item-my-team"
                onClick={handleAccessMyTeam}
              >
                Meu Time
              </Header.DropdownItem>
            </LockedComponent>

            <LockedComponent feature={Features.CONFIGURACOES}>
              <Header.DropdownItem
                onClick={handleAccessCustomizeProposal}
                data-testid="dropdown-item-customize-proposal"
              >
                Personalizar Proposta
              </Header.DropdownItem>
            </LockedComponent>

            <Header.DropdownItem
              data-testid="dropdown-item-logout"
              onClick={handleLogout}
            >
              Sair
            </Header.DropdownItem>
          </Header.DropdownContent>
        </Header.DropdownRoot>
      </Header.Content>
    </Header.Root>
  )
}
