import { useCallback } from 'react'
import palette from 'app_palette'
import API from 'api'

import {
  Accordion,
  Button,
  Spacer,
  Tooltip,
  LabelledDropdown,
  useToast,
} from '@77sol/core'

import {
  BuildingGovIcon,
  DeleteIcon,
  SendIcon,
  ViewIcon,
} from '@77sol/icons/dist'

import { Box, IconButton } from '@material-ui/core'
import { proposalFilterList, shareUrl } from 'utils'
import { projectsTracker } from 'services/tracker/events/projects/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { DimensioningList } from './components/DimensioningList'
import { useCreateFinancingPerQuotationModalStore } from 'containers/Financing/PerQuotation/CreateFinancingPerQuotationModal/store'
import { triggerTypes } from 'services/tracker/properties/triggerType'
import { EmptyState } from './components/EmptyState'
import * as S from './styles'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

function ProposalListItem({
  index,
  proposal,
  seeMoreDetailsProposal,
  setSelectedQuote,
  setOpenCheckoutModal,
  setOpenLoadingPdf,
  selectedRow,
  setIsModalOpen,
  setSizingModal,
  formatProposalToModal,
  setAllQuotationByProposal,
  handleFavorite,
}) {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { logEvent } = useAmplitude()

  const hasQuotation = proposal.quotation_total > 0
  const hasSizing = proposal.dimensionings_count > 0

  const handleStatusChange = async (status, id) => {
    const proposalStatus = proposalFilterList.find(
      ({ value }) => value === status,
    )
    try {
      await API.post('/proposal/status/', {
        status: proposalStatus.slug,
        id,
      })

      onSuccessOpenToast(
        `Proposta ${id} atualizada para status ${proposalStatus.label}.`,
      )
    } catch (e) {
      onErrorOpenToast(`Não foi possível atualizar a proposta ${id}.`)
    }
  }

  const setFinancingPerQuotationModalOpen =
    useCreateFinancingPerQuotationModalStore(
      (state) => state.setFinancingPerQuotationModalOpen,
    )

  async function proposalUtils(event, action) {
    const actions = {
      share: () => {
        logEvent(proposalTracker.actions.openProposalSendModal, {
          origin: '/projetos - Proposta',
        })

        setIsModalOpen('share')
      },
      delete: () => setIsModalOpen('deleteProposal'),
      view: async () => {
        const proposalUrl = await shareUrl({ token: proposal.token })
        return window.open(proposalUrl, '_blank')
      },
      financing: () => {
        logEvent(financingTracker.actions.handeSimulateFinancing, {
          origin: '/projetos > proposta',
        })
        setFinancingPerQuotationModalOpen(proposal.id)
      },
      reserve: async () => {
        await getAllProposalByProject(proposal.id)
        setIsModalOpen('reserve')
      },
    }
    event.preventDefault()
    event.stopPropagation()
    formatProposalToModal(proposal)

    actions[action]()
  }

  async function getAllProposalByProject(proposalId) {
    try {
      const response = await API.get(`proposal/quotations_all/${proposalId}`)

      setAllQuotationByProposal(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  function handleViewDetailedProposal(proposal) {
    logEvent(projectsTracker.actions.viewProposalDetails, {
      origin: '/projetos > proposta',
      type: triggerTypes.button,
      'Proposal ID': proposal.id,
    })

    seeMoreDetailsProposal(proposal)
  }

  const handleClick = useCallback(
    (isOpen) => {
      if (!isOpen) return
      logEvent(projectsTracker.actions.expandDimensioning)
    },
    [logEvent],
  )

  return (
    <>
      <Accordion
        key={index}
        onClick={handleClick}
        title={
          <S.Container>
            <h3>
              Proposta {index + 1} - #{proposal.id}
            </h3>
            <LabelledDropdown
              size="large"
              initialValue={proposal.status || '1'}
              options={proposalFilterList}
              onChange={(value) => handleStatusChange(value, proposal.id)}
            />
            <div>
              <Tooltip title="Financiar">
                <IconButton
                  id="projects_button_finance"
                  aria-label="visualizar"
                  onClick={(e) => proposalUtils(e, 'financing')}
                >
                  <BuildingGovIcon
                    color={palette.grayscale[800]}
                    width="24px"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Visualizar Proposta">
                <IconButton
                  id="projects_button_viewProposal"
                  aria-label="visualizar"
                  onClick={(e) => proposalUtils(e, 'view')}
                >
                  <ViewIcon color={palette.grayscale[800]} width="24px" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Compartilhar">
                <IconButton
                  id="projects_button_share"
                  aria-label="compartilhar"
                  onClick={(e) => proposalUtils(e, 'share')}
                >
                  <SendIcon color={palette.grayscale[800]} width="24px" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deletar">
                <IconButton
                  id="projects_button_delete"
                  aria-label="deletar"
                  onClick={(e) => proposalUtils(e, 'delete')}
                >
                  <DeleteIcon color={palette.red[300]} width="24px" />
                </IconButton>
              </Tooltip>
            </div>
          </S.Container>
        }
      >
        <Box width="100%">
          <DimensioningList
            proposal={proposal}
            dimensionings={proposal.dimensionings}
            setSelectedQuote={setSelectedQuote}
            setOpenCheckoutModal={setOpenCheckoutModal}
            setOpenLoadingPdf={setOpenLoadingPdf}
            selectedRow={selectedRow}
            getAllProposalByProject={getAllProposalByProject}
            setIsModalOpen={setIsModalOpen}
            setSizingModal={setSizingModal}
            seeMoreDetailsProposal={seeMoreDetailsProposal}
            handleFavorite={handleFavorite}
          />

          {!hasSizing && (
            <EmptyState text="Nenhum dimensionamento nesta proposta" />
          )}

          {hasQuotation && (
            <S.ButtonWrapper>
              <Button
                id="projects_button_details"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleViewDetailedProposal(proposal)}
                endIcon={<ViewIcon />}
              >
                Ver detalhes
              </Button>
            </S.ButtonWrapper>
          )}
        </Box>
      </Accordion>
      <Spacer size="10" direction="horizontal" />
    </>
  )
}

export { ProposalListItem }
