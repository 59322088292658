import { QUERY_KEYS } from 'services/constant'
import API from 'api'
import {
  type IProposalQuotationCalculateDeliveryValuePOSTPayload,
  type IProposalQuotationCalculateDeliveryValuePOSTResponse,
} from './types'

export const proposalQuotationCalculateDeliveryValue = {
  post: async (payload: IProposalQuotationCalculateDeliveryValuePOSTPayload) =>
    await API.post<IProposalQuotationCalculateDeliveryValuePOSTResponse>(
      QUERY_KEYS.PROPOSAL.QUOTATION_CALCULATE_DELIVERY_VALUE,
      payload,
    ).then(({ data }) => data),
}
