import styled from 'styled-components'

export const EquipmentsWrapper = styled.div`
  margin-top: 24px;
`

export const EquipmentsList = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ExtraItensWrapper = styled.div`
  margin-top: 24px;

  h2 {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
  }
`
