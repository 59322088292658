import { ScrollTable } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Link } from 'react-router-dom'
import { EmptyState } from './components/EmptyState'
import { ListSkeleton } from '../Skeleton'
import { ProposalItem } from './components/ProposalItem'
import { type IResponseGetProposalsData } from 'domains/home/dashboard-proposals/services/types'
import { Wrapper } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { tableFiltersTrackers } from 'services/tracker/events/tableFilters/trackers'
import { queryClient } from 'services/queryClient'
import { DASHBOARD_PROPOSALS } from 'domains/home/dashboard-proposals/constants/endpointKeys'
import { ErrorState } from '../ErrorState'

export interface IProposalListProps {
  proposals?: IResponseGetProposalsData['proposals']
  isLoading?: boolean
  isError?: boolean
  alreadyCreatedProposal?: boolean
  filterApplied?: boolean
  numberOfRows?: number
}

export function ProposalList({
  proposals,
  alreadyCreatedProposal,
  isLoading,
  filterApplied,
  numberOfRows = 0,
  isError,
}: IProposalListProps) {
  const { logEvent } = useAmplitude()
  const hasProposals = proposals && proposals.length > 0 && !isLoading

  function onSeeAllProposals() {
    logEvent(tableFiltersTrackers.actions.seeAll, {
      origin: '/dashboard',
      table: 'Propostas',
    })
  }

  const retryFetchData = async () => {
    await queryClient.refetchQueries({
      queryKey: [DASHBOARD_PROPOSALS],
    })
  }

  return (
    <Wrapper numberOfRows={numberOfRows} data-testid="proposals-list">
      <ScrollTable.ScrollArea className="table-scroll-area">
        <ScrollTable.Table className="table">
          <ScrollTable.Head>
            <ScrollTable.Tr>
              <ScrollTable.Th pin>ID</ScrollTable.Th>
              <ScrollTable.Th>Nome do Cliente</ScrollTable.Th>
              <ScrollTable.Th>Valor </ScrollTable.Th>
              <ScrollTable.Th>kWp</ScrollTable.Th>
              <ScrollTable.Th>Status</ScrollTable.Th>
              <ScrollTable.Th>Data da Criação</ScrollTable.Th>
            </ScrollTable.Tr>
          </ScrollTable.Head>
          <ScrollTable.Body>
            {isLoading && <ListSkeleton numberOfRows={numberOfRows} />}

            {hasProposals &&
              proposals?.map((proposal) => (
                <ProposalItem key={proposal.id} proposal={proposal} />
              ))}
          </ScrollTable.Body>
        </ScrollTable.Table>
      </ScrollTable.ScrollArea>

      {hasProposals && (
        <ScrollTable.Footer justify="center" className="table-footer">
          <Button variant="outline" asChild>
            <Link to="/projetos" onClick={onSeeAllProposals}>
              Ver tudo
            </Link>
          </Button>
        </ScrollTable.Footer>
      )}

      {isError && !hasProposals && !isLoading && (
        <ErrorState
          title="Ocorreu um erro ao listar as propostas."
          subtitle="Atualize a página e tente novamente."
          buttonText="Tentar novamente"
          numberOfRows={numberOfRows}
          onButtonAction={retryFetchData}
        />
      )}

      {!isError && !hasProposals && !isLoading && (
        <EmptyState
          filterApplied={filterApplied}
          alreadyCreatedProposal={alreadyCreatedProposal}
        />
      )}
    </Wrapper>
  )
}
