import { format, getWeek } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { capitalize } from 'utils/capitalize'

function customizeTooltipTitle(value: string) {
  return format(new Date(value), 'd MMMM, yyyy', {
    locale: ptBR,
  })
}

function customizeTickByDay(value: string) {
  return format(new Date(value), 'dd MMM', {
    locale: ptBR,
  })
}

function customizeTickByWeek(value: string) {
  const week = getWeek(new Date(value), {
    locale: ptBR,
    weekStartsOn: 0,
  })
  return `Semana ${week}`
}

function customizeTickByMonth(value: string): string {
  return capitalize(
    format(new Date(value), 'MMMM yy', {
      locale: ptBR,
    }),
  )
}

const customizeTicks: Record<string, (value: string) => string> = {
  day: customizeTickByDay,
  week: customizeTickByWeek,
  month: customizeTickByMonth,
}

const customizeTooltip: Record<string, (value: string) => string> = {
  day: customizeTooltipTitle,
  week: customizeTickByWeek,
  month: customizeTickByMonth,
}

export { customizeTicks, customizeTooltip }
