import { type IButtonProps } from '@77sol-ui/atoms'
import { Loader2 } from 'lucide-react'
import * as S from './styles'

type ILoadingButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  IButtonProps & {
    isLoading: boolean
  }

export function LoadingButton({
  children,
  isLoading,
  ...props
}: ILoadingButtonProps) {
  return (
    <S.Button disabled={isLoading} {...props}>
      {children}
      {isLoading && (
        <S.Loading data-testid="loading">
          <Loader2 size={24} className="atm-animate-spinSlow" />
        </S.Loading>
      )}
    </S.Button>
  )
}
