import React, { useEffect } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import palette from 'app_palette'
import { withSize } from 'react-sizeme'
import Tooltip from '@material-ui/core/Tooltip'
import { useSelector } from 'react-redux'
import { Button, Spacer, useToast } from '@77sol/core'
import {
  CalculatorIcon,
  SolarPanelIcon,
  FileIcon,
  FullScreenResizeExpandIcon,
} from '@77sol/icons/dist'

function DimStepper({
  activeDimStep,
  setActiveDimStep,
  completedDimSteps,
  expandedStepper,
  setExpandedStepper,
  setCompletedDimSteps,
  size,
  editStepThree,
  hasSomeError,
}) {
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  const { onErrorOpenToast } = useToast()

  // Redux
  const proposal = useSelector((state) => state.Reducer1)

  const steps = [
    { index: 1, label: 'Dimensionamento' },
    { index: 2, label: 'Equipamentos' },
    { index: 3, label: 'Proposta' },
  ]

  const handleStep = (index) => {
    if (
      proposal.quotes.filter(
        (quote) => quote.dimensioning.id == proposal.activeSizing,
      ).length == 0
    ) {
      onErrorOpenToast(
        'Este dimensionamento não tem nenhuma cotação, crie uma antes de avançar!',
      )
      return
    }

    if (
      parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) === 1 &&
      index != 1
    ) {
      onErrorOpenToast('Complete o dimensionamento antes de avançar!')
      return
    }

    if (parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) === 2) {
      if (index === 1 || index === 2) {
        setExpandedStepper(false)
        return setActiveDimStep(index)
      }
      onErrorOpenToast(
        'Complete a cotação dos seus equipamentos antes de avançar!',
      )
      return
    }

    setExpandedStepper(false)
    return setActiveDimStep(index)
  }

  useEffect(() => {
    if (proposal && proposal.activeStep) {
      setCompletedDimSteps([
        {
          index: 1,
          completed:
            parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) >=
            1,
        },
        {
          index: 2,
          completed:
            parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) >=
            2,
        },
        {
          index: 3,
          completed:
            parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) >=
            3,
        },
      ])
      if (
        parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) != 1 &&
        parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) != 2
      ) {
        if (
          !(
            proposal.quotes.filter(
              (quote) => quote.quotation.finalized_budget == true,
            ).length == proposal.quotes.length
          )
        ) {
          setActiveDimStep(3)
          return
        }
      }
      if (!editStepThree) {
        setActiveDimStep(
          parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')),
        )
      }
    }
  }, [proposal.activeStep])

  const handleIcon = {
    1: <CalculatorIcon />,
    2: <SolarPanelIcon />,
    3: <FileIcon />,
    4: <FileIcon />,
  }

  return (
    <Grid
      style={{
        position: 'relative',
        padding: upXl ? '24px 32px' : '24px 23px',
        color: palette,
      }}
      container
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          color: palette.grayscale[800],
        }}
      >
        <div style={{ cursor: 'pointer' }}>
          <FullScreenResizeExpandIcon
            onClick={() => {
              expandedStepper
                ? setExpandedStepper(false)
                : setExpandedStepper(true)
            }}
            width="16px"
          />
        </div>
      </div>
      <Grid item xs={12}>
        <Spacer size="24" direction="horizontal" />
      </Grid>
      {steps.map((step, i) => (
        <Grid key={`step${i}`} item xs={12}>
          {size.width > 260 ? (
            <>
              <Button
                onClick={() => handleStep(step.index)}
                variant={
                  activeDimStep === step.index
                    ? 'contained'
                    : completedDimSteps[i].completed
                    ? 'outlined'
                    : 'contained'
                }
                disabled={
                  !completedDimSteps[i].completed ||
                  (hasSomeError && activeDimStep !== step.index)
                }
                radius="semiRounded"
                fullWidth
                color="primary"
                size="medium"
                startIcon={handleIcon[step.index]}
                noMargin
              >
                {step.label}
              </Button>
              <Spacer size="8" direction="horizontal" />
            </>
          ) : (
            <>
              <Tooltip
                placement="right"
                title={
                  <div style={{ fontSize: '14px', padding: '8px' }}>
                    {step.label}
                  </div>
                }
              >
                <Button
                  onClick={() => handleStep(step.index)}
                  variant={
                    activeDimStep === step.index
                      ? 'contained'
                      : completedDimSteps[i].completed
                      ? 'outlined'
                      : 'contained'
                  }
                  disabled={
                    !completedDimSteps[i].completed ||
                    (hasSomeError && activeDimStep !== step.index)
                  }
                  radius="semiRounded"
                  color="primary"
                  size="medium"
                  onlyIcon={handleIcon[step.index]}
                  noMargin
                />
              </Tooltip>
              <Spacer size="8" direction="horizontal" />
            </>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export default withSize()(DimStepper)
