import { QUERY_KEYS } from 'services/constant'
import API from 'api'
import { type IProposalQuotationChangeDeliveryValuePOSTPayload } from './types'

export const proposalQuotationChangeDeliveryValue = {
  post: async (payload: IProposalQuotationChangeDeliveryValuePOSTPayload) =>
    await API.post(
      QUERY_KEYS.PROPOSAL.QUATATION_CHANGE_DELIVERY_VALUE,
      payload,
    ),
}
