import { type IFilters } from '../../types'
import { ConversionChart } from './components/ConversionChart'
import { OriginationChart } from './components/OriginationChart'

export interface IChartProps {
  filters: IFilters
}

export default function Chart({ filters }: IChartProps) {
  const { chart, interval, period } = filters

  if (chart === 'conversion') {
    return (
      <ConversionChart
        filters={{
          interval,
          period,
        }}
      />
    )
  }

  return (
    <OriginationChart
      filters={{
        interval,
        period,
      }}
    />
  )
}
