import { useCompareKitCardsContainer } from 'containers/EquipmentQuotationContainer/components/Tab/components/CompareKit/components/CompareKitCardsContainer/hooks/useCompareKitCardsContainer'
import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

interface IUseEditQuotationButton {
  quotationId: number
}

export function useEditQuotationButton({
  quotationId,
}: IUseEditQuotationButton) {
  const { quotations } = useCompareKitCardsContainer()
  const { logEvent } = useAmplitude()

  const changeQuottionTab = useEquipmentContainerStore(
    (state) => state.changeTab,
  )

  const setQuotationSelected = useEquipmentContainerStore(
    (state) => state.setQuotationSelected,
  )

  function handleEditQuotation() {
    logEvent(quotationTracker.actions.editQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE['compare-kit'],
    })
    const currentQuotation = quotations.find(
      (quotation) => quotation.id === quotationId,
    )

    if (!currentQuotation) {
      return
    }

    setQuotationSelected(currentQuotation)
    changeQuottionTab(TAB_ENUM.CUSTOMIZE_KIT)
  }

  return {
    handleEditQuotation,
  }
}
