import api from 'api'
import { CREATE_QUOTATION } from '../constants/endpointKeys'
import { type IPayloadCreateQuotation } from './types'

export const createQuotation = async (
  quotationData: IPayloadCreateQuotation,
) => {
  return await api
    .post(`${CREATE_QUOTATION}`, quotationData)
    .then((response) => {
      return response.data
    })
}
