import { TAB_ENUM } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'

export const useDisableTabs = () => {
  const kits = useEquipmentContainerStore((state) => state.selectedKits)
  const currentTab = useEquipmentContainerStore((state) => state.currentTab)

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const quotations = data?.quotations || []

  const kitsIds = [...kits.map((kit) => kit?.id)]
  const quotationsIds = [...quotations.map((quotation) => quotation.id)]
  const quotationIsChanged =
    !quotationsIds.every((id) => kitsIds.includes(id)) ||
    quotationsIds.length !== kitsIds.length

  const disableTabs =
    currentTab === TAB_ENUM.CHOOSE_KIT &&
    (kits.length === 0 || quotationIsChanged)

  return { disableTabs }
}
