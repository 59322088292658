import React from 'react'
import { Box } from '@material-ui/core'
import { Typography, Paper } from '@77sol/core'
import Icons from '../Icons'

function ResponsiveCard({ values, handleIconClick, onClick }) {
  return (
    <Box marginBottom={2}>
      <Paper boxShadow="small">
        <Box onClick={onClick}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography size="xsmall">
              {values?.id?.Component ? values.id.Component({}) : values.id}
            </Typography>
            <Typography size="xsmall">{values.request_date}</Typography>
          </Box>
          <Typography type="link">{values.client}</Typography>
          <Typography size="small">Orçamentos: {values.quotes}</Typography>
          {values.financing_status}
        </Box>
        {handleIconClick && (
          <Box display="flex" justifyContent="flex-end" onClick={() => null}>
            <Icons onClick={(type) => handleIconClick(type, values)} />
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ResponsiveCard
