import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { EmptyState, Header, Loading, TableList } from 'components'
import { ArrowUpIcon } from '@77sol/icons/dist'
import {
  Spacer,
  Typography,
  Slide,
  Dropdown,
  TabList,
  TabContent,
} from '@77sol/core'
import { isEmpty, orderOptions } from 'utils'
import ConfigProposalContent from './ConfigProposalContent'
import styles from './styles'
import ResponsiveCard from './ResponsiveCard'

function ResponsiveSlide({ desktopWidth, ...props }) {
  return (
    <>
      <Hidden smDown>
        <Slide {...props} width={desktopWidth} />
      </Hidden>
      <Hidden mdUp>
        <Slide {...props} width="100%" />
      </Hidden>
    </>
  )
}

function ProposalsContainer({
  getFinancingSimulation,
  getProposalStructureToEdit,
  handleFavorite,
  isLoading,
  searchProposals,
  proposals,
  title,
  selectedProposal,
}) {
  const theme = useTheme()
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('lg'))
  const initialColumns = mobileBreakpoint
    ? [
        '',
        'ID',
        'Dimensionamentos',
        'Cotações',
        'Status Da Proposta',
        'Financiamento',
      ]
    : [
        '',
        'ID',
        'Dimensionamentos',
        'Cotações',
        'Status Da Proposta',
        'Criação',
        'Financiamento',
      ]
  const [selectedTab, setSelectedTab] = useState(0)
  const [columns, setColumns] = useState(initialColumns)
  const [orderSelected, setOrderSelected] = useState('1')
  const [showLastItem, setShowLastItem] = useState(true)
  const [showHeaderButtons, setShowHeaderButtons] = useState(true)
  const [isConfigProposalOpen, setIsConfigProposalOpen] = useState(false)
  const [isConfigProposalLoading, setIsConfigProposalLoading] = useState(false)
  const [listValues, setListValues] = useState([])
  const [showHistoricButton, setShowHistoricButton] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  const [isDescFilter, setIsDescFilter] = useState(true)
  const [projectsData, setProjectsData] = useState([])

  const history = useHistory()
  const classes = styles({ isDescFilter })

  useEffect(() => {
    if (!isConfigProposalOpen) {
      setColumns(initialColumns)
      if (mobileBreakpoint) {
        const slicedValues = proposals.map(({ created_at, ...rest }) => ({
          ...rest,
        }))
        setListValues(slicedValues)
      } else {
        setListValues(proposals)
      }
    }
  }, [mobileBreakpoint, proposals])

  useEffect(() => {
    if (selectedProposal) {
      const rowIndex = projectsData.findIndex(
        (item) => item?.rowData?.id === selectedProposal?.rowData?.id,
      )

      const row = {
        ...selectedProposal,
        rowData: { ...selectedProposal?.rowData, rowIndex },
      }

      onRowClick(row)
    }
  }, [projectsData])

  const getProposalsBySearch = async () => {
    try {
      const params = {
        ...(orderSelected && { order: isDescFilter ? 'desc' : 'asc' }),
        favorite: selectedTab,
      }

      const response = await searchProposals(params)
      setProjectsData(response)
      setListValues(response)
    } catch (e) {
      throw new Error('Erro', e)
    }
  }

  useEffect(() => {
    if (orderSelected) {
      getProposalsBySearch()
    }
  }, [orderSelected, isDescFilter, selectedTab])

  const onBack = () => {
    history.goBack()
  }

  const onToggleConfigProposal = () => {
    if (!isConfigProposalOpen) {
      const slicedValues = proposals.map(({ favorite, id, rowData }) => ({
        favorite,
        id,
        rowData,
      }))

      setListValues(slicedValues)
      setColumns(['', 'ID'])
    } else {
      setSelectedRow({})
      if (mobileBreakpoint) {
        const slicedValues = proposals.map(({ created_at, ...rest }) => ({
          ...rest,
        }))

        setListValues(slicedValues)
      } else {
        setListValues(proposals)
      }
      setColumns(initialColumns)
    }

    setIsConfigProposalOpen(!isConfigProposalOpen)
    setShowHistoricButton(!showHistoricButton)
    setShowLastItem(!showLastItem)
    setShowHeaderButtons(!showHeaderButtons)
  }

  const onRowClick = async (row) => {
    if (row?.rowData?.id === selectedRow?.rowData?.id) {
      return onToggleConfigProposal()
    }

    if (isEmpty(selectedRow)) {
      onToggleConfigProposal()
    }

    setSelectedRow(row)
    setIsConfigProposalLoading(true)

    const proposalEdit = await getProposalStructureToEdit(row.rowData.token)

    setSelectedRow((prevState) => ({
      ...prevState,
      rowData: {
        ...prevState.rowData,
        proposalToEdit: proposalEdit,
      },
    }))

    setIsConfigProposalLoading(false)
  }

  return (
    <Box height="100%">
      <Header zIndex={40} title={title} onBack={onBack} sticky primary />
      <Box display="flex" className={classes.container}>
        <Hidden smDown={isConfigProposalOpen}>
          <Box
            style={{ width: selectedRow && '300px' }}
            className={classes.content}
          >
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                paddingBottom={2}
                minHeight="72px"
              >
                <Box width="100%" maxWidth="350px">
                  <TabList
                    titles={['Todas', 'Favoritadas']}
                    onChange={(value) => {
                      if (isConfigProposalOpen) {
                        onToggleConfigProposal()
                      }
                      setSelectedTab(value)
                    }}
                    value={selectedTab}
                    variant="fullWidth"
                    minWidth="0px"
                  />
                </Box>
                {showHeaderButtons && (
                  <Box className={classes.headContainer}>
                    <Hidden mdUp>
                      <ToggleButton
                        classes={{
                          root: classes.toggleButton,
                        }}
                        disableRipple
                        value="desc"
                        selected={isDescFilter}
                        onChange={() => setIsDescFilter(!isDescFilter)}
                      >
                        <ArrowUpIcon className={classes.iconButton} />
                      </ToggleButton>
                    </Hidden>
                    <Hidden smDown>
                      <Typography>Ordenar por:</Typography>
                      <Spacer size="8" direction="both" />
                    </Hidden>
                    <Box width="150px">
                      <Dropdown
                        fullWidth
                        onChange={setOrderSelected}
                        options={orderOptions}
                        initialValue={orderSelected}
                      />
                    </Box>
                    <Hidden smDown>
                      <ToggleButton
                        classes={{
                          root: classes.toggleButton,
                        }}
                        disableRipple
                        value="desc"
                        selected={isDescFilter}
                        onChange={() => setIsDescFilter(!isDescFilter)}
                      >
                        <ArrowUpIcon className={classes.iconButton} />
                      </ToggleButton>
                      <Spacer size="8" direction="both" />
                    </Hidden>
                  </Box>
                )}
              </Box>
              <TabContent value={selectedTab} index={0}>
                <Loading minHeight="250px" isLoading={isLoading}>
                  <Box>
                    <Hidden smDown>
                      {!isEmpty(listValues) ? (
                        <Box className={classes.list}>
                          <TableList
                            columns={columns}
                            values={listValues}
                            onRowClick={onRowClick}
                            selectedRow={selectedRow}
                          />
                        </Box>
                      ) : (
                        <EmptyState text="Oops! Nenhuma proposta encontrada." />
                      )}
                    </Hidden>
                    <Hidden mdUp>
                      {!isEmpty(proposals) ? (
                        <Box className={classes.list}>
                          {proposals.map((values) => (
                            <ResponsiveCard
                              key={values.rowData.id}
                              values={values}
                              handleFavorite={() =>
                                handleFavorite(values.rowData.id)
                              }
                              onClick={() => onRowClick(values)}
                            />
                          ))}
                        </Box>
                      ) : (
                        <EmptyState text="Oops! Nenhuma proposta encontrada." />
                      )}
                    </Hidden>
                  </Box>
                </Loading>
              </TabContent>
              <TabContent value={selectedTab} index={1}>
                <Loading minHeight="250px" isLoading={isLoading}>
                  <Hidden smDown>
                    {!isEmpty(listValues) ? (
                      <Box className={classes.list}>
                        <TableList
                          columns={columns}
                          values={listValues}
                          onRowClick={onRowClick}
                          selectedRow={selectedRow}
                        />
                      </Box>
                    ) : (
                      <EmptyState text="Oops! Nenhuma proposta encontrada." />
                    )}
                  </Hidden>
                  <Hidden mdUp>
                    {!isEmpty(proposals) ? (
                      <Box className={classes.list}>
                        {proposals.map((values) => (
                          <ResponsiveCard
                            key={values.rowData.id}
                            values={values}
                            handleFavorite={() =>
                              handleFavorite(values.rowData.id)
                            }
                            onClick={() => onRowClick(values)}
                          />
                        ))}
                      </Box>
                    ) : (
                      <EmptyState text="Oops! Nenhuma proposta encontrada." />
                    )}
                  </Hidden>
                </Loading>
              </TabContent>
            </>
          </Box>
        </Hidden>
        <ResponsiveSlide
          isOpen={isConfigProposalOpen}
          desktopWidth="90%"
          style={{ marginTop: '-35px' }}
        >
          <ConfigProposalContent
            getFinancingSimulation={getFinancingSimulation}
            proposal={selectedRow}
            isLoading={isConfigProposalLoading}
            onBack={onToggleConfigProposal}
          />
        </ResponsiveSlide>
      </Box>
    </Box>
  )
}

export default ProposalsContainer
