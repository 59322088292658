import React from 'react'
import { Box } from '@material-ui/core'
import { Typography, Paper } from '@77sol/core'
import { palette } from '@77sol/styles'
import Icons from './Icons'

function ResponsiveCard({
  values,
  handleIconClick,
  archived,
  onClick,
  modal,
  isFinancing,
  incompleteQuote,
  setIncompleteQuoteToken,
  filterIncomplete,
}) {
  return (
    <Box marginBottom={2} marginLeft={modal ? '-24px' : '0px'}>
      <Paper
        style={{ backgroundColor: incompleteQuote && palette.primary[100] }}
        boxShadow={modal ? 'none' : 'small'}
      >
        <Box onClick={onClick}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={modal ? 'column' : 'row'}
          >
            <Typography size="xsmall" colorWeight="600">
              {values.id}
            </Typography>
            <Typography size="xsmall" colorWeight="600">
              {!isFinancing ? values.updated_at : values.bank}
            </Typography>
          </Box>
          <Typography type="link">
            {!isFinancing ? values.customer : values.name_requester}
          </Typography>
          {filterIncomplete ? (
            <Typography size="small">
              {!isFinancing
                ? `Última potência:: ${values.potency}`
                : values.bank}
            </Typography>
          ) : (
            <Typography size="small">
              {!isFinancing ? `Propostas: ${values.proposals}` : values.bank}
            </Typography>
          )}
          <Typography size="small">
            {!isFinancing ? `Cotações: ${values.quotations}` : values.bank}
          </Typography>
        </Box>
        {handleIconClick && (
          <Box display="flex" justifyContent="flex-end" onClick={() => null}>
            <Icons
              onClick={(type) => handleIconClick(type, values)}
              archived={archived}
              incompleteQuote={incompleteQuote}
              rowData={values.rowData}
              setIncompleteQuoteToken={setIncompleteQuoteToken}
            />
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ResponsiveCard
