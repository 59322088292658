import { Modal } from '@77sol/core'
import { CloseXIcon } from '@77sol/icons/dist'
import * as S from './styles'

interface IIconProps {
  width: number
}

interface ISendModalProps {
  id?: string
  open: boolean
  title?: string
  children: React.ReactNode
  Icon?: (props: IIconProps) => React.ReactSVGElement
  onClose: () => void
}

export function SendModal({
  id,
  open,
  Icon,
  title,
  children,
  onClose,
}: ISendModalProps) {
  return (
    <Modal id={id} open={open} onClose={onClose}>
      <S.Header>
        <S.Title>
          {Icon && (
            <S.Icon>
              <Icon width={24} />
            </S.Icon>
          )}
          {title}
        </S.Title>
        <S.Close onClick={onClose}>
          <CloseXIcon />
        </S.Close>
      </S.Header>
      <S.Content>{children}</S.Content>
    </Modal>
  )
}
