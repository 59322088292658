import * as S from './styles'

interface SectionHeaderProps {
  title: string
  description: string
}

export const SectionHeader = ({ title, description }: SectionHeaderProps) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.Container>
  )
}
