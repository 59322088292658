import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  iconButton: {
    transition: 'transform 0.3s',
    transform: 'rotate(180deg)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconExpanded: {
    transition: 'transform 0.3s',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))
