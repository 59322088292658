import React from 'react'
import { Button } from '@77sol/core'
import { CloseXIcon } from '@77sol/icons/dist'
import * as Alert from '../../../../../../../../../components/AlertDialog'
import {
  DESCRIPTION_MODAL_ALL_FIELDS_FILLED,
  TITLE_BUTTON_ALL_FIELDS_FILLED,
  TITLE_MODAL_ALL_FIELDS_FILLED,
} from '../../../../constants'

interface AllFieldsFilledProps {
  open: boolean
  isLoadingConfirmButton: boolean
  onClose?: () => void
  onCloseCustomOpen: () => void
  onClickActionButton: () => void
}

const AllFieldsFilled = ({
  open,
  onClose,
  onCloseCustomOpen,
  isLoadingConfirmButton,
  onClickActionButton,
  ...props
}: AllFieldsFilledProps) => {
  return (
    <Alert.Root open={open} onClose={onClose} {...props}>
      <Alert.CloseButton onClick={onClose}>
        <CloseXIcon width="24" />
      </Alert.CloseButton>
      <Alert.Container>
        <Alert.Title title={TITLE_MODAL_ALL_FIELDS_FILLED} />
        <Alert.Description description={DESCRIPTION_MODAL_ALL_FIELDS_FILLED} />
        <Alert.ButtonContainer>
          <Button onClick={onCloseCustomOpen} variant="text" size="small">
            Descartar e Sair
          </Button>
          <Button
            size="small"
            onClick={onClickActionButton}
            isLoading={isLoadingConfirmButton}
          >
            {TITLE_BUTTON_ALL_FIELDS_FILLED}
          </Button>
        </Alert.ButtonContainer>
      </Alert.Container>
    </Alert.Root>
  )
}

export default AllFieldsFilled
