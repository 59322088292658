import { useEffect, useRef } from 'react'

export function useScrollToCenter() {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function scrollToCenter(container: HTMLElement, elementId: string) {
    timeoutRef.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      const element = document.querySelector(`#${elementId}`) as HTMLElement

      if (element && container) {
        const distanceToCenter =
          element.offsetLeft -
          container.offsetWidth / 2 +
          element.offsetWidth / 2

        container.scrollTo({
          left: distanceToCenter,
          behavior: 'smooth',
        })
      }
    }, 50)
  }

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  return { scrollToCenter }
}
