import React from 'react'
import { Modal, useMediaQuery, Backdrop, Fade } from '@material-ui/core'
import { ArrowChevronBackIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { Paper, Typography } from 'components'
import { Spacer } from '@77sol/core'
import { ResultadoDimensionamento } from '..'
import { useStyles } from './styles'

function SizingModal({ sizing, setSizing }) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={sizing}
      onClose={() => setSizing('')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      style={{
        zIndex: 999,
      }}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={sizing}>
        <div className={classes.paper}>
          {downSm ? (
            <div style={{ width: '100%', display: 'flex' }}>
              <Paper
                style={{
                  backgroundColor: palette.primary[300],
                  height: 'auto',
                  borderRadius: '0px',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  padding: '16px',
                }}
              >
                <div
                  style={{ display: 'flex', cursor: 'pointer' }}
                  onClick={() => setSizing('')}
                >
                  <ArrowChevronBackIcon width="24" color={palette.white} />
                  <Spacer size="8" direction="vertical" />
                  <Typography type="link_small" color="white">
                    Editar dimensionamento
                  </Typography>
                </div>
              </Paper>
              <div
                style={{ marginTop: '56px', width: '100%', boxShadow: 'none' }}
              >
                <ResultadoDimensionamento
                  sizingEdit={sizing}
                  setSizingModal={setSizing}
                  hideTopMenu
                />
              </div>
            </div>
          ) : (
            <Paper style={{ padding: '0px', boxShadow: 'none' }}>
              <ResultadoDimensionamento
                sizingEdit={sizing}
                setSizingModal={setSizing}
              />
            </Paper>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default SizingModal
