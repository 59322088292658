import { SearchIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { Button } from '@77sol/core'
import { DatePickerControlled } from 'components/Controllers/DatePicker'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { TextField } from 'components/Controllers/TextField'
import * as S from './styles'
import { type FilterFields, useOrderFilters } from '../../hooks/useOrderFilters'

interface OrderFiltersFormProps {
  onFilter?: () => void
}

const OrderFiltersForm: React.FC<OrderFiltersFormProps> = ({ onFilter }) => {
  const {
    filters,
    handleFilter,
    handleSubmit,
    control,
    orderStatus,
    handleClearFilters,
  } = useOrderFilters()

  function handleApplyFilters(fields: FilterFields) {
    handleFilter(fields)
    onFilter?.()
  }

  return (
    <S.FormWrapper
      onSubmit={handleSubmit(handleApplyFilters)}
      className="form-grid"
    >
      <TextField
        control={control}
        placeholder="Pesquise pelo ID, valor ou CPF/CNPJ"
        name="search"
        className="g-xs-12 g-md-4"
        startAdornment={<SearchIcon color={palette.grayscale[500]} />}
      />
      <DropdownControlled
        value={filters.status}
        control={control}
        name="status"
        label="Status"
        options={[{ value: '', label: 'Todos' }, ...orderStatus]}
        fullWidth
        className="g-xs-12 g-md-2"
      />
      <DatePickerControlled
        control={control}
        name="date_initial"
        title="Data Inicial"
        className="g-xs-12 g-md-2"
      />
      <DatePickerControlled
        control={control}
        name="date_end"
        title="Data Final"
        className="g-xs-12 g-md-2"
      />
      <S.ButtonContainer className="g-xs-12 g-md-2">
        <Button size="small" type="submit">
          Filtrar
        </Button>
        <Button
          variant="text"
          size="small"
          color="red"
          onClick={handleClearFilters}
        >
          Limpar
        </Button>
      </S.ButtonContainer>
    </S.FormWrapper>
  )
}

export default OrderFiltersForm
