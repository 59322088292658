import { JuridicalPerson } from './JuridicalPerson'
import { NaturalPerson } from './NaturalPerson'
import { Commom } from './Commom'
import { Payment } from './Payment'

export const FormPart = {
  Commom,
  NaturalPerson,
  JuridicalPerson,
  Payment,
}
