import { ArrowChevronForwardIcon } from '@77sol/icons/dist'
import { Fragment } from 'react'
import * as S from './styles'
export interface OptionType {
  Icon: React.FC<{ width: string }>
  label: string
}
export interface TimelineProps {
  options: OptionType[]
  currentStep: number
  direction?: 'horizontal' | 'vertical'
  className?: string
  justifyContent?: React.CSSProperties['justifyContent']
}

const Timeline: React.FC<TimelineProps> = ({
  options,
  currentStep = 0,
  direction = 'horizontal',
  justifyContent = 'flex-start',
  className,
}) => {
  return (
    <S.TimeLineContainer
      justifyContent={justifyContent}
      className={className}
      direction={direction}
    >
      {options.map(({ Icon, label }, index) => {
        const optionPosition = index + 1
        const arrowIsVisible = index > 0 && direction !== 'vertical'
        const isFilled =
          optionPosition === currentStep || optionPosition < currentStep

        return (
          <Fragment key={label}>
            {arrowIsVisible && (
              <S.ArrowIconContainer
                isFilled={isFilled}
                data-testid="arrowIcon"
                direction={direction}
              >
                <ArrowChevronForwardIcon width="24px" isFilled={isFilled} />
              </S.ArrowIconContainer>
            )}
            <S.TimePoint direction={direction}>
              <S.TimePointIconContainer
                direction={direction}
                isFilled={isFilled}
                data-testid="timePoint"
              >
                <Icon width="18px" />
              </S.TimePointIconContainer>
              <span>{label}</span>
            </S.TimePoint>
          </Fragment>
        )
      })}
    </S.TimeLineContainer>
  )
}

export default Timeline
