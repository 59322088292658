import { Radio } from '@77sol-ui/atoms'
import { Table } from '@77sol-ui/molecules'
import { parseBRL } from 'utils'
import { TABLE_COLUMN_NAME, CUSTOM_TABLE_SPACING } from '../../constants'
import { type IQuotationDTO } from 'dto/QuotationDTO'

interface CurrentQuotationSelectedTableProps {
  choosedQuotation: IQuotationDTO
}

export const CurrentQuotationSelectedTable = ({
  choosedQuotation,
}: CurrentQuotationSelectedTableProps) => {
  return (
    <div>
      <Radio.Root>
        <Radio.Group>
          <Table.Root variant="default">
            <Table.Header headerTemplateColumns={CUSTOM_TABLE_SPACING}>
              {TABLE_COLUMN_NAME.map((column) => (
                <Table.Head key={column}>{column}</Table.Head>
              ))}
            </Table.Header>
            <Table.Body>
              {[choosedQuotation].map((data) => (
                <Table.Row
                  key={data.id}
                  rowTemplateColumns={CUSTOM_TABLE_SPACING}
                  rowType="table"
                  selected
                >
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[0]}>
                    <Radio.Item
                      name="quotations"
                      value={String(data.id)}
                      checked={true}
                    />
                  </Table.Cell>
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[1]}>
                    {data.id}
                  </Table.Cell>
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[2]}>
                    {data.potency} kWp
                  </Table.Cell>
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[3]}>
                    {parseBRL(data.equipment_value)}
                  </Table.Cell>
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[4]}>
                    {parseBRL(data.extra_costs)}
                  </Table.Cell>
                  <Table.Cell mobileHead={TABLE_COLUMN_NAME[5]}>
                    {parseBRL(data.total_value)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </Radio.Group>
      </Radio.Root>
    </div>
  )
}
