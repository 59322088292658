import { PositionEnum, type InfoPosition } from '../types'

export const managePositions = (currentPosition: number): InfoPosition => {
  switch (currentPosition) {
    case 0:
      return {
        position: 0,
        key: PositionEnum.unclassified,
      }

    case 1:
      return {
        position: 1,
        key: PositionEnum.first,
      }

    case 2:
      return {
        position: 2,
        key: PositionEnum.second,
      }

    case 3:
      return {
        position: 3,
        key: PositionEnum.third,
      }

    default:
      return {
        position: currentPosition,
        key: PositionEnum.nonPodium,
      }
  }
}
