import { EquipmentCard } from '../EquipmentCard'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { EquipmentsHeader } from './components/EquipmentsHeader'
import { AddEquipmentModal } from './components/AddEquipmentModal'
import * as S from './styles'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

export function Equipments() {
  const { quotationSelected, openModalToAddNewEquipment } =
    useEquipmentContainerStore()

  const hasAddedProducts =
    quotationSelected?.added_products &&
    quotationSelected?.added_products?.length > 0

  return (
    <>
      <S.EquipmentsWrapper>
        <EquipmentsHeader />
        <S.EquipmentsList>
          {quotationSelected?.products?.map((product: IQuotationProductDTO) => (
            <EquipmentCard key={product.id} product={product} />
          ))}
        </S.EquipmentsList>

        {hasAddedProducts && (
          <S.ExtraItensWrapper>
            <h2>Itens extras</h2>
            <S.EquipmentsList>
              {quotationSelected?.added_products?.map(
                (product: IQuotationProductDTO) => (
                  <EquipmentCard
                    key={product.id}
                    product={{ ...product, is_added: true }}
                  />
                ),
              )}
            </S.EquipmentsList>
          </S.ExtraItensWrapper>
        )}
      </S.EquipmentsWrapper>
      {openModalToAddNewEquipment && <AddEquipmentModal />}
    </>
  )
}
