import { AlertWithTitle, type IAlertWithTitle } from 'components/AlertWithTitle'

import { PotentiallyVulnerableContainer } from './styles'

interface IPotentiallyVulnerableClientProps
  extends Pick<IAlertWithTitle, 'onClose'> {
  message: {
    title: string
    description: string
  }
}

export function PotentiallyVulnerableClientAlert({
  onClose,
  message,
}: IPotentiallyVulnerableClientProps) {
  return (
    <PotentiallyVulnerableContainer>
      <AlertWithTitle
        onClose={onClose}
        variant="warning"
        title={message.title}
        description={message.description}
        withButton={false}
      />
    </PotentiallyVulnerableContainer>
  )
}
