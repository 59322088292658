import { LinearProgress } from '@material-ui/core'
import { RouteWithSubRoutes } from 'components'
import { Sidebar } from '_Distributor/components/Sidebar'
import { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import * as S from './styles'
import { type IRoute } from 'types/routes'
import useWindowSize from 'hooks/useWindowSize'
import { Header } from '_Distributor/components/Header'

interface IHomeLayoutProps {
  route: IRoute[]
}

export function HomeLayout({ route }: IHomeLayoutProps) {
  const { width } = useWindowSize()
  const IS_MOBILE = width < 1280

  return (
    <S.LayoutRoot>
      {!IS_MOBILE ? <Sidebar.Desktop /> : <Sidebar.Mobile />}

      <S.LayoutWrapper>
        <Header />
        <S.LayoutContent>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {route?.map((mappedRoute: IRoute) => (
                <RouteWithSubRoutes key={mappedRoute?.route} {...mappedRoute} />
              ))}
            </Switch>
          </Suspense>
        </S.LayoutContent>
      </S.LayoutWrapper>
    </S.LayoutRoot>
  )
}
