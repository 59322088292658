import { Loading } from '@77sol/core'
import { Box } from '@material-ui/core'
import {
  EmptyState,
  FinancingCard,
  PaperRedirectTo,
  TableList,
} from 'components'
import { FINANCING_COLUMNS } from '../../constants'
import { useStyles } from './styles'
import { useHistory } from 'react-router-dom'

export function Content({
  table,
  onClick,
  isLoading,
  financinigsList,
  selectedFinancing,
  bindedFinancing,
  openBindFinancing,
  isFinancingPerValue,
}) {
  const { isBinded, financingId, orderId, orderCode } = bindedFinancing
  const history = useHistory()

  const PaperRedirectVisible = !!(isBinded && isFinancingPerValue)
  const classes = useStyles({ PaperRedirectVisible })

  const redirectToOrder = () => {
    history.push(`/pedidos/${orderId}`)
  }

  if (isLoading) {
    return (
      <Box className={classes.noContent}>
        <Loading isLoading={isLoading} />
      </Box>
    )
  }

  if (!financinigsList.length) {
    return (
      <Box className={classes.noContent}>
        <EmptyState text="Nenhum financiamento encontrado!" />
      </Box>
    )
  }

  return (
    <>
      {PaperRedirectVisible && (
        <PaperRedirectTo
          margin={3}
          iconSize={25}
          textSize="small"
          tooltip="Visualizar pedido"
          text={`Financiamento #${financingId} atrelado ao pedido: #${orderCode}`}
          redirectTo={redirectToOrder}
        />
      )}
      <Box px={3} className={classes.container}>
        {table ? (
          <TableList
            size="xsmall"
            headerWeight="lighter"
            values={financinigsList}
            columns={FINANCING_COLUMNS}
            selectedRow={selectedFinancing}
            onRowClick={onClick}
            LastItem={null}
          />
        ) : (
          financinigsList.map((financing, i) => (
            <Box key={i} mb={2}>
              <FinancingCard
                values={financing}
                isBinded={isBinded}
                onClick={() => onClick(financing)}
                {...(isFinancingPerValue && {
                  openBindFinancing: () => openBindFinancing(financing),
                })}
              />
            </Box>
          ))
        )}
      </Box>
    </>
  )
}
