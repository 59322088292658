/**
 *
 * @param {*} values
 * @returns
 */
export const validateData = (values = {}) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    acc[key] = !value ? 'invalid' : 'default'
    return acc
  }, {})
}
