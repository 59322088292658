import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { DeleteIcon } from '@77sol/icons/dist'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useDispatch } from 'react-redux'
import { Button, Spacer, useToast } from '@77sol/core'
import palette from 'app_palette'
import * as ProposalAction from 'store/actions'
import API from 'api'
import { useStyles } from './styles'

function ModalRemoveSizing({
  modalDeleteSizing,
  setModalDeleteSizing,
  sizingToDelete,
  index,
  callback,
  structureLabel,
  proposal,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { onErrorOpenToast } = useToast()

  const [loadingRemoveSizing, setLoadingRemoveSizing] = useState(false)

  const handleRemoveSizing = () => {
    setLoadingRemoveSizing(true)

    API.delete(`/proposal/delete/dimensioning/${sizingToDelete.id}`)
      .then(() => {
        dispatch(ProposalAction.RemoveSizingRedux(sizingToDelete.id))
        setLoadingRemoveSizing(false)
        setModalDeleteSizing(false)
        const allQuotesToDelete = proposal.quotes.filter(
          (item) => item.quotation.dimensioning_id === sizingToDelete.id,
        )
        allQuotesToDelete.forEach((item) => {
          dispatch(ProposalAction.RemoveQuoteRedux(item.id))
        })
        callback()
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível remover este dimensionamento!')
      })
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={modalDeleteSizing}
      onClose={() => setModalDeleteSizing(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalDeleteSizing} style={{ border: 'none' }}>
        <div className={classes.paperModalCards}>
          <Grid
            style={{
              height: '-webkit-fill-available',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            container
          >
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={12}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justyfyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <DeleteIcon width="40px" color={palette.primary[300]} />
                <Spacer size="24" direction="vertical" />
                <Typography
                  type="display_x_small_bold"
                  color="primary"
                  colorWeight="300"
                >
                  Deseja deletar este dimensionamento?
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Spacer size="32" direction="horizontal" />
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              item
              xs={12}
              md={12}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '12px 32px',
                  borderRadius: '8px',
                  border: `1px solid ${palette.grayscale[300]}`,
                  width: '100%',
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                    item
                    xs={12}
                    md={4}
                  >
                    <Typography
                      type="text_medium"
                      color="primary"
                      colorWeight="300"
                    >
                      Dimensionamento {index + 1}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: 'flex', flexDirection: 'column' }}
                    item
                    xs={12}
                    md={2}
                  >
                    <Typography
                      type="link_x_small"
                      color="grayscale"
                      colorWeight="800"
                    >
                      Potência
                    </Typography>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      {(sizingToDelete?.potenciaCC / 1000)?.toFixed(2)} kWp
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: 'flex', flexDirection: 'column' }}
                    item
                    xs={12}
                    md={2}
                  >
                    <Typography
                      type="link_x_small"
                      color="grayscale"
                      colorWeight="800"
                    >
                      Média de consumo
                    </Typography>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      {sizingToDelete?.consumoMensalMedio?.toFixed(0)} kwh/mês
                    </Typography>
                  </Grid>
                  <Grid
                    style={{ display: 'flex', flexDirection: 'column' }}
                    item
                    xs={12}
                    md={4}
                  >
                    <Typography
                      type="link_x_small"
                      color="grayscale"
                      colorWeight="800"
                    >
                      Local de instalação
                    </Typography>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      {structureLabel(sizingToDelete?.estrutura)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Spacer size="48" direction="horizontal" />
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              item
              xs={12}
              md={12}
            >
              <Button
                onClick={() => handleRemoveSizing()}
                color="red"
                style={{ marginRight: '16px' }}
                isLoading={loadingRemoveSizing}
                noMargin
              >
                Deletar
              </Button>
              <Button
                variant="outlined"
                noMargin
                onClick={() => setModalDeleteSizing(false)}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalRemoveSizing
