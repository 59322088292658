import { palette } from '@77sol/styles'
import styled from 'styled-components'

interface IRoundedButtonProps {
  color?: 'red' | 'primary'
}

export const Button = styled.button<IRoundedButtonProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  outline: none;
  cursor: pointer;

  border-radius: 24px;
  border: ${({ color }) => color && `2px solid ${palette[color][300]}`};
  padding: 8px 20px;
  background-color: transparent;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${({ color }) => color && `${palette[color][300]}`};
  transition: 0.2s;

  &:hover:not(:disabled) {
    background-color: ${({ color }) => color && `${palette[color][100]}`};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${palette.grayscale[300]};
    border: 2px solid ${palette.grayscale[300]};
    pointer-events: none;
  }

  & svg {
    width: 18px;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    gap: 5px;
    font-size: 11px;

    & svg {
      width: 14px;
    }
  }

  @media (max-width: 425px) {
    padding: 4px 8px;
    gap: 4px;
    font-size: 11px;
  }
`
