import styled from 'styled-components'

export const Form = styled.div`
  display: grid;
  grid-gap: 8px;

  button {
    margin: 10px 0;
  }
`

export const BestPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
