import { ArrowUpIcon } from '@77sol/icons/dist'
import { Button } from '@77sol/core'
import * as S from './styles'
import { useMediaQuery } from '@material-ui/core'

interface IReturnToTopButtonProps {
  onClick: () => void
}

export const ReturnToTopButton = ({ onClick }: IReturnToTopButtonProps) => {
  const downSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  return (
    <S.Container>
      <Button
        onClick={onClick}
        startIcon={<ArrowUpIcon />}
        onlyIcon={downSm && <ArrowUpIcon />}
        size="small"
        noMargin
      >
        Voltar ao topo
      </Button>
    </S.Container>
  )
}
