import styled from 'styled-components'

export const Wrapper = styled.section`
  .tab-content {
    flex: 1;
    overflow: auto;
    min-height: 65vh;
    padding-bottom: 80px;
  }

  @media (max-width: 1280px) {
    padding-bottom: 50px;
  }
`

export const Container = styled.div`
  background-color: #ffffff;
  padding: 48px 32px;
  flex: 1;
  width: 100%;
  position: relative;

  @media (max-width: 1280px) {
    padding: 0 16px;
  }
`

export const NewFeatureModalGIF = styled.img`
  margin-top: 8px;
`
