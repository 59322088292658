import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { triggerTypes } from 'services/tracker/properties/triggerType'

export function useRequestFinancing() {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()

  const { logEvent } = useAmplitude()

  const handleOpenFinancingPerValueModal = ({
    triggeredByDropdown = false,
  }) => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.requestFinancingKey,
      type: triggeredByDropdown
        ? triggerTypes.dropdownMenu.option
        : triggerTypes.button,
    })
    setFinancingPerValueModalOpen()
  }

  return { handleOpenFinancingPerValueModal }
}
