export const ORDER_TABLE_COLUMNS = [
  '',
  'ID pedido',
  'Data',
  'Status',
  'Valor total',
]

export const TRACKER_OPTIONS = {
  origin: '(Modal) Atrelar pedido ao financiamento por valor',
}
