import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '50px',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#F7F7FC',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  backdrop: {
    zIndex: 30,
    color: '#fff',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
  indicator: {
    backgroundColor: '#1335c6',
  },
  scrollButtons: {
    color: '#707072',
  },
  input: {
    padding: '10px',
  },
  relative: {
    position: 'relative',
  },
  paddingSectionMarginLeft: {
    paddingLeft: '4px',
    position: 'relative',
  },
  paddingSectionMarginRight: {
    paddingRight: '4px',
    position: 'relative',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
