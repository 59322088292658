import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppConfig from 'globalConfigs'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Divider,
  Box,
} from '@material-ui/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import API from 'api'
import logoImg from '../../../../../../../../assets/img/77sol-img.png'
import logoImgCopernico from '../../../../../../../../assets/img/logo-copernico.png'

moment.locale('pt-br')

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f5f5f5',
    color: 'black',
    borderBottom: '1px solid #f5f5f5',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #eeeeee',
  },
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: theme.spacing(6),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100],
  },
}))

function Details(props) {
  const {
    className,
    id,
    produtos,
    integrador,
    valorTotal,
    desconto,
    cliente,
    potencia,
    adminClient,
    frete,
    criadoData,
    isAdmin,
    delivery_days,
    ...rest
  } = props
  const tokenRegister = localStorage.getItem('sessionToken')
  const [loading] = useState(false)
  const [logs, setLogs] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if (id) {
      API.get(`/proposal/quotation/getlog/${id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: tokenRegister,
        },
      })
        .then((res) => {
          let finalArr = res.data.products.add
          finalArr = [...finalArr, ...res.data.products.changes]
          finalArr = [...finalArr, ...res.data.products.remove]
          finalArr = [...new Set(finalArr)]
          setLogs(finalArr)
        })
        .catch(() => {})
    }
  }, [id, valorTotal])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div className="test-pdf">
        {loading ? (
          <center style={{ margin: '50px 0px' }}>
            <CircularProgress
              disableShrink
              size={30}
              thickness={4}
              style={{ color: 'gray', marginTop: '20px' }}
            />
            <Typography
              variant="h5"
              style={{ color: 'gray', paddingTop: '10px' }}
            >
              Carregando cotação ...
            </Typography>
          </center>
        ) : (
          <CardContent className={classes.content}>
            <br />
            <br />

            <Grid
              container
              justify="space-between"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <img
                  alt="Brand"
                  src={
                    currentEnterpriseConfigs?.whiteLabelLogo
                      ? logoImgCopernico
                      : logoImg
                  }
                  height={currentEnterpriseConfigs.logoHeight}
                />
              </Grid>
              <Grid item />
            </Grid>

            <br />
            <br />

            <Grid
              className={clsx(classes.marginTop, classes.dates)}
              container
              justify="space-between"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <Typography component="h4" gutterBottom variant="overline">
                  Data da cotação
                </Typography>
                <Typography>
                  {moment(new Date(criadoData)).format('L')}
                </Typography>
              </Grid>

              <Grid item align="right">
                <Typography style={{ fontSize: '20px' }}>
                  Cotação <b>#{id || ''}</b> -{' '}
                  <b>
                    {potencia}
                    kWp
                  </b>
                </Typography>
              </Grid>
            </Grid>

            <div className={classes.marginTop}>
              {adminClient && (
                <Grid item>
                  <Typography component="h4" gutterBottom variant="overline">
                    Vendedor
                  </Typography>
                  <Typography>{integrador}</Typography>
                </Grid>
              )}
            </div>

            {cliente && (
              <>
                {cliente.label ? (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Cliente
                    </Typography>
                    <Typography>{adminClient || integrador}</Typography>
                  </div>
                ) : (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Cliente
                    </Typography>
                    <Typography>{adminClient || integrador}</Typography>
                  </div>
                )}
              </>
            )}

            {frete && (
              <>
                {frete && (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Frete
                    </Typography>
                    <Typography>
                      {frete === 'sem_frete' ? 'Sem Frete' : frete}
                    </Typography>
                  </div>
                )}
              </>
            )}

            <Table className={classes.marginTop}>
              <TableHead
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <StyledTableRow>
                  <StyledTableCell style={{ border: 'none' }}>
                    <b>Itens</b>
                  </StyledTableCell>
                  <StyledTableCell style={{ border: 'none' }} />
                  <StyledTableCell
                    style={{ textAlign: 'right', border: 'none' }}
                  >
                    <b>Quantidade</b>
                  </StyledTableCell>
                  {/*
                  <TableCell align="right">Valor</TableCell>
                  */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {produtos?.map((item) => (
                  <StyledTableRow
                    key={item.id}
                    style={{ borderBottom: 'none' }}
                  >
                    <StyledTableCell style={{ border: 'none' }}>
                      {item.titulo}
                      {currentEnterpriseConfigs?.canShowProductsLogs && (
                        <>
                          {logs.find((log) => log === item.titulo) &&
                            !isAdmin && (
                              <div
                                style={{ color: 'orange', fontSize: '13px' }}
                              >
                                Este item sofreu alteração direta do usuário.
                              </div>
                            )}
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell style={{ border: 'none' }} />
                    <StyledTableCell
                      style={{ textAlign: 'right', border: 'none' }}
                    >
                      <strong style={{ color: '#1335C6', fontSize: '17px' }}>
                        x {item.qtde}
                      </strong>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <div
              style={{
                padding: '30px 60px 30px 60px',
                backgroundColor: '#f5f5f5',
                width: '100%',
                marginTop: '30px',
              }}
            >
              <br />
              <br />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '40%' }}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 'bold', textAlign: 'left' }}
                  >
                    A Proposta
                  </Typography>
                </div>
                <div style={{ width: '40%' }}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 'bold', textAlign: 'left' }}
                  >
                    Pagamento
                  </Typography>
                </div>
                <div style={{ width: '20%' }}>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 'bold', textAlign: 'left' }}
                  >
                    Valor total
                  </Typography>
                </div>
              </Box>

              <br />
              <Divider />
              <br />

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '40%' }}>
                  <Typography style={{ width: '80%' }}>
                    <ul>
                      <li>Frete incluso;</li>
                      <li>Validade de 2 dias;</li>
                      {frete === 'sem_frete' ? (
                        <li>
                          Pedido sem frete. Os itens adquiridos devem ser
                          retirados junto ao distribuidor, a partir da
                          confirmação de pagamento (mediante a disponibilidade
                          dos equipamentos comprados);
                        </li>
                      ) : (
                        <li>
                          O prazo de entrega é de {delivery_days} dias úteis a
                          partir da confirmação de pagamento, mediante a
                          disponibilidade de equipamentos;
                        </li>
                      )}
                    </ul>
                  </Typography>
                </div>
                <div style={{ width: '40%' }}>
                  <Typography style={{ width: '80%' }}>
                    <ul>
                      <li>
                        Via boleto, transferência bancária ou em até 12x <br />
                        no cartão (inclui juros), no ato da aprovação da compra;
                      </li>
                    </ul>
                  </Typography>
                </div>
                <div style={{ width: '20%' }}>
                  <Typography
                    style={{
                      textAlign: 'left',
                      color: '#1335C6',
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}
                  >
                    {parseFloat(valorTotal || 0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Typography>
                </div>
              </Box>

              <br />
              <Divider />
              <br />

              <br />
              <Divider />
              <br />
              <br />

              <Grid container justify="space-between">
                <Grid item>
                  <img
                    alt="Brand"
                    src={
                      currentEnterpriseConfigs?.whiteLabelLogo
                        ? logoImgCopernico
                        : logoImg
                    }
                    height={
                      currentEnterpriseConfigs?.whiteLabelLogo
                        ? '100px'
                        : '50px'
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    {AppConfig?.appinfo?.corporateName}
                    <br />
                    CNPJ: {AppConfig?.appinfo?.document}
                    <br />
                    {AppConfig?.appinfo?.address}
                    <br />
                    {AppConfig?.appinfo?.secondAddress}
                  </Typography>
                </Grid>
              </Grid>

              <br />
              <br />
            </div>
          </CardContent>
        )}
      </div>
    </Card>
  )
}

Details.propTypes = {
  className: PropTypes.string,
}

export default Details
