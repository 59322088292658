import { type IAlertCloseProps } from '@77sol-ui/atoms'
import { AlertWithTitle } from 'components/AlertWithTitle'
import { useWhatsappSend } from 'hooks/useWhatsappSend'
import { ApprovedFinancingContainer } from './styles'
import { DEFAULT_WHATSAPP_NUMBER } from 'constants/general'

interface IApprovedFinancingAlertProps
  extends Pick<IAlertCloseProps, 'onClose'> {
  id?: string
  phone?: string
}

export function ApprovedFinancingAlert({
  id,
  phone,
  onClose,
}: IApprovedFinancingAlertProps) {
  const { handleWhatsAppSend } = useWhatsappSend()

  function handleSendMessageToWhatsApp() {
    if (!id) return

    const whatsappNumber = phone ?? DEFAULT_WHATSAPP_NUMBER

    const message = `Olá, meu financiamento #${id} foi aprovado, quero seguir com a formalização.`

    handleWhatsAppSend(whatsappNumber, message)
  }

  return (
    <ApprovedFinancingContainer>
      <AlertWithTitle
        variant="success"
        title="Parabéns, seu crédito foi aprovado!"
        description="Entre em contato com o seu gerente de contas para formalizar seu financiamento."
        buttonText="Falar com gerente"
        onClose={onClose}
        onClick={handleSendMessageToWhatsApp}
      />
    </ApprovedFinancingContainer>
  )
}
