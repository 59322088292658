import { type IChartData } from '@77sol-ui/molecules'
import { colors } from '@77sol-ui/tokens'

const lineStyles: Record<
  'potencia' | 'co2',
  Omit<IChartData, 'data' | 'yAxisID'>
> = {
  potencia: {
    label: 'Potência',
    lineColor: colors.green[300],
    backgroundColor: colors.green[300],
    fillColor: colors.green[300],
    lineWidth: 3,
  },
  co2: {
    label: 'Co2 evitado',

    lineColor: 'transparent',
    backgroundColor: 'transparent',
    fillColor: 'transparent',
    lineWidth: 3,
  },
}

export { lineStyles }
