import * as S from './styles'
import { useAdvertisingModal } from './hooks/useAdvertisingModal'

export function AdvertisingModal() {
  const { handleRedirect, handleCloseModal, advertisingProps } =
    useAdvertisingModal()

  return (
    <S.ModalWrapper open onClose={handleCloseModal}>
      <S.ModalContent>
        <S.ImageContainer>
          <img
            className="desktop"
            src={advertisingProps?.desktop_url}
            alt={advertisingProps?.title}
            onClick={handleRedirect}
          />
          <img
            className="mobile"
            src={advertisingProps?.mobile_url}
            alt={advertisingProps?.title}
            onClick={handleRedirect}
          />
        </S.ImageContainer>
      </S.ModalContent>
    </S.ModalWrapper>
  )
}
