import { useQuery } from '@tanstack/react-query'
import { storeOrdersNotFinancing } from '../services/api'
import { GET_STORE_ORDERS_NOT_FINANCING_KEY } from '../constants/endpointKeys'

export const useFetchStoreOrdersNotFinancing = () => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [GET_STORE_ORDERS_NOT_FINANCING_KEY],
    queryFn: async () => await storeOrdersNotFinancing.get(),
  })

  return { ordersAvailableForFinancing: data, isLoading, isSuccess, isError }
}
