import { useMutation } from '@tanstack/react-query'
import { validateQuotationKitChanges } from '../services/api'
import { VALIDATE_QUOTATION_KIT } from '../constants/endpointKeys'

export function useVerifyQuotationKit() {
  const { mutateAsync: validateKit, ...mutation } = useMutation({
    mutationKey: [VALIDATE_QUOTATION_KIT],
    mutationFn: async (id: number) => await validateQuotationKitChanges(id),
    onSuccess: (data) => {
      return data
    },
  })

  return { ...mutation, validateKit }
}
