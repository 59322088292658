import { useQuery } from '@tanstack/react-query'
import { CAMPAIGN_MODALS } from '../constants/endpointKeys'
import { campaignModals } from '../services/api'

export function useCampaignModals() {
  const { ...methods } = useQuery({
    queryKey: [CAMPAIGN_MODALS],
    queryFn: async () => await campaignModals.get(),
  })

  return methods
}
