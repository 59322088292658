import { colors, fontSize, fontWeight } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const StyledRootWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const StyledTitle = styled.h2`
  font-size: ${fontSize.sm};
  font-family: DM Sans;
  font-weight: 600;
  max-width: 90%;
  color: ${colors.blue[800]};
`

export const StyledInsuranceAlertDescription = styled.ul<{
  isEnabled?: boolean
}>`
  list-style: disc;

  > p,
  li {
    font-family: DM Sans;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.xs};
    line-height: 20px;
    color: ${colors.blue[800]};
  }

  li {
    margin-left: 15px;
    margin-top: 5px;
  }

  .attention-paragraph {
    font-weight: bold;
    font-size: ${fontSize.sm};
    margin-top: 12px;
    color: ${({ isEnabled }) =>
      isEnabled ? colors.blue[800] : colors.red[300]};
  }
`
