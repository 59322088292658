import styled from 'styled-components'
import { Modal } from '@77sol-ui/molecules'

export const Footer = styled(Modal.Footer)`
  margin-top: 0 !important;
`

export const Overlay = styled(Modal.Overlay)`
  z-index: 1300;
`

export const Wrapper = styled(Modal.Wrapper)`
  z-index: 1301;
`
