import { FillMoonIcon } from '@77sol/icons/dist'
import * as S from './styles'
import { type IEmptyStateProps } from './EmptyState.types'

function EmptyState({ Icon, text, action, minHeight }: IEmptyStateProps) {
  return (
    <S.Container action={action} minHeight={minHeight}>
      {Icon || <FillMoonIcon />}
      <span>{text}</span>
      {action}
    </S.Container>
  )
}

export default EmptyState
