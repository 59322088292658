import { createTheme } from '@material-ui/core'
import { defaultTheme, copernicoTheme } from '@77sol/styles'
import appTheme from 'app_theme'
import { colors } from '@77sol-ui/tokens'

export const styledComponentsTheme = {
  colors,
}

const theme = createTheme(
  appTheme === 'copernico' ? copernicoTheme : defaultTheme,
)

export default theme
