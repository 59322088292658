import styled from 'styled-components'
import { DropdownMenu } from '@77sol/core'

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const DropdownMenuPortal = styled(DropdownMenu.Portal)`
  margin-right: 10px;
`

export const DropdownMenuContent = styled(DropdownMenu.Content)`
  z-index: 2;
  right: 10px;
`
