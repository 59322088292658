import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import { useAdminInfosDisponibilityProducts } from 'domains/admin/infos-disponibility-products/hooks/useAdminInfosDisponibilityProducts'

import { type FieldValues } from 'react-hook-form'

interface ISelectVoltageProps<T extends FieldValues>
  extends Omit<ISelectProps<T>, 'options'> {
  currentPhase?: string
}

export const SelectVoltage = <T extends FieldValues>({
  currentPhase,
  ...props
}: ISelectVoltageProps<T>) => {
  const { data, isFetching } = useAdminInfosDisponibilityProducts(currentPhase)

  const voltagesData = [
    { label: props.placeholder as string, value: props.placeholder as string },
    ...(data?.voltages || []),
  ]

  return <Select {...props} options={voltagesData} isLoading={isFetching} />
}
