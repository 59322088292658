import { type IModalWrapperProps, Modal } from '@77sol-ui/molecules'
import { CircularProgress } from 'components/CircularProgress'
import * as S from './styles'

export function Loading({ ...props }: IModalWrapperProps) {
  return (
    <Modal.Wrapper maxWidth="530px" maxHeight="90vh" {...props}>
      <Modal.Content>
        <S.Container>
          <CircularProgress />
          <Modal.Description>
            Aguarde, estamos criando a simulação do seu financiamento.
          </Modal.Description>
        </S.Container>
      </Modal.Content>
    </Modal.Wrapper>
  )
}
