import styled from 'styled-components'

interface InsuranceTriggerProps {
  isOpen?: boolean
}

export const StyledInsuranceTrigger = styled.button<InsuranceTriggerProps>`
  display: flex;
  align-items: center;
  gap: 2px;

  font-family: DM Sans;
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;

  svg {
    width: 18px;
    transition: 0.4s;
    transform: ${(props) => props.isOpen && 'rotate(180deg)'};
  }

  @media (max-width: 780px) {
    margin-top: 4px;
  }
`
