import API from 'api'
import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import { RemoveQuoteRedux } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useToast } from '@77sol/core'
import { useCallback, useState } from 'react'

interface IUseDeleteQuotation {
  quotation: IQuotationDTO
  onError?: () => void
  onSuccess?: () => void
  onStopDelete?: () => Promise<void> | void
}

export function useDeleteQuotation({
  quotation,
  onError,
  onSuccess,
  onStopDelete,
}: IUseDeleteQuotation) {
  const [isLoading, setLoading] = useState(false)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const dispatch = useDispatch()
  const quotationId = quotation.id

  const handleDelete = useCallback(() => {
    setLoading(true)

    if (onStopDelete?.()) {
      setLoading(false)

      return
    }

    API.delete(`/proposal/remove/quotation/${quotationId}`)
      .then(() => {
        dispatch(RemoveQuoteRedux(quotationId))
        onSuccessOpenToast(`Cotação #${quotationId} deletada com sucesso.`)
        onSuccess?.()
      })
      .catch(() => {
        onErrorOpenToast('Ocorreu um erro ao deletar a cotação.')
        onError?.()
      })
      .finally(() => {
        setLoading(false)
      })
  }, [quotationId, onStopDelete])

  return { isLoading, handleDelete }
}
