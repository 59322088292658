const now = new Date()
const nowy = now.getFullYear()

function range(start, stop, step) {
  const a = [start]; let
    b = start;
  while (b < stop) {
    a.push(b += step || 1);
  }
  const yearList = []
  a.map((year) => yearList.push({
    label: year,
    value: year,
  }))
  return yearList;
}

export default range(2020, nowy)