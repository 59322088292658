import API from 'api'
import { type AdminStructureTypesProductsGETResponse } from './types'

export const adminStructureTypesProducts = {
  get: async () => {
    return await API.get<AdminStructureTypesProductsGETResponse[]>(
      '/admin/structure-types-products',
    ).then(({ data = [] }) =>
      data.map(({ name, slug }) => ({
        label: name,
        value: slug,
      })),
    )
  },
}
