import { useQueryClient } from '@tanstack/react-query'
import { useFetchSignals } from 'domains/user/signal'
import { GET_SIGNALS } from 'domains/user/signal/constants/endpointKeys'
import { type INotificationDTO } from 'dto/NotificationDTO'
import {
  NOTIFICATION_CHANNEL_ENUM,
  NOTIFICATION_EVENT_ENUM,
} from 'enums/NotificationsEnum'
import useSocketPusher from 'hooks/useSocketPusher'
import { useSelector } from 'react-redux'
import { type RootState } from 'store'

export const useSignals = () => {
  const { data: signals } = useFetchSignals()

  const client = useQueryClient()

  const userId = useSelector<RootState>(
    (state) => state?.ReducerProfile?.userProfile?.id,
  )

  const updateSignals = (message: INotificationDTO) => {
    if (message.type === 'order') {
      client.invalidateQueries([GET_SIGNALS])
    }
  }

  useSocketPusher({
    enabled: Boolean(userId),
    channelName: `${NOTIFICATION_CHANNEL_ENUM.USER_NOTIFICATION}${
      userId as string
    }`,
    events: [
      {
        eventName: NOTIFICATION_EVENT_ENUM.NOTIFY_USER,
        callback: updateSignals,
      },
    ],
  })

  return { signals }
}
