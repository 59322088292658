import { QuotationCardQueue } from './components/QuotationCardQueue'
import { QuotationKebabMenu } from './components/QuotationKebabMenu'
import { SelectQuotation } from './components/SelectQuotation'

import { useCustomizeKitHeader } from './hooks/useCustomizeKitHeader'

import { MobileWrapper } from './styles'

export function CustomizeKitHeader() {
  const { isMobile, quotations, quotationSelected, setQuotationSelected } =
    useCustomizeKitHeader()

  if (isMobile) {
    return (
      <MobileWrapper>
        <SelectQuotation
          quotations={quotations}
          quotationSelected={quotationSelected}
          onQuotationSelect={setQuotationSelected}
        />

        <QuotationKebabMenu />
      </MobileWrapper>
    )
  }

  return (
    <QuotationCardQueue
      quotations={quotations}
      quotationSelected={quotationSelected}
      onQuotationSelect={setQuotationSelected}
    />
  )
}
