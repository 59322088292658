import { TabContent, TabList } from '@77sol/core'
import { Box } from '@material-ui/core'
import API from 'api'

import useRedirect from 'hooks/useRedirect'
import OrderItemsTab from '../components/OrderTabs/OrderItemsTab'
import OrderPendingFields from '../components/OrderTabs/OrderPendingsTab'
import styles from './styles'

import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import { usePendingOrderFields } from '../components/OrderTabs/OrderPendingsTab/hooks/usePendingOrderFields'
import { Faturamento } from './Faturamento'
import { Observacoes } from './Observacoes'
import { usePendingForm } from '../components/OrderTabs/OrderPendingsTab/hooks/usePendingForm'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { fileTracker } from 'services/tracker/events/files/trackers'

import {
  ORDER_TABS_WITHOUT_PENDINGS,
  ORDER_TABS_WITH_PENDINGS,
  ORDER_CLOSED_STATUS,
  TABS_ENUM,
  ENABLE_PENDING_FIELDS_ORDER,
} from '../constants'

import { EmptyState, Header, Loading, PaperRedirectTo } from 'components'

import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import {
  ORDER_CLOSED_CLIENT_STATUS,
  ORDER_STATUS_CLIENT_ENUM,
} from 'enums/order/OderStatusClientEnum'
import { MoreActionsMenu } from '../components/MoreActionsMenu'

import Status from './Status'
import { ORDER_STATUS_TRADUCTION_ENUM } from 'enums/order/OderStatusTraductionEnum'
const { hasFinancingPerValue } = currentEnterpriseConfigs

function Tabs({
  id,
  codigo,
  onBack,
  selectedOrder,
  onClickCancel,
  onClickShareOrderTracking,
}) {
  const { setOrderPendingInfosFieldState } = usePendingOrderFields()
  const [selectedTab, setSelectedTab] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const [produtos, setProdutos] = useState([])
  const [faturamento, setFaturamento] = useState({})
  const [pedido, setPedido] = useState({})

  const [isLoading, setIsLoading] = useState(false)
  const classes = styles()
  const { redirectTo } = useRedirect()

  const { ...methods } = usePendingForm(id)
  const { logEvent } = useAmplitude()
  const cannotShowPendingTabs = ORDER_CLOSED_CLIENT_STATUS.includes(
    pedido.status,
  )

  const orderIsReserved =
    ORDER_STATUS_CLIENT_ENUM.RESERVADO == selectedOrder?.status

  const orderIsCanceled = ORDER_CLOSED_STATUS.includes(
    Number(selectedOrder?.status),
  )

  const orderLinkedInFinancing = pedido?.requisition_id && pedido?.financing_id
  const originalQuote = pedido?.quotation?.id

  const redirecToFinancing = () => {
    if (orderLinkedInFinancing) {
      redirectTo(
        `/financiamentos/valor/${pedido?.requisition_id}/simulacao/${pedido?.financing_id}`,
      )
    }
  }

  const redirecToQuotation = () => {
    logEvent(ordersTracker.actions.seeQuotationOrigin, {
      origin: '/pedidos',
    })
    redirectTo('/projetos/propostas', {
      projectId: pedido?.quotation?.project_id,
      selectedProposalId: pedido?.quotation?.proposal_id,
      customerName: pedido?.quotation?.customer_name,
    })
  }

  const handleChangeOrderDetailsTab = (tab) => {
    logEvent(ordersTracker.actions.changeTab, {
      origin: '/pedidos',
      tab: ORDER_TABS_WITH_PENDINGS[tab],
    })
    setSelectedTab(tab)
  }

  function getDefaultSelectedTab(status) {
    const orderDetailsTabs = {
      [ORDER_STATUS_TRADUCTION_ENUM.FORECAST]: 1,
      [ORDER_STATUS_TRADUCTION_ENUM.ENTREGE]: 2,
    }

    return orderDetailsTabs[status] || 0
  }

  const getPedido = async () => {
    setIsLoading(true)
    try {
      const { data } = await API.get(`/loja/pedidos/${id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.getItem('sessionToken'),
        },
      })

      const infoPedido = data.pedido[0]
      const itensPedido = data.pedido[1]
      const defaultTab = getDefaultSelectedTab(Number(infoPedido.status))
      setSelectedTab(defaultTab)
      setProdutos(itensPedido)
      if (infoPedido.status_cliente_id) {
        setActiveStep(parseInt(infoPedido.status_cliente_id))
      } else {
        setActiveStep(parseInt(infoPedido.status))
      }
      setPedido({ ...infoPedido, quotation: data?.pedido?.[4] })
      setFaturamento(data.pedido[3][0])

      setOrderPendingInfosFieldState({
        all: false,
        some: false,
        alreadySentToReview: false,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getPedido()
    }
  }, [id])

  useEffect(() => {
    setSelectedTab(0)
    setOrderPendingInfosFieldState({
      all: false,
      some: false,
      alreadySentToReview: false,
    })
  }, [selectedOrder])

  function handleCancelOrder(id) {
    onClickCancel(id)
    logEvent(ordersTracker.actions.intentionToCancelOrder, {
      origin: '/pedidos - detalhe do pedido',
    })
  }

  const handleTrackOrderTabs = (_, order) => {
    logEvent(ordersTracker.actions.trackOrder, {
      origin: '/pedidos - detalhes do pedido',
    })
    onClickShareOrderTracking(order)
  }

  const tabs = cannotShowPendingTabs
    ? ORDER_TABS_WITHOUT_PENDINGS
    : ORDER_TABS_WITH_PENDINGS

  const { STATUS, FATURAMENTO, ITENS, OBSERVACOES, PENDENCIAS } = TABS_ENUM

  const deliveryAddress = {
    streetName: pedido.endereco,
    state: pedido.estado,
    city: pedido.cidade,
    zipCode: pedido.cep,
    number: pedido.numero,
  }

  return (
    <Box className={classes.root}>
      <Header
        id="order-header"
        onBack={onBack}
        size="huge"
        title={`Pedido - ${codigo} `}
      >
        <MoreActionsMenu
          order={selectedOrder}
          orderIsCanceled={orderIsCanceled}
          orderIsReserved={orderIsReserved}
          onCancelOrder={handleCancelOrder}
          onClickTrackOrder={handleTrackOrderTabs}
          onClickTalkToManager={() => {
            logEvent(ordersTracker.actions.talkToManager, {
              origin: '/pedidos - detalhe do pedido',
            })
          }}
          onDownloadInvoice={() => {
            logEvent(fileTracker.actions.downloadFile, {
              fileName: 'Fatura',
              origin: '/pedidos - detalhe do pedido',
            })
          }}
          onDownlodManual={() => {
            logEvent(fileTracker.actions.downloadFile, {
              origin: '/pedidos - detalhe do pedido',
              fileName: 'Manual do integrador',
            })
          }}
        />
      </Header>
      <Loading isLoading={isLoading}>
        <Box className={classes.container}>
          {hasFinancingPerValue && orderLinkedInFinancing && (
            <PaperRedirectTo
              iconSize={25}
              textSize="medium"
              tooltip="Visualizar financiamento"
              redirectTo={redirecToFinancing}
              text={`Atrelado ao financiamento: #${pedido?.financing_id}`}
            />
          )}
          {originalQuote && (
            <PaperRedirectTo
              iconSize={25}
              textSize="medium"
              tooltip="Visualizar cotação"
              redirectTo={redirecToQuotation}
              text={`Pedido #${selectedOrder?.codigo} atrelado a cotação #${originalQuote}`}
            />
          )}
          <FormProvider {...methods}>
            <Box className={classes.tabsContainer}>
              <Box paddingBottom={2}>
                <TabList
                  variant="scrollable"
                  scrollButtons={false}
                  titles={tabs}
                  onChange={handleChangeOrderDetailsTab}
                  value={selectedTab}
                />
              </Box>
              {!cannotShowPendingTabs && (
                <TabContent
                  value={selectedTab}
                  index={tabs.findIndex((item) => item === PENDENCIAS)}
                >
                  <OrderPendingFields
                    pendings={pedido?.pendings}
                    refetchOrder={getPedido}
                    order={pedido}
                    disableFields={
                      !ENABLE_PENDING_FIELDS_ORDER.includes(
                        pedido.status_cliente_id,
                      )
                    }
                  />
                </TabContent>
              )}
              <TabContent
                value={selectedTab}
                index={tabs.findIndex((item) => item === STATUS)}
              >
                <Status
                  activeStep={activeStep}
                  address={deliveryAddress}
                  orderId={selectedOrder?.id}
                  order={selectedOrder}
                />
              </TabContent>
              <TabContent
                value={selectedTab}
                index={tabs.findIndex((item) => item === ITENS)}
              >
                <OrderItemsTab items={produtos} />
              </TabContent>
              <TabContent
                value={selectedTab}
                index={tabs.findIndex((item) => item === FATURAMENTO)}
              >
                <Faturamento values={faturamento} />
              </TabContent>
              <TabContent
                value={selectedTab}
                index={tabs.findIndex((item) => item === OBSERVACOES)}
              >
                {pedido?.descricao ? (
                  <Observacoes text={pedido.descricao} />
                ) : (
                  <EmptyState text="Não há observações" />
                )}
              </TabContent>
            </Box>
          </FormProvider>
        </Box>
      </Loading>
    </Box>
  )
}

export default Tabs
