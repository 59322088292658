import { useQuery } from '@tanstack/react-query'
import { GET_EQUIPMENTS_CATEGORIES } from 'domains/quotation/constants/endpointKeys'
import { quotationService } from '../api'

export function useFetchEquipmentsCategories() {
  const equipmentsCategoriesQuery = useQuery({
    queryKey: [GET_EQUIPMENTS_CATEGORIES],
    queryFn: quotationService.getEquipmentsCategories,
  })

  return {
    ...equipmentsCategoriesQuery,
  }
}
