import { type IDefineDiscount } from '../types'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useState } from 'react'

interface IUseDefineDiscount extends IDefineDiscount {}

export function useDefineDiscount({ initialDiscount }: IUseDefineDiscount) {
  const [discount, setDiscount] = useState<number | string>(initialDiscount)

  function handleChangeDiscount(value: number) {
    if (String(value).length > 5) {
      setDiscount(String(value).slice(0, 5))
      return
    }

    if (currentEnterpriseConfigs?.defaultDiscount) {
      if (String(value).length <= 1) {
        setDiscount(value)
      } else if (value < 48) {
        setDiscount(48)
      } else if (value >= 50.4) {
        setDiscount(50.4)
      } else {
        setDiscount(value)
      }
    } else {
      setDiscount(value)
    }
  }

  return {
    discount,
    handleChangeDiscount,
  }
}
