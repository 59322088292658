import React from 'react'
import { KitCard } from './components/KitCard'
import { ChooseKitSkeleton } from '../ChooseKitSkeleton'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import * as S from './styles'
import useObserveQuery from 'hooks/useObserverQuery'
import { GET_KITS } from 'domains/kits/get-kits-dimensioningId/constants/endpointKeys'
import { useDisableKit } from './hooks/useDisableKit'

const KitList: React.FC = () => {
  const kits = useEquipmentContainerStore((state) => state.kits)
  const { disableKit } = useDisableKit()

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { isLoading } = useObserveQuery([GET_KITS, String(dimensioningId)])

  if (isLoading) return <ChooseKitSkeleton />

  return (
    <S.Container>
      {kits?.map((kit) => (
        <KitCard key={kit.title} kit={kit} disabled={disableKit(kit)} />
      ))}
    </S.Container>
  )
}

export { KitList }
