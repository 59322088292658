import { MailIcon, SendIcon, WhatsappIcon } from '@77sol/icons/dist'

type IShareIcons = (props: unknown) => React.ReactSVGElement

export const ORDER_TRACKING_SHARE_ICONS: Record<string, IShareIcons> = {
  default: SendIcon,
  email: MailIcon,
  whatsapp: WhatsappIcon,
}

export const ORDER_TRACKING_SHARE_TITLES: Record<string, string> = {
  default: 'Como deseja enviar o rastreio do pedido?',
  email: 'Confirmar envio do rastreio por e-mail',
  whatsapp: 'Confirmar envio do rastreio por WhatsApp',
}
