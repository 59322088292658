const { defineState } = require('redux-localstore')

const INITIAL_STATE = {
  proModalIsOpen: false,
}

const initialState = defineState(INITIAL_STATE)('ReducerProModal')

export function ReducerProModal(state = initialState, action) {
  switch (action.type) {
    case 'SET_PRO_MODAL_TOGGLE':
      return { ...state, proModalIsOpen: action.proModalIsOpen }
    case 'SET_PRO_MODAL_OPEN':
      return { ...state, proModalIsOpen: action.proModalIsOpen }
    case 'SET_PRO_MODAL_CLOSE':
      return { ...state, proModalIsOpen: action.proModalIsOpen }
    default:
      return state
  }
}
