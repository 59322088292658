import React, { Suspense } from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { RouteWithSubRoutes } from 'components'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const Error = props => {
  const { route } = props

  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          {route?.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </Suspense>
    </main>
  )
}

Error.propTypes = {
  route: PropTypes.object,
}

export default Error
