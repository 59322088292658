import { type INotificationDTO } from 'dto/NotificationDTO'
import { useNotificationsContext } from 'context/NotificationsContext'

import NotificationItem from '../NotificationItem'
import { Notification } from '@77sol-ui/organisms'

import { useRef } from 'react'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

import { CircularProgress } from 'components'
import { LoadingMore } from './styles'

interface INotificationsListProps {
  onClickNotification?: () => void
}

function NotificationsList({ onClickNotification }: INotificationsListProps) {
  const {
    notificationList,
    hasMoreNotifications,
    isLoadingNotifications,
    handleNextNotifications,
  } = useNotificationsContext()

  const containerRef = useRef(null)

  const notificationsNotFound =
    !isLoadingNotifications && !notificationList.length

  const { ref } = useInfiniteScroll({
    threshold: 0.5,
    root: containerRef.current,
    active: hasMoreNotifications,
    onIntersect: handleNextNotifications,
  })

  return (
    <Notification.Content>
      <Notification.Header>
        <Notification.Title>Notificações</Notification.Title>
      </Notification.Header>

      <Notification.Body ref={containerRef}>
        {isLoadingNotifications && <CircularProgress text="Carregando..." />}

        {notificationsNotFound && (
          <Notification.EmptyState
            title="Você ainda não possui notificações"
            description="Fique tranquilo! Você será notificado sobre novas atividades em seu espaço de trabalho."
          />
        )}

        {!notificationsNotFound && (
          <>
            {notificationList.map((notification: INotificationDTO) => (
              <NotificationItem
                key={notification?.id}
                notification={notification}
                onClickNotification={onClickNotification}
              />
            ))}
            {hasMoreNotifications && (
              <LoadingMore ref={ref}>Carregando...</LoadingMore>
            )}
          </>
        )}
      </Notification.Body>
    </Notification.Content>
  )
}

export { NotificationsList }
