export default function handleOverload(products) {
  const itens = {
    modulo: products.find(({ categoria }) => categoria === 62),
    inverter: products.find(({ categoria }) => categoria === 61),
  }

  if (itens.modulo && itens.inverter) {
    const { potenciaModulo, qtde: qtdeModulo } = itens.modulo
    const { potenciaInversor, qtde: qtdeInversor } = itens.inverter

    const potency = parseFloat(potenciaModulo) * parseFloat(qtdeModulo)
    const overload = parseFloat(potenciaInversor) * parseFloat(qtdeInversor)

    return ((potency / overload - 1) * 100).toFixed(0)
  }

  return '-'
}
