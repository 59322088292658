import { useAmplitude } from 'hooks/useAmplitude'
import { useState } from 'react'
import { chartFiltersTrackers } from 'services/tracker/events/chartFilters/trackers'
import {
  DEFAULT_FILTERS_STATE,
  DEFAULT_INTERVAL_LABEL,
} from 'views/Home/components/Performance/constants'
import { type IFilters } from 'views/Home/components/Performance/types'
import { useChartFilters } from '../../../hooks/useChartFilters'

interface IUseMobileFilterProps {
  onApply: (filters: IFilters) => void
  initialFilters: IFilters
}

export function useMobileFilters({
  initialFilters,
  onApply,
}: IUseMobileFilterProps) {
  const { logEvent } = useAmplitude()
  const [filters, setFilters] = useState<IFilters>(initialFilters)
  const [isOpen, setIsOpen] = useState(false)
  const { INTERVAL, onChangePeriod } = useChartFilters({ filters, setFilters })

  function onApplyFilters() {
    const selectedInterval = INTERVAL.find(
      (item) => item.value === filters.interval,
    )

    logEvent(chartFiltersTrackers.actions.applyFilters, {
      origin: '/dashboard',
      filter: 'Gráfico de Performance',
      ...filters,
      interval: selectedInterval?.label,
    })
    onApply?.(filters)
    setIsOpen?.(false)
  }

  function onClearFilters() {
    logEvent(chartFiltersTrackers.actions.applyFilters, {
      origin: '/dashboard',
      filter: 'Gráfico de Performance',
      ...DEFAULT_FILTERS_STATE,
      interval: DEFAULT_INTERVAL_LABEL,
    })
    setFilters(DEFAULT_FILTERS_STATE)
    onApply?.(DEFAULT_FILTERS_STATE)
    setIsOpen?.(false)
  }

  return {
    onApplyFilters,
    onClearFilters,
    isOpen,
    setIsOpen,
    filters,
    setFilters,
    INTERVAL,
    handleChangePeriod: onChangePeriod,
  }
}
