import { useEffect, useRef } from 'react'
import { type Channel } from 'laravel-echo'
import { useSocketPusherContext } from 'context/SocketPusherContext'

interface IEvents {
  eventName: string
  callback: (data?: any) => void
}

interface IUseSocketPusherOptions {
  enabled?: boolean
  channelName: string
  events: IEvents[]
}

const useSocketPusher = ({
  enabled = true,
  channelName,
  events,
}: IUseSocketPusherOptions) => {
  const { echo } = useSocketPusherContext()
  const channelRef = useRef<Channel | null>(null)

  useEffect(() => {
    if (!echo || !enabled) return

    channelRef.current = echo.channel(channelName)

    if (channelRef.current) {
      events.forEach(({ eventName, callback }) => {
        channelRef.current?.listen(eventName, callback)
      })
    }

    return () => {
      if (channelRef.current) {
        events.forEach(({ eventName, callback }) => {
          channelRef.current?.stopListening(eventName, callback)
        })
      }
    }
  }, [enabled, channelName, events])

  return {
    echo,
    channel: channelRef.current,
  }
}

export default useSocketPusher
