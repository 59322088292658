import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1280px) {
    flex-direction: row;
  }

  > a {
    text-decoration: none;
    color: initial;

    > button {
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
  }
`
