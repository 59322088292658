import { Wrapper } from './styles'

export interface IBadgeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  text: string
}

export function Badge({ text, ...props }: IBadgeProps) {
  return (
    <Wrapper data-testid="badge-component" {...props}>
      <span>{text}</span>
    </Wrapper>
  )
}
