import * as S from './styles'
import {
  FIRST_POSITION,
  RANKING_CONTEXT,
  SCREEN,
  THIRD_POSITION,
} from './constants'
import { useEffect, useState } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import { useFetchRankingSummary } from 'domains/ranking/summary'
import { useHistory, useLocation } from 'react-router-dom'
import { managePositions } from './utils'
import { PositionEnum, type InfoPosition } from './types'
import { Skeleton } from 'components/Skeleton'
import { useAmplitude } from 'hooks/useAmplitude'
import { integratorRankingTrackers } from 'services/tracker/events/integratorRanking/trackers'

interface SummaryRankClassificationProps {
  preventRedirect?: boolean
}

export function SummaryRankClassification({
  preventRedirect,
}: SummaryRankClassificationProps) {
  const { rankingSummaryData, isLoading } = useFetchRankingSummary()
  const { width } = useWindowSize()
  const history = useHistory()
  const location = useLocation()
  const { logEvent } = useAmplitude()

  const isNotSmallScreen = width && width > SCREEN.SM
  const containerHeight = width > SCREEN.XL ? '124px' : '108px'

  const [currentPosition, setCurrentPosition] = useState<InfoPosition>({
    position: 0,
    key: PositionEnum.unclassified,
  } as InfoPosition)

  const isClassifield = rankingSummaryData?.position >= FIRST_POSITION
  const showBackground =
    !isClassifield || rankingSummaryData?.position > THIRD_POSITION
  const currentState = RANKING_CONTEXT[currentPosition.key]

  useEffect(() => {
    setCurrentPosition(managePositions(rankingSummaryData?.position))
  }, [rankingSummaryData?.position])

  const canShowPositionMedal = isNotSmallScreen && !showBackground

  const handleRedirectToRankingPage = () => {
    logEvent(integratorRankingTrackers.actions.summaryBanner, {
      origin: location.pathname,
      action: 'Clicou no banner para ser redirecionado',
    })

    if (preventRedirect) return

    history.push('/rank-77')
  }

  return (
    <>
      {isLoading && (
        <Skeleton data-testid="loading" width="100%" height={containerHeight} />
      )}

      {!isLoading && rankingSummaryData && (
        <S.Container
          data-testid="container"
          height={containerHeight}
          onClick={handleRedirectToRankingPage}
          {...currentState.palette}
        >
          <div className="context">
            <div className="left">
              <p>{currentState.text}</p>
            </div>

            <S.Divider divider={currentState.palette.divider} />

            {canShowPositionMedal && (
              <S.MedalPosition currentPosition={currentPosition.position} />
            )}

            {isClassifield ? (
              <div data-testid="current-position" className="classification">
                <span>Você está na</span>
                <b>{currentPosition.position}°</b>
                <span>posição</span>
              </div>
            ) : (
              <span data-testid="current-position" className="unclassified">
                Não classificado
              </span>
            )}
          </div>

          <S.PodiumMedalEndMobile currentPosition={currentPosition.position} />

          {showBackground && <S.CommonClassification />}

          {isNotSmallScreen && <S.LargeMedal />}
        </S.Container>
      )}
    </>
  )
}
