import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Loading } from 'components'
import ConfigProposalContainer from '../../../views/DimensionarV2/components/StepFour'
import Equipments from '../../../views/DimensionarV2/components/StepTwo'
import API from '../../../api'
import styles from './styles'
import { useLocation } from 'react-router-dom'

function ConfigProposalContent({
  onBack,
  getFinancingSimulation,
  isLoading,
  proposal,
}) {
  const location = useLocation()
  const outdatedQuoteToReserve = location?.state?.outdatedQuoteToReserve

  const tokenRegister = localStorage.getItem('sessionToken')
  const [isFinancingLoading, setIsFinancingLoading] = useState(false)
  const [financingSimulations, setFinancingSimulations] = useState({})
  const [completeQuoteEdit, setCompleteQuoteEdit] = useState(false)
  const [quoteIdEditing, setQuoteIdEditing] = useState()
  const [outdatedQuotesModal, setOutdatedQuotesModal] = useState(false)
  const [outdatedQuotesModalStep, setOutdatedQuotesModalStep] = useState(1)
  const [outdatedQuotes, setOutdatedQuotes] = useState([])
  const [highlightedButton, setHighlightedButton] = useState(
    outdatedQuoteToReserve,
  )
  const classes = styles()

  const onChangeConfigEdit = async (value) => {
    if (value === 'financing') {
      setIsFinancingLoading(true)
      setFinancingSimulations(await getFinancingSimulation(proposal.rowData.id))
      setIsFinancingLoading(false)
    }
  }

  const verifyKit = (id) => {
    API.get(`/proposta/validate-proposals/${id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    }).then((res) => {
      const returnObject = res.data
      const allDimensionings = Object.keys(returnObject[id])
      const allQuotations = []
      allDimensionings.forEach((item) => {
        allQuotations.push(...Object.entries(returnObject[id][item]))
      })
      const quotationsOutdated = []
      allQuotations.forEach((item) => {
        if (
          item[1].resultValues.length !== 0 ||
          Number(item[1].resultVisible) !== 0
        ) {
          quotationsOutdated.push(item)
          setOutdatedQuotesModal(true)
        } else {
          outdatedQuoteToReserve?.quotation_id &&
            setHighlightedButton({
              ...outdatedQuoteToReserve,
              isHighlightedButton: true,
            })
        }
      })
      setOutdatedQuotes(quotationsOutdated)
    })
    // eslint-disable-next-line
  }

  const handleClose = () => {
    // dispatch(ResetRedux())
    onBack()
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (!completeQuoteEdit) {
      verifyKit(proposal?.rowData?.id)
    }
    // eslint-disable-next-line
  }, [completeQuoteEdit])

  useEffect(() => {
    setCompleteQuoteEdit(false)
  }, [proposal.id])

  return (
    <div className={classes.container}>
      <Loading minHeight="250px" isLoading={isLoading}>
        <div>
          {completeQuoteEdit && (
            <Equipments
              setQuoteIdEditing={setQuoteIdEditing}
              quoteIdEditing={quoteIdEditing}
              completeQuoteEdit={completeQuoteEdit}
              setCompleteQuoteEdit={setCompleteQuoteEdit}
              outdatedQuotes={outdatedQuotes}
              setOutdatedQuotes={setOutdatedQuotes}
              verifyKit={verifyKit}
            />
          )}
          <div style={{ display: completeQuoteEdit && 'none' }}>
            <ConfigProposalContainer
              proposalFromList={proposal?.rowData?.proposalToEdit}
              isFinancingLoading={isFinancingLoading}
              financingSimulations={financingSimulations}
              onChangeConfigEdit={onChangeConfigEdit}
              setCompleteQuoteEdit={setCompleteQuoteEdit}
              setQuoteIdEditing={setQuoteIdEditing}
              proposalListHandleClose={handleClose}
              outdatedQuotesModal={outdatedQuotesModal}
              setOutdatedQuotesModal={setOutdatedQuotesModal}
              outdatedQuotesModalStep={outdatedQuotesModalStep}
              setOutdatedQuotesModalStep={setOutdatedQuotesModalStep}
              outdatedQuotes={outdatedQuotes}
              setOutdatedQuotes={setOutdatedQuotes}
              quoteIdEditing={quoteIdEditing}
              verifyKit={verifyKit}
              highlightedButton={highlightedButton}
            />
          </div>
        </div>
      </Loading>
    </div>
  )
}

ConfigProposalContent.propTypes = {
  title: PropTypes.string,
}

export default ConfigProposalContent
