import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  display: flex;

  &::-webkit-scrollbar {
    height: 6px;
    display: block !important;
  }
  &::-webkit-scrollbar-track {
    margin-right: 4px;
    margin-bottom: 24px;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }
  &::-webkit-scrollbar-button {
    display: none !important;
  }

  &:not(:hover)::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
`
