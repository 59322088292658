import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import { Typography, TextArea } from 'components'
import { Spacer } from '@77sol/core'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '32px 34px',
  },
})

function TextEdit() {
  const classes = useStyles()

  return (
    <div className={classes.paperRendered}>
      <Typography
        type={isMobile ? 'link_small' : 'text_medium'}
        color="grayscale"
        colorWeight={isMobile ? '800' : '700'}
      >
        Insira informações extras que deseja informar o cliente
      </Typography>
      <Spacer size="16" direction="horizontal" />
      <div style={{ display: 'flex', marginBottom: '18px', width: '100%' }}>
        <TextArea rows={12} label="Conteúdo" />
      </div>
      {/*
            <Button
                onClick={() => handleFinish(text)}
                disabled={!text}
                className={classes.filledButton}
            >
                {loadingExtras ?
                    <CircularProgress disableShrink style={{color: '#FFF'}} size={20} thickness={4} />
                :
                    'Salvar alterações'
                }
            </Button>
            */}
    </div>
  )
}

export default TextEdit
