import {
  SearchIntegratorInput,
  type ISearchIntegratorInputProps,
} from 'containers/SearchIntegratorInput'
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
} from 'react-hook-form'

export type IAutoCompleteIntegratorProps<T extends FieldValues> =
  ISearchIntegratorInputProps & {
    control: Control<T, object>
    name: Path<T>
    label?: string
  }

export const AutoCompleteIntegrator = <T extends FieldValues>({
  label,
  control,
  name,
  defaultValue,
  ...props
}: IAutoCompleteIntegratorProps<T>) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <SearchIntegratorInput
          {...props}
          label={label}
          onChange={(value) => {
            onChange(value)
            props?.onChange?.(value)
          }}
          defaultIntegratorValue={value}
        />
      )}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      name={name}
    />
  )
}
