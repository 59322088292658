import { useEffect, useState } from 'react'
import API from 'api'
import { useDispatch } from 'react-redux'
import * as ProposalAction from 'store/actions/index'
import { mappedAllCustomer } from 'utils/mappedAllCustomer'
import { isEmpty } from 'utils'
import { useToast } from '@77sol/core'

export const useCustomer = ({
  shouldCreateProposal,
  clientId,
  setSelectedClient,
  setActiveStep,
  selectedClient,
  projectId,
}) => {
  const [pageClients, setPageClients] = useState(1)
  const [hasMoreClients, setHasMoreClients] = useState(true)
  const [clients, setClients] = useState([])
  const [loadingClients, setLoadingClients] = useState(false)
  const [loadingClient, setLoadingClient] = useState(false)

  const [filterClientName, setFilterClientName] = useState('')
  const [loadingCreateProposal, setLoadingCreateProposal] = useState(false)

  const dispatch = useDispatch()

  const { onErrorOpenToast } = useToast()

  useEffect(() => {
    setLoadingClient(true)
    if (!loadingClient) {
      const getClient = async () => {
        try {
          const { data } = await API.get(`/clientes/view/${clientId}`)

          setSelectedClient({
            label: data.nome,
            nome: data.nome,
            ...data,
          })

          setLoadingClient(false)

          if (!shouldCreateProposal) {
            return setActiveStep('inicialValues')
          }

          handleCreateProposal(data.id)
        } catch (e) {
          setLoadingClient(false)
        }
      }

      if (clientId && isEmpty(selectedClient)) {
        getClient()
      }
    }
  }, [clientId, clients])

  const handleCreateProposal = async (selectedClientId) => {
    setLoadingCreateProposal(true)

    await API.post('/proposal/register/customer', {
      customer_id: selectedClientId,
      ...(projectId && { project_id: projectId }),
    })
      .then((res) => {
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        getExtraConfigs(res.data.proposal.id)
        setActiveStep('dadosDim')
      })
      .catch(() => {
        onErrorOpenToast('Complete o seu cadastro para dimensionar!')
      })
      .finally(() => setLoadingCreateProposal(false))
  }

  const getExtraConfigs = (id) => {
    API.get(`/proposal/extra-configs/${id}`).then((res) => {
      dispatch(ProposalAction.SaveExtraConfigsRedux(res.data))
    })
  }

  async function getMoreClients() {
    try {
      const response = await API.get('/clientes/all', {
        params: {
          page: pageClients,
          search: filterClientName,
        },
      })

      const allCustomers = response.data.data.map(mappedAllCustomer)

      setClients([...clients, ...allCustomers])

      dispatch(ProposalAction.SaveClientsRedux([...clients, allCustomers]))
      if (response.data.last_page === pageClients) {
        setHasMoreClients(false)
      }
      setPageClients(pageClients + 1)
      return allCustomers
    } catch (e) {
      throw new Error(e)
    }
  }

  async function getFirstPageClients(isClear) {
    setHasMoreClients(true)
    try {
      const response = await API.get('/clientes/all', {
        params: {
          page: 1,
          search: isClear ? '' : filterClientName,
        },
      })

      const allCustomers = response.data.data.map(mappedAllCustomer)

      if (response.data.last_page === pageClients) {
        setHasMoreClients(false)
      }

      setPageClients(2)

      dispatch(ProposalAction.SaveClientsRedux(allCustomers))
      setClients(allCustomers)

      return allCustomers
    } catch (e) {
      throw new Error(e)
    }
  }

  const getClients = async () => {
    setLoadingClients(true)
    getFirstPageClients()
    setLoadingClients(false)
  }

  function resetFieldSearch(isSearch) {
    setFilterClientName('')
    getFirstPageClients(isSearch)
  }

  return {
    loadingCreateProposal,
    loadingClients,
    clients,
    hasMoreClients,
    filterClientName,
    setFilterClientName,
    getMoreClients,
    getFirstPageClients,
    getClients,
    resetFieldSearch,
    handleCreateProposal,
  }
}
