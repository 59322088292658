import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import { EnumSizingType } from 'views/DimensionarV2/components/StepOne/constants'

export const TRACKERS_MAPPED_BY_TYPE = {
  [EnumSizingType.MonthlyConsumption]:
    proposalTracker.actions.monthlyConsumption,
  [EnumSizingType.SystemPower]: proposalTracker.actions.systemPowerPRO,
  [EnumSizingType.ConsumptionAverage]:
    proposalTracker.actions.consumptionAveragePRO,
  [EnumSizingType.AverageConsumptionValue]:
    proposalTracker.actions.averageConsumptionValuePRO,
  [EnumSizingType.BasicAverageConsumptionValue]:
    proposalTracker.actions.averageConsumptionValue,
  [EnumSizingType.BasicSystemPower]: proposalTracker.actions.systemPower,
}
