export enum PositionEnum {
  first = 'first',
  second = 'second',
  third = 'third',
  nonPodium = 'nonPodium',
  unclassified = 'unclassified',
}

type ClassificationType = PositionEnum

export interface InfoPosition {
  position: number
  key: ClassificationType
}
