import { Button } from '@77sol-ui/atoms'
import { useAmplitude } from 'hooks/useAmplitude'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { useSaveAndQuit } from './hooks/useSaveAndQuit'
import { SaveAndQuitQuotationModal } from 'containers/EquipmentQuotationContainer/components/SaveAndQuitQuotationModal'
import { useHistory } from 'react-router-dom'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'

export function SaveAndQuitButton() {
  const history = useHistory()
  const { isOpenSaveAndQuitModal, setIsOpenSaveAndQuitModal } = useSaveAndQuit()
  const { logEvent } = useAmplitude()

  const { disabled } = useDisableActionButtons()

  const hanldeSaveAndQuit = () => {
    logEvent(headerTracker.actions.saveAndExitKey, {
      origin: '/Cotar',
    })
    setIsOpenSaveAndQuitModal(true)
  }

  return (
    <>
      <Button onClick={hanldeSaveAndQuit} disabled={disabled}>
        Salvar e sair
      </Button>
      <SaveAndQuitQuotationModal
        open={isOpenSaveAndQuitModal}
        onOpenChange={() => {
          setIsOpenSaveAndQuitModal(!isOpenSaveAndQuitModal)
        }}
        onSuccess={() => {
          history.push('/projetos')
        }}
      />
    </>
  )
}
