/**
 * Check if some value is null
 * @param {Object} values
 * @param {Array} optionals
 * @returns
 */
export const isNull = (values = {}, optionals = []) => {
  return Object.entries(values).some(([key, value]) => {
    return !value && !optionals.includes(key)
  })
}

/**
 *  Get plan label
 * @param {Object} infos
 * @returns
 */
export const getPlanLabel = (infos = {}) => {
  const { pago = 0, demonstracao = 0 } = infos
  if (pago) {
    return 'Plano PRO'
  }
  return demonstracao ? 'Demonstração PRO' : 'Plano Free'
}

export function validateActionProposalButtons(proposal) {
  const hasSomeError =
  Boolean(proposal?.quotes?.find((item) => item?.firstKit?.providers === true)) ||
  proposal?.quotes?.length <= 0;

  return hasSomeError;
}

export function hasDifferentProvidersInQuotation(quotations){
  return Boolean(quotations?.find((item) => item?.firstKit?.providers === true));
}