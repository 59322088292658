import api from 'api'

import {
  type IGetFinancingByValueRequest,
  type IGetFinancingByValueResponse,
} from './types'
import { FINANCING_BY_VALUE } from '../constants/endpointKeys'

export const getFinancingByValue = async ({
  financingId,
}: IGetFinancingByValueRequest) => {
  return await api.get<IGetFinancingByValueResponse>(
    `${FINANCING_BY_VALUE}/${financingId}`,
  )
}
