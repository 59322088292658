import { Button, DropdownMenu } from '@77sol-ui/atoms'
import styled from 'styled-components'

export const DropdownContent = styled(DropdownMenu.Content)`
  z-index: 2;
`

export const DropdownButton = styled(Button)`
  font-size: 0.875rem;
`

export const DropdownItem = styled(DropdownMenu.Item)`
  font-size: 0.875rem;
  font-family: DM Sans;
`
