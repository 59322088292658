import { useCallback, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { CompleteRegistrationModalProvider } from 'context/CompleteRegistrationModalContext'
import { QuotationModalProvider } from 'context/QuotationModalContext'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { urlLogin } from 'urls'
import { useDispatch, useSelector } from 'react-redux'
import API from 'api'
import { ScrollReset, GoogleAnalytics } from 'components'
import { useSettings } from 'hooks/useSettings'
import * as AuthAction from 'store/actions/auth'
import UnderMaintenance from 'views/UnderMaintenance/UnderMaintenance'
import {
  setLogo,
  setUserProfile,
  setUserProfileCorporateName,
} from 'store/actions/profile'
import { ErrorBoundary } from 'react-error-boundary'
import { WhiteScreenFallback } from 'views/Errors/WhiteScreenFallback'
import { useAmplitude } from 'hooks/useAmplitude'
import { Helmet } from 'react-helmet'
import { useToast, Toast } from '@77sol/core'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import theme, { styledComponentsTheme } from './theme'
import globalConfigs from './globalConfigs/index'
import { GlobalModals } from 'GlobalModals'
import { SocketPusherProvider } from 'context/SocketPusherContext'
import { NotificationsProvider } from 'context/NotificationsContext'
import { PendingOrderFieldsProvider } from 'views/Pedidos/components/OrderTabs/OrderPendingsTab/hooks/usePendingOrderFields'
import './App.css'
import '@77sol-ui/atoms/dist/index.css'
import '@77sol-ui/molecules/dist/index.css'
import '@77sol-ui/organisms/dist/index.css'
import { CreateFinancingPerValueModalProvider } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import { SuccessFinancingRequestModalProvider } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import '@77sol-ui/atoms/dist/index.css'
import { initTracker } from 'services/tracker/setup'
import { RenderRoutes } from 'components/RenderRoutes'
import { clearStorage } from 'utils/clearStorage'
import { useLojaMeuEndereco } from 'domains/loja/loja-meu-endereco/hooks/useLojaMeuEndereco'
import { IntercomProvider } from 'utils/intercom/provider'
import { V2_TOKEN_API_STORAGE } from 'storage/v2ApiTokenStorage'

const history = createBrowserHistory()

function App() {
  const { toastOptions } = useToast()
  useLojaMeuEndereco()

  const user = useSelector((state) => state.ReducerAuth.userLogged)
  const userId = user?.[0]?.id
  const dispatch = useDispatch()
  const { isProPlan } = useSettings()

  const [isUserLogged, setIsUserLogged] = useState(false)

  const willMount = useRef(true)

  if (willMount.current) {
    const currentToken = localStorage.getItem('sessionToken')

    if (currentToken) {
      API.defaults.headers.common = {
        token: currentToken,
        'Content-Type': 'application/json',
      }
    }

    const [urlToken, tokenV2] = new URLSearchParams(
      window.location.search,
    ).getAll('token')

    if (urlToken) {
      clearStorage([
        '@77sol-admin:expiressRankingModal',
        'dateViewFreePlanModal',
      ])
      API.defaults.headers.common = {
        token: urlToken,
        'Content-Type': 'application/json',
      }
      localStorage.setItem('sessionToken', urlToken)
      V2_TOKEN_API_STORAGE.set(tokenV2)
      window.location.href = '/'
    }

    willMount.current = false
  }

  const getUrlRedirectLogout = () => urlLogin

  const getAuth = useCallback(async () => {
    await API.get('/auth/me')
      .then(({ data }) => {
        if (data[0].validado === 1) {
          dispatch(AuthAction.setUserLogged(data))
          setIsUserLogged(true)

          if (data.regra) {
            localStorage.setItem('sessionDataId', data[0].id)
            API.get(`/user/view/${data[0].id}`).then((userResponse) => {
              dispatch(setUserProfile(userResponse.data[0]))
              dispatch(setUserProfileCorporateName(userResponse.data[1]))
            })
          } else if (window.location.href.search('visualizar/pedido') < 0) {
            window.location.href = getUrlRedirectLogout()
          }

          API.get('/files/logo').then((res) => dispatch(setLogo(res.data.dir)))
        }
      })
      .catch(() => {
        if (window.location.href.search('visualizar/pedido') < 0) {
          window.location.href = getUrlRedirectLogout()
          setIsUserLogged(false)
        } else {
          setIsUserLogged(true)
        }
      })
  }, [dispatch])

  useEffect(() => {
    getAuth().then(() => {
      isProPlan()
    })
  }, [getAuth])

  const { logEventError } = useAmplitude()

  const handleError = (error, info) => {
    logEventError(
      'Erro: Tela Azul',
      {
        error,
        info,
      },
      {
        origin: window.location.href,
      },
    )
  }

  useEffect(() => {
    API.interceptors.response.use((response) => {
      if (response.status === 203) {
        if (window.location.href.search('visualizar/pedido') < 0) {
          setIsUserLogged(false)
          window.location.href = urlLogin
        }
      }

      return response
    })
  }, [])

  initTracker(userId)

  return (
    <>
      {process.env.REACT_APP_UNDER_MAINTENANCE === 'in-maintenance' && (
        <UnderMaintenance />
      )}
      {isUserLogged &&
        user &&
        process.env.REACT_APP_UNDER_MAINTENANCE !== 'in-maintenance' && (
          <ThemeProvider theme={theme}>
            <StyledComponentsThemeProvider theme={styledComponentsTheme}>
              <CompleteRegistrationModalProvider>
                <QuotationModalProvider>
                  <CreateFinancingPerValueModalProvider>
                    <SuccessFinancingRequestModalProvider>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <PendingOrderFieldsProvider>
                          <Helmet>
                            <link
                              rel="shortcut icon"
                              href={`/icons/${globalConfigs.appinfo.tab_icon}`}
                            />
                          </Helmet>
                          <Router history={history}>
                            <SocketPusherProvider>
                              <NotificationsProvider>
                                <IntercomProvider>
                                  <ErrorBoundary
                                    FallbackComponent={WhiteScreenFallback}
                                    onError={handleError}
                                  >
                                    <ScrollReset />
                                    <GoogleAnalytics />
                                    <RenderRoutes />
                                    <GlobalModals />
                                  </ErrorBoundary>
                                </IntercomProvider>
                              </NotificationsProvider>
                            </SocketPusherProvider>
                          </Router>
                          {toastOptions.isOpen && <Toast />}
                        </PendingOrderFieldsProvider>
                      </MuiPickersUtilsProvider>
                    </SuccessFinancingRequestModalProvider>
                  </CreateFinancingPerValueModalProvider>
                </QuotationModalProvider>
              </CompleteRegistrationModalProvider>
            </StyledComponentsThemeProvider>
          </ThemeProvider>
        )}
    </>
  )
}

export default App
