export const clientsTracker = {
  actions: {
    searchCustomer: 'Pesquisar Cliente: Utilizou o campo de busca',
    newCustomer: 'Novo Cliente: Clicou no card novo cliente',
    selectCustomer: 'Selecionar Cliente: Clicou em um cliente da listagem',
    customerFormCancel: 'Formulário de Cliente: Clicou no botão de cancelar',
    customerFormMoreData: 'Formulário de Cliente: Clicou em completar dados',
    customerFormLessData: 'Formulário de Cliente: Clicou em esconder dados',
    customerFormSave: 'Formulário de Cliente: Clicou no botão salvar cliente',
    customerFormNext: 'Formulário de Cliente: Clicou no botão de próximo',
    associateCustomer:
      'Selecionar Cliente: Associou um cliente ao dimensionamento',
    cancelCustomer:
      'Selecionar Cliente: Cancelou a associação do cliente ao dimensionamento',
  },
}
