import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import { useSelector } from 'react-redux'

export function useCompareKitFooter() {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const userProfile = useSelector(
    (state: {
      ReducerProfile: {
        userProfile: {
          role_id: number
          privilegios_admin: number
          uf: string
        }
      }
    }) => state.ReducerProfile.userProfile,
  )

  const isAdmin =
    userProfile.role_id === 1 || userProfile.privilegios_admin === 1

  const integrator = data?.proposal?.integrator

  const canShowNotifyIntegratorButton = integrator && isAdmin
  const canShowCreateProjectButton = !isAdmin

  return {
    canShowNotifyIntegratorButton,
    integrator,
    canShowCreateProjectButton,
  }
}
