export const QUERY_KEYS = {
  CLIENTES: {
    PROPOSAL_VIEW: 'clientes/proposal/view',
  },
  LOJA: {
    MEU_ENDERECO: 'loja/meu-endereco',
    NOVO_PEDIDO_LOJA: 'loja/novo-pedido-loja',
  },
  PROPOSAL: {
    QUOTATION: 'proposal/quotation',
    QUOTATION_CALCULATE_DELIVERY_VALUE:
      'proposal/quotation/calculate/delivery-value',
    QUATATION_CHANGE_DELIVERY_VALUE: 'proposal/quotation/change/delivery-value',
  },
  SHOWCEP: {
    _: 'showcep',
  },
}
