import { DropdownMenu } from '@77sol/core'
import { MoreInfo } from '@77sol/icons/dist'
import * as S from './styles'

interface IDropdownMenuProps {
  trigger?: JSX.Element
  children: React.ReactNode
  color?: string
}

export function KebabMenu({
  color,
  trigger: Trigger = <MoreInfo color={color} fill={color} />,
  children,
}: IDropdownMenuProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <S.Button>{Trigger}</S.Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content align="end">{children}</DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
