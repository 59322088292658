import React, { useState } from 'react'
import { Typography, Button } from '@77sol/core'
import { Box } from '@material-ui/core'
import styles from '../../../../../styles'

function DocumentCard({ title, description, onSendDocument, hasBeenSent }) {
  const classes = styles()
  const [documentSentText, setDocumentSentText] = useState('Documento enviado!')

  const sentButtonTextColor =
    documentSentText === 'Documento enviado!' ? 'green' : 'primary'

  return (
    <Box className={classes.invoiceContent}>
      <Box className={classes.invoiceDescription}>
        <Typography size="small">{title}</Typography>
        {description && (
          <Typography size="small" colorWeight="400">
            {description}
          </Typography>
        )}
      </Box>
      {hasBeenSent ? (
        <Button
          onMouseOver={() => setDocumentSentText('Reenviar documento')}
          onMouseLeave={() => setDocumentSentText('Documento enviado!')}
          size="small"
          color={sentButtonTextColor}
          variant="text"
          onClick={onSendDocument}
        >
          {documentSentText}
        </Button>
      ) : (
        <Button size="small" onClick={onSendDocument}>
          Enviar documento
        </Button>
      )}
    </Box>
  )
}

export default DocumentCard
