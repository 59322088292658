import { useMemo } from 'react'
import {
  timeline,
  baseTimeline,
  canceledOrderBaseTimeline,
  timelineBaseOfExpiredOrder,
} from '../constants'

import { type OrderTimelineProps } from '../OrderTimeline.types'
import { useOrderHistory } from 'domains/orders/historic-order/hooks/useOrderHistory'
import {
  ORDER_CLOSED_CLIENT_STATUS,
  type ORDER_STATUS_CLIENT_ENUM,
} from 'enums/order/OderStatusClientEnum'

export function useOrderTimeline({
  currentStatus,
  orderId,
}: Pick<OrderTimelineProps, 'currentStatus' | 'orderId'>) {
  const { data } = useOrderHistory(orderId)

  const selectedTimelineStatus = useMemo(() => {
    if (ORDER_CLOSED_CLIENT_STATUS.includes(currentStatus)) {
      if (data?.status.length) {
        return [...new Set(data?.status)]
      }

      const defaultTimelineStatus: Partial<
        Record<ORDER_STATUS_CLIENT_ENUM, ORDER_STATUS_CLIENT_ENUM[]>
      > = {
        '1': timelineBaseOfExpiredOrder,
        '13': canceledOrderBaseTimeline,
      }

      return defaultTimelineStatus[currentStatus] as ORDER_STATUS_CLIENT_ENUM[]
    }
    return baseTimeline
  }, [currentStatus, data])

  const timelineOptions = useMemo(() => {
    return selectedTimelineStatus
      .map((item) => timeline[item])
      .filter((item) => item)
  }, [selectedTimelineStatus])

  const currentStep = useMemo(() => {
    if (!selectedTimelineStatus) return -1

    const index = selectedTimelineStatus.indexOf(currentStatus)

    return index + 1
  }, [currentStatus, selectedTimelineStatus])

  return { timelineOptions, currentStep }
}
