import { Select, SlideMenu, ToggleSelect } from '@77sol-ui/molecules'
import { ArrowLeft, Filter } from 'lucide-react'
import * as S from './styles'
import {
  CHARTS_FILTER,
  CHARTS_FILTER_DISABLED,
  PERIODS_FILTER,
} from '../../constants'
import { Button } from '@77sol-ui/atoms'
import { type IFilters } from 'views/Home/components/Performance/types'
import { useMobileFilters } from './hooks/useMobileFilters'

interface IMobileFilterProps {
  onApply: (filters: IFilters) => void
  initialFilters: IFilters
}

export function MobileFilters({ onApply, initialFilters }: IMobileFilterProps) {
  const {
    isOpen,
    setIsOpen,
    onApplyFilters,
    onClearFilters,
    filters,
    setFilters,
    handleChangePeriod,
    INTERVAL,
  } = useMobileFilters({ onApply, initialFilters })

  return (
    <SlideMenu.Root open={isOpen} onOpenChange={setIsOpen}>
      <SlideMenu.Trigger asChild>
        <S.StyledSlideTrigger variant="outline">
          <Filter size={16} /> Filtrar por
        </S.StyledSlideTrigger>
      </SlideMenu.Trigger>
      <SlideMenu.Portal>
        <SlideMenu.Overlay />
        <SlideMenu.Wrapper maxWidth="100vw" rounded={false}>
          <SlideMenu.Content>
            <S.StyledSlideContent>
              <S.StyledBackButton
                variant="text"
                asIcon
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <ArrowLeft size={20} /> Voltar
              </S.StyledBackButton>

              <S.StyledFiltersContent>
                <h3>Filtros</h3>

                <div>
                  <Select.Root
                    value={filters.period}
                    onValueChange={handleChangePeriod}
                  >
                    <Select.Label label="Filtrar por" />
                    <Select.Trigger>
                      <Select.Value />
                      <Select.Icon />
                    </Select.Trigger>
                    <Select.Portal>
                      <Select.Content>
                        <Select.Viewport>
                          {PERIODS_FILTER.map((item) => (
                            <Select.Item key={item.value} value={item.value}>
                              {item.label}
                            </Select.Item>
                          ))}
                        </Select.Viewport>
                      </Select.Content>
                    </Select.Portal>
                  </Select.Root>
                </div>

                <S.StyledToggleSelectRoot amount={INTERVAL.length}>
                  {INTERVAL.map((item, index) => {
                    const isLastIndex = index + 1 === INTERVAL.length
                    return (
                      <>
                        <ToggleSelect.Item
                          key={item.value}
                          onClick={() => {
                            setFilters((prev) => ({
                              ...prev,
                              interval: item.value,
                            }))
                          }}
                          active={filters.interval === item.value}
                        >
                          {item.label}
                        </ToggleSelect.Item>
                        {!isLastIndex && <ToggleSelect.Separator />}
                      </>
                    )
                  })}
                </S.StyledToggleSelectRoot>

                <div>
                  <Select.Root
                    value={filters.chart}
                    onValueChange={(chart) => {
                      setFilters((prev) => ({ ...prev, chart }))
                    }}
                  >
                    <Select.Label label="Tipo de gráfico" />
                    <Select.Trigger>
                      <Select.Value />
                      <Select.Icon />
                    </Select.Trigger>
                    <Select.Portal>
                      <Select.Content>
                        <Select.Viewport>
                          {CHARTS_FILTER.map((item) => (
                            <Select.Item key={item.value} value={item.value}>
                              {item.label}
                            </Select.Item>
                          ))}
                          {CHARTS_FILTER_DISABLED.map((item) => (
                            <Select.Item
                              key={item.value}
                              value={item.value}
                              disabled={item.disabled}
                            >
                              {item.label}
                            </Select.Item>
                          ))}
                        </Select.Viewport>
                      </Select.Content>
                    </Select.Portal>
                  </Select.Root>
                </div>

                <S.ButtonsWrapper>
                  <Button
                    onClick={() => {
                      onApplyFilters()
                    }}
                  >
                    Filtrar
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClearFilters()
                    }}
                  >
                    Limpar filtros
                  </Button>
                </S.ButtonsWrapper>
              </S.StyledFiltersContent>
            </S.StyledSlideContent>
          </SlideMenu.Content>
        </SlideMenu.Wrapper>
      </SlideMenu.Portal>
    </SlideMenu.Root>
  )
}
