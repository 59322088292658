import { QUERY_KEY_PROPOSAL_QUOTATIONS_ALL } from '../constants/endpointKeys'
import { proposalQuotationsAll } from '../services/api'
import { useQuery } from '@tanstack/react-query'

export const useFetchProposalQuotationsAll = (id: number) => {
  const {
    data = [],
    isLoading,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: [QUERY_KEY_PROPOSAL_QUOTATIONS_ALL, id],
    queryFn: async () => await proposalQuotationsAll.get(id),
  })
  return { proposalQuotationsAllData: data, isLoading, isSuccess, isError }
}
