export const NF_OPTIONS = [
  {
    label: 'Valor do projeto',
    value: 'valor_do_projeto',
  },
  {
    label: 'Valor dos equipamentos',
    value: 'valor_dos_equipamentos',
  },
  {
    label: 'Inserir valor manualmente',
    value: 'manual',
  },
]
