import React, { type HTMLAttributes } from 'react'
import { Accordion } from '@77sol/core'
import { format } from 'date-fns'

import * as S from './styles'
import { useOrderHistory } from 'domains/orders/historic-order/hooks/useOrderHistory'

interface IOrderHistory extends HTMLAttributes<HTMLDivElement> {
  orderId: number
  defaultExpanded?: boolean
}

const OrderHistory: React.FC<IOrderHistory> = ({ orderId, ...props }) => {
  const { data } = useOrderHistory(orderId)
  const history = data?.historic ?? []

  return (
    <Accordion title="Histórico do pedido" {...props}>
      <S.ContentContainer>
        {history?.map((status) => (
          <S.StatusContainer key={status.created_at}>
            <span className="bold title">{status.title}</span>
            <span className="description">{status.historic}</span>
            <span className="bold date">
              {format(new Date(status.created_at), 'dd/MM/yyyy')}
            </span>
          </S.StatusContainer>
        ))}
        {!history.length && (
          <span className="emptyText">Nenhum registro encontrado.</span>
        )}
      </S.ContentContainer>
    </Accordion>
  )
}

export default OrderHistory
