import styled from 'styled-components'

export const ImageBanner = styled.div`
  img,
  svg {
    width: 100%;
    border-radius: 8px;
    height: 100%;
  }
`
