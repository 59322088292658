import React from 'react'
import { LocationIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'

import * as S from './styles'
import { showZipCode } from './helpers/showZipCode'

interface IDeliveryAddressProps {
  address: {
    streetName: string
    city: string
    zipCode: string
    state: string
    number: string
  }
  className?: string
}

const DeliveryAddress: React.FC<IDeliveryAddressProps> = ({
  address,
  ...props
}) => {
  return (
    <S.AddressContainer {...props}>
      <div className="locationContainer">
        <LocationIcon color={palette.primary[300]} />
      </div>
      <span className="title">Endereço de entrega (Logradouro)</span>
      <span>
        {address?.streetName} {address.number}
      </span>
      <span>
        {address?.city}, {address?.state} - CEP {showZipCode(address.zipCode)}
      </span>
    </S.AddressContainer>
  )
}

export default DeliveryAddress
