import { useState } from 'react'
import { Button, useToast } from '@77sol/core'
import { DeliveryAddressForm } from './components/DeliveryAddressForm'
import { BillingAddressForm } from './components/BillingAddressForm'
import { SuccessOrderModal, AlterDeliveryModal } from '../Modals'
import { INITIAL } from '../../constants'
import { useStyles } from './styles'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema.yup'
import { type ShipmentFormProps } from './ShipmentForm.types'
import { useShipmentForm } from './hooks/useShipmentForm'
import { useClearErrors } from './hooks/useClearErrors'

export function ShipmentForm({
  quotation,
  proposalId,
  isCustomer,
  kit,
  quotationId,
  onCloseCheckoutModal,
}: ShipmentFormProps) {
  const classes = useStyles()
  const { onErrorOpenToast } = useToast()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const { ...methods } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      deliveryAddress: INITIAL.DELIVERY_ADDRESS,
      billingAddress: INITIAL.BILLING_ADDRESS,
    },
  })

  useClearErrors({
    methods,
  })

  const [isAlterDeliveryModalOpen, setIsAlterDeliveryModalOpen] =
    useState(false)

  const {
    acceptNewDeliveryValue,
    handleCreateOrder,
    deliveryValue,
    isLoadingCreateOrder,
    addLojaNovoPedidoLoja,
    addProposalQuotationChangeDeliveryValue,
    cancelNewDeliveryValue,
  } = useShipmentForm({
    methods,
    quotationId,
    setIsAlterDeliveryModalOpen,
    quotation,
    setIsSuccessModalOpen,
  })

  const onSubmitFailed = ({ genericError = {} }: any) => {
    const {
      message = 'Preencha todos os campos obrigatórios (*) para continuar.',
    } = genericError
    onErrorOpenToast(message)
  }

  return (
    <>
      <div data-testid="shipment-form-container" className={classes.rightSide}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleCreateOrder, onSubmitFailed)}
          >
            <DeliveryAddressForm kit={kit} />
            <BillingAddressForm />

            <div className={classes.actions}>
              <Button
                id="shipmentForm_button_confirm"
                type="submit"
                isLoading={
                  isLoadingCreateOrder ||
                  addProposalQuotationChangeDeliveryValue.isLoading
                }
                size="small"
                fullWidth
              >
                Reservar equipamentos
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      <AlterDeliveryModal
        deliveryValue={deliveryValue}
        alterDeliveryValue={acceptNewDeliveryValue}
        cancelNewDeliveryValue={cancelNewDeliveryValue}
        isOpen={isAlterDeliveryModalOpen}
      />

      {isSuccessModalOpen && addLojaNovoPedidoLoja.data && (
        <SuccessOrderModal
          orderId={addLojaNovoPedidoLoja.data.order_id}
          onClose={() => {
            onCloseCheckoutModal()
          }}
          isOpen={isSuccessModalOpen}
        />
      )}
    </>
  )
}
