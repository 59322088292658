import React, {
  forwardRef,
  type InputHTMLAttributes,
  type KeyboardEvent,
} from 'react'
import { Input } from '@77sol/core'
import { type Control, Controller } from 'react-hook-form'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  control: Control<any>
  label?: string
  mask?: 'cpfCnpj' | 'cep' | 'phoneNumber' | 'real'
  isLoading?: boolean
  error?: string
  onKeyDown?: (e: KeyboardEvent) => void
  endAdornment?: string
  fullWidth?: boolean
  startAdornment?: JSX.Element
}

export const TextField = forwardRef<HTMLElement, TextFieldProps>(
  (
    {
      name,
      control,
      label,
      mask,
      isLoading = false,
      error,
      onKeyDown,
      fullWidth = false,
      startAdornment,
      ...rest
    },
    ref,
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            {...rest}
            {...field}
            value={field.value ?? ''}
            isLoading={isLoading}
            mask={mask}
            title={label}
            onKeyDown={onKeyDown}
            error={error}
            ref={ref}
            fullWidth={fullWidth}
            startAdornment={startAdornment}
          />
        )}
      />
    )
  },
)
