import * as S from './styles'
import { type ICustomerDTO } from 'dto/CustomerDTO'
import { StatusSolLeadsEnum } from 'enums/order/StatusSolLeadsEnum'
import { checkStreetWithNumber } from './helpers'
import { useCallback } from 'react'
import { useToast } from '@77sol/core'

interface IClientPaperProps {
  clientData: ICustomerDTO
  onClick: () => void
}

export function ClientPaper({
  clientData,
  onClick,
  ...props
}: IClientPaperProps) {
  const { onWarningOpenToast } = useToast()
  const { nome, email, endereco, numero, status_solleads } = clientData

  const isSolLeads = Boolean(status_solleads)
  const isDisabled = status_solleads === StatusSolLeadsEnum.VENCIDO
  const streetAndNumber = checkStreetWithNumber(endereco, numero)

  const handleClick = useCallback(() => {
    if (isDisabled) {
      onWarningOpenToast('Cliente SolLeads vencido!')
      return
    }

    onClick()
  }, [isDisabled])

  return (
    <S.Paper
      {...props}
      onClick={handleClick}
      data-solleads={isSolLeads}
      data-disabled={isDisabled}
    >
      <S.Header>
        <h3>{nome || ''}</h3>
      </S.Header>
      {!isDisabled && (
        <S.Content>
          <p>{streetAndNumber}</p>
          <p>{email || ''}</p>
        </S.Content>
      )}
    </S.Paper>
  )
}
