import { type ILineChartContext } from '@77sol-ui/molecules'
import {
  customizeTicks,
  customizeTooltip,
} from '../../../utils/customizeChartLabels'

import { type IResponseGetConversionData } from 'domains/home/dashboard-analysis-conversion/services/types'
import { formatReal } from 'utils/format'
import { type IFormattedDataToChartJs } from '../../../utils/formatDataToChartJs'
import { abbreviateNumber } from '../../../utils/abbreviateNumber'

interface IUseCustomizeLabelsProps {
  period: string
  data?: IResponseGetConversionData
  units?: IFormattedDataToChartJs['units']
}

export function useCustomizeLabels({
  period,
  data,
  units,
}: IUseCustomizeLabelsProps) {
  function customizeMoneyAxisLabel(label: string | number) {
    const num = Number(label)

    return `${units?.[0] || ''} ${abbreviateNumber(num)}`
  }

  function customizeDataAxisLabel(value: string | number) {
    return customizeTicks?.[period]?.(data?.x_axis?.[value as number] as string)
  }

  function customizeTooltipTitle(context: ILineChartContext[]) {
    return customizeTooltip?.[period]?.(context[0]?.label)
  }

  function customizeTooltipLabel(context: ILineChartContext) {
    let label: string = context.dataset.label || ''

    if (context?.parsed?.y !== null) {
      label = `${label}: ${formatReal(context.parsed.y)}`
    }

    return label
  }

  return {
    customizeMoneyAxisLabel,
    customizeDataAxisLabel,
    customizeTooltipTitle,
    customizeTooltipLabel,
  }
}
