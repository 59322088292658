import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    flexGrow: 1,
    height: '100%',
  },
  content: {
    flexGrow: 1,
    padding: '0px 32px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  list: {
    height: 'calc(var(--app-height) - 182px)',
    overflow: 'auto',
    [breakpoints.down('sm')]: {
      height: 'calc(var(--app-height) - 240px)',
    },
  },
  historicSlide: {
    width: '100%',
    [breakpoints.up('md')]: {
      width: '35%',
    },
    transition: 'width 0.3s ease-in-out 0s',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '36px',
  },
  deleteIconContent: {
    backgroundColor: '#FF0000',
    display: 'flex',
    borderRadius: '50%',
    padding: '16px',
  },
  shoppingCartContent: {
    backgroundColor: '#3371F2',
    display: 'flex',
    borderRadius: '50%',
    padding: '16px',
  },
  modalIcon: {
    width: '60px',
    height: '60px',
    '& > path': {
      fill: 'white',
    },
  },
  headContainer: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '16px',
    },
  },
  modalLabel: {
    fontSize: '22px',
    padding: '16px',
    textAlign: 'center',
  },
  toggleButton: ({ isDescFilter }) => ({
    backgroundColor: 'transparent !important',
    border: 'none',
    transform: `rotate(${isDescFilter ? '0deg' : '180deg'})`,
    transition: 'transform 0.3s ease-in-out',
  }),
  iconButton: {
    width: '24px',
    color: palette.grayscale[800],
  },
}))

export default useStyles
