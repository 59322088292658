import React from 'react'
import { Typography, Title, Button } from '@77sol/core'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { CheckIcon, CloseXIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { MESSAGES } from '../utils'

function ResponseMessage({
  onBack,
  onClose,
  billetUrl,
  paymentMethod,
  responseError,
  responseMessage,
}) {
  const history = useHistory()

  const handleOpenBillet = () => {
    window.open(billetUrl, '_blank')
  }

  const handleViewPayment = () => {
    onClose()
    history.push('/carteira/pagamentos')
  }

  return (
    <Box margin="auto">
      <Box textAlign="center">
        {responseMessage === 'error' ? (
          <CloseXIcon width="64px" color={palette.red[300]} />
        ) : (
          <CheckIcon width="64px" color={palette.green[300]} />
        )}
      </Box>
      <Box margin={4} display="flex" alignItems="center" flexDirection="column">
        <Title>{MESSAGES.TITLE[responseMessage]}</Title>
        <Typography color="grayscale" colorWeight="500">
          {responseError.errorField
            ? `${responseError.errorField}: ${responseError.errorMessage}`
            : MESSAGES.SUBTITLE[responseMessage]}
        </Typography>
      </Box>

      {responseMessage === 'error' ? (
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={onBack}>
            Voltar
          </Button>
        </Box>
      ) : (
        {
          creditCard: (
            <Box display="flex" justifyContent="center">
              <Button margin="horizontal" onClick={handleViewPayment}>
                Ver pagamento
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
            </Box>
          ),
          billet: (
            <Box display="flex" justifyContent="center">
              <Button margin="horizontal" onClick={handleOpenBillet}>
                Visualizar boleto
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Fechar
              </Button>
            </Box>
          ),
        }[paymentMethod]
      )}
    </Box>
  )
}

export default ResponseMessage
