import { Table } from '@77sol-ui/molecules'
import { convertProducts } from './utils/convertProducts'
import { handleOverload, parseBRL } from 'utils'
import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import * as S from './styles'

const COLUMNS_SIZE = '1fr 1fr 1fr 1fr'

interface IQuotationRowProps {
  quotation: IQuotationDTO
}

export function QuotationRow({ quotation }: IQuotationRowProps) {
  const products = convertProducts(quotation.products)
  const addedItems = convertProducts(quotation.added_items)

  const overload = handleOverload(
    products?.filter(({ id }: { id: number }) => !addedItems?.includes(id)),
  )

  return (
    <Table.Root variant="bordered">
      <Table.Header headerTemplateColumns={COLUMNS_SIZE}>
        <Table.Head>Cotação</Table.Head>
        <Table.Head>KWp</Table.Head>
        <Table.Head>Sobrecarga</Table.Head>
        <Table.Head>Valor</Table.Head>
      </Table.Header>
      <Table.Body>
        <S.TableRow border="top" rowTemplateColumns={COLUMNS_SIZE}>
          <Table.Cell mobileHead="Cotação">#{quotation.id}</Table.Cell>
          <Table.Cell mobileHead="KWp">{quotation.potency}w</Table.Cell>
          <Table.Cell mobileHead="Sobrecarga">{overload}%</Table.Cell>
          <Table.Cell mobileHead="Valor">
            {parseBRL(quotation.total_value)}
          </Table.Cell>
        </S.TableRow>
      </Table.Body>
    </Table.Root>
  )
}
