import * as S from './styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Typography, Button, Badge } from '@77sol/core'
import { useSelector } from 'react-redux'
import { separateProductName } from 'utils/productName'
import { CircularProgress, EmptyState } from 'components'
import { useState } from 'react'

export function List({
  products,
  isLoading,
  hasMoreProducts,
  onNextPage,
  onChangeProduct,
}) {
  const [isChanging, setIsChanging] = useState(null)

  const userId = useSelector((state) => state.ReducerProfile.userProfile.id)
  const isOperacional = userId === 4282

  function formatProductName({ nome, available_in }) {
    return separateProductName(nome, available_in)
  }

  async function handleChangeProduct(product) {
    setIsChanging(product.id)

    try {
      await onChangeProduct(product)
    } finally {
      setIsChanging(null)
    }
  }

  if (isLoading) {
    return (
      <S.Container>
        <CircularProgress />
      </S.Container>
    )
  }

  if (!products.length) {
    return (
      <S.Container>
        <EmptyState text="Nenhum produto encontrado." />
      </S.Container>
    )
  }

  return (
    <S.Container>
      <InfiniteScroll
        height="100%"
        next={onNextPage}
        hasMore={hasMoreProducts}
        dataLength={products.length}
        loader={<S.Loader />}
      >
        {products?.map((product) => (
          <S.Item key={product?.id}>
            <S.Content>
              <S.Image>
                <img
                  src={product?.imagens || product?.mainImage}
                  alt={product?.nome}
                />
              </S.Image>
              <div>
                <Typography size="small" color="primary" colorWeight="300">
                  {formatProductName(product).productName}
                </Typography>
                <Typography size="xsmall" color="grayscale" colorWeight="500">
                  {formatProductName(product).availabilityMessage}
                </Typography>
                <S.Badge>
                  {Boolean(product?.best_choice) && (
                    <Badge
                      size="medium"
                      color="green"
                      variant="contained"
                      label="Mesmo estoque"
                    />
                  )}
                  {isOperacional && product?.nome_fornecedor && (
                    <Badge
                      size="medium"
                      color="orange"
                      variant="contained"
                      label={product?.nome_fornecedor}
                    />
                  )}
                  {product?.garantia && (
                    <Badge
                      size="medium"
                      color="primary"
                      variant="contained"
                      label={`${product?.garantia} anos de garantia`}
                    />
                  )}
                </S.Badge>
              </div>
            </S.Content>
            <S.Button>
              <Button
                fullWidth
                size="small"
                variant="outlined"
                isLoading={isChanging === product.id}
                onClick={() => handleChangeProduct(product)}
              />
            </S.Button>
          </S.Item>
        ))}
      </InfiniteScroll>
    </S.Container>
  )
}
