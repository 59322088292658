import styled from 'styled-components'

interface IContainerProps {
  width: number
  height: number
}

export const Container = styled.figure<IContainerProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  display: grid;
  place-content: center;
`

export const Logo = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
`
