import { useState } from 'react'
import { Users } from 'lucide-react'
import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { LeadsTable } from './components/LeadsTable'
import { type ILeadModal } from 'domains/solleads/get-leads-modal/services/types'
import { solLeadsTrackers } from 'services/tracker/events/solLeads/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { useAcceptLead } from 'domains/solleads/accept-lead/hooks/useAcceptLead'
import { acceptLeadMessages } from 'containers/SolLeadAlert/constants/leadsMessages'
import { useToast } from '@77sol/core'
import { queryClient } from 'services/queryClient'
import { useSolLeadsModalStore } from './store/useSolLeadsModalStore'
import { useSolLeadAlert } from 'containers/SolLeadAlert/hooks/useSolLeadAlert'
import { LEADS_ALERT } from 'domains/solleads/get-leads-alert/constants/endpointKeys'
import { LEADS_MODAL } from 'domains/solleads/get-leads-modal/constants/endpointKeys'

export function LeadsModal() {
  const { handleRejectLeads, redirectToProposal, eventProperties } =
    useSolLeadAlert()

  const [selectedLead, setSelectedLead] = useState<ILeadModal>()

  const { logEvent } = useAmplitude()

  const disabledConfirm = !selectedLead

  const { mutateAsync: acceptLead } = useAcceptLead()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const setIsSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.setIsSolLeadsModalOpen,
  )

  function handleLeadsModal(open: boolean) {
    const { openLeadsModal, closeLeadModal } = solLeadsTrackers.actions
    logEvent(open ? openLeadsModal : closeLeadModal)

    setIsSolLeadsModalOpen(open)
  }

  const handleConfirm = () => {
    logEvent(solLeadsTrackers.actions.acceptLead, eventProperties)

    if (selectedLead) {
      const leads = [selectedLead.distribution_lead_id]

      acceptLead({ leads })
        .then(({ data }) => {
          queryClient.invalidateQueries({ queryKey: [LEADS_ALERT] })
          queryClient.invalidateQueries({ queryKey: [LEADS_MODAL] })
          handleLeadsModal(false)
          onSuccessOpenToast(acceptLeadMessages.success)
          redirectToProposal(data.customer)
        })
        .catch(() => {
          onErrorOpenToast(acceptLeadMessages.error)
        })
    }
  }

  function handleSelectLead(lead: ILeadModal) {
    logEvent(solLeadsTrackers.actions.selectLead)

    setSelectedLead(lead)
  }

  return (
    <Modal.Root open onOpenChange={handleLeadsModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper id="solleads-modal" maxHeight="90vh" maxWidth="60vw">
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={Users} size={24} />
            <Modal.Title>Confira todos os leads disponíveis</Modal.Title>
            <Modal.Description>
              Os leads são enviados pela equipe da 77Sol, e aguardam sua
              aprovação.
            </Modal.Description>
          </Modal.Header>
          <Modal.Content>
            <LeadsTable
              selected={selectedLead}
              onSelect={handleSelectLead}
              onReject={handleRejectLeads}
            />
          </Modal.Content>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline">
                Cancelar
              </Button>
            </Modal.Close>
            <Button
              fullWidth
              onClick={handleConfirm}
              disabled={disabledConfirm}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
