import { Hidden } from '@material-ui/core'
import { Slide } from '@77sol/core'
import { Container, Content, Wrapper } from './styles'
import { BuyModal } from '../Modals'
import FinancingSimulations from './components/FinancingSimulations'
import SimulationDetails from './components/Tabs'
import { FinancingList } from './components/FinancingList'
import { useFinancingContainer } from './hooks/useFinancingContainer'
import { FinancingFilters } from './components/FinancingFilters'
import { Title } from 'components/Title'

function FinancingContainer({ defaultTab, financingRequestId, simulationId }) {
  const {
    downSm,
    slideStyle,
    financingCustomer,
    selectedFinancing,
    isFinancingPerValue,
    handleIconClick,
    onSelectSimulation,
    handleCloseModal,
    handleInvalidateFinancingQueries,
    selectedSimulation,
    openBuyModal,
    slideAndDetailOpen,
    isOpenSimulations,
    isOpenSimulationDetails,
    onRowClick,
    handleFinancialDetailClose,
    handleFinancingTabsClose,
    setSelectedSimulation,
    currentTabFinancings,
  } = useFinancingContainer({
    defaultTab,
    financingRequestId,
    simulationId,
  })

  return (
    <Wrapper>
      <Container>
        <Hidden
          mdDown={slideAndDetailOpen}
          smDown={downSm && (isOpenSimulations || isOpenSimulationDetails)}
        >
          <Content>
            <Title text="Financiamentos" />
            {!isOpenSimulations && !isOpenSimulationDetails && (
              <FinancingFilters isPerValue={isFinancingPerValue} />
            )}

            <FinancingList
              isOpenSimulationDetails={isOpenSimulationDetails}
              isOpenSimulations={isOpenSimulations}
              isPerValue={isFinancingPerValue}
              onRowClick={onRowClick}
              selectedFinancing={selectedFinancing}
              onIconClick={handleIconClick}
              defaultTab={defaultTab}
              financingId={financingRequestId}
            />
          </Content>
        </Hidden>

        <Slide isOpen={isOpenSimulations} style={slideStyle}>
          <FinancingSimulations
            onClose={handleFinancialDetailClose}
            onCloseFinancingTabs={handleFinancingTabsClose}
            isOpenSimulationDetails={isOpenSimulationDetails}
            selectedSimulation={selectedSimulation}
            selectedFinancing={selectedFinancing}
            setSelectedSimulation={setSelectedSimulation}
            onSelectSimulation={onSelectSimulation}
            isFinancingPerValue={isFinancingPerValue}
            simulationId={simulationId}
          />
        </Slide>
        <Slide isOpen={isOpenSimulationDetails} style={slideStyle}>
          <SimulationDetails
            selectedSimulationId={selectedSimulation?.rowData?.id}
            handleRefreshFinancingList={handleInvalidateFinancingQueries}
            onClose={handleFinancingTabsClose}
            currentTabFinancings={currentTabFinancings}
          />
        </Slide>
      </Container>
      {openBuyModal.isOpen && (
        <BuyModal
          isOpen
          handleCloseModal={handleCloseModal}
          proposalId={openBuyModal?.proposalId}
        />
      )}
    </Wrapper>
  )
}

export default FinancingContainer
