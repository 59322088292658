import { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

function ScrollReset() {
  const router = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [router.location.pathname])

  return null
}

export default ScrollReset
