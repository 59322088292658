import { QuotationHeader } from './components/QuotationHeader'
import useWindowSize from 'hooks/useWindowSize'
import { EquipmentQuotationContainer } from 'containers/EquipmentQuotationContainer'
import * as S from './styles'
import { useParams } from 'react-router-dom'

export function Quotation() {
  const { width } = useWindowSize()
  const { dimensioningId } = useParams<{ dimensioningId: string }>()
  const isMobile = width <= 1280

  return (
    <S.Page title="Cotar equipamentos | 77Sol">
      {isMobile && <QuotationHeader />}
      <EquipmentQuotationContainer dimensioningId={dimensioningId} />
    </S.Page>
  )
}
