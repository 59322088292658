import API from 'api'

import {
  BagIcon,
  DeleteIcon,
  DownloadIcon,
  RoomServiceHelpIcon,
  FilledSaveFavoriteBookmark,
  SaveFavoriteBookmarkIcon,
  ActionPlusIcon,
  EditIcon,
} from '@77sol/icons/dist'
import * as ProposalAction from 'store/actions/index'
import palette from 'app_palette'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'

import { FinancialLabel, TableList } from 'components'
import { parseBRL } from 'utils'
import { Spacer, Tooltip, Typography } from '@77sol/core'
import { IconButton, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import * as FileSaver from 'file-saver'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useAmplitude } from 'hooks/useAmplitude'
import { useHistory } from 'react-router-dom'
import { projectsTracker } from 'services/tracker/events/projects/trackers'
import { usePlan } from 'hooks/usePlan'
import { EmptyState } from '../EmptyState'
import { useCheckQuotationKit } from 'hooks/quotation/useCheckQuotationKit'

function DimensioningList({
  dimensionings,
  proposal,
  setSelectedQuote,
  setOpenCheckoutModal,
  setOpenLoadingPdf,
  selectedRow,
  getAllProposalByProject,
  setIsModalOpen,
  setSizingModal,
  seeMoreDetailsProposal,
  handleFavorite,
}) {
  const dimensioningsIsEmpty = false
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { logEvent } = useAmplitude()
  const history = useHistory()

  const { isPro } = usePlan()

  const { validateQuotationKit } = useCheckQuotationKit({
    callbackKitHasNotChanged: (id) => {
      setOpenCheckoutModal(id)
    },
  })

  if (dimensioningsIsEmpty) {
    return <EmptyState text="Nenhuma cotação neste dimensionamento" />
  }

  const handlePdf = (quote) => {
    setOpenLoadingPdf(true)
    API.get(`/admin/pdf-generate/quotation/${quote.id}`, {
      headers: {
        responseType: 'blob',
      },
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' })
        FileSaver.saveAs(
          file,
          `${quote.id}_Cotacao_${quote.potency
            .toString()
            .replace('.', '_')}kWp_${moment(new Date()).format('L')}`,
        )
      })
      .finally(() => {
        setOpenLoadingPdf(false)
      })
  }

  function formatQuotesTable(quotes) {
    return quotes?.map((quote) => {
      return {
        favorite: {
          type: 'component',
          Component: () => (
            <IconButton
              onClick={() => {
                handleFavorite(quote.id)
              }}
            >
              {quote.is_favorite ? (
                <FilledSaveFavoriteBookmark
                  width="24px"
                  color={palette.yellow[400]}
                />
              ) : (
                <SaveFavoriteBookmarkIcon
                  width="24px"
                  color={palette.grayscale[300]}
                />
              )}
            </IconButton>
          ),
        },
        id: <Typography size="xsmall">{`#${quote.id}`}</Typography>,
        potency: <Typography size="xsmall">{quote.potency}Kw</Typography>,
        totalValue: (
          <Typography size="xsmall">{parseBRL(quote.total_value)}</Typography>
        ),
        financing: (
          <FinancialLabel status={quote.financing_status} size="xsmall" />
        ),
        rowData: quote,
      }
    })
  }

  async function handleValidateExpiredQuotation(quotation) {
    logEvent(projectsTracker.actions.reserveEquipment)
    try {
      const response = await API.get(
        `/proposta/validate-proposals/${proposal.id}`,
      )

      const returnObject = response.data
      const allDimensionings = Object.keys(returnObject[proposal.id])
      const allQuotations = []
      allDimensionings.forEach((item) => {
        allQuotations.push(...Object.entries(returnObject[proposal.id][item]))
      })
      allQuotations.forEach((item) => {
        if (
          item[1].resultValues.length !== 0 ||
          Number(item[1].resultVisible) !== 0
        ) {
          const outdatedQuoteToReserve = { quotation_id: quotation.id }
          return seeMoreDetailsProposal(proposal, outdatedQuoteToReserve)
        }
      })
      validateQuotationKit(quotation.id)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNewSizing = () => {
    if (!isPro()) return

    const { customer_id } = selectedRow.rowData

    return history.push({
      pathname: '/dimensionar',
      state: {
        type: 'new_sizing',
        proposal,
        projectId: proposal.id,
        customerId: customer_id,
      },
    })
  }

  return dimensionings.map((dimensioning, index) =>
    downSm ? (
      <>
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Novo dimensionamento">
              <IconButton
                aria-label="novo dimensionamento"
                onClick={() => handleNewSizing()}
              >
                <ActionPlusIcon color="black" width="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar dimensionamento">
              <IconButton
                aria-label="editar dimensionamento"
                onClick={() => {
                  dispatch(
                    ProposalAction.SaveSizingRedux({
                      ...dimensioning,
                      ...dimensioning.properties,
                      id: dimensioning.id,
                    }),
                  )
                  setSizingModal({
                    ...dimensioning,
                    ...dimensioning.properties,
                    id: dimensioning.id,
                  })
                }}
              >
                <EditIcon color="black" width="20px" />
              </IconButton>
            </Tooltip>
          </div>
          <Spacer size="20" direction="vertical" />
          <div>
            <Typography color="grayscale" colorWeight="500">
              Dimensionamento {index + 1}
            </Typography>
            <Typography color="grayscale" colorWeight="600">
              {dimensioning.kwp}
              Kwp
            </Typography>
            <Spacer size="22" direction="vertical" />
            <Typography color="grayscale" colorWeight="600">
              {dimensioning.average_consumption}
              kwh/mês
            </Typography>
          </div>
        </>
        {dimensioning.quotations.length === 0 ? (
          <EmptyState text="Nenhuma cotação neste dimensionamento" />
        ) : (
          formatQuotesTable(dimensioning.quotations).map((item) => (
            <>
              <Spacer size="9" direction="horizontal" />
              <Paper
                style={{
                  padding: '10px 20px',
                  boxShadow: '0px 2px 4px -2px gray',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleFavorite(item.rowData.id)
                    }}
                  >
                    {item.rowData.is_favorite ? (
                      <FilledSaveFavoriteBookmark
                        width="24px"
                        color={palette.yellow[400]}
                      />
                    ) : (
                      <SaveFavoriteBookmarkIcon
                        width="24px"
                        color={palette.grayscale[300]}
                      />
                    )}
                  </IconButton>
                  {item.rowData.reserved ? (
                    <Tooltip
                      title={currentEnterpriseConfigs.orderGeneratedText}
                    >
                      <IconButton
                        aria-label={currentEnterpriseConfigs.orderGeneratedText}
                      >
                        <BagIcon color={palette.primary[300]} width="20px" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={currentEnterpriseConfigs.textToGenerateOrder}
                    >
                      <IconButton
                        aria-label="reservar equipamentos"
                        onClick={() =>
                          handleValidateExpiredQuotation(item.rowData)
                        }
                      >
                        <RoomServiceHelpIcon color="black" width="20px" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Baixar">
                    <IconButton
                      aria-label="baixar"
                      onClick={() => {
                        setSelectedQuote(item.rowData)
                        setTimeout(() => {
                          handlePdf(item.rowData)
                        }, 500)
                      }}
                    >
                      <DownloadIcon color="black" width="20px" />
                    </IconButton>
                  </Tooltip>
                  {dimensioning?.quotations.length > 1 && (
                    <Tooltip title="Deletar">
                      <IconButton
                        aria-label="deletar"
                        onClick={() => {
                          getAllProposalByProject(proposal.id)
                          setSelectedQuote(item.rowData)
                          setIsModalOpen('deleteQuotation')
                        }}
                      >
                        <DeleteIcon color={palette.red[300]} width="20px" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <Typography>#{item.rowData.id}</Typography>
                <Typography>Potência: {item.rowData.potency}kw</Typography>
                <Typography>
                  Valor Total: {parseBRL(item.rowData.total_value)}
                </Typography>
                <Spacer size="8" direction="horizontal" />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FinancialLabel status={item.rowData.financing_status} />
                </div>
              </Paper>
              <Spacer size="18" direction="horizontal" />
            </>
          ))
        )}
      </>
    ) : (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Spacer size="20" direction="vertical" />
            <Typography color="grayscale" colorWeight="500">
              Dimensionamento {index + 1}
            </Typography>
            <Spacer size="48" direction="vertical" />
            <Typography size="xsmall" color="grayscale" colorWeight="600">
              {dimensioning.kwp}
              Kwp
            </Typography>
            <Spacer size="22" direction="vertical" />
            <Typography size="xsmall" color="grayscale" colorWeight="600">
              {dimensioning.average_consumption}
              kwh/mês
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Tooltip title="Novo dimensionamento">
              <IconButton
                aria-label="novo dimensionamento"
                onClick={() => handleNewSizing()}
              >
                <ActionPlusIcon color="black" width="20px" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Editar dimensionamento">
              <IconButton
                aria-label="editar dimensionamento"
                onClick={() => {
                  dispatch(
                    ProposalAction.SaveSizingRedux({
                      ...dimensioning,
                      ...dimensioning.properties,
                      id: dimensioning.id,
                    }),
                  )
                  setSizingModal({
                    ...dimensioning,
                    ...dimensioning.properties,
                    id: dimensioning.id,
                  })
                }}
              >
                <EditIcon color="black" width="20px" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {dimensioning.quotations.length === 0 ? (
          <EmptyState text="Nenhuma cotação neste dimensionamento" />
        ) : (
          <TableList
            columns={['', 'ID', 'Potência', 'Valor Total', 'Financiamento']}
            headerSize="small"
            LastItem={({ row }) => (
              <>
                {row.rowData.reserved ? (
                  <Tooltip title={currentEnterpriseConfigs.orderGeneratedText}>
                    <IconButton
                      style={{ cursor: 'auto' }}
                      aria-label={currentEnterpriseConfigs.orderGeneratedText}
                    >
                      <BagIcon color={palette.primary[300]} width="20px" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={currentEnterpriseConfigs.textToGenerateOrder}>
                    <IconButton
                      aria-label="reservar equipamentos"
                      onClick={() =>
                        handleValidateExpiredQuotation(row.rowData)
                      }
                    >
                      <RoomServiceHelpIcon color="black" width="20px" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Baixar">
                  <IconButton
                    aria-label="baixar"
                    onClick={() => {
                      setSelectedQuote(row.rowData)
                      setTimeout(() => {
                        handlePdf(row.rowData)
                      }, 500)
                    }}
                  >
                    <DownloadIcon color="black" width="20px" />
                  </IconButton>
                </Tooltip>

                {dimensioning?.quotations.length > 1 && (
                  <Tooltip title="Deletar">
                    <IconButton
                      aria-label="deletar"
                      onClick={() => {
                        getAllProposalByProject(proposal.id)
                        setSelectedQuote(row.rowData)
                        setIsModalOpen('deleteQuotation')
                      }}
                    >
                      <DeleteIcon color={palette.red[300]} width="20px" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            values={formatQuotesTable(dimensioning.quotations)}
          />
        )}
      </>
    ),
  )
}

export { DimensioningList }
