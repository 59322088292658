import { type FormEvent, type ButtonHTMLAttributes } from 'react'
import * as S from './styles'
import { useCreateFinancingPerQuotationFormContext } from '../../../hooks/useCreateFinancingPerQuotationForm'

export const ClearFieldsButton = ({
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {
    reset,
    getValues,
    formState: { submitCount },
    trigger,
  } = useCreateFinancingPerQuotationFormContext()

  const handleClearFields = (e: FormEvent) => {
    e.preventDefault()

    reset(
      {
        client_type: getValues('client_type'),
        financing_value: getValues('financing_value'),
        project_value: getValues('project_value'),
        client_name: '',
        client_birth_date: '',
        client_document: '',
        input_value: 0.0,
        client_income: 0.0,
        grace_days: '90',
      },
      {
        keepSubmitCount: true,
        keepIsSubmitted: true,
      },
    )

    if (submitCount > 0) trigger()
  }

  return (
    <S.Container type="button" onClick={handleClearFields} {...props}>
      Limpar dados
    </S.Container>
  )
}
