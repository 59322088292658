import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import {
  CloseXIcon,
  EditIcon,
  ViewIcon,
  DollarIcon,
  TimeIcon,
} from '@77sol/icons/dist'
import { InfoPaper, Spacer, Paper } from '@77sol/core'
import palette from 'app_palette'
import smoothscroll from 'smoothscroll-polyfill'
import {
  ConcessionariaCard,
  TarifaCard,
  CorrecaoAnualCard,
  InvestimentoRetornoCard,
} from '../../..'
import { DragPaper } from '../../..'
import DragIconBlue from '../../../../img/dragBlue.png'
import DragIcon from '../../../../img/dragGrey.png'
import { getRoiMessage } from 'views/DimensionarV2/components/StepFour/utils'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
  paddingSectionMarginLeft: {
    paddingLeft: '4px',
    position: 'relative',
  },
  paddingSectionMarginRight: {
    paddingRight: '4px',
    position: 'relative',
  },
})

function DigitalRetornoSubCards({
  editMode,
  internalEditing,
  setInternalEditing,
  drag,
  setDrag,
  editingMore,
  selectedQuote,
  setEditingMore,
  proposal,
  size,
  retornoSubCards,
  setRetornoSubCards,
  returnData,
  setReturnData,
  colors,
}) {
  const classes = useStyles()

  return (
    <Droppable
      direction="horizontal"
      type="droppableRetornoSubCards"
      droppableId="droppableRetornoSubCards"
    >
      {(provided, snapshot) => (
        <Grid
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ height: '100%' }}
          container
        >
          {retornoSubCards.map(
            (container, index) =>
              container.visible && (
                <Draggable
                  isDragDisabled={
                    editMode !== 'investingContainer' ||
                    editingMore === 'retornoEmContainer' ||
                    editingMore === '30AnosContainer'
                  }
                  key={container.id}
                  draggableId={container.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Grid
                      item
                      className={
                        index === 0
                          ? classes.paddingSectionMarginRight
                          : classes.paddingSectionMarginLeft
                      }
                      xs={12}
                      sm={12}
                      md={
                        retornoSubCards.filter((item) => item.visible === true)
                          .length === 1
                          ? 12
                          : 6
                      }
                      lg={
                        retornoSubCards.filter((item) => item.visible === true)
                          .length === 1
                          ? 12
                          : 6
                      }
                      xl={
                        retornoSubCards.filter((item) => item.visible === true)
                          .length === 1
                          ? 12
                          : container.breakpoint
                      }
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      onClick={(e) => {
                        if (editMode === 'investingContainer') {
                          internalEditing && internalEditing === container.id
                            ? setInternalEditing('')
                            : setInternalEditing(container.id)
                          setEditingMore('')
                        }
                      }}
                    >
                      {container.visible && (
                        <>
                          {container.id === 'retornoEmContainer' && (
                            <InfoPaper
                              colors={colors}
                              isDragging={snapshot.isDragging}
                              icon={
                                <TimeIcon
                                  height="48"
                                  width="48"
                                  color={
                                    colors
                                      ? colors.primary_color
                                      : palette.primary[300]
                                  }
                                />
                              }
                              title="Tenha seu retorno em"
                              text={
                                returnData?.payback &&
                                getRoiMessage(returnData?.payback)
                              }
                            />
                          )}
                          {container.id === '30AnosContainer' && (
                            <InfoPaper
                              colors={colors}
                              isDragging={snapshot.isDragging}
                              icon={
                                <DollarIcon
                                  height="48"
                                  width="48"
                                  color={
                                    colors
                                      ? colors.primary_color
                                      : palette.primary[300]
                                  }
                                />
                              }
                              title={`Em ${
                                returnData?.anos ? returnData?.anos : 25
                              } anos você economiza`}
                              text={
                                returnData?.dadosConsolidados &&
                                `${returnData?.dadosConsolidados[
                                  returnData.dadosConsolidados.length - 1
                                ].fluxoAcumuladoAnual?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}`
                              }
                            />
                          )}
                          {snapshot.isDragging && <DragPaper />}
                          {editMode === 'investingContainer' &&
                            internalEditing === container.id &&
                            container.id !== 'retornoSubCards' &&
                            container.id !== 'tirVplContainers' && (
                              <div
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                  position: 'absolute',
                                  zIndex: 10,
                                  top: '-15px',
                                  left: '-15px',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Paper
                                  style={{
                                    padding: '16px 24px',
                                    boxShadow: 'none',
                                    cursor: 'auto',
                                    maxHeight: '50vh',
                                    width: 'auto',
                                    border: `1px solid ${palette.grayscale[300]}`,
                                    overflowY: 'auto',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="link_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {container.display}
                                    </Typography>
                                    <Spacer size="24" direction="vertical" />
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {editingMore === container.id ? (
                                        <Tooltip title="Fechar">
                                          <CloseXIcon
                                            width="24"
                                            onClick={() =>
                                              editingMore === container.id
                                                ? setEditingMore('')
                                                : setEditingMore(container.id)
                                            }
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <>
                                          <Tooltip title="Editar">
                                            <EditIcon
                                              onClick={() =>
                                                editingMore === container.id
                                                  ? setEditingMore('')
                                                  : setEditingMore(container.id)
                                              }
                                              style={{ cursor: 'pointer' }}
                                              width="24"
                                              color={palette.grayscale[700]}
                                            />
                                          </Tooltip>
                                          <Spacer
                                            size="12"
                                            direction="vertical"
                                          />
                                          <Tooltip title="Arrastar">
                                            <img
                                              onClick={() =>
                                                drag === container.id
                                                  ? setDrag('')
                                                  : setDrag(container.id)
                                              }
                                              src={
                                                internalEditing === container.id
                                                  ? DragIconBlue
                                                  : DragIcon
                                              }
                                              style={{
                                                width: '20px',
                                                cursor: 'pointer',
                                                color:
                                                  drag === container.id
                                                    ? '#1335c6'
                                                    : 'rgb(112, 112, 114)',
                                              }}
                                              alt="icone de arrastar"
                                            />
                                          </Tooltip>
                                          <Spacer
                                            size="12"
                                            direction="vertical"
                                          />
                                          <Tooltip title="Esconder">
                                            <ViewIcon
                                              onClick={() => {
                                                const newArr = [
                                                  ...retornoSubCards,
                                                ]
                                                const index = newArr.findIndex(
                                                  (item) =>
                                                    item.id === container.id,
                                                )
                                                newArr[index].visible =
                                                  !newArr[index].visible
                                                setRetornoSubCards({
                                                  containers: newArr,
                                                })
                                              }}
                                              style={{ cursor: 'pointer' }}
                                              width="24"
                                              color={palette.grayscale[700]}
                                            />
                                          </Tooltip>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {editingMore === container.id && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Grid
                                        style={{
                                          width: '100%',
                                          padding: '0px',
                                          margin: '0px',
                                          marginTop: '16px',
                                        }}
                                        container
                                        spacing={2}
                                      >
                                        <Grid xs={12} md={12} item>
                                          <ConcessionariaCard
                                            proposal={proposal}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={12} item>
                                          <TarifaCard
                                            selectedQuote={selectedQuote}
                                            setReturnData={setReturnData}
                                            returnData={returnData}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={12} item>
                                          <CorrecaoAnualCard
                                            selectedQuote={selectedQuote}
                                            setReturnData={setReturnData}
                                            returnData={returnData}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={12} item>
                                          <InvestimentoRetornoCard
                                            returnData={returnData}
                                            onlyShow
                                            noPadding
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}
                                </Paper>
                              </div>
                            )}
                        </>
                      )}
                    </Grid>
                  )}
                </Draggable>
              ),
          )}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  )
}

export default DigitalRetornoSubCards
