import { type IChartData } from '@77sol-ui/molecules'
import { colors } from '@77sol-ui/tokens'

const lineStyles: Record<
  'vendas' | 'financiamentos',
  Omit<IChartData, 'data' | 'yAxisID'>
> = {
  vendas: {
    label: 'Vendas',
    lineColor: colors.blue[300],
    backgroundColor: colors.blue[300],
    fillColor: colors.blue[300],
    lineWidth: 3,
  },
  financiamentos: {
    label: 'Financiamentos',
    lineColor: colors.orange[300],
    backgroundColor: colors.orange[300],
    fillColor: colors.orange[300],
    lineWidth: 3,
  },
}

export { lineStyles }
