import { useState } from 'react'

export function useDeleteQuotation() {
  const [quotationDelete, setQuotationDelete] = useState(null)

  function handleOpenDeleteModal({ quotation = null }) {
    setQuotationDelete(quotation)
  }

  function handleCloseDeleteModal() {
    setQuotationDelete(null)
  }

  return {
    quotationDelete,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  }
}
