import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  border: 2px solid orange;

  margin: 32px 0;

  p,
  span {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  span {
    cursor: pointer;
    color: ${palette.primary[300]};
  }
`
