export const INITIAL = {
  ALTER_DELIVERY: {
    old_value: '',
    new_value: '',
  },

  DELIVERY_ADDRESS: {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  },
  BILLING_ADDRESS: {
    nome: '',
    documento: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  },
}
