import { ListFinancingValue } from './components/ListFinancingValue'
import { ListFinancingQuotation } from './components/ListFinancingQuotation'

export function FinancingList({ isPerValue, ...rest }) {
  if (isPerValue) {
    return <ListFinancingValue isPerValue={isPerValue} {...rest} />
  }

  return <ListFinancingQuotation {...rest} />
}
