import React, { useState, useEffect } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import {
  Input,
  Typography,
  TextArea,
  CoverImageEdit,
  GroupButtonMobile,
} from 'components'
import { Spacer, Button, Paper, Checkbox, Modal } from '@77sol/core'
import {
  FullScreenExpandResizeIcon,
  FullScreenResizeExpandIcon,
  ArrowChevronBackIcon,
  ArrowChevronForwardIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import API from 'api'

function NewContainer({
  title,
  setTitle,
  content,
  setContent,
  handleSave,
  loading,
  image,
  setImage,
  fieldType,
  setFieldType,
  themeCustomSwitch,
  setThemeCustomSwitch,
  themeType,
  setThemeType,
  selectedTheme,
  setSelectedTheme,
}) {
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  const [openModalThemes, setOpenModalThemes] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loadingThemes, setLoadingThemes] = useState(false)

  const [themes, setThemes] = useState({})

  const prev = () => {
    setCurrentSlide(currentSlide - 1)
  }

  const next = () => {
    setCurrentSlide(currentSlide + 1)
  }

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index)
    }
  }

  useEffect(() => {
    if (themes && themeCustomSwitch === 0 && title) {
      setThemeType(title.split('/')[0])
      const themeIndex = themes[title.split('/')[0]]?.findIndex(
        (item) => item.url === content,
      )
      setSelectedTheme(themeIndex)
    }
  }, [content])

  useEffect(() => {
    setLoadingThemes(true)

    API.get('/themes/all').then((res) => {
      setLoadingThemes(false)
      setThemes(res.data.themes)
      if (!content && !image) {
        setContent(res?.data?.themes?.covers[0]?.url)
        setTitle(res?.data?.themes?.covers[0]?.title)
      }
      if (fieldType === 'theme') {
        const themeIndex = res?.data?.themes[title.split('/')[0]]?.findIndex(
          (item) => item.url === content,
        )
        setSelectedTheme(themeIndex)
        setThemeType(title.split('/')[0])
      }
    })
  }, [])

  return (
    <div style={{ padding: '0px 32px', width: '100%' }}>
      <div>
        <Typography type="text_medium" color="grayscale" colorWeight="700">
          Estruture sua proposta
        </Typography>
      </div>
      <Spacer size={downLg ? '12' : '16'} direction="horizontal" />
      <GroupButtonMobile
        value={themeCustomSwitch}
        setValue={(value) => {
          setThemeCustomSwitch(value)
          if (value === 0) {
            setFieldType('theme')
          } else {
            setTitle('')
            setContent('')
            setFieldType('text')
          }
        }}
        labels={['Tema', 'Criar']}
      />
      <Spacer size={downLg ? '16' : '32'} direction="horizontal" />
      {themeCustomSwitch === 0 && (
        <>
          <Input
            value={themeType}
            setValue={(value) => {
              setThemeType(value)
              setContent(themes?.[value][0]?.url)
              setTitle(themes?.[value][0]?.title)
              setSelectedTheme(0)
            }}
            label="Tipo de campo"
            select
          >
            <option key="covers" value="covers">
              Capa do projeto
            </option>
            <option key="explanations" value="explanations">
              Explicações & Conteúdos
            </option>
            <option key="benefits" value="benefits">
              Vantagens & Diferenciais
            </option>
            <option key="partners" value="partners">
              Parceiros & Soluções
            </option>
          </Input>
          <Spacer size="24" direction="horizontal" />
          {loadingThemes ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                size={48}
                thickness={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: palette.primary[300],
                }}
              />
            </div>
          ) : (
            <Grid
              style={{
                maxHeight: 'calc(var(--app-height) * 0.4)',
                overflowY: 'auto',
              }}
              spacing={2}
              container
            >
              {themes?.[themeType]?.map((item, i) => (
                <Grid key={i} item xs={6} md={6}>
                  <Paper
                    style={{
                      position: 'relative',
                      padding: '0px',
                      borderRadius: '24px',
                      backgroundImage: `url(${item.url})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'bottom right',
                      height: '160px',
                    }}
                  >
                    <Paper
                      style={{
                        padding: '8px',
                        position: 'absolute',
                        bottom: '16px',
                        right: '16px',
                        display: 'flex',
                      }}
                    >
                      <Checkbox
                        checked={selectedTheme === i}
                        onChange={() => {
                          setSelectedTheme(i)
                          setContent(item?.url)
                          setTitle(item?.title)
                        }}
                        size="small"
                      />
                      <Spacer size="12" direction="vertical" />
                      <FullScreenExpandResizeIcon
                        onClick={() => {
                          setOpenModalThemes(true)
                          setCurrentSlide(i)
                        }}
                        style={{ cursor: 'pointer' }}
                        width="24"
                        color={palette.grayscale[400]}
                      />
                    </Paper>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
      {themeCustomSwitch === 1 && (
        <Grid spacing={2} container>
          <Grid item xs={12} md={12}>
            <Input
              value={title}
              setValue={setTitle}
              label="Insira um titulo para o campo"
            />
            <Spacer size="24" direction="horizontal" />
            <Input
              value={fieldType}
              setValue={(value) => {
                setContent('')
                setImage('')
                setFieldType(value)
              }}
              label="Tipo de campo"
              select
            >
              <option key="text" value="text">
                Texto
              </option>
              <option key="image" value="image">
                Imagem
              </option>
            </Input>
            <Spacer size="24" direction="horizontal" />
            {fieldType === 'text' ? (
              <TextArea
                value={content}
                setValue={setContent}
                rows="8"
                placeholder="Digite aqui o conteúdo referente a informação que deseja informar ao cliente"
              />
            ) : (
              <CoverImageEdit
                image={image}
                handleFinish={setImage}
                handleRemoveImage={() => setImage()}
                hideText
                equips
              />
            )}
          </Grid>
        </Grid>
      )}
      <Spacer size="64" direction="horizontal" />
      <Button
        style={{ width: '100%', justifyContent: 'center' }}
        disabled={
          themeCustomSwitch === 1 ? !title || (!content && !image) : !themeType
        }
        onClick={() => handleSave()}
        isLoading={loading}
      >
        Salvar campo
      </Button>
      <Spacer size="32" direction="horizontal" />
      <Modal open={openModalThemes} onClose={() => setOpenModalThemes(false)}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Paper
            style={{
              padding: '8px',
              position: 'absolute',
              top: '0px',
              right: '0px',
              display: 'flex',
            }}
          >
            <Checkbox
              checked={selectedTheme === currentSlide}
              onChange={() => {
                setSelectedTheme(currentSlide)
                setContent(themes?.[themeType][currentSlide]?.url)
                setTitle(themes?.[themeType][currentSlide]?.title)
              }}
            />
            <Spacer size="24" direction="vertical" />
            <FullScreenResizeExpandIcon
              onClick={() => setOpenModalThemes(false)}
              style={{ cursor: 'pointer' }}
              width="32"
              color={palette.grayscale[400]}
            />
          </Paper>
          <div
            style={{
              padding: '8px',
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              display: 'flex',
            }}
          >
            <ArrowChevronBackIcon
              onClick={() => (currentSlide === 0 ? '' : prev())}
              width="24"
              color={
                currentSlide === 0
                  ? palette.grayscale[300]
                  : palette.primary[300]
              }
              style={{ cursor: currentSlide === 0 ? 'auto' : 'pointer' }}
            />
            <Spacer size="24" direction="vertical" />
            <ArrowChevronForwardIcon
              onClick={() =>
                currentSlide === themes?.[themeType]?.length - 1 ? '' : next()
              }
              width="24"
              color={
                currentSlide === themes?.[themeType]?.length - 1
                  ? palette.grayscale[300]
                  : palette.primary[300]
              }
              style={{
                cursor:
                  currentSlide === themes?.[themeType]?.length - 1
                    ? 'auto'
                    : 'pointer',
              }}
            />
          </div>
          <div style={{ width: '80%', margin: '64px 0px' }}>
            <Carousel
              showThumbs={false}
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
              showArrows={false}
              showStatus={false}
              showIndicators={false}
            >
              {themes?.[themeType]?.map((item, i) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <img
                    src={item.url}
                    style={{
                      height: 'auto',
                      width: 'auto',
                      maxHeight: '80vh',
                      maxWidth: '100%',
                    }}
                    alt="tema"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default NewContainer
