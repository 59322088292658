import API from 'api'
import {
  type INewFinancingSimulationPOSTPayload,
  type INewFinancingSimulationPOSTResponse,
} from './types'

export const financingCreateSimulation = {
  post: async (payload: INewFinancingSimulationPOSTPayload) => {
    return await API.post<INewFinancingSimulationPOSTResponse>(
      'financing/create-simulation',
      payload,
    )
      .then(({ data }) => data)
      .catch((error) => {
        throw new Error(error)
      })
  },
}
