import styled from 'styled-components'

interface IInputContainerProps {
  isLoading: boolean
}
export const InputContainer = styled.div<IInputContainerProps>`
  & .content {
    z-index: 2;
  }

  & .inputSearch {
    position: unset !important;
    z-index: 2000;
  }
`
