import { Header } from './components/Header'
import * as S from './styles'
import { Tabs } from './components/Tab'
import { type IEquipmentQuotationContainerProps } from './types'
import { Footer } from './components/Footer'
import { ModalConfirmProposalCancellation } from './components/ModalConfirmProposalCancellation'
import { ModalRemoveQuotation } from './components/ModalRemoveQuotation'
import { SaveQuotationBeforeQuit } from './components/SaveQuotationBeforeQuit'
import { ModalSelectClient } from './components/ModalSelectClient'
import { useEquipmentQuotationContainer } from './hooks/useEquipmentQuotationContainer'
import { QuotationDiscountModal } from './components/QuotationDiscountModal'

export function EquipmentQuotationContainer({
  dimensioningId,
}: IEquipmentQuotationContainerProps) {
  const {
    openModalToDeleteQuotation,
    openModalToCancelProposal,
    openModalToCreateProject,
    openModalQuotationDiscount,
  } = useEquipmentQuotationContainer({ dimensioningId })

  return (
    <S.Wrapper id="equipment-container">
      <S.Container>
        <Header />
        <Tabs />
      </S.Container>

      <Footer id="quotation-container-footer" />

      {openModalToDeleteQuotation && <ModalRemoveQuotation />}

      {openModalToCancelProposal && <ModalConfirmProposalCancellation />}

      {openModalToCreateProject && <ModalSelectClient />}

      {openModalQuotationDiscount && <QuotationDiscountModal />}

      <SaveQuotationBeforeQuit />
    </S.Wrapper>
  )
}
