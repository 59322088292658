export const applyInputMask = (value: string) => formatMoney(value)

export function formatInputValue(value: string) {
  const parsedValue = formatDecimals(value)
  return formatDecimals(applyInputMask(parsedValue))
}

function formatMoney(value: string) {
  const onlyDigits = value?.toString()?.replace(/\D+/g, '')
  const numberValue = parseInt(onlyDigits || '0', 10)

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(numberValue / 100)
}

const formatDecimals = (value: string) =>
  value
    .replace(/(?![,])\D/g, '')
    .replace(/\s/g, '')
    .replace(/,/g, '.')
