import React, { useState, useEffect } from 'react'
import { Box, Grid, Link, useMediaQuery } from '@material-ui/core'
import { Button, Paper, Dropdown, Input, Checkbox, useToast } from '@77sol/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  setUserProfile,
  setUserProfileCorporateName,
} from 'store/actions/profile'
import API from 'api'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { ufList } from 'utils/constants'
import { useBlacklist } from 'hooks/useBlacklist'
import { SkeletonLoader } from './components/SkeletonLoader'

function GeneralSettings() {
  const dispatch = useDispatch()
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { consultBlacklist, existInBlacklist, validBlacklist } = useBlacklist()

  const [loading, setLoading] = useState({
    saveChange: false,
    consultCep: false,
    consultCnpj: false,
  })

  const dataId = localStorage.getItem('sessionDataId')

  const { userProfile } = useSelector((state) => state.ReducerProfile)

  const [jsonSintegra, setJsonSintegra] = useState({})
  const [dataTelefone, setDataTelefone] = useState('')
  const [dataCelular, setDataCelular] = useState('')
  const [dataCPNJ, setDataCNPJ] = useState('')
  const [dataNomeFantasia, setDataNomeFantasia] = useState('')
  const [dataRazaoSocial, setDataRazaoSocial] = useState('')
  const [dataCEP, setDataCEP] = useState('')
  const [dataEndereco, setDataEndereco] = useState('')
  const [dataNumero, setDataNumero] = useState('')
  const [dataComplemento, setDataComplemento] = useState('')
  const [dataBairro, setDataBairro] = useState('')
  const [dataCidade, setDataCidade] = useState('')
  const [dataEstado, setDataEstado] = useState('')
  const [dataEmail, setDataEmail] = useState('')
  const [dataTerms, setDataTerms] = useState({
    checked: false,
    disabled: false,
  })

  const [hasFinishedLoading, setHasFinishedLoading] = useState(false)
  const [hasInputErrors, setHasInputErrors] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  const updateUser = (e) => {
    e.preventDefault()

    if (validBlacklist) {
      setLoading({
        saveChange: true,
      })

      API.patch(`/user/update/${dataId}`, {
        razaoSocial: dataRazaoSocial,
        nomeFantasia: dataNomeFantasia,
        ...(!isCNPJAlreadyFilled && {
          cnpj: dataCPNJ,
        }),
        telefone: dataTelefone,
        celular: dataCelular,
        email: dataEmail,
        cep: dataCEP,
        endereco: dataEndereco,
        complemento: dataComplemento,
        numero: dataNumero,
        bairro: dataBairro,
        cidade: dataCidade,
        uf: dataEstado,
        json_sintegra: jsonSintegra,
        ...(currentEnterpriseConfigs.termsUse && {
          accept_terms: dataTerms.checked ? 1 : 0,
        }),
      })
        .then(() => {
          API.get(`/user/view/${dataId}`).then((response) => {
            dispatch(setUserProfile(response.data[0]))
            dispatch(setUserProfileCorporateName(response.data[1]))

            onSuccessOpenToast('Dados alterados com sucesso!')
          })
        })
        .catch(() => onErrorOpenToast('Erro ao alterar dados!'))
        .finally(() => setLoading(false))
    }
  }

  const [isCNPJAlreadyFilled, setIsCNPJAlreadyFilled] = useState(true)

  useEffect(() => {
    if (!userProfile.cnpj) {
      setIsCNPJAlreadyFilled(false)
    } else {
      setIsCNPJAlreadyFilled(true)
    }

    setDataTelefone(userProfile.telefone || '')
    setDataCelular(userProfile.celular || '')
    setDataCNPJ(userProfile.cnpj || '')
    setDataRazaoSocial(userProfile.razaoSocial || '')
    setDataNomeFantasia(userProfile.nomeFantasia || '')
    setDataCEP(userProfile.cep || '')
    setDataEmail(userProfile.email || '')
    setDataEndereco(userProfile.endereco || '')
    setDataComplemento(userProfile.complemento || '')
    setDataNumero(userProfile.numero || '')
    setDataBairro(userProfile.bairro || '')
    setDataCidade(userProfile.cidade || '')
    setDataEstado(userProfile.uf || '')
    setJsonSintegra(
      userProfile.json_sintegra && JSON.parse(userProfile.json_sintegra),
    )
    setHasFinishedLoading(true)

    if (currentEnterpriseConfigs.termsUse) {
      setDataTerms({
        checked: userProfile.accept_terms,
        disabled: false,
      })
    }
    setIsFetching(!userProfile)
  }, [userProfile])

  const handleSearchCep = (cep) => {
    if (cep?.length === 8) {
      setLoading({
        consultCep: true,
      })

      API.get('/showcep', {
        params: { cep },
      })
        .then((res) => {
          setDataEndereco(res.data.logradouro || '')
          setDataNumero('')
          setDataComplemento('')
          setDataBairro(res.data.bairro || '')
          setDataCidade(res.data.cidade || '')
          setDataEstado(res.data.uf || '')
        })
        .finally(() => setLoading({}))
    }
  }

  const handleSearchCnpj = async (document) => {
    const fieldBlocked = await consultBlacklist('cnpj', document)
    if (fieldBlocked) return
    if (document.length === 14) {
      setLoading({
        consultCnpj: true,
      })

      API.post('/document', { document })
        .then(({ data }) => {
          setDataRazaoSocial(data.nome_empresarial || '')
          setDataNomeFantasia(
            data.nome_fantasia === 'Não informado' ? '' : data.nome_fantasia,
          )
          setDataCEP(data.cep || '')
          setDataEndereco(data.logradouro || '')
          setDataComplemento(data.complemento || '')
          setDataNumero(data.numero || '')
          setDataBairro(data.bairro || '')
          setDataCidade(data.municipio || '')
          setDataEstado(data.uf || '')
          setJsonSintegra(data)
        })
        .finally(() => setLoading({}))
        .catch(() => setJsonSintegra(null))
    }
  }

  useEffect(() => {
    if (
      !validBlacklist ||
      !dataRazaoSocial ||
      !dataCPNJ ||
      !dataBairro ||
      !dataCelular ||
      !dataCEP ||
      !dataEstado ||
      !dataEstado ||
      !dataEndereco ||
      !dataTelefone ||
      !dataNomeFantasia ||
      !dataNumero ||
      (currentEnterpriseConfigs.termsUse && !dataTerms.checked) ||
      dataCEP?.replace(/[^+\d]+/g, '')?.length < 8 ||
      dataCelular?.replace(/[^+\d]+/g, '')?.length < 11 ||
      dataTelefone?.replace(/[^+\d]+/g, '')?.length < 10 ||
      dataCPNJ?.replace(/[^+\d]+/g, '')?.length < 14
    ) {
      setHasInputErrors(true)
    } else {
      setHasInputErrors(false)
    }
  }, [
    validBlacklist,
    dataRazaoSocial,
    dataCPNJ,
    dataBairro,
    dataCelular,
    dataCEP,
    dataCidade,
    dataEstado,
    dataEndereco,
    dataTelefone,
    dataNomeFantasia,
    dataNumero,
    dataTerms,
  ])

  return (
    <>
      <Paper
        style={{
          height: 'auto',
        }}
      >
        {isFetching && <SkeletonLoader />}
        {!isFetching && (
          <form onSubmit={updateUser}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Input
                  disabled
                  fullWidth
                  name="email"
                  title="E-mail"
                  value={dataEmail}
                  onChange={() => false && setDataEmail}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Input
                  autoTab
                  mask="cnpj"
                  title="CNPJ"
                  name="cnpj"
                  onChange={(e) => setDataCNPJ(e)}
                  onBlur={handleSearchCnpj}
                  value={dataCPNJ}
                  disabled={isCNPJAlreadyFilled}
                  isLoading={loading.consultCnpj}
                  InputLabelProps={{
                    shrink: !!dataCPNJ,
                  }}
                  validationState={
                    (existInBlacklist('cnpj') ||
                      (hasFinishedLoading && dataCPNJ === '') ||
                      (hasFinishedLoading && dataCPNJ.length < 14)) &&
                    'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Input
                  autoTab
                  mask="phoneNumber"
                  onChange={(e) => setDataTelefone(e)}
                  value={dataTelefone}
                  InputLabelProps={{
                    shrink: !!dataTelefone,
                  }}
                  fullWidth
                  title="Telefone"
                  name="telefone"
                  validationState={
                    ((hasFinishedLoading && dataTelefone === '') ||
                      (hasFinishedLoading &&
                        dataTelefone?.replace(/[^+\d]+/g, '').length < 10)) &&
                    'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Input
                  autoTab
                  mask="phoneNumber"
                  onChange={(e) => setDataCelular(e)}
                  value={dataCelular}
                  fullWidth
                  title="Celular"
                  name="celular"
                  InputLabelProps={{
                    shrink: !!dataCelular,
                  }}
                  onBlur={(value) => consultBlacklist('mobile', value)}
                  validationState={
                    (existInBlacklist('mobile') ||
                      (hasFinishedLoading && dataCelular === '') ||
                      (hasFinishedLoading &&
                        dataCelular?.replace(/[^+\d]+/g, '')?.length < 11)) &&
                    'invalid'
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                <Input
                  fullWidth
                  title="Razão Social"
                  name="razaoSocial"
                  onChange={(e) => setDataRazaoSocial(e)}
                  value={dataRazaoSocial}
                  InputLabelProps={{
                    shrink: !!dataRazaoSocial,
                  }}
                  validationState={
                    hasFinishedLoading &&
                    (dataRazaoSocial === '' || dataRazaoSocial?.length < 5) &&
                    'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Input
                  fullWidth
                  title="Nome Fantasia"
                  name="nomeFantasia"
                  InputLabelProps={{
                    shrink: !!dataNomeFantasia,
                  }}
                  onChange={(e) => setDataNomeFantasia(e)}
                  value={dataNomeFantasia}
                  validationState={
                    hasFinishedLoading &&
                    (dataNomeFantasia === '' || dataNomeFantasia?.length < 5) &&
                    'invalid'
                  }
                />
              </Grid>
              {upXl ? (
                <Grid item xs={12} sm={12} md={7} lg={7} xl={3}>
                  <Input
                    fullWidth
                    title="Endereço"
                    name="endereco"
                    InputLabelProps={{
                      shrink: !!dataEndereco,
                    }}
                    onChange={(e) => setDataEndereco(e)}
                    value={dataEndereco}
                    validationState={
                      hasFinishedLoading &&
                      (dataEndereco === '' || dataEndereco?.length < 5) &&
                      'invalid'
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Input
                    autoTab
                    mask="cep"
                    onChange={(e) => setDataCEP(e)}
                    value={dataCEP}
                    onBlur={handleSearchCep}
                    fullWidth
                    title="CEP"
                    name="cep"
                    isLoading={loading.consultCep}
                    InputLabelProps={{
                      shrink: !!dataCEP,
                    }}
                    validationState={
                      ((hasFinishedLoading && dataCEP === '') ||
                        (hasFinishedLoading &&
                          dataCEP?.replace(/[^+\d]+/g, '')?.length < 8)) &&
                      'invalid'
                    }
                  />
                </Grid>
              )}
              {!upXl ? (
                <Grid item xs={12} sm={12} md={7} lg={7} xl={3}>
                  <Input
                    fullWidth
                    title="Endereço"
                    name="endereco"
                    InputLabelProps={{
                      shrink: !!dataEndereco,
                    }}
                    onChange={(e) => setDataEndereco(e)}
                    value={dataEndereco}
                    validationState={
                      hasFinishedLoading &&
                      (dataEndereco === '' || dataEndereco?.length < 3) &&
                      'invalid'
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Input
                    autoTab
                    mask="cep"
                    onChange={(e) => setDataCEP(e)}
                    value={dataCEP}
                    onBlur={handleSearchCep}
                    fullWidth
                    title="CEP"
                    name="cep"
                    InputLabelProps={{
                      shrink: !!dataCEP,
                    }}
                    validationState={
                      ((hasFinishedLoading && dataCEP === '') ||
                        (hasFinishedLoading &&
                          dataCEP?.replace(/[^+\d]+/g, '')?.length < 8)) &&
                      'invalid'
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={2} lg={2} xl={3}>
                <Input
                  onChange={(e) => setDataNumero(e)}
                  value={dataNumero}
                  maskChar=""
                  fullWidth
                  title="Número"
                  name="numero"
                  InputLabelProps={{
                    shrink: !!dataNumero,
                  }}
                  validationState={
                    hasFinishedLoading && dataNumero === '' && 'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Input
                  fullWidth
                  title="Complemento"
                  name="complemento"
                  InputLabelProps={{
                    shrink: !!dataComplemento,
                  }}
                  onChange={(e) => setDataComplemento(e)}
                  value={dataComplemento}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Input
                  fullWidth
                  title="Bairro"
                  name="bairro"
                  InputLabelProps={{
                    shrink: !!dataBairro,
                  }}
                  onChange={(e) => setDataBairro(e)}
                  value={dataBairro}
                  validationState={
                    hasFinishedLoading && dataBairro === '' && 'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
                <Input
                  fullWidth
                  title="Cidade"
                  name="cidade"
                  InputLabelProps={{
                    shrink: !!dataCidade,
                  }}
                  onChange={(e) => setDataCidade(e)}
                  value={dataCidade}
                  validationState={
                    hasFinishedLoading && dataCidade === '' && 'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={4}>
                <Dropdown
                  fullWidth
                  title="UF"
                  value={dataEstado}
                  options={ufList}
                  onChange={(e) => {
                    setDataEstado(e)
                  }}
                  validationState={
                    hasFinishedLoading && dataEstado === '' && 'invalid'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box
                  margin={2}
                  display="flex"
                  alignItems="center"
                  justifyContent={
                    currentEnterpriseConfigs.termsUse
                      ? 'space-between'
                      : 'flex-end'
                  }
                  {...(downSm && {
                    flexDirection: 'column',
                  })}
                >
                  {currentEnterpriseConfigs.termsUse && (
                    <Box
                      display="flex"
                      alignSelf="start"
                      alignItems="center"
                      gridGap={8}
                      {...(downSm && {
                        marginBottom: 4,
                      })}
                    >
                      <Checkbox
                        size="small"
                        checked={dataTerms.checked}
                        disabled={dataTerms.disabled}
                        onChange={() =>
                          setDataTerms((state) => ({
                            ...state,
                            checked: !dataTerms.checked,
                          }))
                        }
                      />
                      <Box display="flex" width="100%">
                        <span>
                          Li e aceito os&nbsp;
                          <Link
                            color="primary"
                            target="_blank"
                            href={currentEnterpriseConfigs.terms_of_use}
                            display="inline-block"
                          >
                            termos de uso
                          </Link>
                        </span>
                      </Box>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    noMargin
                    size="small"
                    isLoading={loading.saveChange}
                    fullWidth={downSm}
                    disabled={hasInputErrors}
                  >
                    Salvar alterações
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </>
  )
}

export default GeneralSettings
