import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: calc(100vh - 78px);

  @media (min-width: 1280px) {
    height: calc(100vh - 88px);
  }
`

export const Content = styled.div`
  height: 100%;

  #equipments-title {
    min-height: 46px;
  }

  .infinite-scroll-component__outerdiv {
    flex: auto;
    min-height: 0;
  }
`
