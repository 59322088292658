import { TextField } from 'components/Controllers/TextField'
import * as S from './styles'
import { useFormContext } from 'react-hook-form'

export const SystemPower = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <S.Container>
      <S.Label>Insira a potência do sistema</S.Label>
      <TextField
        autoFocus
        control={control}
        label="Potência*"
        name="system_potency"
        type="number"
        endAdornment="kWp"
        error={errors.system_potency?.message as string}
      />
    </S.Container>
  )
}
