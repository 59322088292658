import React, { useEffect, useState } from 'react'
import { FinancialLabel, TableList } from 'components'
import { Box, Hidden } from '@material-ui/core'
import { Badge, Modal, Button, Title, Spacer, Typography } from '@77sol/core'
import {
  DeleteIcon,
  FilledSaveFavoriteBookmark,
  SaveFavoriteBookmarkIcon,
} from '@77sol/icons/dist'
import styles from './styles'
import { formatDate, proposalFilterList } from 'utils'
import palette from 'app_palette'

const valuesToShowInTable = (values = {}, type) => {
  const proposalStatus =
    proposalFilterList.find(({ value }) => value === values?.status) ||
    proposalFilterList[0]
  return [
    {
      ...(type === 'proposal' && {
        favorite: values.is_favorite ? (
          <FilledSaveFavoriteBookmark
            width="24px"
            color={palette.yellow[400]}
          />
        ) : (
          <SaveFavoriteBookmarkIcon
            width="24px"
            color={palette.grayscale[300]}
          />
        ),
      }),
      ...(type === 'own_product' && {
        imagem: values.imagem,
        name: values.name,
        estoque: values.estoque,
        categoria: values.categoria,
        valor: values.valor,
        criacao: values.criacao,
      }),
      ...(type !== 'own_product' && {
        id: values.id,
      }),
      ...(type === 'proposal' && {
        dimensionings: values.dimensionings,
        quotations: values.quotations,
        status: (
          <Badge label={proposalStatus.label} color={proposalStatus.color} />
        ),
        created_at: formatDate(values.created_at),
        financing_status: <FinancialLabel status={values.financing_status} />,
      }),
      ...(type === 'project' && {
        customer: values.customer,
        proposals: values.proposals,
        quotations: values.quotations,
        updated_at: values.updated_at,
      }),
      ...(type === 'financing' && {
        proposal: values.proposalId,
        potency: values.potency,
        customer: values.client,
        quotations: values.quotes,
        requestDate: values.request_date,
        financing_status: values.financing_status,
      }),
      ...(type === 'proposta' && {
        nome_cliente: values.nome_cliente,
        kwp: values.kwp,
        valor: values.valorTotalInvestimentos,
        updated_at: values.updated_at,
      }),
      ...(type === 'quotation') && {
        kwp: values?.potency,
        customer: values?.customer,
        simulations: values?.rowData?.simulations,
        updated_at: new Date(values?.rowData?.updated_at).toLocaleString(),
        financing_status: <FinancialLabel status={values?.rowData?.financing_status} />
      }
    },
  ]
}

const DeleteModal = ({
  columns,
  isOpen,
  onClose,
  onConfirmDelete,
  optionToDelete,
  responsiveCard,
  type,
  loading,
}) => {
  const [title, setTitle] = useState('')
  const classes = styles()

  useEffect(() => {
    if (type === 'own_product') {
      setTitle('este produto')
    } else if (type === 'project') {
      setTitle('este projeto')
    } else if (type === 'proposal') {
      setTitle('esta proposta')
    }else{
      setTitle('esta cotação')
    }
  }, [type])

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box width="70vw">
        <Hidden smDown>
          <Title size="xsmall" color="primary" colorWeight="300">
            <DeleteIcon width="32px" />
            <Spacer size="16" direction="both" />
            {`Deseja deletar ${title}?`}
          </Title>
        </Hidden>
        <Hidden mdUp>
          <Typography
            type="link"
            size="small"
            color="primary"
            colorWeight="300"
          >
            <DeleteIcon width="24px" />
            <Spacer size="16" direction="both" />
            {`Deseja deletar ${title}?`}
          </Typography>
        </Hidden>
      </Box>
      <Hidden smDown>
        <Box paddingY={3} className={classes.table}>
          <TableList
            columns={columns}
            values={valuesToShowInTable(optionToDelete, type)}
            noSelectRow
            LastItem={null}
          />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box paddingY={1}>{responsiveCard}</Box>
      </Hidden>
      <Box className={classes.buttonsBox}>
        <Button
          size="small"
          noMarginY
          color="red"
          isLoading={loading}
          onClick={() => onConfirmDelete(optionToDelete)}
        >
          Deletar
        </Button>
        <Spacer size="8" direction="both" />
        <Button size="small" noMarginY variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  )
}

export default DeleteModal
