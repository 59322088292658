import API from 'api'
import { GET_ORDER_HISTORY } from '../constants/endpointKeys'
import { type IOrderHistoryGETResponse } from './types'

export const orderHistoric = {
  get: async (orderId: number) => {
    if (!orderId) return
    return await API.get<IOrderHistoryGETResponse>(
      `${GET_ORDER_HISTORY}/${orderId}`,
    )
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      })
  },
}
