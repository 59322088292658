/**
 * Count quantity financing
 * @param {*} financing
 * @returns
 */
export const countFinancing = (financing = []) => {
  return financing.reduce((acc, { status }) => (status ? acc + 1 : acc), 0)
}

/**
 * Search for financing based on values
 * @param {*} proposal
 * @param {*} values
 * @returns
 */
export const searchFinancing = (proposal = [], values = {}) => {
  return (
    proposal.find(
      ({ name_financier }) => name_financier === values.name_financier,
    ) || values
  )
}
