import { create } from 'zustand'

interface IState {
  isSolLeadsModalOpen: boolean
}

interface IActions {
  setIsSolLeadsModalOpen: (value: boolean) => void
}

const INITIAL_STATE = {
  isSolLeadsModalOpen: false,
}

export const useSolLeadsModalStore = create<IState & IActions>((set) => ({
  ...INITIAL_STATE,
  setIsSolLeadsModalOpen: (isOpen) => {
    set({ isSolLeadsModalOpen: isOpen })
  },
}))
