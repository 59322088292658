import { palette } from '@77sol/styles'
import styled from 'styled-components'

type DirectionProps = 'horizontal' | 'vertical'

interface IconProps {
  isFilled: boolean
  direction: DirectionProps
}
interface TimeLineContainerProps {
  direction: DirectionProps
  justifyContent: React.CSSProperties['justifyContent']
}

export const ArrowIconContainer = styled.div<IconProps>`
  & > svg {
    margin-top: 8px;
    width: 24px;
    color: ${({ isFilled }) =>
      isFilled ? palette.primary[300] : palette.grayscale[300]};
    display: block;
    margin-inline: auto;
    transform: ${({ direction }) =>
      direction === 'vertical' && 'rotate(90deg)'};
    margin-bottom: ${({ direction }) => direction === 'vertical' && '12px'};
  }
`

export const TimePointIconContainer = styled.div<IconProps>`
  background-color: ${({ isFilled }) =>
    isFilled ? palette.primary[300] : palette.grayscale[300]};
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    content: '';
    position: absolute;
    top: -28px;
    left: 20px;
    width: 2px;
    height: calc(100% - 10px);
    background-color: ${({ isFilled }) =>
      isFilled ? palette.primary[300] : palette.grayscale[300]};
    display: ${({ direction }) => direction === 'horizontal' && 'none'};
  }

  & > svg {
    color: ${({ isFilled }) =>
      isFilled ? palette.white : palette.grayscale[500]};
  }
`

export const TimeLineContainer = styled.div<TimeLineContainerProps>`
  display: flex;
  background-color: #ffff;
  overflow-x: auto;
  padding: 20px;
  padding: 24px 16px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 6px;
  border-radius: 8px;
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};

  & div:nth-child(1) > div::before {
    display: none;
  }

  & > div {
    padding-top: 8px;
    margin-bottom: 20px;
  }
`
export const TimePoint = styled.div<Pick<TimeLineContainerProps, 'direction'>>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'column' : 'row'};
  align-items: center;
  text-align: ${({ direction }) =>
    direction === 'horizontal' ? 'center' : 'left'};
  gap: 12px;
  min-width: 100px;

  & > span {
    font-size: 13px;
    font-weight: 500;
    max-width: ${({ direction }) => direction === 'vertical' && '80%'};
  }
`
