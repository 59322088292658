import { MailIcon, SendIcon, WhatsappIcon } from '@77sol/icons/dist'

type IShareIcons = (props: unknown) => React.ReactSVGElement

export const PROPOSAL_SHARE_ICONS: Record<string, IShareIcons> = {
  default: SendIcon,
  email: MailIcon,
  whatsapp: WhatsappIcon,
}

export const PROPOSAL_SHARE_TITLES: Record<string, string> = {
  default: 'Como deseja enviar esta proposta?',
  email: 'Confirmar envio da proposta por e-mail',
  whatsapp: 'Confirmar envio da proposta por WhatsApp',
}
