import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  blueBoxCreateProposal: {
    '@media (min-width:780px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  blueBoxCreateProposalPhrase: {
    '@media (min-width:780px)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}))
