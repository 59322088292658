export const CPF_DOCUMENTS = [
  {
    title: 'Documento de identidade',
    description:
      'Documento emitido pelos órgãos competentes que sejam válidos como identificação em território nacional. Ex: RG, CREA, CRM e OAB. O documento deve estar dentro do prazo de validade ou, com até 10 anos da data de emissão no caso do RG. A imagem do documento deve ser legível. Caso o cliente necessite de uma procuração, esta também deve ser anexada junto à identificação do cliente.',
    type: 'identity_document',
    hasBeenSent: false,
    documentType: 'cpf',
  },
  {
    title: 'Comprovante de residência',
    description:
      'O documento deve estar no nome do financiado e precisa ser do último mês. ',
    type: 'proof_address',
    hasBeenSent: false,
    documentType: 'cpf',
  },
  {
    title: 'Comprovante de renda',
    description:
      'Holerite/ Decore/ Pro-labore/ Imposto de Renda (IR)/ Extrato bancário 3 meses',
    type: 'proof_income',
    hasBeenSent: false,
    documentType: 'cpf',
  },
]
