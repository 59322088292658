import { Button, Paper, Spacer, Typography } from '@77sol/core'
import { CartIcon, CloseXIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useStyles } from './styles'

export function BlueBoxProposalAnchor({ setDisplayBlueBoxCreateProposal }) {
  const history = useHistory()
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <Spacer size={downSm ? '24' : '32'} direction="horizontal" />
      <Paper
        style={{
          backgroundColor: palette.primary[300],
          color: palette.white,
          padding: '16px 32px',
          height: 'auto',
          position: 'relative',
          marginTop: '12px',
        }}
      >
        <div className={classes.blueBoxCreateProposal}>
          <div className={classes.blueBoxCreateProposalPhrase}>
            <CartIcon width="32" />
            <Spacer
              size={downSm ? '12' : '24'}
              direction={downSm ? 'horizontal' : 'vertical'}
            />
            <Typography
              size={downSm ? 'xsmall' : 'small'}
              style={{ color: '#FFF' }}
            >
              Reserve o kit completo e personalizado de acordo com sua proposta
            </Typography>
            {downSm && <Spacer size="24" direction="horizontal" />}
          </div>
          <Button
            noMargin
            size="small"
            variant="outlined"
            color="white"
            onClick={() => history.push('/dimensionar')}
          >
            Criar proposta
          </Button>
        </div>
        {downSm && (
          <CloseXIcon
            onClick={() => {
              setDisplayBlueBoxCreateProposal(false)
            }}
            color={palette.white}
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
            }}
            width={20}
          />
        )}
      </Paper>
    </>
  )
}
