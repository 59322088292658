import { useState } from 'react'
import { Typography, Spacer, Paper, Accordion } from '@77sol/core'
import DocumentCard from './components/DocumentCard'
import * as S from './styles'
import { useDocumentUpload } from './hooks/useDocumentUpload'
import { ModalUploadFile } from './components/ModalUploadFile'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'

function Documents({ selectedFinancing }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [documentSelected, setDocumentSelected] = useState({})
  const [modalTitle, setModalTitle] = useState('')

  const { logEvent } = useAmplitude()
  const { handleUploadDocument, documentList, otherDocumentList } =
    useDocumentUpload()

  const documentTypeList = documentList.filter(
    (doc) => doc.documentType === selectedFinancing?.document_type,
  )

  const handleSelectDocument = (document) => {
    logEvent(financingTracker.actions.sendDocument, {
      origin: '/financiamentos',
      document: document.title,
    })

    setDocumentSelected(document)
    buildModalTitle(document)
    setIsModalOpen(true)
  }

  const checkDocumentHasBeenSent = (document) => {
    const fileAlreadyAttached = selectedFinancing?.files?.find(
      (file) => file.type === document.type,
    )
    return fileAlreadyAttached || document.hasBeenSent
  }

  const handleSendDocument = (fileToSend) => {
    handleUploadDocument(fileToSend, documentSelected, selectedFinancing.id)
    const updatedFiles = selectedFinancing.files.map((item) =>
      item.title === documentSelected.title
        ? { ...item, hasBeenSent: true }
        : item,
    )
    selectedFinancing.files = updatedFiles
  }

  const buildModalTitle = (document) => {
    let title = ''
    const fileAlreadyAttached = checkDocumentHasBeenSent(document)
    if (fileAlreadyAttached) {
      title = `Reenviar e substituir ${document.title}`
    } else {
      title = `Enviar ${document.title}`
    }
    setModalTitle(title)
  }

  return (
    <>
      <S.DocumentsContainer>
        {documentTypeList.map((document) => (
          <>
            <Paper boxShadow="small" key={document.title}>
              <DocumentCard
                title={document.title}
                type={document.type}
                description={document.description}
                selectedFinancing={selectedFinancing}
                onSendDocument={() => handleSelectDocument(document)}
                hasBeenSent={checkDocumentHasBeenSent(document)}
              />
            </Paper>
            <Spacer size="16" direction="both" />
          </>
        ))}
        <Accordion title="Documentos complementares">
          <S.UploadDocumentContainer>
            <Typography size="small" colorWeight="400">
              Para envio de documentos solicitados como: Contrato, Nota fiscal
              de produto ou Ordem de compra.
            </Typography>
            {otherDocumentList.map((document) => (
              <>
                <Spacer size="16" direction="both" />
                <DocumentCard
                  key={document.title}
                  title={document.title}
                  type={document.type}
                  description={document.description}
                  selectedFinancing={selectedFinancing}
                  onSendDocument={() => handleSelectDocument(document)}
                  hasBeenSent={checkDocumentHasBeenSent(document)}
                />
              </>
            ))}
          </S.UploadDocumentContainer>
        </Accordion>
      </S.DocumentsContainer>

      {isModalOpen && (
        <ModalUploadFile
          isModalOpen={isModalOpen}
          title={modalTitle}
          setIsModalOpen={setIsModalOpen}
          documentSelected={documentSelected}
          handleSendDocument={handleSendDocument}
          selectedFinancing={selectedFinancing}
        />
      )}
    </>
  )
}

export default Documents
