import { Badge, type IBadgeProps } from '@77sol-ui/atoms'
import { Select } from '@77sol-ui/molecules'
import { Filter } from 'lucide-react'
import { type IFinancingStatus } from '../../types'
import { TooltipLockedToFaas } from '_Distributor/components/TooltipLockedToFaas'
import { SelectStatusWrapper } from './styles'

type IOptions = IFinancingStatus

interface IStatusSelectProps {
  onChangeFilter: (status: string) => void
  selectedStatus: IFinancingStatus
  options: IOptions[]
  isLocked?: boolean
}

export function StatusSelect({
  onChangeFilter,
  selectedStatus,
  options,
  isLocked,
}: IStatusSelectProps) {
  if (isLocked)
    return (
      <TooltipLockedToFaas align="start">
        <SelectStatusWrapper>
          <Select.Root>
            <Select.Trigger
              id="select-status"
              data-testid="select-status-trigger"
            >
              <Select.Icon>
                <Filter size={18} />
              </Select.Icon>
              <Select.Value
                data-testid="select-status-value"
                asChild
                placeholder="Filtrar por status"
              >
                <Badge color="gray" variant="ghost" text="Todos os status" />
              </Select.Value>
              <Select.Icon />
            </Select.Trigger>
          </Select.Root>
        </SelectStatusWrapper>
      </TooltipLockedToFaas>
    )

  return (
    <Select.Root onValueChange={onChangeFilter}>
      <Select.Trigger id="select-status" data-testid="select-status-trigger">
        <Select.Icon>
          <Filter size={18} />
        </Select.Icon>
        <Select.Value
          data-testid="select-status-value"
          asChild
          placeholder="Filtrar por status"
        >
          <Badge
            color={selectedStatus?.color as IBadgeProps['color']}
            variant={selectedStatus?.value ? 'filled' : 'ghost'}
            text={selectedStatus?.label}
          />
        </Select.Value>
        <Select.Icon />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.Viewport>
            <Select.Item
              value="all"
              customComponent={
                <Badge color="gray" text="Todos os status" variant="ghost" />
              }
            />
            {options.map((item) => (
              <Select.Item
                key={item?.value}
                data-testid={`select-status-option-${String(item?.value)}`}
                value={String(item?.value)}
                customComponent={
                  <Badge
                    color={item.color as IBadgeProps['color']}
                    text={item.label}
                    variant="filled"
                  />
                }
              />
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
