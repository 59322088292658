import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type IActions, type IState } from './types'

const DEFAULT_VALUES = {
  isOpen: false,
  quotationId: null,
  orderId: null,
  financingId: null,
}

const useCheckoutModalStore = create<IState & IActions>()(
  devtools(
    (set) => ({
      ...DEFAULT_VALUES,
      setIsOpen: (isOpen) => {
        set({ isOpen })
      },
      setQuotationId: (quotationId) => {
        set({ quotationId })
      },
      setOrderId: (orderId) => {
        set({ orderId })
      },
      setFinancingId: (financingId) => {
        set({ financingId })
      },
      reset: () => {
        set(DEFAULT_VALUES)
      },
    }),
    { name: 'CheckoutModalStore' },
  ),
)

export { useCheckoutModalStore }
