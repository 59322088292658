import API from 'api'
import { GET_NOTIFICATION_READALL } from '../constants/endpointKeys'
import { type INotificationsReadallGETResponse } from './types'

export const readAllNotifications = {
  get: async () =>
    await API.get<INotificationsReadallGETResponse>(GET_NOTIFICATION_READALL)
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err)
      }),
}
