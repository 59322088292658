import { Header } from 'components'
import OrderFiltersForm from '../OrderFiltersForm'
import * as S from './styles'
import useWindowSize from 'hooks/useWindowSize'
import { useEffect } from 'react'

interface OrderFilterSlideProps {
  isOpenOrderFiltersSlide: boolean
  setIsOpenOrderFiltersSlide: (state: boolean) => void
}

const OrderFiltersSlide: React.FC<OrderFilterSlideProps> = ({
  setIsOpenOrderFiltersSlide,
}) => {
  const { width } = useWindowSize()
  const isMobile = width < 1280

  useEffect(() => {
    if (!isMobile) setIsOpenOrderFiltersSlide(false)
  }, [isMobile])

  return (
    <S.SlideContent>
      <Header
        onBack={() => {
          setIsOpenOrderFiltersSlide(false)
        }}
        size="huge"
        title="Filtros"
      />
      <S.SlideContentContainer>
        <OrderFiltersForm
          onFilter={() => {
            setIsOpenOrderFiltersSlide(false)
          }}
        />
      </S.SlideContentContainer>
    </S.SlideContent>
  )
}

export default OrderFiltersSlide
