import {
  validateItemBeingUpdated,
  validateAllQuotes,
  removeQuoteValidation,
} from 'store/validators/proposal'
import { CATEGORIES } from 'store/validators/proposal/constants'

const { defineState } = require('redux-localstore')

const INICIAL_STATE = {
  proposal: {},
  sizings: [],
  quotes: [],
  activeStep: 1,
  activeSizing: '',
  newSizing: false,
  clients: [],
  stocks: {},
  products: [],
  categories: [],
  brands: [],
  extraConfigs: {},
  quoteFilter: {},
  userName: '',
  showForPayment: false,
  isProPlanModalOpen: false,
  isFromProposalList: false,
  nivelLogin: '',
  isAdmin: false,
  leadType: '',
  outdatedQuote: {},
  quotesErrors: {
    hasError: false,
    errors: {},
  },
}

const initialState = defineState(INICIAL_STATE)('Reducer1')

export function Reducer1(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROPOSAL':
      return { ...state, proposal: action.proposal }
    case 'SET_CLIENTS':
      return { ...state, clients: action.clients }
    case 'SET_SIZING':
      return { ...state, sizings: state.sizings.concat(action.sizing) }
    case 'UPDATE_SIZING': {
      const dataSizing = [...state.sizings]
      const indexSizing = state.sizings.findIndex(
        (sizing) => sizing.id == action.sizing.id,
      )
      dataSizing[indexSizing] = action.sizing
      return { ...state, sizings: dataSizing }
    }

    case 'SET_STEP':
      return { ...state, activeStep: action.step }
    case 'SET_QUOTE': {
      const quotes = state.quotes.concat(action.quote)
      const quotesErrors = validateAllQuotes(quotes)
      return { ...state, quotesErrors, quotes }
    }
    case 'REMOVE_SIZING': {
      const sizingRemove = [...state.sizings]
      const sizingIndexRemove = state.sizings.findIndex(
        (sizing) => sizing.id == action.sizingId,
      )
      sizingRemove.splice(sizingIndexRemove, 1)
      return { ...state, sizings: [...sizingRemove] }
    }
    case 'REMOVE_QUOTE': {
      const dataRemove = [...state.quotes]
      const indexRemove = state.quotes.findIndex(
        (quote) => quote.quotation.id == action.quoteId,
      )

      dataRemove.splice(indexRemove, 1)
      const quotesErrors = removeQuoteValidation(
        state?.quotesErrors,
        action?.quoteId,
      )

      return { ...state, quotesErrors, quotes: [...dataRemove] }
    }

    case 'UPDATE_QUOTE': {
      const data = [...state.quotes]
      const index = state.quotes.findIndex(
        (quote) => quote.quotation.id == action.quote.quotation.id,
      )
      if (data[index]?.firstKit?.providers && action.keepWarning) {
        const firstKit = data[index]?.firstKit
        data[index] = action.quote
        data[index].firstKit = firstKit
      } else {
        data[index] = action.quote
      }

      let quotesErrors = state?.quotesErrors

      const updatingModuleOrInversor = action?.quote?.kit?.find(
        (item) =>
          item?.id === action?.productId &&
          CATEGORIES.includes(item?.categoria),
      )

      if (updatingModuleOrInversor) {
        quotesErrors = validateItemBeingUpdated(
          state?.quotesErrors,
          action?.quote,
          action?.productId,
        )
      }

      return { ...state, quotesErrors, quotes: data }
    }
    case 'UPDATE_DATA': {
      const dataUpdate = [...state.quotes]
      const indexUpdate = state.quotes.findIndex(
        (quote) => quote.quotation.id == action.quoteData.id,
      )
      dataUpdate[indexUpdate].quotation = action.quoteData
      return { ...state, quotes: dataUpdate }
    }
    case 'SET_STOCKS':
      return { ...state, stocks: action.stocks }
    case 'SET_PRODUCTS':
      return { ...state, products: action.products }
    case 'SET_CATEGORIES':
      return { ...state, categories: action.categories }
    case 'SET_BRANDS':
      return { ...state, brands: action.brands }
    case 'SET_NEW_SIZING':
      return { ...state, newSizing: action.newSizing }
    case 'SET_NIVEL_LOGIN':
      return { ...state, nivelLogin: action.nivel }
    case 'SET_USER_NAME':
      return { ...state, userName: action.userName }
    case 'SET_ACTIVE_SIZING':
      return { ...state, activeSizing: action.sizing }
    case 'SET_QUOTE_FILTER':
      return { ...state, quoteFilter: action.filters }
    case 'CHANGE_PRODUCT_QTD': {
      const quoteIdProduct = action.value[0]
      const productId = action.value[1]
      const qtd = action.value[2]
      const quotesProd = [...state.quotes]

      const { kit } = state.quotes.find(
        (quote) => quote.quotation.id == quoteIdProduct,
      )
      const indexProd = state.quotes
        .find((quote) => quote.quotation.id == quoteIdProduct)
        .kit.findIndex((item) => item.id == productId)
      kit[indexProd].qtde = qtd
      // quotesProd[indexQuoteProd] = state.quotes.find(quote => quote.quotation.id == quoteIdProduct).kit = kit;
      return { ...state, quotes: quotesProd }
    }
    case 'SET_EXTRA_CONFIGS':
      return { ...state, extraConfigs: action.extraConfigs }
    case 'SET_SHOW_FOR_PAYMENT':
      return { ...state, showForPayment: action.showForPayment }
    case 'SET_IS_ADMIN':
      return { ...state, isAdmin: action.isAdmin }
    case 'SET_IS_FROM_PROPOSAL_LIST':
      return { ...state, isFromProposalList: action.isFromProposalList }
    case 'SET_IS_PRO_PLAN_MODAL_OPEN':
      return { ...state, isProPlanModalOpen: action.isProPlanModalOpen }
    case 'LEAD_TYPE':
      return { ...state, leadType: action.leadType }
    case 'OUTDATED_QUOTE':
      const newState = { ...state, outdatedQuote: action.outdatedQuote }
      return newState
    case 'RESET':
      return { ...INICIAL_STATE }
    default:
      return state
  }
}
