import * as S from './styles'
import { DownloadQuotationButton } from './components/DownloadQuotationButton'
import { EditQuotationButton } from './components/EditQuotationButton'

interface CompareKitHeaderActionsProps {
  quotationId: number
}

export function CompareKitHeaderActions({
  quotationId,
}: CompareKitHeaderActionsProps) {
  return (
    <S.Wrapper>
      <DownloadQuotationButton quotationId={quotationId} />
      <EditQuotationButton quotationId={quotationId} />
    </S.Wrapper>
  )
}
