import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Backdrop = styled.div`
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  z-index: 200;
`

export const LoadingContainer = styled.div`
  height: 100px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  gap: 20px;
  margin: auto;
  z-index: 201;

  & > span {
    color: ${palette.primary[100]};
  }
`

export const PDFContainer = styled.div`
  z-index: -100;
`
