import styled, { css } from 'styled-components'
import firstMobile from './assets/mobile/first.svg'
import secondMobile from './assets/mobile/second.svg'
import thirdMobile from './assets/mobile/third.svg'
import otherPositionsMobile from './assets/mobile/other-positions-mobile.svg'
import first from './assets/first.svg'
import second from './assets/second.svg'
import third from './assets/third.svg'

import MedalImg from './assets/medal.svg'

interface ContainerProps {
  background: string
  height: string
  color: {
    text?: string
    context?: string
  }
}

export const Container = styled.div<ContainerProps>`
  cursor: pointer;
  background: ${(props) => props.background};
  color: ${(props) => props.color.text};
  height: ${(props) => props.height};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media (min-width: 1280px) {
    justify-content: space-between;
  }

  .context {
    font-weight: 500;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    padding: 16px;

    @media (min-width: 360px) {
      margin-right: 40px;
    }

    @media (min-width: 640px) {
      margin-right: 250px;
      padding: 16px 32px;
    }

    .left {
      @media (min-width: 640px) {
        min-width: 120px;
      }

      @media (min-width: 1280px) {
        max-width: 605px;
      }

      p {
        font-family: 'DM Sans' !important;
        font-size: 14px;
        font-weight: 700;

        @media (min-width: 768px) {
          font-size: 1rem;
        }

        @media (min-width: 1280px) {
          font-size: 1.2rem;
        }

        @media (min-width: 1536px) {
          font-size: 1.5rem;
        }
      }
    }

    .classification {
      min-width: 100px;

      span,
      b {
        font-family: 'DM Sans' !important;
        display: block;
      }
      :first-child {
        text-transform: uppercase;
        font-size: 0.675rem;
        font-weight: 700;
        color: ${(props) => props.color.context};

        @media (min-width: 1280px) {
          font-size: 0.8rem;
        }
      }

      b {
        font-family: 'DM Sans' !important;
        font-size: 1.5rem;

        @media (min-width: 640px) {
          display: inline;
          margin-right: 8px;
        }

        @media (min-width: 1280px) {
          font-size: 2.5rem;
        }
      }

      :last-child {
        font-size: 1rem;
        font-weight: 300;
        font-family: 'DM Sans' !important;

        @media (min-width: 640px) {
          display: inline;
        }

        @media (min-width: 1280px) {
          font-size: 2rem;
        }
      }
    }

    .unclassified {
      font-family: 'DM Sans' !important;
      font-weight: 400;
      font-size: 1rem;

      @media (min-width: 1280px) {
        font-size: 2rem;
      }
    }
  }
`

interface DividerProps {
  divider: string
}

export const Divider = styled.hr<DividerProps>`
  display: block;
  border: none;
  border-left: 2px solid ${(props) => props.divider};
  margin-right: 16px;
  margin-left: 24px;
  height: 70px;

  @media (min-width: 1280px) {
    margin: 0 24px;
  }
`

interface ImageProps {
  currentPosition: number
}

export const PodiumMedalEndMobile = styled.div<ImageProps>`
  ${({ currentPosition }) =>
    currentPosition === 1 &&
    css`
      background-image: url(${firstMobile});
    `}

  ${({ currentPosition }) =>
    currentPosition === 2 &&
    css`
      background-image: url(${secondMobile});
    `} 
  
  ${({ currentPosition }) =>
    currentPosition === 3 &&
    css`
      background-image: url(${thirdMobile});
    `} 
    
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  height: 100%;
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: inherit;

  @media (max-width: 360px) {
    display: none;
  }

  @media (min-width: 640px) {
    display: none;
  }
`

export const CommonClassification = styled.div`
  @media (max-width: 360px) {
    display: none;
  }

  @media (min-width: 640px) {
    display: none;
  }

  background-image: url(${otherPositionsMobile});
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-bottom-right-radius: inherit;
`
interface MedalPositionProps {
  currentPosition: number
}

export const MedalPosition = styled.div<MedalPositionProps>`
  margin-right: 16px;
  display: block;

  height: 54px;
  min-width: 80px;

  ${({ currentPosition }) =>
    currentPosition === 1 &&
    css`
      background-image: url(${first});
    `}

  ${({ currentPosition }) =>
    currentPosition === 2 &&
    css`
      background-image: url(${second});
    `} 
  
  ${({ currentPosition }) =>
    currentPosition === 3 &&
    css`
      background-image: url(${third});
    `}

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const LargeMedal = styled.div`
  display: block;
  background-image: url(${MedalImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 108px;
  width: 300px;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: 640px) {
    width: 280px;
  }

  @media (min-width: 1280px) {
    height: 124px;
    right: 16px;
  }

  @media (min-width: 1536px) {
    right: 64px;
  }
`
