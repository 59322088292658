import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  incrementButton: () => ({
    '&.MuiButtonBase-root': {
      border: `2px solid ${palette.grayscale[300]}`,
      backgroundColor: palette.grayscale[200],
      color: palette.grayscale[800],
      borderRadius: '50%',
      width: 32,
      minWidth: 32,
      height: 32,
      fontSize: '20px',
      '&:disabled': {
        color: palette.grayscale[800],
        border: `none`,
        backgroundColor: palette.grayscale[200],
      },
      '&:focus': {
        backgroundColor: 'none',
        boxShadow: `0 0 0 6px ${palette.primary[100]}`,
        transition: 'box-shadow 0.2s ease-in-out',
        border: `2px solid ${palette.primary[300]}`,
      },
      '&:hover': {
        backgroundColor: palette.grayscale[100],
      },
    },
  }),
  displayButton: ({ white }) => ({
    '&.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
      borderRight: 'none',
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0.75px',
      width: '32px',
      height: '32px',
      color: white ? palette.white : palette.grayscale[800],
      '&:disabled': {
        color: white ? palette.white : palette.grayscale[800],
        border: `none`,
      },
      '&:focus': {
        color: white ? palette.white : palette.grayscale[800],
      },
      '&:hover': {
        color: white ? palette.white : palette.primary[300],
      },
    },
    '&.MuiButtonGroup-groupedTextVertical:not(:last-child)': {
      borderBottom: 'none',
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0.75px',
      minWidth: '32px',
      width: '32px',
      height: '32px',
      color: palette.grayscale[800],
      '&:disabled': {
        color: palette.grayscale[800],
        border: `none`,
      },
      '&:focus': {
        color: palette.grayscale[800],
      },
      '&:hover': {
        color: palette.primary[300],
      },
    },
  }),
}))

export default styles
