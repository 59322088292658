import logo from '../../assets/img/logo-copernico.png'
import logoWhite from '../../assets/img/logo-copernico-white.png'
import { type EnterpriseInformation } from '../globalConfig.types'

export const copernicoEnterpriseConfig: EnterpriseInformation = {
  appinfo: {
    language: 'pt',
    tab_icon: 'copernico.svg',
    logo,
    logoPDF: logo,
    logo_white: logoWhite,
    logo_order: logoWhite,
    favicon: '',
    favicon_white: '',
    corporateName: 'SOL COPERNICO DISTRIBUIDORA LTDA',
    name: 'SOL Copérnico',
    document: '39.798.207/0001-99',
    phone: '(11) 2626-3932',
    email: 'contato@copernico.com.br',
    address: 'Av. Paulista, 1063 - 2º Andar - Bela Vista, São Paulo - SP',
    secondAddress: '',
    site: '',
    metatags: {
      title: '',
      url: '',
      image: '',
      site_name: '',
      description: '',
      keywords: '',
      author: '',
    },
    socials: [
      {
        media: 'Facebook',
        icon: 'fa-facebook-f',
        url: 'https://www.facebook.com/sol.copernico',
      },
      {
        media: 'Instagram',
        icon: 'fa-instagram',
        url: 'https://www.instagram.com/sejasol/',
      },
      {
        media: 'Youtube',
        icon: 'fa-youtube',
        url: 'https://www.youtube.com/channel/UC47nSwtwnjckjvSCKtaXiSA',
      },
      {
        media: 'Linkedin',
        icon: 'fa-linkedin-in',
        url: 'https://br.linkedin.com/company/sol-copernico',
      },
    ],
    show_campaigns: {
      supportMaterials: false,
    },
  },
}
