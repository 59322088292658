import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid } from '@material-ui/core'
import { InfoPaper, ReturnPaper, Spacer, Paper } from '@77sol/core'
import { Typography } from 'components'
import {
  CloseXIcon,
  EditIcon,
  ViewIcon,
  CashMoneyIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import smoothscroll from 'smoothscroll-polyfill'
import DragIcon from '../../../../img/dragGrey.png'
import DragIconBlue from '../../../../img/dragBlue.png'
import { DragPaper } from '../../..'
import {
  ConcessionariaCard,
  TarifaCard,
  CorrecaoAnualCard,
  InvestimentoRetornoCard,
  OrcamentoPanel,
} from '../../..'
import { DigitalRetornoSubCards, DigitalTirVplContainers } from '..'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
})

function DigitalInvestingContainer({
  container,
  index,
  editMode,
  investingContainers,
  setStateInvesting,
  internalEditing,
  setInternalEditing,
  drag,
  setDrag,
  editingMore,
  selectedQuote,
  setEditingMore,
  proposal,
  size,
  tirVplContainers,
  setTirVplContainers,
  retornoSubCards,
  setRetornoSubCards,
  budgetEdit,
  returnData,
  setReturnData,
  handleGetBudget,
  colors,
}) {
  /* eslint eqeqeq: 0 */
  const classes = useStyles()
  const nivelLogin = proposal?.nivelLogin

  return (
    <Draggable
      isDragDisabled={
        editMode != 'investingContainer' ||
        editingMore == 'voceInvesteContainer' ||
        editingMore == 'TirContainer' ||
        editingMore == 'VplContainer' ||
        editingMore == 'retornoEmContainer' ||
        editingMore == '30AnosContainer' ||
        editingMore == 'RetornoContainer'
      }
      key={container.id}
      draggableId={container.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Grid
          item
          className={classes.paddingSection}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={container.breakpoint}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div
            onClick={(e) => {
              if (editMode == 'investingContainer') {
                internalEditing && internalEditing == container.id
                  ? container.id == 'retornoSubCards' ||
                    container.id == 'tirVplContainers'
                    ? e.stopPropagation()
                    : setInternalEditing('')
                  : setInternalEditing(container.id)
                setEditingMore('')
              }
            }}
            style={{ height: '100%' }}
            className={snapshot.isDragging && classes.dragging}
          >
            {container.id == 'retornoSubCards' && (
              <DigitalRetornoSubCards
                container={container}
                index={index}
                editMode={editMode}
                investingContainers={investingContainers}
                setStateInvesting={setStateInvesting}
                internalEditing={internalEditing}
                setInternalEditing={setInternalEditing}
                drag={drag}
                setDrag={setDrag}
                editingMore={editingMore}
                selectedQuote={selectedQuote}
                setEditingMore={setEditingMore}
                proposal={proposal}
                size={size}
                retornoSubCards={retornoSubCards}
                setRetornoSubCards={setRetornoSubCards}
                returnData={returnData}
                setReturnData={setReturnData}
                colors={colors}
              />
            )}
            {container.id == 'tirVplContainers' && (
              <DigitalTirVplContainers
                container={container}
                index={index}
                editMode={editMode}
                investingContainers={investingContainers}
                setStateInvesting={setStateInvesting}
                internalEditing={internalEditing}
                setInternalEditing={setInternalEditing}
                drag={drag}
                setDrag={setDrag}
                editingMore={editingMore}
                selectedQuote={selectedQuote}
                setEditingMore={setEditingMore}
                proposal={proposal}
                size={size}
                tirVplContainers={tirVplContainers}
                setTirVplContainers={setTirVplContainers}
                returnData={returnData}
                setReturnData={setReturnData}
                colors={colors}
              />
            )}
            {container.id == 'voceInvesteContainer' && (
              <InfoPaper
                colors={colors}
                isDragging={snapshot.isDragging}
                icon={
                  <CashMoneyIcon
                    height="48"
                    width="48"
                    color={colors ? colors.primary_color : palette.primary[300]}
                  />
                }
                title="Você investe"
                text={
                  returnData?.custoTotalProjeto &&
                  parseFloat(returnData?.custoTotalProjeto)?.toLocaleString(
                    'pt-br',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    },
                  )
                }
              />
            )}
            {container.id == 'RetornoContainer' && (
              <ReturnPaper
                colors={colors}
                returnData={returnData}
                isDragging={snapshot.isDragging}
              />
            )}
          </div>
          {snapshot.isDragging && <DragPaper />}
          {editMode == 'investingContainer' &&
            internalEditing == container.id &&
            container.id != 'retornoSubCards' &&
            container.id != 'tirVplContainers' && (
              <div
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-15px',
                  left: '-15px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Paper
                  style={{
                    padding: '16px 24px',
                    boxShadow: 'none',
                    cursor: 'auto',
                    maxHeight: '50vh',
                    width: 'auto',
                    border: `1px solid ${palette.grayscale[300]}`,
                    overflowY: 'auto',
                    maxWidth: '50vw',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="700"
                    >
                      {container.display}
                    </Typography>
                    <Spacer size="24" direction="vertical" />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {editingMore == container.id ? (
                        <Tooltip title="Fechar">
                          <CloseXIcon
                            onClick={() =>
                              editingMore == container.id
                                ? setEditingMore('')
                                : setEditingMore(container.id)
                            }
                            style={{ cursor: 'pointer' }}
                            width="24"
                          />
                        </Tooltip>
                      ) : (
                        <>
                          {container.id == 'voceInvesteContainer' &&
                          nivelLogin >= 4 ? (
                            <></>
                          ) : (
                            <Tooltip title="Editar">
                              <EditIcon
                                onClick={() =>
                                  editingMore == container.id
                                    ? setEditingMore('')
                                    : setEditingMore(container.id)
                                }
                                style={{ cursor: 'pointer' }}
                                width="24"
                                color={palette.grayscale[700]}
                              />
                            </Tooltip>
                          )}
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Arrastar">
                            <img
                              alt="drag"
                              onClick={() =>
                                drag == container.id
                                  ? setDrag('')
                                  : setDrag(container.id)
                              }
                              src={
                                internalEditing == container.id
                                  ? DragIconBlue
                                  : DragIcon
                              }
                              style={{
                                width: '20px',
                                cursor: 'pointer',
                                color:
                                  drag == container.id
                                    ? '#1335c6'
                                    : 'rgb(112, 112, 114)',
                              }}
                            />
                          </Tooltip>
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Esconder">
                            <ViewIcon
                              onClick={() => {
                                const newArr = [...investingContainers]
                                const index = newArr.findIndex(
                                  (item) => item.id == container.id,
                                )
                                newArr[index].visible = !newArr[index].visible
                                setStateInvesting({ containers: newArr })
                              }}
                              style={{ cursor: 'pointer' }}
                              width="24"
                              color={palette.grayscale[700]}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  {editingMore == container.id && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {container.id == 'voceInvesteContainer' ? (
                        <OrcamentoPanel
                          lessMargin
                          handleGetBudget={handleGetBudget}
                          budgetEdit={budgetEdit}
                          selectedQuote={selectedQuote}
                        />
                      ) : (
                        <Grid
                          style={{
                            width: '100%',
                            padding: '0px',
                            margin: '0px',
                            marginTop: '16px',
                          }}
                          container
                          spacing={2}
                        >
                          <Grid xs={12} md={12} item>
                            <ConcessionariaCard proposal={proposal} />
                          </Grid>
                          <Grid xs={12} md={12} item>
                            <TarifaCard
                              selectedQuote={selectedQuote}
                              setReturnData={setReturnData}
                              returnData={returnData}
                            />
                          </Grid>
                          <Grid xs={12} md={12} item>
                            <CorrecaoAnualCard
                              selectedQuote={selectedQuote}
                              setReturnData={setReturnData}
                              returnData={returnData}
                            />
                          </Grid>
                          <Grid xs={12} md={12} item>
                            <InvestimentoRetornoCard
                              returnData={returnData}
                              onlyShow
                              noPadding
                            />
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  )}
                </Paper>
              </div>
            )}
        </Grid>
      )}
    </Draggable>
  )
}

export default DigitalInvestingContainer
