import { type ISkeletonProps } from '.'
import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Skeleton = styled.div<ISkeletonProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 8px;
  animation: 3s ease-in infinite skeleton;

  @keyframes skeleton {
    0% {
      background-color: ${palette.grayscale[100]};
    }

    50% {
      background-color: ${palette.grayscale[200]};
    }

    100% {
      background-color: ${palette.grayscale[100]};
    }
  }
`
