import { Switch } from '@77sol-ui/atoms'
import { Tooltip } from '@77sol-ui/molecules'
import * as S from './styles'
import { useTooltip } from '../../hooks/useTooltip'

interface IInsuranceSwitchProps {
  onChange?: (value: boolean) => void
  disabled?: boolean
  isChecked: boolean
  disabledDescription?: string
}

export function InsuranceSwitch({
  isChecked,
  onChange = () => {},
  disabled,
  disabledDescription,
}: IInsuranceSwitchProps) {
  const { open, setOpen, handleOpenByClick } = useTooltip()

  if (disabled) {
    return (
      <Tooltip.Provider delayDuration={50}>
        <Tooltip.Root open={open} onOpenChange={setOpen}>
          <Tooltip.Trigger asChild>
            <Switch.Root
              checked={false}
              size="medium"
              onClick={handleOpenByClick}
            >
              <Switch.Thumb size="medium" />
            </Switch.Root>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <S.StyledTooltipContent
              align="center"
              side="bottom"
              sideOffset={10}
              variant="blue"
            >
              {disabledDescription}
            </S.StyledTooltipContent>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    )
  }

  return (
    <Switch.Root onCheckedChange={onChange} checked={isChecked} size="medium">
      <Switch.Thumb size="medium" />
    </Switch.Root>
  )
}
