import React from 'react'
import { Button } from '@77sol/core'
import { CloseXIcon } from '@77sol/icons/dist'
import * as Alert from '../../../../../../../../../components/AlertDialog'
import {
  INVALID_FIELDS_MESSAGE,
  TITLE_BUTTON_SOME_DIRTY_FIELD,
  TITLE_MODAL_SOME_FIELD_DIRTY,
} from '../../../../constants'

interface FieldsWithErrorProps {
  open: boolean
  onClose?: () => void
  onCloseCustomOpen: () => void
}

const FieldsWithError = ({
  open,
  onClose,
  onCloseCustomOpen,
  ...props
}: FieldsWithErrorProps) => {
  return (
    <Alert.Root open={open} onClose={onClose} {...props}>
      <Alert.CloseButton onClick={onClose}>
        <CloseXIcon width="24" />
      </Alert.CloseButton>
      <Alert.Container>
        <Alert.Title title={TITLE_MODAL_SOME_FIELD_DIRTY} />
        <Alert.Description
          color="red"
          $colorWeight="300"
          description={INVALID_FIELDS_MESSAGE}
        />
        <Alert.ButtonContainer>
          <Button onClick={onCloseCustomOpen} variant="text" size="small">
            Descartar e Sair
          </Button>
          <Button size="small" disabled={true}>
            {TITLE_BUTTON_SOME_DIRTY_FIELD}
          </Button>
        </Alert.ButtonContainer>
      </Alert.Container>
    </Alert.Root>
  )
}

export default FieldsWithError
