import { Typography, Title } from '@77sol/core'
import { formatDate } from 'utils'
import { FileIcon, FlagBannorIcon, CheckIcon } from '@77sol/icons/dist'
import { SubmitFinancingDocuments } from './components/SubmitFinancingDocuments'
import { ProceedToApproval } from './components/ProceedToApproval'
import { ACTIVITIES_TITLE } from '../../constants/status'
import * as S from './styles'

export function Activities({ history, selectedFinancing }) {
  const handleIcon = (icon) => {
    const labels = {
      CheckOutlinedIcon: <CheckIcon />,
      FlagBannor: <FlagBannorIcon />,
      File: <FileIcon />,
    }

    return labels[icon]
  }

  return (
    <S.Container>
      <S.Time>
        <Typography size="xsmall" colorWeight="300">
          {formatDate(history.created_at)}
        </Typography>
      </S.Time>
      <S.Content>
        {history.icon && (
          <S.Icon color={history.color}>{handleIcon(history.icon)}</S.Icon>
        )}
        <div>
          <S.Context titleColor={history.color}>
            <Title type="link" size="xxsmall">
              {history.title}
            </Title>

            <Typography size="small" colorWeight="300">
              {history.subtitle}
            </Typography>

            {history.file && (
              <a href={history?.file} target="_blank" rel="noreferrer">
                Ver {history?.type_name?.toLowerCase()}
              </a>
            )}
          </S.Context>

          {history.title === ACTIVITIES_TITLE.APPROVAL && (
            <ProceedToApproval selectedFinancing={selectedFinancing} />
          )}

          {history.title === ACTIVITIES_TITLE.SUBMIT_DOCUMENTS && (
            <SubmitFinancingDocuments selectedFinancing={selectedFinancing} />
          )}
        </div>
      </S.Content>
    </S.Container>
  )
}
