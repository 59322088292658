import { Features } from './features.types'
import { FeaturesRoutes } from './routes.types'
import { type UserRole } from './types'

export interface IPermissions {
  featuresCannotAccess: Features[]
  routesCannotAccess: FeaturesRoutes[]
}

const superAdminPermissions: IPermissions = {
  featuresCannotAccess: [],
  routesCannotAccess: [],
}

const adminPermissions: IPermissions = {
  featuresCannotAccess: [],
  routesCannotAccess: [],
}

const gestorPermissions: IPermissions = {
  featuresCannotAccess: [Features.CONFIGURACOES],
  routesCannotAccess: [FeaturesRoutes.CONFIGURACOES],
}

const vendedorPermissions: IPermissions = {
  featuresCannotAccess: [
    Features.CONFIGURACOES,
    Features.EQUIPAMENTOS,
    Features.MEUTIME,
  ],
  routesCannotAccess: [
    FeaturesRoutes.CONFIGURACOES,
    FeaturesRoutes.MEUTIME,
    FeaturesRoutes.EQUIPAMENTOS,
  ],
}

const permissions: Record<UserRole, IPermissions> = {
  1: superAdminPermissions,
  2: adminPermissions,
  3: gestorPermissions,
  4: vendedorPermissions,
}

export { permissions }
