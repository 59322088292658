export function setStructureTypes(structureTypes) {
  return {
    type: 'SET_STRUCTURE_TYPES',
    structureTypes,
  }
}

export function setAllPaymentMethods(allPaymentMethods) {
  return {
    type: 'SET_ALL_PAYMENT_METHODS',
    allPaymentMethods,
  }
}
