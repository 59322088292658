import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 8px 0 16px 0;
`
export const DesktopContainer = styled.div`
  display: none;

  @media (min-width: 1280px) {
    display: block;
  }
`

export const MobileContainer = styled.div`
  display: block;

  @media (min-width: 1280px) {
    display: none;
  }
`
