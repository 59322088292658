import { type MouseEvent, type ReactNode, useRef } from 'react'
import { StyledCarouselItem } from './styles'

interface IClickableItemProps {
  children: ReactNode
  onClick: () => void
}

export function ClickableItem({ children, onClick }: IClickableItemProps) {
  const clientXRef = useRef<MouseEvent | null>(null)

  function onMouseDown(event: MouseEvent) {
    clientXRef.current = event
    event.preventDefault()
  }

  function onCarouselClick(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (clientXRef.current?.clientX !== event.clientX) {
      event.preventDefault()
    } else {
      onClick()
    }
  }

  return (
    <StyledCarouselItem
      onMouseDown={(e) => {
        onMouseDown(e)
      }}
      onClick={onCarouselClick}
    >
      {children}
    </StyledCarouselItem>
  )
}
