import { useState } from 'react'
import { type IFinancingStatus } from '../types'
import {
  ALL_STATUS_FILTER,
  FINANCING_STATUS_FILTER,
  FinancingAndProposalTab,
  TabEnum,
} from '../constants'
import { useAmplitude } from 'hooks/useAmplitude'
import { tableFiltersTrackers } from 'services/tracker/events/tableFilters/trackers'

export function useFinancingAndProposalsFilter() {
  const { logEvent } = useAmplitude()
  const [selectedStatus, setSelectedStatus] = useState<IFinancingStatus>(
    ALL_STATUS_FILTER as unknown as IFinancingStatus,
  )
  const [selectedTab, setSelectedTab] = useState<FinancingAndProposalTab>(
    FinancingAndProposalTab.FINANCINGS,
  )

  function onChangeTab(tab: string) {
    setSelectedStatus(ALL_STATUS_FILTER as unknown as IFinancingStatus)
    setSelectedTab(tab as FinancingAndProposalTab)

    logEvent(tableFiltersTrackers.actions.changeTabFilter, {
      origin: '/dashboard',
      tab: TabEnum[tab as keyof typeof TabEnum],
    })
  }

  function onChangeFilter(value: string) {
    const status = FINANCING_STATUS_FILTER?.find(
      (status: IFinancingStatus) => status?.value === Number(value),
    )
    setSelectedStatus(status ?? ALL_STATUS_FILTER)

    logEvent(tableFiltersTrackers.actions.changeStatusFilter, {
      origin: '/dashboard',
      tab: TabEnum[selectedTab],
      status: status?.label,
    })
  }

  return {
    selectedStatus,
    selectedTab,
    onChangeTab,
    onChangeFilter,
    FILTER_STATUS: FINANCING_STATUS_FILTER,
  }
}
