import styled from 'styled-components'

export const Paper = styled.div`
  cursor: pointer;

  width: 100%;
  height: 100%;
  min-height: 138px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: #0000000a 0px 4px 6px;

  display: grid;
  align-items: baseline;
  grid-gap: 4px;
  padding: 42px 0;

  header,
  main {
    display: grid;
    padding: 0 24px;

    h3,
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[data-solleads='true'] {
    padding: 0 0 42px;
    grid-template-rows: 38px auto auto;
  }

  &[data-solleads='true']::before {
    content: 'SOLLEADS';

    font-size: 12px;
    font-weight: 700;
    font-family: DM Sans;
    color: #fff;

    display: grid;
    place-content: center;
    justify-self: flex-end;

    width: fit-content;
    padding: 10px;
    border-radius: 0px 8px;
    background-color: #2d69f6;
  }

  &[data-disabled='true'] {
    cursor: not-allowed;

    opacity: 0.5;
    background-color: #e0e0e0;
  }

  &[data-disabled='true']::before {
    background-color: #4f4f4f;
  }
`

export const Header = styled.header`
  padding: 0 24px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.75px;
    color: #262338;
  }
`

export const Content = styled.main`
  padding: 0 24px;

  p {
    font-size: 13px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6e7191;
  }
`
