import { CardQueue } from 'components/CardQueue'
import { QuotationCard } from './components/QuotationCard'
import { type IQuotationCardQueueProps } from './types'

import { useQuotationCardQueue } from './hooks/useQuotationCardQueue'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'

export function QuotationCardQueue({
  quotations,
  quotationSelected,
  onQuotationSelect,
  onAddQuotation,
  isLoading,
}: IQuotationCardQueueProps) {
  const { logEvent } = useAmplitude()
  const changeTab = useEquipmentContainerStore((state) => state.changeTab)

  const { scrollContainerRef } = useQuotationCardQueue({ quotationSelected })

  const setOpenModalToDeleteQuotation = useEquipmentContainerStore(
    (state) => state.setOpenModalToDeleteQuotation,
  )

  const currentTab = useEquipmentContainerStore((state) => state.currentTab)

  const handleAddQuotation = () => {
    logEvent(quotationTracker.actions.newQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })
    changeTab(TAB_ENUM.CHOOSE_KIT)
    onAddQuotation?.()
  }

  const handleDeleteQuotation = (quotation: IQuotationDTO) => {
    logEvent(quotationTracker.actions.removeQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })
    setOpenModalToDeleteQuotation(quotation)
  }

  return (
    <CardQueue.Root>
      <CardQueue.ScrollContainer ref={scrollContainerRef}>
        <CardQueue.ButtonAdd
          isLoading={isLoading}
          onClick={handleAddQuotation}
          disabled={isLoading}
        />
        <CardQueue.Content>
          {quotations?.map((quotation) => (
            <QuotationCard
              key={quotation.id}
              id={`card-${quotation.id}`}
              quotation={quotation}
              onDelete={() => {
                handleDeleteQuotation(quotation)
              }}
              isSelected={quotationSelected?.id === quotation.id}
              onSelect={() => {
                onQuotationSelect?.(quotation)
              }}
            />
          ))}
        </CardQueue.Content>
      </CardQueue.ScrollContainer>
    </CardQueue.Root>
  )
}
