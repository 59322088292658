import { Input, type InputProps } from '@77sol-ui/atoms'
import * as S from './styles'
import { Minus, Plus } from 'lucide-react'

interface IInputQuantityProps extends InputProps {
  onIncrease: () => void
  onDecrease: () => void
  disabledIncrease?: boolean
  disabledDecrease?: boolean
}

export function InputQuantity({
  onIncrease,
  onDecrease,
  disabledIncrease = false,
  disabledDecrease = false,
  ...rest
}: IInputQuantityProps) {
  return (
    <S.StyledInputQuantity>
      <S.StyledQuantityButton
        data-testid="input-button-decrease-quantity"
        className="minus"
        onClick={onDecrease}
        disabled={disabledDecrease}
      >
        <Minus size={18} />
      </S.StyledQuantityButton>
      <Input {...rest} data-testid="input-quantity" />
      <S.StyledQuantityButton
        data-testid="input-button-increase-quantity"
        className="plus"
        onClick={onIncrease}
        disabled={disabledIncrease}
      >
        <Plus size={18} />
      </S.StyledQuantityButton>
    </S.StyledInputQuantity>
  )
}
