import React from 'react'
import { Box } from '@material-ui/core'
import { LabelItem } from '../components'
import styles from './styles'

export function Observacoes({ text }) {
  const classes = styles()

  return (
    <Box p={3} className={classes.container}>
      <LabelItem title="Observações" text={text} />
    </Box>
  )
}
