import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { useUpdateDimensioningKits } from 'domains/dimensioning/update-dimensioning-kits/hooks/useUpdateDimensioningKits'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useToast } from '@77sol/core'
import { queryClient } from 'services/queryClient'
import { type IKitDTO } from 'dto/KitDTO'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'

export function useUpdateKits() {
  const { onErrorOpenToast } = useToast()

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const queryKeys = [GET_DIMENSIONING, dimensioningId]

  const { isFetching } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const { mutateAsync, isLoading } = useUpdateDimensioningKits({
    dimensioningId,
  })

  const isLoadingKits = isFetching || isLoading

  async function updateKits(kits: IKitDTO[]) {
    try {
      await mutateAsync({ kits })

      await queryClient.invalidateQueries(queryKeys)
    } catch {
      throw onErrorOpenToast('Ocorreu um erro ao atualizar os kits.')
    }
  }

  return {
    updateKits,
    isLoadingKits,
  }
}
