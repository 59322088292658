import { useMutation } from '@tanstack/react-query'
import { quotationRemoveProduct } from '../services/api'
import { QUOTATION_REMOVE_PRODUCT } from '../constants/endpointKeys'
import { type IQuotationProductRemovePayload } from '../services/types'

export function useQuotationRemoveProduct() {
  const { ...mutation } = useMutation({
    mutationKey: [QUOTATION_REMOVE_PRODUCT],
    mutationFn: async (payload: IQuotationProductRemovePayload) =>
      await quotationRemoveProduct(payload),
  })

  return { ...mutation }
}
