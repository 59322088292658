import { useToast } from '@77sol/core'
import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import { useCallback } from 'react'

interface IUseValidateDeleteQuotation {
  quotation: IQuotationDTO
  allQuotations?: IQuotationDTO[]
}

export function useValidateDeleteQuotation({
  quotation,
  allQuotations,
}: IUseValidateDeleteQuotation) {
  const { onErrorOpenToast } = useToast()

  const checkSingleQuotation = useCallback(() => {
    const currentQuotation = allQuotations?.find(
      (quote) => quote?.id === quotation?.id,
    )

    const filteredQuotations = allQuotations?.filter(
      (quote) => quote?.dimensioning_id === currentQuotation?.dimensioning_id,
    )

    const isOnlyQuote = filteredQuotations?.length === 1

    if (isOnlyQuote) {
      onErrorOpenToast(
        'Não é possível remover essa cotação, pois é a única de seu dimensionamento!',
      )
    }

    return isOnlyQuote
  }, [allQuotations, quotation])

  return { checkSingleQuotation }
}
