import { useCallback, useEffect, useRef, useState } from 'react'

export const useContentHeight = () => {
  const [hasScroll, setHasScroll] = useState(false)

  const contentRef = useRef<{ clientHeight: number; scrollHeight: number }>(
    null,
  )

  const handleGetScroll = useCallback(() => {
    const scrollHeight = contentRef?.current?.scrollHeight ?? 0
    const clientHeight = contentRef?.current?.clientHeight ?? 0

    const isScrolling = scrollHeight > clientHeight

    return isScrolling
  }, [contentRef?.current?.scrollHeight, contentRef?.current?.clientHeight])

  const handleResize = useCallback(() => {
    const hasScroll = handleGetScroll()
    setHasScroll(hasScroll)
  }, [handleGetScroll])

  useEffect(() => {
    const hasScroll = handleGetScroll()

    setHasScroll(hasScroll)
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, handleGetScroll])

  return { hasScroll, contentRef }
}
