import { FINANCING_REQUEST_STATUS_ENUM } from 'enums/financingRequest/FinancingRequestStatusEnum'

export const useFinancingStatusRequestBadge = (
  status: FINANCING_REQUEST_STATUS_ENUM,
) => {
  const { APROVADO, SOLICITADO, CANCELADO, NEGADO, PRE_APROVADO } =
    FINANCING_REQUEST_STATUS_ENUM

  const statusVariant = {
    [SOLICITADO]: {
      color: 'orange',
      text: 'Solicitado',
    },
    [PRE_APROVADO]: {
      color: 'blue',
      text: 'Pré aprovado',
    },
    [APROVADO]: {
      color: 'green',
      text: 'Aprovado',
    },
    [NEGADO]: {
      color: 'red',
      text: 'Negado',
    },
    [CANCELADO]: {
      color: 'gray',
      text: 'Cancelado',
    },
  }

  return (
    statusVariant?.[status] || {
      color: 'blue',
      text: '-',
    }
  )
}
