import { palette } from '@77sol/styles'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  container: {
    padding: 32,
    background: palette.primary[100],
    borderRadius: 10,

    '@media (min-width:1280px)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& button': {
      height: 'fit-content',
    },
  },
  containerImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      height: 75,

      '@media (min-width:768px)': {
        marginRight: 32,
      },
    },
  },
  row: {
    '@media (min-width:768px)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  badge: {
    marginBottom: 10,
  },
  context: {
    margin: '16px 0',

    '@media (max-width:768px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& p': {
      lineHeight: '22px',
      color: palette.grayscale[500],
      textAlign: 'center',
      display: 'block',

      '@media (min-width:768px)': {
        textAlign: 'start',
      },
      '& strong': {
        color: palette.primary[300],
      },
    },
  },
}))
