import styled from 'styled-components'

export const Form = styled.form`
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1px;
  }
`
