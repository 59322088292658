import { palette } from '@77sol/styles'
import styled from 'styled-components'

export * from '../styles'

export const Slider = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }

  .percent {
    background: ${palette.primary[300]};
    color: #fff;
    width: 92px;
    height: 52px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
