import React, { useEffect, useState } from 'react'
import { Spacer, useToast } from '@77sol/core'
import API from 'api'
import Billets from './components/Billets'
import CreditCard from './components/CreditCard'
import Financing from './components/Financing'
import BankTransfer from './components/BankTransfer'
import {
  beOneMethod,
  convertInstallments,
  convertShortage,
  percentBillet,
} from './utils'

const financingBanks = async (financing) => {
  const defaultBanks = await API.get('/financing/banks')
  const userBanks = await API.get('/user/payment-configs')

  const allBanks = [
    ...defaultBanks.data.data.filter(({ config_simulation }) => {
      return JSON.parse(config_simulation || '{}').view_admin
    }),
    ...(userBanks.data.financing.data || []).filter(({ enable }) => {
      return enable
    }),
  ].map(
    ({
      bank = '',
      logo_url = '',
      config_simulation = null,
      ...infosBanksUser
    }) => {
      const {
        tax_month,
        quotas = [],
        grace_days = [],
      } = JSON.parse(config_simulation || '{}')

      const infosBankDefault = {
        status: 1,
        name_financier: bank,
        image_financier: logo_url,
        waiting_period: grace_days[0],
        number_installments: quotas[0],
        monthly_interest_rate: tax_month,
        quotas: convertInstallments(quotas),
        grace_days: convertShortage(grace_days),
      }

      return {
        config_simulation,
        ...infosBankDefault,
        ...infosBanksUser,
      }
    },
  )

  if (financing) {
    return allBanks.map((bank) => {
      const infosBanksProposal = financing.find(
        ({ name_financier }) => name_financier === bank.name_financier,
      )
      if (infosBanksProposal) {
        const {
          quotas = [],
          grace_days = [],
          ...configs
        } = JSON.parse(infosBanksProposal.config_simulation || '{}')
        return {
          ...bank,
          ...configs,
          ...infosBanksProposal,
          quotas: convertInstallments(quotas),
          grace_days: convertShortage(grace_days),
        }
      }
      return {
        ...bank,
        status: 0,
      }
    })
  }

  return allBanks
}

function PagamentoEdit({
  proposal = {},
  selectedQuoteEdit,
  setMethodsQuoteEdit,
}) {
  const [firstQuote] = proposal.quotes
  const fullQuote = proposal.quotes.find(
    (quote) => quote.quotation.id === selectedQuoteEdit,
  )
  const percentFirstBillet = percentBillet(fullQuote.quotation)
  const idQuote = selectedQuoteEdit || firstQuote.quotation.id

  const { onSuccessOpenToast, onErrorOpenToast, onInfoOpenToast } = useToast()

  const [userMethods, setUserMethods] = useState({})
  const [proposalMethods, setProposalMethods] = useState({})
  const [refreshMethods, setRefreshMethods] = useState(null)

  useEffect(() => {
    API.get('/user/payment-configs').then(({ data }) => {
      financingBanks().then((financing) => {
        setUserMethods({
          financing,
          billets: data?.billets?.data,
          credit_card: data?.card?.data,
          bank_transfer: data?.bank_transfer?.data,
        })
      })
    })
  }, [])

  useEffect(() => {
    API.get(`/proposal/quotation/payment_methods/${proposal.proposal.id}`).then(
      ({ data }) => {
        const { payments_methods = {} } = data.find(({ id }) => id === idQuote)
        setMethodsQuoteEdit(payments_methods)
        financingBanks(payments_methods.financing).then((financing) => {
          setProposalMethods({
            financing,
            quotation_id: idQuote,
            billets: payments_methods.billets,
            credit_card: payments_methods.cards,
            bank_transfer: payments_methods.bank_transfer,
          })
        })
      },
    )
  }, [refreshMethods])

  const updateMethods = (name = '', method = null) => {
    const paymentMethods = {
      ...proposalMethods,
      [name]: method,
    }

    if (paymentMethods.financing) {
      const actives = paymentMethods.financing.filter(({ status }) => status)
      paymentMethods.financing = actives.length ? actives : null
    }

    if (beOneMethod(paymentMethods, ['quotation_id'])) {
      onInfoOpenToast('Matenha ao menos um método selecionado!')
      return
    }

    API.post('/proposal/quotation/select/payment_form', paymentMethods)
      .then((data) => {
        onSuccessOpenToast('Forma de pagamento editada com sucesso!')
        setRefreshMethods(data)
      })
      .catch(() => {
        onErrorOpenToast('Erro ao editar a forma de pagamento!')
      })
  }

  return (
    <>
      <Billets
        minPercent={percentFirstBillet}
        userMethods={userMethods.billets}
        proposalMethods={proposalMethods.billets}
        setProposalMethod={(method) => updateMethods('billets', method)}
      />
      <Spacer size="8" direction="horizontal" />
      <CreditCard
        userMethods={userMethods.credit_card}
        proposalMethods={proposalMethods.credit_card}
        setProposalMethod={(method) => updateMethods('credit_card', method)}
      />
      <Spacer size="8" direction="horizontal" />
      <Financing
        userMethods={userMethods.financing}
        proposalMethods={proposalMethods.financing}
        setProposalMethod={(method) => updateMethods('financing', method)}
      />
      <Spacer size="8" direction="horizontal" />
      <BankTransfer
        userMethods={userMethods.bank_transfer}
        proposalMethods={proposalMethods.bank_transfer}
        setProposalMethod={(method) => updateMethods('bank_transfer', method)}
      />
    </>
  )
}

export default PagamentoEdit
