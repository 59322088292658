import { Tab } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const TableInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  #select-status {
    min-width: 230px;
    width: 230px;

    @media (max-width: 425px) {
      min-width: unset;
      width: 100%;
    }
  }
`

export const Title = styled.div`
  * {
    font-family: DM Sans;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #4f4f4f;
  }
`

export const TabRoot = styled(Tab.Root)`
  gap: 24px;
`
