import { CATEGORY_ENUM } from 'enums/products/CategoryEnum'
import filterAddedItems from './filterAddedItems'
import handleOverload from './handleOverload'
import handlePotency from './handlePotency'

export default function getOverloadAndPotency(quote) {
  const kit = quote?.quotation?.added_items
    ? filterAddedItems(quote?.kit, quote?.quotation.added_items)
    : quote?.kit

  const overload = Number(handleOverload(kit))

  const potency = Number(handlePotency(kit))

  const inversor = quote?.kit?.find(
    (item) => item?.categoria === CATEGORY_ENUM.INVERSOR,
  )

  const inversorOverload = Number(inversor?.overload) * Number(inversor?.qtde)

  return { overload, potency, inversorOverload }
}
