import { type IOrderDTO } from 'dto/OrderDTO'
import { type ORDER_STATUS_TRADUCTION_ENUM } from 'enums/order/OderStatusTraductionEnum'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'

import { ORDER_CLOSED_STATUS } from 'views/Pedidos/constants'

interface IUseOrderList {
  onClickCancelOrder: (id: string) => void
  onClickShareOrderTracking: (order: IOrderDTO) => void
  onClickOrder: (order: IOrderDTO) => void
  onClickRemakeOrder: (order: IOrderDTO) => void
  orders: IOrderDTO[]
  selectedOrder: IOrderDTO
}

export const useOrderList = ({
  onClickCancelOrder,
  onClickOrder,
  onClickRemakeOrder,
  onClickShareOrderTracking,
  orders,
  selectedOrder,
}: IUseOrderList) => {
  const { logEvent } = useAmplitude()
  const listIsEmpty = orders?.length === 0
  const orderIsSelected = Boolean(selectedOrder?.id)

  const isReserved = (status: string) => {
    const statusReserved = ['Reservado', 'Pedido gerado']
    return statusReserved.includes(status)
  }

  const isClosed = (status: ORDER_STATUS_TRADUCTION_ENUM) => {
    return ORDER_CLOSED_STATUS.includes(Number(status))
  }

  function handleCancelOrder(id: string) {
    onClickCancelOrder(id)

    logEvent(ordersTracker.actions.intentionToCancelOrder, {
      origin: 'listagem de pedidos',
    })
  }

  // @ts-expect-error payload_original
  const isExpiredOrder = ({ payload_original, status }) => {
    if (payload_original) {
      const parsedPayloadOriginal = Object(JSON.parse(payload_original))
      return Object.keys(parsedPayloadOriginal).length > 0 && status === '1'
    }
  }

  function onOrderTracking(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) {
    event.stopPropagation()
    if (isClosed(order?.status as ORDER_STATUS_TRADUCTION_ENUM)) return
    onClickShareOrderTracking(order)
  }

  function onButtonTagEvent(
    buttonType: 'pendencias' | 'refazer pedido' | 'rastrear',
  ) {
    logEvent(ordersTracker.actions.clickedOnTheTagButton, {
      origin: '/pedidos',
      buttonType,
    })
  }

  const handleClickTrackOrder = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) => {
    onOrderTracking(event, order)

    onButtonTagEvent('rastrear')
  }

  const handleDropdownClickTrackOrder = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) => {
    onOrderTracking(event, order)

    logEvent(ordersTracker.actions.trackOrder, {
      origin: '/pedidos',
    })
  }

  function handlePendingOrder(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) {
    event.stopPropagation()

    onClickOrder(order)

    onButtonTagEvent('pendencias')
  }

  function handleRemakeOrder(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) {
    event.stopPropagation()

    if (isExpiredOrder(order)) {
      onClickRemakeOrder(order as unknown as IOrderDTO)
      onButtonTagEvent('refazer pedido')
    }
  }

  const getRemakeOrderTooltip = (order: any) => {
    const parsedPayload = Object(JSON.parse(order.payload_original))
    const canRemakeOrder =
      order.status === '1' && Object.keys(parsedPayload).length > 0
    return !canRemakeOrder
      ? 'Pedido gerado via Time Comercial 77Sol. Entre em contato com o seu gerente caso queira refazer o pedido.'
      : ''
  }

  return {
    isReserved,
    isClosed,
    handleCancelOrder,
    isExpiredOrder,
    handleClickTrackOrder,
    handlePendingOrder,
    handleRemakeOrder,
    getRemakeOrderTooltip,
    listIsEmpty,
    orderIsSelected,
    handleDropdownClickTrackOrder,
  }
}
