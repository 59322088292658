import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  iconButton: {
    transition: 'transform 0.3s',
    transform: 'rotate(180deg)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconExpanded: {
    transition: 'transform 0.3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
