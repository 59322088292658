import { SidebarMobile } from '@77sol-ui/organisms'
import { ECOSYSTEM_GROUP_ITEMS } from '../../constants/integratorSidebarMobileItems'
import { type INavbarItem } from 'layouts/Dashboard/components/NavBar/components/NavbarItems/types'
import { ACCORDION_GROUP_KEYS } from '../../constants'
import { useSidebarMobile } from '../../hooks/useSidebarMobile'
import { Link } from 'react-router-dom'
import { checkIfCurrentPageIsActive } from 'components/Sidebar/utils'

interface EcosystemGroupProps {
  onRedirect: (navItem: INavbarItem) => void
  signals: Record<string, boolean>
}

export function EcosystemGroup({ onRedirect, signals }: EcosystemGroupProps) {
  const { validatePermission } = useSidebarMobile()

  return (
    <SidebarMobile.Accordion value={ACCORDION_GROUP_KEYS.ECOSSISTEMA}>
      <SidebarMobile.AccordionTrigger>
        Ecossistema
      </SidebarMobile.AccordionTrigger>

      <SidebarMobile.AccordionContent>
        <SidebarMobile.NavRoot>
          {ECOSYSTEM_GROUP_ITEMS.filter(validatePermission).map((navItem) => {
            const Icon = navItem.icon
            const hasSignalization =
              signals?.[navItem?.signalType as keyof typeof signals]
            return (
              <SidebarMobile.NavItem
                key={navItem.href}
                onClick={() => {
                  onRedirect(navItem)
                }}
                active={checkIfCurrentPageIsActive(navItem.href)}
                asChild
              >
                <Link to={navItem.href}>
                  <SidebarMobile.NavIcon>
                    <Icon />
                    {hasSignalization && <SidebarMobile.Marker />}
                  </SidebarMobile.NavIcon>
                  <SidebarMobile.NavTitle>
                    {navItem.title}
                  </SidebarMobile.NavTitle>
                </Link>
              </SidebarMobile.NavItem>
            )
          })}
        </SidebarMobile.NavRoot>
      </SidebarMobile.AccordionContent>
    </SidebarMobile.Accordion>
  )
}
