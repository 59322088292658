import React from 'react'
import { Timeline } from 'components'
import { Box } from '@material-ui/core'
import { Typography } from '@77sol/core'
import { Activities } from './components/Activities'
import { ACTIVITIES_TITLE } from './constants/status'
import { CANCEL_STAGES, DENY_STAGES, MAIN_STAGES } from './constants/stages'
import {
  type History,
  type FinancingStatusProps,
} from './FinancingStatus.types'

import * as S from './styles'

export const FinancingStatus: React.FC<FinancingStatusProps> = ({
  selectedFinancing,
}) => {
  const financingStage = selectedFinancing.stage

  const handleSelectStages = () => {
    if (financingStage === 'negado') return DENY_STAGES
    if (financingStage === 'cancelado') return CANCEL_STAGES
    return MAIN_STAGES
  }

  const timeLineStages = handleSelectStages()

  const currentTimelineStep =
    timeLineStages.findIndex((option) => option.key === financingStage) + 1

  const filterTitle = (history: History) => {
    return history.title !== ACTIVITIES_TITLE.NOT_COMPLETED_CLIENT_DATA
  }

  return (
    <S.Container>
      <Timeline options={timeLineStages} currentStep={currentTimelineStep} />
      <S.Historic>
        <Typography size="small">Histórico de atividades</Typography>
        <Box>
          {selectedFinancing.history?.filter(filterTitle)?.map((history) => (
            <Activities
              key={history.id}
              history={history}
              selectedFinancing={selectedFinancing}
            />
          ))}
        </Box>
      </S.Historic>
    </S.Container>
  )
}
