import { create } from 'zustand'
import { type ICustomerDTO } from 'dto/CustomerDTO'

interface ILeadStore {
  lead?: ICustomerDTO
  setLead: (state: ICustomerDTO) => void
  resetLead: () => void
}

const INITIAL_LEAD = {
  lead: undefined,
}

export const useSolLeadStore = create<ILeadStore>((set) => ({
  ...INITIAL_LEAD,
  setLead: (lead) => {
    set({ lead })
  },
  resetLead: () => {
    set(INITIAL_LEAD)
  },
}))
