import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  root: ({ color, colorWeight }) => ({
    display: 'flex',
    alignItems: 'center',
    color: palette[color] ? palette[color][colorWeight] : palette[color],
  }),
}))

export default useStyles
