import { Loader2, Plus } from 'lucide-react'
import { Wrapper, StyledButton } from './styles'

export interface IButtonAddProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

export function ButtonAdd({ isLoading, ...props }: IButtonAddProps) {
  return (
    <Wrapper>
      <StyledButton variant="outline" {...props}>
        {isLoading && (
          <Loader2 data-testid="loading-icon" className="spin" size={16} />
        )}
        {!isLoading && <Plus size={16} />}
      </StyledButton>
    </Wrapper>
  )
}
