import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Grid } from '@material-ui/core'
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
})

function MultiImagesUpload(props) {
  const classes = useStyles()

  const {
    imagens,
    handleSaveWorkImages,
    setConfigEditing,
    from,
    handleRemoveImage,
  } = props

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'preparing') {
      // setImageLoading(true)
      remove()
      handleSaveWorkImages(file)
    }
    if (status === 'headers_received') {
      // let newArray = imagens;
      // newArray.push(file);
      // setImagens([...newArray]);
      // setImageLoading(false)
    }
  }

  return (
    <div className={classes.paperRendered}>
      <div>
        <Typography
          component="h1"
          variant="h3"
          style={{ fontSize: '20px', marginBottom: '8px', marginTop: '16px' }}
        >
          Upload de imagem
        </Typography>
        <Typography
          component="h2"
          gutterBottom
          variant="overline"
          style={{
            fontSize: '14px',
            marginBottom: '18px',
            textTransform: 'none',
          }}
        >
          São suportados apenas arquivos em PNG, JPEG e GIF de até 10MB
        </Typography>
      </div>
      <Grid style={{ marginTop: '32px' }} spacing={2} container>
        {Array.from(
          Array(imagens.length + 1 >= 8 ? 8 : imagens.length + 1).keys(),
        ).map((image, i) => (
          <Grid item xs={6} md={6} lg={6} xl={4}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Dropzone
                maxFiles={1}
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                inputContent={
                  imagens && imagens[i] ? (
                    <div
                      style={{
                        padding: '40px 6%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative',
                        width: '100%',
                        minHeight: '60px',
                        zIndex: '1',
                        borderBottom: 'none',
                        boxSizing: 'border-box',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          alt="upload"
                          style={{
                            maxHeight: '60px',
                            width: 'auto',
                            maxWidth: '80px',
                            borderRadius: '4px',
                          }}
                          src={imagens && imagens[i]}
                        />
                      </div>
                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          handleRemoveImage(imagens[i])
                        }}
                        style={{
                          backgroundColor: 'red',
                          color: '#FFF',
                          padding: '8px 12px',
                          borderRadius: '8px',
                          textTransform: 'none',
                          fontFamily: 'NexaRegular',
                          fontWeight: '500',
                        }}
                      >
                        <DeleteRoundedIcon style={{ color: '#FFF' }} />
                      </div>
                    </div>
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '250px',
                      }}
                    >
                      <CloudUploadRoundedIcon
                        style={{ fontSize: '46px', marginBottom: '10x' }}
                      />
                      Arraste o arquivo ou clique aqui para selecionar o arquivo
                    </span>
                  )
                }
                accept="image/*"
                styles={{
                  dropzone: {
                    padding: '30px',
                    border: '2px dashed #1335c6',
                    borderRadius: '5px',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    color: '#1335c6',

                    width: '100%',
                    height: imagens && imagens[i] ? '100%' : 'auto',
                    minHeight: imagens && imagens[i] ? '200px' : '200px',
                  },
                  preview: {
                    border: 'none',
                  },
                  input: {
                    display: 'none',
                  },
                  inputLabel: {
                    color: '#1335c6',

                    fontSize: '16px',
                    padding: '16px',
                    textAlign: 'center',
                  },
                }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      {!from && (
        <Button
          onClick={() => (setConfigEditing ? setConfigEditing('') : '')}
          disabled={!imagens}
          style={{ marginTop: '32px' }}
          className={classes.filledButton}
        >
          Salvar alterações
        </Button>
      )}
    </div>
  )
}

export default MultiImagesUpload
