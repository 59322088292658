import { colors } from '@77sol-ui/tokens'
import { palette } from '@77sol/styles'
import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
interface IContainerProps {
  text?: string
}

export const Container = styled.div<IContainerProps>`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    div {
      box-sizing: content-box;
      width: 30px;
      height: 30px;
      border: 6px solid ${palette.primary[300]};
      border-top-color: ${palette.primary[200]};
      border-radius: 50%;
      animation: ${rotate} 1s linear infinite;
    }

    ${(props: IContainerProps) =>
      props.text &&
      css`
        &:after {
          content: '${props.text}';
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          font-family: DM Sans;
          color: ${colors.gray[300]};
          font-size: 15px;
          font-weight: 400;
        }
      `}
  }
`
