import * as S from './styles'

export type ButtonContainerProps = React.ComponentProps<
  typeof S.ButtonsContainer
> & {
  children: React.ReactNode
}

export const ButtonContainer = ({
  children,
  ...props
}: ButtonContainerProps) => {
  return <S.ButtonsContainer {...props}>{children}</S.ButtonsContainer>
}
