import API from 'api'
import { GET_FINANCING_DETAILS } from '../constants/enpointKeys'

export const getFinancingDetails = {
  get: async (financingId: number) => {
    if (!financingId) return
    return await API.get(`${GET_FINANCING_DETAILS}/${financingId}`).then(
      ({ data }) => data,
    )
  },
}
