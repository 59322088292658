import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import storeSynchronize from 'redux-localstore'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  ReducerAuth,
  Reducer1,
  ReducerProfile,
  ReducerQuotation,
  ReducerProModal,
} from './reducers'

export interface RootState {
  ReducerAuth: {
    userLogged: boolean
  }
  Reducer1: any
  ReducerProfile: any
  ReducerQuotation: any
  ReducerProModal: any
}

const combineReducer = combineReducers<RootState>({
  ReducerAuth,
  Reducer1,
  ReducerProfile,
  ReducerQuotation,
  ReducerProModal,
})

const store = createStore(
  combineReducer,
  composeWithDevTools(applyMiddleware()),
)

storeSynchronize(store)

export default store
