import { Button } from '@77sol/core'
import {
  PlugIcon,
  WeatherLightningIcon,
  CalendarIcon,
  ChartBarIcon,
} from '@77sol/icons/dist'
import * as S from './styles'
import useWindowSize from 'hooks/useWindowSize'
import {
  BASIC_SIZING_HEADER_TEXT,
  DETAILED_SIZING_HEADER_TEXT,
  EnumSizingType,
} from 'views/DimensionarV2/components/StepOne/constants'
import { useFormContext } from 'react-hook-form'
import { SectionHeader } from '../SectionHeader'
import { isDetailedSizing } from 'views/DimensionarV2/components/StepOne/utils'
import { useMemo } from 'react'

const smallScreensButton = {
  size: 'small',
  variant: 'text',
}

const largeScreensButton = {
  size: 'medium',
  variant: 'outlined',
}

interface ChangeCalculationMethodProps {
  optionSelected: EnumSizingType
  handleResetOptionSelected: (value: EnumSizingType | undefined) => void
}

export const ChangeCalculationMethod = ({
  optionSelected,
  handleResetOptionSelected,
}: ChangeCalculationMethodProps) => {
  const { reset } = useFormContext()
  const { width } = useWindowSize()

  const isDetailed = useMemo(
    () => isDetailedSizing(optionSelected),
    [optionSelected],
  )

  return (
    <>
      <SectionHeader
        {...(isDetailed
          ? DETAILED_SIZING_HEADER_TEXT
          : BASIC_SIZING_HEADER_TEXT)}
      />
      <S.Container>
        {optionSelected === EnumSizingType.MonthlyConsumption && (
          <S.CurrentCalculation>
            <CalendarIcon />
            <span>Consumo mensal</span>
          </S.CurrentCalculation>
        )}

        {optionSelected === EnumSizingType.ConsumptionAverage && (
          <S.CurrentCalculation>
            <WeatherLightningIcon />
            <span>Média de consumo</span>
          </S.CurrentCalculation>
        )}
        {optionSelected === EnumSizingType.SystemPower && (
          <S.CurrentCalculation>
            <PlugIcon />
            <span>Potência do sistema</span>
          </S.CurrentCalculation>
        )}
        {optionSelected === EnumSizingType.AverageConsumptionValue && (
          <S.CurrentCalculation>
            <ChartBarIcon />
            <span>Valor de consumo médio</span>
          </S.CurrentCalculation>
        )}

        {optionSelected === EnumSizingType.BasicSystemPower && (
          <S.CurrentCalculation>
            <PlugIcon />
            <span>Potência do sistema</span>
          </S.CurrentCalculation>
        )}
        {optionSelected === EnumSizingType.BasicAverageConsumptionValue && (
          <S.CurrentCalculation>
            <ChartBarIcon />
            <span>Valor de consumo médio</span>
          </S.CurrentCalculation>
        )}

        <div>
          <Button
            onClick={() => {
              reset()
              handleResetOptionSelected(undefined)
            }}
            noMargin
            {...(width > 1280 ? largeScreensButton : smallScreensButton)}
          >
            Trocar método de cálculo
          </Button>
        </div>
      </S.Container>
    </>
  )
}
