import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'

export const useDisableActionButtons = () => {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const quotations = data?.quotations || []

  const disabled = quotations.some((quotation) =>
    quotation.products.some((product) => product.has_error),
  )

  return { disabled }
}
