import * as S from './styles'
import { FinancingAndProposalsTable } from './components/FinancingAndProposalsTable'
import { SummaryRankClassification } from 'containers'
import { Carousel } from 'components'
import { Performance } from './components/Performance'
import { TooltipLockedToFaas } from '_Distributor/components/TooltipLockedToFaas'

export function Home() {
  return (
    <S.Wrapper title="Home">
      <S.Container>
        <Carousel />
        <FinancingAndProposalsTable />
        <TooltipLockedToFaas side="top">
          <SummaryRankClassification preventRedirect />
        </TooltipLockedToFaas>
        <Performance />
      </S.Container>
    </S.Wrapper>
  )
}
