import styled from 'styled-components'
import { Button as PrimitiveButton } from '@77sol-ui/atoms'

export const Button = styled(PrimitiveButton)`
  position: relative;

  > * {
    pointer-events: none;
  }

  > div {
    top: 6px;
  }
`
