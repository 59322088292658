import { GET_INTEGRATORS } from '../constants/endpointKeys'
import { integrators } from '../services/api'
import { useQuery } from '@tanstack/react-query'

export const useSearchIntegrator = (search: string) => {
  const data = useQuery({
    queryKey: [GET_INTEGRATORS, search],
    queryFn: async () => await integrators.get(search),
    retry: false,
    staleTime: 60 * 1000, // 1min
  })

  return data
}
