import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin: 16px 0;

  .form-grid {
    grid-template-rows: auto 1fr;
    grid-gap: 16px 10px;

    @media (max-width: 1280px) {
      margin-bottom: 16px;
    }
  }
`

export const Label = styled.p`
  font-size: 0.875rem;
  color: ${palette.grayscale[700]};
  letter-spacing: 0.75px;
  margin-bottom: 10px;
`
