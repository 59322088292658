import { EmptyState } from '@77sol-ui/molecules'
import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Wrapper = styled(EmptyState.Root)`
  @media (min-width: 1280px) {
    flex-direction: row;
  }

  > h3,
  svg {
    color: ${colors.gray[400]};
  }

  > h3 {
    font-family: DM Sans;
    font-size: 14px;
  }
`

export const ButtonAsLink = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.blue[300]};
  font-weight: 700;
  padding: 2px;

  &:focus-visible {
    border-radius: 4px;
    background-color: ${colors.blue[50]};
  }

  font-family: DM Sans;
  font-size: 14px;
`
