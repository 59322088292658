import { Input, InputMask } from '@77sol-ui/atoms'
import { Form, InputGroup } from './styles'
import { Select } from 'components/forms/react-hook-form/Select'
import { Percent } from 'lucide-react'
import { SelectStructureType } from './components/SelectStructureType'
import { SelectPhase } from './components/SelectPhase'
import { SelectVoltage } from './components/SelectVoltage'
import { SelectState } from './components/SelectState'
import { LEAD_TYPE } from 'utils/constants'
import { SelectAlgorithm } from './components/SelectAlgorithm'
import { useQuotationForm } from './hooks/useQuotationForm'
import { type IQuotationFormProps } from './types'
import { AutoCompleteIntegrator } from './components/AutoCompleteIntegrator'

export function QuotationForm({
  formId,
  onSubmit,
  defaultValues,
}: IQuotationFormProps) {
  const {
    register,
    handleSubmit,
    control,
    selectedPhase,
    errors,
    isAdmin,
    operationalUser,
    handleClearVoltageField,
    handleUpdateDeliveryState,
    handleAdjustDiscount,
    handleAdjustDiscountFocus,
  } = useQuotationForm({ defaultValues })

  return (
    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors.system_potency?.message}
        type="number"
        step="0.01"
        label="Potência do sistema (kWp)"
        {...register('system_potency')}
        placeholder="Insira o Kwp"
      />
      <SelectStructureType
        control={control}
        label="Tipo de estrutura"
        placeholder="Selecione o tipo de estrutura"
        name="structure_type"
        error={errors.structure_type?.message}
      />

      <InputGroup>
        <SelectPhase
          control={control}
          label="Fase"
          placeholder="Selecione a fase"
          name="power_fase"
          required={false}
          onValueChange={() => {
            handleClearVoltageField()
          }}
          error={errors?.power_fase?.message}
        />
        <SelectVoltage
          control={control}
          label="Tensão"
          placeholder="Selecione a tensão"
          name="voltage"
          currentPhase={selectedPhase}
          required={false}
          error={errors?.voltage?.message}
        />
      </InputGroup>

      {isAdmin && (
        <AutoCompleteIntegrator
          control={control}
          name="integrator"
          autoComplete="off"
          contentSide="top"
          offset={-20}
          error={errors.integrator?.id?.message}
          defaultValue={defaultValues?.integrator?.name}
          onChange={(integrator) => {
            handleUpdateDeliveryState(integrator?.uf)
          }}
        />
      )}

      <SelectState
        control={control}
        label="Estado de entrega do produto"
        placeholder="Selecione o estado"
        name="delivery_state"
        side="top"
        maxHeight="280px"
        error={errors.delivery_state?.message}
      />

      {isAdmin && (
        <>
          <InputGroup>
            <Select
              control={control}
              label="Origem do Lead"
              placeholder="Selecione o lead"
              name="origin_lead"
              error={errors.origin_lead?.message}
              defaultValue={LEAD_TYPE[0].value}
              options={LEAD_TYPE}
            />

            <InputMask.Percentage
              label="Desconto"
              icon={Percent}
              {...register('discount')}
              min={0}
              max={100}
              onChange={(e) => {
                handleAdjustDiscount(e.target.value)
              }}
              onBlur={(e) => {
                handleAdjustDiscountFocus(e.target.value)
              }}
              required={false}
              error={errors.discount?.message}
              defaultValue={defaultValues?.discount}
              placeholder="Insira o valor do desconto"
            />
          </InputGroup>
        </>
      )}

      {operationalUser && (
        <SelectAlgorithm
          control={control}
          label="Algoritmo de formação de kit"
          placeholder="Selecione o algoritmo"
          name="type_test"
          side="top"
          error={errors.type_test?.message}
        />
      )}
    </Form>
  )
}
