import { useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useParams() {
  const location = useLocation()
  const history = useHistory()

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )

  const clearParams = () => {
    const paramsKeys = Array.from(params.keys())
    for (const param of paramsKeys) {
      params.delete(param)
    }
  }

  return {
    location,
    history,
    params,
    clearParams,
  }
}
