import { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, useMediaQuery } from '@material-ui/core'
import { BlueBoxProposalAnchor } from './components/BlueBoxProposalAnchor'
import { ProductDetail } from './components/ProductDetail'
import { Stock } from './components/Stock'
import './style.css'
import { useEquipmentsStore } from './hooks/useEquipmentsStore'
import { Filters } from './components/Filters'
import { Title } from 'components/Title'
import { Container, Content } from './styles'

export function EquipmentsStore({ className, ...rest }) {
  const [openSidebarProduct, setOpenSidebarProduct] = useState(false)
  const [productViewDetail, setProductViewDetail] = useState([])
  const [displayBlueBoxCreateProposal, setDisplayBlueBoxCreateProposal] =
    useState(true)

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const {
    totalProducts,
    filteredProdutosNovaLoja,
    loadingProdutosNovaLoja,
    hasMoreFilteredProducts,
    updatePageFilteredProducts,
    handleFilterProducts,
  } = useEquipmentsStore()

  return (
    <Container {...rest} className={clsx(className)}>
      <Content>
        <Box display="flex" height="100%">
          <Box
            px={downSm && openSidebarProduct ? 0 : 4}
            py={downSm && openSidebarProduct ? 0 : 2}
            flex={1}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <Title id="equipments-title" text="Equipamentos" />
            <Filters
              onFilterProducts={handleFilterProducts}
              openSidebarProduct={openSidebarProduct}
              setOpenSidebarProduct={setOpenSidebarProduct}
            />

            {displayBlueBoxCreateProposal && (
              <BlueBoxProposalAnchor
                setDisplayBlueBoxCreateProposal={
                  setDisplayBlueBoxCreateProposal
                }
              />
            )}

            <Stock
              loadingProdutosNovaLoja={loadingProdutosNovaLoja}
              totalProducts={totalProducts}
              filteredProdutosNovaLoja={filteredProdutosNovaLoja}
              updatePageFilteredProducts={updatePageFilteredProducts}
              hasMoreFilteredProducts={hasMoreFilteredProducts}
              setOpenSidebarProduct={setOpenSidebarProduct}
              setProductViewDetail={setProductViewDetail}
            />
          </Box>
          <ProductDetail
            openSidebarProduct={openSidebarProduct}
            setOpenSidebarProduct={setOpenSidebarProduct}
            productViewDetail={productViewDetail}
          />
        </Box>
      </Content>
    </Container>
  )
}

EquipmentsStore.propTypes = {
  className: PropTypes.string,
}
