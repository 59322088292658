import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
