/* eslint-disable @typescript-eslint/no-explicit-any */
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { type Control } from 'react-hook-form'
import { useFinancingStatusDropdown } from './hooks/useFinancingStatusDropdown'

interface IFinancingStatusDropdownProps {
  value?: string | number
  control: Control<any>
  className?: string
}

export function FinancingStatusDropdown({
  value,
  control,
  className,
}: IFinancingStatusDropdownProps) {
  const { defaultOption, financingStatus } = useFinancingStatusDropdown()

  return (
    <DropdownControlled
      value={value}
      control={control}
      name="status"
      label="Status"
      options={[defaultOption, ...financingStatus]}
      fullWidth
      className={className}
    />
  )
}
