const { makeStyles } = require('@material-ui/core')

export const useStyles = makeStyles((theme) => ({
  paperModalCards: {
    '@media (min-width:350px)': {
      backgroundColor: theme.palette.background.paper,
      padding: '38px',
      borderRadius: '8px',
      border: 'none',
      width: '80vw',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
    },
    '@media (min-width:1250px)': {
      backgroundColor: theme.palette.background.paper,
      padding: '38px',
      borderRadius: '8px',
      border: 'none',
      width: '70vw',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
    },
  },
  filledButton: {
    background: 'linear-gradient(to right, #eb0000 0%, #ff4c4c 100%)',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(to right, #eb0000 0%, #ff4c4c 100%)',
      borderRadius: '8px',
    },
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  selectedPaper: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    padding: '16px',
    cursor: 'pointer',
    width: '100%',
    height: '140px',
    borderRadius: '8px',
  },
  notSelectedPaper: {
    border: '1px solid #1335c6',
    padding: '16px',
    cursor: 'pointer',
    width: '100%',
    height: '140px',
    borderRadius: '8px',
  },
  titleSelected: {
    fontSize: '16px',
    color: '#FFF',
    marginBottom: '8px',
  },
  titleNotSelected: {
    fontSize: '16px',
    color: '#464F5B',
    marginBottom: '8px',
  },
  subtitleSelected: {
    fontSize: '14px',
    color: '#FFF',
    marginBottom: '4px',
  },
  subtitleNotSelected: {
    fontSize: '14px',
    color: '#464F5B',
    marginBottom: '4px',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
