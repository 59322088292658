import React, { useState, useEffect } from 'react'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { ResultPaper, Input, Typography } from 'components'
import { Spacer, Button, Paper, useToast } from '@77sol/core'
import palette from 'app_palette'
import * as ProposalAction from 'store/actions'
import { setStructureTypes } from 'store/actions/quotation'
import { handleUnit } from 'utils'
import API from 'api'
import { useAmplitude } from 'hooks/useAmplitude'
import { usePlan } from 'hooks/usePlan'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import {
  PotenciaCard,
  ConsumoCard,
  AzimuteCard,
  FinancialStats,
  ProductionCardAbridged,
} from './components'

const useStyles = makeStyles({
  mainContainer: ({ sizingEdit }) => ({
    position: 'relative',
    '@media (min-width:350px)': {
      width: '100%',
    },
    '@media (min-width:1880px)': {
      width: sizingEdit ? '100%' : '80%',
    },
  }),
})

function ResultadoDimensionamento({
  resumido,
  showButtons,
  setActiveDimStep,
  sizingEdit,
  setSizingModal,
  hideTopMenu,
  fromDragAndDrop,
  setLoadingCreateQuotation,
}) {
  const classes = useStyles({ resumido, sizingEdit })
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const downXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const { logEvent } = useAmplitude()

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  // Redux
  const proposalReal = useSelector((state) => state.Reducer1)
  const proposal = useSelector((state) =>
    state?.Reducer1?.sizings?.find(
      (sizing) =>
        sizing?.id ==
        (sizingEdit ? sizingEdit.id : state?.Reducer1?.activeSizing),
    ),
  )

  const { leadType } = useSelector((state) => state.Reducer1)

  const quotes = useSelector((state) => state.Reducer1.quotes)
  const dispatch = useDispatch()

  const [loadingIrradiancia, setLoadingIrradiancia] = useState(false)
  const [loadingPerformanceRatio, setLoadingPerformanceRatio] = useState(false)
  const [loadingLocal, setLoadingLocal] = useState(false)
  const [othersLoading, setOthersLoading] = useState(false)
  const [currentIrradiance, setCurrentIrradiance] = useState(
    proposal.planoIrradiancia,
  )

  const { isPro } = usePlan()

  const [loadingCreateQuote, setLoadingCreateQuote] = useState(false)

  const handleChangeLock = (potency) => {
    API.post('/proposal/dimensioning/lock', {
      lock: potency,
      dimensioning_id: proposal.id,
    })
      .then((res) => {
        setLocked(potency)
        dispatch(ProposalAction.UpdateSizingRedux(res.data.dimensioning))
        onSuccessOpenToast('Trava alterada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar trava!')
      })
  }

  const handleCreateQuote = () => {
    logEvent(proposalTracker.actions.createEquipmentQuote)
    setLoadingCreateQuote(true)
    setLoadingCreateQuotation(true)

    API.post('/proposal/create/dimensioning/quotation', {
      potency: proposal.potenciaSistema,
      structure: proposal.estrutura,
      dimensioning_id: proposal.id,
      origin_lead: leadType || 'INTEGRADOR',
      quotations_quantity: 3,
    })
      .then((res) => {
        for (const quotation of res.data) {
          dispatch(ProposalAction.SaveQuoteRedux(quotation))
        }
        setActiveDimStep(2)
        if (
          parseInt(proposalReal.activeStep.toString().replace(/[^0-9]/g, '')) <=
          2
        ) {
          dispatch(ProposalAction.SaveStepRedux('2'))
        }
        setLoadingCreateQuote(false)
        setLoadingCreateQuotation(false)
        localStorage.setItem('showTidio', false)
        API.get(`/proposal/quotation/roi/calculate/${res.data[0].quotation.id}`)
        API.post('/proposal/quotation/budget/finalized', {
          quotation_id: res.data[0].quotation.id,
        }).then(() => {
          dispatch(ProposalAction.UpdateQuoteDataRedux(res.data[0].quotation))
        })
      })
      .catch(({ response }) => {
        const { message = '' } = response?.data || {}
        setLoadingCreateQuotation(false)
        setLoadingCreateQuote(false)
        onErrorOpenToast(message || 'Não foi possível criar a cotação!')
      })
  }

  const [performanceRatio, setPerformanceRatio] = useState(
    proposal.performanceRatio * 100,
  )

  const handleChangePerformanceRatio = (value) => {
    if (!isPro()) {
      return
    }

    setLoadingPerformanceRatio(true)

    API.post('/proposal/dimensioning/update/performance_ratio', {
      dimensioning_id: proposal.id,
      performance_ratio: value,
      potency: proposal.potenciaCC / 1000,
    })
      .then((res) => {
        setPerformanceRatio(value)
        setLoadingPerformanceRatio(false)
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        onSuccessOpenToast('Coeficiente de performance alterado com sucesso!')
      })
      .catch(() => {
        setLoadingPerformanceRatio(false)
        onErrorOpenToast(
          'Não foi possível alterar o coeficiente de performance!',
        )
      })
  }

  const [local, setLocal] = useState(proposal.estrutura)

  const structureLabel = (value) => {
    const labels = {
      'fibrocimento-madeira': 'Fibrocimento (Madeira)',
      'fibrocimento-metalico': 'Fibrocimento (Metálico)',
      ceramico: 'Cerâmico',
      metalico: 'Metalico',
      laje: 'Laje',
      solo: 'Solo',
    }

    return labels[value] || 'Sem estrutura'
  }

  const handleChangeLocal = (value) => {
    setLoadingLocal(true)

    API.post('/proposal/dimensioning/update/local', {
      dimensioning_id: proposal.id,
      structure: value,
    })
      .then((res) => {
        setLocal(value)
        setLoadingLocal(false)
        dispatch(
          ProposalAction.UpdateSizingRedux(res.data.dimensioning_properties),
        )
        onSuccessOpenToast('Local de instalação alterado com sucesso!')
      })
      .catch(() => {
        setLoadingLocal(false)
        onErrorOpenToast('Não foi possível alterar o local de instalação!')
      })
  }

  const handleChangeIrradiancia = (value) => {
    setCurrentIrradiance(value)

    if (!isPro()) {
      return setCurrentIrradiance(proposal.planoIrradiancia || 'inclinado')
    }

    setLoadingIrradiancia(true)

    API.post('/proposal/dimensioning/update/irradiance', {
      dimensioning_id: proposal.id,
      plane: value,
      potency: proposal.potenciaCC / 1000,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        onSuccessOpenToast('Irradiância alterada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar a irradiância!')
      })
      .finally(() => setLoadingIrradiancia(false))
  }

  const [showAzimute, setShowAzimute] = useState(
    proposal?.posicaoSolInstalacao != 'norte',
  )
  const [locked, setLocked] = useState(proposal?.lock)
  const [actualEdit, setActualEdit] = useState()

  const structureTypes = useSelector(
    (state) => state.ReducerQuotation.structureTypes,
  )

  const getStructureTypes = () => {
    if (structureTypes.length <= 0) {
      API.get('/admin/structure-types-products').then((res) => {
        dispatch(setStructureTypes(res.data))
      })
    }
  }

  useEffect(() => {
    getStructureTypes()
  }, [])

  const handleAddLossRelatedAzimuthalAngle = () => {
    if (!isPro()) {
      logEvent(proposalTracker.actions.AddLossRelatedToAzimuthalAngleNotProUser)
    } else {
      logEvent(proposalTracker.actions.AddLossRelatedToAzimuthalAngle)
      setShowAzimute(true)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={!resumido && classes.mainContainer}>
        {!resumido && (
          <>
            {sizingEdit ? (
              !hideTopMenu && (
                <Grid spacing={2} container>
                  <Grid item xs={12} sm={12} md={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        type={downSm ? 'link_small' : 'display_x_small_bold'}
                        color="primary"
                        colorWeight="300"
                      >
                        Editar dimensionamento
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <Button
                          noMargin
                          size="small"
                          color="primary"
                          onClick={() => setSizingModal('')}
                          disabled={
                            loadingIrradiancia ||
                            loadingLocal ||
                            loadingPerformanceRatio ||
                            othersLoading
                          }
                        >
                          Confirmar alterações
                        </Button>
                        <Spacer direction="vertical" size="16" />
                        <Button
                          noMargin
                          size="small"
                          color="red"
                          variant="outlined"
                          onClick={() => setSizingModal('')}
                          disabled={
                            loadingIrradiancia ||
                            loadingLocal ||
                            loadingPerformanceRatio ||
                            othersLoading
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid spacing={2} container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    type={downSm ? 'link_small' : 'display_x_small_bold'}
                    color="grayscale"
                    colorWeight="700"
                  >
                    {resumido ? 'Dimensionamento' : 'Dados de dimensionamento'}
                  </Typography>
                  <Spacer size="4" direction="horizontal" />
                  {!resumido && (
                    <Typography
                      type={downSm ? 'text_x_small' : 'text_small'}
                      color="grayscale"
                      colorWeight="600"
                    >
                      Resultado calculado a partir de dados inseridos
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            <Spacer size="24" direction="horizontal" />
          </>
        )}
        <div
          style={{
            height: fromDragAndDrop
              ? '100%'
              : downXs && !sizingEdit
              ? ''
              : sizingEdit
              ? downSm
                ? 'calc(var(--app-height) - 16px)'
                : 'calc(var(--app-height) - 242px)'
              : 'calc(var(--app-height) - 302px)',
            overflowY: fromDragAndDrop
              ? 'hidden'
              : downXs && !sizingEdit
              ? 'hidden'
              : 'auto',
            overflowX: 'hidden',
          }}
        >
          <Grid spacing={2} container>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 4}
              lg={resumido ? 12 : 4}
              xl={resumido ? 12 : 3}
            >
              <PotenciaCard
                actualEdit={actualEdit}
                setActualEdit={setActualEdit}
                locked={locked}
                setLocked={setLocked}
                showButtons={showButtons}
                proposal={proposal}
                handleChangeLock={handleChangeLock}
                sizingEdit={sizingEdit}
                setOthersLoading={setOthersLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 8}
              lg={resumido ? 12 : 8}
              xl={resumido ? 12 : 9}
            >
              <ConsumoCard
                actualEdit={actualEdit}
                setActualEdit={setActualEdit}
                locked={locked}
                setLocked={setLocked}
                showButtons={showButtons}
                proposal={proposal}
                resumido={downSm ? true : !!resumido}
                handleChangeLock={handleChangeLock}
                sizingEdit={sizingEdit}
                setOthersLoading={setOthersLoading}
              />
            </Grid>
            {(resumido || downSm) && (
              <Grid
                item
                xs={12}
                sm={resumido ? 12 : 12}
                md={resumido ? 12 : 8}
                lg={resumido ? 12 : 8}
                xl={resumido ? 12 : 9}
              >
                <ProductionCardAbridged
                  actualEdit={actualEdit}
                  setActualEdit={setActualEdit}
                  locked={locked}
                  setLocked={setLocked}
                  showButtons={showButtons}
                  proposal={proposal}
                  resumido={downSm ? true : !!resumido}
                  sizingEdit={sizingEdit}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 4}
              lg={resumido ? 12 : 4}
              xl={resumido ? 12 : 4}
            >
              <ResultPaper
                autoHeight
                title="Irradiância média"
                firstText={proposal.energiaSolarMedia}
                secondText="UV"
                type="select"
                bordered={sizingEdit}
              >
                {showButtons == false ? (
                  ''
                ) : (
                  <Input
                    select
                    value={currentIrradiance}
                    setValue={handleChangeIrradiancia}
                    size="small"
                    loading={loadingIrradiancia}
                  >
                    <option value="inclinado">Plano inclinado</option>
                    <option value="horizontal">Plano horizontal</option>
                  </Input>
                )}
              </ResultPaper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 4}
              lg={resumido ? 12 : 4}
              xl={resumido ? 12 : 4}
            >
              <ResultPaper
                autoHeight
                title="Coeficiente de performance"
                firstText={proposal.performanceRatio * 100}
                secondText="%"
                type="select"
                bordered={sizingEdit}
              >
                {showButtons == false ? (
                  ''
                ) : (
                  <Input
                    size="small"
                    setValue={handleChangePerformanceRatio}
                    value={performanceRatio}
                    select
                    loading={loadingPerformanceRatio}
                  >
                    <option value="70">70%</option>
                    <option value="71">71%</option>
                    <option value="72">72%</option>
                    <option value="73">73%</option>
                    <option value="74">74%</option>
                    <option value="75">75%</option>
                    <option value="76">76%</option>
                    <option value="77">77%</option>
                    <option value="78">78%</option>
                    <option value="79">79%</option>
                    <option value="80">80%</option>
                    <option value="81">81%</option>
                    <option value="82">82% (Recomendado)</option>
                    <option value="83">83%</option>
                    <option value="84">84%</option>
                    <option value="85">85%</option>
                    <option value="86">86%</option>
                    <option value="87">87%</option>
                    <option value="88">88%</option>
                    <option value="89">89%</option>
                    <option value="90">90%</option>
                  </Input>
                )}
              </ResultPaper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 4}
              lg={resumido ? 12 : 4}
              xl={resumido ? 12 : 4}
            >
              <ResultPaper
                autoHeight
                title="Local da instalação"
                firstText={structureLabel(proposal.estrutura)}
                type="select"
                sizeText="small"
                bordered={sizingEdit}
              >
                {showButtons == false ? (
                  ''
                ) : (
                  <Input
                    select
                    value={local}
                    setValue={handleChangeLocal}
                    size="small"
                    loading={loadingLocal}
                  >
                    {structureTypes.map((option) => (
                      <option key={option.id} value={option.slug}>
                        {option.name}
                      </option>
                    ))}
                  </Input>
                )}
              </ResultPaper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 3}
              lg={resumido ? 12 : 3}
              xl={resumido ? 12 : 3}
            >
              <ResultPaper
                autoHeight
                title="Área ocupada"
                firstText={proposal.areaOcupada}
                secondText="m²"
                type="show"
                bordered={sizingEdit}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 3}
              lg={resumido ? 12 : 3}
              xl={resumido ? 12 : 3}
            >
              <ResultPaper
                autoHeight
                title="Produção específica"
                firstText={proposal.producaoEspecifica.toFixed(2)}
                secondText="kWh/kWp/ano"
                type="show"
                bordered={sizingEdit}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 3}
              lg={resumido ? 12 : 3}
              xl={resumido ? 12 : 3}
            >
              <ResultPaper
                autoHeight
                title="CEP da instalação"
                firstText={
                  proposal.cep.indexOf('-') != -1
                    ? proposal.cep
                    : `${proposal.cep.substr(0, 5)}-${proposal.cep.substr(
                        5,
                        7,
                      )}`
                }
                type="show"
                bordered={sizingEdit}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={resumido ? 12 : 12}
              md={resumido ? 12 : 3}
              lg={resumido ? 12 : 3}
              xl={resumido ? 12 : 3}
            >
              <ResultPaper
                autoHeight
                title="Quantidade de módulos"
                firstText={proposal.numeroPlacas}
                secondText="unidades"
                type="show"
                bordered={sizingEdit}
              />
            </Grid>
          </Grid>
          <Spacer size="16" direction="horizontal" />
          <Grid spacing={2} container>
            {!showAzimute && (
              <Grid style={{ justifyContent: 'center' }} item xs={12} md={12}>
                <Button
                  onClick={() => handleAddLossRelatedAzimuthalAngle()}
                  size={downSm ? 'small' : 'medium'}
                  noMargin
                  fullWidth
                >
                  Adicionar perda relacionada ao ângulo azimutal
                </Button>
              </Grid>
            )}
            {showAzimute && (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <AzimuteCard
                  setShowAzimute={setShowAzimute}
                  resumido={downSm ? true : !!resumido}
                  showButtons={showButtons}
                  proposal={proposal}
                  sizingEdit={sizingEdit}
                  setOthersLoading={setOthersLoading}
                  locked={locked}
                />
              </Grid>
            )}
          </Grid>
          <Spacer size="16" direction="horizontal" />
          <Grid spacing={2} container>
            {!resumido && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper
                  style={{
                    border: sizingEdit
                      ? `2px solid ${palette.grayscale[300]}`
                      : 'none',
                    boxShadow: sizingEdit
                      ? 'none'
                      : 'rgb(0 0 0 / 4%) 0px 4px 6px',
                    marginBottom: downXs && '90px',
                  }}
                >
                  <Typography
                    type={downSm ? 'link_small' : 'text_medium'}
                    color="grayscale"
                    colorWeight="700"
                  >
                    Consumo e produção mensal
                  </Typography>
                  <Spacer size="16" direction="horizontal" />
                  <FinancialStats proposal={proposal} />
                </Paper>
              </Grid>
            )}
          </Grid>
          {!sizingEdit && (
            <>{!downSm && <Spacer size="52" direction="horizontal" />}</>
          )}
        </div>
      </div>
      {!sizingEdit &&
        (downSm ? (
          <>
            <Spacer size="48" direction="horizontal" />
            <Grid spacing={2} container>
              {!resumido &&
              quotes.filter((quote) => quote.dimensioning.id == proposal.id)
                .length == 0 ? (
                <Grid item xs={12} md={12}>
                  <Box
                    position={downXs && 'fixed'}
                    bottom="0px"
                    left="0px"
                    padding={downXs && '0px 32px'}
                    width="100%"
                    height="65px"
                    style={{ backgroundColor: palette.grayscale[100] }}
                  >
                    <Button
                      onClick={handleCreateQuote}
                      size={downSm ? 'small' : 'medium'}
                      noMargin
                      fullWidth={!!downSm}
                      isLoading={loadingCreateQuote}
                    >
                      Fazer cotação de equipamentos
                    </Button>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} md={12}>
                  <Button
                    onClick={() => setActiveDimStep(2)}
                    size={downSm ? 'small' : 'medium'}
                    noMargin
                    fullWidth={!!downSm}
                    style={{ justifyContent: downSm && 'center' }}
                  >
                    Avançar
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Paper
            style={{
              flexDirection: 'column',
              borderRadius: '8px 8px 0px 0px',
              bottom: '0px',
              left: '0px',
              display: 'flex',
              height: '98px',
              zIndex: 99,
              transition: 'height 0.3s ease-in-out 0s',
              justifyContent: 'flex-end',
              marginLeft: '-32px',
              width: 'calc(100% + 64px)',
              boxShadow: 'rgb(0 0 0 / 4%) 0px 4px 6px',
            }}
          >
            <Grid spacing={2} container>
              {!resumido &&
              quotes.filter((quote) => quote.dimensioning.id == proposal.id)
                .length == 0 ? (
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    type={downSm ? 'text_small' : 'text_medium'}
                    color="grayscale"
                    colorWeight="700"
                  >
                    Potência:{' '}
                    <b>{handleUnit(proposal.potenciaCC / 1000).value}</b>{' '}
                    {handleUnit(proposal.potenciaCC / 1000).label}
                    Wp | Consumo:
                    <b>{proposal.consumoMensalMedio.toFixed(0)}</b> kWh/mês
                  </Typography>
                  <Button
                    onClick={handleCreateQuote}
                    size="small"
                    noMargin
                    fullWidth={!!downSm}
                    isLoading={loadingCreateQuote}
                    style={{ marginRight: '8px' }}
                  >
                    Fazer cotação de equipamentos
                  </Button>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    type={downSm ? 'text_small' : 'text_medium'}
                    color="grayscale"
                    colorWeight="700"
                  >
                    {`Potência: ${
                      handleUnit(proposal.potenciaCC / 1000).value
                    }  ${
                      handleUnit(proposal.potenciaCC / 1000).label
                    } Wp | Consumo: ${proposal.consumoMensalMedio.toFixed(
                      0,
                    )} kWh/mês`}
                  </Typography>
                  <Button
                    onClick={() => setActiveDimStep(2)}
                    size="small"
                    noMargin
                    fullWidth={!!downSm}
                    style={{
                      justifyContent: downSm && 'center',
                      marginRight: '8px',
                    }}
                  >
                    Avançar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        ))}
    </div>
  )
}

export default ResultadoDimensionamento
