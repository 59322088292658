import { apiV2 } from 'apis/apiV2'
import { DASHBOARD_ANALYSIS_SUSTAINABILITY } from '../constants/endpointKeys'
import {
  type IGetSustainabilityRequest,
  type IResponseGetSustainabilityData,
} from './types'

export const getSustainabilityData = async ({
  params,
}: IGetSustainabilityRequest): Promise<IResponseGetSustainabilityData> => {
  return await apiV2
    .get(DASHBOARD_ANALYSIS_SUSTAINABILITY, {
      params,
    })
    .then((response) => {
      return response.data
    })
}
