import React, { useState, useEffect } from 'react'
import {
  Grid,
  Divider,
  Modal,
  useMediaQuery,
  Backdrop,
  Fade,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ArrowChevronBackIcon, FilterIcon } from '@77sol/icons/dist'
import { Button, Spacer, Paper, Badge } from '@77sol/core'
import { EmptyState, Typography, Input, Radio } from 'components'
import palette from 'app_palette'
import InfiniteScroll from 'react-infinite-scroll-component'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import API from 'api'
import { useIsMount } from './useIsMount'
import { useStyles } from './styles'
import { useFetchEquipmentsCategories } from 'domains/quotation/services/hooks/useFetchEquipmentsCategories'

const CATEGORY_ITEMS_TO_REMOVE = ['Inversor', 'Micro inversor', 'Módulo']

function ModalAdicionarProduto({
  modalAdicionarProduto,
  setModalAdicionarProduto,
  brands,
  handleAddProduct,
  actualQuote,
  loadingAddProduct,
  integratorInfos,
}) {
  const classes = useStyles()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const sessionIdStorage = localStorage.getItem('sessionDataId')

  const tokenRegister = localStorage.getItem('sessionToken')
  const isMount = useIsMount()

  const [categoryFilter, setCategoryFilter] = useState()
  const [nameFilter, setNameFilter] = useState()
  const [brandFilter, setBrandFilter] = useState()
  const [changingProduct, setChangingProduct] = useState()
  const [filtering, setFiltering] = useState(false)
  const [hasMoreProducts, setHasMoreProducts] = useState(true)
  const [page, setPage] = useState(1)
  const [loadingProducts, setLoadingProducts] = useState(true)

  const [mobileInputs, setMobileInputs] = useState({
    name: '',
    brand: '',
    category: '',
  })

  const [products, setProducts] = useState([])

  const getProducts = (firstPage) => {
    if (firstPage) {
      setLoadingProducts(true)
    }

    let url = `/loja/get-produtos?quotation_id=${actualQuote.quotation.id}`
    url += `${'&best_option=true'}`
    url += `&page=${firstPage ? 1 : page}`
    url += `&category=${categoryFilter || ''}`
    url += `&brand=${brandFilter || ''}`
    url += `${nameFilter ? `&keyword=${nameFilter}` : ''}`

    API.get(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        setPage(res.data.current_page + 1)
        if (firstPage) {
          setProducts([...res.data.data])
        } else {
          setProducts((prod) => [...prod, ...res.data.data])
        }
        if (firstPage) {
          setLoadingProducts(false)
        }
        setHasMoreProducts(res.data.current_page < res.data.last_page)
      })
      .catch(() => {
        setLoadingProducts(false)
      })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isMount) {
        setPage(1)
        getProducts(true)
      }
    }, 2000)

    return () => clearTimeout(delayDebounceFn)
  }, [nameFilter])

  useEffect(() => {
    if (!isMount) {
      setPage(1)
      getProducts(true)
    }
  }, [brandFilter, categoryFilter])

  useEffect(() => {
    if (modalAdicionarProduto) {
      setPage(1)
      getProducts(true)
    }
  }, [modalAdicionarProduto])

  const { data: categoriesData = [] } = useFetchEquipmentsCategories()

  const categories = categoriesData.filter(
    (item) => !CATEGORY_ITEMS_TO_REMOVE.includes(item.nome),
  )

  return (
    <Modal
      id="modal-add-equipment"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={modalAdicionarProduto}
      onClose={() => {
        setModalAdicionarProduto(false)
        setNameFilter('')
        setCategoryFilter('')
        setBrandFilter('')
        setChangingProduct('')
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalAdicionarProduto} style={{ border: 'none' }}>
        {downSm ? (
          <div className={classes.paperModalAlterar}>
            {filtering ? (
              <>
                <Paper
                  style={{
                    backgroundColor: palette.primary[300],
                    height: 'auto',
                    borderRadius: '0px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      color: 'white',
                    }}
                    onClick={() => {
                      setFiltering(false)
                    }}
                  >
                    <ArrowChevronBackIcon width="24" color={palette.white} />
                    <Spacer size="8" direction="vertical" />
                    <Typography type="link_small" color="white">
                      Filtros
                    </Typography>
                  </div>
                </Paper>
                <div style={{ overflowY: 'auto', marginTop: '60px' }}>
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="500"
                  >
                    Filtros
                  </Typography>
                  <Spacer size="16" direction="horizontal" />
                  <Input
                    size="small"
                    label="Busca por nome"
                    value={mobileInputs.name}
                    setValue={(value) =>
                      setMobileInputs({ ...mobileInputs, name: value })
                    }
                  />
                  <Spacer size="16" direction="horizontal" />
                  <Typography
                    type="text_small"
                    color="black"
                    style={{ paddingLeft: '24px' }}
                  >
                    Categoria
                  </Typography>
                  <Spacer size="8" direction="horizontal" />
                  <Input
                    size="small"
                    select
                    value={mobileInputs.category}
                    setValue={(value) =>
                      setMobileInputs({ ...mobileInputs, category: value })
                    }
                  >
                    <option key="" value="">
                      Selecione...
                    </option>
                    {categories?.map((categorie) => (
                      <option key={categorie.id} value={categorie.id}>
                        {categorie.nome}
                      </option>
                    ))}
                  </Input>
                  <Spacer size="16" direction="horizontal" />
                  <Typography
                    type="text_small"
                    color="black"
                    style={{ paddingLeft: '24px' }}
                  >
                    Marca
                  </Typography>
                  <Spacer size="8" direction="horizontal" />
                  <Input
                    size="small"
                    select
                    value={mobileInputs.brand}
                    setValue={(value) =>
                      setMobileInputs({ ...mobileInputs, brand: value })
                    }
                    SelectProps={{ native: true }}
                  >
                    <option key="" value="">
                      Selecione...
                    </option>
                    {categoryFilter ? (
                      <>
                        {brands?.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.nome}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {brands?.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.nome}
                          </option>
                        ))}
                      </>
                    )}
                  </Input>
                  <Spacer size="24" direction="horizontal" />
                  <Button
                    noMargin
                    fullWidth
                    style={{ justifyContent: 'center' }}
                    onClick={() => {
                      setBrandFilter(mobileInputs.brand)
                      setNameFilter(mobileInputs.name)
                      setCategoryFilter(mobileInputs.category)
                      setFiltering(false)
                    }}
                  >
                    Aplicar filtros
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Paper
                  style={{
                    backgroundColor: palette.primary[300],
                    height: 'auto',
                    borderRadius: '0px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      color: 'white',
                    }}
                    onClick={() => {
                      setModalAdicionarProduto(false)
                      setNameFilter('')
                      setCategoryFilter('')
                      setBrandFilter('')
                      setChangingProduct('')
                    }}
                  >
                    <ArrowChevronBackIcon width="24" color={palette.white} />
                    <Spacer size="8" direction="vertical" />
                    <Typography type="link_small" color="white">
                      Adicionar produto
                    </Typography>
                  </div>
                  <FilterIcon
                    width="24"
                    color={palette.white}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setFiltering(true)}
                  />
                </Paper>
                <div style={{ overflowY: 'auto', marginTop: '60px' }}>
                  <Grid style={{ height: '100%' }} container>
                    <Grid style={{ height: '100%' }} item xs={12}>
                      {products?.length == 0 ? (
                        <EmptyState text="Nenhum produto encontrado." />
                      ) : (
                        <>
                          <InfiniteScroll
                            next={getProducts}
                            dataLength={products?.length}
                            hasMore={hasMoreProducts}
                            height="100%"
                            loader={
                              <div>
                                <p
                                  style={{
                                    textAlign: 'center',
                                    padding: '16px',
                                    marginBottom: '24px',
                                  }}
                                >
                                  <b> Carregando...</b>
                                </p>
                              </div>
                            }
                          >
                            {products?.map((product, i) => (
                              <Grid
                                key={i}
                                item
                                xs={12}
                                style={{ marginBottom: '8px' }}
                              >
                                <Paper
                                  style={{ padding: '16px', display: 'flex' }}
                                >
                                  {loadingAddProduct &&
                                  changingProduct == product.id ? (
                                    <div style={{ padding: '8px' }}>
                                      <CircularProgress
                                        disableShrink
                                        style={{ color: palette.primary[300] }}
                                        size={32}
                                        thickness={4}
                                      />
                                    </div>
                                  ) : (
                                    <Radio
                                      color="primary"
                                      checked={changingProduct == product.id}
                                      setChecked={() => null}
                                      onClick={() => {
                                        handleAddProduct(product, actualQuote)
                                        setChangingProduct(product.id)
                                      }}
                                    />
                                  )}
                                  <Spacer size="16" direction="horizontal" />
                                  <div>
                                    <Typography
                                      type="link_x_small"
                                      color="primary"
                                      colorWeight="300"
                                    >
                                      {product?.nome}{' '}
                                      {integratorInfos?.privilegios_admin
                                        ? currentEnterpriseConfigs.formatProductName(
                                            product?.preco,
                                          )
                                        : ''}
                                    </Typography>
                                    {product?.nome_fornecedor &&
                                    sessionIdStorage == '4282' ? (
                                      <>
                                        <Spacer
                                          size="8"
                                          direction="horizontal"
                                        />
                                        <Badge
                                          color="orange"
                                          variant="contained"
                                          label={product?.nome_fornecedor}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {product?.garantia ? (
                                      <>
                                        <Spacer
                                          size="8"
                                          direction="horizontal"
                                        />
                                        <Badge
                                          color="primary"
                                          variant="contained"
                                          label={`${product.garantia} anos de garantia`}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {product?.visivel == 0 ? (
                                      <>
                                        <Spacer
                                          size="8"
                                          direction="horizontal"
                                        />
                                        <Badge
                                          color="grayscale"
                                          variant="contained"
                                          label="Inativo"
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </Paper>
                              </Grid>
                            ))}
                          </InfiniteScroll>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={classes.paperModalAlterar}>
            <Grid spacing={3} container style={{ padding: '0px 20px' }}>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                item
                xs={12}
                md={12}
              >
                <Typography
                  type="display_x_small_x_bold"
                  color="primary"
                  colorWeight="300"
                >
                  Adicionar produto
                </Typography>
                <Button
                  onClick={() => {
                    setModalAdicionarProduto(false)
                    setCategoryFilter('')
                    setNameFilter('')
                    setBrandFilter('')
                    setChangingProduct('')
                  }}
                  variant="outlined"
                  color="red"
                  noMargin
                  size="small"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>

            <Spacer size="32" direction="horizontal" />

            <Grid
              style={{ height: '-webkit-fill-available', overflowY: 'auto' }}
              container
              spacing={3}
            >
              <Grid
                style={{
                  height: '-webkit-fill-available',
                  borderRight: `2px solid ${palette.grayscale[300]}`,
                }}
                xs={12}
                md={3}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: '-webkit-fill-available',
                    padding: '0px 32px',
                  }}
                >
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="500"
                  >
                    Filtros
                  </Typography>
                  <Spacer size="16" direction="horizontal" />
                  <Input
                    size="small"
                    label="Busca por nome"
                    value={nameFilter}
                    setValue={setNameFilter}
                  />
                  <Spacer size="16" direction="horizontal" />
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="800"
                    style={{ marginLeft: '24px' }}
                  >
                    Categoria
                  </Typography>
                  <Spacer size="8" direction="horizontal" />
                  <Input
                    size="small"
                    select
                    value={categoryFilter}
                    setValue={setCategoryFilter}
                  >
                    <option key="" value="">
                      Selecione...
                    </option>
                    {categories?.map((categorie) => (
                      <option key={categorie.id} value={categorie.id}>
                        {categorie.nome}
                      </option>
                    ))}
                  </Input>
                  <Spacer size="16" direction="horizontal" />
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="800"
                    style={{ marginLeft: '24px' }}
                  >
                    Marca
                  </Typography>
                  <Spacer size="8" direction="horizontal" />
                  <Input
                    size="small"
                    select
                    value={brandFilter}
                    setValue={setBrandFilter}
                    SelectProps={{ native: true }}
                  >
                    <option key="" value="">
                      Selecione...
                    </option>
                    {categoryFilter ? (
                      <>
                        {brands?.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.nome}
                          </option>
                        ))}
                      </>
                    ) : (
                      <>
                        {brands?.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.nome}
                          </option>
                        ))}
                      </>
                    )}
                  </Input>
                </div>
              </Grid>
              <Grid style={{ height: '-webkit-fill-available' }} xs={12} md={9}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    height: '-webkit-fill-available',
                    padding: '0px 32px',
                  }}
                >
                  {loadingProducts ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <CircularProgress
                        disableShrink
                        style={{ color: '#1335c6' }}
                        size={48}
                        thickness={4}
                      />
                    </div>
                  ) : (
                    <>
                      {products?.length == 0 ? (
                        <EmptyState text="Nenhum produto encontrado." />
                      ) : (
                        <>
                          <InfiniteScroll
                            next={getProducts}
                            dataLength={products?.length}
                            hasMore={hasMoreProducts}
                            height="100%"
                            scrollableTarget="scrollableDiv"
                            loader={
                              <div>
                                <p
                                  style={{
                                    textAlign: 'center',
                                    padding: '16px',
                                    marginBottom: '24px',
                                  }}
                                >
                                  <b> Carregando...</b>
                                </p>
                              </div>
                            }
                          >
                            {products?.map((product) => (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Avatar
                                      src={
                                        product.imagens
                                          ? product.imagens
                                          : product.mainImage
                                      }
                                      variant="rounded"
                                      style={{
                                        marginRight: '16px',
                                        width: '64px',
                                        height: '64px',
                                        borderRadius: '8px',
                                      }}
                                    />
                                    <div>
                                      <Typography
                                        type="text_medium"
                                        color="primary"
                                        colorWeight="300"
                                      >
                                        {product.nome}{' '}
                                        {integratorInfos?.privilegios_admin
                                          ? currentEnterpriseConfigs.formatProductName(
                                              product?.preco,
                                            )
                                          : ''}
                                      </Typography>
                                      <div style={{ display: 'flex' }}>
                                        {product.nome_fornecedor &&
                                        sessionIdStorage == '4282' ? (
                                          <>
                                            <Spacer
                                              size="8"
                                              direction="vertical"
                                            />
                                            <Badge
                                              color="orange"
                                              variant="contained"
                                              label={product.nome_fornecedor}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {product?.garantia ? (
                                          <>
                                            <Spacer
                                              size="8"
                                              direction="vertical"
                                            />
                                            <Badge
                                              size="large"
                                              color="primary"
                                              variant="contained"
                                              label={`${product.garantia} anos de garantia`}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {product?.visivel == 0 ? (
                                          <>
                                            <Spacer
                                              size="8"
                                              direction="vertical"
                                            />
                                            <Badge
                                              size="large"
                                              color="grayscale"
                                              variant="contained"
                                              label="Inativo"
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <Button
                                      onClick={() => {
                                        handleAddProduct(product, actualQuote)
                                        setChangingProduct(product.id)
                                      }}
                                      variant="outlined"
                                      size="small"
                                      noMargin
                                      style={{ width: 'max-content' }}
                                      isLoading={
                                        loadingAddProduct &&
                                        changingProduct == product.id
                                      }
                                    >
                                      Selecionar produto
                                    </Button>
                                  </div>
                                </div>
                                <Divider
                                  style={{ margin: '24px' }}
                                  variant="middle"
                                />
                              </>
                            ))}
                          </InfiniteScroll>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Fade>
    </Modal>
  )
}

export default ModalAdicionarProduto
