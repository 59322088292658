import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ breakpoints, palette }) => ({
  productContainer: {
    display: 'flex',
    height: '100%',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      overflow: 'auto',
    },
  },
  productTable: {
    flex: 2,
    padding: '40px 24px',
    paddingTop: '60px',
    [breakpoints.down('md')]: {
      flex: 0,
      paddingTop: '40px',
      paddingBottom: '100px'
    },
  },
  totalValueBox: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  paymentContainer: {
    overflow: 'auto',
    flex: 1,
    padding: '40px 24px',
    justifyContent: 'space-between',
    backgroundColor: palette.grayscale[100],
    [breakpoints.down('md')]: {
      flex: 1,
      borderRadius: '0px 0px 8px 8px',
      overflow: 'initial',
    },
    [breakpoints.down('sm')]: {
      overflow: 'initial',
    },
  },
}))

export default styles
