import api from 'api'
import { EQUIPMENT_CATEGORIES } from '../constants/endpointKeys'
import { type IEquipmentCategoriesParams } from './types'

export const equipmentCategories = async (
  params: IEquipmentCategoriesParams,
) => {
  return await api
    .get(EQUIPMENT_CATEGORIES, { params })
    .then(({ data }) => data?.categorias)
}
