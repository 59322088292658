import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

interface CompleteRegistrationModalData {
  isOpenCompleteRegistrationModal: boolean
  setIsOpenCompleteRegistrationModal: (value: boolean) => void
  closeModalCompleteUserData: () => void
}

interface ProviderProps {
  children: ReactNode
}

const CompleteRegistrationModalContext =
  createContext<CompleteRegistrationModalData>(
    {} as CompleteRegistrationModalData,
  )

function CompleteRegistrationModalProvider({ children }: ProviderProps) {
  const [isOpenCompleteRegistrationModal, setIsOpenCompleteRegistrationModal] =
    useState(false)

  const closeModalCompleteUserData = () => {
    setIsOpenCompleteRegistrationModal(false)
  }

  const contextValue = useMemo(
    () => ({
      isOpenCompleteRegistrationModal,
      setIsOpenCompleteRegistrationModal,
      closeModalCompleteUserData,
    }),
    [
      isOpenCompleteRegistrationModal,
      setIsOpenCompleteRegistrationModal,
      closeModalCompleteUserData,
    ],
  )

  return (
    <CompleteRegistrationModalContext.Provider value={contextValue}>
      {children}
    </CompleteRegistrationModalContext.Provider>
  )
}

const useCompleteRegistrationModalContext = () => {
  const context = useContext(CompleteRegistrationModalContext)
  return context
}

export {
  useCompleteRegistrationModalContext,
  CompleteRegistrationModalProvider,
}
