import { BADGE_PLAN_COLORS } from 'constants/badgePlanColors'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import { useSelector } from 'react-redux'
import { formatReal } from 'utils/format'

export function useCustomizeKitFooter() {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const userProfile = useSelector(
    (state: {
      ReducerProfile: {
        userProfile: {
          role_id: number
          privilegios_admin: number
          uf: string
        }
      }
    }) => state.ReducerProfile.userProfile,
  )

  const isAdmin =
    userProfile.role_id === 1 || userProfile.privilegios_admin === 1

  const { quotationSelected } = useEquipmentContainerStore()

  const integrator = data?.proposal?.integrator

  const quotationTotalValue = formatReal(quotationSelected?.equipment_value)
  const quotationCurrentDiscountPlan = {
    ...BADGE_PLAN_COLORS[
      quotationSelected?.discount_plan as keyof typeof BADGE_PLAN_COLORS
    ],
    label: quotationSelected?.discount_plan,
  }
  const canShowActionButtons =
    !quotationSelected?.userInternal && !data?.proposal?.isAdmin
  const canShowNotifyIntegratorButton = integrator && isAdmin

  const canShowReserveEquipmentsButton =
    !quotationSelected?.reserved &&
    !canShowNotifyIntegratorButton &&
    !isAdmin &&
    canShowActionButtons

  const canShowCreateProjectButton =
    canShowActionButtons && !canShowNotifyIntegratorButton && !isAdmin

  return {
    quotationTotalValue,
    quotationCurrentDiscountPlan,
    integrator,
    canShowReserveEquipmentsButton,
    canShowCreateProjectButton,
    canShowNotifyIntegratorButton,
  }
}
