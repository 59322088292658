import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Page } from 'components'
import { Modal as ModalCore, useToast } from '@77sol/core'
import { Slide } from '@77sol/core'

import { HeaderDashboard } from 'components/HeaderDashboard'
import { AnimationSolarEnergy } from 'components/AnimationSolarEnergy'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { isEmpty } from 'utils'
import { useStyles } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { ModalCancelOrder } from './components/ModalCancelOrder'

import Box from '@material-ui/core/Box'
import API from 'api'
import RemakeOrderModal from 'components/RemakeOrderModal'
import Tabs from './Tabs'
import OrderList from './components/OrderList'
import * as ProposalAction from 'store/actions'
import useWindowSize from 'hooks/useWindowSize'
import { OrderTrackingModal } from './components/OrderTrackingModal'
import { useFetchOrders } from 'domains/loja/loja-pedidos/hooks/useOrders'
import OrderFilters from './components/OrderFilters'
import OrderFiltersSlide from './components/OrderFilters/components/OrderFiltersSlide'
import { Header } from 'components/NewHeader'
import { Title } from 'components/Title'

function Pedidos() {
  const params = useParams()
  const { orderId } = params?.orderId ? params : ''
  const dispatch = useDispatch()
  const history = useHistory()
  const { logEvent } = useAmplitude()
  const { width: windowWidth } = useWindowSize()
  const { onErrorOpenToast } = useToast()

  const { ordersList = [] } = useFetchOrders()

  const [selectedOrder, setSelectedOrder] = useState({})
  const [modalCancelOrder, setModalCancelOrder] = useState(false)

  const [isCreatingNewQuotation, setIsCreatingNewQuotation] = useState(false)
  const [isOpenRemakeOrderModal, setIsOpenRemakeOrderModal] = useState(false)
  const [selectedOrderToSendTrackingLink, setSelectedOrderToSendTrackingLink] =
    useState({})
  const [currentOrderToRemake, setCurrentOrderToRemake] = useState(null)
  const [isOpenFilterSlide, setIsOpenFilterSlide] = useState(false)

  const classes = useStyles()

  const isMobile = windowWidth <= 1280
  const slideStyle = {
    width: isMobile ? '100%' : '50%',
    minWidth: isMobile ? '100%' : '50%',
    position: isMobile ? 'absolute' : 'relative',
    marginTop: isMobile ? 'unset' : '-68px',
    inset: 0,
  }

  function handleClickOrder(order) {
    logEvent(ordersTracker.actions.clickOrder)
    setSelectedOrder(order)
  }

  const handleBackSlide = () => {
    closeSlideOrderDetails()
  }

  const closeSlideOrderDetails = () => {
    setSelectedOrder({})
    orderId && history.push('/pedidos')
  }

  async function reOrder() {
    setIsOpenRemakeOrderModal(false)
    const parsedPayloadOriginal = JSON.parse(
      currentOrderToRemake.payload_original,
    )
    setIsCreatingNewQuotation(true)
    const payload = {
      origin_order: currentOrderToRemake.id,
      quotations_quantity: 3,
      ...parsedPayloadOriginal,
    }

    try {
      const { data } = await API.post('/creating/quotation', payload)
      const proposal = data?.[0]?.proposal
      const dimensioningProperties = data?.[0]?.dimensioning_properties

      dispatch(ProposalAction.setOriginLead(parsedPayloadOriginal.origin_lead))
      dispatch(ProposalAction.SaveProposalRedux(proposal))
      dispatch(ProposalAction.SaveSizingRedux(dimensioningProperties))
      dispatch(ProposalAction.SaveStepRedux('2'))
      dispatch(ProposalAction.SaveNewSizingRedux(false))
      dispatch(ProposalAction.SaveActiveSizingRedux(dimensioningProperties.id))

      for (const quotation of data) {
        dispatch(
          ProposalAction.SaveQuoteRedux({
            dimensioning: quotation.dimensioning,
            kit: quotation.kit,
            quotation: quotation.quotation,
            firstKit: quotation.firstKit,
          }),
        )
      }
      setIsCreatingNewQuotation(false)
      history.push('/dimensionar')
    } catch (error) {
      onErrorOpenToast('Houve um erro ao refazer pedido! Tente novamente.')
      setIsCreatingNewQuotation(false)
    }
  }

  useEffect(() => {
    if (orderId && ordersList?.length) {
      const selectedOrder = ordersList.find(
        (order) => order?.id === Number(orderId),
      )
      setSelectedOrder(selectedOrder)
    }
  }, [ordersList, orderId])

  const handleSendOrderTracking = (order) => {
    setSelectedOrderToSendTrackingLink(order)
  }

  return (
    <Page title={currentEnterpriseConfigs.titlePage('Meus Pedidos')}>
      <Header />
      <Box className={classes.root}>
        <Title className="title" text="Pedidos" />
        {isEmpty(selectedOrder) && (
          <>
            <OrderFilters
              isOpenFilterSlide={isOpenFilterSlide}
              setIsOpenFilterSlide={setIsOpenFilterSlide}
            />
          </>
        )}
        <Box display="flex">
          <OrderList
            selectedOrder={selectedOrder}
            onClickOrder={(order) => handleClickOrder(order)}
            onClickCancelOrder={(order) => setModalCancelOrder(order)}
            onClickRemakeOrder={(order) => {
              setCurrentOrderToRemake(order)
              setIsOpenRemakeOrderModal(true)
            }}
            onClickShareOrderTracking={(order) =>
              handleSendOrderTracking(order)
            }
            isOpenFilterSlide={isOpenFilterSlide}
          />
          <Slide style={slideStyle} isOpen={isOpenFilterSlide}>
            <OrderFiltersSlide
              setIsOpenOrderFiltersSlide={setIsOpenFilterSlide}
            />
          </Slide>
          <Slide style={slideStyle} isOpen={!isEmpty(selectedOrder)}>
            <Tabs
              id={selectedOrder?.id}
              onBack={handleBackSlide}
              codigo={selectedOrder?.codigo}
              selectedOrder={selectedOrder}
              onClickCancel={(order) => setModalCancelOrder(order)}
              onClickShareOrderTracking={(order) =>
                handleSendOrderTracking(order)
              }
            />
          </Slide>
        </Box>
      </Box>

      {isCreatingNewQuotation && (
        <ModalCore open={isCreatingNewQuotation}>
          <Box
            width="30vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AnimationSolarEnergy animationText="Sua cotação está sendo refeita…" />
          </Box>
        </ModalCore>
      )}

      {Boolean(selectedOrderToSendTrackingLink?.id) && (
        <OrderTrackingModal
          isOpen={Boolean(selectedOrderToSendTrackingLink?.id)}
          onClose={() => setSelectedOrderToSendTrackingLink({})}
          order={selectedOrderToSendTrackingLink}
        />
      )}

      {isOpenRemakeOrderModal && (
        <RemakeOrderModal
          isOpen={isOpenRemakeOrderModal}
          onClose={() => setIsOpenRemakeOrderModal(false)}
          onConfirm={reOrder}
        />
      )}

      {modalCancelOrder && (
        <ModalCancelOrder
          modalCancelOrder={modalCancelOrder}
          setSelectedOrder={setSelectedOrder}
          setModalCancelOrder={setModalCancelOrder}
          handleConfirmOrderCancel={() => setSelectedOrder({})}
        />
      )}
    </Page>
  )
}

export default Pedidos
