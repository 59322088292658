import { useEffect } from 'react'
import { Header } from 'components/NewHeader'
import { Page, Carousel } from 'components'
import { SolLeadAlert } from 'containers/SolLeadAlert'
import { SummaryRankClassification } from 'containers'
import { Performance } from './components/Performance'
import { FinancingAndProposalsTable } from './components/FinancingAndProposalsTable'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import * as S from './styles'
import { useLocation } from 'react-router-dom'

export function Home() {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()
  const { setIsQuotationModalOpen } = useQuotationModalContext()
  const { search } = useLocation()

  useEffect(() => {
    if (search.includes('quotationModal')) {
      setIsQuotationModalOpen(true)
    }

    if (search.includes('financingModal')) {
      setFinancingPerValueModalOpen()
    }
  }, [setIsQuotationModalOpen, setFinancingPerValueModalOpen, search])

  return (
    <Page title="Home">
      <Header />
      <S.CarouselWrapper>
        <Carousel />
      </S.CarouselWrapper>
      <S.Container>
        <SolLeadAlert />
        <FinancingAndProposalsTable />
        <SummaryRankClassification />
        <Performance />
      </S.Container>
    </Page>
  )
}
