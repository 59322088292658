import { Badge } from '@77sol/core'
import { palette } from '@77sol/styles'
import { format } from 'date-fns'
import { FinancialLabel } from 'components'
import { proposalFilterList } from 'utils'
import { type IProposalDTO } from 'dto/ProposalDTO'
import {
  SaveFavoriteBookmarkIcon,
  FilledSaveFavoriteBookmark,
} from '@77sol/icons/dist'

export const formatProposalForTable = (proposal: IProposalDTO) => {
  const favoriteIcon = proposal?.is_favorite ? (
    <FilledSaveFavoriteBookmark width="24px" color={palette.yellow[400]} />
  ) : (
    <SaveFavoriteBookmarkIcon width="24px" color={palette.grayscale[300]} />
  )

  const proposalDate = proposal.created_at
    ? format(new Date(proposal.created_at), 'dd/mm/yyyy hh:ss')
    : ''

  const proposalStatus = () => {
    const { label = 'Rascunho', color = 'grayscale' } =
      proposalFilterList.find(({ value }) => value === proposal?.status) ?? {}

    return <Badge label={label} color={color} />
  }

  return {
    is_favorite: favoriteIcon,
    id: proposal?.id,
    dimensionings_count: proposal?.dimensionings_count,
    quotation_total: proposal?.quotation_total,
    status: proposalStatus(),
    created_at: proposalDate,
    financing_status: <FinancialLabel status={proposal?.financing_status} />,
  }
}
