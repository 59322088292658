import { type IModalWrapperProps } from '@77sol-ui/molecules'
import { Modal } from 'components/Modal'
import { Landmark } from 'lucide-react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerQuotationFormProps,
} from '../FormManager/types'
import { type FormEvent, useState } from 'react'
import { FormStep } from './Steps/FormStep'
import { QuotationListStep } from './Steps/QuotationListStep'
import { FINANCING_TYPE, STEPS_ENUM } from '../../constants'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { StyledModalDescriptionWrapper } from './styles'
import { useAcl } from 'acl/hooks/useAcl'

export interface IContentProps extends IModalWrapperProps {
  onCreateFinancing: ICreateFinancingPerQuotationFormProps['onSubmit']
  onCloseModal: () => void
  requestFallback: DefaultValuesForm
}

export function Content({
  onCreateFinancing,
  onCloseModal,
  requestFallback,
  ...props
}: IContentProps) {
  const { userType } = useAcl()
  const [choosedQuotation, setChooedQuotation] = useState<IQuotationDTO>()
  const [currentStep, setCurrentStep] = useState<STEPS_ENUM>(STEPS_ENUM.LIST)

  const [isEnabledInsurance, setIsEnabledInsurance] = useState<boolean>(
    userType === 'integrator',
  )

  const { logEvent } = useAmplitude()

  const handleNextStep = (e: FormEvent) => {
    e.preventDefault()

    if (choosedQuotation) {
      logEvent(financingTracker.actions.projectFinancingSelected)
      setCurrentStep(STEPS_ENUM.FORM)
    }
  }

  const handlePreviousStep = (e: FormEvent) => {
    e.preventDefault()
    logEvent(financingTracker.actions.financingListStep, {
      financingType: FINANCING_TYPE,
      step: currentStep,
    })
    setCurrentStep(STEPS_ENUM.LIST)
  }

  const handleCloseModal = () => {
    logEvent(financingTracker.actions.financingCanceled, {
      financingType: FINANCING_TYPE,
      step: currentStep,
    })
    onCloseModal()
  }

  return (
    <Modal.Wrapper
      maxWidth="790px"
      maxHeight="90vh"
      id="create-financing-modal-wrapper"
      {...props}
    >
      <Modal.Close onClick={handleCloseModal} />
      <Modal.Header>
        <Modal.Icon icon={Landmark} />
        <Modal.Title>Crie uma simulação de financiamento</Modal.Title>
        <StyledModalDescriptionWrapper>
          <Modal.Description id="modal-description">
            Adicione as informações necessárias para criar uma simulação de
            financiamento.
          </Modal.Description>
        </StyledModalDescriptionWrapper>
      </Modal.Header>

      {currentStep === STEPS_ENUM.LIST && (
        <QuotationListStep
          choosedQuotation={choosedQuotation}
          setChooedQuotation={setChooedQuotation}
          onCloseModal={handleCloseModal}
          onNextStep={handleNextStep}
        />
      )}
      {currentStep === STEPS_ENUM.FORM && choosedQuotation && (
        <FormStep
          onCreateFinancing={(data, project) => {
            onCreateFinancing(
              { ...data, is_insurance: isEnabledInsurance },
              project,
            )
          }}
          onPreviousStep={handlePreviousStep}
          choosedQuotation={choosedQuotation}
          requestFallback={requestFallback}
          isEnabledInsurance={isEnabledInsurance}
          setIsEnabledInsurance={setIsEnabledInsurance}
        />
      )}
    </Modal.Wrapper>
  )
}
