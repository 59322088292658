import { create } from 'zustand'

interface IAdvertisingModalStore {
  advertisingModalIsOpen: boolean
  openAdvertisingModal: () => void
  closeAdvertisingModal: () => void
}

const INITIAL_ADVERTISING_MODAL = {
  advertisingModalIsOpen: false,
}

export const useAdvertisingModalStore = create<IAdvertisingModalStore>(
  (set) => ({
    ...INITIAL_ADVERTISING_MODAL,
    openAdvertisingModal: () => {
      set({ advertisingModalIsOpen: true })
    },
    closeAdvertisingModal: () => {
      set(INITIAL_ADVERTISING_MODAL)
    },
  }),
)
