import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { CloseXIcon, EditIcon, ViewIcon } from '@77sol/icons/dist'
import {
  BudgetPaper,
  PotencyConsumptionPaper,
  Paper,
  Spacer,
} from '@77sol/core'
import smoothscroll from 'smoothscroll-polyfill'
import palette from 'app_palette'
import DragIcon from '../../../../img/dragGrey.png'
import DragIconBlue from '../../../../img/dragBlue.png'
import { DragPaper, SizingEdit } from '../../..'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
})

function DigitalEquipmentsSection({
  container,
  index,
  editMode,
  internalEditing,
  setInternalEditing,
  stateEquipmentsSections,
  setStateEquipmentsSections,
  drag,
  setDrag,
  equipmentsList,
  editingMore,
  selectedQuote,
  setEditingMore,
  proposal,
  colors,
  budgetEdit,
  handleGetBudget,
}) {
  /* eslint eqeqeq: 0 */ 
  const classes = useStyles()
  const [sizingActivePanel, setSizingActivePanel] = useState('all')
  const [quoteEdit, setQuoteEdit] = useState({})

  const SizingStepLabel = (value) => {
    const labels = {
      sizing: 'Dados do dimensionamento',
      equipments: `Cotação #${selectedQuote} - Equipamentos`,
      budget: `Cotação #${selectedQuote} - Orçamento`,
      investmentReturn: `Cotação #${selectedQuote} - Retorno de investimento`,
    }

    return labels[value] || ''
  }

  useEffect(() => {
    if (editingMore == '') {
      handleGetBudget(selectedQuote)
    }
    // eslint-disable-next-line
  }, [editingMore])

  return (
    <Draggable
      isDragDisabled={
        editMode != 'equipmentsContainer'
        || editingMore == 'equipmentsCard'
        || editingMore == 'ProducaoRetorno'
      }
      key={container.id}
      draggableId={container.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Grid
          item
          className={classes.paddingSection}
          xs={12}
          sm={12}
          md={
            stateEquipmentsSections.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : 6
          }
          lg={
            stateEquipmentsSections.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : 6
          }
          xl={
            stateEquipmentsSections.filter((item) => item.visible == true)
              .length == 1
              ? 12
              : container.breakpoint
          }
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div
            style={{ height: '100%' }}
            className={snapshot.isDragging && classes.dragging}
            onClick={(e) => {
              if (editMode == 'equipmentsContainer') {
                internalEditing && internalEditing == container.id
                  ? setInternalEditing('')
                  : setInternalEditing(container.id)
                setEditingMore('')
              }
            }}
          >
            {container.id == 'equipmentsCard' && (
              <BudgetPaper
                colors={colors}
                budgets={budgetEdit}
                showEquips={equipmentsList.visible}
              />
            )}
            {container.id == 'ProducaoRetorno' && (
              <PotencyConsumptionPaper
                colors={colors}
                proposal={proposal}
                selectedQuote={selectedQuote}
              />
            )}
          </div>
          {snapshot.isDragging && <DragPaper />}
          {editMode == 'equipmentsContainer'
            && internalEditing == container.id && (
              <div
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-15px',
                  left: '-15px',
                  width: '100%',
                  display: 'flex',
                  justifyContent:
                    container.id == 'equipmentsCard'
                      ? 'flex-start'
                      : 'flex-end',
                }}
              >
                <Paper
                  style={{
                    padding: '16px 24px',
                    boxShadow: 'none',
                    cursor: 'auto',
                    maxHeight: '50vh',
                    width: 'auto',
                    border: `1px solid ${palette.grayscale[300]}`,
                    overflowY: 'auto',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="700"
                    >
                      {container.display}
                    </Typography>
                    <Spacer size="24" direction="vertical" />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {editingMore == container.id ? (
                        <Tooltip title="Fechar">
                          <CloseXIcon
                            width="24"
                            onClick={() => (editingMore == container.id
                              ? setEditingMore('')
                              : setEditingMore(container.id))}
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Editar">
                            <EditIcon
                              onClick={() => (editingMore == container.id
                                ? setEditingMore('')
                                : setEditingMore(container.id))}
                              style={{ cursor: 'pointer' }}
                              width="24"
                              color={palette.grayscale[700]}
                            />
                          </Tooltip>
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Arrastar">
                            <img
                              alt="drag"
                              onClick={() => (drag == container.id
                                ? setDrag('')
                                : setDrag(container.id))}
                              src={
                                internalEditing == container.id
                                  ? DragIconBlue
                                  : DragIcon
                              }
                              style={{
                                width: '20px',
                                cursor: 'pointer',
                                color:
                                  drag == container.id
                                    ? '#1335c6'
                                    : 'rgb(112, 112, 114)',
                              }}
                            />
                          </Tooltip>
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Esconder">
                            <ViewIcon
                              onClick={() => {
                                const newArr = [...stateEquipmentsSections]
                                const index = newArr.findIndex(
                                  (item) => item.id == container.id,
                                )
                                newArr[index].visible = !newArr[index].visible
                                setStateEquipmentsSections({
                                  containers: newArr,
                                })
                              }}
                              style={{ cursor: 'pointer' }}
                              width="24"
                              color={palette.grayscale[700]}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  {editingMore == 'equipmentsCard' && (
                    <div style={{ minWidth: '25vw' }}>
                      {sizingActivePanel != 'all' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                          }}
                        >
                          <div
                            onClick={() => setSizingActivePanel('all')}
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowBackIosRoundedIcon
                              width="24"
                              color={palette.grayscale[700]}
                              style={{ cursor: 'pointer' }}
                            />
                            <Typography
                              type="link_small"
                              color="grayscale"
                              colorWeight="700"
                              style={{ margin: '0px 16px' }}
                            >
                              Voltar
                            </Typography>
                          </div>
                          <Paper
                            style={{
                              backgroundColor: palette.primary[300],
                              color: '#FFF',
                              textAlign: 'center',
                              padding: '6px',
                              fontSize: '13px',
                              borderRadius: '4px',
                              width: '-webkit-fill-available',
                            }}
                          >
                            {SizingStepLabel(sizingActivePanel)}
                          </Paper>
                        </div>
                      )}
                      <SizingEdit
                        sizingActivePanel={sizingActivePanel}
                        setSizingActivePanel={setSizingActivePanel}
                        quoteEdit={quoteEdit}
                        setQuoteEdit={setQuoteEdit}
                        proposal={proposal}
                        proposalDragDrop
                        oneQuoteId={selectedQuote}
                        handleGetBudget={handleGetBudget}
                        budgetEdit={budgetEdit}
                        selectedQuote={selectedQuote}
                      />
                    </div>
                  )}
                  {editingMore == 'ProducaoRetorno' && (
                    <div style={{ minWidth: '25vw' }}>
                      {sizingActivePanel != 'all' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                          }}
                        >
                          <div
                            onClick={() => setSizingActivePanel('all')}
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowBackIosRoundedIcon
                              width="24"
                              color={palette.grayscale[700]}
                              style={{ cursor: 'pointer' }}
                            />
                            <Typography
                              type="link_small"
                              color="grayscale"
                              colorWeight="700"
                              style={{ margin: '0px 16px' }}
                            >
                              Voltar
                            </Typography>
                          </div>
                          <Paper
                            style={{
                              backgroundColor: palette.primary[300],
                              color: '#FFF',
                              textAlign: 'center',
                              padding: '6px',
                              fontSize: '13px',
                              borderRadius: '4px',
                              width: '-webkit-fill-available',
                            }}
                          >
                            {SizingStepLabel(sizingActivePanel)}
                          </Paper>
                        </div>
                      )}
                      <SizingEdit
                        sizingActivePanel={sizingActivePanel}
                        setSizingActivePanel={setSizingActivePanel}
                        quoteEdit={quoteEdit}
                        setQuoteEdit={setQuoteEdit}
                        oneQuoteId={selectedQuote}
                        proposal={proposal}
                        proposalDragDrop
                        consumptionPanel
                        sizingEdit={proposal.sizings.find(
                          (sizing) => sizing.id
                            == proposal.quotes.find(
                              (quote) => quote.quotation.id == selectedQuote,
                            ).dimensioning.id,
                        )}
                        handleGetBudget={handleGetBudget}
                        budgetEdit={budgetEdit}
                        selectedQuote={selectedQuote}
                      />
                    </div>
                  )}
                </Paper>
              </div>
          )}
        </Grid>
      )}
    </Draggable>
  )
}

export default DigitalEquipmentsSection
