import { useCallback, useEffect, useRef, useState } from 'react'

type TargetTypes = 'BUTTON' | 'A'

export interface UseInterceptClickProps {
  eventIsEnableByDefault?: boolean
  excludeIds?: string[]
  interceptIds?: string[]
  excludeClassNames?: string[]
  canInterceptElements?: TargetTypes[]
}

interface UseInterceptClickResult {
  eventIsEnabled: boolean
  handleDisableEvent: () => void
  handleEnableEvent: () => void
  handleUninterceptClick: () => Promise<number>
  handleResetEvent: () => void
  clickIntercepted: boolean
  setClickIntercepted: (intercepted: boolean) => void
}

export function useInterceptableClick({
  eventIsEnableByDefault = false,
  excludeIds,
  excludeClassNames,
  interceptIds,
  canInterceptElements = ['A', 'BUTTON'],
}: UseInterceptClickProps): UseInterceptClickResult {
  const [eventIsEnabled, setEventIsActive] = useState(eventIsEnableByDefault)
  const [clickIntercepted, setClickIntercepted] = useState<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const buttonRef = useRef<HTMLElement | null>(null)

  const findButton = useCallback(
    (target: HTMLElement): HTMLElement | null => {
      if (!canInterceptElements.includes(target.nodeName as TargetTypes)) {
        const canIntercept = interceptIds?.includes(target?.id)

        if (canIntercept) return target

        if (!target.parentNode) return null

        return findButton(target.parentNode as HTMLElement)
      }

      return target
    },
    [interceptIds],
  )

  const checkIfElementContainsTargetByClassName = useCallback(
    (target: HTMLElement, classes?: string[]) => {
      if (!classes) {
        return false
      }
      return classes.some((className) => {
        const elements = Array.from(document.getElementsByClassName(className))
        return elements.some((element) => element?.contains(target))
      })
    },
    [excludeIds],
  )

  const checkIfElementContainsTarget = useCallback(
    (target: HTMLElement, ids?: string[]) => {
      if (!ids) {
        return false
      }
      return ids.some((id) => {
        const element = document.getElementById(id)

        return element?.contains(target)
      })
    },
    [excludeIds],
  )

  const handleInterceptClick = useCallback(
    (e: MouseEvent): void => {
      if (!eventIsEnabled || clickIntercepted || e.button !== 0) return

      const button = findButton(e.target as HTMLElement)

      const ignoreEvent = button?.dataset.ignoreevent === 'true'

      if (
        button &&
        !ignoreEvent &&
        !checkIfElementContainsTargetByClassName(button, excludeClassNames) &&
        !checkIfElementContainsTarget(button, excludeIds)
      ) {
        buttonRef.current = button
        setClickIntercepted(true)

        e.stopPropagation()
        e.preventDefault()
      }
    },
    [
      eventIsEnabled,
      findButton,
      clickIntercepted,
      excludeIds,
      checkIfElementContainsTarget,
      checkIfElementContainsTargetByClassName,
    ],
  )

  const handleEnableEvent = useCallback(() => {
    setEventIsActive(true)
  }, [])

  const handleResetEvent = useCallback(() => {
    setClickIntercepted(false)
    buttonRef.current = null
  }, [])

  const handleDisableEvent = useCallback(() => {
    setEventIsActive(false)
    handleResetEvent()
  }, [handleResetEvent])

  const handleUninterceptClick = useCallback(async (): Promise<number> => {
    return await new Promise((resolve) => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        if (buttonRef.current && clickIntercepted) {
          const button =
            document.getElementById(buttonRef.current?.id) || buttonRef.current

          const pointerEvent = new PointerEvent('pointerdown', {
            bubbles: true,
            cancelable: true,
            pointerId: 1,
          })

          button.dispatchEvent(pointerEvent)
          button.click()
          buttonRef.current = null
        }
        resolve(1)
      }, 50)
    })
  }, [clickIntercepted])

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('pointerdown', handleInterceptClick, true)

    return () => {
      document.removeEventListener('pointerdown', handleInterceptClick, true)
    }
  }, [handleInterceptClick])

  return {
    eventIsEnabled,
    handleDisableEvent,
    handleEnableEvent,
    handleResetEvent,
    handleUninterceptClick,
    clickIntercepted,
    setClickIntercepted,
  }
}
