import {
  PlugIcon,
  WeatherLightningIcon,
  CalendarIcon,
  ChartBarIcon,
} from '@77sol/icons/dist'
import * as S from './styles'
import { ItemButton } from './ItemButton'
import { SectionHeader } from '../SectionHeader'
import { usePlan } from 'hooks/usePlan'
import { useAmplitude } from 'hooks/useAmplitude'
import {
  BASIC_SIZING_HEADER_TEXT,
  DETAILED_SIZING_HEADER_TEXT,
  EnumSizingType,
} from 'views/DimensionarV2/components/StepOne/constants'
import { TRACKERS_MAPPED_BY_TYPE } from './constants'
import { isDetailedSizing } from 'views/DimensionarV2/components/StepOne/utils'

interface ISizingTypeMenuProps {
  setCurrentSizingType: (value: EnumSizingType) => void
}

export const SizingTypeMenu = ({
  setCurrentSizingType,
}: ISizingTypeMenuProps) => {
  const { isPro } = usePlan()
  const { logEvent } = useAmplitude()

  const handleSelectSizingType = (type: EnumSizingType) => {
    if (isDetailedSizing(type) && !isPro()) {
      return
    }

    logEvent(TRACKERS_MAPPED_BY_TYPE[type])
    setCurrentSizingType(type)
  }

  return (
    <S.Container>
      <SectionHeader {...DETAILED_SIZING_HEADER_TEXT} />
      <div className="panel">
        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.ConsumptionAverage)
          }}
          icon={<WeatherLightningIcon />}
          label="Média de consumo"
          description="kWh"
        />

        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.AverageConsumptionValue)
          }}
          icon={<ChartBarIcon />}
          label="Valor de consumo médio"
          description="R$"
        />

        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.SystemPower)
          }}
          icon={<PlugIcon />}
          label="Potência do sistema"
          description="kWp"
        />

        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.MonthlyConsumption)
          }}
          icon={<CalendarIcon />}
          label="Consumo mensal"
          description="Recomendado"
          highlightDescription
        />
      </div>

      <SectionHeader {...BASIC_SIZING_HEADER_TEXT} />

      <div className="panel">
        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.BasicSystemPower)
          }}
          icon={<PlugIcon />}
          label="Potência do sistema"
          description="kWh"
        />

        <ItemButton
          onClick={() => {
            handleSelectSizingType(EnumSizingType.BasicAverageConsumptionValue)
          }}
          icon={<ChartBarIcon />}
          label="Valor de consumo médio"
          description="R$"
        />
      </div>
    </S.Container>
  )
}
