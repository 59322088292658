import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #ff0000;
  border-radius: 8px;
  border: 2px solid #ff0000;
  font-size: 13px;

  > span {
    display: none;
  }

  > svg {
    width: 18px;
    height: 18px;
    color: #ff0000;
  }

  .MuiCircularProgress-svg {
    color: #ff0000;
  }

  &:disabled {
    cursor: not-allowed;
    color: #ccc;
    border-color: #ccc;

    .MuiCircularProgress-svg {
      color: #ccc;
    }
  }

  @media (min-width: 640px) {
    padding: 10px 22px;
  }

  @media (min-width: 1280px) {
    > span {
      display: inline-block;
    }
    > svg {
      display: none;
      width: 24px;
      height: 24px;
    }
  }
`
