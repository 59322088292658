export enum ORDER_STATUS_TRADUCTION_ENUM {
  PEDIDO_VENCIDO = 1,
  FORECAST = 2,
  NF_PARA_FINANCIAMENTO = 3,
  FORMA_DE_PAGAMENTO_ENVIADA = 4,
  APURAR_PAGAMENTO = 5,
  REALIZAR_PEDIDO = 6,
  EMITIR_NF_PE = 21,
  PEDIDO_EM_REVISAO = 7,
  CONFIRMACAO_DE_COMPRA = 8,
  EQUIPAMENTOS_CHEGANDO = 9,
  FILA_DE_SEPARACAO = 10,
  MONTAGEM = 21,
  EMITIR_NF = 12,
  ACOMPANHAR_SAIDA = 13,
  NF_DO_DISTRIBUIDOR = 14,
  ACOMPANHAR_ENTREGA = 15,
  AGENDAMENTO_FEITO = 16,
  ENTREGAS_DA_SEMANA = 17,
  ENTREGAS_POSTERGADAS = 18,
  ENTREGE = 19,
  CANCELADO = 20,
}
