import { type IPendingFields } from '../OrderPendingsTab.types'

export function formatPendingFields(pendings: IPendingFields) {
  return {
    payment_type: pendings?.payment_type ?? '',
    nf_value: pendings?.nf_value ?? 0,
    customer_phone: pendings?.customer_phone ?? '',
    customer_email: pendings?.customer_email ?? '',
    birth_date: pendings?.birth_date ?? '',
    billing_letter: {
      file: null,
      name_file: pendings?.billing_letter?.name_file ?? null,
      url: pendings?.billing_letter?.url ?? null,
    },
    address_payment: {
      file: null,
      name_file: pendings?.address_payment?.name_file ?? null,
      url: pendings?.address_payment?.url ?? null,
    },
  }
}
