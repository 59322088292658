import { useState } from 'react'

export function useSaveAndQuit() {
  const [isOpenSaveAndQuitModal, setIsOpenSaveAndQuitModal] = useState(false)

  return {
    setIsOpenSaveAndQuitModal,
    isOpenSaveAndQuitModal,
  }
}
