import { palette } from '@77sol/styles'
import styled, { css } from 'styled-components'

interface ContentProps {
  highlightDescription?: boolean
  tag?: string
}

interface ContainerProps {
  tag?: string
}

export const Container = styled.button<ContainerProps>`
  border: none;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background 500ms;

  @media (min-width: 768px) {
    justify-content: center;
  }

  ${({ tag }) =>
    tag &&
    css`
      @media (max-width: 768px) {
        ::after {
          content: '${tag}';
          color: #fff;
          background-color: ${palette.primary[300]};
          font-size: 0.5rem;
          padding: 8px;
          border-radius: 8px;
          line-height: 8px;
        }
      }
    `}
`

export const Content = styled.div<ContentProps>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  .block {
    display: flex;

    ${({ tag }) =>
      tag &&
      css`
        position: relative;

        @media (min-width: 768px) {
          ::after {
            position: absolute;
            bottom: -10px;
            right: -20px;
            content: '${tag}';
            color: #fff;
            background-color: ${palette.primary[300]};
            font-size: 0.5rem;
            padding: 8px;
            border-radius: 8px;
            line-height: 8px;
          }
        }
      `}

    svg {
      width: 32px;
      height: 32px;
      color: ${palette.grayscale[700]};

      @media (min-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  .context {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      align-items: center;
    }

    span:first-child {
      font-size: 1rem;
    }

    span:last-child {
      font-size: 0.8rem;
      font-weight: bold;
      color: ${({ highlightDescription }) =>
        highlightDescription ? palette.primary[300] : palette.grayscale[500]};
    }
  }
`
