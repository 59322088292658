import { useInfiniteQuery } from '@tanstack/react-query'
import { EQUIPMENT_LIST } from '../constants/endpointKeys'
import { equipmentList } from '../services/api'
import { type IEquipmentListParams } from '../services/types'
import { useMemo } from 'react'

export function useEquipmentList(params: IEquipmentListParams) {
  const { data, ...methods } = useInfiniteQuery({
    queryKey: [EQUIPMENT_LIST, params],
    queryFn: async ({ pageParam = 1 }) => {
      return await equipmentList({ ...params, page: pageParam })
    },
    getNextPageParam: ({ current_page, last_page }) => {
      if (current_page < last_page) {
        return current_page + 1
      }
    },
    keepPreviousData: true,
  })

  const equipmentData = useMemo(() => {
    return {
      data: data?.pages?.flatMap((page) => page?.data) ?? [],
      total: data?.pages?.[0]?.total ?? 0,
    }
  }, [data])

  return {
    ...methods,
    ...equipmentData,
  }
}
