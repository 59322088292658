import { useQuery } from '@tanstack/react-query'
import { adminStructureTypesProducts } from '../services/api'
import { GET_ADMIN_STRUCTURE_TYPES_PRODUCTS } from '../constants/endpointKeys'

const STALE_TIME_IN_MINUTES = 1000 * 60 * 10

export const useAdminStructureTypesProducts = () => {
  const { data = [], ...rest } = useQuery({
    queryKey: [GET_ADMIN_STRUCTURE_TYPES_PRODUCTS],
    queryFn: async () => await adminStructureTypesProducts.get(),
    staleTime: STALE_TIME_IN_MINUTES,
  })

  return { structureTypesData: data, ...rest }
}
