import { type INotificationsAllGETResponse } from 'domains/notifications/all/services/types'
import { useFetchNotificationsAll } from 'domains/notifications/all'
import { useReadOneNotification } from 'domains/notifications/readone'
import { useReadAllNotifications } from 'domains/notifications/readall'
import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react'
import useSocketPusher from 'hooks/useSocketPusher'
import { useSelector } from 'react-redux'
import { type RootState } from 'store'
import { type INotificationDTO } from 'dto/NotificationDTO'
import { type InfiniteData, useQueryClient } from '@tanstack/react-query'
import { GET_NOTIFICATIONS_ALL } from 'domains/notifications/all/constants/endpointKeys'
import { useAmplitude } from 'hooks/useAmplitude'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'
import {
  NOTIFICATION_CHANNEL_ENUM,
  NOTIFICATION_EVENT_ENUM,
} from 'enums/NotificationsEnum'

interface INotificationsContext {
  notificationList: INotificationDTO[]
  notificationsOpen: boolean
  unreadNotifications: boolean
  hasMoreNotifications: boolean
  isLoadingNotifications: boolean
  handleNextNotifications: () => void
  handleOpenNotifications: () => void
  handleCloseNotifications: () => void
  handleToggleNotifications: () => void
  handleNotificationReadOne: (id: string) => Promise<void>
  handleNotificationReadAll: () => Promise<void>
}

const NotificationsContext = createContext<INotificationsContext>(
  {} as INotificationsContext,
)

interface INotificationsProvider {
  children: ReactNode
}

function NotificationsProvider({ children }: INotificationsProvider) {
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const { logEvent } = useAmplitude()

  const userId = useSelector<RootState>(
    (state) => state?.ReducerProfile?.userProfile?.id,
  )

  const queryClient = useQueryClient()
  const readOneNotification = useReadOneNotification()
  const readAllNotification = useReadAllNotifications()
  const {
    notificationList = [],
    unreadNotifications = false,
    refetch: refetchNotifications,
    isLoading: isLoadingNotifications,
    hasNextPage: hasMoreNotifications = false,
    fetchNextPage: handleNextNotifications,
  } = useFetchNotificationsAll()

  const handleNotifyUser = (message?: INotificationDTO) => {
    const queryKey = [GET_NOTIFICATIONS_ALL]
    const prevData =
      queryClient.getQueryData<InfiniteData<INotificationsAllGETResponse>>(
        queryKey,
      )

    if (prevData) {
      queryClient.setQueryData(queryKey, () => {
        const allPages = new Array(...prevData.pages)
        const firstPage = allPages.shift()

        if (!firstPage) return prevData

        return {
          ...prevData,
          pages: [
            {
              ...firstPage,
              data: [message, ...firstPage.data],
              total_not_read: firstPage.total_not_read + 1,
            },
            ...allPages,
          ],
        }
      })
    }
  }

  useSocketPusher({
    enabled: Boolean(userId),
    channelName: `${NOTIFICATION_CHANNEL_ENUM.USER_NOTIFICATION}${
      userId as string
    }`,
    events: [
      {
        eventName: NOTIFICATION_EVENT_ENUM.NOTIFY_USER,
        callback: handleNotifyUser,
      },
    ],
  })

  const handleOpenNotifications = () => {
    setNotificationsOpen(true)
  }

  const handleCloseNotifications = () => {
    setNotificationsOpen(false)
  }

  const handleToggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen)
  }

  const handleNotificationReadOne = async (id: string) => {
    await readOneNotification.mutateAsync(id)
    refetchNotifications()
  }

  const handleNotificationReadAll = async () => {
    if (unreadNotifications) {
      await readAllNotification.mutateAsync()
      logEvent(notificationsTracker.actions.readAllNotifications)
      refetchNotifications()
    }
  }

  const contextValue = useMemo(
    () => ({
      notificationList,
      notificationsOpen,
      unreadNotifications,
      hasMoreNotifications,
      isLoadingNotifications,
      handleNextNotifications,
      handleOpenNotifications,
      handleCloseNotifications,
      handleNotificationReadOne,
      handleNotificationReadAll,
      handleToggleNotifications,
    }),
    [
      notificationList,
      notificationsOpen,
      unreadNotifications,
      hasMoreNotifications,
      isLoadingNotifications,
      handleNextNotifications,
      handleOpenNotifications,
      handleCloseNotifications,
      handleNotificationReadOne,
      handleNotificationReadAll,
      handleToggleNotifications,
      handleToggleNotifications,
    ],
  )

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  )
}

const useNotificationsContext = () => {
  return useContext(NotificationsContext)
}

export { NotificationsProvider, useNotificationsContext }
