import styled from 'styled-components'

export const LayoutRoot = styled.div`
  height: 100%;
  display: flex;
`

export const LayoutContent = styled.main`
  overflow-y: auto;
  flex: 1;
  background-color: white;
`
