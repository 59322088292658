import React from 'react'
import { Divider, Spacer, Typography, DropdownMenu } from '@77sol/core'
import { DownloadIcon, WeatherMoonIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import * as S from './styles'
import { KebabMenu } from 'components/KebabMenu'
import { logEvent } from '@amplitude/analytics-browser'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { separateProductName } from 'utils/productName'

export default function OrderItemsTab({ items = [] }) {
  function handleDownloadFile(link, name) {
    if (!link) return
    logEvent(fileTracker.actions.downloadFile, {
      origin: '/pedidos',
      fileName: name,
    })
    window.open(link, '_blank')
  }

  function normalizeProductName({ nome = '' }) {
    return separateProductName(nome)
  }

  return items.length === 0 ? (
    <S.NoItemsContainer>
      <WeatherMoonIcon width="24px" color={palette.grayscale[400]} />
      <Spacer size="12" direction="vertical" />
      <Typography size="small" grayscale colorWeight="400">
        Nenhum item encontrado nesse pedido
      </Typography>
    </S.NoItemsContainer>
  ) : (
    <S.Container>
      {items.map((item) => (
        <S.Item key={item.nome}>
          <S.ItemWrapper>
            <S.ItemTitleInfo>
              <S.ItemImage src={item.imagens} alt={item.nome} />
              <Spacer size="24" direction="vertical" />
              <S.Title>
                <Typography size="small" colorWeight="800">
                  {normalizeProductName(item).productName}
                </Typography>
                <Typography size="xsmall" colorWeight="400">
                  {normalizeProductName(item).availabilityMessage}
                </Typography>
              </S.Title>
            </S.ItemTitleInfo>
            <S.ItemAmountInfo>
              <S.ItemAmount>
                <Typography size="large" color="grayscale" colorWeight="800">
                  {item.quantidade}
                </Typography>
                <Spacer size="4" direction="vertical" />
                <Typography size="small" color="grayscale" colorWeight="800">
                  un
                </Typography>
              </S.ItemAmount>
              <KebabMenu>
                <DropdownMenu.Item
                  disabled={!item.inmetro}
                  onClick={() => handleDownloadFile(item.inmetro, 'Inmetro')}
                >
                  <S.DropdownItemWithIcon>
                    Baixar Inmetro
                    <DownloadIcon />
                  </S.DropdownItemWithIcon>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  disabled={!item.datasheet}
                  onClick={() =>
                    handleDownloadFile(item.datasheet, 'DataSheet')
                  }
                >
                  <S.DropdownItemWithIcon>
                    Baixar DataSheet
                    <DownloadIcon />
                  </S.DropdownItemWithIcon>
                </DropdownMenu.Item>
              </KebabMenu>
            </S.ItemAmountInfo>
          </S.ItemWrapper>
          <Divider />
        </S.Item>
      ))}
    </S.Container>
  )
}
