import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { type ReactNode, createContext, useContext, useMemo } from 'react'

interface ISocketPusherContext {
  echo?: Echo
}

const SocketPusherContext = createContext<ISocketPusherContext>({})

interface ISocketPusherProvider {
  children: ReactNode
}

function SocketPusherProvider({ children }: ISocketPusherProvider) {
  const client = useMemo(() => {
    return new Pusher(String(process.env.REACT_APP_WEBSOCKET_KEY), {
      forceTLS: Boolean(process.env.REACT_APP_WEBSOCKET_TLS),
      cluster: String(process.env.REACT_APP_WEBSOCKET_CLUSTER),
      wsHost: String(process.env.REACT_APP_WEBSOCKET_HOST_URL),
      ...(process.env.REACT_APP_WEBSOCKET_HOST_PORT && {
        wsPort: Number(process.env.REACT_APP_WEBSOCKET_HOST_PORT),
      }),
    })
  }, [])

  const echo = useMemo(() => {
    return new Echo({
      broadcaster: 'pusher',
      client,
    })
  }, [client])

  const contextValue = useMemo(() => {
    return {
      echo,
    }
  }, [echo])

  return (
    <SocketPusherContext.Provider value={contextValue}>
      {children}
    </SocketPusherContext.Provider>
  )
}

function useSocketPusherContext() {
  return useContext(SocketPusherContext)
}

export { SocketPusherProvider, useSocketPusherContext }
