import React from 'react'
import { Typography } from 'components'

function ConcessionariaCard(props) {
  const { proposal } = props

  return (
    <div>
      <Typography type="text_small" color="black">
        Concessionária
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography type="text_x_small" color="grayscale" colorWeight="700">
          {proposal.sizings[0].nome_concessionaria}
        </Typography>
      </div>
    </div>
  )
}

export default ConcessionariaCard
