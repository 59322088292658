/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback } from 'react'
import API from 'api'
import {
  Button,
  Spacer,
  Modal,
  TableList,
  Title,
  Paper,
  useToast,
  Typography,
} from '@77sol/core'
import { palette } from '@77sol/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import { parseBRL } from 'utils'
import { DeleteIcon } from '@77sol/icons/dist'
import { FINANCING_CANCEL_COLUMNS, FINANCING_TYPE } from '../../constants'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'

export function ModalCancelFinancing({
  setLoadingCancelFinancing,
  handleRefreshFinancingList,
  onClose,
  selectedFinancingToCancel,
  setSelectedFinancingToCancel,
  setIsCancelFinancing,
  isCancelFinancing,
  currentTabFinancings,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { logEvent } = useAmplitude()

  const selectedFinancingFormatted = {
    id: `#${selectedFinancingToCancel?.id}`,
    requester: `${selectedFinancingToCancel?.name_requester}`,
    bank: `${selectedFinancingToCancel?.bank}`,
    total_value: `${parseBRL(
      selectedFinancingToCancel?.total_value ||
        selectedFinancingToCancel?.value,
    )}`,
    status: `${selectedFinancingToCancel?.status}`,
  }

  const handleFinancingCancel = (id) => {
    setLoadingCancelFinancing(true)

    logEvent(financingTracker.actions.confirmedCancellationFinancing, {
      origin: 'Modal de cancelar financiamento',
      financingType: FINANCING_TYPE[currentTabFinancings],
    })

    API.put(`/financing/cancel/${id}`)
      .then(() => {
        handleRefreshFinancingList()
        onClose()
        onSuccessOpenToast('Financiamento cancelado com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Erro ao cancelar o financiamento!')
      })
      .finally(() => setLoadingCancelFinancing(false))
  }

  const handleModalCancel = useCallback(() => {
    setSelectedFinancingToCancel({})
    setIsCancelFinancing(false)

    logEvent(financingTracker.actions.gaveUpCancellationFinancing, {
      origin: 'Modal de cancelar financiamento',
      financingType: FINANCING_TYPE[currentTabFinancings],
    })
  }, [])

  return (
    <Modal open={isCancelFinancing} onClose={handleModalCancel}>
      <Box width="70vw">
        <Box
          display="flex"
          {...(downSm && {
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          })}
        >
          <DeleteIcon color={palette.primary[300]} width={downSm ? 24 : 32} />
          <Spacer size="16" direction="both" />
          <Title
            weight="bold"
            color="primary"
            colorWeight="300"
            size={downSm ? 'xxsmall' : 'xsmall'}
          >
            Deseja cancelar esse financiamento?
          </Title>
        </Box>
        <Spacer size="24" direction="horizontal" />
        {downSm ? (
          <Paper boxShadow="small">
            <Box display="flex" justifyContent="space-between">
              <Typography size="xsmall" colorWeight="600">
                {selectedFinancingFormatted.id}
              </Typography>
              <Typography size="xsmall" colorWeight="600">
                {selectedFinancingFormatted.bank}
              </Typography>
            </Box>
            <Spacer size="8" direction="horizontal" />
            <Box display="flex" justifyContent="space-between">
              <Typography type="link">
                {selectedFinancingFormatted.requester}
              </Typography>
              <Spacer size="8" direction="horizontal" />
              <Typography size="small">
                {selectedFinancingFormatted.total_value}
              </Typography>
            </Box>
          </Paper>
        ) : (
          <TableList
            LastItem={null}
            onRowClick={() => {}}
            columns={FINANCING_CANCEL_COLUMNS}
            values={[selectedFinancingFormatted]}
          />
        )}
        <Spacer size="20" direction="horizontal" />
        <Box display="flex" {...(downSm && { justifyContent: 'center' })}>
          <Button
            color="red"
            variant="contained"
            size="small"
            onClick={() => handleFinancingCancel(selectedFinancingToCancel.id)}
          >
            Confirmar
          </Button>
          <Spacer size="12" direction="vertical" />
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleModalCancel()}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
