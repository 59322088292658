import { DIFFERENTS_STATES_ERROR } from 'containers/CheckoutModal/constants/genericErrors'
import { useEffect } from 'react'
import { type UseFormReturn } from 'react-hook-form'

interface IUseClearErrorsProps {
  methods: UseFormReturn<any>
}

export function useClearErrors({ methods }: IUseClearErrorsProps) {
  const {
    watch,
    clearErrors,
    formState: { errors },
  } = methods

  const billingState = watch('deliveryAddress.estado')
  const deliveryState = watch('billingAddress.estado')

  useEffect(() => {
    if (errors.genericError?.type === DIFFERENTS_STATES_ERROR.KEY) {
      if (billingState === deliveryState) {
        clearErrors(['deliveryAddress.estado', 'billingAddress.estado'])
      }
    }
  }, [billingState, deliveryState])
}
