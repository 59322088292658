import { useQueryClient } from '@tanstack/react-query'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function useDisplayModal() {
  const queryClient = useQueryClient()
  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const quotationId = quotationSelected?.id
  const initialDiscount = Number(quotationSelected?.discount_percentage)

  const setOpenModalQuotationDiscount = useEquipmentContainerStore(
    (state) => state.setOpenModalQuotationDiscount,
  )

  function handleCloseModal() {
    setOpenModalQuotationDiscount(false)
  }

  function handleSuccessSaveDiscount() {
    queryClient.invalidateQueries([GET_DIMENSIONING])
    setOpenModalQuotationDiscount(false)
  }

  return {
    quotationId,
    initialDiscount,
    handleCloseModal,
    handleSuccessSaveDiscount,
  }
}
