import { Button } from '@77sol-ui/atoms'
import { ImageCrop } from '@77sol-ui/organisms'
import { ImageCrop as ATMImageCrop } from '@77sol-ui/atoms'
import { Modal } from 'components/Modal'
import { UserPen } from 'lucide-react'
import { useProfilePhoto } from './hooks/useProfilePhoto'
import * as S from './styles'

export interface IProfilePhotoModalProps {
  onSave: (file: File) => void
  onCancel: () => void
  imageURL: string
}

export function ProfilePhotoModal({
  onSave,
  imageURL,
  onCancel,
}: IProfilePhotoModalProps) {
  const {
    cropSize,
    setZoom,
    zoom,
    handleSave,
    imageRef,
    handleDecreaseZoom,
    handleIncreaseZoom,
    zoomMin,
    zoomLimit,
  } = useProfilePhoto({
    onSave,
  })

  return (
    <>
      <Modal.Root open>
        <S.StyledOverlay />
        <Modal.Portal>
          <S.WrapperStyled>
            <Modal.Header>
              <Modal.Icon icon={UserPen} />
              <Modal.Title>Ajuste sua foto de perfil</Modal.Title>
              <Modal.Description>
                Redimensione sua imagem de perfil para se ajustar ao campo
                abaixo.
              </Modal.Description>
            </Modal.Header>
            <Modal.Content>
              <ImageCrop.Root>
                <S.EditorBackground>
                  <ATMImageCrop
                    ref={imageRef}
                    image={imageURL}
                    zoom={zoom}
                    width={cropSize}
                    height={cropSize}
                  />
                </S.EditorBackground>
              </ImageCrop.Root>
            </Modal.Content>
            <Modal.Footer>
              <S.FooterContent>
                <S.StyledActions>
                  <ImageCrop.Minus onClick={handleDecreaseZoom} />
                  <ImageCrop.Control
                    name="profilePhoto"
                    step={0.1}
                    min={zoomMin}
                    max={zoomLimit}
                    value={zoom}
                    onChange={(e) => {
                      setZoom(Number(e.target.value))
                    }}
                  />
                  <ImageCrop.Plus onClick={handleIncreaseZoom} />
                </S.StyledActions>
                <div className="buttons">
                  <Button fullWidth variant="outline" onClick={onCancel}>
                    Cancelar
                  </Button>
                  <Button fullWidth onClick={handleSave}>
                    Salvar
                  </Button>
                </div>
              </S.FooterContent>
            </Modal.Footer>
          </S.WrapperStyled>
        </Modal.Portal>
      </Modal.Root>
    </>
  )
}
