import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export const useDisableKit = () => {
  const selectedKits = useEquipmentContainerStore((state) => state.selectedKits)

  const disableKit = (kit: IKitDTOWithId) => {
    const kitIsSelected = selectedKits?.some(
      (selectedKit) => selectedKit?.kitId === kit?.kitId,
    )
    const disable = !kitIsSelected && selectedKits?.length === 5
    return disable
  }
  return { disableKit }
}
