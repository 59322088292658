import {
  useAddProposalCreateDimensioning,
  type Payload,
} from 'domains/proposal/create-dimensioning'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useAmplitude } from 'hooks/useAmplitude'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import * as ProposalAction from 'store/actions'
import { type EnumSizingType } from '../../../constants'
import { type IFormValues } from '../types'

interface useCreateSizingProps {
  setIsDimensioningDataScreen: (value: boolean) => void
  setResultadoDimensionamento: (value: boolean) => void
  currentSizingType: EnumSizingType
  statusPercentageFioB: boolean
  porcentagemProducao: number
}

export const useCreateSizing = ({
  setIsDimensioningDataScreen,
  setResultadoDimensionamento,
  currentSizingType,
  statusPercentageFioB,
  porcentagemProducao,
}: useCreateSizingProps) => {
  const { getValues } = useFormContext<IFormValues>()
  const proposalCreateDimensioning = useAddProposalCreateDimensioning()
  const dispatch = useDispatch()
  const { logEvent } = useAmplitude()

  const { validateUserRegistration } = useUserHasFullRegistration()

  const proposal = useSelector((state: any) => state.Reducer1)

  const DownRequestResponse = (allFieldValues: IFormValues, data: any) => {
    dispatch(ProposalAction.setOriginLead(allFieldValues.origin_lead))
    dispatch(ProposalAction.SaveSizingRedux(data))
    if (parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, '')) === 1) {
      dispatch(ProposalAction.SaveStepRedux('1done'))
    }
    dispatch(ProposalAction.SaveActiveSizingRedux(data.id))
    dispatch(ProposalAction.SaveNewSizingRedux(false))
    setResultadoDimensionamento(true)
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    setIsDimensioningDataScreen && setIsDimensioningDataScreen(true)
  }

  const handleCreateDetailedSizing = async () => {
    logEvent(proposalTracker.actions.calculateSizing)

    if (!validateUserRegistration()) {
      return
    }

    const allFieldValues = getValues()

    const payload: Payload = {
      proposal_id: proposal.proposal.id,
      status_percentage_fio_b: statusPercentageFioB,
      tariff_role: currentEnterpriseConfigs?.tariffRole,
      sizing_type: currentSizingType,
      percentage_production:
        currentSizingType !== 'system_potency'
          ? porcentagemProducao
          : undefined,
      ...allFieldValues,
    }

    await proposalCreateDimensioning.mutateAsync(payload).then((res) => {
      DownRequestResponse(allFieldValues, res)
    })
  }

  return {
    handleCreateDetailedSizing,
    proposalCreateDimensioning,
  }
}
