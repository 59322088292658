import styled from 'styled-components'
import { Tab } from '@77sol-ui/molecules'

export const Root = styled(Tab.Root)`
  margin-top: 20px;
  height: auto;
`

export const Trigger = styled(Tab.Trigger)`
  padding-top: 20px !important;
`

export const Content = styled(Tab.Content)`
  padding-top: 24px;
  height: 100%;
  width: 100%;
`
