import { useDispatch, useSelector } from 'react-redux'
import API from 'api'
import * as ProposalAction from 'store/actions'
import { useToast } from '@77sol/core'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

export const useCreateBasicSizing = ({
  selectedClient,
  setLoadingCreateSizing,
  setLoadingCreateQuotation,
  setActiveDimStep,
  formMethods,
}) => {
  const dispatch = useDispatch()
  const proposal = useSelector((state) => state.Reducer1)
  const { onErrorOpenToast } = useToast()
  const { userLogged } = useSelector((state) => state.ReducerAuth)
  const { validateUserRegistration } = useUserHasFullRegistration()

  const { logEvent } = useAmplitude()

  const handleCreateQuote = (sizing) => {
    const origin_lead = formMethods.getValues('origin_lead')

    API.post('/proposal/create/dimensioning/quotation', {
      delivery_state: sizing.estado,
      dimensioning_id: sizing.id,
      origin_lead,
      potency: sizing.potenciaSistema,
      structure: sizing.estrutura,
      quotations_quantity: 3,
    })
      .then((res) => {
        for (const quotation of res.data) {
          dispatch(ProposalAction.SaveQuoteRedux(quotation))
        }
        if (
          Number(proposal.activeStep.toString().replace(/[^0-9]/g, '')) === 1
        ) {
          dispatch(ProposalAction.SaveStepRedux('2'))
        } else {
          setActiveDimStep(2)
        }

        API.get(`/proposal/quotation/roi/calculate/${res.data[0].quotation.id}`)

        API.post('/proposal/quotation/budget/finalized', {
          quotation_id: res.data[0].quotation.id,
        }).then((FinalizedResponse) => {
          dispatch(
            ProposalAction.UpdateQuoteDataRedux(
              FinalizedResponse.data.quotation,
            ),
          )
        })
      })
      .catch(({ response = {} }) => {
        const { message } = response.data || {}
        if (message) {
          onErrorOpenToast(message)
        }
      })
      .finally(() => {
        setLoadingCreateQuotation(false)
        setLoadingCreateSizing(false)
      })
  }

  const handleCreateBasicSizing = () => {
    logEvent(proposalTracker.actions.simulateProject)
    setLoadingCreateSizing(true)

    if (!validateUserRegistration()) {
      return setLoadingCreateSizing(false)
    }

    const allFieldValues = formMethods.getValues()
    const zipcode = (
      selectedClient.cep ? selectedClient.cep : userLogged[0].cep
    ).replace('-', '')

    API.post('/proposal/create/dimensioning', {
      ...allFieldValues,
      proposal_id: proposal.proposal.id,
      filter_stock: '77sol',
      sizing_type: 'default',
      zipcode,
    })
      .then((res) => {
        dispatch(ProposalAction.setOriginLead(allFieldValues.origin_lead))
        dispatch(ProposalAction.SaveSizingRedux(res.data))
        dispatch(ProposalAction.SaveNewSizingRedux(false))
        dispatch(ProposalAction.SaveActiveSizingRedux(res.data.id))
        handleCreateQuote(res.data)
      })
      .catch(() => onErrorOpenToast('Não foi possível criar a cotação!'))
  }

  return { handleCreateBasicSizing }
}
