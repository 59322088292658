import React from 'react'
import propTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { TableLine } from 'components'
import { Typography } from '@77sol/core'
import { isEmpty } from 'utils'
import styles from './styles'

function TableList({
  columns,
  values,
  onRowClick,
  noSelectRow,
  selectedRow,
  LastItem,
  onClickFullRow,
  rowOutlined,
  StartItem,
  headerSticky,
  size,
  headerSize = 'medium',
}) {
  const classes = styles({ headerSticky })

  const handleSelectRow = (row, index) => {
    onRowClick(row, index)
  }

  return (
    <TableContainer className={classes.container}>
      <Table
        className={classes.table}
        stickyHeader={headerSticky}
        aria-label="sticky table">
        <TableHead>
          <TableRow className={classes.head}>
            {StartItem && <TableCell className={classes.titles} align="left" />}
            {columns.map((label, index) => (
              <TableCell key={index} className={classes.titles} align="left">
                <Typography size={headerSize}>{label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {values &&
            values.map((row, index) => (
              <TableLine
                className={row.highlighted && classes.highlighted}
                id={`table_item_${index}`}
                disabled={row?.rowData?.disabled}
                onRowClick={(rowValues) =>
                  row?.rowData?.disabled
                    ? ''
                    : handleSelectRow(rowValues, index)
                }
                values={row}
                rowIndex={index}
                LastItem={LastItem}
                isRowSelected={
                  noSelectRow
                    ? false
                    : !isEmpty(selectedRow) &&
                      selectedRow?.rowData?.rowIndex === index
                }
                fieldsLength={columns.length}
                onClickFullRow={onClickFullRow}
                key={index}
                rowOutlined={rowOutlined}
                StartItem={StartItem}
                headerSticky={headerSticky}
                fontSize={size}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TableList.propTypes = {
  headerWeight: propTypes.oneOf(['bold', 'lighter']),
  onRowClick: propTypes.func,
}

TableList.defaultProps = {
  onRowClick: () => null,
  headerWeight: 'bold',
}

export default TableList
