import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { cancelProposal } from '../services/api'
import { type IProposalDTO } from 'dto/ProposalDTO'

export function useCancelProposalMutation<
  TError = unknown,
  TContext = unknown,
>({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: UseMutationOptions<unknown, TError, IProposalDTO['id'], TContext>) {
  const data = useMutation({
    mutationFn: async (proposalId) => {
      return await cancelProposal(proposalId)
    },
    onMutate,
    onSettled,
    onSuccess,
    onError,
  })
  return data
}
