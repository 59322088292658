import { Modal } from 'components/Modal'
import { Button } from '@77sol-ui/atoms'
import { FormManager } from '../../FormManager'
import { CurrentQuotationSelectedTable } from '../../CurrentQuotationSelectedTable'
import { FormProvider } from 'react-hook-form'
import { useCreateFinancingPerQuotationForm } from '../../FormManager/hooks/useCreateFinancingPerQuotationForm'
import { useState, type FormEvent } from 'react'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import {
  type ICreateFinancingPerQuotationFormProps,
  type DefaultValuesForm,
} from '../../FormManager/types'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { StyledModalContent } from './styles'
import { InsuranceAlert } from 'components/InsuranceAlert'
import { Portal } from 'components/Portal'
import { calculateClientYearsOld } from 'containers/Financing/helpers/calculateClientYearsOld'

interface FormStepProps {
  onCreateFinancing: ICreateFinancingPerQuotationFormProps['onSubmit']
  onPreviousStep: (e: FormEvent) => void
  choosedQuotation: IQuotationDTO
  requestFallback: DefaultValuesForm
  isEnabledInsurance: boolean
  setIsEnabledInsurance: (state: boolean) => void
}

const FORM_ID = 'modal-financing-per-quotation-form'

export const FormStep = ({
  onCreateFinancing,
  onPreviousStep,
  choosedQuotation,
  requestFallback,
  isEnabledInsurance,
  setIsEnabledInsurance,
}: FormStepProps) => {
  const { logEvent } = useAmplitude()
  const methods = useCreateFinancingPerQuotationForm(requestFallback)
  const [isOpenInsuranceAlert, setIsOpenInsuranceAlert] =
    useState<boolean>(false)

  const inputValue = methods.watch('input_value')
  const clientType = methods.watch('client_type')
  const clientBirthDate = methods.watch('client_birth_date')

  const { yearsOld: clientYearsOld } = calculateClientYearsOld(
    clientBirthDate as string,
  )

  const totalFinancingValue = choosedQuotation.total_value - inputValue

  function handleControlInsurance() {
    const { enableInsurance, disableInsurance } = financingTracker.actions
    const newState = !isEnabledInsurance

    const tracker = newState ? enableInsurance : disableInsurance

    logEvent(tracker, {
      financingType: 'Cotação',
    })

    setIsEnabledInsurance(newState)
  }

  function handleControlInsuranceAlert() {
    const { openInsuranceAlert, closeInsuranceAlert } = financingTracker.actions

    const newAlertState = !isOpenInsuranceAlert
    const tracker = newAlertState ? openInsuranceAlert : closeInsuranceAlert

    logEvent(tracker, {
      financingType: 'Cotação',
    })

    setIsOpenInsuranceAlert(newAlertState)
  }

  return (
    <FormProvider {...methods}>
      <Portal id="modal-description">
        <InsuranceAlert.Trigger
          clientType={clientType}
          isOpen={isOpenInsuranceAlert}
          onClick={handleControlInsuranceAlert}
          isEnabledInsurance={isEnabledInsurance}
          clientYearsOld={clientYearsOld}
        />
      </Portal>
      <StyledModalContent>
        {isOpenInsuranceAlert && (
          <InsuranceAlert.Content
            financingValue={totalFinancingValue}
            clientType={clientType}
            clientYearsOld={clientYearsOld}
            isEnabledInsurance={isEnabledInsurance}
            handleControlInsurance={handleControlInsurance}
          />
        )}
        <CurrentQuotationSelectedTable choosedQuotation={choosedQuotation} />
        <FormManager
          formId={FORM_ID}
          onSubmit={onCreateFinancing}
          choosedQuotation={choosedQuotation}
        />
      </StyledModalContent>

      <Modal.Footer>
        <Modal.Close asChild>
          <Button
            type="button"
            onClick={onPreviousStep}
            fullWidth
            variant="outline"
          >
            Voltar
          </Button>
        </Modal.Close>

        <Button fullWidth type="submit" form={FORM_ID}>
          Criar simulação
        </Button>
      </Modal.Footer>
    </FormProvider>
  )
}
