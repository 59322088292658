import { useAmplitude } from 'hooks/useAmplitude'
import { chartFiltersTrackers } from 'services/tracker/events/chartFilters/trackers'
import { type IPerformanceFiltersProps } from '../../..'
import { useChartFilters } from '../../../hooks/useChartFilters'

export function useDesktopFilters({
  filters,
  setFilters,
}: IPerformanceFiltersProps) {
  const { logEvent } = useAmplitude()
  const { INTERVAL, onChangePeriod } = useChartFilters({ filters, setFilters })

  function handleChangePeriod(period: string) {
    logEvent(chartFiltersTrackers.actions.changePeriodFilter, {
      origin: '/dashboard',
      filter: 'Gráfico de Performance',
      period,
    })
    onChangePeriod(period)
  }

  function onChangeInterval(interval: number) {
    const selectedInterval = INTERVAL.find((item) => item.value === interval)

    logEvent(chartFiltersTrackers.actions.changeIntervalFilter, {
      origin: '/dashboard',
      filter: 'Gráfico de Performance',
      interval: selectedInterval?.label,
    })
    setFilters((prev) => ({ ...prev, interval }))
  }

  function onChangeChart(chart: string) {
    logEvent(chartFiltersTrackers.actions.changeChartFilter, {
      origin: '/dashboard',
      filter: 'Gráfico de Performance',
      chart,
    })
    setFilters((prev) => ({ ...prev, chart }))
  }

  return {
    handleChangePeriod,
    onChangeInterval,
    onChangeChart,
    INTERVAL,
  }
}
