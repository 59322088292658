import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'DM Sans';
  margin: 64px 0;
`
