import styled from 'styled-components'
import { Button as ButtonUI } from '@77sol-ui/atoms'

export const Button = styled(ButtonUI)`
  position: relative;
  overflow: hidden;
`

export const Loading = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: inherit;
  background-color: inherit;

  > svg {
    position: absolute;
  }
`
