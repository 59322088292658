import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import styles from './styles'

function IncrementStepper({
  type, value, setValue, white, min, ...props
}) {
  const classes = styles({ type, white })

  const handleIncrement = () => {
    setValue(value + 1)
  }

  const handleDecrement = () => {
    if (value - 1 < min) {
      setValue(1)
    } else {
      setValue(value - 1)
    }
  }

  const displayCounter = value > 0

  return (
    <ButtonGroup
      {...props}
      orientation={type}
      variant="text"
      size="small"
      aria-label="small outlined button group"
    >
      {displayCounter && (
        <Button className={classes.incrementButton} onClick={handleDecrement}>
          -
        </Button>
      )}
      {displayCounter && (
        <Button className={classes.displayButton} disabled>
          {value}
        </Button>
      )}
      <Button className={classes.incrementButton} onClick={handleIncrement}>
        +
      </Button>
    </ButtonGroup>
  )
}

IncrementStepper.propTypes = {
  type: PropTypes.oneOf(['vertical', 'horizontal']),
}

IncrementStepper.defaultProps = {
  type: 'horizontal',
}

export default IncrementStepper
