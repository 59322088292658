import { defineState } from 'redux-localstore'

const INITIAL_STATE = {
  isProPlan: false,
  planInfos: {},
  logo: {},
  avatar: {},
  userProfile: {},
  userProfileCorporateName: '',
  allSchedules: [],
  costTypes: [],
  productsCategories: [],
  integratorUser: [],
  extraFields: [],
  roiItemsTypes: [],
  returnYears: [],
  precifications: [],
  commissionings: [],
}

const initialState = defineState(INITIAL_STATE)('ReducerProfile')

export function ReducerProfile(state = initialState, action) {
  switch (action.type) {
    case 'SET_IS_PRO_PLAN':
      return { ...state, isProPlan: action.isProPlan }
    case 'SET_PLAN_INFOS':
      return { ...state, planInfos: action.planInfos }
    case 'SET_LOGO':
      return { ...state, logo: action.logo }
    case 'SET_AVATAR':
      return { ...state, avatar: action.avatar }
    case 'SET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile }
    case 'SET_USER_PROFILE_CORPORATE_NAME':
      return {
        ...state,
        userProfileCorporateName: action.userProfileCorporateName,
      }
    case 'SET_ALL_SCHEDULES':
      return { ...state, allSchedules: action.allSchedules }
    case 'SET_COST_TYPES':
      return { ...state, costTypes: action.costTypes }
    case 'SET_PRODUCTS_CATEGORIES':
      return { ...state, productsCategories: action.productsCategories }
    case 'SET_INTEGRATOR_USER':
      return { ...state, integratorUser: action.integratorUser }
    case 'SET_EXTRA_FIELDS':
      return { ...state, extraFields: action.extraFields }
    case 'SET_ROI_ITEMS_TYPES':
      return { ...state, roiItemsTypes: action.roiItemsTypes }
    case 'SET_RETURN_YEARS':
      return { ...state, returnYears: action.returnYears }
    case 'SET_PRECIFICATIONS':
      return { ...state, precifications: action.precifications }
    case 'SET_COMMISSIONINGS':
      return { ...state, commissionings: action.commissionings }
    default:
      return state
  }
}
