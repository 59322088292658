import { Typography } from '@77sol/core'
import { useStyles } from './styles'
import { KitItem } from './components/KitItem'
import { type OrderSummaryProps } from './OrderSummary.types'
import { Banner } from '../Banner'

export function OrderSummary({
  kit,
  totalValue,
}: OrderSummaryProps): JSX.Element {
  const classes = useStyles()

  return (
    <div data-testid="order-summary-container" className={classes.leftSide}>
      <div>
        <Typography color="primary" colorWeight="300" size="large">
          Equipamentos
        </Typography>

        {kit.map((item) => (
          <KitItem key={item.id} item={item} />
        ))}

        <div data-testid="total-container" className={classes.totalValue}>
          <Typography size="large">
            {'Total: '}
            <b>
              {parseFloat(totalValue).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </b>
          </Typography>
        </div>

        <Banner />
      </div>
    </div>
  )
}
