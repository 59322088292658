import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const LoadingMore = styled.span`
  font-family: DM Sans;
  color: ${colors.gray[300]};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`
