import { formatReal } from 'utils/format'
import { useLogos } from './useLogos'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'

export const useKitCard = (kit: IKitDTOWithId) => {
  const selectKit = useEquipmentContainerStore((state) => state.selectKit)
  const selectedKits = useEquipmentContainerStore((state) => state.selectedKits)

  const { firstLogo, hasTwoLogos, secondLogo } = useLogos({
    logos: kit?.logos || [],
  })

  const totalValueFormated = formatReal(kit.valueTotal)

  const isChecked = selectedKits?.some((item) => item?.kitId === kit?.kitId)

  const buttonLabel = isChecked ? 'Desmarcar kit' : 'Escolher kit'

  const handleSelectKit = () => {
    selectKit(kit)
  }

  return {
    selectedKits,
    handleSelectKit,
    totalValueFormated,
    isChecked,
    firstLogo,
    hasTwoLogos,
    secondLogo,
    buttonLabel,
  }
}
