import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: ({ PaperRedirectVisible }) => ({
    overflow: 'auto',
    height: `calc(100vh - ${PaperRedirectVisible ? 335 : 220}px)`,
    [breakpoints.down('sm')]: {
      height: `calc(100vh - ${PaperRedirectVisible ? 280 : 185}px)`,
    },
  }),
  noContent: {
    display: 'grid',
    placeContent: 'center',
    height: `calc(100vh - 215px)`,
    [breakpoints.down('sm')]: {
      height: `calc(100vh - 125px)`,
    },
  },
}))
