const TEXT_LIMIT = 20

export function truncateText(text: string) {
  const [firstText = '', secondText = ''] = String(text).split(' ')
  const textLength = `${firstText} ${secondText}`.length

  if (textLength > TEXT_LIMIT) {
    const firstTextLength = firstText.length
    const secondTextLimit = TEXT_LIMIT - firstTextLength
    return `${firstText} ${secondText.slice(0, secondTextLimit - 1)}...`
  }

  return `${firstText} ${secondText}`
}
