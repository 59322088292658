import { type ISaveDiscount } from '../types'
import { useState } from 'react'
import { useToast } from '@77sol/core'
import API from 'api'

interface IUseSaveDiscount extends ISaveDiscount {}

export function useSaveDiscount({
  quotationId,
  onError,
  onSuccess,
}: IUseSaveDiscount) {
  const [isLoading, setIsLoading] = useState(false)
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  function handleSaveDiscount(value: string | number) {
    setIsLoading(true)

    API.patch(`/proposal/quotation/discount-delivery/${quotationId}`, {
      type: 'discount_percentage',
      value,
    })
      .then((response: unknown) => {
        onSuccessOpenToast('Desconto alterado com sucesso!')
        onSuccess?.(response)
      })
      .catch((error: unknown) => {
        onErrorOpenToast('Não foi possível alterar o desconto!')
        onError?.(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    isLoading,
    handleSaveDiscount,
  }
}
