import { useQuery } from '@tanstack/react-query'
import { GET_KITS } from '../constants/endpointKeys'
import { kitService } from '../services/api'

export const useGetKits = (dimensioningId: string, options = {}) => {
  const data = useQuery({
    queryKey: [GET_KITS, dimensioningId],
    queryFn: async () => {
      return await kitService.getKits(dimensioningId)
    },
    ...options,
  })

  return data
}
