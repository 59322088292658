import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    padding: 32,
    background: palette.primary[300],
    color: palette.white,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 10,
    '& svg': {
      stroke: palette.primary[300],
      strokeWidth: 3,
      width: 100,
    },
  },
  title: {
    '& h6': {
      margin: '32px 0',
      color: palette.white,
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '38px',
    },
  },
  context: {
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& p': {
      textAlign: 'center',
      color: palette.white,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    },
  },
}))
