import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

interface SuccessFinancingRequestModalData {
  isSuccessFinancingModalOpen: boolean
  setSuccessFinancingModalOpen: (value: boolean) => void
}

interface ProviderProps {
  children: ReactNode
}

const SuccessFinancingRequestModalContext =
  createContext<SuccessFinancingRequestModalData>(
    {} as SuccessFinancingRequestModalData,
  )

function SuccessFinancingRequestModalProvider({ children }: ProviderProps) {
  const [isSuccessFinancingModalOpen, setIsSuccessFinancingModalOpen] =
    useState(false)

  const setSuccessFinancingModalOpen = useCallback((value: boolean) => {
    setIsSuccessFinancingModalOpen(value)
  }, [])

  const contextValue = useMemo(
    () => ({
      isSuccessFinancingModalOpen,
    }),
    [isSuccessFinancingModalOpen],
  )

  return (
    <SuccessFinancingRequestModalContext.Provider
      value={{
        ...contextValue,
        setSuccessFinancingModalOpen,
      }}
    >
      {children}
    </SuccessFinancingRequestModalContext.Provider>
  )
}

const useSuccessFinancingRequestModalContext = () => {
  const context = useContext(SuccessFinancingRequestModalContext)
  return context
}

export {
  useSuccessFinancingRequestModalContext,
  SuccessFinancingRequestModalProvider,
}
