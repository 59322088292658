import { type IButtonProps } from '@77sol-ui/atoms'
import { useRequestFinancing } from './hooks/useRequestFinancing'
import { StyledButton } from './styles'

interface IRequestFinancingProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
}

export function RequestFinancingButton({
  text = 'Solicitar Financiamento',
  ...props
}: IButtonProps & IRequestFinancingProps) {
  const { handleOpenFinancingPerValueModal } = useRequestFinancing()

  return (
    <StyledButton
      variant="primary"
      data-testid="request-financing-button"
      onClick={() => {
        handleOpenFinancingPerValueModal({})
      }}
      {...props}
    >
      {text}
    </StyledButton>
  )
}
