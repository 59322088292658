export const INITIAL = {
  VALUES: {
    account_type: '',
    bank: '',
    agency: '',
    account: '',
    digit: '',
    cpf: '',
    cnpj: '',
    account_name: '',
  },
}
