import distributorRoutes from '_Distributor/routes'
import { type UserType } from 'acl/config/types'
import { useAcl } from 'acl/hooks/useAcl'
import integratorRoutes from 'routes'
import { type INestedRoute } from 'types/routes'

export function useRenderRoutes() {
  const { userType } = useAcl()

  const routesByUserType: Record<UserType, INestedRoute[]> = {
    integrator: integratorRoutes,
    distributor: distributorRoutes,
  }

  const routes: INestedRoute[] = routesByUserType[userType]

  return { routes }
}
