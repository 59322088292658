import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 16px;

  padding: 24px 18px 14px 18px;
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
    display: block !important;
  }
  &::-webkit-scrollbar-track {
    margin-right: 4px;
    margin-bottom: 24px;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }
  &::-webkit-scrollbar-button {
    display: none !important;
  }
`