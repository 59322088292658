import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useChangeEquipmentQuotation } from 'domains/quotation/services/hooks/useChangeEquipmentQuotation'
import { type ChangeEquipmentProps } from 'domains/quotation/services/types'
import { useToast } from '@77sol/core'
import { queryClient } from 'services/queryClient'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

interface IUseChangeEquipmentProps {
  handleCloseModal: () => void
}

export function useChangeEquipment({
  handleCloseModal,
}: IUseChangeEquipmentProps) {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { quotationSelected, dimensioningId } = useEquipmentContainerStore()

  const { changeEquipmentMutation } = useChangeEquipmentQuotation()

  async function handleChangeEquipment(
    newProduct: IQuotationProductDTO,
    currentProduct: IQuotationProductDTO,
    quote: any,
    qtde: number,
  ) {
    const payload: ChangeEquipmentProps = {
      current_product_id: currentProduct.id,
      quotation_id: quote.quotation.id,
      new_product_id: newProduct.id,
      new_product_qty: qtde,
      product_qty: qtde,
    }

    try {
      await changeEquipmentMutation.mutateAsync(payload)
      await queryClient.invalidateQueries([GET_DIMENSIONING, dimensioningId])
      onSuccessOpenToast('Equipamento alterado com sucesso!')
      handleCloseModal()
    } catch (error: any) {
      onErrorOpenToast(
        error?.response?.data?.message ||
          'Não foi possível alterar o equipamento!',
      )
    }
  }

  return { quotationSelected, handleChangeEquipment }
}
