import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { ArrowChevronUpIcon } from '@77sol/icons/dist'
import { Checkbox, Divider, Paper, Typography } from '@77sol/core'
import useStyles from './styles'

function AccordionPaymentMethod({
  children,
  title = '',
  subtitle = '',
  description = '',
  expand = false,
  checked = false,
  setExpand = () => {},
  setChecked = () => {},
}) {
  const classes = useStyles({ expand })

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Checkbox
            size="small"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <Box marginX={2}>
            <Typography type="medium" color="grayscale" colorWeight="900">
              {title}
            </Typography>
            {checked && (
              <>
                <Typography type="small" color="grayscale" colorWeight="500">
                  {subtitle}
                </Typography>
                <Typography type="xsmall" color="grayscale" colorWeight="400">
                  {description}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        <Box alignSelf="center">
          <ArrowChevronUpIcon
            width="20"
            className={classes.arrow}
            onClick={() => setExpand(!expand)}
          />
        </Box>
      </Box>
      {expand && (
        <>
          <Box marginY={2}>
            <Divider />
          </Box>
          {children}
        </>
      )}
    </Paper>
  )
}

AccordionPaymentMethod.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  expand: PropTypes.bool,
  checked: PropTypes.bool,
  setExpand: PropTypes.func,
  setChecked: PropTypes.func,
}

export default AccordionPaymentMethod
