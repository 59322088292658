export default [
  {
    color: 'grayscale',
    label: 'Rascunho',
    slug: 'rascunho',
    value: '1',
  },
  {
    color: 'primary',
    label: 'Enviada',
    slug: 'enviada',
    value: '2',
  },
  {
    color: 'orange',
    label: 'Em andamento',
    slug: 'em-andamento',
    value: '3',
  },
  {
    color: 'green',
    label: 'Aceita',
    slug: 'aceita',
    value: '4',
  },
  {
    color: 'red',
    label: 'Recusada',
    slug: 'recusada',
    value: '5',
  },
  {
    color: 'green',
    label: 'Iniciada',
    slug: 'iniciada',
    value: '6',
  },
  {
    color: 'primary',
    label: 'Finalizada',
    slug: 'finalizada',
    value: '7',
  },
]
