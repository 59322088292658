import { Wrapper } from './styles'

export type IContentProps = React.HtmlHTMLAttributes<HTMLDivElement>

export const Content = ({ children, ...props }: IContentProps) => {
  return (
    <Wrapper data-testid="card-content" {...props}>
      {children}
    </Wrapper>
  )
}
