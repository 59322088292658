import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  container: ({ downSm }) => ({
    maxHeight: '80vh',
    maxWidth: downSm ? '100vw' : '50vw',
  }),
  tariffGraphic: ({ downSm }) => ({
    margin: 'auto',
    display: 'block',
    width: downSm ? '100%' : '80%',
  }),
}))

export default useStyles
