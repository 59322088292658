import React from 'react'
import PropTypes from 'prop-types'
import palette from 'app_palette'
import { Paper } from '..'

function Badge({
  color,
  colorWeight,
  backgroundWeight,
  children,
  size,
  type,
  ...props
}) {
  return (
    <Paper
      {...props}
      style={{
        width: 'fit-content',
        backgroundColor: palette[color][backgroundWeight],
        color: palette[color][colorWeight],
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
        textAlign: 'center',
        height: size === 'small' ? '32px' : '40px',
        border:
          type === 'solid'
            ? 'none'
            : `2px solid ${palette[color][parseInt(backgroundWeight) + 100]}`,
        boxShadow: 'none',
        padding: '5px 8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Paper>
  )
}

Badge.propTypes = {
  color: PropTypes.string,
  colorWeight: PropTypes.string,
  backgroundWeight: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  type: PropTypes.oneOf(['solid', 'outlined']),
}

Badge.defaultProps = {
  color: 'primary',
  colorWeight: '300',
  backgroundWeight: '100',
  size: 'small',
  type: 'solid',
}

export default Badge
