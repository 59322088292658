import styled from 'styled-components'

export * from '../styles'

export const Wrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;

    > div {
      width: 100%;
    }
  }
`
