import { TextField } from 'components/Controllers/TextField'
import * as S from '../styles'
import { useFormContext } from 'react-hook-form'

export function CEPForm() {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <S.Container>
      <S.Label>Insira o CEP do local da instalação</S.Label>
      <TextField
        control={control}
        label="CEP*"
        name="zipcode"
        mask="cep"
        type="tel"
        error={errors.zipcode?.message as string}
      />
    </S.Container>
  )
}
