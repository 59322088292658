import appTheme from 'app_theme'
import { copernicoEnterpriseConfig } from './copernico/copernico'
import { defaultEnterpriseConfig } from './default/default'
import { type EnterpriseInformation } from './globalConfig.types'

let config: EnterpriseInformation

if (appTheme === 'copernico') {
  config = copernicoEnterpriseConfig
} else {
  config = defaultEnterpriseConfig
}

export default config
