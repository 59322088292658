import * as S from './styles'
import { CompareKitCardHeaderInfo } from './components/Info'
import { formatReal } from 'utils/format'
import { CompareKitHeaderActions } from './components/Actions'

interface CompareKitCardHeaderProps {
  id: number
  potency: number
  overload: number
  equipmentValue: number
}

export function CompareKitCardHeader({
  id,
  overload,
  potency,
  equipmentValue,
}: CompareKitCardHeaderProps) {
  const formatedEquipmentsValue = formatReal(equipmentValue)
  return (
    <S.Header>
      <div className="quotation-main-actions">
        <h1>Cotação #{id}</h1>
        <CompareKitHeaderActions quotationId={id} />
      </div>
      <CompareKitCardHeaderInfo>
        Potência do kit:
        <span>
          <span className="main">{potency}</span>
          kWp
        </span>
      </CompareKitCardHeaderInfo>
      <CompareKitCardHeaderInfo>
        Sobrecarga:
        <span>
          <span className="main">{overload}</span>%
        </span>
      </CompareKitCardHeaderInfo>
      <CompareKitCardHeaderInfo>
        Valor total:
        <span className="main">{formatedEquipmentsValue}</span>
      </CompareKitCardHeaderInfo>
    </S.Header>
  )
}
