import React, { Fragment, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { RouteWithSubRoutes } from 'components'

import { Topbar } from './components'

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}))

const Auth = props => {
  const { route } = props

  const classes = useStyles()

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {route?.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Suspense>
      </main>
    </Fragment>
  )
}

Auth.propTypes = {
  route: PropTypes.object,
}

export default Auth
