import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { Grid, Paper } from '@material-ui/core'
import { Typography, Spacer } from '@77sol/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const useStyles = makeStyles(() => ({
  root: {
    margin: '10px',
    cursor: 'pointer',
    minHeight: '180px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.04)',
    padding: '24px',
  },
}))

function ProdutosCard({ produto, className, ...rest }) {
  const classes = useStyles()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Paper
      {...rest}
      className={clsx(classes.root, className)}
      style={{
        marginLeft: downSm ? '0px' : '10px',
        marginRight: downSm ? '0px' : '10px',
        height: '100%',
      }}
    >
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid
          item
          md={4}
          xs={4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {produto.imagens ? (
            <img
              style={{ width: '70%' }}
              alt={produto.nome}
              src={produto.imagens}
            />
          ) : (
            <Typography>Sem imagem</Typography>
          )}
        </Grid>
        <Grid item md={8} xs={8}>
          <Typography size="xsmall" color="primary" colorWeight="300">
            {currentEnterpriseConfigs.enterpriseName}
          </Typography>
          <Spacer size="12" weight="700" direction="horizontal" />
          <Typography size="small">{produto.nome}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

ProdutosCard.propTypes = {
  className: PropTypes.string,
  produto: PropTypes.object.isRequired,
}

export default ProdutosCard
