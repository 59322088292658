import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { Loader2, Trash } from 'lucide-react'
import { ProductRow } from './components/ProductsRow'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useRemoveProduct } from './hooks/useRemoveProduct'

interface IProductRemoveModalProps {
  product: IQuotationProductDTO | null
  quotationId?: number
  onClose: () => void
  onError?: () => void
  onSuccess?: () => void
}

export function ProductRemoveModal({
  product,
  quotationId,
  onClose,
  onError,
  onSuccess,
}: IProductRemoveModalProps) {
  const { isLoading, handleRemove } = useRemoveProduct({
    product,
    quotationId,
    onError,
    onSuccess,
  })

  if (!product) {
    return null
  }

  return (
    <Modal.Root open onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper id="modal-delete-equipment" maxWidth="800px">
          <Modal.Header>
            <Modal.Close />
            <Modal.Icon icon={Trash} />
            <Modal.Title>Tem certeza que deseja excluir esse item?</Modal.Title>
            <Modal.Description>
              Essa ação não poderá ser desfeita.
            </Modal.Description>
          </Modal.Header>
          <Modal.Content>
            <ProductRow product={product} />
          </Modal.Content>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button variant="outline" fullWidth>
                Cancelar
              </Button>
            </Modal.Close>
            <Button fullWidth disabled={isLoading} onClick={handleRemove}>
              {isLoading ? (
                <Loader2 size={24} className="atm-animate-spinSlow" />
              ) : (
                'Excluir'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
