import { type ILineChartContext } from '@77sol-ui/molecules'
import {
  customizeTicks,
  customizeTooltip,
} from '../../../utils/customizeChartLabels'
import { type IFormattedDataToChartJs } from '../../../utils/formatDataToChartJs'
import { type IResponseGetSustainabilityData } from 'domains/home/dashboard-analysis-sustainability/services/types'

interface IUseCustomizeLabelsProps {
  period: string
  data?: IResponseGetSustainabilityData
  units?: IFormattedDataToChartJs['units']
}

export function useCustomizeLabels({
  period,
  units,
  data,
}: IUseCustomizeLabelsProps) {
  function customizePowerAxisLabel(label: string | number) {
    return `${Math.round(Number(label)) || 0} ${units?.[0] || ''}`
  }

  function customizeTonAxisLabel(label: string | number) {
    return `${Math.round(Number(label)) || 0} ${units?.[1] || ''}`
  }

  function customizeDataAxisLabel(value: string | number) {
    return customizeTicks?.[period]?.(data?.x_axis?.[value as number] as string)
  }

  function customizeTooltipTitle(context: ILineChartContext[]) {
    return customizeTooltip?.[period]?.(context[0]?.label)
  }

  function customizeTooltipLabel(context: ILineChartContext) {
    let label: string = context.dataset.label || ''

    if (context?.parsed?.y !== null) {
      const isTon = context?.datasetIndex === 1

      const value = isTon
        ? Number(context.parsed.y).toFixed(1)
        : context.parsed.y

      label = `${label}: ${value} ${units?.[context?.datasetIndex] || ''}`
    }

    return label
  }

  return {
    customizePowerAxisLabel,
    customizeTonAxisLabel,
    customizeDataAxisLabel,
    customizeTooltipTitle,
    customizeTooltipLabel,
  }
}
