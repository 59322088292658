interface IMessages {
  success: string
  error: string
}

export const acceptLeadMessages: IMessages = {
  success: 'Lead(s) aceito(s) com sucesso!',
  error: 'Erro ao aceitar o(s) lead(s)!',
}

export const rejectLeadsMessages: IMessages = {
  success: 'Lead(s) rejeitado(s) com sucesso!',
  error: 'Erro ao rejeitar o(s) lead(s)!',
}
