import React from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { Button, Modal, Spacer, Typography } from '@77sol/core'
import TusdGraphicImage from '../../assets/img/tusd-graphic.png'
import useStyles from './styles'

function ModalLegislationWireB({ open = false, onClose }) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles({ downSm })

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.container}>
        <Typography
          color="primary"
          colorWeight="300"
          size={downSm ? 'medium' : 'large'}
        >
          Explicação sobre a Lei 14.300/2022
        </Typography>
        <Spacer size="16" direction="horizontal" />
        <Typography size="small">
          Como é composta a tarifa de energia?
        </Typography>
        <Spacer size="8" direction="horizontal" />
        <img
          src={TusdGraphicImage}
          alt="gráfico de tarifas"
          className={classes.tariffGraphic}
        />
        <Spacer size="4" direction="horizontal" />
        <Typography
          size="small"
          color="grayscale"
          colorWeight="500"
          style={{ display: 'initial' }}
        >
          Por padrão inserimos <b>30%</b> como percentual do fio B na TUSD da
          tarifa, mas o ideal é que você personalize.
        </Typography>
        <Spacer size="16" direction="horizontal" />
        <Typography size="small">O que diz a lei?</Typography>
        <Spacer size="8" direction="horizontal" />
        <Typography size="small" color="grayscale" colorWeight="500">
          Houve algumas mudanças para quem gera a própria energia ou deseja
          instalar uma usina em sua residência ou comércio. A cobrança de uma
          taxa (Fio B da TUSD) que incide na conta de luz é a principal.
        </Typography>
        <Spacer size="16" direction="horizontal" />
        <Typography size="small">
          Como ocorrerá a tributação do fio B?
        </Typography>
        <Spacer size="8" direction="horizontal" />
        <Typography size="small" color="grayscale" colorWeight="500">
          A cobrança do Fio B será repassada aos poucos ao consumidor, conforme
          o cronograma previsto no marco legal. O percentual do Fio B a ser
          cobrado a cada ano é:
          <br />
          2023 - 15%
          <br />
          2024 - 30%
          <br />
          2025 - 45%
          <br />
          2026 - 60%
          <br />
          2027 - 75%
          <br />
          2028 - 90%
          <br />
          2029 - haverá novas regras, as quais serão abordadas em um próximo
          artigo.
        </Typography>
        <Spacer size="16" direction="horizontal" />
        <Typography size="small">
          Como o cálculo funciona na 77 caso esta opção esteja habilitada?
        </Typography>
        <Spacer size="8" direction="horizontal" />
        <Typography size="small" color="grayscale" colorWeight="500">
          Iremos calcular o dimensionamento levando em consideração o percentual
          do Fio B na TUSD dentro da tarifa e em 7 anos iremos calcular no
          projeto o impacto da tributação progressiva.
        </Typography>
        <Spacer size="16" direction="horizontal" />
        <Box paddingTop={2}>
          <Button
            noMargin
            fullWidth
            size="small"
            variant="contained"
            onClick={onClose}
          >
            Entendi
          </Button>
        </Box>
        <Spacer size="48" direction="horizontal" />
      </Box>
    </Modal>
  )
}

export default ModalLegislationWireB
