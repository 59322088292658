import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useUpdateKits } from './useUpdateKits'
import { useAmplitude } from 'hooks/useAmplitude'
import { bottomMenuTracker } from 'services/tracker/events/bottomMenu/trackers'

export function useChooseKitFooter() {
  const { logEvent } = useAmplitude()
  const { updateKits, isLoadingKits } = useUpdateKits()

  const selectedKits = useEquipmentContainerStore((state) => state.selectedKits)
  const currentTab = useEquipmentContainerStore((state) => state.currentTab)
  const changeTab = useEquipmentContainerStore((store) => store.changeTab)

  const kitQuantity = selectedKits?.length
  const hasMoreThanOneKitSelected = kitQuantity > 1

  const maxKits = kitQuantity === 5
  const titleComplement = maxKits ? 'o máximo de 5' : kitQuantity

  const title = `${
    hasMoreThanOneKitSelected
      ? `Você selecionou ${titleComplement} kits para personalizar`
      : `${kitQuantity} kit para visualizar`
  }`

  const description = hasMoreThanOneKitSelected
    ? 'Personalize agora mesmo os kits escolhidos.'
    : 'Você pode selecionar até 5 kits para personalizar.'

  function handleCustomizeKit() {
    logEvent(bottomMenuTracker.actions.next, {
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })

    updateKits(selectedKits).then(() => {
      changeTab(TAB_ENUM.CUSTOMIZE_KIT)
      document
        .getElementById('customize-kit')
        ?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  function handleCompareKits() {
    logEvent(bottomMenuTracker.actions.compareKits, {
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })

    updateKits(selectedKits).then(() => {
      changeTab(TAB_ENUM.COMPARE_KIT)
    })
  }

  return {
    title,
    description,
    isLoadingKits,
    handleCustomizeKit,
    handleCompareKits,
    hasMoreThanOneKitSelected,
  }
}
