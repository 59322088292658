import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button:nth-of-type(1) {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    flex-direction: column;

    button {
      width: 100%;
    }

    button:nth-of-type(1) {
      order: 2;
      margin-top: 14px;
      margin-right: 0;
    }

    button:nth-of-type(2) {
      order: 1;
    }
  }
`
