import { Select, ToggleSelect, Tooltip } from '@77sol-ui/molecules'
import {
  CHARTS_FILTER,
  CHARTS_FILTER_DISABLED,
  CHART_TOOLTIP,
  PERIODS_FILTER,
} from '../../constants'
import { Radio } from '@77sol-ui/atoms'
import { type IPerformanceFiltersProps } from '../..'
import * as S from './styles'
import { useDesktopFilters } from './hooks/useDesktopFilters'
import { TooltipLockedToFaas } from '_Distributor/components/TooltipLockedToFaas'

export function DesktopFilters({
  filters,
  setFilters,
}: IPerformanceFiltersProps) {
  const { handleChangePeriod, onChangeChart, onChangeInterval, INTERVAL } =
    useDesktopFilters({ filters, setFilters })

  return (
    <S.FiltersWrapper>
      <S.PeriodFilterWrapper>
        <p>Filtrar por:</p>
        <Select.Root value={filters.period} onValueChange={handleChangePeriod}>
          <Select.Trigger>
            <Select.Value />
            <Select.Icon />
          </Select.Trigger>
          <Select.Portal>
            <Select.Content>
              <Select.Viewport>
                {PERIODS_FILTER.map((item) => (
                  <Select.Item key={item.value} value={item.value}>
                    {item.label}
                  </Select.Item>
                ))}
              </Select.Viewport>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </S.PeriodFilterWrapper>

      <ToggleSelect.Root>
        {INTERVAL.map((item, index) => {
          const isLastIndex = index + 1 === INTERVAL.length
          return (
            <>
              <ToggleSelect.Item
                key={item.value}
                onClick={() => {
                  onChangeInterval(item.value)
                }}
                active={filters.interval === item.value}
              >
                {item.label}
              </ToggleSelect.Item>
              {!isLastIndex && <ToggleSelect.Separator />}
            </>
          )
        })}
      </ToggleSelect.Root>

      <Radio.Root>
        <Radio.Group defaultValue={filters.chart}>
          {CHARTS_FILTER.map((item) => (
            <Tooltip.Provider key={item.value}>
              <Tooltip.Root>
                <Tooltip.Trigger>
                  <Radio.Item
                    name={item.label}
                    value={item.value}
                    onClick={() => {
                      onChangeChart(item.value)
                    }}
                  >
                    {item.label}
                  </Radio.Item>
                </Tooltip.Trigger>

                <Tooltip.Portal>
                  <Tooltip.Content
                    variant="white"
                    sideOffset={10}
                    textAlign="center"
                    maxWidth="280px"
                  >
                    {CHART_TOOLTIP[item.value as keyof typeof CHART_TOOLTIP]}
                    <Tooltip.Arrow variant="white" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          ))}

          {CHARTS_FILTER_DISABLED.map((item) => (
            <TooltipLockedToFaas key={item.value}>
              <Radio.Item
                name={item.label}
                value={item.value}
                disabled={item.disabled}
                onClick={() => {
                  onChangeChart(item.value)
                }}
              >
                {item.label}
              </Radio.Item>
            </TooltipLockedToFaas>
          ))}
        </Radio.Group>
      </Radio.Root>
    </S.FiltersWrapper>
  )
}
