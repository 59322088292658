import { useAmplitude } from 'hooks/useAmplitude'
import { useParams } from 'hooks/useParams'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { financingTracker } from 'services/tracker/events/financing/trackers'

type AmplitudeFields = Record<string, string>

export interface FilterFields {
  status: string
  date_initial: string | null
  search: string | null
}

interface IUseFinancingFiltersProps {
  onFilter?: () => void
  isPerValue: boolean
}

export function useFinancingFilters({
  onFilter,
  isPerValue,
}: IUseFinancingFiltersProps) {
  const tab = isPerValue ? 'Valor' : 'Cotação'

  const { history, params } = useParams()
  const { handleSubmit, control, setValue, reset } = useForm<FilterFields>({
    defaultValues: {
      status: '',
      date_initial: null,
      search: null,
    },
  })
  const { logEvent } = useAmplitude()
  const filters = useWatch({
    control,
  })

  useEffect(() => {
    const paramsKeys = Array.from(params.entries()).filter(
      (item) => item[0] !== 'page',
    )
    for (const param of paramsKeys) {
      const field = param[0] as keyof FilterFields
      const value = param[1]
      setValue(field, value)
    }
  }, [params, setValue])

  function handleFilter(filtersFields: FilterFields) {
    const filteredFields: AmplitudeFields = {}
    Object.entries(filtersFields).forEach(([field, value]) => {
      if (value) {
        params.set(field, value)
        filteredFields[field] = value
      }
      if (!value) params.delete(field)
    })

    if (Object.keys(filteredFields).length > 0) {
      logEvent(financingTracker.actions.financingsFilter, {
        origin: '/financiamentos',
        tab,
        ...filteredFields,
      })
    }

    onFilter?.()
    history.push(`?${params.toString()}`)
  }

  function handleClearFilters() {
    const paramsKeys = Array.from(params.keys())
    for (const param of paramsKeys) {
      params.delete(param)
    }

    reset()
    logEvent(financingTracker.actions.clearFinancingsFilter, {
      origin: '/financiamentos',
      tab,
    })
    history.push('/financiamentos')
  }

  function handleApplyFilters(fields: FilterFields) {
    handleFilter(fields)
  }

  return {
    filters,
    handleSubmit,
    control,
    handleClearFilters,
    handleApplyFilters,
  }
}
