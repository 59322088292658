export const copernico = {
  enterpriseName: 'Copérnico',
  stockName: 'Estoque Copérnico',
  terms_of_use:
    'https://termosportabilidade.s3.sa-east-1.amazonaws.com/Termos+e+condi%C3%A7%C3%B5es+-+integrador+(1).pdf',
  requestSiteVideoUrl: 'https://www.youtube.com/embed/6B8OT4cfTfc?controls=0',
  titlePage: (page: string) => `${page} | Copérnico`,
  orderButtonTitle: 'Gerar pedido',
  whatsappNumber:
    'https://api.whatsapp.com/send?phone=551131360251&text=Ol%C3%A1!%20Tenho%20uma%20dúvida!',
  budgetTitle: 'Qual orçamento deseja gerar',
  budgetSubtitle:
    'Selecione o orçamento dentro da proposta selecionada que deseja gerar um pedido',
  budgetButtonTitle: 'Gerar',
  phoneNumber: 551126263932,
  termsUse: true,
  showBestOption: true,
  deliveryForecast: false,
  planBadge: false,
  hideQuotationFilterColumn: true,
  hasFinancingPerValue: false,
  quotationModal: {
    hasFilterProperty: true,
    deliveryState: true,
    freeShipping: true,
    canShowFilterInversor: true,
    validationInputInversor: true,
  },
  stepOne: {
    filters: {
      phaseAndVoltage: {
        hasFilter: true,
        isRequired: true,
      },
    },
    deliveryState: true,
    freeShipping: true,
  },
  navBar: {
    showLogo: true,
  },
  pagamentoEdit: {
    defaultBank: 'Safra',
    imageBank:
      'https://copernicoassets.s3.sa-east-1.amazonaws.com/banks/safra.svg',
    monthlyInterestRate: '1.59',
    optionsEntrie: [
      { label: '0%', value: '0.00' },
      { label: '10%', value: '0.10' },
      { label: '20%', value: '0.20' },
      { label: '30%', value: '0.30' },
      { label: '40%', value: '0.40' },
      { label: '50%', value: '0.50' },
    ],
    optionsShortage: [
      { label: '90 dias', value: 90 },
      { label: '120 dias', value: 120 },
    ],
    optionsInstallments: [
      { label: '6 parcelas', value: 6 },
      { label: '12 parcelas', value: 12 },
      { label: '24 parcelas', value: 24 },
      { label: '36 parcelas', value: 36 },
      { label: '48 parcelas', value: 48 },
      { label: '60 parcelas', value: 60 },
      { label: '72 parcelas', value: 72 },
      { label: '84 parcelas', value: 84 },
    ],
  },
  orderGeneratedText: 'Pedido Gerado',
  textToGenerateOrder: 'Gerar pedido',
  pdfTab: {
    maxWidth: '160px',
    maxHeight: '80px',
  },
  modalFrete: {
    copernicoStates: true,
    freeShipping: true,
  },
  whiteLabelLogo: true,
  canManageUsers: true,
  canChangeLogo: true,
  teamName: 'Time Comercial Sol Copérnico',
  formatProductName: (value: string) => {
    const formatedValue = parseFloat(value).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
    return ` - ${formatedValue}`
  },
  specifyRuleToCalculatePotency: true,
  logoHeight: '200px',
  canShowProductsLogs: true,
  domainName: '.distribuicao.solcopernico.com.br',
  newStatusOrder: false,
  canRemakeOrder: false,
  tariffRole: false, // Role for the law 14300/22
}
