import { useQuery } from '@tanstack/react-query'
import { type IEquipmentsBrandsParams } from '../types'
import { GET_EQUIPMENTS_BRANDS } from 'domains/quotation/constants/endpointKeys'
import { quotationService } from '../api'

export function useFetchEquipmentsBrands(params?: IEquipmentsBrandsParams) {
  const equipmentsBrandsQuery = useQuery({
    queryKey: [GET_EQUIPMENTS_BRANDS, params],
    queryFn: async () => await quotationService.getEquipmentsBrands(params),
  })

  return {
    ...equipmentsBrandsQuery,
  }
}
