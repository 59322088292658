import { ChevronRight } from 'lucide-react'
import { LoadingButton } from 'components/LoadingButton'
import { useChooseKitFooter } from './hooks/useChooseKitFooter'

export function ChooseKitFooter() {
  const { title, description, isLoadingKits, handleCustomizeKit } =
    useChooseKitFooter()

  return (
    <>
      <div className="info">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      <div className="actions">
        <LoadingButton isLoading={isLoadingKits} onClick={handleCustomizeKit}>
          <ChevronRight width={16} />
          Avançar
        </LoadingButton>
      </div>
    </>
  )
}
