import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid, Box, Hidden, useMediaQuery, IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import { isMobile } from 'react-device-detect'
import * as FileSaver from 'file-saver'
import {
  Card,
  Header,
  Typography,
  CoverImageEdit,
  WorkImagesEdit,
  NewContainer,
  PaperSelect,
  Loading,
} from 'components'
import { Button, Spacer, Paper, Badge, useToast } from '@77sol/core'
import {
  SendIcon,
  CheckIcon,
  ArrowChevronForwardIcon,
  SaveFavoriteBookmarkIcon,
  DownloadIcon,
  EditIcon,
  FilledSaveFavoriteBookmark,
  DeleteIcon,
  RoomServiceHelpIcon,
  BagIcon,
  CloseXIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import FinancingSimulations from 'containers/FinancingContainer/components/FinancingSimulations'
import appTheme from 'app_theme'
import API from 'api'
import { handlePotency, isEmpty } from 'utils'
import * as ProposalAction from 'store/actions'
import { setIsProPlanModalOpen as setIsProPlanModalOpenRedux } from 'store/actions'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import {
  ClienteEdit,
  ValidadeEdit,
  CronogramaEdit,
  QuoteEdit,
  ProposalStructurer,
  FullscreenProposal,
  FullscreenPdfProposal,
  SizingCard,
  ColorsEdit,
} from './components'
import { ModalSelectClient, ModalAlterarProduto } from '../StepTwo/components'
import proposalImage from './img/proposal.png'
import SizingModal from '../SizingModal'
import {
  initial,
  initialPdf,
  initialRetornoSubCards,
  initialTirVplContainers,
  initialInvesting,
  initialInvestingSections,
  initialEqupmentsSections,
  initialPaymentOptionsContainers,
  initialMoreInfosContainers,
  initialPaymentOptionsSections,
} from './components/ProposalStructurer/initialValues'
import { useStyles } from './styles'
import { ModalRemoveCustomField } from './components/ModalRemoveCustomField'
import { ModalMoreOptions } from './components/ModalMoreOptions'
import { ModalOutdatedQuotes } from './components/ModalOutdatedQuotes'
import { headerLabel, subheaderLabel } from './constants'
import { ProposalShareModal } from 'containers/Modals/ProposalShareModal'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'

function StepFour({
  proposalFromList,
  financingSimulations,
  isFinancingLoading,
  onChangeConfigEdit,
  setActiveDimStep,
  setCompleteQuoteEdit,
  proposalListHandleClose,
  setQuoteIdEditing,
  outdatedQuotesModal,
  setOutdatedQuotesModal,
  outdatedQuotesModalStep,
  setOutdatedQuotesModalStep,
  outdatedQuotes,
  quoteIdEditing,
  verifyKit,
  highlightedButton,
}) {
  const isUserProPlan = useSelector((state) => state.ReducerProfile.isProPlan)
  const { userProfile, userProfileCorporateName } = useSelector(
    (state) => state.ReducerProfile,
  )

  const [isProPlanModalOpen, setIsProPlanModalOpen] = useState(false)

  useEffect(() => {
    getFirstPageClients(true)
  }, [])

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { logEvent } = useAmplitude()
  const history = useHistory()
  const isProModalOpen = useSelector(
    (state) => state.Reducer1.isProPlanModalOpen,
  )
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)

  // Redux
  const dispatch = useDispatch()
  const isFromProposalList = !isEmpty(proposalFromList)
  const proposal = useSelector((state) => state.Reducer1)

  const classes = useStyles({ isFromProposalList })

  const [propostaStep, setPropostaStep] = useState(
    isFromProposalList ? 'configsExtras' : 'configsExtras',
  )

  const [configEditing, setConfigEditing] = useState('')
  const [quotePanelEditing, setQuotePanelEditing] = useState('')
  const [selectClientModal, setSelectClientModal] = useState(false)

  // New fields

  const [editingFieldTitle, setEditingFieldTitle] = useState('')
  const [editingFieldContent, setEditingFieldContent] = useState('')
  const [imageEditContainer, setImageEditContainer] = useState('')

  const [validade, setValidade] = useState(proposal.proposal.validity)
  const [qtdSchedule, setQtdSchedule] = useState(
    proposal?.extraConfigs?.schedules?.data.length
      ? proposal.extraConfigs.schedules.data.length
      : 1,
  )
  const [schedules, setSchedules] = useState(
    proposal?.extraConfigs?.schedules?.data.length
      ? proposal.extraConfigs.schedules.data
      : [{ title: '', weeks: '' }],
  )

  const [quoteEdit, setQuoteEdit] = useState()
  const [modalMoreOptions, setModalMoreOptions] = useState(false)

  const [clients, setClients] = useState([])
  const [loadingExtras, setLoadingExtras] = useState(false)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [logo, setLogo] = useState(proposal.proposal.logo)
  const [capa, setCapa] = useState(
    proposal?.extraConfigs?.cover_image?.data?.url
      ? proposal?.extraConfigs?.cover_image?.data?.url
      : '',
  )
  const [workImages, setWorkImages] = useState(
    proposal?.extraConfigs?.work_images?.data
      ? Array.from(proposal?.extraConfigs?.work_images?.data, (x) => x.url)
      : [],
  )

  const [sendModalStepFour, setSendModalStepFour] = useState(false)

  const [cartQuote, setCartQuote] = useState()
  const [loadingCreateQuote, setLoadingCreateQuote] = useState('')
  const [sizingModal, setSizingModal] = useState('')
  const [expandedQuotesBySizing, setExpandedQuotesBySizing] = useState(
    proposal.sizings ? [0] : [],
  )

  const [openConfigsEquipments, setOpenConfigsEquipments] = useState(false)

  const [openLoadingPdf, setOpenLoadingPdf] = useState()

  const [newFieldContent, setNewFieldContent] = useState('')
  const [newFieldTitle, setNewFieldTitle] = useState('')
  const [imageNewContainer, setImageNewContainer] = useState('')
  const [fieldType, setFieldType] = useState('text')
  const [filterClientName, setFilterClientName] = useState('')
  const [pageClients, setPageClients] = useState(1)
  const [hasMoreClients, setHasMoreClients] = useState(true)

  const [themeCustomSwitch, setThemeCustomSwitch] = useState(0)
  const [themeType, setThemeType] = useState('covers')
  const [selectedTheme, setSelectedTheme] = useState('')

  const [deletingField, setDeletingField] = useState('')

  const [loadingPdfStructurer, setLoadingPdfStructurer] = useState(false)
  const [loadingProposalStructurer, setLoadingProposalStructurer] =
    useState(false)

  const [loadingUploadImage, setLoadingUploadImage] = useState(false)
  const [editSnackbarActive, setEditSnackbarActive] = useState(true)

  const [nivelLogin, setNivelLogin] = useState(proposal?.nivelLogin)
  const [selectedOutdatedQuote, setSelectedOutdatedQuote] = useState()

  const [loadingAttProduct, setLoadingAttProduct] = useState(false)
  const [modalChangeProduct, setModalChangeProduct] = useState(false)
  const [productToChange, setProductToChange] = useState({})

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  const handleOpenSendProposalModal = () => {
    logEvent(proposalTracker.actions.openProposalSendModal, {
      origin: '/dimensionar - Proposta',
    })

    setSendModalStepFour(true)
  }

  const handleChangeProduct = async (
    newProduct,
    currentProduct,
    quote,
    qtde,
    newQtde,
  ) => {
    const responseChangeItem = {
      then: ({ data }) => {
        verifyKit(proposal.proposal.id)
        dispatch(ProposalAction.UpdateQuoteRedux(data))
        onSuccessOpenToast('Produto alterado com sucesso')
        setOutdatedQuotesModalStep(1)
        setModalChangeProduct(false)
      },
      catch: ({ response }) => {
        const { message } = response.data || {}
        onErrorOpenToast(
          [422].includes(response.status)
            ? message
            : 'Não foi possível alterar o produto',
        )
      },
    }

    if (
      newProduct.categoria == 61 ||
      newProduct.categoria == 62 ||
      newProduct.categoria == 66 ||
      newProduct.categoria == 63
    ) {
      await API.post('/proposal/quotation/item/change', {
        quotation_id: quote.quotation.id,
        new_product_id: newProduct.id,
        product_qty: qtde,
        new_product_qty: newQtde || null,
        potency: handlePotency(
          quote.quotation.added_items
            ? filterAddedItems(quote.kit, quote.quotation.added_items)
            : quote.kit,
        ),
        current_product_id: currentProduct.id,
      })
        .then(responseChangeItem.then)
        .catch(responseChangeItem.catch)
    } else {
      await API.post('proposal/quotation/item/change-direct', {
        quotation_id: quote.quotation.id,
        new_product_id: newProduct.id,
        new_product_qty: qtde,
        current_product_id: currentProduct.id,
      })
        .then(responseChangeItem.then)
        .catch(responseChangeItem.catch)
    }
  }

  const handleChangeQuantity = (quoteId, productId, qtd) => {
    setLoadingAttProduct(true)

    API.post('/proposal/quotation/item/qty', {
      quotation_id: quoteId,
      product_id: productId,
      qty_product: qtd,
    })
      .then((res) => {
        setLoadingAttProduct(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        verifyKit(proposal.proposal.id)
        setOutdatedQuotesModalStep(1)
        onSuccessOpenToast('Valor atualizado com sucesso!')
      })
      .catch(({ response }) => {
        onErrorOpenToast(
          response?.data?.message || 'Não foi possível atualizar o valor!',
        )
      })
  }

  const getNivel = () => {
    if (userLogged.privilegios_admin === 1) {
      dispatch(ProposalAction.SetIsAdmin(true))
    }
    if (userLogged.regra) {
      setNivelLogin(userLogged.regra)
      dispatch(ProposalAction.SavenivelLoginRedux(userLogged.regra))
    } else {
      setNivelLogin(2)
      dispatch(ProposalAction.SavenivelLoginRedux(2))
    }
  }

  const checkoutModal = useCheckoutModalStore((state) => state)

  const setOpenCheckoutModal = (quotationId) => {
    checkoutModal.setQuotationId(quotationId)
    checkoutModal.setIsOpen(true)
  }

  const handleUpdateCart = (id) => {
    logEvent(proposalTracker.actions.reserveEquipment)
    setCartQuote(id)
    setOpenCheckoutModal(id)
  }

  const handlePdf = (quote) => {
    setOpenLoadingPdf(true)
    API.get(`/admin/pdf-generate/quotation/${quote.quotation.id}`, {
      headers: {
        responseType: 'blob',
      },
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' })
        FileSaver.saveAs(
          file,
          `${quote.quotation.id}_Cotacao_${handlePotency(quote.kit)
            .toString()
            .replace('.', '_')}kWp_${clients
            .find((client) => client.id == proposal.proposal.customer_id)
            .label.split(' ')
            .join('_')}_${moment(new Date()).format('L')}`,
        )
        setOpenLoadingPdf(false)
      })
      .catch(() => {
        setOpenLoadingPdf(false)
      })
  }

  const handleGetExtraConfigs = (id) => {
    if (id) {
      API.get(`/proposal/extra-configs/${id}`).then((res) => {
        dispatch(ProposalAction.SaveExtraConfigsRedux(res.data))
      })
    }
  }

  useEffect(() => {
    if (isFromProposalList) {
      onChangeConfigEdit(configEditing)
    }
  }, [configEditing])

  useEffect(() => {
    localStorage.removeItem('reduxStore')
    if (isFromProposalList) {
      if (!isMobile) {
        setQuoteEdit(proposalFromList.quotes[0].quotation.id)
        setConfigEditing('cotacao')
      }
      dispatch(ProposalAction.ResetRedux())
      dispatch(ProposalAction.setIsFromProposalList(true))
      dispatch(ProposalAction.SaveSizingRedux(proposalFromList.sizings))
      dispatch(ProposalAction.SaveQuoteRedux(proposalFromList.quotes))
      dispatch(ProposalAction.SaveProposalRedux(proposalFromList.proposal))
      handleGetExtraConfigs(proposalFromList.proposal.id)
      setValidade(proposalFromList.proposal.validity)
      setQtdSchedule(proposalFromList.proposal.schedules || 1)
      setSchedules(
        proposalFromList.proposal.schedules || [{ title: '', weeks: '' }],
      )
      setQtdSchedule(
        proposalFromList.proposal.schedules
          ? proposalFromList.proposal.schedules.length
          : 1,
      )
      setLogo(proposalFromList.proposal.logo)
      setCapa(proposalFromList.proposal.cover_photo)
      setWorkImages(proposalFromList.proposal.work_images)
    }
  }, [isFromProposalList, proposalFromList])

  const handleSaveQuote = (id, favorited) => {
    API.put(`/proposal/quotation/favorite?quotation_id=${id}`, {
      quotation_id: id,
    })
      .then(() => {
        handleFinish(id)
        onSuccessOpenToast(
          `Cotação ${favorited ? 'desfavoritada' : 'favoritada'} com sucesso!`,
        )
      })
      .catch(() => {
        onErrorOpenToast(
          `Não foi possível ${
            favorited ? 'desfavoritar' : 'favoritar'
          } cotação!`,
        )
      })
  }

  const handleFinish = (id) => {
    API.post('/proposal/quotation/budget/finalized', {
      quotation_id: id,
    }).then((res) => {
      dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
    })
  }

  const handleRemoveField = (id) => {
    setLoadingExtras(true)

    API.delete(`/proposal/extra-config/${id}`)
      .then(() => {
        setLoadingExtras(false)
        setDeletingField('')
        handleGetExtraConfigs(proposal.proposal.id)
        onSuccessOpenToast('Campo removido com sucesso!')
        checkDragAndDrop('remove', id)
        checkPdfDragAndDrop('remove', id)
      })
      .catch(() => {
        setLoadingExtras(false)
        onErrorOpenToast('Não foi possível remover campo!')
      })
  }

  const handleNewContainer = () => {
    setLoadingExtras(true)

    const formData = new FormData()
    if (fieldType == 'image') {
      formData.append('content', imageNewContainer, imageNewContainer.fileName)
    } else {
      formData.append('content', newFieldContent)
    }
    if (themeCustomSwitch == 0) {
      formData.append('content_key', newFieldTitle?.split('/')[1])
    }
    formData.append('proposal_id', proposal.proposal.id)
    formData.append('title', newFieldTitle)
    formData.append('type', themeCustomSwitch == 0 ? 'theme' : fieldType)
    formData.append('status', 1)

    API.post('/proposal/extra-config/add', formData)
      .then((res) => {
        setLoadingExtras(false)
        setConfigEditing('')
        handleGetExtraConfigs(proposal.proposal.id)
        onSuccessOpenToast('Campo adicionado com sucesso!')
        checkDragAndDrop('new', res.data.data)
        checkPdfDragAndDrop('new', res.data.data)
        setFieldType('text')
        setNewFieldContent('')
        setNewFieldTitle('')
        setImageNewContainer('')
      })
      .catch(() => {
        setLoadingExtras(false)
        onErrorOpenToast('Não foi possível adicionar campo!')
      })
  }

  const handleEditContainer = () => {
    setLoadingExtras(true)

    const formData = new FormData()
    if (fieldType == 'image') {
      if (imageEditContainer.fileName) {
        formData.append(
          'content',
          imageEditContainer,
          imageEditContainer.fileName,
        )
      } else {
        formData.append('content', imageEditContainer)
      }
    } else {
      formData.append('content', editingFieldContent)
    }
    if (themeCustomSwitch == 0) {
      formData.append('content_key', editingFieldTitle?.split('/')[1])
    }
    formData.append('id', configEditing)
    formData.append('proposal_id', proposal.proposal.id)
    formData.append('title', editingFieldTitle)
    formData.append('type', themeCustomSwitch == 0 ? 'theme' : fieldType)
    formData.append('status', 1)

    API.post('/proposal/extra-config/edit', formData)
      .then(() => {
        setLoadingExtras(false)
        checkDragAndDrop(
          'update',
          configEditing,
          editingFieldTitle,
          editingFieldContent,
        )
        checkPdfDragAndDrop(
          'update',
          configEditing,
          editingFieldTitle,
          editingFieldContent,
        )
        setConfigEditing('')
        handleGetExtraConfigs(proposal.proposal.id)
        onSuccessOpenToast('Campo alterado com sucesso!')
        setFieldType('text')
        setNewFieldContent('')
        setNewFieldTitle('')
        setImageNewContainer('')
      })
      .catch(() => {
        setLoadingExtras(false)
        onErrorOpenToast('Não foi possível alterar campo!')
      })
  }

  const handleCreateQuote = (sizing, multipleQuotations = true) => {
    setLoadingCreateQuote(sizing.id)

    const payload = {
      potency: sizing.potenciaCC / 1000,
      structure: sizing.estrutura,
      dimensioning_id: sizing.id,
    }

    if (multipleQuotations) payload.quotations_quantity = 3

    API.post('/proposal/create/dimensioning/quotation', payload)
      .then((res) => {
        if (!multipleQuotations) res.data = [{ ...res.data }]
        setLoadingCreateQuote('')
        for (const quotation of res.data) {
          dispatch(ProposalAction.SaveQuoteRedux(quotation))
        }
        dispatch(ProposalAction.SaveActiveSizingRedux(sizing.id))
        setCompleteQuoteEdit(true)
        setQuoteIdEditing(res.data[0].quotation.id)

        API.get(
          `/proposal/quotation/roi/calculate/${res.data[0].quotation.id}`,
        ).then(() => {})

        API.post('/proposal/quotation/budget/finalized', {
          quotation_id: res.data[0].quotation.id,
        }).then((finalizedResponse) => {
          dispatch(
            ProposalAction.UpdateQuoteDataRedux(
              finalizedResponse.data.quotation,
            ),
          )
        })
      })
      .catch(({ response }) => {
        if (response?.data?.message) {
          onErrorOpenToast(response?.data?.message)
        }
      })
  }

  const handleNewSizing = () => {
    if (isFromProposalList) {
      const { customer_id, project_id } = proposal.proposal

      return history.push({
        pathname: '/dimensionar',
        state: {
          type: 'new_sizing',
          proposal: proposal.proposal,
          projectId: project_id,
          customerId: customer_id,
        },
      })
    }
    dispatch(ProposalAction.SaveNewSizingRedux(true))
    setActiveDimStep(1)
  }

  const structureLabel = (value) => {
    const labels = {
      'fibrocimento-madeira': 'Fibrocimento (Madeira)',
      'fibrocimento-metalico': 'Fibrocimento (Metálico)',
      ceramico: 'Cerâmico',
      metalico: 'Metalico',
      laje: 'Laje',
      solo: 'Solo',
    }

    return labels[value] || 'Sem estrutura'
  }

  function resetFieldSearch(isSearch) {
    setFilterClientName('')
    getFirstPageClients(isSearch)
  }

  async function getFirstPageClients(isClear) {
    API.get('/clientes/all', {
      params: {
        page: 1,
        paginate: 75,
        search: isClear ? '' : filterClientName,
      },
    }).then((response) => {
      const allCustomers = response?.data?.data?.map(
        ({ nome, id, ...rest }) => ({
          label: nome || '',
          value: id,
          nome,
          id,
          ...rest,
        }),
      )

      if (response?.data?.last_page === pageClients) {
        setHasMoreClients(false)
      }

      setPageClients(2)

      setClients(allCustomers)
      dispatch(ProposalAction.SaveClientsRedux(allCustomers))

      return allCustomers
    })
  }

  async function getMoreClients() {
    API.get(
      `/clientes/all?page=${pageClients}&paginate=75&search=${filterClientName}`,
    ).then((response) => {
      const allCustomers = response.data.data.map(({ nome, id, ...rest }) => ({
        label: nome || '',
        value: id,
        nome,
        id,
        ...rest,
      }))

      setClients([...clients, ...allCustomers])

      dispatch(ProposalAction.SaveClientsRedux([...clients, allCustomers]))
      if (response.data.last_page === pageClients) {
        setHasMoreClients(false)
      }
      setPageClients(pageClients + 1)
      return allCustomers
    })
  }

  const getClients = async (isClear) => {
    getFirstPageClients(isClear)
  }

  const handleRemoveImage = (url) => {
    API.post('/proposal/remove-image', {
      url,
      proposal_id: proposal.proposal.id,
    })
      .then((res) => {
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        setWorkImages(res.data.proposal.work_images)
        setCapa(res.data.proposal.cover_photo)
        setLogo(res.data.proposal.logo)
        onSuccessOpenToast('Imagem removida com sucesso')
        handleGetExtraConfigs(proposal.proposal.id)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível remover imagem')
      })
  }

  const handleSaveSchedules = (newSchedules) => {
    setLoadingExtras(true)

    API.post('/proposal/edit/schedules', {
      proposal_id: proposal.proposal.id,
      schedules: newSchedules,
      status: 1,
    })
      .then((res) => {
        onSuccessOpenToast('Cronograma alterado com sucesso!')
        handleGetExtraConfigs(proposal.proposal.id)
        setLoadingExtras(false)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o cronograma!')
        setLoadingExtras(false)
      })
  }

  const handleEditImage = () => {
    const formData = new FormData()
    formData.append('work_images', workImages, workImages.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('/proposal/update/work-images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        handleGetExtraConfigs(proposal.proposal.id)
        onSuccessOpenToast('Alterado com sucesso!')
      })
      .catch((err) => {
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar a imagem!')
        }
      })
  }

  const handleSaveWorkImages = (newWorkImages) => {
    setLoadingUploadImage(true)

    const formData = new FormData()
    formData.append('work_images', newWorkImages, newWorkImages.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('/proposal/update/work-images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        handleGetExtraConfigs(proposal.proposal.id)
        setLoadingUploadImage(false)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        setWorkImages(res.data.proposal.work_images)
        onSuccessOpenToast('Alterado com sucesso!')
      })
      .catch((err) => {
        setLoadingUploadImage(false)
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar as imagens de obras!')
        }
      })
  }

  const handleSaveCapa = (newCover) => {
    setLoadingUploadImage(true)

    const formData = new FormData()
    formData.append('proposal_cover', newCover, newCover.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('/proposal/edit/cover-photo', formData)
      .then((res) => {
        handleGetExtraConfigs(proposal.proposal.id)
        setLoadingUploadImage(false)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        setCapa(res.data.proposal.cover_photo)
        onSuccessOpenToast('Alterado com sucesso')
      })
      .catch((err) => {
        setLoadingUploadImage(false)
        setLoadingExtras(false)
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar a imagem de capa')
        }
      })
  }

  const handleSaveLogo = (newLogo) => {
    setLoadingUploadImage(true)

    const formData = new FormData()
    formData.append('proposal_logo', newLogo, newLogo.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('/proposal/edit/logo', formData)
      .then((res) => {
        setLoadingUploadImage(false)
        setLogo(res.data.proposal.logo)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        onSuccessOpenToast('Alterado com sucesso')
      })
      .catch((err) => {
        setLoadingUploadImage(false)
        setLoadingExtras(false)
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar o logo')
        }
      })
  }

  const handleSaveValidity = (validity, noMessage) => {
    setLoadingExtras(true)

    API.post('/proposal/edit/validity', {
      proposal_id: proposal.proposal.id,
      validity,
    })
      .then((res) => {
        setLoadingExtras(false)
        setConfigEditing('')
        setValidade(validity)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        if (!noMessage) {
          onSuccessOpenToast('Alterado com sucesso')
        }
      })
      .catch(() => {
        setLoadingExtras(false)
        onErrorOpenToast('Não foi possível alterar a validade')
      })
  }

  const handleUpdateColors = (
    background,
    primary,
    secondary,
    texts,
    primaryGraph,
    secondaryGraph,
    positive,
    negative,
    backgroundContent,
  ) => {
    API.patch('/proposal/color/config', {
      proposal_id: proposal.proposal.id,
      background,
      primary_color: primary,
      secondary_color: secondary,
      texts,
      primary_color_graphics: primaryGraph,
      secondary_color_graphics: secondaryGraph,
      positive_values: positive,
      negative_values: negative,
      background_content: backgroundContent,
    })
      .then(() => {
        handleGetExtraConfigs(proposal.proposal.id)
        onSuccessOpenToast('Cor alterada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar cor!')
      })
  }

  const checkPdfDragAndDrop = async (type, id, title, content) => {
    setLoadingPdfStructurer(true)
    API.get(`/proposal/pdf-structure/${proposal.proposal.id}`).then((res) => {
      if (!res.data[0]) {
        const pdfItems = []
        const quotesItems = []
        proposal.quotes.map((item, i) => {
          pdfItems.push({
            id: `QuotePdf${i}`,
            content: `item-quotePdf${i}`,
            display: `Cotação ${i + 1}`,
            visible: true,
          })
          quotesItems.push([
            {
              id: `returnQuote${i}`,
              content: `item-clientPdf${i}`,
              display: 'Retorno de investimento',
              visible: true,
            },
            {
              id: `quoteData${i}`,
              content: `item-quoteData${i}`,
              display: 'Dados do projeto',
              visible: true,
            },
            {
              id: `productionQuote${i}`,
              content: `item-productionQuote${i}`,
              display: 'Produção e consumo',
              visible: true,
            },
            {
              id: `paymentQuote${i}`,
              content: `item-paymentQuote${i}`,
              display: 'Formas de pagamento',
              visible: true,
            },
          ])
        })
        if (proposal?.extraConfigs?.extra_configs) {
          proposal.extraConfigs.extra_configs.map((item) => {
            pdfItems.push({
              id: item.id,
              content: item.content,
              display: item.title,
              visible: true,
            })
          })
        }
        pdfItems.unshift({
          id: 'ClientPdf',
          content: 'item-clientPdf',
          display: 'Introdução',
          visible: true,
        })

        const newArrPdfContainers = [...initialPdf]

        if (proposal.extraConfigs.schedules.data.length == 0) {
          const indexPdf = newArrPdfContainers.findIndex(
            (item) => item.id == 'SchedulesPdf',
          )
          newArrPdfContainers[indexPdf].visible = false
        }

        const newArray = [...pdfItems, ...newArrPdfContainers]

        const pdfProposalsContainers = [
          { containers: newArray },
          quotesItems,
          {
            id: 'signature',
            content: 'item-signature',
            display: 'Assinatura',
            visible: true,
          },
        ]
        API.post('/proposal/pdf-structure', {
          proposal_id: proposal.proposal.id,
          pdf_structure: pdfProposalsContainers,
        })
      }
      if (type == 'update') {
        const actualData = res.data
        const index = actualData[0].containers.findIndex(
          (container) => container.id == id,
        )
        actualData[0].containers[index].content = content
        actualData[0].containers[index].display = title
        API.post('/proposal/pdf-structure', {
          proposal_id: proposal.proposal.id,
          pdf_structure: actualData,
        })
      }
      if (type == 'remove') {
        const actualData = res.data
        const index = actualData[0].containers.findIndex(
          (container) => container.id == id,
        )
        actualData[0].containers.splice(index, 1)
        API.post('/proposal/pdf-structure', {
          proposal_id: proposal.proposal.id,
          pdf_structure: actualData,
        })
      }
      if (type == 'new') {
        const actualData = res.data
        actualData[0].containers.push({
          id: id.id,
          display: id.title,
          content: id.content,
          visible: true,
        })
        API.post('/proposal/pdf-structure', {
          proposal_id: proposal.proposal.id,
          pdf_structure: actualData,
        })
      }
      if (res.data[1].length != proposal.quotes.length) {
        const pdfItems = res.data[0].containers
        const quotesItemsInitial = res.data[1].length
        const quotesItems = res.data[1]
        const newQuote = proposal.quotes.filter(
          (quote, i) => i > quotesItems.length - 1,
        )
        const pdfItems2 = pdfItems.splice(
          pdfItems.findIndex(
            (item) => item.id == `QuotePdf${quotesItems.length - 1}`,
          ) + 1,
        )
        const newItems = []

        newQuote.forEach((item, i) => {
          newItems.push({
            id: `QuotePdf${quotesItemsInitial + i}`,
            content: `item-quotePdf${quotesItemsInitial + i}`,
            display: `Cotação ${quotesItemsInitial + i + 1}`,
            visible: true,
          })
          quotesItems.push([
            {
              id: `returnQuote${quotesItemsInitial + i}`,
              content: `item-clientPdf${quotesItemsInitial + i}`,
              display: 'Retorno de investimento',
              visible: true,
            },
            {
              id: `quoteData${quotesItemsInitial + i}`,
              content: `item-quoteData${quotesItemsInitial + i}`,
              display: 'Dados do projeto',
              visible: true,
            },
            {
              id: `productionQuote${quotesItemsInitial + i}`,
              content: `item-productionQuote${quotesItemsInitial + i}`,
              display: 'Produção e consumo',
              visible: true,
            },
            {
              id: `paymentQuote${quotesItemsInitial + i}`,
              content: `item-paymentQuote${quotesItemsInitial + i}`,
              display: 'Formas de pagamento',
              visible: true,
            },
          ])
        })
        const finalArray2 = [...newItems, ...pdfItems2]
        const newArray = [...pdfItems, ...finalArray2]
        const arrayRequest = [
          { containers: newArray },
          [...quotesItems],
          {
            id: 'signature',
            content: 'item-signature',
            display: 'Assinatura',
            visible: true,
          },
        ]

        API.post('/proposal/pdf-structure', {
          proposal_id: proposal.proposal.id,
          pdf_structure: arrayRequest,
        })
      }
      setLoadingPdfStructurer(false)
    })
  }

  const checkDragAndDrop = async (type, id, title, content) => {
    setLoadingProposalStructurer(true)
    API.get(`/proposal/drag-and-drop/${proposal.proposal.id}`).then((res) => {
      if (!res.data[0]) {
        const roiConfigs = proposal?.extraConfigs?.roi_configs
        const newArray = [...initial]
        const invCon = [...initialInvesting]
        const invSections = [...initialInvestingSections]
        const tirVplCont = [...initialTirVplContainers]
        const returnSubCards = [...initialRetornoSubCards]

        proposal.extraConfigs.extra_configs.forEach((item) => {
          newArray.push({
            id: item.id,
            content: item.content,
            display: item.title,
            visible: true,
          })
        })
        const indexWorkImages = newArray.findIndex(
          (container) => container.id == 'constructionImagesCard',
        )
        const indexSchedules = newArray.findIndex(
          (container) => container.id == 'scheduleCard',
        )

        if (proposal?.extraConfigs?.work_images?.data?.length == 0) {
          newArray[indexWorkImages].visible = false
        }
        if (proposal?.extraConfigs?.schedules?.data?.length == 0) {
          newArray[indexSchedules].visible = false
        }
        if (roiConfigs) {
          if (roiConfigs?.find((item) => item.type == 1)?.status == 0) {
            const indexInvest = invCon.findIndex(
              (container) => container.id == 'voceInvesteContainer',
            )
            invCon[indexInvest].visible = false
          }
          if (roiConfigs?.find((item) => item.type == 2)?.status == 0) {
            const indexReturnYears = returnSubCards.findIndex(
              (container) => container.id == 'retornoEmContainer',
            )
            const indexReturnValue = returnSubCards.findIndex(
              (container) => container.id == '30AnosContainer',
            )
            returnSubCards[indexReturnYears].visible = false
            returnSubCards[indexReturnValue].visible = false
          }
          if (roiConfigs?.find((item) => item.type == 3)?.status == 0) {
            const indexReturn = invCon.findIndex(
              (container) => container.id == 'RetornoContainer',
            )
            invCon[indexReturn].visible = false
          }
          if (roiConfigs?.find((item) => item.type == 4)?.status == 0) {
            const indexTir = tirVplCont.findIndex(
              (container) => container.id == 'TirContainer',
            )
            tirVplCont[indexTir].visible = false
          }
          if (roiConfigs?.find((item) => item.type == 5)?.status == 0) {
            const indexVpl = tirVplCont.findIndex(
              (container) => container.id == 'VplContainer',
            )
            tirVplCont[indexVpl].visible = false
          }
          if (roiConfigs?.find((item) => item.type == 6)?.status == 0) {
            const indexGraph = invSections.findIndex(
              (container) => container.id == 'retornoGrafico',
            )
            invSections[indexGraph].visible = false
          }
        }
        const ProposalsContainers = [
          { containers: newArray },
          { containers: invCon },
          { containers: invSections },
          { containers: initialEqupmentsSections },
          { containers: initialPaymentOptionsSections },
          { containers: initialPaymentOptionsContainers },
          { containers: initialMoreInfosContainers },
          { containers: tirVplCont },
          { containers: returnSubCards },
        ]

        API.post('/proposal/drag-and-drop', {
          proposal_id: proposal.proposal.id,
          data: ProposalsContainers,
        })
      }
      if (type == 'update') {
        const actualData = res.data
        const index = actualData[0].containers.findIndex(
          (container) => container.id == id,
        )
        actualData[0].containers[index].content = content
        actualData[0].containers[index].display = title
        API.post('/proposal/drag-and-drop', {
          proposal_id: proposal.proposal.id,
          data: actualData,
        })
      }
      if (type == 'remove') {
        const actualData = res.data
        const index = actualData[0].containers.findIndex(
          (container) => container.id == id,
        )
        actualData[0].containers.splice(index, 1)
        API.post('/proposal/drag-and-drop', {
          proposal_id: proposal.proposal.id,
          data: actualData,
        })
      }
      if (type == 'new') {
        const actualData = res.data
        actualData[0].containers.push({
          id: id.id,
          display: id.title,
          content: id.content,
          visible: true,
        })
        API.post('/proposal/drag-and-drop', {
          proposal_id: proposal.proposal.id,
          data: actualData,
        })
      }
      setLoadingProposalStructurer(false)
    })
  }

  const getCategories = () => {
    API.get('/loja/categorias?ordem=asc').then((res) => {
      dispatch(ProposalAction.SaveCategoriesRedux(res.data.categorias))
    })
  }

  useEffect(() => {
    if (proposal.extraConfigs.extra_configs) {
      if (!loadingProposalStructurer) {
        checkDragAndDrop()
      }
      if (!loadingPdfStructurer) {
        checkPdfDragAndDrop()
      }
    }
    if (proposal.nivelLogin) {
      setNivelLogin(proposal?.nivelLogin)
    } else {
      getNivel()
    }
    if (!isEmpty(proposal?.clients)) {
      setClients(proposal.clients)
    } else {
      getClients(true)
    }
    if (proposal.categories.length == 0) {
      getCategories()
    }

    if (proposal.quotes.length != 0) {
      if (!isMobile) {
        if (!isFromProposalList) {
          setQuoteEdit(proposal.quotes[0].quotation.id)
          setConfigEditing('cotacao')
        }
      }
    }

    if (!proposal.userName) {
      dispatch(
        ProposalAction.SaveUserNameRedux(
          userProfile.nomeFantasia
            ? userProfile.nomeFantasia
            : userProfile.razaoSocial
            ? userProfile.razaoSocial
            : userProfileCorporateName,
        ),
      )
    }
  }, [dispatch])

  useEffect(() => {
    if (!proposal.extraConfigs.extra_configs) {
      handleGetExtraConfigs(proposal.proposal.id, 'initial')
    }
  }, [proposal.proposal.id])

  useEffect(() => {
    handleGetExtraConfigs(proposal.proposal.id)
  }, [])

  useEffect(() => {
    if (proposal?.extraConfigs?.schedules?.data.length) {
      setQtdSchedule(proposal.extraConfigs.schedules.data.length)
    }
    if (proposal?.extraConfigs?.schedules?.data.length) {
      setSchedules(proposal?.extraConfigs?.schedules?.data)
    }
    if (proposal?.extraConfigs?.work_images?.data) {
      setWorkImages(
        Array.from(proposal?.extraConfigs?.work_images?.data, (x) => x.url),
      )
    }
  }, [propostaStep])

  const omnizeButtonFrame = document.getElementById('omnizeButtonFrame')
  const omnizemessageFrame = document.getElementById('omnizemessageFrame')

  useEffect(() => {
    if (isFromProposalList) {
      if (document.getElementById('omnizeButtonFrame')) {
        document.getElementById('omnizeButtonFrame').style.display = 'none'
      }
      if (document.getElementById('omnizemessageFrame')) {
        document.getElementById('omnizemessageFrame').style.display = 'none'
      }
      return () => {
        if (document.getElementById('omnizeButtonFrame')) {
          document.getElementById('omnizeButtonFrame').style.display = 'flex'
        }
        if (document.getElementById('omnizemessageFrame')) {
          document.getElementById('omnizemessageFrame').style.display = 'flex'
        }
      }
    }
  }, [omnizeButtonFrame, omnizemessageFrame, isFromProposalList])

  return (
    <div
      style={{
        height:
          propostaStep == 'structuringProposal' || propostaStep == 'fullscreen'
            ? downSm
              ? 'calc(var(--app-height) - 72px)'
              : 'calc(100% - 0px)'
            : '100%',
        overflowY:
          (propostaStep == 'structuringProposal' ||
            propostaStep == 'fullscreen') &&
          downSm
            ? 'auto'
            : propostaStep == 'structuringProposal' && 'hidden',
      }}
    >
      {propostaStep == 'configsExtras' && !isEmpty(proposal.proposal) && (
        <div
          style={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Hidden smDown>
            <div
              style={{
                width: quotePanelEditing
                  ? isFromProposalList
                    ? upXl
                      ? 'calc(100% - 901px)'
                      : 'calc(100% - 436px)'
                    : upXl
                    ? 'calc(100% - 1085px)'
                    : 'calc(100% - 789px)'
                  : configEditing
                  ? upXl
                    ? 'calc(100% - 784px)'
                    : 'calc(100% - 436px)'
                  : '100%',
              }}
            >
              <Header
                size={isFromProposalList && configEditing && 'huge'}
                onBack={isFromProposalList && proposalListHandleClose}
                noMargin
                title={
                  isFromProposalList && (
                    <Box display="flex" flexDirection="column">
                      <Typography
                        type="display_x_small"
                        color="grayscale"
                        colorWeight="700"
                      >
                        Edição de proposta
                      </Typography>
                      {!configEditing && (
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          Alteração de dados podem impactar outros pontos da
                          proposta
                        </Typography>
                      )}
                    </Box>
                  )
                }
              >
                <Box className={classes.topTitle}>
                  {!isFromProposalList && (
                    <Box display="flex">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          type="display_x_small"
                          color="grayscale"
                          colorWeight="700"
                        >
                          Estruture sua proposta
                        </Typography>
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {!configEditing
                            ? 'Organize as informações que vão ser enviadas na proposta para o cliente'
                            : 'Organize as informações que vão ser enviadas'}
                        </Typography>
                      </div>
                    </Box>
                  )}
                  {!configEditing ? (
                    <div style={{ display: 'flex' }}>
                      <Hidden smDown>
                        <Button
                          id="stepFour_button_editProposal"
                          onClick={() => setPropostaStep('structuringProposal')}
                          size="small"
                          variant="outlined"
                        >
                          Editar proposta
                        </Button>
                        <Spacer size="8" direction="both" />
                        <Button
                          id="stepFour_button_sendProposal"
                          onClick={() => handleOpenSendProposalModal()}
                          size="small"
                          startIcon={<SendIcon />}
                        >
                          Enviar
                        </Button>
                      </Hidden>
                      <Hidden mdUp>
                        <Tooltip title="Editar proposta">
                          <IconButton
                            id="stepFour_button_editProposal"
                            aria-label="Editar proposta"
                            onClick={() => {
                              setPropostaStep('structuringProposal')
                            }}
                          >
                            <EditIcon
                              width="24px"
                              color={palette.grayscale[700]}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Enviar">
                          <IconButton
                            id="stepFour_button_sendProposal"
                            aria-label="Enviar proposta"
                            onClick={() => handleOpenSendProposalModal()}
                          >
                            <SendIcon
                              width="24px"
                              color={palette.grayscale[800]}
                            />
                          </IconButton>
                        </Tooltip>
                      </Hidden>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Editar proposta">
                        <IconButton
                          aria-label="Editar proposta"
                          onClick={() => {
                            logEvent(proposalTracker.actions.editProposal)
                            setPropostaStep('structuringProposal')
                          }}
                        >
                          <EditIcon
                            width="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Enviar">
                        <IconButton
                          aria-label="Enviar proposta"
                          onClick={() => handleOpenSendProposalModal()}
                        >
                          <SendIcon
                            width="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </Box>
              </Header>
            </div>
          </Hidden>
          <Hidden mdUp>
            {isFromProposalList && (
              <Header
                onBack={proposalListHandleClose}
                title="Edição"
                noMargin
                size="small"
              >
                <Box>
                  <IconButton
                    onClick={() => {
                      logEvent(proposalTracker.actions.editProposal)
                      if (downSm) {
                        setPropostaStep('fullscreen')
                      } else {
                        setPropostaStep('structuringProposal')
                      }
                    }}
                  >
                    <EditIcon width="24px" color={palette.white} />
                  </IconButton>
                  <IconButton onClick={() => handleOpenSendProposalModal()}>
                    <SendIcon width="24px" color={palette.white} />
                  </IconButton>
                </Box>
              </Header>
            )}
          </Hidden>
          <div
            style={{
              display: downSm && configEditing ? 'none' : 'flex',
              width: quotePanelEditing
                ? isFromProposalList
                  ? upXl
                    ? 'calc(100% - 901px)'
                    : 'calc(100% - 436px)'
                  : upXl
                  ? 'calc(100% - 1085px)'
                  : 'calc(100% - 789px)'
                : configEditing
                ? upXl
                  ? 'calc(100% - 784px)'
                  : 'calc(100% - 436px)'
                : '100%',
              transition: 'width 0.3s ease-in-out 0s',
              padding: isFromProposalList ? '0px' : '24px 16px',
              overflowY: 'auto',
              position: 'relative',
            }}
          >
            <Grid
              direction="column"
              container
              spacing={isFromProposalList ? 0 : 2}
            >
              <Grid item xs={12} md={12} lg={configEditing ? 12 : 12}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    maxHeight: isFromProposalList
                      ? 'calc(var(--app-height) - 156px)'
                      : 'inherit',
                    overflowY: isFromProposalList ? 'auto' : 'inherit',
                    padding: isFromProposalList ? '24px 16px' : '0px',
                    margin: '0px',
                    width: '100%',
                  }}
                >
                  <Grid item xs={12} md={12} xl={12}>
                    <Grid
                      container
                      spacing={2}
                      style={{ margin: '0px', width: '100%' }}
                    >
                      {!isFromProposalList && downSm && (
                        <Grid item xs={12} md={12}>
                          <div>
                            <Typography
                              type="link_small"
                              color="grayscale"
                              colorWeight="800"
                            >
                              Estruture sua proposta
                            </Typography>
                            <Spacer size="4" direction="horizontal" />
                            <Typography
                              type="text_x_small"
                              color="grayscale"
                              colorWeight="600"
                            >
                              Organize as informações que vão ser enviadas na
                              proposta para o cliente
                            </Typography>
                          </div>
                          <Spacer size="16" direction="horizontal" />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              onClick={() => {
                                if (downSm) {
                                  setPropostaStep('fullscreen')
                                } else {
                                  setPropostaStep('structuringProposal')
                                }
                              }}
                              size="small"
                              variant="outlined"
                              fullWidth
                            >
                              Editar
                            </Button>
                            <Button
                              style={{ marginLeft: '16px', height: '48px' }}
                              onClick={() => handleOpenSendProposalModal()}
                              size="small"
                              variant="outlined"
                              startIcon={<SendIcon width="16px" />}
                              fullWidth
                            >
                              Enviar
                            </Button>
                          </div>
                          <Spacer size="16" direction="horizontal" />
                        </Grid>
                      )}
                      {proposal &&
                        proposal.sizings.map((sizing, index) => (
                          <Grid key={index} item xs={12} md={12} lg={12}>
                            <SizingCard
                              index={index}
                              proposal={proposal}
                              quoteEdit={quoteEdit}
                              setQuoteEdit={setQuoteEdit}
                              setConfigEditing={setConfigEditing}
                              handleNewSizing={handleNewSizing}
                              handleCreateQuote={handleCreateQuote}
                              setSizingModal={setSizingModal}
                              sizing={sizing}
                              setQuotePanelEditing={setQuotePanelEditing}
                              configEditing={configEditing}
                              structureLabel={structureLabel}
                              expandedQuotesBySizing={expandedQuotesBySizing}
                              setExpandedQuotesBySizing={
                                setExpandedQuotesBySizing
                              }
                              highlightedButton={highlightedButton}
                              loadingCreateQuote={loadingCreateQuote}
                              quotePanelEditing={quotePanelEditing}
                              isFromProposalList={isFromProposalList}
                              setEditSnackbarActive={setEditSnackbarActive}
                            />
                          </Grid>
                        ))}
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'logo'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'logo' ? 'white' : 'primary'
                              }
                              colorWeight="300"
                            >
                              {proposal.proposal.logo && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Logo
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('logo')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'logo' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('logo')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setConfigEditing('logo')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                        setEditSnackbarActive(true)
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'logo' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            Adicione o logo de sua empresa
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'capa'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'capa' ? 'white' : 'primary'
                              }
                              colorWeight="300"
                            >
                              {capa && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Foto de capa
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('capa')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'capa' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('capa')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('capa')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'capa' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            Adicione a imagem de capa da proposta
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'cliente'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'cliente' ? 'white' : 'primary'
                              }
                              colorWeight="300"
                            >
                              <CheckIcon
                                width="24px"
                                style={{ marginRight: '8px' }}
                              />
                              Cliente
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    if (!isUserProPlan) {
                                      return setIsProPlanModalOpen(true)
                                    }
                                    setEditSnackbarActive(true)
                                    setConfigEditing('cliente')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'cliente' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('cliente')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('cliente')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'cliente' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            {
                              clients.find(
                                (client) =>
                                  client.id == proposal?.proposal.customer_id,
                              )?.label
                            }
                          </Typography>
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'cliente' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            {
                              clients.find(
                                (client) =>
                                  client.id == proposal?.proposal.customer_id,
                              )?.email
                            }
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'cronograma'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'cronograma'
                                  ? 'white'
                                  : 'primary'
                              }
                              colorWeight="300"
                            >
                              {schedules[0]?.title != '' && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Cronograma
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('cronograma')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'cronograma' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('cronograma')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('cronograma')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'cronograma'
                                ? 'white'
                                : 'primary'
                            }
                            colorWeight="700"
                          >
                            Defina o cronograma do projeto
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'validade'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'validade'
                                  ? 'white'
                                  : 'primary'
                              }
                              colorWeight="300"
                            >
                              {validade && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Validade
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('validade')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'validade' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('validade')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('validade')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'validade' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            Defina a validade dessa proposta
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'imagensObras'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'imagensObras'
                                  ? 'white'
                                  : 'primary'
                              }
                              colorWeight="300"
                            >
                              {workImages.length != 0 && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Imagens de obras
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('imagensObras')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'imagensObras' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('imagensObras')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('imagensObras')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'imagensObras'
                                ? 'white'
                                : 'primary'
                            }
                            colorWeight="700"
                          >
                            Insira as imagens de obras passadas
                          </Typography>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={configEditing ? 12 : 6}
                        xl={configEditing ? 12 : 4}
                      >
                        <Card
                          style={{
                            backgroundColor:
                              configEditing != 'cores'
                                ? palette.white
                                : palette.primary[300],
                            height: '100%',
                          }}
                          title={
                            <Typography
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              type={
                                downSm || !upXl ? 'link_medium' : 'text_large'
                              }
                              color={
                                configEditing == 'cores' ? 'white' : 'primary'
                              }
                              colorWeight="300"
                            >
                              {proposal?.extraConfigs?.color_configs && (
                                <CheckIcon
                                  width="24px"
                                  style={{ marginRight: '8px' }}
                                />
                              )}
                              Cores
                            </Typography>
                          }
                          bold
                          headerChildren={
                            downSm ? (
                              <Tooltip title="Editar">
                                <IconButton
                                  aria-label="Editar"
                                  onClick={() => {
                                    setEditSnackbarActive(true)
                                    setConfigEditing('cores')
                                    setQuoteEdit('')
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  <EditIcon
                                    width="24px"
                                    color={palette.grayscale[700]}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : configEditing != 'cores' ? (
                              <div>
                                <Hidden lgDown>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                      setEditSnackbarActive(true)
                                      setConfigEditing('cores')
                                      setQuoteEdit('')
                                      setQuotePanelEditing('')
                                    }}
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                                <Hidden xlUp>
                                  <Tooltip title="Editar">
                                    <IconButton
                                      aria-label="Editar"
                                      onClick={() => {
                                        setEditSnackbarActive(true)
                                        setConfigEditing('cores')
                                        setQuoteEdit('')
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      <EditIcon
                                        width="24px"
                                        color={palette.grayscale[700]}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Hidden>
                              </div>
                            ) : (
                              <ArrowChevronForwardIcon
                                width="24px"
                                color={palette.white}
                              />
                            )
                          }
                        >
                          <Typography
                            type="text_small"
                            color={
                              configEditing == 'cores' ? 'white' : 'primary'
                            }
                            colorWeight="700"
                          >
                            Defina as cores de sua proposta
                          </Typography>
                        </Card>
                      </Grid>
                      <Hidden smDown>
                        {proposal?.extraConfigs?.extra_configs?.length == 0 &&
                          configEditing != 'newField' && (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={configEditing ? 12 : 6}
                              xl={configEditing ? 12 : 4}
                            >
                              <PaperSelect
                                onClick={() => {
                                  setEditSnackbarActive(true)
                                  setConfigEditing('newField')
                                  setQuotePanelEditing('')
                                  setThemeType('covers')
                                  setThemeCustomSwitch(0)
                                }}
                                type="new"
                                title="Novo campo personalizado"
                              />
                            </Grid>
                          )}
                      </Hidden>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12}>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        margin: '0px',
                        width: '100%',
                        marginBottom: '24px',
                      }}
                    >
                      {proposal?.extraConfigs?.extra_configs?.length != 0 && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              marginTop: !downSm && '20px',
                              marginBottom: !downSm && '16px',
                            }}
                            type={
                              downSm ? 'link_medium' : 'display_x_small_bold'
                            }
                            color="grayscale"
                            colorWeight="700"
                          >
                            Campos pré-configurados
                          </Typography>
                          <Hidden smDown>
                            <Button
                              id="stepFour_button_newCustomField"
                              variant="outlined"
                              size="small"
                              noMargin
                              onClick={() => {
                                setEditSnackbarActive(true)
                                setConfigEditing('newField')
                                setQuotePanelEditing('')
                                setThemeType('covers')
                                setThemeCustomSwitch(0)
                              }}
                            >
                              Novo campo personalizado
                            </Button>
                          </Hidden>
                        </Grid>
                      )}
                      {proposal?.extraConfigs?.extra_configs?.map(
                        (extraField, i) => (
                          <Grid
                            key={i}
                            item
                            xs={12}
                            md={12}
                            lg={configEditing ? 12 : 6}
                            xl={configEditing ? 12 : 6}
                          >
                            <Card
                              style={{
                                backgroundColor:
                                  configEditing != extraField.id
                                    ? palette.white
                                    : palette.primary[300],
                                height: '100%',
                              }}
                              title={
                                <Typography
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  type={
                                    downSm || !upXl
                                      ? 'link_medium'
                                      : 'text_large'
                                  }
                                  color={
                                    configEditing == extraField.id
                                      ? 'white'
                                      : 'primary'
                                  }
                                  colorWeight="300"
                                >
                                  {extraField.content && (
                                    <CheckIcon
                                      width="24px"
                                      style={{ marginRight: '8px' }}
                                    />
                                  )}
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: downSm ? 'column' : 'row',
                                      alignItems: !downSm && 'center',
                                    }}
                                  >
                                    {extraField.title.split('/')[1] ? (
                                      <>
                                        {(extraField.title.split('/')[0] ==
                                          'covers' &&
                                          `${`${'Capa ('}${
                                            proposal?.extraConfigs?.extra_configs
                                              ?.filter(
                                                (item) =>
                                                  item.title.split('/')[0] ==
                                                  'covers',
                                              )
                                              .findIndex(
                                                (item) =>
                                                  item.id == extraField.id,
                                              ) + 1
                                          }`})`) ||
                                          (extraField.title.split('/')[0] ==
                                            'explanations' &&
                                            `${`${'Explicações ('}${
                                              proposal?.extraConfigs?.extra_configs
                                                ?.filter(
                                                  (item) =>
                                                    item.title.split('/')[0] ==
                                                    'explanations',
                                                )
                                                .findIndex(
                                                  (item) =>
                                                    item.id == extraField.id,
                                                ) + 1
                                            }`})`) ||
                                          (extraField.title.split('/')[0] ==
                                            'benefits' &&
                                            `${`${'Vantagens ('}${
                                              proposal?.extraConfigs?.extra_configs
                                                ?.filter(
                                                  (item) =>
                                                    item.title.split('/')[0] ==
                                                    'benefits',
                                                )
                                                .findIndex(
                                                  (item) =>
                                                    item.id == extraField.id,
                                                ) + 1
                                            }`})`) ||
                                          (extraField.title.split('/')[0] ==
                                            'partners' &&
                                            `${`${'Parceiros ('}${
                                              proposal?.extraConfigs?.extra_configs
                                                ?.filter(
                                                  (item) =>
                                                    item.title.split('/')[0] ==
                                                    'partners',
                                                )
                                                .findIndex(
                                                  (item) =>
                                                    item.id == extraField.id,
                                                ) + 1
                                            }`})`) ||
                                          extraField.title.split('/')[0]}
                                      </>
                                    ) : (
                                      <>{extraField.title}</>
                                    )}
                                    <Spacer size="16" direction="vertical" />
                                    {extraField.type == 'image' && (
                                      <Badge
                                        size="medium"
                                        variant="contained"
                                        color="green"
                                        label="Imagem"
                                      />
                                    )}
                                    {extraField.type == 'text' && (
                                      <Badge
                                        size="medium"
                                        variant="contained"
                                        color="orange"
                                        label="Texto"
                                      />
                                    )}
                                    {extraField.type == 'theme' && (
                                      <Badge
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        label="Tema"
                                      />
                                    )}
                                  </div>
                                </Typography>
                              }
                              bold
                              headerChildren={
                                downSm ? (
                                  <div style={{ display: 'flex' }}>
                                    <Tooltip title="Editar">
                                      <IconButton
                                        aria-label="Editar"
                                        onClick={() => {
                                          setEditSnackbarActive(true)
                                          setConfigEditing(extraField.id)
                                          setEditingFieldTitle(extraField.title)
                                          if (extraField.type == 'theme') {
                                            setThemeCustomSwitch(0)
                                          } else {
                                            setThemeCustomSwitch(1)
                                          }
                                          if (extraField.type == 'image') {
                                            setImageEditContainer(
                                              extraField.content,
                                            )
                                          } else {
                                            setEditingFieldContent(
                                              extraField.content,
                                            )
                                          }
                                          setFieldType(
                                            extraField.type
                                              ? extraField.type
                                              : 'text',
                                          )
                                          setQuoteEdit('')
                                          setQuotePanelEditing('')
                                        }}
                                      >
                                        <EditIcon
                                          width="24px"
                                          color={palette.grayscale[700]}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {proposal.nivelLogin <= 2 && (
                                      <Tooltip title="Excluir">
                                        <IconButton
                                          aria-label="Excluir"
                                          onClick={() =>
                                            setDeletingField(extraField.id)
                                          }
                                        >
                                          <DeleteIcon
                                            width="24px"
                                            height="24px"
                                            color={palette.red[300]}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </div>
                                ) : configEditing != extraField.id ? (
                                  <div
                                    style={{
                                      width: 'max-content',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Hidden lgDown>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                          setEditSnackbarActive(true)
                                          setConfigEditing(extraField.id)
                                          setEditingFieldTitle(extraField.title)
                                          if (extraField.type == 'theme') {
                                            setThemeCustomSwitch(0)
                                          } else {
                                            setThemeCustomSwitch(1)
                                          }
                                          if (extraField.type == 'image') {
                                            setImageEditContainer(
                                              extraField.content,
                                            )
                                          } else {
                                            setEditingFieldContent(
                                              extraField.content,
                                            )
                                          }
                                          setFieldType(
                                            extraField.type
                                              ? extraField.type
                                              : 'text',
                                          )
                                          setQuoteEdit('')
                                          setQuotePanelEditing('')
                                        }}
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        color="red"
                                        style={{ marginLeft: '16px' }}
                                        onClick={() =>
                                          setDeletingField(extraField.id)
                                        }
                                      >
                                        Remover
                                      </Button>
                                    </Hidden>
                                    <Hidden xlUp>
                                      <Tooltip title="Editar">
                                        <IconButton
                                          aria-label="Editar"
                                          onClick={() => {
                                            setEditSnackbarActive(true)
                                            setConfigEditing(extraField.id)
                                            setEditingFieldTitle(
                                              extraField.title,
                                            )
                                            if (extraField.type == 'theme') {
                                              setThemeCustomSwitch(0)
                                            } else {
                                              setThemeCustomSwitch(1)
                                            }
                                            if (extraField.type == 'image') {
                                              setImageEditContainer(
                                                extraField.content,
                                              )
                                            } else {
                                              setEditingFieldContent(
                                                extraField.content,
                                              )
                                            }
                                            setFieldType(
                                              extraField.type
                                                ? extraField.type
                                                : 'text',
                                            )
                                            setQuoteEdit('')
                                            setQuotePanelEditing('')
                                          }}
                                        >
                                          <EditIcon
                                            width="24px"
                                            color={palette.grayscale[700]}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      {proposal.nivelLogin <= 2 && (
                                        <Tooltip title="Excluir">
                                          <IconButton
                                            aria-label="Excluir"
                                            onClick={() =>
                                              setDeletingField(extraField.id)
                                            }
                                          >
                                            <DeleteIcon
                                              width="24px"
                                              height="24px"
                                              color={palette.red[300]}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Hidden>
                                  </div>
                                ) : (
                                  <ArrowChevronForwardIcon
                                    width="24px"
                                    color={palette.white}
                                  />
                                )
                              }
                            />
                          </Grid>
                        ),
                      )}
                      <Hidden mdUp>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={configEditing ? 12 : 6}
                          xl={configEditing ? 12 : 4}
                        >
                          <Button
                            id="stepFour_button_newCustomField"
                            variant="outlined"
                            size="small"
                            noMargin
                            fullWidth
                            onClick={() => {
                              setThemeType('covers')
                              setConfigEditing('newField')
                              setEditSnackbarActive(true)
                              setQuotePanelEditing('')
                              setThemeCustomSwitch(0)
                            }}
                          >
                            Novo campo personalizado
                          </Button>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                  {!downSm &&
                    editSnackbarActive &&
                    configEditing &&
                    propostaStep != 'fullscreen' &&
                    propostaStep != 'fullscreenPdf' && (
                      <>
                        {(downLg && isFromProposalList) ||
                        (downLg && quotePanelEditing) ? (
                          <Paper
                            style={{
                              padding: '16px 24px',
                              backgroundColor: palette.primary[400],
                              width: '100%',
                              display: 'flex',
                              alignItems: 'flex-start',
                              position: 'sticky',
                              bottom: isFromProposalList ? '24px' : '0px',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: '12px',
                              }}
                            >
                              <Typography type="link_small" color="white">
                                Veja a sua proposta e edite por lá mesmo
                              </Typography>
                              <CloseXIcon
                                onClick={() => {
                                  setEditSnackbarActive(false)
                                }}
                                width="24"
                                color={palette.white}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                            <Button
                              onClick={() =>
                                setPropostaStep('structuringProposal')
                              }
                              size="small"
                              style={{
                                color: 'white',
                                border: '2px solid white',
                                backgroundColor: palette.primary[400],
                              }}
                            >
                              Editar proposta
                            </Button>
                          </Paper>
                        ) : (
                          <Paper
                            style={{
                              padding: '16px 24px',
                              backgroundColor: palette.primary[400],
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              position: 'sticky',
                              bottom: isFromProposalList ? '24px' : '0px',
                            }}
                          >
                            <Typography type="link_small" color="white">
                              Veja a sua proposta e edite por lá mesmo
                            </Typography>
                            <div style={{ display: 'flex' }}>
                              <Button
                                onClick={() =>
                                  setPropostaStep('structuringProposal')
                                }
                                size="small"
                                style={{
                                  color: 'white',
                                  border: '2px solid white',
                                  backgroundColor: palette.primary[400],
                                }}
                              >
                                Editar proposta
                              </Button>
                              <Spacer size="24" direction="vertical" />
                              <CloseXIcon
                                onClick={() => {
                                  setEditSnackbarActive(false)
                                }}
                                width="24"
                                color={palette.white}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </Paper>
                        )}
                      </>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Hidden lgDown>
              {!isFromProposalList && !configEditing && (
                <Paper
                  style={{
                    backgroundColor: palette.primary[300],
                    width: '328px',
                    height: 'fit-content',
                    marginLeft: '24px',
                  }}
                >
                  <img
                    src={proposalImage}
                    style={{ width: '280px', height: '240px' }}
                    alt="proposta"
                  />
                  <Spacer size="24" direction="horizontal" />
                  <Typography type="display_x_small_bold" color="white">
                    Visualize sua proposta e edite em tempo real
                  </Typography>
                  <Spacer size="24" direction="horizontal" />
                  <Button
                    onClick={() => setPropostaStep('structuringProposal')}
                    style={{ display: 'flex', justifyContent: 'center' }}
                    fullWidth
                    size="small"
                    color="white"
                  >
                    Editar proposta
                  </Button>
                </Paper>
              )}
            </Hidden>
          </div>
          <div
            style={{
              width:
                downSm && (quotePanelEditing || configEditing)
                  ? '100%'
                  : quotePanelEditing
                  ? isFromProposalList
                    ? upXl
                      ? '901px'
                      : '436px'
                    : 'auto'
                  : configEditing
                  ? upXl
                    ? '784px'
                    : '436px'
                  : '0%',
              display: configEditing ? 'block' : 'none',
              transition: 'width 0.3s ease-in-out 0s',
              borderLeft: '1px solid #e2e6e7',
              position: 'absolute',
              right: '0px',
              top: '0px',
              backgroundColor: '#F7F7FC',
              height:
                isFromProposalList && downSm
                  ? 'calc(var(--app-height) - 48px)'
                  : '100%',
              overflowY:
                configEditing == 'dimensionamento' ? 'inherit' : 'none',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display:
                    quotePanelEditing &&
                    (downSm || (isFromProposalList && !upXl)) &&
                    'none',
                  width: quotePanelEditing
                    ? isFromProposalList
                      ? upXl && '436px'
                      : upXl
                      ? '436px'
                      : '401px'
                    : '100%',
                  transition: 'width 0.3s ease-in-out 0s',
                }}
              >
                <Header
                  noMargin
                  sticky={configEditing !== 'financing'}
                  title={
                    <Typography
                      style={{
                        padding: isFromProposalList ? '4.8px 0px' : '7.8px 0px',
                      }}
                      type={downSm ? 'text_medium' : 'text_large'}
                      color={downSm ? 'white' : 'grayscale'}
                      colorWeight="700"
                    >
                      {headerLabel[configEditing]}
                      {configEditing === 'cotacao' && ` - #${quoteEdit}`}
                      {Number.isInteger(configEditing) && (
                        <>
                          {proposal.extraConfigs.extra_configs
                            .find((item) => item.id == configEditing)
                            .title.split('/')[1] ? (
                            <>
                              {(proposal.extraConfigs.extra_configs
                                .find((item) => item.id == configEditing)
                                .title.split('/')[0] == 'covers' &&
                                `${`${'Capa ('}${
                                  proposal?.extraConfigs?.extra_configs
                                    ?.filter(
                                      (item) =>
                                        item.title.split('/')[0] == 'covers',
                                    )
                                    .findIndex(
                                      (item) =>
                                        item.id ==
                                        proposal.extraConfigs.extra_configs.find(
                                          (item) => item.id == configEditing,
                                        ).id,
                                    ) + 1
                                }`})`) ||
                                (proposal.extraConfigs.extra_configs
                                  .find((item) => item.id == configEditing)
                                  .title.split('/')[0] == 'explanations' &&
                                  `${`${'Explicações ('}${
                                    proposal?.extraConfigs?.extra_configs
                                      ?.filter(
                                        (item) =>
                                          item.title.split('/')[0] ==
                                          'explanations',
                                      )
                                      .findIndex(
                                        (item) =>
                                          item.id ==
                                          proposal.extraConfigs.extra_configs.find(
                                            (item) => item.id == configEditing,
                                          ).id,
                                      ) + 1
                                  }`})`) ||
                                (proposal.extraConfigs.extra_configs
                                  .find((item) => item.id == configEditing)
                                  .title.split('/')[0] == 'benefits' &&
                                  `${`${'Vantagens ('}${
                                    proposal?.extraConfigs?.extra_configs
                                      ?.filter(
                                        (item) =>
                                          item.title.split('/')[0] ==
                                          'benefits',
                                      )
                                      .findIndex(
                                        (item) =>
                                          item.id ==
                                          proposal.extraConfigs.extra_configs.find(
                                            (item) => item.id == configEditing,
                                          ).id,
                                      ) + 1
                                  }`})`) ||
                                (proposal.extraConfigs.extra_configs
                                  .find((item) => item.id == configEditing)
                                  .title.split('/')[0] == 'partners' &&
                                  `${`${'Parceiros ('}${
                                    proposal?.extraConfigs?.extra_configs
                                      ?.filter(
                                        (item) =>
                                          item.title.split('/')[0] ==
                                          'partners',
                                      )
                                      .findIndex(
                                        (item) =>
                                          item.id ==
                                          proposal.extraConfigs.extra_configs.find(
                                            (item) => item.id == configEditing,
                                          ).id,
                                      ) + 1
                                  }`})`) ||
                                proposal.extraConfigs.extra_configs
                                  .find((item) => item.id == configEditing)
                                  .title.split('/')[0]}
                            </>
                          ) : (
                            <>
                              {
                                proposal.extraConfigs.extra_configs.find(
                                  (item) => item.id == configEditing,
                                ).title
                              }
                            </>
                          )}
                        </>
                      )}
                    </Typography>
                  }
                  size={isFromProposalList && 'huge'}
                  onBack={() => {
                    setConfigEditing('')
                    setQuotePanelEditing('')
                    setQuoteEdit('')
                  }}
                >
                  {configEditing === 'cotacao' && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title={
                          proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.is_favorite
                            ? 'Desfavoritar cotação'
                            : 'Favoritar cotação'
                        }
                      >
                        <div style={{ display: 'flex' }}>
                          {proposal.quotes.find(
                            (quote) => quote.quotation.id == quoteEdit,
                          )?.quotation?.is_favorite ? (
                            <FilledSaveFavoriteBookmark
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleSaveQuote(
                                  quoteEdit,
                                  proposal.quotes.find(
                                    (quote) => quote.quotation.id == quoteEdit,
                                  ).quotation.is_favorite,
                                )
                              }
                              width="24px"
                              color={palette.yellow[400]}
                            />
                          ) : (
                            <SaveFavoriteBookmarkIcon
                              onClick={() => handleSaveQuote(quoteEdit)}
                              width="24px"
                              color={downSm ? palette.white : palette.black}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </Header>
              </div>
              {quotePanelEditing && (
                <div
                  style={{
                    borderLeft: '1px solid rgb(226, 230, 231)',
                    width:
                      downSm || (isFromProposalList && !upXl)
                        ? '100%'
                        : isFromProposalList
                        ? upXl && '468px'
                        : upXl
                        ? '652px'
                        : '388px',
                    transition: 'width 0.3s ease-in-out 0s',
                  }}
                >
                  <Header
                    title={
                      <Typography
                        style={{
                          padding: isFromProposalList
                            ? '4.8px 0px'
                            : '7.8px 0px',
                        }}
                        type={downSm ? 'text_medium' : 'text_large'}
                        color={downSm ? 'white' : 'grayscale'}
                        colorWeight="700"
                      >
                        {openConfigsEquipments
                          ? 'Configurar produtos'
                          : subheaderLabel[quotePanelEditing]}
                      </Typography>
                    }
                    noMargin
                    size={isFromProposalList && configEditing && 'huge'}
                    sticky
                    onBack={
                      openConfigsEquipments
                        ? () => setOpenConfigsEquipments(false)
                        : () => setQuotePanelEditing('')
                    }
                  />
                </div>
              )}
            </div>
            {isFromProposalList && configEditing === 'financing' && (
              <Box>
                <Loading minHeight="250px" isLoading={isFinancingLoading}>
                  <FinancingSimulations
                    financingSimulations={financingSimulations}
                    handleRefreshFinancingList={() =>
                      onChangeConfigEdit('financing')
                    }
                    onClose={() => setConfigEditing('')}
                    isFromProposalList
                  />
                </Loading>
              </Box>
            )}
            {configEditing == 'cotacao' && (
              <QuoteEdit
                proposal={proposal}
                quoteEdit={quoteEdit}
                setQuoteEdit={setQuoteEdit}
                quotePanelEditing={quotePanelEditing}
                setQuotePanelEditing={setQuotePanelEditing}
                openConfigsEquipments={openConfigsEquipments}
                isFromProposalList={isFromProposalList}
                setCartQuote={setCartQuote}
                setOpenCheckoutModal={setOpenCheckoutModal}
                handlePdf={handlePdf}
                setCompleteQuoteEdit={setCompleteQuoteEdit}
                setQuoteIdEditing={setQuoteIdEditing}
                setOpenConfigsEquipments={setOpenConfigsEquipments}
                nivelLogin={nivelLogin}
                highlightedButton={highlightedButton}
              />
            )}
            {configEditing == 'cliente' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 128px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                {/* editar aquii */}
                <ClienteEdit
                  proposal={proposal}
                  getClientsFromStepFour={getClients}
                  getMoreClients={() => getMoreClients}
                  hasMoreClients={hasMoreClients}
                  setFilterClientName={setFilterClientName}
                  filterClientName={filterClientName}
                  resetFieldSearch={() => resetFieldSearch}
                  clients={clients}
                />
              </div>
            )}
            {Number.isInteger(configEditing) && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <Spacer size="34" direction="horizontal" />
                <NewContainer
                  title={editingFieldTitle}
                  setTitle={setEditingFieldTitle}
                  content={editingFieldContent}
                  setContent={setEditingFieldContent}
                  handleSave={handleEditContainer}
                  loading={loadingExtras}
                  image={imageEditContainer}
                  setImage={setImageEditContainer}
                  fieldType={fieldType}
                  setFieldType={setFieldType}
                  themeCustomSwitch={themeCustomSwitch}
                  setThemeCustomSwitch={setThemeCustomSwitch}
                  themeType={themeType}
                  setThemeType={setThemeType}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                />
              </div>
            )}
            {configEditing == 'cronograma' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 98px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <CronogramaEdit
                  qtdSchedule={qtdSchedule}
                  setQtdSchedule={setQtdSchedule}
                  schedules={schedules}
                  setSchedules={setSchedules}
                  handleFinish={handleSaveSchedules}
                  loadingExtras={loadingExtras}
                />
              </div>
            )}
            {configEditing == 'logo' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <Spacer size="34" direction="horizontal" />
                <CoverImageEdit
                  image={logo}
                  handleFinish={handleSaveLogo}
                  handleRemoveImage={handleRemoveImage}
                  square
                  loading={loadingUploadImage}
                />
              </div>
            )}
            {configEditing == 'capa' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <Spacer size="34" direction="horizontal" />
                <CoverImageEdit
                  image={capa}
                  handleFinish={handleSaveCapa}
                  handleRemoveImage={handleRemoveImage}
                  loading={loadingUploadImage}
                />
              </div>
            )}
            {configEditing == 'imagensObras' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 128px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <Spacer size="34" direction="horizontal" />
                <WorkImagesEdit
                  imagens={workImages}
                  handleSaveWorkImages={handleSaveWorkImages}
                  handleEditImage={handleEditImage}
                  handleRemoveImage={handleRemoveImage}
                  loading={loadingUploadImage}
                />
              </div>
            )}
            {configEditing == 'validade' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <ValidadeEdit
                  handleFinish={handleSaveValidity}
                  loadingExtras={loadingExtras}
                  value={validade}
                  setValue={setValidade}
                />
              </div>
            )}
            {configEditing == 'cores' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <ColorsEdit
                  proposal={proposal}
                  handleUpdateColors={handleUpdateColors}
                  isFromProposalList={isFromProposalList}
                />
              </div>
            )}
            {configEditing == 'newField' && (
              <div
                style={{
                  height: downSm ? 'calc(100% - 67px)' : 'calc(100% - 104px)',
                  overflowY: 'auto',
                }}
              >
                <Spacer size="34" direction="horizontal" />
                <NewContainer
                  title={newFieldTitle}
                  setTitle={setNewFieldTitle}
                  content={newFieldContent}
                  setContent={setNewFieldContent}
                  handleSave={handleNewContainer}
                  loading={loadingExtras}
                  image={imageNewContainer}
                  setImage={setImageNewContainer}
                  fieldType={fieldType}
                  setFieldType={setFieldType}
                  themeCustomSwitch={themeCustomSwitch}
                  setThemeCustomSwitch={setThemeCustomSwitch}
                  themeType={themeType}
                  setThemeType={setThemeType}
                  selectedTheme={selectedTheme}
                  setSelectedTheme={setSelectedTheme}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {propostaStep == 'structuringProposal' && (
        <ProposalStructurer
          setPropostaStep={setPropostaStep}
          proposal={proposal}
          isFromProposalList={isFromProposalList}
          setIsProPlanModalOpen={setIsProPlanModalOpen}
          isUserProPlan={isUserProPlan}
          checkPdfDragAndDrop={checkPdfDragAndDrop}
          checkDragAndDrop={checkDragAndDrop}
          getMoreClients={() => getMoreClients}
          hasMoreClients={hasMoreClients}
          setFilterClientName={setFilterClientName}
          filterClientName={filterClientName}
          resetFieldSearch={() => resetFieldSearch}
          clients={clients}
          getFirstPageClients={getClients}
        />
      )}
      {propostaStep === 'fullscreen' && (
        <>
          {downSm && (
            <Header
              onBack={() => setPropostaStep('configsExtras')}
              title={
                <Typography
                  style={{
                    padding: isFromProposalList ? '4.8px 0px' : '7.8px 0px',
                  }}
                  type={downSm ? 'text_medium' : 'text_large'}
                  color={downSm ? 'white' : 'grayscale'}
                  colorWeight="700"
                >
                  Visualizar tela inteira
                </Typography>
              }
              noMargin
            >
              <div>
                <Tooltip title="Editar proposta">
                  <IconButton
                    aria-label="Editar proposta"
                    onClick={() => setPropostaStep('structuringProposal')}
                  >
                    <EditIcon width="24px" color={palette.white} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar">
                  <IconButton
                    aria-label="Enviar proposta"
                    onClick={() => handleOpenSendProposalModal()}
                  >
                    <SendIcon width="24px" color={palette.white} />
                  </IconButton>
                </Tooltip>
              </div>
            </Header>
          )}
          <FullscreenProposal
            setPropostaStep={setPropostaStep}
            proposal={proposal}
          />
        </>
      )}
      {propostaStep == 'fullscreenPdf' && (
        <>
          {downSm && (
            <Header
              onBack={() => setPropostaStep('configsExtras')}
              title={
                <Typography
                  style={{
                    padding: isFromProposalList ? '4.8px 0px' : '7.8px 0px',
                  }}
                  type={downSm ? 'text_medium' : 'text_large'}
                  color={downSm ? 'white' : 'grayscale'}
                  colorWeight="700"
                >
                  Visualizar tela inteira
                </Typography>
              }
              noMargin
            >
              <div>
                <Tooltip title="Editar proposta">
                  <IconButton
                    aria-label="Editar proposta"
                    onClick={() => setPropostaStep('structuringProposal')}
                  >
                    <EditIcon width="24px" color={palette.white} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enviar">
                  <IconButton
                    aria-label="Enviar proposta"
                    onClick={() => handleOpenSendProposalModal()}
                  >
                    <SendIcon width="24px" color={palette.white} />
                  </IconButton>
                </Tooltip>
              </div>
            </Header>
          )}
          <FullscreenPdfProposal
            setPropostaStep={setPropostaStep}
            isFromProposalList={isFromProposalList}
          />
        </>
      )}
      {sendModalStepFour && (
        <ProposalShareModal
          onlyButtons
          isOpen={sendModalStepFour}
          proposal={{
            ...proposal.proposal,
            dimensionings: proposal.sizings.length,
            quotations: proposal.quotes.length,
          }}
          onClose={() => setSendModalStepFour(false)}
          onSend={(sendBy) => {
            logEvent(proposalTracker.actions.sendProposalToCustomer, {
              sendBy,
              origin: '/dimensionar - Proposta',
            })
          }}
        />
      )}

      {sizingModal && (
        <SizingModal sizing={sizingModal} setSizing={setSizingModal} />
      )}
      <Backdrop className={classes.backdrop} open={openLoadingPdf}>
        <CircularProgress style={{ marginBottom: '20px' }} color="inherit" />
        Carregando PDF
      </Backdrop>
      {isFromProposalList && isProModalOpen && (
        <ProModal
          isOpen={isProModalOpen}
          onClose={() => dispatch(setIsProPlanModalOpenRedux(false))}
          showForPayment={false}
        />
      )}
      {isProModalOpen && (
        <ProModal
          zIndex={1000}
          isOpen={isProPlanModalOpen}
          onClose={() => setIsProPlanModalOpen(false)}
        />
      )}

      {deletingField && (
        <ModalRemoveCustomField
          deletingField={deletingField}
          setDeletingField={setDeletingField}
          handleRemoveField={handleRemoveField}
          loadingExtras={loadingExtras}
        />
      )}

      {modalMoreOptions && (
        <ModalMoreOptions
          modalMoreOptions={modalMoreOptions}
          setModalMoreOptions={setModalMoreOptions}
          setSendModalStepFour={setSendModalStepFour}
          setPropostaStep={setPropostaStep}
        />
      )}

      {outdatedQuotes?.length !== 0 && outdatedQuotesModal && (
        <ModalOutdatedQuotes
          outdatedQuotes={outdatedQuotes}
          quoteIdEditing={quoteIdEditing}
          outdatedQuotesModal={outdatedQuotesModal}
          setOutdatedQuotesModal={setOutdatedQuotesModal}
          outdatedQuotesModalStep={outdatedQuotesModalStep}
          setOutdatedQuotesModalStep={setOutdatedQuotesModalStep}
          setQuoteIdEditing={setQuoteIdEditing}
          handleChangeQuantity={handleChangeQuantity}
          setSelectedOutdatedQuote={setSelectedOutdatedQuote}
          selectedOutdatedQuote={selectedOutdatedQuote}
          setModalChangeProduct={setModalChangeProduct}
          setProductToChange={setProductToChange}
          loadingAttProduct={loadingAttProduct}
        />
      )}
      {modalChangeProduct && (
        <ModalAlterarProduto
          product={productToChange}
          isOpen={modalChangeProduct}
          onChangeProduct={handleChangeProduct}
          onClose={() => setModalChangeProduct(false)}
          quotationId={
            proposal.quotes.find(
              (quote) => quote?.quotation?.id == quoteIdEditing,
            )?.quotation?.id
          }
        />
      )}
      {selectClientModal && (
        <ModalSelectClient
          fromStepFour
          proposal={proposal}
          getClients={getClients}
          clients={clients}
          selectClientModal={selectClientModal}
          setSelectClientModal={setSelectClientModal}
          proposalListHandleClose={proposalListHandleClose}
        />
      )}
    </div>
  )
}

export default StepFour
