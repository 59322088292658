import { colors } from '@77sol-ui/tokens'
import styled, { keyframes } from 'styled-components'

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1004;
  color: ${colors.white[0]};
  > svg {
    animation: ${spin} 1s linear infinite;
  }

  > span {
    font-size: 16px;
    font-family: DM Sans;
  }
`
