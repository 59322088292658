import { NOTIFICATION_URLS } from 'containers/Notifications/constants/redirectNotificationURLs'
import { type INotificationDTO } from 'dto/NotificationDTO'
import { type INotificationOrderParams } from 'dto/NotificationDTO/order'

export const redirectOrderNotification = (notification: INotificationDTO) => {
  const { orderId } = notification.params as INotificationOrderParams
  const path = NOTIFICATION_URLS[notification.type].concat(`/${orderId}`)

  return path
}
