import API from 'api'
import { GET_NOTIFICATIONS_ALL } from '../constants/endpointKeys'
import {
  type INotificationsAllParams,
  type INotificationsAllGETResponse,
} from './types'

function formatNotificationsData(notifications: INotificationsAllGETResponse) {
  return notifications?.data?.map(({ json_data }) => json_data) ?? []
}

export const notificationsAll = {
  get: async (params: INotificationsAllParams) =>
    await API.get<INotificationsAllGETResponse>(GET_NOTIFICATIONS_ALL, {
      params,
    })
      .then(({ data }) => ({
        ...data,
        data: formatNotificationsData(data),
      }))
      .catch(() => {
        throw new Error()
      }),
}
