import React, { useState, useEffect, createRef, useRef } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid } from '@material-ui/core'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import {
  ImagesPaper,
  SchedulePaper,
  TextPaper,
  Spacer,
  Paper,
  ImagePaper,
  useToast,
} from '@77sol/core'
import { Typography, Toggle, WorkImagesEdit, NewContainer } from 'components'
import { useDispatch } from 'react-redux'
import {
  AutoLayoutIcon,
  CloseXIcon,
  EditIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ViewIcon,
} from '@77sol/icons/dist'
import smoothscroll from 'smoothscroll-polyfill'
import 'moment/locale/pt-br'
import palette from 'app_palette'
import * as ProposalAction from 'store/actions'
import API from 'api'
import { CronogramaEdit } from '../../..'
import {
  DigitalInvestingSection,
  DigitalEquipmentsSection,
  DigitalPaymentSection,
  ProposalPopover,
} from '..'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  borderRadius: '16px',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const grid = 8

const reorderMain = (list, startIndex, endIndex, direction) => {
  const result = Array.from(list)
  let nextVisible = []
  if (direction == 'down') {
    nextVisible = result
      .slice(endIndex, result.length)
      .find((container) => container.visible == true)
  } else {
    nextVisible = result
      .slice(0, startIndex)
      .reverse()
      .find((container) => container.visible == true)
  }
  let NextVisibleIndex = ''
  if (nextVisible) {
    NextVisibleIndex = result.findIndex(
      (container) => container.id == nextVisible.id,
    )
  } else {
    NextVisibleIndex = endIndex
  }
  const [removed] = result.splice(startIndex, 1)
  result.splice(NextVisibleIndex, 0, removed)

  return result
}

function DigitalProposalContainer({
  container,
  investingContainers,
  index,
  isEditing,
  onClick,
  allContainers,
  setState,
  setIsEditing,
  isViewing,
  setIsViewing,
  editMode,
  setEditMode,
  investingSections,
  stateEquipmentsSections,
  statePaymentSections,
  ProposalInfosSections,
  setStateInvesting,
  setStateInvestingSections,
  setStateEquipmentsSections,
  setStatePaymentSections,
  setProposalInfosSections,
  selectedQuote,
  proposal,
  size,
  paymentOptionsContainers,
  setPaymentOptionsContainers,
  moreInfosContainers,
  setMoreInfosContainers,
  ProposalSubInfosSections,
  setProposalSubInfosSections,
  tirVplContainers,
  setTirVplContainers,
  retornoSubCards,
  setRetornoSubCards,
  colors,
  budgetEdit,
  setBudgetEdit,
  returnData,
  setReturnData,
  handleGetBudget,
  isFromProposalList,
  setIsProPlanModalOpen,
  isUserProPlan,
  editRef,
  checkDragAndDrop,
  checkPdfDragAndDrop,
}) {
  const hiddenContainersRef = useRef(null)
  const { onErrorOpenToast } = useToast()

  const [isViewingExpanded, setIsViewingExpanded] = useState(false)
  const [internalEditing, setInternalEditing] = useState('')
  const [drag, setDrag] = useState('')
  const [editingMore, setEditingMore] = useState('')
  const [equipmentsList, setEquipmentsList] = useState({
    id: 'equipmentsList',
    content: 'item-equipmentsList',
    visible: true,
    display: 'Equipamentos',
  })

  const dispatch = useDispatch()

  const [loadingExtras, setLoadingExtras] = useState(false)
  const [qtdSchedule, setQtdSchedule] = useState(
    proposal?.extraConfigs?.schedules?.data
      ? proposal.extraConfigs.schedules.data.length
      : 1,
  )
  const [schedules, setSchedules] = useState(
    proposal?.extraConfigs?.schedules?.data
      ? proposal.extraConfigs.schedules.data
      : [{ title: '', weeks: '' }],
  )
  const [workImages, setWorkImages] = useState(
    proposal.extraConfigs?.work_images?.data
      ? Array.from(proposal?.extraConfigs?.work_images?.data, (x) => x.url)
      : [],
  )
  const [editingFieldTitle, setEditingFieldTitle] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.title,
  )
  const [editingFieldContent, setEditingFieldContent] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.content,
  )
  const [imageEditContainer, setImageEditContainer] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.content,
  )
  const [fieldType, setFieldType] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.type
      ? proposal?.extraConfigs?.extra_configs?.find(
          (item) => item.id == container.id,
        )?.type
      : 'text',
  )

  const [themeCustomSwitch, setThemeCustomSwitch] = useState(
    proposal?.extraConfigs?.extra_configs?.find(
      (item) => item.id == container.id,
    )?.type == 'theme'
      ? 0
      : 1,
  )
  const [themeType, setThemeType] = useState('covers')
  const [selectedTheme, setSelectedTheme] = useState('')

  const handleEditContainer = () => {
    setLoadingExtras(true)

    const formData = new FormData()
    if (fieldType == 'image') {
      if (imageEditContainer.fileName) {
        formData.append(
          'content',
          imageEditContainer,
          imageEditContainer.fileName,
        )
      } else {
        formData.append('content', imageEditContainer)
      }
    } else {
      formData.append('content', editingFieldContent)
    }
    if (themeCustomSwitch == 0) {
      formData.append('content_key', editingFieldTitle?.split('/')[1])
    }
    formData.append('id', container.id)
    formData.append('proposal_id', proposal.proposal.id)
    formData.append('title', editingFieldTitle)
    formData.append('type', themeCustomSwitch == 0 ? 'theme' : fieldType)
    formData.append('status', 1)

    API.post('/proposal/extra-config/edit', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      setEditingMore('')
      setIsViewing('')
      setIsEditing(false)
      setEditMode('')
      setLoadingExtras(false)
      handleGetExtraConfigs(proposal.proposal.id)
      checkDragAndDrop(
        'update',
        container.id,
        editingFieldTitle,
        editingFieldContent,
      ).then(() => {
        checkPdfDragAndDrop(
          'update',
          container.id,
          editingFieldTitle,
          editingFieldContent,
        )
      })
    })
  }

  const handleEditImage = () => {
    const formData = new FormData()
    formData.append('work_images', workImages, workImages.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('/proposal/update/work-images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(() => {
        handleGetExtraConfigs(proposal.proposal.id)
      })
      .catch((err) => {
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar as imagens de obras!')
        }
      })
  }

  const handleGetExtraConfigs = (id) => {
    API.get(`/proposal/extra-configs/${id}`).then((res) => {
      dispatch(ProposalAction.SaveExtraConfigsRedux(res.data))
    })
  }

  const handleRemoveImage = (url) => {
    API.post('/proposal/remove-image', {
      url,
      proposal_id: proposal.proposal.id,
    }).then((res) => {
      dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
      setWorkImages(res.data.proposal.work_images)
    })
  }

  const handleSaveWorkImages = (workImages) => {
    setLoadingExtras(true)

    const formData = new FormData()
    formData.append('work_images', workImages, workImages.fileName)
    formData.append('proposal_id', proposal.proposal.id)

    API.post('proposal/update/work-images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        handleGetExtraConfigs(proposal.proposal.id)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        setWorkImages(res.data.proposal.work_images)
      })
      .catch((err) => {
        if (err?.response?.data?.message == 'Request Entity Too Large') {
          onErrorOpenToast('Tamanho do arquivo muito grande!')
        } else {
          onErrorOpenToast('Não foi possível alterar as imagens de obras!')
        }
      })
  }

  const handleSaveSchedules = (schedules) => {
    setLoadingExtras(true)

    API.post('/proposal/edit/schedules', {
      proposal_id: proposal.proposal.id,
      schedules,
      status: 1,
    }).then((res) => {
      handleGetExtraConfigs(proposal.proposal.id)
      setLoadingExtras(false)
      dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
      setSchedules(res.data.proposal.schedules)
    })
  }

  const handleOptionToRender = () => {
    switch (isViewing) {
      case 'investingContainer':
        return investingContainers
      case 'equipmentsContainer':
        return stateEquipmentsSections
      case 'paymentsOptionsContainer':
        return [...paymentOptionsContainers, ...moreInfosContainers]
      case 'InfosContainer':
        return ProposalInfosSections
      default:
        break
    }
  }

  const handleChangeVisibility = (container) => {
    switch (isViewing) {
      case 'investingContainer':
        if (container.id == 'retornoGrafico') {
          const newArrInvestingContainer = [...investingSections]
          const indexInvestingContainer = newArrInvestingContainer.findIndex(
            (item) => item.id == container.id,
          )
          newArrInvestingContainer[indexInvestingContainer].visible =
            !newArrInvestingContainer[indexInvestingContainer].visible
          setStateInvestingSections({ containers: newArrInvestingContainer })
        }
        if (
          investingContainers.findIndex((item) => item.id == container.id) != -1
        ) {
          const newArrInvestingSection = [...investingContainers]
          const indexInvestingSection = newArrInvestingSection.findIndex(
            (item) => item.id == container.id,
          )
          newArrInvestingSection[indexInvestingSection].visible =
            !newArrInvestingSection[indexInvestingSection].visible
          setStateInvesting({ containers: newArrInvestingSection })
        }
        if (
          tirVplContainers.findIndex((item) => item.id == container.id) != -1
        ) {
          const newArrInvestingSection = [...tirVplContainers]
          const indexInvestingSection = newArrInvestingSection.findIndex(
            (item) => item.id == container.id,
          )
          newArrInvestingSection[indexInvestingSection].visible =
            !newArrInvestingSection[indexInvestingSection].visible
          setTirVplContainers({ containers: newArrInvestingSection })
        }
        if (
          retornoSubCards.findIndex((item) => item.id == container.id) != -1
        ) {
          const newArrInvestingSection = [...retornoSubCards]
          const indexInvestingSection = newArrInvestingSection.findIndex(
            (item) => item.id == container.id,
          )
          newArrInvestingSection[indexInvestingSection].visible =
            !newArrInvestingSection[indexInvestingSection].visible
          setRetornoSubCards({ containers: newArrInvestingSection })
        }
        break
      case 'equipmentsContainer':
        const newArrEquip = [...stateEquipmentsSections]
        const indexEquip = newArrEquip.findIndex(
          (item) => item.id == container.id,
        )
        newArrEquip[indexEquip].visible = !newArrEquip[indexEquip].visible
        setStateEquipmentsSections({ containers: newArrEquip })
        break
      case 'paymentsOptionsContainer':
        if (
          paymentOptionsContainers.findIndex(
            (item) => item.id == container.id,
          ) != -1
        ) {
          const newArrPay = [...paymentOptionsContainers]
          const indexPay = newArrPay.findIndex(
            (item) => item.id == container.id,
          )
          newArrPay[indexPay].visible = !newArrPay[indexPay].visible
          setPaymentOptionsContainers({ containers: newArrPay })
        }
        if (
          moreInfosContainers.findIndex((item) => item.id == container.id) != -1
        ) {
          const newArrPay = [...moreInfosContainers]
          const indexPay = newArrPay.findIndex(
            (item) => item.id == container.id,
          )
          newArrPay[indexPay].visible = !newArrPay[indexPay].visible
          setMoreInfosContainers({ containers: newArrPay })
        }
        break
      case 'InfosContainer':
        if (
          ProposalInfosSections.findIndex((item) => item.id == container.id) !=
          -1
        ) {
          const newArrInfo = [...ProposalInfosSections]
          const indexinfo = newArrInfo.findIndex(
            (item) => item.id == container.id,
          )
          newArrInfo[indexinfo].visible = !newArrInfo[indexinfo].visible
          setProposalInfosSections({ containers: newArrInfo })
        }
        if (
          ProposalSubInfosSections.findIndex(
            (item) => item.id == container.id,
          ) != -1
        ) {
          const newArrInfo = [...ProposalSubInfosSections]
          const indexinfo = newArrInfo.findIndex(
            (item) => item.id == container.id,
          )
          newArrInfo[indexinfo].visible = !newArrInfo[indexinfo].visible
          setProposalSubInfosSections({ containers: newArrInfo })
        }
        break
      default:
        break
    }
  }

  // Scroll
  const refContainer = createRef()
  const handleClickContainer = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (refContainer.current) {
      refContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    if (!refContainer.current) {
      return
    }
    handleClickContainer()
  }, [isViewing, isEditing])

  const handleClickOutside = (event) => {
    if (
      editRef.current &&
      !editRef.current.contains(event.target) &&
      event.target.nodeName != 'INPUT' &&
      event.target.className != 'popoverToggles' &&
      !document.getElementById('popoverClass')?.contains(event.target)
    ) {
      setIsViewing('')
      setIsEditing(false)
      setEditingMore('')
      setEditMode('')
      setInternalEditing('')
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <Draggable
      type="droppableItem"
      isDragDisabled
      key={container.id}
      draggableId={container.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          onClick={(e) => {
            if (isViewing && isViewing != container.id) {
              setIsViewing('')
              setIsEditing(false)
              setEditingMore('')
              setEditMode('')
              setInternalEditing('')
              e.stopPropagation()
              e.preventDefault()
            } else {
              onClick()
            }
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            backgroundColor: colors ? colors.background : '#F7F7FC',
            border: isEditing ? `3px solid ${palette.primary[300]}` : 'none',
            padding:
              isEditing || isViewing == container.id
                ? '30px 0px 8px 0px'
                : '16px 0px',
            cursor: 'pointer',
            position: 'relative',
            opacity:
              isViewing && isViewing == container.id ? 1 : isViewing ? 0.1 : 1,
            zIndex:
              isViewing == container.id
                ? 501
                : editingMore
                ? 10
                : isViewing
                ? index >
                  allContainers.findIndex(
                    (container) => container.id == isViewing,
                  )
                  ? 500
                  : 502
                : 10,
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
          }}
        >
          {isViewing == container.id && (
            <div
              onClick={(e) => {
                setIsViewing('')
                setIsEditing(false)
                setEditingMore('')
                setEditMode('')
                setInternalEditing('')
                e.stopPropagation()
              }}
              style={{
                backgroundColor: '#000',
                position: 'fixed',
                width: '100vw',
                height: 'var(--app-height)',
                top: '0px',
                left: '0px',
                opacity: 0.5,
              }}
            />
          )}
          {isViewing == container.id &&
            container.id != 'scheduleCard' &&
            container.id != 'constructionImagesCard' && (
              <div
                ref={hiddenContainersRef}
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-15px',
                  left: '0px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {container.id != 'scheduleCard' &&
                  container.id != 'constructionImagesCard' &&
                  !Number.isInteger(container.id) && (
                    <Paper
                      boxShadow="small"
                      style={{
                        borderRadius: '8px',
                        cursor: 'auto',
                        padding: '16px 24px',
                        width: 'auto',
                        display: isViewingExpanded && 'none',
                      }}
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="700"
                        >
                          {container.id == 'investingContainer' &&
                            'Seção - Retorno de investimento'}
                          {container.id == 'equipmentsContainer' &&
                            'Seção - Dados do projeto'}
                          {container.id == 'paymentsOptionsContainer' &&
                            'Seção - Financiamento e pagamento'}
                          {container.id == 'scheduleCard' &&
                            'Seção - Cronograma'}
                          {container.id == 'constructionImagesCard' &&
                            'Seção - Imagens de obras'}
                          {Number.isInteger(container.id) &&
                            `${container.display}`}
                        </Typography>
                        <Spacer size="24" direction="vertical" />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {isViewingExpanded ? (
                            <Tooltip title="Esconder">
                              <CloseXIcon
                                onClick={() =>
                                  isViewingExpanded
                                    ? setIsViewingExpanded(false)
                                    : setIsViewingExpanded(true)
                                }
                                style={{ cursor: 'pointer' }}
                                width="24"
                                color={palette.grayscale[700]}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Expandir">
                              <AutoLayoutIcon
                                onClick={() =>
                                  isViewingExpanded
                                    ? setIsViewingExpanded(false)
                                    : setIsViewingExpanded(true)
                                }
                                style={{ cursor: 'pointer' }}
                                width="24"
                                color={palette.grayscale[700]}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </Paper>
                  )}
              </div>
            )}
          <ProposalPopover
            title="Seções ocultadas"
            anchorRef={hiddenContainersRef.current}
            value={isViewingExpanded}
            setValue={setIsViewingExpanded}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {isViewingExpanded && (
              <div>
                <Spacer size="16" direction="horizontal" />
                <Paper
                  style={{
                    backgroundColor: '#647381',
                    color: '#FFF',
                    textAlign: 'center',
                    padding: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <Typography type="link_small" color="white">
                    Visualização
                  </Typography>
                </Paper>
                <Spacer size="16" direction="horizontal" />
                {isViewing == 'equipmentsContainer' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '12px 16px',
                    }}
                  >
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      Equipamentos
                    </Typography>
                    <Spacer size="42" direction="vertical" />
                    <Toggle
                      onChange={() => {
                        const newContainer = { ...equipmentsList }
                        newContainer.visible
                          ? (newContainer.visible = false)
                          : (newContainer.visible = true)
                        setEquipmentsList(newContainer)
                      }}
                      checked={equipmentsList.visible}
                    />
                  </div>
                )}
                {handleOptionToRender()?.map((option, i) =>
                  option.id == 'financingCard' ||
                  option.id == 'paymentOptionsCard' ||
                  option.id == 'userOptionsCard' ||
                  option.id == 'proposalInfosCard' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 16px',
                      }}
                    >
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        {option.display}
                      </Typography>
                      <Spacer size="42" direction="vertical" />
                      <LockRoundedIcon
                        style={{ fontSize: '16px', color: '#707072' }}
                      />
                    </div>
                  ) : option.id == 'retornoSubCards' ? (
                    tirVplContainers?.map((option, i) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px 16px',
                        }}
                      >
                        <Typography
                          type="link_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {option.display}
                        </Typography>
                        <Spacer size="42" direction="vertical" />
                        <Toggle
                          onChange={() => handleChangeVisibility(option)}
                          checked={option.visible}
                        />
                      </div>
                    ))
                  ) : option.id == 'tirVplContainers' ? (
                    retornoSubCards?.map((option, i) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px 16px',
                        }}
                      >
                        <Typography
                          type="link_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {option.display}
                        </Typography>
                        <Spacer size="42" direction="vertical" />
                        <Toggle
                          onChange={() => handleChangeVisibility(option)}
                          checked={option.visible}
                        />
                      </div>
                    ))
                  ) : option.id == 'subCards' ? (
                    ProposalSubInfosSections?.map((option, i) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '12px 16px',
                        }}
                      >
                        <Typography
                          type="link_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {option.display}
                        </Typography>
                        <Spacer size="42" direction="vertical" />
                        <Toggle
                          onChange={() => handleChangeVisibility(option)}
                          checked={option.visible}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 16px',
                      }}
                    >
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        {option.display}
                      </Typography>
                      <Spacer size="42" direction="vertical" />
                      <Toggle
                        onChange={() => handleChangeVisibility(option)}
                        checked={option.visible}
                      />
                    </div>
                  ),
                )}
                {isViewing == 'investingContainer' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '12px 16px',
                    }}
                  >
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      Curva de retorno
                    </Typography>
                    <Spacer size="42" direction="vertical" />
                    <Toggle
                      onChange={() =>
                        handleChangeVisibility(
                          investingSections.find(
                            (item) => item.id == 'retornoGrafico',
                          ),
                        )
                      }
                      checked={
                        investingSections.find(
                          (item) => item.id == 'retornoGrafico',
                        ).visible
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </ProposalPopover>
          {isEditing && (
            <div
              ref={(el) => {
                refContainer.current = el
              }}
              style={{
                position: 'absolute',
                zIndex: 10,
                top: '-28px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Paper
                style={{
                  width: 'auto',
                  backgroundColor: palette.primary[300],
                  padding: '16px 24px',
                }}
              >
                <Typography type="text_x_small" color="white">
                  {container.id == 'investingContainer' &&
                    'Seção - Retorno de investimento'}
                  {container.id == 'equipmentsContainer' &&
                    'Seção - Dados do projeto'}
                  {container.id == 'paymentsOptionsContainer' &&
                    'Seção - Financiamento e pagamento'}
                  {container.id == 'InfosContainer' &&
                    'Seção - Informações extras'}
                  {container.id == 'scheduleCard' && 'Seção - Cronograma'}
                  {container.id == 'constructionImagesCard' &&
                    'Seção - Imagens de obras'}
                  {Number.isInteger(container.id) &&
                    `${
                      container.display[1]
                        ? (container.display.split('/')[0] == 'covers' &&
                            `${`${'Capa' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'covers',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'explanations' &&
                            `${`${'Explicações' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] ==
                                    'explanations',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'benefits' &&
                            `${`${'Vantagens' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'benefits',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'partners' &&
                            `${`${'Parceiros' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'partners',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          container.display
                        : container.display
                    }`}
                </Typography>
              </Paper>
            </div>
          )}
          {isEditing && (
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                position: 'absolute',
                zIndex: 10,
                top: '14px',
                right: '16px',
                cursor: 'auto',
              }}
            >
              <Paper
                style={{
                  padding: '16px 24px',
                  display: 'flex',
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              >
                <Typography
                  type="link_small"
                  color="grayscale"
                  colorWeight="700"
                >
                  {container.id == 'investingContainer' &&
                    'Retorno de investimento'}
                  {container.id == 'equipmentsContainer' && 'Dados do projeto'}
                  {container.id == 'paymentsOptionsContainer' &&
                    'Financiamento e pagamento'}
                  {container.id == 'InfosContainer' && 'Informações extras'}
                  {container.id == 'scheduleCard' && 'Cronograma'}
                  {container.id == 'constructionImagesCard' &&
                    'Imagens de obras'}
                  {Number.isInteger(container.id) &&
                    `${
                      container.display[1]
                        ? (container.display.split('/')[0] == 'covers' &&
                            `${`${'Capa' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'covers',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'explanations' &&
                            `${`${'Explicações' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] ==
                                    'explanations',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'benefits' &&
                            `${`${'Vantagens' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'benefits',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'partners' &&
                            `${`${'Parceiros' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'partners',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          container.display
                        : container.display
                    }`}
                </Typography>
                <Spacer size="24" direction="vertical" />
                <div style={{ display: 'flex' }}>
                  <Tooltip title="Editar">
                    <div style={{ display: 'flex' }}>
                      <EditIcon
                        onClick={() => {
                          if (
                            container.id != 'scheduleCard' &&
                            container.id != 'constructionImagesCard' &&
                            !Number.isInteger(container.id)
                          ) {
                            setIsViewing(container.id)
                            setIsEditing(false)
                            setEditMode(container.id)
                          } else {
                            if (!isUserProPlan) {
                              return setIsProPlanModalOpen(true)
                            }
                            setIsViewing(container.id)
                            setIsEditing(false)
                            setEditMode(container.id)
                            editingMore == container.id
                              ? setEditingMore('')
                              : setEditingMore(container.id)
                          }
                        }}
                        width="24"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </Tooltip>
                  {index != 0 &&
                    allContainers.filter(
                      (container) => container.visible == true,
                    )[0].id != container.id && (
                      <>
                        <Spacer size="12" direction="vertical" />
                        <Tooltip title="Mover para cima">
                          <div style={{ display: 'flex' }}>
                            <ArrowUpIcon
                              width="24"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (index != 0) {
                                  const containers = reorderMain(
                                    allContainers,
                                    index,
                                    index - 1,
                                    'up',
                                  )
                                  setState({ containers })
                                }
                              }}
                            />
                          </div>
                        </Tooltip>
                      </>
                    )}
                  {index != allContainers.length - 1 &&
                    allContainers
                      .filter((container) => container.visible == true)
                      .reverse()[0].id != container.id && (
                      <>
                        <Spacer size="12" direction="vertical" />
                        <Tooltip title="Mover para baixo">
                          <div style={{ display: 'flex' }}>
                            <ArrowDownIcon
                              width="24"
                              onClick={() => {
                                if (index != allContainers.length - 1) {
                                  const containers = reorderMain(
                                    allContainers,
                                    index,
                                    index + 1,
                                    'down',
                                  )
                                  setState({ containers })
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                        </Tooltip>
                      </>
                    )}
                  {container.id != 'paymentsOptionsContainer' && (
                    <>
                      <Spacer size="12" direction="vertical" />
                      <Tooltip title="Esconder">
                        <div style={{ display: 'flex' }}>
                          <ViewIcon
                            width="24"
                            onClick={() => {
                              const newContainer = Array.from(allContainers)
                              newContainer[index].visible = false
                              setState({ containers: newContainer })
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    </>
                  )}
                </div>
              </Paper>
            </div>
          )}
          {container.id == 'investingContainer' && (
            <Droppable
              direction={
                size.width > (isFromProposalList ? 1413 : 1920)
                  ? 'horizontal'
                  : 'vertical'
              }
              type="droppableSubItemInvestingSection"
              droppableId="droppableSubItemInvestingSection"
            >
              {(provided) => (
                <Grid
                  onClick={(e) =>
                    isViewing == container.id && e.stopPropagation()
                  }
                  ref={provided.innerRef}
                  style={{
                    position: 'relative',
                    backgroundColor: colors ? colors.background : '#F7F7FC',
                    borderRadius: '8px',
                  }}
                  {...provided.droppableProps}
                  container
                >
                  {investingSections.map((container, index) => (
                    <>
                      {container.visible && (
                        <DigitalInvestingSection
                          editingMore={editingMore}
                          selectedQuote={selectedQuote}
                          setEditingMore={setEditingMore}
                          drag={drag}
                          setDrag={setDrag}
                          setStateInvesting={setStateInvesting}
                          investingSections={investingSections}
                          setStateInvestingSections={setStateInvestingSections}
                          container={container}
                          index={index}
                          investingContainers={investingContainers}
                          editMode={editMode}
                          internalEditing={internalEditing}
                          setInternalEditing={setInternalEditing}
                          proposal={proposal}
                          size={size}
                          tirVplContainers={tirVplContainers}
                          setTirVplContainers={setTirVplContainers}
                          retornoSubCards={retornoSubCards}
                          setRetornoSubCards={setRetornoSubCards}
                          colors={colors}
                          budgetEdit={budgetEdit}
                          setBudgetEdit={setBudgetEdit}
                          returnData={returnData}
                          setReturnData={setReturnData}
                          handleGetBudget={handleGetBudget}
                        />
                      )}
                    </>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          )}
          {container.id == 'equipmentsContainer' && (
            <Droppable
              direction="horizontal"
              type="droppableEquipmentsSection"
              droppableId="droppableEquipmentsSection"
            >
              {(provided) => (
                <Grid
                  onClick={(e) =>
                    isViewing == container.id && e.stopPropagation()
                  }
                  ref={provided.innerRef}
                  style={{
                    position: 'relative',
                    backgroundColor: colors ? colors.background : '#F7F7FC',
                    borderRadius: '8px',
                  }}
                  {...provided.droppableProps}
                  container
                >
                  {stateEquipmentsSections.map((container, index) => (
                    <>
                      {container.visible && (
                        <DigitalEquipmentsSection
                          selectedQuote={selectedQuote}
                          setEditingMore={setEditingMore}
                          editingMore={editingMore}
                          drag={drag}
                          equipmentsList={equipmentsList}
                          setDrag={setDrag}
                          stateEquipmentsSections={stateEquipmentsSections}
                          setStateEquipmentsSections={
                            setStateEquipmentsSections
                          }
                          container={container}
                          index={index}
                          editMode={editMode}
                          internalEditing={internalEditing}
                          setInternalEditing={setInternalEditing}
                          size={size}
                          proposal={proposal}
                          colors={colors}
                          budgetEdit={budgetEdit}
                          handleGetBudget={handleGetBudget}
                        />
                      )}
                    </>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          )}
          {container.id == 'paymentsOptionsContainer' && (
            <Droppable
              direction="vertical"
              type="droppablePaymentsSection"
              droppableId="droppablePaymentsSection"
            >
              {(provided) => (
                <Grid
                  onClick={(e) =>
                    isViewing == container.id && e.stopPropagation()
                  }
                  ref={provided.innerRef}
                  style={{
                    position: 'relative',
                    backgroundColor: colors ? colors.background : '#F7F7FC',
                    borderRadius: '8px',
                  }}
                  {...provided.droppableProps}
                  container
                >
                  {statePaymentSections.map((container, index) => (
                    <>
                      {container.visible && (
                        <DigitalPaymentSection
                          editingMore={editingMore}
                          selectedQuote={selectedQuote}
                          setEditingMore={setEditingMore}
                          drag={drag}
                          setDrag={setDrag}
                          statePaymentSections={statePaymentSections}
                          setStatePaymentSections={setStatePaymentSections}
                          container={container}
                          index={index}
                          editMode={editMode}
                          internalEditing={internalEditing}
                          setInternalEditing={setInternalEditing}
                          size={size}
                          paymentOptionsContainers={paymentOptionsContainers}
                          setPaymentOptionsContainers={
                            setPaymentOptionsContainers
                          }
                          moreInfosContainers={moreInfosContainers}
                          setMoreInfosContainers={setMoreInfosContainers}
                          proposal={proposal}
                          colors={colors}
                        />
                      )}
                    </>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          )}
          {container.id == 'scheduleCard' && (
            <Grid
              style={{
                position: 'relative',
                backgroundColor: colors ? colors.background : '#F7F7FC',
                borderRadius: '8px',
              }}
              container
            >
              <Grid
                item
                style={{
                  padding: '4px',
                  position: 'relative',
                }}
                xs={12}
              >
                <SchedulePaper colors={colors} schedules={schedules} />
              </Grid>
            </Grid>
          )}
          {container.id == 'constructionImagesCard' &&
            proposal?.extraConfigs?.work_images &&
            proposal?.extraConfigs?.work_images?.data?.length != 0 && (
              <Grid
                style={{
                  position: 'relative',
                  backgroundColor: colors ? colors.background : '#F7F7FC',
                  borderRadius: '8px',
                }}
                container
              >
                <Grid
                  item
                  style={{
                    padding: '4px',
                    position: 'relative',
                  }}
                  xs={12}
                >
                  <ImagesPaper colors={colors} proposal={proposal} />
                </Grid>
              </Grid>
            )}
          {Number.isInteger(container.id) && (
            <Grid
              style={{
                position: 'relative',
                backgroundColor: colors ? colors.background : '#F7F7FC',
                borderRadius: '8px',
              }}
              container
            >
              <Grid
                item
                style={{
                  padding: '4px',
                  position: 'relative',
                }}
                xs={12}
              >
                {proposal.extraConfigs.extra_configs
                  .find((item) => item.id == container.id)
                  ?.title?.split('/')[1] ? (
                  <img
                    alt="Extra config"
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '8px',
                    }}
                    src={
                      proposal.extraConfigs.extra_configs.find(
                        (item) => item.id == container.id,
                      )?.content
                    }
                  />
                ) : (
                  <>
                    {proposal.extraConfigs.extra_configs
                      .find((item) => item.id == container.id)
                      ?.content.includes('.s3') ? (
                      <ImagePaper
                        image={
                          proposal.extraConfigs.extra_configs.find(
                            (item) => item.id == container.id,
                          )?.content
                        }
                        title={
                          proposal.extraConfigs.extra_configs.find(
                            (item) => item.id == container.id,
                          )?.title
                        }
                        colors={colors}
                      />
                    ) : (
                      <TextPaper
                        text={
                          proposal.extraConfigs.extra_configs.find(
                            (item) => item.id == container.id,
                          )?.content
                        }
                        title={
                          proposal.extraConfigs.extra_configs.find(
                            (item) => item.id == container.id,
                          )?.title
                        }
                        colors={colors}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {editMode && editingMore == container.id && (
            <div
              onClick={(e) => {
                if (container.id != 'constructionImagesCard') {
                  e.stopPropagation()
                  e.preventDefault()
                }
              }}
              style={{
                position: 'absolute',
                zIndex: 1000,
                top: '-15px',
                left: '-15px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Paper
                style={{
                  padding: '16px 24px',
                  boxShadow: 'none',
                  cursor: 'auto',
                  maxHeight: '50vh',
                  width: 'auto',
                  border: `1px solid ${palette.grayscale[300]}`,
                  overflowY: 'auto',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography
                    type="link_small"
                    color="grayscale"
                    colorWeight="700"
                  >
                    {`${
                      container.display[1]
                        ? (container.display.split('/')[0] == 'covers' &&
                            `${`${'Capa' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'covers',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'explanations' &&
                            `${`${'Explicações' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] ==
                                    'explanations',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'benefits' &&
                            `${`${'Vantagens' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'benefits',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          (container.display?.split('/')[0] == 'partners' &&
                            `${`${'Parceiros' + ' ('}${
                              allContainers
                                ?.filter(
                                  (item) =>
                                    item.display.split('/')[0] == 'partners',
                                )
                                .findIndex((item) => item.id == container.id) +
                              1
                            }`})`) ||
                          container.display
                        : container.display
                    }`}
                  </Typography>
                  <Spacer size="24" direction="vertical" />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Fechar">
                      <CloseXIcon
                        onClick={() => {
                          editingMore == container.id
                            ? setEditingMore('')
                            : setEditingMore(container.id)
                          setIsViewing('')
                          setIsEditing(false)
                          setEditMode('')
                        }}
                        width="24"
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    width: '40vw',
                    marginTop:
                      container.id == 'scheduleCard' ||
                      container.id == 'constructionImagesCard'
                        ? '-8px'
                        : '16px',
                    zIndex: 600,
                  }}
                >
                  <Spacer size="24" direction="horizontal" />
                  {container.id == 'constructionImagesCard' && (
                    <WorkImagesEdit
                      imagens={workImages}
                      handleSaveWorkImages={handleSaveWorkImages}
                      handleEditImage={handleEditImage}
                      handleRemoveImage={handleRemoveImage}
                      noPadding
                    />
                  )}
                  {container.id == 'scheduleCard' && (
                    <CronogramaEdit
                      qtdSchedule={qtdSchedule}
                      setQtdSchedule={setQtdSchedule}
                      schedules={schedules}
                      setSchedules={setSchedules}
                      handleFinish={handleSaveSchedules}
                      loadingExtras={loadingExtras}
                    />
                  )}
                  {Number.isInteger(container.id) && (
                    <NewContainer
                      title={editingFieldTitle}
                      setTitle={setEditingFieldTitle}
                      content={editingFieldContent}
                      setContent={setEditingFieldContent}
                      handleSave={handleEditContainer}
                      loading={loadingExtras}
                      image={imageEditContainer}
                      setImage={setImageEditContainer}
                      fieldType={fieldType}
                      setFieldType={setFieldType}
                      themeCustomSwitch={themeCustomSwitch}
                      setThemeCustomSwitch={setThemeCustomSwitch}
                      themeType={themeType}
                      setThemeType={setThemeType}
                      selectedTheme={selectedTheme}
                      setSelectedTheme={setSelectedTheme}
                    />
                  )}
                </div>
              </Paper>
            </div>
          )}
        </Grid>
      )}
    </Draggable>
  )
}

export default DigitalProposalContainer
