import React, { useMemo } from 'react'

import { type INotificationDTO } from 'dto/NotificationDTO'
import { useNotificationsContext } from 'context/NotificationsContext'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { EVENT_TYPE } from 'containers/Notifications/constants/events'
import { showNotificationTime } from './helpers/showNotificationTime'

import { useRedirectNotification } from './hooks/useRedirectNotification'
import { useSolLeadsNotificationAction } from './hooks/useRedirectNotification/sol-leads/useSolLeadsNotificationAction'
import { Notification } from '@77sol-ui/organisms'
import { Landmark, Sun, Truck } from 'lucide-react'

interface INotificationItemProps {
  notification: INotificationDTO
  onClickNotification?: () => void
}

const NotificationItem: React.FC<INotificationItemProps> = ({
  notification,
  onClickNotification,
  ...props
}) => {
  const { logEvent } = useAmplitude()
  const { redirectNotification } = useRedirectNotification()

  const { openSolLeadsModalNotification } = useSolLeadsNotificationAction()

  const { handleNotificationReadOne, handleCloseNotifications } =
    useNotificationsContext()

  const handleNotificationClick = (notification: INotificationDTO) => {
    const { id, type } = notification

    handleNotificationReadOne(id)
    handleCloseNotifications()

    logEvent(notificationsTracker.actions.clickedOnANotification, {
      type: EVENT_TYPE[type],
    })

    if (notification.type === 'solleads') {
      openSolLeadsModalNotification()
      return
    }

    redirectNotification(notification)

    onClickNotification?.()
  }

  const Icon: Record<INotificationDTO['type'], React.ReactNode> = {
    'financing-approved': <Landmark size={24} />,
    'financing-customer-request': <Landmark size={24} />,
    'financing-pre-approved': <Landmark size={24} />,
    order: <Truck size={24} />,
    solleads: <Sun size={24} />,
  }

  const identifier = useMemo(() => {
    if (notification.type === 'financing-approved') {
      return notification.params?.financingId
    }
    if (notification.type === 'order') {
      return notification.params?.code
    }
    if (notification.type === 'financing-pre-approved') {
      return notification.params?.simulationId
    }
  }, [notification])

  return (
    <Notification.Item
      data-testid="notification-item"
      {...props}
      onClick={() => {
        handleNotificationClick(notification)
      }}
      highlighted={!notification.read}
    >
      <Notification.Icon>{Icon?.[notification.type]}</Notification.Icon>
      <Notification.Message>
        {identifier && (
          <Notification.Identifier>ID#{identifier}</Notification.Identifier>
        )}
        <Notification.Text>{notification.title}</Notification.Text>
        <Notification.Description>
          {notification.message}
        </Notification.Description>
        <Notification.Small>
          {showNotificationTime(notification?.created_at)}
        </Notification.Small>
      </Notification.Message>
    </Notification.Item>
  )
}

export default NotificationItem
