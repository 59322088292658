import React from 'react'
import { Paper, Typography } from 'components'
import styles from './styles'

function GroupButtonMobile({
  value, setValue, labels, ...props
}) {
  const classes = styles()

  return (
    <div {...props} className={classes.groupButtonMobile}>
      {labels.map((item, i) => (
        <Paper
          style={
            Number(value) === i
              ? {
                padding: '4px',
                width: '100%',
                cursor: 'pointer',
              }
              : {
                padding: '4px',
                width: '100%',
                background: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
              }
          }
          onClick={() => {
            setValue(i)
          }}
        >
          <Typography
            type="text_x_small"
            color="grayscale"
            colorWeight="800"
            style={{ textAlign: 'center' }}
          >
            {item}
          </Typography>
        </Paper>
      ))}
    </div>
  )
}

GroupButtonMobile.propTypes = {}

GroupButtonMobile.defaultProps = {}

export default GroupButtonMobile
