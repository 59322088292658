import { Bell } from 'lucide-react'
import { useNotifyIntegrator } from './hooks/useNotifyIntegrator'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'
import * as S from './styles'

export function ButtonNotifyIntegrator({
  integrator,
}: Pick<IProposalDTO, 'integrator'>) {
  const { handleNotifyIntegrator } = useNotifyIntegrator({
    integrator,
  })

  const { disabled } = useDisableActionButtons()

  return (
    <S.StyleNotifyButton onClick={handleNotifyIntegrator} disabled={disabled}>
      <Bell width={16} />
      <span>Notificar {integrator.name}</span>
    </S.StyleNotifyButton>
  )
}
