import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import { useAdminInfosDisponibilityProducts } from 'domains/admin/infos-disponibility-products/hooks/useAdminInfosDisponibilityProducts'

import { type FieldValues } from 'react-hook-form'

export const SelectPhase = <T extends FieldValues>({
  ...props
}: Omit<ISelectProps<T>, 'options'>) => {
  const { data, isFetching } = useAdminInfosDisponibilityProducts()

  const phasesData = [
    { label: props.placeholder as string, value: props.placeholder as string },
    ...(data?.phases || []),
  ]

  return <Select {...props} options={phasesData} isLoading={isFetching} />
}
