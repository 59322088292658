import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  Spacer,
  Button,
  Input,
  useToast,
  Dropdown,
  DatePicker,
} from '@77sol/core'
import { CloseXIcon, SearchIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import { useStyles } from './styles'
import { useAdminStructureTypesProducts } from 'domains/admin/structure-types-products/hooks/useAdminStructureTypesProducts'
import { ClientPaper } from 'views/DimensionarV2/components/ClientPaper'

function ClienteEdit({
  proposal,
  getClientsFromStepFour,
  getMoreClients,
  hasMoreClients,
  setFilterClientName,
  filterClientName,
  resetFieldSearch,
  clients,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { structureTypesData } = useAdminStructureTypesProducts()

  const [nomeClientEdit, setNomeClientEdit] = useState('')
  const [cepClientEdit, setCepClientEdit] = useState('')
  const [enderecoClientEdit, setEnderecoClientEdit] = useState('')
  const [numeroClientEdit, setNumeroClientEdit] = useState('')
  const [complementoClientEdit, setComplementoClientEdit] = useState('')
  const [bairroClientEdit, setBairroClientEdit] = useState('')
  const [cidadeClientEdit, setCidadeClientEdit] = useState('')
  const [estadoClientEdit, setEstadoClientEdit] = useState('')
  const [emailClientEdit, setEmailClientEdit] = useState('')
  const [telefoneClientEdit, setTelefoneClientEdit] = useState('')
  const [cpfClientEdit, setCpfClientEdit] = useState('')
  const [nascimentoClientEdit, setNascimentoClientEdit] = useState('')
  const [valorConsumoEdit, setValorConsumoEdit] = useState('')
  const [tipoEstruturaEdit, setTipoEstruturaEdit] = useState('')
  const [loadingCep, setLoadingCep] = useState(false)

  const [selectedClient, setSelectedClient] = useState(null)

  const handleEditClientValues = () => {
    API.patch(`/clientes/update/${selectedClient.id}`, {
      nome: nomeClientEdit,
      email: emailClientEdit,
      telefone: telefoneClientEdit,
      cpf: cpfClientEdit,
      nascimento: nascimentoClientEdit,
      cep: cepClientEdit,
      cidade: cidadeClientEdit,
      bairro: bairroClientEdit,
      uf: estadoClientEdit,
      endereco: enderecoClientEdit,
      numero: numeroClientEdit,
      complemento: complementoClientEdit,
      energy_bill_value: valorConsumoEdit,
      structure: tipoEstruturaEdit,
      status: '0',
      tipo: '1',
    }).then(() => {
      getClientsFromStepFour()
    })
  }

  const handleCancelClient = () => {
    setSelectedClient(null)
    setNomeClientEdit('')
    setCepClientEdit('')
    setEnderecoClientEdit('')
    setNumeroClientEdit('')
    setComplementoClientEdit('')
    setBairroClientEdit('')
    setCidadeClientEdit('')
    setEstadoClientEdit('')
    setEmailClientEdit('')
    setTelefoneClientEdit('')
    setCpfClientEdit('')
    setNascimentoClientEdit('')
  }

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const handleSetEditValues = (newValue) => {
    setSelectedClient(newValue || null)
    setNomeClientEdit(newValue?.label || newValue?.nome || '')
    setCepClientEdit(newValue?.cep || '')
    setEnderecoClientEdit(newValue?.endereco || '')
    setNumeroClientEdit(newValue?.numero || '')
    setComplementoClientEdit(newValue?.complemento || '')
    setBairroClientEdit(newValue?.bairro || '')
    setCidadeClientEdit(newValue?.cidade || '')
    setEstadoClientEdit(newValue?.uf || '')
    setEmailClientEdit(newValue?.email || '')
    setTelefoneClientEdit(newValue?.telefone || '')
    setCpfClientEdit(newValue?.cpf || '')
    setNascimentoClientEdit(newValue?.nascimento || '')
    setValorConsumoEdit(newValue?.energy_bill_value || '')
    setTipoEstruturaEdit(newValue?.structure || '')
  }

  const getEndereco = () => {
    setLoadingCep(true)

    API.get(`/showcep?cep=${cepClientEdit}`).then((res) => {
      setLoadingCep(false)
      setCidadeClientEdit(res.data.cidade)
      setEstadoClientEdit(res.data.uf)
      setEnderecoClientEdit(res.data.logradouro)
      setBairroClientEdit(res.data.bairro)
    })
  }

  const handleChangeClient = (client) => {
    API.post('/proposal/change/customer', {
      customer_id: client.id,
      proposal_id: proposal.proposal.id,
    })
      .then((res) => {
        dispatch(ProposalAction.SaveProposalRedux(res.data))
        onSuccessOpenToast('Cliente alterado com sucesso!')
        handleSetEditValues(client)
        setSelectedClient(client)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o cliente!')
      })
  }

  useEffect(() => {
    if (clients && clients.length) {
      if (proposal && proposal.proposal.customer_id) {
        handleSetEditValues(
          clients.find((client) => client.id == proposal.proposal.customer_id),
        )
      }
    }
  }, [])

  return (
    <Grid
      style={{ margin: '0px', width: '100%', padding: '16px' }}
      spacing={2}
      container
    >
      <Grid item xs={12} md={12} lg={12} style={{ zIndex: 1000 }}>
        <div style={{ display: 'flex', width: '100%' }}>
          {selectedClient ? (
            <Button
              style={{ width: '-webkit-fill-available' }}
              size="medium"
              noMargin
            >
              {nomeClientEdit}
            </Button>
          ) : (
            <Input
              value={filterClientName}
              onBlur={() => getClientsFromStepFour()}
              setValue={setFilterClientName}
              placeholder="Pesquise pelo nome do cliente"
              adornmentPosition="right"
              adornment={
                !filterClientName ? (
                  <SearchIcon width="18" color={palette.grayscale[500]} />
                ) : (
                  <CloseXIcon
                    width="18"
                    color={palette.grayscale[500]}
                    onClick={() => resetFieldSearch(true)}
                  />
                )
              }
            />
          )}
          {selectedClient && (
            <>
              <Spacer size="16" direction="vertical" />
              <Button
                onClick={() => handleCancelClient()}
                size="small"
                variant="outlined"
                noMargin
              >
                Alterar
              </Button>
            </>
          )}
        </div>
        <Spacer size="16" direction="horizontal" />
      </Grid>
      {selectedClient && (
        <>
          <Grid className={classes.container} item xs={12}>
            <Input
              title="Nome completo / Razão social*"
              value={nomeClientEdit}
              onChange={setNomeClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              mask="cep"
              title="CEP"
              loading={loadingCep}
              value={cepClientEdit}
              onChange={setCepClientEdit}
              onBlur={() => {
                handleEditClientValues()
                getEndereco()
              }}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              title="Endereço"
              value={enderecoClientEdit}
              onChange={setEnderecoClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={6}>
            <Input
              type="number"
              title="Numero"
              value={numeroClientEdit}
              onChange={setNumeroClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={6}>
            <Input
              title="Complemento"
              value={complementoClientEdit}
              onChange={setComplementoClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={8}>
            <Input
              title="Bairro"
              value={bairroClientEdit}
              onChange={setBairroClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={4}>
            <Input
              title="Cidade"
              value={cidadeClientEdit}
              onChange={setCidadeClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              title="Estado"
              value={estadoClientEdit}
              onChange={setEstadoClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              title="E-mail"
              value={emailClientEdit}
              onChange={setEmailClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              mask="phoneNumber"
              title="Telefone"
              value={telefoneClientEdit}
              onChange={setTelefoneClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              mask="cpfCnpj"
              title="CPF / CNPJ"
              value={cpfClientEdit}
              onChange={setCpfClientEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <DatePicker
              title="Data de nascimento / Fundação"
              value={nascimentoClientEdit}
              dateChange={setNascimentoClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Input
              mask="real"
              title="Valor de consumo médio"
              value={valorConsumoEdit}
              onChange={setValorConsumoEdit}
              onBlur={handleEditClientValues}
            />
          </Grid>
          <Grid className={classes.container} item xs={12}>
            <Dropdown
              fullWidth
              title="Tipo de estrutura"
              value={tipoEstruturaEdit}
              onChange={setTipoEstruturaEdit}
              options={structureTypesData}
              onBlur={handleEditClientValues}
            />
          </Grid>
        </>
      )}
      {!selectedClient && (
        <>
          <InfiniteScroll
            dataLength={clients.length}
            next={getMoreClients}
            hasMore={hasMoreClients}
            height="calc(100vh - 296px)"
          >
            <Grid
              style={{ width: '100%', margin: '0px' }}
              container
              spacing={2}
            >
              {clients?.map((client, index) => (
                <Grid key={index} item xs={12}>
                  <ClientPaper
                    id={`clientsSelect_button_${index}`}
                    clientData={client}
                    onClick={() => handleChangeClient(client)}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </>
      )}
    </Grid>
  )
}

export default ClienteEdit
