export const optionsDifferentialList = [
    {
      text: 'Dimensionamento Simplificado',
      free: true,
      pro: true,
    },
    {
      text: 'Customização de Propostas Comerciais',
      free: true,
      pro: true,
    },
    {
      text: 'Cotação de Equipamentos',
      free: true,
      pro: true,
    },
    {
      text: 'Financiamentos',
      free: true,
      pro: true,
    },
    {
      text: `Dimensionamento Completo`,
      free: false,
      pro: true,
    },
    {
      text: 'Salvamento de Propostas Customizadas',
      free: false,
      pro: true,
    },
    {
      text: 'Cotações Variadas na mesma Proposta',
      free: false,
      pro: true,
    },
    {
      text: 'Estoque Próprio',
      free: false,
      pro: true,
    },
    {
      text: 'Fura Fila na Análise de Financiamentos',
      free: false,
      pro: true,
    },
    {
      text: 'Usuários Ilimitados',
      free: false,
      pro: true,
    },
  ]