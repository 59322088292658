import { parseBRL } from 'utils'
import { format } from 'date-fns'
import { Table } from '@77sol-ui/molecules'
import { Button, Radio } from '@77sol-ui/atoms'
import { tableHeader } from '../../constants/tableHeader'
import { templateColumn } from '../../constants/templateColumn'
import { type ILeadModal } from 'domains/solleads/get-leads-modal/services/types'
import { useLeadsModal } from 'domains/solleads/get-leads-modal/hooks/useLeadsModal'
import { type FormEvent } from 'react'
import { CircularProgress } from 'components'
import * as S from './styles'
import { EmptyState } from './EmptyState'

interface ILeadsTableProps {
  selected?: ILeadModal
  onReject: (leads?: number[]) => void
  onSelect: (lead: ILeadModal) => void
}

export function LeadsTable({ selected, onSelect, onReject }: ILeadsTableProps) {
  const { data: leadsModalData, isLoading } = useLeadsModal()

  const selectedLeadId = selected?.distribution_lead_id

  function handleRejectRow(e: FormEvent, leadId: number) {
    e.stopPropagation()

    onReject([leadId])
  }

  if (isLoading)
    return (
      <S.Container>
        <CircularProgress />
      </S.Container>
    )

  if (leadsModalData.length === 0) return <EmptyState />

  return (
    <Radio.Root>
      <Radio.Group iconType="success">
        <Table.Root variant="default">
          <Table.Header headerTemplateColumns={templateColumn}>
            <Table.Head />
            <Table.Head>{tableHeader[1]}</Table.Head>
            <Table.Head>{tableHeader[2]}</Table.Head>
            <Table.Head>{tableHeader[3]}</Table.Head>
            <Table.Head>{tableHeader[4]}</Table.Head>
            <Table.Head />
          </Table.Header>
          <Table.Body maxHeight="250px">
            {leadsModalData?.map((lead: ILeadModal) => (
              <Table.Row
                key={lead.distribution_lead_id}
                rowType="table"
                rowTemplateColumns={templateColumn}
                selected={lead.distribution_lead_id === selectedLeadId}
                onClick={() => {
                  onSelect(lead)
                }}
              >
                <Table.Cell>
                  <Radio.Item
                    name="leads"
                    value={lead.nome}
                    checked={lead.distribution_lead_id === selectedLeadId}
                  />
                </Table.Cell>
                <Table.Cell mobileHead={tableHeader[1]}>
                  {lead.nome || '-'}
                </Table.Cell>
                <Table.Cell mobileHead={tableHeader[2]}>
                  {lead.cidade || '-'}
                </Table.Cell>
                <Table.Cell mobileHead={tableHeader[3]}>
                  {parseBRL(lead.consumo_medio)}
                </Table.Cell>
                <Table.Cell mobileHead={tableHeader[4]}>
                  {format(new Date(lead.created_at), 'dd/MM/yyyy')}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    variant="text"
                    onClick={(event) => {
                      handleRejectRow(event, lead.distribution_lead_id)
                    }}
                  >
                    Rejeitar
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Radio.Group>
    </Radio.Root>
  )
}
