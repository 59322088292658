import React, { useState, useEffect } from 'react'
import { Button, Box, Hidden } from '@material-ui/core'
import { Spacer, Typography, Title, Badge } from '@77sol/core'
import palette from 'app_palette'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { CloseXIcon, CheckIcon } from '@77sol/icons/dist'
import styles from '../styles'
import { optionsDifferentialList } from 'utils/optionsDifferentialList'

function DifferentialList({ valueMonthlyPayment, disabledSubtitle }) {
  
  const classes = styles()

  return (
    <>
      <Box
        className={classes.tableContent}
        style={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Hidden lgUp>
          <>
            <Typography
              type="link"
              size="medium"
              color="primary"
              colorWeight="300"
            >
              Plano Pro
            </Typography>
            <Spacer size="8" direction="both" />
            <hr
              style={{
                width: '100%',
                height: '2px',
                borderColor: palette.primary[300],
                backgroundColor: palette.primary[300],
              }}
            />
            <Spacer size="8" direction="both" />
            {optionsDifferentialList.map(
              ({ text }) => (
                <Box display="flex" paddingTop={1} key={text}>
                  <CheckIcon width="16px" color={palette.primary[300]} />
                  <Spacer size="8" direction="both" />
                  <Typography size="xsmall" color="primary" colorWeight="300">
                    {text}
                  </Typography>
                </Box>
              ),
            )}
          </>
        </Hidden>
        <Hidden mdDown>
          <Box style={{ paddingBottom: '16px' }} padding={5}>
            <Title size="xsmall" weight="xbold" color="primary" colorWeight="300" style={{ fontSize: '22px' }}>
              Funcionalidade exclusiva para usuários PRO:
            </Title>
            {!disabledSubtitle && (
              <Typography size="small" colorWeight="600">
                Funções ainda mais inteligentes que profissionalizam o seu negócio e te garante uma conversão cada vez maior!
              </Typography>
            )}
            <Spacer size="32" direction="horizontal" />
            <table style={{ margin: 'auto', width: '100%' }}>
              <tbody>
                <tr style={{ textAlign: 'center', paddingTop: '8px' }}>
                  <th />
                  <th style={{ minWidth: '150px' }}>
                    <Box display="flex" justifyContent="center">
                      <Title size="xsmall" weight="xbold">
                        77
                      </Title>
                    </Box>
                  </th>
                  <th>
                    <Box display="flex" justifyContent="center">
                      <Badge
                        label='77PRO'
                        color='primary'
                      />
                    </Box>
                  </th>
                </tr>
                <br />
                {optionsDifferentialList.map(({ text, free, pro }) => (
                  <tr style={{ paddingBottom: '8px' }} key={text}>
                    <td>
                      <Typography colorWeight="600">{text}</Typography>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {free ? (
                        <CheckIcon width="24px" color={palette.primary[300]} />
                      ) : (
                        <CloseXIcon
                          width="24px"
                          color={palette.grayscale[300]}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {pro ? (
                        <CheckIcon width="24px" color={palette.primary[300]} />
                      ) : (
                        <CloseXIcon
                          width="24px"
                          color={palette.grayscale[300]}
                        />
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td>
                    <Box display="flex" justifyContent="center">
                      <Typography
                        type="link"
                        size="large"
                        colorWeight="600"
                      >
                        R$ 0,00
                      </Typography>
                    </Box>
                  </td>
                  <td>
                    <Box
                      display="flex"
                      alignItems="baseline"
                      justifyContent="center"
                    >
                      <Typography
                        type="link"
                        size="large"
                        colorWeight="600"
                      >
                        {parseFloat(valueMonthlyPayment).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </Typography>
                      <Typography size="xsmall" colorWeight="600">
                        /mês
                      </Typography>
                    </Box>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Hidden>
      </Box>
    </>
  )
}

export default DifferentialList
