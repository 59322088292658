import { useQuery } from '@tanstack/react-query'
import { FINANCING_STATUS } from '../constants/endpointKeys'
import { type IGetFinancingStatusResponse } from '../services/types'
import { getFinancingStatus } from '../services/api'

interface IUseFinancingByValueProps {
  onSuccess?: (data: IGetFinancingStatusResponse[]) => void
  enabled?: boolean
  onError?: (data: unknown) => void
}

export function useFinancingStatus({
  onSuccess,
  enabled = true,
  onError,
}: IUseFinancingByValueProps) {
  return useQuery({
    queryKey: [FINANCING_STATUS],
    queryFn: async () => {
      return await getFinancingStatus()
        .then((response) => {
          const { data } = response
          onSuccess?.(data)
          return data
        })
        .catch((error: unknown) => {
          onError?.(error)
          throw new Error(error as any)
        })
    },
    enabled,
  })
}
