const handlePotency = products => {
  const module = products?.find(x => Number(x.categoria) === 62)
  if (module) {
    const potency = parseFloat(module.potenciaModulo) * parseFloat(module.qtde)

    return parseFloat((potency / 1000).toFixed(2))
  }

  return '-'
}

export default handlePotency
