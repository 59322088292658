export enum ORDER_STATUS_CLIENT_ENUM {
  PEDIDO_VENCIDO = 1,
  RESERVADO = 2,
  EM_ANALISE = 3,
  PAGO = 4,
  EM_PREPARACAO = 5,
  EM_TRANSPORTE = 10,
  PEDIDO_PERTO_DE_VOCE = 1,
  ENTREGUE = 12,
  CANCELADO = 13,
}

export const ORDER_CLOSED_CLIENT_STATUS = [
  ORDER_STATUS_CLIENT_ENUM.PEDIDO_VENCIDO,
  ORDER_STATUS_CLIENT_ENUM.CANCELADO,
]
