import { useToast } from '@77sol/core'
import API from 'api'
import copy from 'copy-to-clipboard'
import { type IOrderDTO } from 'dto/OrderDTO'
import { type ISendWhatsAppForm } from 'components/SendModal/components/SendWhatsAppForm/validators'
import { useWhatsappSend } from 'hooks/useWhatsappSend'

interface UseOrderTrackingModalProps {
  order: IOrderDTO
}

interface HandleSubmitEmailProps {
  email?: string
}

export function useOrderTrackingModal({ order }: UseOrderTrackingModalProps) {
  const { handleWhatsAppSend } = useWhatsappSend()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const currentHost = location.host

  const trackingOrderLink = `${currentHost}/visualizar/pedido/${order.codigo}`

  async function handleEmailSubmit({ email }: HandleSubmitEmailProps) {
    try {
      await API.post(`/loja/send/order-tracking/email/${order.id}`, {
        email,
      })
      onSuccessOpenToast(
        'O link de rastreio foi enviado pelo email com sucesso',
      )
    } catch (error) {
      onErrorOpenToast('Ocorreu um erro ao enviar o link de rastreio!')
    }
  }

  async function handleCopyTrackingOrderLink() {
    try {
      copy(trackingOrderLink)
      onSuccessOpenToast('Link de rastreio copiado com sucesso!')
    } catch (error) {
      onErrorOpenToast('Houve um erro ao copiar o link de rastreio!')
    }
  }

  const handleSendOnWhatsapp = ({ celular }: ISendWhatsAppForm) => {
    const firstLine = `Olá ${order.customer_name}, aqui é ${order.integrator_company}! Clique no link abaixo para acompanhar o rastreio do seu projeto solar:`
    const endLine = `Qualquer dúvida é só e chamar!`
    const completeMessage = `${firstLine}\n${trackingOrderLink}\n${endLine}`
    handleWhatsAppSend(celular, completeMessage)
  }

  const handleOpenTrackingOrderInNewTab = () =>
    window.open(`visualizar/pedido/${order.codigo}`, '_blank')

  return {
    handleEmailSubmit,
    handleCopyTrackingOrderLink,
    handleSendOnWhatsapp,
    handleOpenTrackingOrderInNewTab,
  }
}
