import styled from 'styled-components'
import { colors } from '@77sol-ui/tokens'
import { Table } from '@77sol-ui/molecules'

export const TableRow = styled(Table.Row)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  * {
    font-family: DM Sans, sans-serif;
    font-weight: 400;
  }

  @media (max-width: 1023px) {
    border: none;
  }
`

export const Image = styled.img`
  max-height: 68px;
  max-width: 68px;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const Description = styled.div`
  display: grid;
  grid-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: auto;

  .product {
    @media (min-width: 1024px) {
      grid-column: span 3;
    }
  }

  .available {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.gray[400]};

    &[data-available='Disponível em estoque'] {
      color: ${colors.green[300]};
    }
  }
`
