import React, { useState } from 'react'
import { Grid, Typography, Button, Paper } from '@material-ui/core'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useDispatch, useSelector } from 'react-redux'
import * as ProposalAction from 'store/actions/index'
import { useStyles } from './styles'

const ModalCheckbox = withStyles({
  root: {
    borderRadius: '8px',
    color: '#707072',
    '&$checked': {
      color: '#FFF',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

function ModalSelecionarCotacoes({
  modalSelecionarCotacoes,
  setModalSelecionarCotacoes,
  quotes,
  handlePotency,
  handleOverload,
  setActiveDimStep,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proposal = useSelector((state) => state.Reducer1)

  const [selectedQuotes, setSelectedQuotes] = useState([])

  const handleFinishQuote = () => {
    const step = parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, ''))
    if (step == 2) {
      dispatch(ProposalAction.SaveStepRedux('3'))
    }
    if (step == 3 || step == 4) {
      setActiveDimStep(3)
    }
    setModalSelecionarCotacoes(false)
    setSelectedQuotes('')
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={modalSelecionarCotacoes}
      onClose={() => {
        setModalSelecionarCotacoes(false)
        setSelectedQuotes('')
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalSelecionarCotacoes} style={{ border: 'none' }}>
        <div className={classes.paperModalCards}>
          <Grid
            style={{
              height: '-webkit-fill-available',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={3}
            container
          >
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={12}
            >
              <AssignmentRoundedIcon
                style={{ fontSize: '100px', color: '#1335c6' }}
              />
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '20px 0px',
              }}
              item
              xs={12}
              md={12}
            >
              <Typography
                style={{
                  fontSize: '26px',
                  color: '#464F5B',
                  marginBottom: '40px',
                }}
              >
                Selecione a cotação que deseja inserir na proposta
              </Typography>
              <Grid
                style={{ overflowY: 'auto', maxHeight: '40vh' }}
                justify="center"
                container
                spacing={2}
              >
                {quotes.map((quote, i) => (
                  <Grid key={i} item xs={6}>
                    <Paper
                      onClick={() => {
                        if (selectedQuotes.indexOf(quote.quotation.id) >= 0) {
                          selectedQuotes.splice(
                            selectedQuotes.indexOf(quote.quotation.id),
                            1,
                          )
                          setSelectedQuotes([...selectedQuotes])
                        } else {
                          const newArray = [
                            ...selectedQuotes,
                            quote.quotation.id,
                          ]
                          setSelectedQuotes([...newArray])
                        }
                      }}
                      className={
                        selectedQuotes.indexOf(quote.quotation.id) >= 0
                          ? classes.selectedPaper
                          : classes.notSelectedPaper
                      }
                    >
                      <Grid container>
                        <Grid
                          style={{ display: 'flex', alignItems: 'center' }}
                          item
                          md={2}
                        >
                          <ModalCheckbox
                            value="b"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'B' }}
                            checked={
                              selectedQuotes.indexOf(quote.quotation.id) >= 0
                            }
                          />
                        </Grid>
                        <Grid item md={10}>
                          <Typography
                            className={
                              selectedQuotes.indexOf(quote.quotation.id) >= 0
                                ? classes.titleSelected
                                : classes.titleNotSelected
                            }
                          >
                            <b>
                              Cotação
                              {i + 1}
                            </b>
                          </Typography>
                          <Typography
                            className={
                              selectedQuotes.indexOf(quote.quotation.id) >= 0
                                ? classes.subtitleSelected
                                : classes.subtitleNotSelected
                            }
                          >
                            Potência do kit:{' '}
                            <b>
                              {handlePotency(quote.kit)}
                              kWp
                            </b>
                          </Typography>
                          <Typography
                            className={
                              selectedQuotes.indexOf(quote.quotation.id) >= 0
                                ? classes.subtitleSelected
                                : classes.subtitleNotSelected
                            }
                          >
                            Sobrecarga: <b>{handleOverload(quote.kit)}%</b>
                          </Typography>
                          <Typography
                            className={
                              selectedQuotes.indexOf(quote.quotation.id) >= 0
                                ? classes.subtitleSelected
                                : classes.subtitleNotSelected
                            }
                          >
                            Valor total:{' '}
                            <b>
                              {quote.quotation.total_value.toLocaleString(
                                'pt-br',
                                { style: 'currency', currency: 'BRL' },
                              )}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={12}
            >
              <Button
                disabled={selectedQuotes.length === 0}
                className={classes.filledButton}
                onClick={handleFinishQuote}
                style={{ marginRight: '16px' }}
              >
                Criar proposta
              </Button>
              <Button
                onClick={() => setModalSelecionarCotacoes(false)}
                className={classes.outlinedButton}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalSelecionarCotacoes
