import { useCallback, useEffect, useState } from 'react'

export interface IUseGetMaxNumberOfProps {
  financingsQty?: number
  proposalsQty?: number
  defaultValue?: number
  active: boolean
}

export default function useGetMaxNumberOfRows({
  financingsQty = 0,
  proposalsQty = 0,
  defaultValue = 0,
  active,
}: IUseGetMaxNumberOfProps) {
  const [numberOfRows, setNumberOfRows] = useState(defaultValue)

  const getMaxValue = useCallback(() => {
    if (financingsQty <= 0 && proposalsQty <= 0) {
      setNumberOfRows(defaultValue)
      return
    }

    const max = Math.max(financingsQty, proposalsQty)
    setNumberOfRows(max)
  }, [defaultValue, financingsQty, proposalsQty])

  useEffect(() => {
    if (active) {
      getMaxValue()
    }
  }, [active, getMaxValue])

  return { numberOfRows }
}
