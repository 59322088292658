import { Header, type IHeaderRootProps } from '@77sol-ui/organisms'

import { useHistory } from 'react-router-dom'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { HamburgerMenu } from '../components/HamburgerMenu'
import { useNotificationsContext } from 'context/NotificationsContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { sidebarTracker } from 'services/tracker/events/sidebar/tracker'
import { CreateButton } from './components/CreateButton'
import logo from 'assets/img/logo-77sol-new.svg'

export function Mobile({ ...props }: Omit<IHeaderRootProps, 'children'>) {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const { unreadNotifications } = useNotificationsContext()
  const setOpenSidebar = useSidebarMobileStore((state) => state.setIsOpen)

  function handleOpenSidebar() {
    logEvent(sidebarTracker.actions.expandSidebar, {
      origin: 'header',
      type: 'Button',
    })
    setOpenSidebar(true)
  }

  return (
    <Header.Root {...props}>
      <Header.Content>
        <HamburgerMenu
          marker={unreadNotifications}
          onClick={handleOpenSidebar}
        />
        <Header.Logo
          data-testid="header-logo"
          onClick={() => history.push('/')}
          src={logo}
        />
      </Header.Content>

      <Header.Content data-testid="header-content">
        <CreateButton />
      </Header.Content>
    </Header.Root>
  )
}
