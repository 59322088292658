import { UserRole } from 'acl/config/types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { type IReduxStore } from 'types/redux'

export function useSidebarMobileProfile() {
  const { reset } = useSidebarMobileStore()
  const history = useHistory()
  const userLogged = useSelector(
    (state: IReduxStore) => state.ReducerAuth.userLogged,
  )

  const { logo, userProfile } = useSelector(
    (state: IReduxStore) => state.ReducerProfile,
  )

  const isAdmin =
    userProfile.role_id === UserRole.ADMIN ||
    userProfile.role_id === UserRole.SUPER_ADMIN

  function handleRedirectToAccount() {
    if (!isAdmin) return

    history.push('/configuracoes/empresa')
    reset()
  }

  return {
    userLogged,
    logo,
    handleRedirectToAccount,
  }
}
