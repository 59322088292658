import { useQuery } from '@tanstack/react-query'
import { financing } from '../services/api'
import {
  GET_FINANCING_INSURANCE_PERCENTAGE,
  GET_FINANCING_PER_QUOTATION_SIMULATIONS,
  GET_FINANCING_PER_VALUE_SIMULATIONS,
} from '../constants/endpointKeys'

export const useFetchFinancingPerValueSimulations = (
  financingId: string,
  isFinancingPerValue: boolean,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_FINANCING_PER_VALUE_SIMULATIONS, financingId],
    queryFn: async ({ signal }) =>
      await financing.getFinancingPerValueSimulations({ financingId, signal }),
    enabled: isFinancingPerValue && Boolean(financingId),
  })

  return {
    simulations: data,
    ...rest,
  }
}

export const useFetchFinancingPerQuotationSimulations = (
  quotationId: string,
  isFinancingPerValue: boolean,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_FINANCING_PER_QUOTATION_SIMULATIONS, quotationId],
    queryFn: async ({ signal }) => {
      return await financing.getFinancingPerQuotationSimulations({
        quotationId,
        signal,
      })
    },
    enabled: !isFinancingPerValue && Boolean(quotationId),
  })

  return {
    simulations: data,
    ...rest,
  }
}

export const useFetchFinancingInsurancePercentage = () => {
  const { isFetching, data, refetch } = useQuery({
    queryKey: [GET_FINANCING_INSURANCE_PERCENTAGE],
    queryFn: async ({ signal }) => {
      return financing.getFinancingInsurancePercentage(signal)
    },
    enabled: false,
  })

  return {
    percentage: isFetching ? 0.08 : Number(data?.insurance_fee) / 100,
    getInsurancePercentage: refetch,
  }
}
