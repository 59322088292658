import { useQuery } from '@tanstack/react-query'
import { GET_ORDERS_STATUS } from '../constants/endpointKeys'
import { ordersService } from '../services/api'

export const useFetchOrdersStatus = () => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_ORDERS_STATUS],
    queryFn: async () => await ordersService.getOrderStatus(),
  })

  const orderStatus = data?.external.map((status) => ({
    value: String(status.id),
    label: status.description,
  }))

  return {
    orderStatus,
    ...rest,
  }
}
