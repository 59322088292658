import { useToast } from '@77sol/core'
import { useGeneratePdfQuotation } from 'domains/quotation/admin-pdf-generate-quotation/hooks/useGeneratePdfQuotation'
import { saveFile } from 'utils/saveFile'

export function useDownloadQuotationPdf() {
  const { onErrorOpenToast } = useToast()

  function generateQuotationPdfName(quotationId: number) {
    if (!quotationId) return ''

    return `Cotação - #${quotationId}`
  }

  async function onGeneratePdfQuotation(quotationId: number) {
    if (!quotationId) return

    const data = await onGeneratePdfQuotationMutate(quotationId)
    const file = new Blob([data], { type: 'application/pdf' })
    saveFile(file, generateQuotationPdfName(quotationId))
  }

  const { mutateAsync: onGeneratePdfQuotationMutate, isLoading } =
    useGeneratePdfQuotation({
      onError: (e) => {
        console.error(e)
        onErrorOpenToast('Erro ao fazer o download da cotação')
      },
    })

  return { onGeneratePdfQuotation, isLoading, generateQuotationPdfName }
}
