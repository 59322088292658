import { useEffect, useState } from 'react'
import API from 'api'
import clsx from 'clsx'
import jsPDF from 'jspdf'
import moment from 'moment'
import html2canvas from 'html2canvas'
import AppConfig from 'globalConfigs'
import 'moment/locale/pt-br'
import { palette } from '@77sol/styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Box,
} from '@material-ui/core'
import * as S from './styles'
import { CircularProgress } from 'components/CircularProgress'
import { createPortal } from 'react-dom'

moment.locale('pt-br')

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f5f5f5',
    color: 'black',
    borderBottom: '1px solid #f5f5f5',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #eeeeee',
  },
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  root: {
    left: '-200%',
    width: '1440px',
    position: 'absolute',
  },
  card: {
    margin: '0 32px',
    backgroundColor: palette.white,
  },
  content: {
    padding: '32px 0',
    backgroundColor: palette.white,
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100],
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
}))

const formatReal = (value = 0) => {
  return parseFloat(value || 0).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
}

const formatDate = (value = '') => {
  return moment(value).isValid() ? moment(value).format('L') : ''
}

function OrderInvoice({ stateOrder = [] }) {
  const classes = useStyles()

  const [order, setOrder] = stateOrder
  const [infosPedidos, setInfosPedido] = useState([])
  const [loading, setLoading] = useState(false)

  const { logoPDF, corporateName } = AppConfig.appinfo

  const [
    pedido = {},
    itens = [],
    valoresAdicionais = [],
    dadosFaturamento = {},
  ] = infosPedidos

  useEffect(() => {
    if (order) {
      setLoading(true)
      API.get(`/loja/pedidos/${order}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.getItem('sessionToken'),
        },
      }).then(({ data = {} }) => {
        setInfosPedido([
          data.pedido[0],
          data.pedido[1],
          data.pedido[2],
          data.pedido[3][0],
        ])
      })
    }
  }, [order])

  useEffect(() => {
    if (pedido.codigo) {
      html2canvas(document.querySelector('.exportFaturaAdmin'))
        .then((canvas) => {
          const imgWidth = 210
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          const imgData = canvas.toDataURL('image/png')

          const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            compress: true,
          })

          pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight)
          pdf.save(
            `${pedido.codigo}_Fatura_${pedido.nome_integrador
              .split(' ')
              .join('_')}${moment(new Date()).format('L')}.pdf`,
          )
        })
        .finally(() => {
          setLoading(false)
          setOrder()
        })
    }
  }, [infosPedidos])

  useEffect(() => {
    const headBar = document.getElementById('headerBar')
    if (!headBar) return

    headBar.style.zIndex = 'unset'

    return () => {
      headBar.style.zIndex = '1'
    }
  }, [])

  return (
    <>
      {loading &&
        createPortal(
          <>
            <S.Backdrop />
            <S.LoadingContainer>
              <CircularProgress />
              <span>Carregando PDF...</span>
            </S.LoadingContainer>
          </>,
          document.body,
        )}

      <S.PDFContainer>
        <div className={classes.root}>
          <div className="exportFaturaAdmin">
            <Card className={classes.card}>
              <CardContent className={classes.content}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      height={100}
                      component="img"
                      alt="Logo empresa"
                      src={logoPDF}
                    />
                  </Grid>
                  <Grid item>
                    <Typography align="right" component="h4" variant="h4">
                      Pedido
                      <b>{`#${pedido.codigo}`}</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  className={clsx(classes.marginTop, classes.dates)}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography component="h4" gutterBottom variant="overline">
                      Data do pedido
                    </Typography>
                    <Typography>{formatDate(pedido.data)}</Typography>
                  </Grid>
                  <Grid item align="right">
                    {pedido.nome_status !== 'Orçamento' &&
                      formatDate(pedido.data_faturamento) && (
                        <>
                          <Typography
                            component="h4"
                            gutterBottom
                            variant="overline"
                          >
                            Data de Pagamento
                          </Typography>
                          <Typography>
                            {formatDate(pedido.data_faturamento)}
                          </Typography>
                        </>
                      )}
                  </Grid>
                </Grid>
                <Grid
                  className={clsx(classes.marginTop, classes.dates)}
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <Typography component="h4" gutterBottom variant="overline">
                      Frete
                    </Typography>
                    <Typography variant="body1">
                      {pedido.free_shipping
                        ? `Pedido sem frete. Os itens adquiridos devem ser retirados junto ao distribuidor, a partir da confirmação de pagamento (mediante a disponibilidade dos equipamentos comprados).`
                        : `O tempo médio para a entrega nessa região são de ${pedido?.medium_delivery} dias úteis. O prazo de entrega é de ${pedido.delivery_days} dias úteis a partir da confirmação de pagamento, mediante a disponibilidade de equipamento. Frete incluso.`}
                    </Typography>
                    <Typography variant="body1">
                      A responsabilidade pela conferência e descarga dos
                      equipamentos, bem como da contratação de pessoas e/ou
                      equipamentos é única e exclusiva do integrador.
                    </Typography>
                  </Grid>
                </Grid>
                {pedido?.descricao && (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Descrição
                    </Typography>
                    <Typography>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: pedido.descricao }}
                      />
                    </Typography>
                  </div>
                )}
                {pedido?.endereco && (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Endereço de entrega
                    </Typography>
                    <Typography>
                      {`${pedido.endereco}, ${pedido.numero} ${
                        pedido.complemento ? ` - ${pedido.complemento}` : ''
                      }`}
                      <br />
                      {`${pedido.bairro} - ${pedido.cidade}`}
                      <br />
                      {`${pedido.estado} - ${pedido.cep}`}
                    </Typography>
                  </div>
                )}
                {dadosFaturamento?.endereco && (
                  <div className={classes.marginTop}>
                    <Typography component="h4" gutterBottom variant="overline">
                      Faturado para
                    </Typography>
                    <Typography>
                      {`${dadosFaturamento.nome}`}
                      <br />
                      {`${dadosFaturamento.doc}`}
                      <br />
                      {`${dadosFaturamento.endereco}, ${dadosFaturamento.numero}`}
                      {dadosFaturamento.complemento
                        ? ` - ${dadosFaturamento.complemento}`
                        : ''}
                      <br />
                      {`${dadosFaturamento.bairro} - ${dadosFaturamento.cidade}`}
                      <br />
                      {`${dadosFaturamento.estado} - ${dadosFaturamento.cep}`}
                    </Typography>
                  </div>
                )}
                <Table className={classes.marginTop}>
                  <TableHead
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                  >
                    <StyledTableRow>
                      <StyledTableCell>Itens</StyledTableCell>
                      <StyledTableCell />
                      <StyledTableCell style={{ textAlign: 'right' }}>
                        <b>Quantidade</b>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {itens.map((item) => (
                      <StyledTableRow
                        key={item.id}
                        style={{ borderBottom: 'none' }}
                      >
                        <StyledTableCell
                          style={{ border: 'none', padding: '8px' }}
                        >
                          {item.nome}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ border: 'none', padding: '8px' }}
                        />
                        <StyledTableCell
                          style={{
                            textAlign: 'right',
                            border: 'none',
                            padding: '8px',
                          }}
                        >
                          <strong
                            style={{ color: '#1335C6', fontSize: '17px' }}
                          >
                            {`x ${item.quantidade}`}
                          </strong>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                    {valoresAdicionais.map(({ descricao, tipo, valor }, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell
                          style={{ border: 'none', padding: '8px' }}
                        />
                        <StyledTableCell
                          style={{ border: 'none', padding: '8px' }}
                        >
                          {descricao}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          style={{ border: 'none', padding: '8px' }}
                        >
                          {tipo === 'desconto'
                            ? `-${formatReal(valor)}`
                            : `${formatReal(valor)}`}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              <CardContent className={classes.content}>
                <Grid container spacing={3}>
                  <Grid md={6} item>
                    <div
                      style={{
                        padding: '30px 60px 30px 60px',
                        backgroundColor: '#f5f5f5',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <Box marginBottom={2}>
                          <img
                            alt="Logo empresa"
                            src={logoPDF}
                            height={50}
                            width={140}
                          />
                        </Box>
                        <Typography>{corporateName}</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid md={6} item>
                    <div
                      style={{
                        padding: '30px 60px 30px 60px',
                        border: '1px solid #F2F2F2',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div>
                        <div
                          style={{
                            borderBottom: 'none',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            marginBottom: '8px',
                          }}
                        >
                          <Typography>Subtotal:</Typography>
                          &nbsp;
                          <Typography>
                            <strong>{formatReal(pedido.subtotal)}</strong>
                          </Typography>
                        </div>
                        <div
                          style={{
                            borderBottom: 'none',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            marginBottom: '8px',
                          }}
                        >
                          <Typography>Desconto:</Typography>
                          &nbsp;
                          <Typography>
                            <strong>{formatReal(pedido.desconto)}</strong>
                          </Typography>
                        </div>
                        <div
                          style={{
                            borderBottom: 'none',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Typography>Total:</Typography>
                          &nbsp;
                          <Typography
                            style={{
                              color: '#1335C6',
                              fontSize: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            <strong>{formatReal(pedido.total)}</strong>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      </S.PDFContainer>
    </>
  )
}

export default OrderInvoice
