import styled from 'styled-components'

export const SlideContent = styled.div`
  height: calc(var(--app-height) - 100px);
`

export const SlideContentContainer = styled.div`
  padding: 0 24px;

  .form-grid > span {
    display: none;
  }
`
