import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 2px 8px;
  background-color: ${colors.blue[50]};
  border-radius: 6px;
  > span {
    font-family: DM Sans;
    color: ${colors.blue[300]};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`
