import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Popover, colors } from '@material-ui/core'
import { MenuList } from './components'

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%',
    zIndex: '2',
    padding: '20px',
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
  },
}))

function MenuPopover(props) {
  const { directRestrict, restrict, anchorEl, nivelLogin, hideMenu, ...rest } =
    props

  const classes = useStyles()

  // Dados usuário
  const [, setDataNome] = useState('')

  useEffect(() => {
    const str = localStorage.getItem('sessionDataNome')
    if (str) {
      setDataNome(str)
    }
  }, [])

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      style={{
        zIndex: '8892',
        border: '1px solid #D9DBE9',
        boxShadow: '0px 12px 16px rgba(0, 0, 0, 0.04)',
      }}
      data-testid="popover-menu"
    >
      <div className={classes.root}>
        {/* <CardHeader title={dataNome} /> */}
        <MenuList
          nivelLogin={nivelLogin}
          directRestrict={directRestrict}
          restrict={restrict}
          hideClick={props.hideClick}
          hideMenu={() => hideMenu()}
        />
      </div>
    </Popover>
  )
}

MenuPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired,
  restrict: PropTypes.string.isRequired,
  directRestrict: PropTypes.bool.isRequired,
}

export default MenuPopover
