import React from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import {
  BoxIcon,
  DeleteIcon,
  RefreshIcon,
  EditIcon,
  ViewIcon,
} from '@77sol/icons/dist'
import { Button } from '@77sol/core'
import palette from 'app_palette'

export default function Icons({
  onClick,
  archived,
  incompleteQuote,
  rowData,
  setIncompleteQuoteToken,
  filterIncomplete,
}) {
  return (
    <>
      {incompleteQuote &&
        (filterIncomplete ? (
          <Button
            id={`projectContainer_button_editQuotation_${rowData.id}`}
            endIcon={<ViewIcon />}
            style={{ marginRight: '12px' }}
            size="small"
            onClick={() => {
              setIncompleteQuoteToken(rowData.firstToken)
              onClick('continue')
            }}
          >
            Editar cotação
          </Button>
        ) : (
          <Tooltip title="Continuar cotação">
            <IconButton
              id={`projectContainer_button_continueQuotation_${rowData.id}`}
              aria-label="continue"
              onClick={() => {
                setIncompleteQuoteToken(rowData.firstToken)
                onClick('continue')
              }}
            >
              <EditIcon color={palette.primary[300]} width="24px" />
            </IconButton>
          </Tooltip>
        ))}
      {archived ? (
        <Tooltip title="Desarquivar">
          <IconButton
            id={`projectContainer_button_unarchive_${rowData.id}`}
            aria-label="desarquivar"
            onClick={() => onClick('unarchive')}
          >
            <RefreshIcon color={palette.grayscale[700]} width="24px" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Arquivar">
          <IconButton
            id={`projectContainer_button_archive_${rowData.id}`}
            aria-label="arquivar"
            onClick={() => onClick('archive')}
          >
            <BoxIcon color={palette.grayscale[700]} width="24px" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Deletar">
        <IconButton aria-label="deletar" onClick={() => onClick('delete')}>
          <DeleteIcon color={palette.red[300]} width="24px" />
        </IconButton>
      </Tooltip>
    </>
  )
}
