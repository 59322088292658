import styled from 'styled-components'

export const Button = styled.button`
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  > svg {
    color: #828282;
    width: 24px;
  }
`
