import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react'

interface QuotationModalData {
  isQuotationModalOpen: boolean
  setIsQuotationModalOpen: (value: boolean) => void
}

interface ProviderProps {
  children: ReactNode
}

const QuotationModalContext = createContext<QuotationModalData>(
  {} as QuotationModalData,
)

function QuotationModalProvider({ children }: ProviderProps) {
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false)

  const contextValue = useMemo(
    () => ({
      isQuotationModalOpen,
      setIsQuotationModalOpen,
    }),
    [isQuotationModalOpen, setIsQuotationModalOpen],
  )

  return (
    <QuotationModalContext.Provider value={contextValue}>
      {children}
    </QuotationModalContext.Provider>
  )
}

const useQuotationModalContext = () => {
  const context = useContext(QuotationModalContext)
  return context
}

export { useQuotationModalContext, QuotationModalProvider }
