import { useEffect } from 'react'
import {
  type UseIntersectionObserverOptions,
  useIntersectionObserver,
} from './useIntersectionObserver'

export interface IUseInfiniteScrollProps
  extends UseIntersectionObserverOptions {
  onIntersect?: () => void
  active?: boolean
}

export function useInfiniteScroll({
  onIntersect,
  active = false,
  ...props
}: IUseInfiniteScrollProps) {
  const { isIntersecting, ref } = useIntersectionObserver({
    ...props,
  })

  useEffect(() => {
    if (active && isIntersecting) {
      onIntersect?.()
    }
  }, [isIntersecting, active])

  return { isIntersecting, ref }
}
