import { Wrapper } from './styles'

export type IRootProps = React.HtmlHTMLAttributes<HTMLDivElement>

export function Root({ children, ...props }: IRootProps) {
  return (
    <Wrapper data-testid="root-component" {...props}>
      {children}
    </Wrapper>
  )
}
