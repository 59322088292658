import React from 'react'
import { Backdrop, Modal, Fade } from '@material-ui/core'
import { CircleActionsAlertInfoIcon } from '@77sol/icons/dist'
import { Spacer, Button, Paper, Typography } from '@77sol/core'
import palette from 'app_palette'
import { useStyles } from '../../styles'

export function ModalUpdateSelectedEquipment({
  handleDiferencyPotency,
  setShowAlertDiferency,
  quote,
  handlePotency,
  filterAddedItems,
  activeSizing,
  handleUpdateKitPotency,
  handleChangePotency,
  loadingChangePotency,
  loadingUpdateKitPotency,
}) {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modalSm}
      open={handleDiferencyPotency(quote)}
      onClose={() => setShowAlertDiferency(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={handleDiferencyPotency(quote)} style={{ border: 'none' }}>
        <Paper boxShadow="small" style={{ height: 'auto', width: '90%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircleActionsAlertInfoIcon
                width="24"
                height="24"
                color={palette.orange[300]}
                style={{ marginRight: '8px' }}
              />
            </div>
            <Typography
              type="link"
              size="small"
              color="orange"
              colorWeight="300"
            >
              Os equipamentos selecionados estão fora do dimensionamento
            </Typography>
          </div>
          <Spacer size="32" direction="horizontal" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography size="medium" color="grayscale" colorWeight="700">
              Potência do kit
            </Typography>
            <Typography
              type="link"
              size="large"
              color="grayscale"
              colorWeight="700"
            >
              {handlePotency(
                quote?.quotation?.added_items
                  ? filterAddedItems(quote?.kit, quote?.quotation?.added_items)
                  : quote?.kit,
              )}
              kWp
            </Typography>
          </div>
          <Spacer size="16" direction="horizontal" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography type="text_medium" color="grayscale" colorWeight="700">
              Potência do dimensionamento
            </Typography>
            <Typography
              type="link"
              size="large"
              color="grayscale"
              colorWeight="700"
            >
              {(activeSizing?.potenciaCC / 1000).toFixed(2)}
              kWp
            </Typography>
          </div>
          <Spacer size="16" direction="horizontal" />
          <div>
            <Button
              id="quoteTabContent_button_updateEquipament"
              onClick={() =>
                handleUpdateKitPotency(
                  (activeSizing?.potenciaCC / 1000).toFixed(2),
                  quote.quotation.id,
                )
              }
              isLoading={loadingUpdateKitPotency}
              variant="outlined"
              noMargin
              size="small"
              fullWidth
              style={{ justifyContent: 'center' }}
            >
              Atualizar equipamentos
            </Button>
            <Spacer size="8" direction="horizontal" />
            <Button
              id="quoteTabContent_button_updateSizing"
              onClick={() =>
                handleChangePotency(
                  handlePotency(
                    quote?.quotation?.added_items
                      ? filterAddedItems(
                          quote?.kit,
                          quote?.quotation?.added_items,
                        )
                      : quote?.kit,
                  ),
                )
              }
              isLoading={loadingChangePotency}
              variant="outlined"
              noMargin
              size="small"
              fullWidth
              style={{ justifyContent: 'center' }}
            >
              Atualizar dimensionamento
            </Button>
            <Spacer size="8" direction="horizontal" />
            <Button
              id="quoteTabContent_button_cancel"
              variant="text"
              noMargin
              size="small"
              fullWidth
              onClick={() => setShowAlertDiferency(false)}
              style={{ justifyContent: 'center' }}
            >
              Cancelar
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
