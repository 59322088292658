import { DiscountModal } from 'containers/Modals/DiscountModal'
import { useDisplayModal } from './hooks/useDisplayModal'

export function QuotationDiscountModal() {
  const {
    quotationId,
    initialDiscount,
    handleCloseModal,
    handleSuccessSaveDiscount,
  } = useDisplayModal()

  if (!quotationId) {
    return null
  }

  return (
    <DiscountModal
      initialDiscount={initialDiscount}
      quotationId={quotationId}
      onClose={handleCloseModal}
      onSuccess={handleSuccessSaveDiscount}
    />
  )
}
