import styled from 'styled-components'

export const StyledModalDescriptionWrapper = styled.div`
  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (max-width: 780px) {
    p {
      display: inline-block !important;
    }
  }
`
