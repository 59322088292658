import API from 'api'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SetShowForPayment } from 'store/actions'
import {
  setAllSchedules,
  setCostTypes,
  SetIsProPlan,
  SetPlanInfos,
  setProductsCategories,
  setIntegratorUser,
  setExtraFields,
  setRoiItemsTypes,
  setReturnYears,
} from 'store/actions/profile'

export const useSettings = () => {
  const dispatch = useDispatch()

  const { costTypes, productsCategories } = useSelector(
    (state) => state.ReducerProfile,
  )

  const fetchAllSchedules = useCallback(() => {
    API.get('/user/schedules').then((response) => {
      dispatch(setAllSchedules(response.data))
    })
  }, [dispatch])

  const fetchCostTypes = useCallback(() => {
    if (costTypes.length <= 0) {
      API.get('/user/precification/types').then((response) => {
        dispatch(setCostTypes(response.data))
      })
    }
  }, [dispatch])

  const fetchProductsCategories = useCallback(() => {
    if (productsCategories.length <= 0) {
      API.get('/user/products/categorys').then((response) => {
        dispatch(setProductsCategories(response.data))
      })
    }
  }, [dispatch])

  const fetchIntegratorUsers = useCallback(() => {
    API.get('/users-integrador').then((response) => {
      dispatch(setIntegratorUser(response.data))
    })
  }, [dispatch])

  const fetchExtraFields = useCallback(() => {
    API.get('/user/fields').then((response) => {
      if (response?.data?.message === 'user without extra fields') {
        dispatch(setExtraFields([]))
      } else {
        dispatch(setExtraFields(response.data))
      }
    })
  }, [dispatch])

  const fetchRoiItems = useCallback(() => {
    API.get('/user/roi/items/configs')
      .then((res) => {
        dispatch(setRoiItemsTypes(res.data.items))
        dispatch(setReturnYears(res.data.years))
      })
      .catch(() => {
        API.post('/user/roi/years/edit', {
          years: 30,
        }).then(() => {
          fetchRoiItems()
        })
      })
  }, [dispatch])

  const isProPlan = useCallback(() => {
    API.get('/plano/acesso-plano-pro').then((response) => {
      const { pago, demonstracao, showForPayment } = response.data

      dispatch(SetIsProPlan(!!(pago || demonstracao)))
      dispatch(SetShowForPayment(showForPayment))
      dispatch(SetPlanInfos(response.data))
    })
  }, [dispatch])

  return {
    fetchAllSchedules,
    fetchCostTypes,
    fetchProductsCategories,
    fetchIntegratorUsers,
    fetchExtraFields,
    fetchRoiItems,
    isProPlan,
  }
}
