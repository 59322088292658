import { Button } from '@77sol-ui/atoms'
import { Pencil } from 'lucide-react'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { dimensioningTracker } from 'services/tracker/events/dimensioning/trackers'
import { useAmplitude } from 'hooks/useAmplitude'

interface IChangeFreightButtonProps {
  state: string
}

export function ChangeFreightButton({ state }: IChangeFreightButtonProps) {
  const setOpenModalToChangeQuotationFreight = useEquipmentContainerStore(
    (state) => state.setOpenModalToChangeQuotationFreight,
  )
  const { logEvent } = useAmplitude()

  function handleChangeQuotationFreight() {
    setOpenModalToChangeQuotationFreight(true)
    logEvent(dimensioningTracker.actions.editShipment, {
      origin: '/cotar',
    })
  }

  return (
    <Button variant="outline" onClick={handleChangeQuotationFreight}>
      {`Frete - ${state}`}
      <Pencil size={16} />
    </Button>
  )
}
