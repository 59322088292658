import * as S from './styles'

export interface ISkeletonProps {
  height: string
  width?: string
}

export function Skeleton({ height, width = '100%', ...rest }: ISkeletonProps) {
  return <S.Skeleton {...rest} height={height} width={width} />
}
