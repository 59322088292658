import { palette } from '@77sol/styles'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  leftSide: {
    gridColumn: 'span 5',
    background: palette.white,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '32px',

    '@media (max-width: 1279px)': {
      gridColumn: 'span 12',
      paddingTop: '24px',
      overflowY: 'hidden',
    },
    '@media (min-width: 1280px)': {
      borderRadius: 16,
      height: '100%',
    },
  },
  totalValue: {
    marginTop: '12px',

    '& p': {
      display: 'flex',
      justifyContent: 'space-between',

      '& b': {
        color: palette.green[300],
      },
    },
  },
})
