import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 10;

  @media (min-width: 1270px) {
    right: 42px;
    bottom: 42px;
  }
`
