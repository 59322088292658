import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { urlLogin } from 'urls'
import { useSelector } from 'react-redux'
import { clearStorage } from 'utils/clearStorage'
import { useAmplitude } from 'hooks/useAmplitude'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'
import { profileTracker } from 'services/tracker/events/profile/trackers'
import { userAccountTracker } from 'services/tracker/events/userAccount/tracker'
import { myTeamTracker } from 'services/tracker/events/myTeam/tracker'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { triggerTypes } from 'services/tracker/properties/triggerType'

interface IProfile {
  ReducerAuth: {
    userLogged: {
      0: {
        nome: string
        display_role: string
      }
    }
  }
}

export function useHeaderDesktop() {
  const history = useHistory()
  const { logEvent } = useAmplitude()
  const userLogged = useSelector(
    (state: IProfile) => state.ReducerAuth.userLogged,
  )

  const [open, setOpen] = useState(false)

  const redirectTo = useCallback((path: string) => {
    setOpen(false)
    history.push(path)
  }, [])

  const handleLogout = useCallback(() => {
    clearStorage(['@77sol-admin:expiressRankingModal', 'dateViewFreePlanModal'])

    return (window.location.href = urlLogin as string)
  }, [])

  function handleOpenedNotifications() {
    logEvent(notificationsTracker.actions.openNotifications, {
      origin: 'header',
      type: 'Button',
    })
  }

  function handleOpenDropdown() {
    setOpen((oldValue) => !oldValue)
    logEvent(profileTracker.actions.showProfileOptions, {
      origin: 'header',
      type: 'button',
      state: !open ? 'Aberto' : 'Fechado',
    })
  }

  function handleAccessMyAcount() {
    logEvent(userAccountTracker.events.accessMyAccount, {
      origin: 'header',
      type: 'Dropdown Option',
    })
    redirectTo('/configuracoes/empresa')
  }

  function handleAccessMyTeam() {
    logEvent(myTeamTracker.events.accessMyTeam, {
      origin: 'header',
      type: 'Dropdown Option',
    })
    redirectTo('/time')
  }

  function handleAccessCustomizeProposal() {
    logEvent(proposalTracker.actions.accessCustomizeProposal, {
      origin: 'header',
      type: 'Dropdown Option',
    })
    redirectTo('/configuracoes/propostas')
  }

  function handleOpenQuotationDropdown(opened: boolean) {
    logEvent(headerTracker.eventName, {
      actions: headerTracker.actions.quoteEquipmentKey,
      origin: location.pathname,
      type: triggerTypes.dropdownMenu.trigger,
      state: opened ? 'Aberto' : 'Fechado',
    })
  }

  return {
    userLogged,
    open,
    handleLogout,
    handleOpenedNotifications,
    handleOpenDropdown,
    handleAccessMyAcount,
    handleAccessMyTeam,
    handleAccessCustomizeProposal,
    handleOpenQuotationDropdown,
  }
}
