import { useHistory } from 'react-router-dom'
import { useSolLeadsModalStore } from '../../store/useSolLeadsModalStore'
import * as S from './styles'

export const EmptyState = () => {
  const history = useHistory()

  const setIsSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.setIsSolLeadsModalOpen,
  )

  const handleRedirectByEmptyState = () => {
    setIsSolLeadsModalOpen(false)
    history.push('/dimensionar')
  }

  return (
    <S.Container>
      <span>
        Incrível! Todos os seus clientes SolLeads já foram aceitos. E ai,{' '}
        <strong onClick={handleRedirectByEmptyState}>
          bora criar o projeto?
        </strong>
      </span>
    </S.Container>
  )
}
