import { type IRedirectNotification } from 'dto/NotificationDTO'
import { useHistory } from 'react-router-dom'
import { redirectOrderNotification } from './order'
import { redirectFinancingNotification } from './financing'
import { redirectFinancingCustomerRequestNotification } from './financing-customer-request'

export const useRedirectNotification = () => {
  const history = useHistory()

  const notificationPath = {
    order: redirectOrderNotification,
    'financing-pre-approved': redirectFinancingNotification,
    'financing-approved': redirectFinancingNotification,
    'financing-customer-request': redirectFinancingCustomerRequestNotification,
  }

  const redirectNotification = (notification: IRedirectNotification) => {
    const URL = notificationPath[notification.type](notification)
    return history.push(URL)
  }

  return { redirectNotification }
}
