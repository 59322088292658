import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 32px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`
