import { EmptyState as PrimitiveEmptyState } from '@77sol-ui/molecules'
import { Link } from 'react-router-dom'
import { ButtonAsLink, StyledLink, Wrapper } from './styles'

import { Search } from 'lucide-react'
import { useQuotationModalContext } from 'context/QuotationModalContext'

export interface IEmptyStateProps {
  alreadyCreatedProposal?: boolean
  filterApplied?: boolean
}

export function EmptyState({
  alreadyCreatedProposal,
  filterApplied,
}: IEmptyStateProps) {
  const { setIsQuotationModalOpen } = useQuotationModalContext()

  if (filterApplied) {
    return (
      <Wrapper height="100%" maxWidth="100%" spacing={4}>
        <Search strokeWidth={2} />

        <PrimitiveEmptyState.Subtitle size="lg">
          Nenhuma proposta encontrada para o filtro selecionado.
        </PrimitiveEmptyState.Subtitle>
      </Wrapper>
    )
  }

  if (alreadyCreatedProposal) {
    return (
      <Wrapper height="100%" maxWidth="100%" spacing={4}>
        <Search />

        <PrimitiveEmptyState.Subtitle size="lg">
          Você ainda não possui propostas criadas.
        </PrimitiveEmptyState.Subtitle>
        <StyledLink to="/dimensionar">Crie uma proposta agora!</StyledLink>
      </Wrapper>
    )
  }

  return (
    <PrimitiveEmptyState.Root
      height="300px"
      maxWidth="100%"
      spacing={4}
      variant="table"
    >
      <PrimitiveEmptyState.Icon emoji="👋" size="lg" />
      <PrimitiveEmptyState.Title size="md">
        Crie um novo projeto
      </PrimitiveEmptyState.Title>
      <PrimitiveEmptyState.Subtitle size="md">
        Ou simule uma cotação
        <ButtonAsLink
          onClick={() => {
            setIsQuotationModalOpen(true)
          }}
        >
          aqui
        </ButtonAsLink>
      </PrimitiveEmptyState.Subtitle>
      <PrimitiveEmptyState.Button asChild>
        <Link to="/dimensionar">Criar Projeto</Link>
      </PrimitiveEmptyState.Button>
    </PrimitiveEmptyState.Root>
  )
}
