import { Slider } from '@77sol/core'
import * as S from './styles'

interface ProductionFormProps {
  porcentagemProducao: number
  setPorcentagemProducao: (value: number) => void
}

export function ProductionForm({
  porcentagemProducao,
  setPorcentagemProducao,
}: ProductionFormProps) {
  return (
    <S.Container>
      <S.Label>Indique a porcentagem de produção desejada</S.Label>
      <S.Slider>
        <Slider
          id="sizing_slider_productionPercentage"
          defaultValue={porcentagemProducao}
          onChange={(_: null, newValue: number) => {
            setPorcentagemProducao(newValue)
          }}
          step={5}
          min={10}
          max={200}
        />
        <span className="percent">{`${porcentagemProducao}%`}</span>
      </S.Slider>
    </S.Container>
  )
}
