import styled from 'styled-components'

export const SlideContent = styled.div`
  height: calc(var(--app-height) - 100px);
`

export const SlideContentContainer = styled.div`
  height: 100%;
  padding: 0 24px;
  background-color: #fff;

  .form-grid > span {
    display: none;
  }
`
