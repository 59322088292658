import * as S from './styles'
import { Typography } from '@77sol/core'

export type DescriptionProps = React.ComponentProps<
  typeof S.DescriptionContainer
> & {
  description: string
  color?: string
  $colorWeight?: string
}

export const Description = ({
  description,
  color,
  $colorWeight,
  ...props
}: DescriptionProps) => {
  return (
    <S.DescriptionContainer {...props}>
      <Typography color={color} colorWeight={$colorWeight}>
        {description}
      </Typography>
    </S.DescriptionContainer>
  )
}
