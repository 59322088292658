import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  paperSelect: ({ size, direction }) => ({}),
  divPaper: {
    height: '100%',
    minHeight: '138px',
  },
}))

export default styles
