import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import utilsOptions from 'utils/options'
import { type FieldValues } from 'react-hook-form'

export const SelectState = <T extends FieldValues>({
  ...props
}: Omit<ISelectProps<T>, 'options'>) => {
  const statesOptions = [
    { label: props.placeholder as string, value: props.placeholder as string },
    ...(utilsOptions?.states || []),
  ]

  return <Select {...props} options={statesOptions} />
}
