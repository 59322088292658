import api from 'api'

import {
  type IGetProposalQuotationAllResponse,
  type IGetProposalQuotationAllRequest,
} from './types'

export const getProposalQuotationAll = async ({
  proposalId,
}: IGetProposalQuotationAllRequest) => {
  return await api.get<IGetProposalQuotationAllResponse>(
    `proposal/quotations_all/${proposalId}`,
  )
}
