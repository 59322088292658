import { useState, useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import smoothscroll from 'smoothscroll-polyfill'
import { useSelector } from 'react-redux'
import { ClientsSelect, SizingTypes, ReturnToTopButton } from './components'
import ResultadoDimensionamento from '../ResultadoDimensionamento'
import { useForm, FormProvider } from 'react-hook-form'
import { useCustomer } from './hooks/useCustomer'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { SizingTypeMenu } from './components/SizingTypes/components/SizingTypeMenu'
import { ChangeCalculationMethod } from './components/SizingTypes/components/ChangeCalculationMethod'
import * as S from './styles'
import { useScroll } from './hooks/useScroll'
import { schema } from './validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCreateBasicSizing } from './hooks/useCreateBasicSizing'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

export function StepOne({
  clientId,
  shouldCreateProposal,
  isIncompleteProFlow,
  projectId,
  setActiveDimStep,
  selectedClient,
  setSelectedClient,
  scrollStepOne,
  setLoadingCreateQuotation,
  setIsDimensioningDataScreen,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { userProfile } = useSelector((state) => state.ReducerProfile)
  const proposal = useSelector((state) => state.Reducer1)
  const newSizing = useSelector((state) => state.Reducer1.newSizing)

  const isAdmin = useIsAdmin()
  const [currentSizingType, setCurrentSizingType] = useState('')

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      zipcode: '',
      dealership_tariff: '',
      percentage_fio_b: '30',
      average_consumption_value: '',
      delivery_state: userProfile.uf || '',
      origin_lead: isAdmin ? 'B2B' : 'INTEGRADOR',
      structure_type: '',
      filter: {
        power_fase: '',
        voltage: '',
      },
    },
  })

  useEffect(() => {
    const { energy_bill_value, structure, cep } = selectedClient ?? {}
    formMethods.setValue('average_consumption_value', energy_bill_value || '')
    formMethods.setValue('structure_type', structure || '')
    formMethods.setValue('zipcode', cep || '')
    formMethods.setValue('sizing_type', currentSizingType)
  }, [selectedClient, currentSizingType])

  const [resultadoDimensionamento, setResultadoDimensionamento] = useState(
    !!isIncompleteProFlow,
  )
  const [activeStep, setActiveStep] = useState('cliente')
  const [statusPercentageFioB, setStatusPercentageFioB] = useState(true)
  const [porcentagemProducao, setPorcentagemProducao] = useState(100)
  const [loadingCreateSizing, setLoadingCreateSizing] = useState(false)

  const { handleCreateBasicSizing } = useCreateBasicSizing({
    selectedClient,
    setLoadingCreateSizing,
    setLoadingCreateQuotation,
    setActiveDimStep,
    formMethods,
  })

  const {
    handleClickClient,
    handleClickClientButton,
    handleClickTipoDimensionamento,
    refClient,
    refClientButton,
    refTipoDimensionamento,
  } = useScroll()

  useEffect(() => {
    getClients()

    if (proposal.sizings.length) {
      if (newSizing) {
        setActiveStep('dadosDim')
      } else if (proposal.proposal.customer_id) {
        setResultadoDimensionamento(true)
        handleClickTipoDimensionamento()
        setIsDimensioningDataScreen && setIsDimensioningDataScreen(true)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedClient) {
      formMethods.setValue(
        'delivery_state',
        selectedClient.uf || userProfile.uf || '',
      )

      if (downSm) {
        handleClickClientButton()
      }
    }
  }, [selectedClient])

  useEffect(() => {
    if (resultadoDimensionamento) {
      handleClickTipoDimensionamento()
    }
  }, [resultadoDimensionamento])

  const {
    loadingCreateProposal,
    loadingClients,
    clients,
    hasMoreClients,
    filterClientName,
    setFilterClientName,
    getMoreClients,
    getClients,
    resetFieldSearch,
    handleCreateProposal,
  } = useCustomer({
    setCurrentSizingType,
    shouldCreateProposal,
    clientId,
    setSelectedClient,
    setActiveStep,
    selectedClient,
    projectId,
  })

  return (
    <div ref={refTipoDimensionamento} style={{ padding: '24px 32px' }}>
      {!resultadoDimensionamento ? (
        <>
          <FormProvider {...formMethods}>
            <div>
              <ClientsSelect
                listHeight="calc(100vh - 280px)"
                handleClickClient={handleClickClient}
                refClient={refClient}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                getClients={getClients}
                loadingCreateProposal={loadingCreateProposal}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                handleCreateProposal={handleCreateProposal}
                clients={clients}
                loadingClients={loadingClients}
                projectId={projectId}
                setActiveDimStep={setActiveDimStep}
                refClientButton={refClientButton}
                getMoreClients={getMoreClients}
                hasMoreClients={hasMoreClients}
                filterClientName={filterClientName}
                setFilterClientName={setFilterClientName}
                resetFieldSearch={resetFieldSearch}
                formMethods={formMethods}
                setCurrentSizingType={setCurrentSizingType}
              />

              {selectedClient && activeStep !== 'cliente' && (
                <S.Container>
                  {!currentSizingType && (
                    <SizingTypeMenu
                      setCurrentSizingType={setCurrentSizingType}
                    />
                  )}

                  {currentSizingType && (
                    <>
                      <ChangeCalculationMethod
                        optionSelected={currentSizingType}
                        handleResetOptionSelected={setCurrentSizingType}
                      />

                      <SizingTypes
                        currentSizingType={currentSizingType}
                        setStatusPercentageFioB={setStatusPercentageFioB}
                        statusPercentageFioB={statusPercentageFioB}
                        porcentagemProducao={porcentagemProducao}
                        setPorcentagemProducao={setPorcentagemProducao}
                        loadingCreateSizing={loadingCreateSizing}
                        handleCreateBasicSizing={handleCreateBasicSizing}
                        setIsDimensioningDataScreen={
                          setIsDimensioningDataScreen
                        }
                        setResultadoDimensionamento={
                          setResultadoDimensionamento
                        }
                      />
                    </>
                  )}
                </S.Container>
              )}
            </div>
          </FormProvider>

          {scrollStepOne !== 0 && (
            <ReturnToTopButton onClick={handleClickTipoDimensionamento} />
          )}
        </>
      ) : (
        <ResultadoDimensionamento
          showButtons
          setActiveDimStep={setActiveDimStep}
          setLoadingCreateQuotation={setLoadingCreateQuotation}
        />
      )}
    </div>
  )
}
