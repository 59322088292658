import React, { useEffect, useState } from 'react'
import API from 'api'
import { Box } from '@material-ui/core'
import { Input, Dropdown, Typography } from '@77sol/core'
import { AccordionPaymentMethod } from 'components'
import { formatTransferData } from 'utils/format'
import { ACCOUNT_TYPE } from 'utils/constants'
import { INITIAL } from './initial'
import { validateData } from './utils'

function BankTransfer({
  userMethods = {},
  proposalMethods = null,
  setProposalMethod = () => {},
}) {
  const [banksList, setBanksList] = useState([])
  const [expandAccordion, setExpandAccordion] = useState(false)
  const [checkedTransfer, setCheckedTransfer] = useState(false)
  const [validateTransfer, setValidateTransfer] = useState({})
  const [valuesTransfer, setValuesTransfer] = useState(INITIAL.VALUES)

  useEffect(() => {
    setValidateTransfer(validateData(valuesTransfer))
  }, [valuesTransfer])

  useEffect(() => {
    API.get('/infos/banks').then(({ data }) => {
      setBanksList(
        data.map(({ code: value, description: label }) => ({
          value,
          label,
        })),
      )
    })
  }, [])

  useEffect(() => {
    setCheckedTransfer(Boolean(proposalMethods))
    setValuesTransfer({
      ...(proposalMethods || INITIAL.VALUES),
    })
  }, [proposalMethods])

  const handleUpdateMethod = () => {
    setProposalMethod(valuesTransfer)
  }

  const handleCheckedMethod = (checked = false) => {
    setProposalMethod(checked ? userMethods : null)
  }

  return (
    <AccordionPaymentMethod
      title="Transferência bancária"
      checked={checkedTransfer}
      expand={expandAccordion}
      setExpand={setExpandAccordion}
      setChecked={handleCheckedMethod}>
      <Box margin={2}>
        <Typography type="xsmall" color="grayscale" colorWeight="800">
          Tipo de conta
        </Typography>
      </Box>
      <Dropdown
        fullWidth
        title="Selecione uma conta"
        options={ACCOUNT_TYPE}
        value={valuesTransfer.account_type}
        validationState={validateTransfer.account_type}
        onBlur={handleUpdateMethod}
        onChange={(account_type) =>
          setValuesTransfer((state) => ({
            ...state,
            account_type,
          }))
        }
      />
      <Box margin={2}>
        <Typography type="xsmall" color="grayscale" colorWeight="800">
          Banco
        </Typography>
      </Box>
      <Dropdown
        fullWidth
        title="Selecione um banco"
        options={banksList}
        value={valuesTransfer.bank}
        validationState={validateTransfer.bank}
        onBlur={handleUpdateMethod}
        onChange={(bank) =>
          setValuesTransfer((state) => ({
            ...state,
            bank,
          }))
        }
      />
      <Box marginY={2}>
        <Input
          title="Agência"
          value={formatTransferData('agency', valuesTransfer.agency)}
          validationState={validateTransfer.agency}
          onBlur={handleUpdateMethod}
          onChange={(agency) =>
            setValuesTransfer((state) => ({
              ...state,
              agency,
            }))
          }
        />
      </Box>
      <Box marginY={2}>
        <Input
          title="Conta"
          value={formatTransferData('account', valuesTransfer.account)}
          validationState={validateTransfer.account}
          onBlur={handleUpdateMethod}
          onChange={(account) =>
            setValuesTransfer((state) => ({
              ...state,
              account,
            }))
          }
        />
      </Box>
      <Box marginY={2}>
        <Input
          title="Dígito da conta"
          value={formatTransferData('digit', valuesTransfer.digit)}
          validationState={validateTransfer.digit}
          onBlur={handleUpdateMethod}
          onChange={(digit) =>
            setValuesTransfer((state) => ({
              ...state,
              digit,
            }))
          }
        />
      </Box>
      <Box marginY={2}>
        <Input
          title="CPF / CNPJ"
          mask="cpfCnpj"
          value={valuesTransfer.cpf}
          validationState={validateTransfer.cpf}
          onBlur={handleUpdateMethod}
          onChange={(document) => {
            setValuesTransfer((state) => ({
              ...state,
              cpf: document,
              cnpj: document,
            }))
          }}
        />
      </Box>
      <Box marginY={2}>
        <Input
          title="Nome completo"
          value={valuesTransfer.account_name}
          validationState={validateTransfer.account_name}
          onBlur={handleUpdateMethod}
          onChange={(account_name) =>
            setValuesTransfer((state) => ({
              ...state,
              account_name,
            }))
          }
        />
      </Box>
    </AccordionPaymentMethod>
  )
}

export default BankTransfer
