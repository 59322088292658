import * as yup from 'yup'

const required = 'Campo obrigatório'

export const deliveryAddressSchema = yup
  .object()
  .shape({
    cep: yup.string().required(required).min(8, 'Insira um CEP válido'),
    endereco: yup.string().required(required),
    numero: yup.string().required(required),
    complemento: yup.string(),
    bairro: yup.string().required(required),
    cidade: yup.string().required(required),
    estado: yup
      .string()
      .required(required)
      .min(2, 'Insira um UF válido')
      .max(2),
  })
  .required()
