export const CUSTOM_TABLE_SPACING = '0.3fr 0.6fr 0.7fr 1fr 1fr 1fr'

export const TABLE_COLUMN_NAME = [
  '',
  'ID',
  'Potência',
  'Total Equipamentos',
  'Custos Extras',
  'Valor Total',
]
