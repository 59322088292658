import API from 'api'
import {
  ADD_EQUIPMENT_TO_QUOTATION,
  CHANGE_QUOTATION_EQUIPMENT_QUANTITY,
  GET_EQUIPMENTS_BRANDS,
  GET_EQUIPMENTS_CATEGORIES,
  REDEFINE_QUOTATION_KIT,
} from '../constants/endpointKeys'
import {
  type RedefineQuotationKitPayload,
  type ChangeEquipmentProps,
  type IEquipmentsCategoriesResponse,
  type changeEquipmentQuantityProps,
  type AddQuotationEquipmentPayload,
  type IEquipmentsBrandsResponse,
  type IEquipmentsBrandsParams,
  type AddQuotationEquipmentResponse,
  type RevertKitQuotationResponse,
} from './types'

export const quotationService = {
  changeEquipment: async (data: ChangeEquipmentProps) => {
    const response = await API.post('/proposal/quotation/item/change', data)
    return response.data
  },
  changeEquipmentQuantity: async (data: changeEquipmentQuantityProps) => {
    return await API.post(`${CHANGE_QUOTATION_EQUIPMENT_QUANTITY}`, data)
  },
  getEquipmentsCategories: async () => {
    const response = await API.get<IEquipmentsCategoriesResponse>(
      GET_EQUIPMENTS_CATEGORIES,
    )
    return response.data.categorias
  },
  getEquipmentsBrands: async (params?: IEquipmentsBrandsParams) => {
    const response = await API.get<IEquipmentsBrandsResponse>(
      GET_EQUIPMENTS_BRANDS,
      { params },
    )
    return response.data.marcas
  },
  redefineKit: async (
    data: RedefineQuotationKitPayload,
  ): Promise<RevertKitQuotationResponse> => {
    const response = await API.post(REDEFINE_QUOTATION_KIT, data)
    return response.data
  },
  addNewEquipment: async (
    data: AddQuotationEquipmentPayload,
  ): Promise<AddQuotationEquipmentResponse> => {
    const response = await API.post(ADD_EQUIPMENT_TO_QUOTATION, data)
    return response.data
  },
}
