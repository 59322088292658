import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialPaper from '@material-ui/core/Paper'
import styles from './styles'

function Paper({
  fillDirection,
  pointer,
  onClick,
  borderColor,
  children,
  noBackground,
  ...props
}) {
  const classes = styles({
    fillDirection, pointer, borderColor, noBackground,
  })
  const PaperClass = classNames(classes.paper)

  return (
    <MaterialPaper {...props} onClick={onClick} className={PaperClass}>
      {children}
    </MaterialPaper>
  )
}

Paper.propTypes = {
  fillDirection: PropTypes.oneOf(['vertical', 'horizontal', 'both']),
  pointer: PropTypes.bool,
  onClick: PropTypes.node,
  borderColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'green',
    'orange',
    'red',
    'yellow',
    'grayscale',
    'none',
  ]),
}

Paper.defaultProps = {
  fillDirection: 'horizontal',
  pointer: false,
  onClick: null,
  borderColor: 'none',
}

export default Paper
