import * as S from './styles'
import { Button } from '@77sol/core'
import { useSendEmailForm } from './hooks/useSendEmailForm'
import { TextField } from 'components/Controllers/TextField'
import { type IProposalCustomer } from 'dto/ProposalDTO'
import { type ISendEmailForm } from './validators'

interface ISendEmailFormProps {
  customer: IProposalCustomer
  onCancel: () => void
  onSubmit: (data: ISendEmailForm) => Promise<void>
}

export function SendEmailForm({
  customer,
  onSubmit,
  onCancel,
}: ISendEmailFormProps) {
  const { control, formState, handleSendEmailSubmit, handleSendEmailCancel } =
    useSendEmailForm({
      customer,
      onSubmit,
      onCancel,
    })

  return (
    <>
      <S.Label>Cliente</S.Label>
      <S.Client>{customer?.nome}</S.Client>
      <form onSubmit={handleSendEmailSubmit}>
        <S.Field>
          <S.Label>E-mail</S.Label>
          <TextField
            name="email"
            control={control}
            placeholder="example@example.com"
            error={formState.errors.email?.message}
          />
        </S.Field>
        <S.Buttons>
          <Button
            size="small"
            variant="outlined"
            onClick={handleSendEmailCancel}
            disabled={formState.isSubmitting}
          >
            Voltar
          </Button>
          <Button size="small" type="submit" isLoading={formState.isSubmitting}>
            Enviar
          </Button>
        </S.Buttons>
      </form>
    </>
  )
}
