import { Table } from '@77sol-ui/molecules'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import * as S from './styles'
import { useProductName } from 'hooks/useProductName'
import { ProductBadge } from 'containers/ProductBadge'

interface IProductRowProps {
  product: IQuotationProductDTO | null
}

const COLUMNS_SIZE = '1fr 6fr 1.5fr'

export function ProductRow({ product }: IProductRowProps) {
  const { productName, availabilityMessage } = useProductName({ product })

  const quantityUnit = product?.qtde === 1 ? 'unidade' : 'unidades'

  return (
    <Table.Root variant="bordered">
      <Table.Header headerTemplateColumns={COLUMNS_SIZE}>
        <Table.Head>Produto</Table.Head>
        <Table.Head>Descrição</Table.Head>
        <Table.Head>Quantidade</Table.Head>
      </Table.Header>

      <Table.Body>
        <S.TableRow border="top" rowTemplateColumns={COLUMNS_SIZE}>
          <Table.Cell>
            <S.Image src={product?.url} alt={productName} />
          </Table.Cell>
          <Table.Cell>
            <S.Description>
              <span className="product">{productName}</span>
              <span className="available" data-available={availabilityMessage}>
                {availabilityMessage}
              </span>
              {product?.garantia && (
                <ProductBadge.Warranty size="10" years={product.garantia} />
              )}
            </S.Description>
          </Table.Cell>
          <Table.Cell>
            {product?.qtde} {quantityUnit}
          </Table.Cell>
        </S.TableRow>
      </Table.Body>
    </Table.Root>
  )
}
