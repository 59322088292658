export const ROUTES_TO_SHOW_READER = [
  '/',
  '/calendario',
  '/projetos',
  '/equipamentos',
  '/financiamentos',
  '/pedidos',
  '/carteira/pagamentos',
  '/configuracoes',
  '/configuracoes/empresa',
  '/time',
]

export const INITIALLY_OPEN_DRAWER_ON_ROUTES = ['/', '/rank-77']

export const ROUTES_TO_OPEN_PROMPT = ['/dimensionar', '/projetos/propostas']
