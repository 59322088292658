import { createQuotation } from '../services/api'
import { useMutation } from '@tanstack/react-query'
import { type IPayloadCreateQuotation } from '../services/types'

export function useCreateQuotation() {
  const data = useMutation({
    mutationFn: async (data: IPayloadCreateQuotation) => {
      return await createQuotation(data)
    },
  })

  return data
}
