import { useEffect } from 'react'
import { Typography } from '@77sol/core'
import { useFetchShowCEP } from 'domains/showcep'
import { useFormContext } from 'react-hook-form'
import { type DeliveryAddressFormProps } from './DeliveryAddressForm.types'
import { TextField } from 'components/Controllers/TextField'
import { type IShipmentFormValues } from '../../ShipmentForm.types'
import { Container } from './styles'
import 'styles/grids.scss'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { ufList } from 'utils/constants'

export function DeliveryAddressForm({
  kit,
}: DeliveryAddressFormProps): JSX.Element {
  const {
    watch,
    setValue,
    control,
    clearErrors,
    formState: {
      errors: { deliveryAddress },
    },
  } = useFormContext<IShipmentFormValues>()

  const providerId = kit.find((item) => Boolean(item))?.fornecedor
  const cep = watch().deliveryAddress.cep

  const { cepData, isFetching } = useFetchShowCEP(
    {
      cep,
      providerId,
    },
    {
      enabled: Boolean(cep),
    },
  )

  useEffect(() => {
    if (cepData) {
      clearErrors([
        'deliveryAddress.endereco',
        'deliveryAddress.bairro',
        'deliveryAddress.cidade',
        'deliveryAddress.estado',
      ])
      setValue('deliveryAddress.endereco', cepData.logradouro || '')
      setValue('deliveryAddress.bairro', cepData.bairro || '')
      setValue('deliveryAddress.cidade', cepData.cidade)
      setValue('deliveryAddress.estado', cepData.uf)
    }
  }, [cepData])

  return (
    <section>
      <Typography size="large" color="primary" colorWeight="300">
        Dados de entrega
      </Typography>

      <Container className="form-grid">
        <TextField
          className="g-xs-12 g-md-3"
          name="deliveryAddress.cep"
          control={control}
          label="CEP*"
          mask="cep"
          isLoading={isFetching}
          error={deliveryAddress?.cep?.message}
        />

        <TextField
          className="g-xs-12 g-md-6"
          name="deliveryAddress.endereco"
          control={control}
          label="Endereço*"
          error={deliveryAddress?.endereco?.message}
        />

        <TextField
          className="g-xs-4 g-md-3"
          name="deliveryAddress.numero"
          control={control}
          label="Número*"
          error={deliveryAddress?.numero?.message}
        />

        <TextField
          className="g-xs-8 g-md-4"
          name="deliveryAddress.complemento"
          control={control}
          label="Complemento"
          error={deliveryAddress?.complemento?.message}
        />

        <TextField
          className="g-xs-12 g-md-3"
          name="deliveryAddress.bairro"
          control={control}
          label="Bairro*"
          error={deliveryAddress?.bairro?.message}
        />

        <TextField
          className="g-xs-8 g-md-3"
          name="deliveryAddress.cidade"
          control={control}
          label="Cidade*"
          error={deliveryAddress?.cidade?.message}
        />

        <DropdownControlled
          fullWidth
          options={ufList}
          className="g-xs-4 g-md-2"
          name="deliveryAddress.estado"
          control={control}
          label="UF*"
          error={deliveryAddress?.estado?.message}
        />
      </Container>
    </section>
  )
}
