export const headerLabel = {
  cliente: 'Cliente',
  financing: 'Financiamento',
  cotacao: 'Cotação',
  descricao: 'Descrição da Proposta',
  logo: 'Logo',
  cronograma: 'Cronograma',
  capa: 'Foto de capa',
  quemSomos: 'Quem somos',
  premissas: 'Premissas do projeto',
  termos: 'Termos e condições',
  imagensObras: 'Imagens de obras',
  validade: 'Validade',
  observacoes: 'Observações',
  cores: 'Cores',
  newField: 'Novo campo personalizado',
}

export const subheaderLabel = {
  paymentsMethod: 'Forma de pagamento',
  equipments: 'Equipamentos',
  budget: 'Orçamento',
  investmentReturn: 'Retorno de investmento',
}
