import { type ButtonHTMLAttributes, type ReactNode } from 'react'
import { Tooltip } from '@77sol/core'
import * as S from './styles'

interface RoundedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  color?: 'red' | 'primary'
  tooltipText?: string
}

export function RoundedButton({
  children,
  color = 'primary',
  tooltipText,
  ...rest
}: RoundedButtonProps) {
  return tooltipText ? (
    <Tooltip title={tooltipText}>
      <S.Button {...rest} color={color}>
        {children}
      </S.Button>
    </Tooltip>
  ) : (
    <S.Button {...rest} color={color}>
      {children}
    </S.Button>
  )
}
