import copy from 'copy-to-clipboard'
import { BitlyClient } from 'bitly-react'
import { urlLanding } from 'urls';

const generateBitly = async (url) => {
  try {
    const bitly = new BitlyClient(
      'ffc8e425019aeb52ea9a294c971b535310901d81',
      {},
    )
    return await bitly.shorten(url)
  } catch (e) {
    return { url }
  }
}

const shareUrl = async ({
  token, // case proposal
  resource, // whatsapp, copy
  customer, // { nome, telefone } //case whatsapp
  customerPhone,
}) => {
  try {
    const urlToFormat = `https://${urlLanding}/proposta/${token}`

    const { url: shareableUrl } = await generateBitly(urlToFormat)

    const whatsUrl = `https://api.whatsapp.com/send?phone=55${customerPhone}&text=https://api.whatsapp.com/send?l=pt-BR&text=Ol%C3%A1%20${customer?.nome}!%0A%0ASegue%20a%20sua%20proposta%20solar%3A%20${shareableUrl}.%0A%0AQualquer%20d%C3%BAvida%20%C3%A9%20s%C3%B3%20me%20chamar!`

    switch (resource) {
      case 'whatsapp':
        return window.open(whatsUrl, '_blank')
      case 'copy':
        return copy(shareableUrl)
      default:
        return shareableUrl
    }
  } catch (e) {
    console.log(e)
  }
}

export default shareUrl
