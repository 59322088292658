import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type IActions, type IState } from './types'

const DEFAULT_VALUES = {
  isOpen: false,
}

const useSidebarMobileStore = create<IState & IActions>()(
  devtools(
    (set) => ({
      ...DEFAULT_VALUES,
      setIsOpen: (isOpen) => {
        set({ isOpen })
      },
      reset: () => {
        set(DEFAULT_VALUES)
      },
    }),
    { name: 'SidebarMobileStore' },
  ),
)

export { useSidebarMobileStore }
