import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (min-width: 1280px) {
    gap: 8px;
    grid-template-columns: 1fr 0.7fr 1fr 30%;
    align-items: center;
  }

  @media (min-width: 1536px) {
    grid-template-columns: 1.3fr 0.7fr 0.7fr 40%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    > button {
      max-height: 46px;
      white-space: nowrap;
    }
  }
`
