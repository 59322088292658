/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import ErrorLayout from '../layouts/Error'
import { type INestedRoute } from 'types/routes'

export const errorRoutes: INestedRoute = {
  path: '/erro',
  component: ErrorLayout,
  routes: [
    {
      path: '/erro/401',
      exact: true,
      component: lazy(() => import('components/notFoundPage')),
    },
    {
      path: '/erro/404',
      exact: true,
      component: lazy(() => import('components/notFoundPage')),
    },
    {
      path: '/erro/500',
      exact: true,
      component: lazy(() => import('components/notFoundPage')),
    },
    {
      component: () => <Redirect to="/erro/404" />,
    },
  ],
}
