import { Alert } from '@77sol-ui/atoms'
import * as S from '../../styles'
import { InsuranceSwitch } from '../Switch'
import { InsuranceInformation } from '../InsuranceInformation'
import { useInsuranceValue } from '../../hooks/useInsuranceValue'
import { type IInsuranceControl } from '../../types/insuranceControl'

interface IInsuranceAlertProps
  extends Omit<IInsuranceControl, 'handleControlInsurance'> {}

export function PJContent({
  financingValue = 0,
  isEnabledInsurance,
}: IInsuranceAlertProps) {
  const { insuranceValue } = useInsuranceValue({
    financingValue,
    isEnabledInsurance,
  })

  const FORMATED_VALUE = insuranceValue.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })

  return (
    <Alert.Root variant="info" rounded>
      <S.StyledRootWrapper>
        <InsuranceSwitch
          isChecked={false}
          disabled
          disabledDescription="A Proteção de Financiamentos não está disponível para solicitantes PJ."
        />
        <Alert.Content>
          <S.StyledTitle>
            Aprimore a segurança do seu financiamento contra imprevistos com a
            Proteção para Financiamentos da 77Sol.
          </S.StyledTitle>
          <S.StyledInsuranceAlertDescription data-testid="insurance-description-list">
            <InsuranceInformation value={FORMATED_VALUE} />
          </S.StyledInsuranceAlertDescription>
        </Alert.Content>
      </S.StyledRootWrapper>
    </Alert.Root>
  )
}
