import React, { ReactElement } from 'react'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import { useStyles } from './styles'

interface ModalWrapperProps {
  isOpen: boolean
  onClose?: (isOpen: boolean) => void
  children: ReactElement
  id?: string
}

export function ModalWrapper({
  isOpen,
  children,
  id,
}: ModalWrapperProps): JSX.Element {
  const classes = useStyles()
  return (
    <Modal
      id={id}
      open={isOpen}
      className={classes.container}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>{children}</Fade>
    </Modal>
  )
}
