import { type IModalWrapperProps, Modal } from '@77sol-ui/molecules'
import { AnimationSolarEnergy } from 'components/AnimationSolarEnergy'
import { useCallback } from 'react'

export function LoadingAnimated({ ...props }: IModalWrapperProps) {
  const handlePreventModalCloseWithClickOutside = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  return (
    <Modal.Wrapper
      maxWidth="485px"
      maxHeight="440px"
      onPointerDownOutside={handlePreventModalCloseWithClickOutside}
      {...props}
    >
      <AnimationSolarEnergy animationText="Estamos gerando seu kit..." />
    </Modal.Wrapper>
  )
}
