import { useFinancingStatus } from 'domains/financing/financing-status/hooks/useFinancingStatus'
import { useToast } from '@77sol/core'
import { useMemo } from 'react'

export function useFinancingStatusDropdown() {
  const defaultOption = { value: '', label: 'Todos' }

  const { onErrorOpenToast } = useToast()

  const { data } = useFinancingStatus({
    onError: () => {
      onErrorOpenToast('Erro ao listar os status dos financiamentos')
    },
  })

  const financingStatus = useMemo(() => {
    const options =
      data?.map((item) => {
        return {
          label: item.name,
          value: item.slug,
        }
      }) || []

    return options
  }, [data])

  return {
    defaultOption,
    financingStatus,
  }
}
