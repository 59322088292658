import useWindowSize from 'hooks/useWindowSize'
import { Wrapper } from './styles'
import { type IFlagProps } from './types'

function Flag({ variant = 'blue', text, Icon, ...props }: IFlagProps) {
  const { width } = useWindowSize()
  const isMobile = width < 768

  return (
    <Wrapper variant={variant} data-testid="flag-component" {...props}>
      {Icon && !isMobile && <Icon size={16} />}
      <span>{text}</span>
    </Wrapper>
  )
}

export { Flag, type IFlagProps }
