import { useGetDimensioningQuotation } from 'domains/dimensioning/hooks/useGetDimensioningQuotations'
import { useGetKits } from 'domains/kits/get-kits-dimensioningId/hooks/useGetKits'
import { v4 as uuid } from 'uuid'
import { findKitInQuotation } from '../helpers/findKitInQuotation'
import { type IKitDTO } from 'dto/KitDTO'
import { convertQuotationToKit } from '../helpers/convertQuotationToKit'
import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'
import { useMemo } from 'react'

export interface IKitDTOWithId extends IKitDTO {
  kitId: string
  id?: number
  changed?: boolean
}

export const useLoadKits = (dimensioningId: string) => {
  const setSelectedQuotation = useEquipmentContainerStore(
    (state) => state.setQuotationSelected,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setFirstLoadPage = useEquipmentContainerStore(
    (state) => state.setFirstLoadPage,
  )

  const firstLoadPage = useEquipmentContainerStore(
    (state) => state.firstLoadPage,
  )

  const { data: dimensioninData } = useGetDimensioningQuotation({
    dimensioningId,
  })

  const findQuotationSelected = dimensioninData?.quotations?.find(
    ({ id }) => id === quotationSelected?.id,
  )

  setSelectedQuotation(
    findQuotationSelected || dimensioninData?.quotations?.[0] || null,
  )

  const { data: kitsList } = useGetKits(dimensioningId, {
    staleTime: 1000 * 60 * 60,
  })

  const { kitsWithId = [], selectedKits = [] } = useMemo((): {
    kitsWithId?: IKitDTOWithId[]
    selectedKits?: IKitDTOWithId[]
  } => {
    if (!kitsList?.kits?.length) return { kitsWithId: [], selectedKits: [] }

    const kitsWithId = kitsList?.kits.map((kit) => {
      return { ...kit, kitId: uuid() }
    })

    let selectedKits = dimensioninData?.quotations?.map((quotation) => {
      const findKit = findKitInQuotation(quotation, kitsWithId)

      if (findKit) {
        const { kitId: findKitId } = findKit

        const index = kitsWithId.findIndex(({ kitId }) => kitId === findKitId)
        kitsWithId[index] = findKit

        return {
          ...quotation,
          kitId: findKitId,
        }
      }

      const newQuotationKit = convertQuotationToKit(quotation)
      kitsWithId?.unshift(newQuotationKit)

      return newQuotationKit
    })

    if (selectedKits?.length === 0 && firstLoadPage) {
      selectedKits = kitsWithId.slice(0, 3)
      setFirstLoadPage(false)
    }

    return {
      kitsWithId,
      selectedKits,
    }
  }, [kitsList, dimensioninData])

  return {
    kits: kitsWithId,
    selectedKits,
    quotations: dimensioninData?.quotations ?? [],
  }
}
