import { getOverloadAndPotency } from 'utils'
import { CATEGORIES, ERROR_MESSAGES } from './constants'

function checkInverterOverload(product, potency, inversorOverload) {
  return CATEGORIES.includes(product?.categoria) && potency > inversorOverload
}

function checkNegativeOverload(product, overload) {
  return overload < -20 && CATEGORIES.includes(product?.categoria)
}

function checkProviderError(product, quote) {
  return (
    quote?.firstKit?.providers &&
    quote?.firstKit?.changeProduct === product?.categoria
  )
}

function createError(
  existingError = {},
  productId,
  message = 'Erro desconhecido',
) {
  return {
    hasError: true,
    ...existingError,
    kitError: {
      ...existingError?.kitError,
      [productId]: {
        hasError: true,
        message,
      },
    },
  }
}

function validateOverload({ product, overload, potency, inversorOverload }) {
  let error = {}

  if (checkInverterOverload(product, potency, inversorOverload)) {
    error = createError(
      error,
      product?.id,
      ERROR_MESSAGES?.[product?.categoria]?.OVERLOAD,
    )
  }

  if (checkNegativeOverload(product, overload)) {
    error = createError(
      error,
      product?.id,
      ERROR_MESSAGES?.[product?.categoria]?.LOW_OVERLOAD,
    )
  }

  return error
}

function validateAllQuotes(quotes) {
  const errors = quotes?.reduce((prevErrors, quote) => {
    let error = {}

    const kitModule = quote?.kit?.find((item) => item?.categoria === 62)

    if (kitModule) {
      const { overload, potency, inversorOverload } =
        getOverloadAndPotency(quote)

      error = validateOverload({
        product: kitModule,
        overload,
        potency,
        inversorOverload,
      })
    }

    quote?.kit.forEach((product) => {
      if (checkProviderError(product, quote)) {
        error = createError(
          error,
          product?.id,
          ERROR_MESSAGES.DIFFERENT_PROVIDER,
        )
      }
    })

    return {
      ...prevErrors,
      [quote?.quotation?.id]: error,
    }
  }, {})

  return {
    hasError: Object.values(errors).some((error) => error.hasError),
    errors,
  }
}

function removeQuoteValidation(quotesErrors, quoteId) {
  const updatedErrors = Object.fromEntries(
    Object.entries(quotesErrors?.errors).filter(
      ([key]) => Number(key) !== quoteId,
    ),
  )

  return {
    hasError: Object.values(updatedErrors).some((error) => error.hasError),
    errors: updatedErrors,
  }
}

function validateItemBeingUpdated(previousErrors, quote, productId) {
  let error = {}

  const product = quote?.kit?.find(
    (item) => item?.id === productId && CATEGORIES.includes(item?.categoria),
  )

  if (product) {
    const { overload, potency, inversorOverload } = getOverloadAndPotency(quote)

    error = validateOverload({
      product,
      overload,
      potency,
      inversorOverload,
    })
  }

  quote?.kit.forEach((product) => {
    if (checkProviderError(product, quote)) {
      error = createError(error, product?.id, ERROR_MESSAGES.DIFFERENT_PROVIDER)
    }
  })

  const errors = {
    ...previousErrors?.errors,
    [quote?.quotation?.id]: error,
  }

  return {
    hasError: Object.values(errors).some((error) => error.hasError),
    errors,
  }
}

export { removeQuoteValidation, validateAllQuotes, validateItemBeingUpdated }
