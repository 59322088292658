import React from 'react'
import { Button, Title, Typography } from '@77sol/core'
import { WeatherMoonIcon } from '@77sol/icons/dist'
import { useHistory } from 'react-router-dom'
import { useStyles } from './styles'

export function WhiteScreenFallback({ resetErrorBoundary }) {
  const classes = useStyles()
  const history = useHistory()

  const handleResetErrorBoundary = () => {
    resetErrorBoundary()
    history.push('/')
  }

  return (
    <section className={classes.container} role="alert">
      <div className={classes.icon}>
        <WeatherMoonIcon />
      </div>
      <div className={classes.title}>
        <Title size="xxsmall">Algo de errado aconteceu!</Title>
      </div>
      <div className={classes.context}>
        <Typography>
          Nosso time de engenharia já recebeu uma notificação e está trabalhando
          na correção.
        </Typography>
        <Typography>
          Por enquanto, recomendamos limpar o cache do seu navegador de internet
          e tentar novamente.
        </Typography>
      </div>
      <Button color="white" onClick={handleResetErrorBoundary}>
        Voltar ao início
      </Button>
    </section>
  )
}
