import { useMutation } from '@tanstack/react-query'
import { updateDimensioningKits } from '../services/api'
import { type IUpdateDimensioningKitsPayload } from '../services/types'

export const useUpdateDimensioningKits = ({
  dimensioningId,
}: {
  dimensioningId: number | null
}) => {
  const mutation = useMutation({
    mutationFn: async (payload: IUpdateDimensioningKitsPayload) => {
      return await updateDimensioningKits(dimensioningId, payload)
    },
  })

  return mutation
}
