import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Hidden, useMediaQuery } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { Skeleton } from '@material-ui/lab'
import LinearProgress from '@material-ui/core/LinearProgress'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Input, Typography, GroupButtonMobile, Header } from 'components'
import { Spacer, Button, Paper, TabList, Checkbox, useToast } from '@77sol/core'
import {
  DeleteIcon,
  ActionPlusIcon,
  EditIcon,
  MoreVertIcon,
  CircleActionsCloseIcon,
  CompareIcon,
  DownloadIcon,
  ProfileIcon,
  SendIcon,
  RefreshIcon,
  CommentIcon,
} from '@77sol/icons/dist'
import { addWorkDays, handlePotency, handleOverload, parseBRL } from 'utils'
import palette from 'app_palette'
import { getAllCustomers } from 'utils/requests'
import { SaveExtraConfigsRedux } from 'store/actions'
import API from 'api'
import * as ProposalAction from 'store/actions/index'
import axios from 'axios'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useAmplitude } from 'hooks/useAmplitude'
import * as FileSaver from 'file-saver'
import { setProModalOpen } from 'store/actions/proModal'
import {
  ModalSelecionarCotacoes,
  ModalAlterarProduto,
  ModalDescricao,
  BottomMenu,
  ModalAdicionarProduto,
  ModalSalvarCotacao,
  ModalFrete,
  ModalSelectClient,
  QuoteTabContent,
} from './components'
import SizingModal from '../SizingModal'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'moment/locale/pt-br'
import { Filters } from './components/Filters'
import { useStyles } from './styles'
import { ModalPloomes } from './components/ModalPloomes'
import { ModalMoreOptionsMobile } from './components/ModalMoreOptionsMobile/ModalMoreOptionsMobile'
import { ModalDefaultFilter } from './components/ModalDefaultFilter'
import { ModalUpdateProductValue } from './components/ModalUpdateProductValue'
import { validateActionProposalButtons } from 'utils/functions'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { configTracker } from 'services/tracker/events/configs/trackers'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { ProductRemoveModal } from 'containers/Modals/ProductRemoveModal'
import { useRemoveProduct } from '../../hooks/useRemoveProduct'
import { useDeleteQuotation } from './hooks/useDeleteQuotation'
import { QuotationDeleteModal } from 'containers/Modals/QuotationDeleteModal'
import { DiscountModal } from 'containers/Modals/DiscountModal'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { useValidateDeleteQuotation } from 'hooks/quotation/useValidateDeleteQuotation'

moment.locale('pt-br')

function StepTwo({
  completeQuoteEdit,
  setCompleteQuoteEdit,
  setActiveDimStep,
  newFromStepThree,
  quoteIdEditing,
  setQuoteIdEditing,
  outdatedQuotes,
  verifyKit,
  isCancelingQuotationOrProject,
}) {
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const downXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const { logEvent } = useAmplitude()

  // Redux
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)
  const currentQuotes = useSelector((state) => state.Reducer1.quotes)
  const currentActiveSizing = useSelector(
    (state) => state.Reducer1.activeSizing,
  )

  const [quotes, setQuotes] = useState([])

  useEffect(() => {
    setQuotes(
      currentQuotes.filter(
        (quote) => quote.dimensioning.id === currentActiveSizing,
      ),
    )
  }, [currentQuotes, currentActiveSizing])

  const proposal = useSelector((state) => state.Reducer1)
  const activeSizing = useSelector((state) =>
    state.Reducer1.sizings.find(
      (sizing) => sizing.id == state.Reducer1.activeSizing,
    ),
  )
  const isProPlan = useSelector((state) => state.ReducerProfile.isProPlan)
  const dispatch = useDispatch()

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { productToDelete, handleCloseDeleteProduct, handleOpenDeleteProduct } =
    useRemoveProduct()

  const [modalDefaultFilter, setModalDefaultFilter] = useState(false)

  const classes = useStyles()

  const [comparacao, setComparacao] = useState(false)
  const [modalDescricao, setModalDescricao] = useState(false)
  const [modalAlterar, setModalAlterar] = useState(false)
  const [modalSelecionarCotacoes, setModalSelecionarCotacoes] = useState(false)
  const [modalAdicionarProduto, setModalAdicionarProduto] = useState(false)
  const [valueTab, setValueTab] = useState(
    quoteIdEditing
      ? currentQuotes.findIndex((quote) => quote.quotation.id == quoteIdEditing)
      : newFromStepThree
      ? currentQuotes.length - 1
      : 0,
  )

  const currentQuotation = quotes.find((_, i) => i === valueTab)
  const currentQuotationId = currentQuotation?.quotation?.id

  const [sizingWidth, setSizingWidth] = useState('388px')
  const [datasheetProduct, setDatasheetProduct] = useState({})
  const [productToChange, setProductToChange] = useState({})
  const [brands, setBrands] = useState(
    useSelector((state) => state.Reducer1.brands),
  )
  const [loadingCreateQuote, setLoadingCreateQuote] = useState(false)
  const [openLoadingPdf, setOpenLoadingPdf] = useState(false)
  const [editingOverload, setEditingOverload] = useState(false)
  const [openModalSalvarCotacao, setOpenModalSalvarCotacao] = useState(false)
  const [newOverload, setNewOverload] = useState('')
  const [loadingChangePotency, setLoadingChangePotency] = useState(false)
  const [loadingUpdateKitPotency, setLoadingUpdateKitPotency] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)

  const [sizingModal, setSizingModal] = useState()

  const [loadingDelivery, setLoadingDelivery] = useState(false)

  const [isDeletingMobile, setIsDeletingMobile] = useState('')
  const [loadingRemoveItem, setLoadingRemoveItem] = useState(false)
  const [modalMoreOptionsMobile, setModalMoreOptionsMobile] = useState(false)

  const [showAlertDiferency, setShowAlertDiferency] = useState(false)
  const [selectClientModal, setSelectClientModal] = useState(false)

  const [filterInversorOpen, setFilterInversorOpen] = useState(false)
  const [filterModulosOpen, setFilterModulosOpen] = useState(false)
  const [inversorTypeFilter, setInversorTypeFilter] = useState(0)
  const [quotationTypeFilter] = useState('preco')
  const [filterMobile, setFilterMobile] = useState(false)

  const [inversorsBrandFilter, setInversorsBrandFilter] = useState([])
  const [modulesBrandFilter, setModulesBrandFilter] = useState([])
  const [lockedProducts, setLockedProducts] = useState([])
  const [loadingFilter, setLoadingFilter] = useState(false)

  const [toPotency, setToPotency] = useState(600)
  const [fromPotency, setFromPotency] = useState(300)
  const [checkedTransfomer] = useState(false)

  // Admin
  const admin = useIsAdmin()
  const [adminModal, setAdminModal] = useState(false)

  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const [invertersBrands, setInvertersBrands] = useState([])
  const [changedFilter, setChangedFilter] = useState(false)
  const [loadingDefaultFilter, setLoadingDefaultFilter] = useState(false)

  const [modalAttProduct, setModalAttProduct] = useState(false)
  const [outdatedProductToAdd, setOutdatedProductToAdd] = useState()
  const [loadingAttProduct, setLoadingAttProduct] = useState(false)

  // Integration Ploomes
  const [optionsCity, setOptionsCity] = useState([])
  const [modalPloomes, setModalPloomes] = useState(false)
  const [valuesPloomes, setValuesPloomes] = useState({
    id_quotes: quotes[valueTab]?.quotation.id,
    state: quotes[valueTab]?.quotation?.delivery_state,
    city: '',
    info: '',
  })

  const { quotationDelete, handleOpenDeleteModal, handleCloseDeleteModal } =
    useDeleteQuotation()

  const { checkSingleQuotation } = useValidateDeleteQuotation({
    quotation: quotationDelete,
    allQuotations: quotes,
  })

  function handleDeleteCurrentQuotation() {
    const currentQuotation = quotes?.[valueTab]
    handleOpenDeleteModal(currentQuotation)
  }

  function handleSuccessDeleteQuotation() {
    handleCloseDeleteModal()

    const isLastTab = valueTab + 1 === quotes.length
    if (isLastTab) {
      setValueTab(valueTab - 1)
    }
  }

  const hasSomeError =
    validateActionProposalButtons(proposal) || proposal?.quotesErrors?.hasError

  useEffect(() => {
    const { state } = valuesPloomes
    if (state && state !== 'sem_frete') {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
        )
        .then(({ data }) => {
          setOptionsCity(
            data.map(({ nome }) => ({
              label: nome,
              value: nome,
            })),
          )
        })
    }
  }, [valuesPloomes.state, valuesPloomes])

  const actionsPloomes = () => {
    const { id_quotes } = valuesPloomes
    API.post(`/ploomes/info-quotation/${id_quotes}`, valuesPloomes)
      .then(({ data }) => {
        onSuccessOpenToast(data?.message || 'Enviado com sucesso!')
        setModalPloomes(false)
      })
      .catch(() => {
        onErrorOpenToast('Erro ao enviar!')
      })
  }

  const getNivel = () => {
    if (userLogged.regra) {
      dispatch(ProposalAction.SavenivelLoginRedux(userLogged.regra))
    } else {
      dispatch(ProposalAction.SavenivelLoginRedux(2))
    }
  }

  const updateActualQuote = (updatedQuotation) => {
    if (currentQuotationId) {
      API.get(`/proposal/quotation/${currentQuotationId}`).then(({ data }) => {
        const quotationData = data

        if (updatedQuotation) {
          quotationData.quotation = {
            ...quotationData.quotation,
            ...updatedQuotation,
          }
        }

        dispatch(ProposalAction.UpdateQuoteRedux(quotationData))
        setLoadingFilter(false)
      })
    }
  }

  function handleCloseDiscountModal() {
    setAdminModal(false)
  }

  function handleSuccessSaveConfig(response) {
    updateActualQuote(response?.data?.result)
    handleCloseDiscountModal()
  }

  const handleMessageOneQuote = () => {
    onErrorOpenToast(
      'Não há cotações suficientes para prosseguir com essa ação!',
    )
  }

  const handleRemoveItem = (productId, quoteId) => {
    setLoadingRemoveItem(true)

    API.post('/proposal/remove/quotation/item', {
      quotation_id: quoteId,
      product_id: productId,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateQuoteRedux(res.data, true))
        setLoadingRemoveItem(false)
        onSuccessOpenToast('Item removido com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível remover item!')
      })
  }

  const handleUpdateKitPotency = (value, quoteId) => {
    setLoadingUpdateKitPotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/quotation/update/kit', {
      quotation_id: quoteId,
      potency: finalValue,
    })
      .then((res) => {
        setShowAlertDiferency(false)
        setLoadingUpdateKitPotency(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        onSuccessOpenToast('Cotação atualizada com sucesso!')
      })
      .catch(() => {
        setLoadingUpdateKitPotency(false)
        onErrorOpenToast('Não foi possível alterar a cotação!')
      })
  }

  const handleChangePotency = (value) => {
    setLoadingChangePotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/dimensioning/update/potency', {
      dimensioning_id: activeSizing.id,
      potency: finalValue,
    })
      .then((res) => {
        setLoadingChangePotency(false)
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        onSuccessOpenToast('Dimensionamento atualizado com sucesso!')
        setShowAlertDiferency(false)
      })
      .catch(() => {
        setLoadingChangePotency(false)
        onErrorOpenToast('Não foi possível alterar o dimensionamento!')
      })
  }

  const handleFinish = (id) => {
    API.post('/proposal/quotation/budget/finalized', {
      quotation_id: id,
    }).then((res) => {
      dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
    })
  }

  const handleFinishQuote = () => {
    const step = parseInt(proposal.activeStep.toString().replace(/[^0-9]/g, ''))
    if (step == 2) {
      dispatch(ProposalAction.SaveStepRedux('3'))
    }
    if (step == 3 || step == 4) {
      setActiveDimStep(3)
    }
  }

  const handleEditOverload = () => {
    const actualQuotation = quotes.find((quote, i) => i == valueTab)

    API.post('/proposal/quotation/edit/overload', {
      quotation_id: actualQuotation.quotation.id,
      overload: newOverload,
    })
      .then((res) => {
        setEditingOverload(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        onSuccessOpenToast('Sobrecarga alterada com sucesso!')
      })
      .catch(() => {
        setEditingOverload(false)
        onErrorOpenToast('Não foi possível alterar a sobrecarga!')
      })
  }

  const [clients, setClients] = useState([])
  const [loadingAddProduct, setLoadingAddProduct] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)

  const [openModalShipmentValue, setOpenModalShipmentValue] = useState(false)

  const handleChangeShipmentValue = (values = {}, quoteId) => {
    setLoadingDelivery(true)

    API.post('/proposal/quotation/update/delivery', {
      quotation_id: quoteId,
      delivery_state: values.state,
      delivery_city: values.city,
    })
      .then(() => {
        const actualQuotation = quotes.find((quote, i) => i == valueTab)
        onSuccessOpenToast('Frete alterado com sucesso!')
        handleFinish(actualQuotation.quotation.id)
      })
      .catch(({ response = {} }) => {
        const { message } = response.data || {}
        onErrorOpenToast(message || 'Não foi possível alterar o frete!')
      })
      .finally(() => {
        setLoadingDelivery(false)
        setOpenModalShipmentValue(false)
      })
  }

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  const handleSaveCotation = (id) => {
    setLoadingSave(true)

    API.put('/proposal/quotation/save', {
      quotation_id: id,
    })
      .then(() => {
        setOpenModalSalvarCotacao(false)
        setLoadingSave(false)
        onSuccessOpenToast('Cotação salva com sucesso!')
      })
      .catch(() => {
        setLoadingSave(false)
        onErrorOpenToast('Não foi possível salvar a cotação!')
      })
  }

  const checkoutModal = useCheckoutModalStore((state) => state)

  const handleUpdateCart = () => {
    const quotationId = quotes.find((quote, i) => i == valueTab).quotation.id
    checkoutModal.setQuotationId(quotationId)
    checkoutModal.setIsOpen(true)
  }

  const handleAddProduct = (currentProduct, quote) => {
    setLoadingAddProduct(true)

    API.post('/proposal/quotation/add/item', {
      quotation_id: quote.quotation.id,
      extra_items: [currentProduct.id],
      potency: handlePotency(
        quote.quotation.added_items
          ? filterAddedItems(quote.kit, quote.quotation.added_items)
          : quote.kit,
      ),
    })
      .then((res) => {
        setLoadingAddProduct(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data, true))
        setModalAdicionarProduto(false)
        onSuccessOpenToast('Produto adicionado com sucesso')
      })
      .catch(({ response }) => {
        setLoadingAddProduct(false)
        onErrorOpenToast(
          response?.data?.message || 'Não foi possível adicionar o produto',
        )
      })
  }

  const handleChangeProduct = async (
    newProduct,
    currentProduct,
    quote,
    qtde,
    newQtde,
  ) => {
    const responseChangeItem = {
      then: ({ data }) => {
        dispatch(ProposalAction.UpdateQuoteRedux(data, false, newProduct.id))
        onSuccessOpenToast('Produto alterado com sucesso')
        setModalAlterar(false)
      },
      catch: ({ response }) => {
        const { message } = response.data || {}
        onErrorOpenToast(
          [422].includes(response.status)
            ? message
            : 'Não foi possível alterar o produto',
        )
      },
    }

    if (
      newProduct.categoria == 61 ||
      newProduct.categoria == 62 ||
      newProduct.categoria == 66 ||
      newProduct.categoria == 63
    ) {
      await API.post('/proposal/quotation/item/change', {
        quotation_id: quote.quotation.id,
        new_product_id: newProduct.id,
        product_qty: qtde,
        new_product_qty: newQtde || null,
        potency: handlePotency(
          quote.quotation.added_items
            ? filterAddedItems(quote.kit, quote.quotation.added_items)
            : quote.kit,
        ),
        current_product_id: currentProduct.id,
      })
        .then(responseChangeItem.then)
        .catch(responseChangeItem.catch)
    } else {
      await API.post('proposal/quotation/item/change-direct', {
        quotation_id: quote.quotation.id,
        new_product_id: newProduct.id,
        new_product_qty: qtde,
        current_product_id: currentProduct.id,
      })
        .then(responseChangeItem.then)
        .catch(responseChangeItem.catch)
    }
  }

  const [loadingChangingQtd, setLoadingChangingQtd] = useState('')

  const handleChangeQuantity = (quoteId, productId, qtd, type) => {
    setLoadingAttProduct(true)
    setLoadingChangingQtd(productId)

    API.post('/proposal/quotation/item/qty', {
      quotation_id: quoteId,
      product_id: productId,
      qty_product: qtd,
    })
      .then((res) => {
        setModalAttProduct(false)
        setLoadingAttProduct(false)
        setLoadingChangingQtd('')
        dispatch(ProposalAction.UpdateQuoteRedux(res.data, true, productId))
        if (type == 'att') {
          verifyKit(proposal.proposal.id)
          onSuccessOpenToast('Valor atualizado com sucesso!')
        } else {
          onSuccessOpenToast('Quantidade alterada com sucesso!')
        }
      })
      .catch(({ response }) => {
        setModalAttProduct(false)
        setLoadingAttProduct(false)
        if (type == 'att') {
          onErrorOpenToast(
            response?.data?.message || 'Não foi possível alterar o valor!',
          )
        } else {
          onErrorOpenToast(
            response?.data?.message || 'Não foi possível alterar a quantidade!',
          )
        }
      })
  }

  const handlePdf = () => {
    if (hasSomeError) {
      return
    }

    logEvent(fileTracker.actions.downloadPDF)
    if (!comparacao) {
      const actualQuotation = quotes.find((quote, i) => i == valueTab)

      API.get(
        `/admin/pdf-generate/quotation/${actualQuotation?.quotation?.id}`,
        {
          headers: {
            responseType: 'blob',
          },
          responseType: 'blob',
        },
      )
        .then((res) => {
          const file = new Blob([res.data], { type: 'application/pdf' })
          FileSaver.saveAs(
            file,
            `${actualQuotation?.quotation?.id}_Cotacao_${handlePotency(
              actualQuotation?.quotation?.added_items
                ? filterAddedItems(
                    actualQuotation?.kit,
                    actualQuotation?.quotation?.added_items,
                  )
                : actualQuotation?.kit,
            )
              .toString()
              .replace('.', '_')}kWp_${moment(new Date()).format('L')}`,
          )
          setOpenLoadingPdf(false)
        })
        .catch(() => {
          setOpenLoadingPdf(false)
        })

      setOpenLoadingPdf(true)
    }
  }

  const handleCreateQuote = () => {
    if (!isProPlan) {
      setModalMoreOptionsMobile(false)
      return dispatch(setProModalOpen())
    }
    logEvent(quotationTracker.actions.newQuotation)

    setLoadingCreateQuote(true)

    API.post('/proposal/create/dimensioning/quotation', {
      potency: activeSizing.potenciaCC / 1000,
      structure: activeSizing.estrutura,
      dimensioning_id: activeSizing.id,
    })
      .then((res) => {
        setLoadingCreateQuote(false)
        dispatch(ProposalAction.SaveQuoteRedux(res.data))
        setValueTab(quotes.length)
        onSuccessOpenToast('Cotação criada com sucesso!')
        setModalMoreOptionsMobile(false)
        API.get(`/proposal/quotation/roi/calculate/${res.data.quotation.id}`)

        API.post('/proposal/quotation/budget/finalized', {
          quotation_id: res.data.quotation.id,
        }).then((response) => {
          dispatch(ProposalAction.UpdateQuoteDataRedux(response.data.quotation))
        })
      })
      .catch(({ response }) => {
        const { message = '' } = response?.data || {}
        setLoadingCreateQuote(false)
        onErrorOpenToast(message || 'Não foi possível criar cotação!')
      })
  }

  const handleOrderProduct = (product) => {
    const newArray = [...product]
    // eslint-disable-next-line array-callback-return
    newArray.sort((a, b) => {
      if (
        a.categoria == 62 ||
        a.categoria == 61 ||
        a.categoria == 66 ||
        a.categoria == 63
      ) {
        if (a.categoria == 62) {
          return -1
        }
        if (b.categoria == 62) {
          return 1
        }
        if (a.categoria == 61) {
          return -1
        }
        if (b.categoria == 61) {
          return 1
        }
        if (a.categoria == 66) {
          return -1
        }
        if (b.categoria == 66) {
          return 1
        }
        if (a.categoria == 63) {
          return -1
        }
        if (b.categoria == 63) {
          return 1
        }
      } else {
        return 1
      }
    })
    return newArray
  }

  const handleChange = (event) => {
    logEvent(quotationTracker.actions.changeQuotationTab, {
      origin: '/dimensionar - Cotar e dimensionar',
      tab: `Cotação ${event + 1}`,
    })
    setValueTab(event)
  }

  const [loadingInverters, setLoadingInverters] = useState(false)

  const getInvertersBrands = (type) => {
    setLoadingInverters(true)

    API.get(`/store/brands?micro=${type}`)
      .then((res) => {
        setInvertersBrands(res.data)
        setLoadingInverters(false)
      })
      .catch(() => {
        setLoadingInverters(false)
      })
  }

  const getBrands = () => {
    API.get('/loja/marcas?onlyVisible=1').then((res) => {
      setBrands(res.data.marcas)
      dispatch(ProposalAction.SaveBrandsRedux(res.data.marcas))
    })
  }

  const getClients = async () => {
    const allCustomers = await getAllCustomers()

    dispatch(ProposalAction.SaveClientsRedux(allCustomers))
    setClients(allCustomers)

    return allCustomers
  }

  useEffect(() => {
    if (!proposal.nivelLogin) {
      getNivel()
    }
    if (proposal.brands.length == 0) {
      getBrands()
    }
    if (proposal && proposal.clients.length) {
      setClients(proposal.clients)
    } else {
      getClients()
    }
    if (invertersBrands.length == 0) {
      getInvertersBrands(0)
    }
  }, [])

  useEffect(() => {
    if (!upXl) {
      setSizingWidth('360px')
    } else {
      setSizingWidth('388px')
    }
  }, [])

  useEffect(() => {
    setShowAlertDiferency(!downSm)
  }, [valueTab, downSm])

  const [plan, setPlan] = useState()

  const handleRedefine = () => {
    setLoadingDefaultFilter(true)

    const actualQuotation = quotes.find((quote, i) => i == valueTab)

    API.post('/proposal/quotation/redefine', {
      quotation_id: actualQuotation.quotation.id,
    })
      .then(() => {
        setChangedFilter(false)
        setModalDefaultFilter(false)
        setLoadingDefaultFilter(false)
        updateActualQuote()
        onSuccessOpenToast('Filtro redefinido com sucesso!')
      })
      .catch(() => {
        setLoadingDefaultFilter(false)
      })
  }

  const handleFilter = () => {
    setLoadingFilter(true)
    const actualQuotation = quotes.find((quote, i) => i == valueTab)

    API.post('/proposal/quotation/filter', {
      quotation_id: actualQuotation.quotation.id,
      quotation_type: quotationTypeFilter,
      locks: lockedProducts,
      transformer: checkedTransfomer,
      inverter: {
        type: inversorTypeFilter == 0 ? 'inversor' : 'micro',
        brands: inversorsBrandFilter,
      },
      module: {
        brands: modulesBrandFilter,
        max_potency: toPotency,
        min_potency: fromPotency,
      },
    })
      .then(() => {
        setChangedFilter(false)
        setLoadingFilter(false)
        dispatch(
          ProposalAction.SaveQuoteFilter({
            quotation_type: quotationTypeFilter,
            locks: lockedProducts,
            transformer: checkedTransfomer,
            inverter: {
              type: inversorTypeFilter == 0 ? 'inversor' : 'micro ',
              brands: inversorsBrandFilter,
            },
            module: {
              brands: modulesBrandFilter,
              max_potency: toPotency,
              min_potency: fromPotency,
            },
          }),
        )
        updateActualQuote()
        onSuccessOpenToast('Filtro aplicado com sucesso!')
      })
      .catch(() => {
        setLoadingFilter(false)
        onErrorOpenToast('Não foi possível aplicar o filtro!')
      })
  }

  useEffect(() => {
    const actualQuotation = quotes?.find((quote, i) => i == valueTab)
    setPlan(actualQuotation?.quotation?.discount_plan)
  }, [valueTab, quotes])

  useEffect(() => {
    const brandsFilInv = []
    const brandsFilMol = []

    invertersBrands.map((brand) => brandsFilInv.push(brand.id))

    setInversorsBrandFilter([...brandsFilInv])
    setModulesBrandFilter([...brandsFilMol])
  }, [invertersBrands])

  const userProfile = useSelector((state) => state.ReducerProfile.userProfile)

  useEffect(() => {
    if (admin) {
      dispatch(ProposalAction.SetIsAdmin(true))
    }
  }, [admin])

  const [integratorInfos, setIntegratorInfos] = useState()
  const [userInfos, setUserInfos] = useState()

  useEffect(() => {
    API.get(`/proposal/extra-configs/${proposal.proposal.id}`).then(
      (response) => {
        dispatch(SaveExtraConfigsRedux(response.data))
      },
    )
  }, [])

  const getIntegratorInfos = () => {
    setIntegratorInfos(userProfile)
  }

  const getUserInfos = useCallback(() => {
    setUserInfos(userProfile)
  }, [userProfile])

  useEffect(() => {
    getUserInfos()
  }, [getUserInfos])

  useEffect(() => {
    const actualQuote = quotes.find((quote, i) => i == valueTab)

    if (actualQuote?.quotation?.integrator_id) {
      getIntegratorInfos(actualQuote?.quotation?.integrator_id)
    }
  }, [quotes, valueTab])

  function handleSendProposal() {
    if (hasSomeError) {
      return
    }

    const actualQuotation = quotes.find((quote, i) => i == valueTab)

    window.open(
      `/visualizar/cotacaoV2/${actualQuotation.dimensioning.proposal_id}`,
      '_blank',
    )
  }

  return (
    <>
      <div
        style={{ height: '100%', position: 'relative', overflowY: 'hidden' }}
      >
        <div
          style={{
            width:
              downSm || currentEnterpriseConfigs?.hideQuotationFilterColumn
                ? '100%'
                : `calc(100% - ${sizingWidth})`,
            maxHeight: 'calc(var(--app-height) - 80px)',
            margin: '0px',
            overflowX: 'hidden',
            overflowY: 'auto',
            transition: 'width 0.3s ease-in-out 0s',
            height: '-webkit-fill-available',
          }}
          spacing={2}
        >
          {/* <PerfectScrollbar> */}
          <div style={{ padding: downSm ? '24px' : '24px 32px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                type={downSm ? 'link_small' : 'display_x_small_bold'}
                color="grayscale"
                colorWeight="700"
              >
                Equipamentos
              </Typography>
              <div style={{ display: 'flex', alignItems: downSm && 'center' }}>
                <Hidden xsDown>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      minWidth: '260px',
                    }}
                  >
                    {!proposal?.proposal?.customer_id && !comparacao && (
                      <Tooltip title="Baixar PDF">
                        <div style={{ display: 'flex', marginLeft: '16px' }}>
                          <DownloadIcon
                            onClick={handlePdf}
                            disabled={hasSomeError}
                            width="24"
                            color={palette.grayscale[hasSomeError ? 400 : 800]}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {admin && !proposal?.proposal?.customer_id && (
                      <Tooltip title="Configurações de adminstrador">
                        <div style={{ display: 'flex', marginLeft: '16px' }}>
                          <ProfileIcon
                            onClick={() => setAdminModal(true)}
                            width="24"
                            color={palette.grayscale[800]}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {admin && (
                      <Tooltip title="Enviar">
                        <div style={{ display: 'flex', marginLeft: '16px' }}>
                          <SendIcon
                            onClick={handleSendProposal}
                            disabled={hasSomeError}
                            width="24"
                            color={palette.grayscale[hasSomeError ? 400 : 800]}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    {currentEnterpriseConfigs?.quotationNegociation &&
                      admin && (
                        <Tooltip title="Negociação interna">
                          <div style={{ display: 'flex', marginLeft: '16px' }}>
                            <CommentIcon
                              id="stepTwo_button_internalNegotiation"
                              width="24"
                              color={palette.grayscale[800]}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalPloomes(true)
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                  </div>
                </Hidden>
                <Hidden smUp>
                  {!proposal?.proposal?.customer_id && (
                    <div style={{ display: 'flex', marginLeft: '16px' }}>
                      <Tooltip title="Baixar PDF">
                        <DownloadIcon
                          onClick={handlePdf}
                          disabled={hasSomeError}
                          width="24"
                          color={palette.grayscale[hasSomeError ? 400 : 800]}
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {admin && (
                    <div style={{ display: 'flex', marginLeft: '16px' }}>
                      <Tooltip title="Configurações de adminstrador">
                        <ProfileIcon
                          onClick={() => setAdminModal(true)}
                          width="24"
                          color={palette.grayscale[800]}
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {admin && (
                    <div style={{ display: 'flex', marginLeft: '16px' }}>
                      <Tooltip title="Enviar">
                        <SendIcon
                          onClick={handleSendProposal}
                          width="24"
                          color={palette.grayscale[hasSomeError ? 400 : 800]}
                          style={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </Hidden>
              </div>
            </div>
            <div>
              <Spacer size="16" direction="horizontal" />
            </div>
            {comparacao ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    type={downSm ? 'text_small' : 'text_large'}
                    color="grayscale"
                    colorWeight={downSm ? '800' : '700'}
                  >
                    Modo comparação
                  </Typography>
                  <Tooltip title="Sair do modo comparação">
                    <div style={{ display: 'flex' }}>
                      {downSm && (
                        <CircleActionsCloseIcon
                          onClick={() => {
                            setComparacao(false)
                          }}
                          width="24"
                          color={palette.red[300]}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  </Tooltip>
                  {!downSm && (
                    <div style={{ display: 'flex' }}>
                      <Hidden lgDown>
                        <Button
                          id="stepTwo_button_addNewQuotation"
                          onClick={() => {
                            handleCreateQuote()
                          }}
                          noMargin
                          size="small"
                          variant="outlined"
                          isLoading={loadingCreateQuote}
                        >
                          Adicionar nova cotação
                        </Button>
                        <Spacer size="16" direction="vertical" />
                        <Button
                          onClick={() => {
                            comparacao
                              ? setComparacao(false)
                              : setComparacao(true)
                          }}
                          noMargin
                          size="small"
                          variant="outlined"
                        >
                          Sair do modo comparação
                        </Button>
                      </Hidden>
                      <Hidden xlUp>
                        <Tooltip title="Nova cotação">
                          <div style={{ display: 'flex' }}>
                            {loadingCreateQuote ? (
                              <CircularProgress
                                disableShrink
                                style={{ color: palette.primary[300] }}
                                size={20}
                                thickness={4}
                              />
                            ) : (
                              <ActionPlusIcon
                                width="24"
                                color={palette.black}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleCreateQuote()}
                              />
                            )}
                          </div>
                        </Tooltip>
                        <Spacer size="24" direction="vertical" />
                        <Tooltip title="Sair do modo comparação">
                          <div style={{ display: 'flex' }}>
                            <CircleActionsCloseIcon
                              width="24"
                              color={palette.red[300]}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                comparacao
                                  ? setComparacao(false)
                                  : setComparacao(true)
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Hidden>
                    </div>
                  )}
                </div>
                {downSm ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      overflowY: 'auto',
                      height: `calc(var(--app-height) - ${
                        isOpenMobile
                          ? downXs
                            ? '322px'
                            : '354px'
                          : downXs
                          ? '301px'
                          : '331px'
                      })`,
                      marginTop: '24px',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      style={{
                        height: 'fit-content',
                        justifyContent: 'center',
                        marginBottom: '8px',
                      }}
                      noMargin
                      fullWidth
                      size="small"
                      variant="outlined"
                      onClick={() => handleCreateQuote()}
                      startIcon={
                        <ActionPlusIcon
                          width="16"
                          color={palette.primary[300]}
                        />
                      }
                      isLoading={loadingCreateQuote}
                    >
                      Nova cotação
                    </Button>
                    {quotes
                      ?.filter(
                        (quoteFilter) => !quoteFilter?.quotation?.filter_stock,
                      )
                      ?.map((quote, i) => (
                        <Grid key={i} container style={{ marginBottom: '8px' }}>
                          <Grid item xs={12}>
                            <Paper
                              boxShadow="small"
                              style={{ padding: '16px' }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  type="link_medium"
                                  color="primary"
                                  colorWeight="300"
                                >
                                  {`Cotação ${i + 1}`}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                  <EditIcon
                                    onClick={() => {
                                      setValueTab(i)
                                      setComparacao(false)
                                    }}
                                    width="24"
                                    color={palette.grayscale[700]}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <Spacer size="24" direction="vertical" />
                                  <DeleteIcon
                                    width="24"
                                    color={palette.red[300]}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      handleOpenDeleteModal(quote)
                                    }}
                                  />
                                </div>
                              </div>
                              <Spacer size="4" direction="horizontal" />
                              <div style={{ display: 'flex' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                  }}
                                >
                                  <Typography
                                    type="text_x_small"
                                    color="grayscale"
                                    colorWeight="700"
                                  >
                                    Potência
                                  </Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="link_large"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {handlePotency(
                                        quote.quotation.added_items
                                          ? filterAddedItems(
                                              quote.kit,
                                              quote.quotation.added_items,
                                            )
                                          : quote.kit,
                                      )}
                                    </Typography>
                                    <Spacer size="4" direction="vertical" />
                                    <Typography
                                      style={{ marginBottom: '4px' }}
                                      type="text_x_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      kWp
                                    </Typography>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                  }}
                                >
                                  <Typography
                                    type="text_x_small"
                                    color="grayscale"
                                    colorWeight="700"
                                  >
                                    Sobrecarga
                                  </Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="link_large"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {handleOverload(
                                        quote.quotation.added_items
                                          ? filterAddedItems(
                                              quote.kit,
                                              quote.quotation.added_items,
                                            )
                                          : quote.kit,
                                      )}
                                    </Typography>
                                    <Spacer size="4" direction="vertical" />
                                    <Typography
                                      style={{ marginBottom: '4px' }}
                                      type="text_x_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      %
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                              <Spacer size="4" direction="horizontal" />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  Valor total:
                                </Typography>
                                <Spacer size="8" direction="horizontal" />
                                <Typography
                                  type="display_x_small_bold"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  {parseBRL(
                                    parseFloat(
                                      quote?.quotation.equipment_value,
                                    ),
                                  )}
                                </Typography>
                              </div>
                            </Paper>
                          </Grid>
                        </Grid>
                      ))}
                  </div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      overflowX: 'overlay',
                      marginTop: '24px',
                      marginBottom: '222px',
                    }}
                  >
                    <PerfectScrollbar>
                      <div
                        style={{
                          display: 'flex',
                          height: downSm
                            ? '100%'
                            : 'calc(var(--app-height) - 400px)',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <Paper
                            onClick={() => handleCreateQuote()}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background: 'none',
                              border: `2px solid ${palette.primary[300]}`,
                              borderRadius: '16px',
                              minWidth: '88px',
                              height: 'calc(var(--app-height) - 401px)',
                              cursor: 'pointer',
                              position: 'fixed',
                            }}
                          >
                            {loadingCreateQuote ? (
                              <CircularProgress
                                disableShrink
                                style={{ color: '#1335c6' }}
                                size={24}
                                thickness={4}
                              />
                            ) : (
                              <ActionPlusIcon
                                width="24"
                                color={palette.primary[300]}
                              />
                            )}
                          </Paper>
                        </div>
                        <Spacer
                          size="104"
                          direction="vertical"
                          style={{ minWidth: '104px' }}
                        />
                        <div
                          style={{
                            backgroundColor: '#F7F7FC',
                            zIndex: 0,
                            display: 'flex',
                            height: 'fit-content',
                            paddingBottom: '4px',
                          }}
                        >
                          {quotes
                            ?.filter(
                              (quoteFilter) =>
                                !quoteFilter?.quotation?.filter_stock,
                            )
                            ?.map((quote, i) => (
                              <div
                                key={i}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginRight: '16px',
                                  height: 'auto',
                                }}
                              >
                                <Paper
                                  boxShadow="small"
                                  style={{
                                    height: 'auto',
                                    width: '416px',
                                    padding: '16px',
                                    paddingBottom: '24px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Typography
                                      type="link_large"
                                      color="primary"
                                      colorWeight="300"
                                    >
                                      {`Cotação ${i + 1}`}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                      <Tooltip title="Editar cotação">
                                        <div style={{ display: 'flex' }}>
                                          <EditIcon
                                            onClick={() => {
                                              setValueTab(i)
                                              setComparacao(false)
                                            }}
                                            width="24"
                                            color={palette.grayscale[800]}
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </div>
                                      </Tooltip>
                                      <Spacer size="24" direction="vertical" />
                                      <Tooltip title="Excluir cotação">
                                        <div style={{ display: 'flex' }}>
                                          <DeleteIcon
                                            onClick={() => {
                                              handleOpenDeleteModal(quote)
                                            }}
                                            width="24"
                                            color={palette.red[300]}
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <Spacer size="8" direction="horizontal" />
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="text_small"
                                      color="grayscale"
                                      colorWeight="600"
                                    >
                                      Potência do sistema:
                                    </Typography>
                                    <Spacer size="8" direction="vertical" />
                                    <Typography
                                      type="display_x_small_bold"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {handlePotency(
                                        quote?.quotation?.added_items
                                          ? filterAddedItems(
                                              quote?.kit,
                                              quote?.quotation?.added_items,
                                            )
                                          : quote?.kit,
                                      )}
                                    </Typography>
                                    <Typography
                                      type="text_x_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      kWp
                                    </Typography>
                                  </div>
                                  <Spacer size="12" direction="horizontal" />
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="text_small"
                                      color="grayscale"
                                      colorWeight="600"
                                    >
                                      Sobrecarga:
                                    </Typography>
                                    <Spacer size="8" direction="vertical" />
                                    <Typography
                                      type="display_x_small_bold"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {handleOverload(
                                        quote?.quotation?.added_items
                                          ? filterAddedItems(
                                              quote?.kit,
                                              quote?.quotation?.added_items,
                                            )
                                          : quote?.kit,
                                      )}
                                    </Typography>
                                    <Typography
                                      type="text_x_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      %
                                    </Typography>
                                  </div>
                                  <Spacer size="12" direction="horizontal" />
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    }}
                                  >
                                    <Typography
                                      type="text_small"
                                      color="grayscale"
                                      colorWeight="600"
                                    >
                                      Valor total:
                                    </Typography>
                                    <Spacer size="8" direction="vertical" />
                                    <Typography
                                      type="display_x_small_bold"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {parseBRL(
                                        parseFloat(
                                          quote?.quotation?.equipment_value,
                                        ),
                                      )}
                                    </Typography>
                                  </div>
                                  <Spacer size="16" direction="horizontal" />
                                  <div>
                                    <Typography type="link_medium">
                                      Produtos
                                    </Typography>
                                    <Spacer size="4" direction="horizontal" />
                                    {handleOrderProduct(quote?.kit).map(
                                      (product, index) => (
                                        <div key={index}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              alignItems: 'center',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }}
                                            >
                                              <Avatar
                                                src={product?.url}
                                                variant="rounded"
                                                style={{
                                                  height: '56px',
                                                  width: '56px',
                                                  borderRadius: '8px',
                                                  marginRight: '16px',
                                                }}
                                              />
                                              <Typography
                                                type="text_small"
                                                color="grayscale"
                                                colorWeight="800"
                                              >
                                                {product?.titulo}
                                              </Typography>
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginLeft: '16px',
                                              }}
                                            >
                                              <Typography
                                                type="text_large"
                                                color="grayscale"
                                                colorWeight="800"
                                              >
                                                {product?.qtde}
                                              </Typography>
                                              <Typography
                                                type="text_x_small"
                                                color="grayscale"
                                                colorWeight="700"
                                              >
                                                un
                                              </Typography>
                                            </div>
                                          </div>
                                          {quote?.kit?.length != index + 1 && (
                                            <>
                                              <Spacer
                                                size="16"
                                                direction="horizontal"
                                                style={{
                                                  borderBottom: `2px solid ${palette.grayscale[300]}`,
                                                }}
                                              />
                                              <Spacer
                                                size="16"
                                                direction="horizontal"
                                              />
                                            </>
                                          )}
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </Paper>
                              </div>
                            ))}
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </div>
                )}
              </>
            ) : (
              <>
                {(!quoteIdEditing || !completeQuoteEdit) && (
                  <Hidden xsDown>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          overflowX: 'auto',
                          width: '-webkit-fill-available',
                        }}
                      >
                        <TabList
                          value={valueTab}
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          titles={
                            quoteIdEditing
                              ? quotes
                                  ?.filter(
                                    (quote) => quote?.id == quoteIdEditing,
                                  )
                                  ?.map((quote, i) => `Cotação ${i + 1}`)
                              : quotes?.map((quote, i) => `Cotação ${i + 1}`)
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: upXl
                            ? '-webkit-fill-available'
                            : 'fit-content',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          marginLeft: '24px',
                        }}
                      >
                        <Hidden xlUp>
                          <Tooltip title="Comparar">
                            <div style={{ display: 'flex' }}>
                              <CompareIcon
                                onClick={() => {
                                  logEvent(configTracker.actions.compare)

                                  quotes.length == 1
                                    ? handleMessageOneQuote()
                                    : comparacao
                                    ? setComparacao(false)
                                    : setComparacao(true)
                                }}
                                width="24"
                                color={palette.black}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </Tooltip>
                          <Spacer size="24" direction="vertical" />
                          <Tooltip title="Adicionar nova cotação">
                            <div style={{ display: 'flex' }}>
                              {loadingCreateQuote ? (
                                <CircularProgress
                                  disableShrink
                                  style={{ color: palette.primary[300] }}
                                  size={24}
                                  thickness={4}
                                />
                              ) : (
                                <ActionPlusIcon
                                  onClick={() => handleCreateQuote()}
                                  width="24"
                                  color={palette.black}
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </div>
                          </Tooltip>
                          <Spacer size="24" direction="vertical" />
                          <Tooltip title="Deletar cotação">
                            <div style={{ display: 'flex' }}>
                              <DeleteIcon
                                width="24"
                                color={palette.red[300]}
                                style={{ cursor: 'pointer' }}
                                onClick={handleDeleteCurrentQuotation}
                              />
                            </div>
                          </Tooltip>
                        </Hidden>
                        <Hidden lgDown>
                          <div style={{ display: 'flex' }}>
                            <Button
                              onClick={() => {
                                logEvent(configTracker.actions.compare)
                                comparacao
                                  ? setComparacao(false)
                                  : setComparacao(true)
                              }}
                              noMargin
                              size="small"
                              variant="outlined"
                              style={{ marginRight: '16px' }}
                              disabled={quotes.length == 1}
                            >
                              Comparar
                            </Button>
                            <Button
                              onClick={() => handleCreateQuote()}
                              noMargin
                              size="small"
                              variant="outlined"
                              style={{ marginRight: '16px' }}
                              isLoading={loadingCreateQuote}
                            >
                              Adicionar nova cotação
                            </Button>
                            <Button
                              id="stepTwo_button_deleteQuotation"
                              noMargin
                              size="small"
                              variant="outlined"
                              color="red"
                              colorWeight="300"
                              disabled={quotes.length == 1}
                              onClick={handleDeleteCurrentQuotation}
                            >
                              Deletar cotação
                            </Button>
                          </div>
                        </Hidden>
                      </div>
                    </div>
                  </Hidden>
                )}
                {(!quoteIdEditing || !completeQuoteEdit) && (
                  <Hidden smUp>
                    <div style={{ display: 'flex' }}>
                      <Input
                        select
                        value={valueTab}
                        setValue={(value) => setValueTab(parseInt(value))}
                        size="small"
                      >
                        {quotes?.map((quote, i) => (
                          <option key={i} value={i}>
                            {`Cotação ${i + 1}`}
                          </option>
                        ))}
                      </Input>
                      <Spacer size="16" direction="vertical" />
                      <MoreVertIcon
                        width="24"
                        color={palette.grayscale[700]}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setModalMoreOptionsMobile(true)}
                      />
                    </div>
                  </Hidden>
                )}
                <div>
                  <Spacer size="24" direction="horizontal" />
                </div>
                <QuoteTabContent
                  quotes={quotes}
                  valueTab={valueTab}
                  activeSizing={activeSizing}
                  outdatedQuotes={outdatedQuotes}
                  handleEditOverload={handleEditOverload}
                  showAlertDiferency={showAlertDiferency}
                  setShowAlertDiferency={setShowAlertDiferency}
                  clients={clients}
                  isDeletingMobile={isDeletingMobile}
                  setIsDeletingMobile={setIsDeletingMobile}
                  loadingProducts={loadingProducts}
                  handleUpdateKitPotency={handleUpdateKitPotency}
                  handleChangeQuantity={handleChangeQuantity}
                  loadingUpdateKitPotency={loadingUpdateKitPotency}
                  handleChangePotency={handleChangePotency}
                  loadingChangePotency={loadingChangePotency}
                  handleOrderProduct={handleOrderProduct}
                  setFilterMobile={setFilterMobile}
                  setModalAdicionarProduto={setModalAdicionarProduto}
                  setSizingModal={setSizingModal}
                  setOpenModalShipmentValue={setOpenModalShipmentValue}
                  newOverload={newOverload}
                  setNewOverload={setNewOverload}
                  filterAddedItems={filterAddedItems}
                  editingOverload={editingOverload}
                  proposal={proposal}
                  lockedProducts={lockedProducts}
                  setLockedProducts={setLockedProducts}
                  integratorInfos={userInfos}
                  setModalAttProduct={setModalAttProduct}
                  setModalDescricao={setModalDescricao}
                  setDatasheetProduct={setDatasheetProduct}
                  setProductToChange={setProductToChange}
                  onOpenDeleteProduct={handleOpenDeleteProduct}
                  setModalAlterar={setModalAlterar}
                  handleRemoveItem={handleRemoveItem}
                  setOutdatedProductToAdd={setOutdatedProductToAdd}
                  loadingRemoveItem={loadingRemoveItem}
                  addWorkDays={addWorkDays}
                />
              </>
            )}
          </div>
        </div>
        {(!downSm || filterMobile) &&
          !currentEnterpriseConfigs?.hideQuotationFilterColumn && (
            <div
              style={{
                width: downSm ? '100%' : sizingWidth,
                borderLeft: '1px solid #e2e6e7',
                height: '100%',
                position: 'absolute',
                top: '0px',
                right: '0px',
                transition: 'width 0.3s ease-in-out 0s',
                backgroundColor: '#F7F7FC',
              }}
            >
              <Header
                title={
                  <Typography
                    type="text_large"
                    color="grayscale"
                    colorWeight="700"
                  >
                    Filtros
                  </Typography>
                }
                size="small"
                zIndex={40}
                noMargin
                onBack={downSm ? () => setFilterMobile(false) : null}
              >
                <div style={{ display: 'flex' }}>
                  {!downSm && (
                    <div
                      style={{
                        display: 'flex',
                        marginRight: '16px',
                        alignItems: 'center',
                      }}
                    >
                      {loadingDefaultFilter ? (
                        <CircularProgress
                          disableShrink
                          style={{ color: palette.primary[300] }}
                          size={24}
                          thickness={4}
                        />
                      ) : (
                        <Tooltip title="Redefinir">
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <RefreshIcon
                              onClick={() =>
                                invertersBrands.length == 0 ||
                                loadingInverters ||
                                loadingProducts
                                  ? ''
                                  : setModalDefaultFilter(true)
                              }
                              style={{
                                cursor:
                                  invertersBrands.length == 0 ||
                                  loadingInverters ||
                                  loadingProducts
                                    ? ''
                                    : 'pointer',
                              }}
                              width="24"
                              color={
                                invertersBrands.length == 0 ||
                                loadingInverters ||
                                loadingProducts
                                  ? palette.grayscale[400]
                                  : palette.primary[300]
                              }
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {!downSm && (
                    <Button
                      size="small"
                      noMargin
                      onClick={handleFilter}
                      isLoading={loadingFilter}
                      disabled={!changedFilter}
                    >
                      Aplicar
                    </Button>
                  )}
                </div>
              </Header>
              <div style={{ marginTop: '66px' }} />
              <div
                style={{
                  height: 'calc(100% - 69px)',
                }}
              >
                <PerfectScrollbar>
                  <div style={{ padding: 32 }}>
                    <Filters
                      label="Inversores"
                      isOpen={filterInversorOpen}
                      setIsOpen={setFilterInversorOpen}
                    >
                      {filterInversorOpen && (
                        <div style={{ padding: '0 24px 24px 24px' }}>
                          <Divider
                            style={{
                              marginBottom: 24,
                              backgroundColor: palette.grayscale[300],
                              height: '2px',
                            }}
                          />
                          <GroupButtonMobile
                            value={inversorTypeFilter}
                            setValue={(value) => {
                              if (!loadingInverters) {
                                getInvertersBrands(value)
                                setInversorTypeFilter(value)
                              }
                              setChangedFilter(true)
                            }}
                            labels={['Inversor', 'Micro inversor']}
                          />
                          <Spacer size="24" direction="horizontal" />
                          <div>
                            <Typography
                              style={{ paddingLeft: '24px' }}
                              type="link_medium"
                              color="grayscale"
                              colorWeight="800"
                            >
                              Marca
                            </Typography>
                            <Spacer size="8" direction="horizontal" />
                            {invertersBrands?.length == 0 ||
                            loadingInverters ||
                            loadingProducts ? (
                              <Skeleton
                                key={Math.random()}
                                height="40px"
                                width="246px"
                              />
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      invertersBrands.length ==
                                      inversorsBrandFilter.length
                                    }
                                    size="small"
                                    onChange={() => {
                                      setChangedFilter(true)
                                      if (
                                        invertersBrands.length ==
                                        inversorsBrandFilter.length
                                      ) {
                                        setInversorsBrandFilter([])
                                      } else {
                                        const brandsFilInv = []
                                        invertersBrands.map((brand) =>
                                          brandsFilInv.push(brand.id),
                                        )
                                        setInversorsBrandFilter([
                                          ...brandsFilInv,
                                        ])
                                      }
                                    }}
                                  />
                                  <Spacer size="16" direction="vertical" />
                                  <Typography
                                    type="text_small"
                                    color="grayscale"
                                    colorWeight="700"
                                  >
                                    Selecionar todos
                                  </Typography>
                                </div>
                                <Divider
                                  style={{
                                    margin: '8px 0px',
                                    backgroundColor: palette.grayscale[300],
                                    height: '2px',
                                  }}
                                />
                                {invertersBrands?.map((brand, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '12px',
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      key={brands.id}
                                      checked={
                                        inversorsBrandFilter.findIndex(
                                          (brandd) => brandd == brand.id,
                                        ) != -1
                                      }
                                      onChange={() => {
                                        setChangedFilter(true)
                                        const index =
                                          inversorsBrandFilter.findIndex(
                                            (brandd) => brandd == brand.id,
                                          )
                                        if (index != -1) {
                                          const newArr = inversorsBrandFilter
                                          newArr.splice(index, 1)
                                          setInversorsBrandFilter([...newArr])
                                        } else {
                                          const newArr = inversorsBrandFilter
                                          newArr.push(brand.id)
                                          setInversorsBrandFilter([...newArr])
                                        }
                                      }}
                                    />
                                    <Spacer size="16" direction="vertical" />
                                    <Typography
                                      type="text_small"
                                      color="grayscale"
                                      colorWeight="700"
                                    >
                                      {brand.nome}
                                    </Typography>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Filters>

                    <Spacer size="16" direction="horizontal" />
                    <Filters
                      label="Módulos"
                      isOpen={filterModulosOpen}
                      setIsOpen={setFilterModulosOpen}
                    >
                      {filterModulosOpen && (
                        <div style={{ padding: '0 24px 24px 24px' }}>
                          <Divider
                            style={{
                              marginBottom: 24,
                              backgroundColor: palette.grayscale[300],
                              height: '2px',
                            }}
                          />
                          <div>
                            <Typography
                              style={{ paddingLeft: '24px' }}
                              type="link_medium"
                              color="grayscale"
                              colorWeight="800"
                            >
                              Potência
                            </Typography>
                            <Spacer size="8" direction="horizontal" />
                            <Input
                              placeholder="de"
                              adornment="W"
                              label="Mínimo"
                              adornmentPosition="right"
                              value={fromPotency}
                              setValue={(value) => {
                                setChangedFilter(true)
                                if (value.length <= 2) {
                                  setFromPotency(value)
                                } else if (value < 300) {
                                  setFromPotency(300)
                                } else if (value > 600) {
                                  setFromPotency(600)
                                } else {
                                  setFromPotency(value)
                                }
                              }}
                              type="number"
                              size="small"
                              minDate={300}
                              maxDate={600}
                            />
                            <Spacer size="8" direction="horizontal" />
                            <Input
                              placeholder="até"
                              adornment="W"
                              label="Máximo"
                              adornmentPosition="right"
                              value={toPotency}
                              setValue={(value) => {
                                setChangedFilter(true)
                                if (value.length <= 2) {
                                  setToPotency(value)
                                } else if (value < 300) {
                                  setToPotency(300)
                                } else if (value > 600) {
                                  setToPotency(600)
                                } else {
                                  setToPotency(value)
                                }
                              }}
                              type="number"
                              size="small"
                              minDate={300}
                              maxDate={600}
                            />
                          </div>
                        </div>
                      )}
                    </Filters>

                    {downSm && (
                      <Button
                        size="small"
                        noMargin
                        style={{ marginTop: '48px' }}
                        fullWidth
                        variant="outlined"
                        onClick={() => setModalDefaultFilter(true)}
                        isLoading={loadingDefaultFilter}
                      >
                        Redefinir
                      </Button>
                    )}
                    {downSm && (
                      <Button
                        size="small"
                        style={{ marginTop: '8px' }}
                        noMargin
                        fullWidth
                        onClick={handleFilter}
                        isLoading={loadingFilter}
                      >
                        Aplicar
                      </Button>
                    )}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          )}

        <Backdrop className={classes.backdrop} open={openLoadingPdf}>
          <CircularProgress style={{ marginBottom: '20px' }} color="inherit" />
          Carregando PDF
        </Backdrop>

        {selectClientModal && (
          <ModalSelectClient
            proposal={proposal}
            getClients={getClients}
            clients={clients}
            selectClientModal={selectClientModal}
            setSelectClientModal={setSelectClientModal}
          />
        )}

        {openModalShipmentValue && (
          <ModalFrete
            loadingDelivery={loadingDelivery}
            actualQuote={quotes.find((quote, i) => i == valueTab)}
            handleChangeShipmentValue={handleChangeShipmentValue}
            openModalFrete={openModalShipmentValue}
            setOpenModalFrete={setOpenModalShipmentValue}
          />
        )}
        {openModalSalvarCotacao && (
          <ModalSalvarCotacao
            loadingSave={loadingSave}
            handleSaveCotation={handleSaveCotation}
            quotes={quotes}
            quoteToSave={{}}
            openModalSalvarCotacao={openModalSalvarCotacao}
            setOpenModalSalvarCotacao={setOpenModalSalvarCotacao}
          />
        )}
        {quotationDelete && (
          <QuotationDeleteModal
            quotation={quotationDelete}
            onClose={handleCloseDeleteModal}
            onSuccess={handleSuccessDeleteQuotation}
            onStopDelete={checkSingleQuotation}
          />
        )}
        {!filterMobile && (
          <>
            {loadingChangingQtd && (
              <LinearProgress
                style={{
                  backgroundColor: palette.primary[300],
                  zIndex: 1000,
                  position: 'absolute',
                  bottom: downSm ? (isOpenMobile ? '132px' : '114px') : '135px',
                  width:
                    downSm ||
                    currentEnterpriseConfigs?.hideQuotationFilterColumn
                      ? '100%'
                      : `calc(100% - ${sizingWidth})`,
                }}
              />
            )}
            <BottomMenu
              setQuoteIdEditing={setQuoteIdEditing}
              isOpenMobile={isOpenMobile}
              setIsOpenMobile={setIsOpenMobile}
              updateActualQuote={updateActualQuote}
              plan={plan}
              setSelectClientModal={setSelectClientModal}
              handleUpdateCart={handleUpdateCart}
              proposal={proposal}
              handleFinishQuote={handleFinishQuote}
              actualQuote={quotes.find((quote, i) => i == valueTab)}
              completeQuoteEdit={completeQuoteEdit}
              setCompleteQuoteEdit={setCompleteQuoteEdit}
              sizingWidth={sizingWidth}
              setModalSelecionarCotacoes={setModalSelecionarCotacoes}
              integratorInfos={integratorInfos}
              isCancelingQuotationOrProject={isCancelingQuotationOrProject}
            />
          </>
        )}
        {modalDescricao && (
          <ModalDescricao
            addWorkDays={addWorkDays}
            datasheetProduct={datasheetProduct}
            modalDescricao={modalDescricao}
            setModalDescricao={setModalDescricao}
          />
        )}

        {modalAlterar && (
          <ModalAlterarProduto
            isOpen={modalAlterar}
            product={productToChange}
            quotationId={quotes.find((_, i) => i === valueTab)?.quotation?.id}
            onChangeProduct={handleChangeProduct}
            onClose={() => setModalAlterar(false)}
          />
        )}
        {modalAdicionarProduto && (
          <ModalAdicionarProduto
            loadingAddProduct={loadingAddProduct}
            actualQuote={quotes.find((quote, i) => i == valueTab)}
            handleAddProduct={handleAddProduct}
            brands={brands}
            modalAdicionarProduto={modalAdicionarProduto}
            setModalAdicionarProduto={setModalAdicionarProduto}
            integratorInfos={userInfos}
          />
        )}
        {modalSelecionarCotacoes && (
          <ModalSelecionarCotacoes
            setActiveDimStep={setActiveDimStep}
            handlePotency={handlePotency}
            handleOverload={handleOverload}
            quotes={quotes}
            modalSelecionarCotacoes={modalSelecionarCotacoes}
            setModalSelecionarCotacoes={setModalSelecionarCotacoes}
          />
        )}
        {productToDelete && (
          <ProductRemoveModal
            product={productToDelete}
            quotationId={currentQuotationId}
            onClose={handleCloseDeleteProduct}
            onSuccess={handleCloseDeleteProduct}
          />
        )}
        {adminModal && (
          <DiscountModal
            quotationId={currentQuotationId}
            initialDiscount={currentQuotation?.quotation?.discount_percentage}
            onSuccess={handleSuccessSaveConfig}
            onClose={handleCloseDiscountModal}
          />
        )}

        {modalPloomes && (
          <ModalPloomes
            modalPloomes={modalPloomes}
            setModalPloomes={setModalPloomes}
            valuesPloomes={valuesPloomes}
            setValuesPloomes={setValuesPloomes}
            actionsPloomes={actionsPloomes}
            optionsCity={optionsCity}
          />
        )}

        {modalMoreOptionsMobile && (
          <ModalMoreOptionsMobile
            quotes={quotes}
            modalMoreOptionsMobile={modalMoreOptionsMobile}
            setModalMoreOptionsMobile={setModalMoreOptionsMobile}
            setComparacao={setComparacao}
            comparacao={comparacao}
            handleCreateQuote={handleCreateQuote}
            loadingCreateQuote={loadingCreateQuote}
            onDeleteCurrentQuotation={handleDeleteCurrentQuotation}
          />
        )}

        {modalDefaultFilter && (
          <ModalDefaultFilter
            loadingDefaultFilter={loadingDefaultFilter}
            handleRedefine={handleRedefine}
            setModalDefaultFilter={setModalDefaultFilter}
            modalDefaultFilter={modalDefaultFilter}
          />
        )}
        {modalAttProduct && (
          <ModalUpdateProductValue
            modalAttProduct={modalAttProduct}
            setModalAttProduct={setModalAttProduct}
            handleChangeQuantity={handleChangeQuantity}
            loadingAttProduct={loadingAttProduct}
            quoteIdEditing={quoteIdEditing}
            outdatedProductToAdd={outdatedProductToAdd}
          />
        )}

        {sizingModal && (
          <SizingModal sizing={sizingModal} setSizing={setSizingModal} />
        )}
      </div>
    </>
  )
}

export default StepTwo
