import { Modal } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Info } from 'lucide-react'
import * as S from './styles'

interface SaveAndQuitProjectModalProps {
  open?: boolean
  onOpenChange?: () => void
  onSuccess: () => void
  onClose?: () => void
  hasProviderError: boolean
  handleCancelProject: () => void
}

export function SaveAndQuitProjectModal({
  open,
  onOpenChange,
  onSuccess,
  onClose,
  hasProviderError = false,
  handleCancelProject,
}: SaveAndQuitProjectModalProps) {
  const title = hasProviderError
    ? 'Tem certeza que deseja cancelar o projeto?'
    : 'Tem certeza que deseja sair do projeto?'

  const description = hasProviderError
    ? 'Ao seguir em frente, você estará saindo do projeto sem salvar. O projeto será cancelado'
    : 'Salve este projeto na sua aba de projetos no menu lateral para revisitar mais tarde.'

  const cancelButtonTitle = hasProviderError
    ? 'Cancelar projeto'
    : 'Continuar editando'

  const continueButtonTitle = hasProviderError
    ? 'Continuar editando'
    : 'Salvar e sair'

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <S.Overlay />

        <S.Wrapper maxWidth="640px" maxHeight="90vh" id="modal-quit-quotation">
          <Modal.Close onClick={onClose} />
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Title>{title}</Modal.Title>
            <Modal.Description>{description}</Modal.Description>
          </Modal.Header>

          <S.Footer>
            <Button
              fullWidth
              variant="outline"
              onClick={hasProviderError ? handleCancelProject : onClose}
            >
              {cancelButtonTitle}
            </Button>

            <Button fullWidth onClick={hasProviderError ? onClose : onSuccess}>
              {continueButtonTitle}
            </Button>
          </S.Footer>
        </S.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
