import React from 'react'
import { Skeleton } from '@material-ui/lab'

export function SkeletonLoader() {
  return (
    <>
      <div className="skeleton-container">
        <div className="skeleton-group">
          <Skeleton variant="rect" width="100%" height={58} />
        </div>
        <div className="skeleton-group">
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
        </div>
        <div className="skeleton-group">
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
        </div>

        <div className="skeleton-group">
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
        </div>

        <div className="skeleton-group">
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
          <Skeleton variant="rect" width="100%" height={58} />
        </div>

        <div className="skeleton-group">
          <Skeleton variant="text" width={350} height={38} />
          <Skeleton
            className="skeleton-width-100"
            variant="rect"
            width="170px"
            height={46}
          />
        </div>
      </div>
    </>
  )
}
