import api from 'api'
import { QUOTATION_UPDATE_DELIVERY } from '../constants/endpointKeys'
import { type IPayloadQuotationUpdateDelivery } from './types'

export const updateDeliveryQuotation = async (
  data: IPayloadQuotationUpdateDelivery,
) => {
  return await api.post(QUOTATION_UPDATE_DELIVERY, data).then((response) => {
    return response.data
  })
}
