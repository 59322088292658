import { PROPOSAL_STATUS_ENUM } from 'enums/proposals/ProposalsStatusEnum'

export const useProposalBadge = (status: PROPOSAL_STATUS_ENUM) => {
  const {
    RASCUNHO,
    ENVIADA,
    EM_ANDAMENTO,
    ACEITA,
    RECUSADA,
    INICIADA,
    FINALIZADA,
  } = PROPOSAL_STATUS_ENUM

  const statusVariant = {
    [RASCUNHO]: {
      color: 'gray',
      text: 'Rascunho',
    },
    [ENVIADA]: {
      color: 'blue',
      text: 'Enviada',
    },
    [EM_ANDAMENTO]: {
      color: 'orange',
      text: 'Em andamento',
    },
    [ACEITA]: {
      color: 'green',
      text: 'Aceita',
    },
    [RECUSADA]: {
      color: 'red',
      text: 'Recusada',
    },
    [INICIADA]: {
      color: 'green',
      text: 'Iniciada',
    },
    [FINALIZADA]: {
      color: 'blue',
      text: 'Finalizada',
    },
  }

  return (
    statusVariant?.[status] || {
      color: 'blue',
      text: '-',
    }
  )
}
