import React from 'react'
import classNames from 'classnames'
import PropTypes, { oneOf } from 'prop-types'
import styles from './styles'

function Typography({
  color, colorWeight, children, type, ...props
}) {
  const classes = styles({ color, colorWeight })
  const TypographyClass = classNames(classes.typography, classes[type])

  return (
    <p {...props} className={TypographyClass}>
      {children}
    </p>
  )
}

Typography.propTypes = {
  color: oneOf([
    'primary',
    'secondary',
    'green',
    'orange',
    'red',
    'yellow',
    'grayscale',
  ]),
  type: PropTypes.oneOf([
    'display_huge',
    'display_large',
    'display_medium',
    'display_small',
    'display_x_small',
    'display_huge_bold',
    'display_large_bold',
    'display_medium_bold',
    'display_small_bold',
    'display_x_small_bold',
    'display_huge_x_bold',
    'display_large_x_bold',
    'display_medium_x_bold',
    'display_small_x_bold',
    'display_x_small_x_bold',
    'text_large',
    'text_medium',
    'text_small',
    'text_x_small',
    'link_large',
    'link_medium',
    'link_small',
    'link_x_small',
  ]),
  colorWeight: oneOf([
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
  ]),
}

Typography.defaultProps = {
  color: 'grayscale',
  type: 'text_medium',
  colorWeight: '800',
}

export default Typography
