import React from 'react'
import { CircleActionsAlertInfoIcon } from '@77sol/icons/dist'
import { Box } from '@material-ui/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { format } from 'date-fns'
import { parseBRL } from 'utils'
import { Badge } from '@77sol/core'
import { RemakeOrderBadge } from 'components/RemakeOrderBadge'
import { EmptyState, Loading } from 'components'
import { type IOrderDTO } from 'dto/OrderDTO'
import { useOrderList } from './hooks/useOrderList'

import orderStatus from 'views/Pedidos/orderStatus'
import useWindowSize from 'hooks/useWindowSize'
import * as S from './styles'
import { useParams } from 'react-router-dom'
import { type ORDER_STATUS_TRADUCTION_ENUM } from 'enums/order/OderStatusTraductionEnum'
import { EnumOrderStatus } from 'views/Pedidos/constants'
import { RoundedButton } from '../RoundedButton'
import { type IOrder } from 'domains/loja/loja-pedidos/services/types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MoreActionsMenu } from '../MoreActionsMenu'
import { useFetchOrders } from 'domains/loja/loja-pedidos/hooks/useOrders'
import { useAmplitude } from 'hooks/useAmplitude'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { ORDER_STATUS_CLIENT_ENUM } from 'enums/order/OderStatusClientEnum'

interface IOrderListProps {
  selectedOrder: IOrderDTO
  onClickOrder: (order: IOrderDTO) => void
  onClickCancelOrder: (id: string) => void
  onClickRemakeOrder: (order: IOrderDTO) => void
  onClickShareOrderTracking: (order: IOrderDTO) => void
  isOpenFilterSlide: boolean
}

const OrderList: React.FC<IOrderListProps> = ({
  selectedOrder,
  onClickOrder,
  onClickCancelOrder,
  onClickRemakeOrder,
  onClickShareOrderTracking,
  isOpenFilterSlide,
}) => {
  const { width } = useWindowSize()
  const { orderId = '' }: { orderId: string } = useParams()
  const { logEvent } = useAmplitude()
  const isDesktop = width > 1280
  const {
    ordersList = [],
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
    fetchNextPage: handleFetchNextOrdersPage,
    hasNextPage: hasMoreOrders = false,
  } = useFetchOrders()

  const {
    handleCancelOrder,
    isReserved,
    isClosed,
    listIsEmpty,
    orderIsSelected,
    handleClickTrackOrder,
    handlePendingOrder,
    handleRemakeOrder,
    isExpiredOrder,
    getRemakeOrderTooltip,
    handleDropdownClickTrackOrder,
  } = useOrderList({
    onClickCancelOrder,
    onClickOrder,
    onClickRemakeOrder,
    onClickShareOrderTracking,
    orders: ordersList as unknown as IOrderDTO[],
    selectedOrder,
  })

  if (listIsEmpty && !isLoadingOrders && !isFetchingOrders) {
    return (
      <Box width="100%">
        <EmptyState text="Sem pedidos recentes!" />
      </Box>
    )
  }

  const filterOrderList = (order: IOrder) => {
    if (!orderId) return true
    return Number(order.id) === Number(orderId)
  }

  const filterAndTitleHeight = isDesktop ? '356px' : '280px'

  return (
    <>
      {/* @ts-expect-error loadingprops */}
      <Loading
        isLoading={isLoadingOrders || (orderId && isFetchingOrders)}
        minHeight="250px"
      >
        <S.OrdersListContainer
          orderIsSelected={orderIsSelected}
          isOpenFilterSlide={isOpenFilterSlide}
        >
          {isDesktop && (
            <S.HeaderList orderIsSelected={orderIsSelected}>
              <span>ID</span>
              <span>Data</span>
              <span>Faturado para</span>
              {!orderIsSelected && <span>CPF/CNPJ</span>}
              {!orderIsSelected && <span>Status</span>}
              {!orderIsSelected && <span>Valor Total</span>}
            </S.HeaderList>
          )}
          <InfiniteScroll
            loader={<S.Loading />}
            height={`calc(var(--app-height) - ${filterAndTitleHeight})`}
            hasMore={hasMoreOrders}
            dataLength={ordersList.length}
            next={handleFetchNextOrdersPage}
          >
            {ordersList?.filter(filterOrderList).map((order: IOrder) => {
              const {
                id,
                codigo,
                data,
                total,
                nome_status,
                status,
                billing_name = '-',
                customer_document = '-',
              } = order

              const orderIsReserved = isReserved(nome_status)
              const orderIsCanceled = isClosed(
                status as ORDER_STATUS_TRADUCTION_ENUM,
              )

              const orderIsExpired =
                Number(status) === ORDER_STATUS_CLIENT_ENUM.PEDIDO_VENCIDO
              const orderIsDelivered =
                (status as EnumOrderStatus) === EnumOrderStatus.entregue

              const CAN_REMAKE_ORDER = isExpiredOrder(order)
              const remakeOrderTooltipMessage = getRemakeOrderTooltip(order)

              const orderSelected = selectedOrder?.id === id

              const orderHtmlId = orderSelected
                ? 'order-selected-from-list'
                : undefined

              const statusBadge =
                orderIsExpired && currentEnterpriseConfigs.canRemakeOrder ? (
                  <RemakeOrderBadge nome_status={nome_status} />
                ) : (
                  <Badge
                    fontWeight="700"
                    colorWeight="100"
                    label={nome_status}
                    // @ts-expect-error status
                    color={orderStatus[nome_status]?.color}
                  />
                )

              return (
                <S.ResponsiveCardOrder
                  key={id}
                  isActive={orderSelected}
                  id={orderHtmlId}
                  orderIsSelected={orderIsSelected}
                  isReserved={orderIsReserved}
                  onClick={() => {
                    onClickOrder(order as unknown as IOrderDTO)
                  }}
                >
                  <span className="orderId">#{codigo}</span>
                  <span className="date">
                    {format(new Date(data), 'dd/MM/yyyy')}
                  </span>
                  <span className="billing-name">{billing_name}</span>
                  {!orderIsSelected && <span>{customer_document}</span>}
                  {!orderIsSelected && (
                    <span className="badge">{statusBadge}</span>
                  )}
                  {!orderIsSelected && <span>{parseBRL(total)}</span>}
                  {!orderIsSelected && (
                    <S.IconsContainer>
                      <>
                        {orderIsReserved && (
                          <RoundedButton
                            color="red"
                            onClick={(event) => {
                              handlePendingOrder(
                                event,
                                order as unknown as IOrderDTO,
                              )
                            }}
                          >
                            <CircleActionsAlertInfoIcon />
                            Pendente
                          </RoundedButton>
                        )}
                        {orderIsDelivered && (
                          <RoundedButton>Documentos técnicos</RoundedButton>
                        )}
                        {!orderIsReserved &&
                          !orderIsExpired &&
                          !orderIsDelivered && (
                            <RoundedButton
                              onClick={(event) => {
                                handleClickTrackOrder(
                                  event,
                                  order as unknown as IOrderDTO,
                                )
                              }}
                              disabled={orderIsCanceled}
                            >
                              Rastrear
                            </RoundedButton>
                          )}
                        {orderIsExpired && (
                          <RoundedButton
                            disabled={!CAN_REMAKE_ORDER}
                            onClick={(event) => {
                              handleRemakeOrder(
                                event,
                                order as unknown as IOrderDTO,
                              )
                            }}
                            tooltipText={remakeOrderTooltipMessage}
                          >
                            Refazer pedido
                          </RoundedButton>
                        )}
                        <MoreActionsMenu
                          order={order}
                          orderIsCanceled={orderIsCanceled}
                          orderIsReserved={orderIsReserved}
                          onCancelOrder={handleCancelOrder}
                          onClickTrackOrder={handleDropdownClickTrackOrder}
                          onClickTalkToManager={() => {
                            logEvent(ordersTracker.actions.talkToManager, {
                              origin: '/pedidos',
                            })
                          }}
                          onDownloadInvoice={() => {
                            logEvent(fileTracker.actions.downloadFile, {
                              fileName: 'Fatura',
                              origin: '/pedidos',
                            })
                          }}
                          onDownlodManual={() => {
                            logEvent(fileTracker.actions.downloadFile, {
                              fileName: 'Manual do integrador',
                              origin: '/pedidos',
                            })
                          }}
                        />
                      </>
                    </S.IconsContainer>
                  )}
                </S.ResponsiveCardOrder>
              )
            })}
          </InfiniteScroll>
        </S.OrdersListContainer>
      </Loading>
    </>
  )
}

export default OrderList
