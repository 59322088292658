export const FINANCING_QUOTATION_COLUMNS = {
  ONE: ['ID'],
  LESS: ['ID', 'KwP', 'Cliente'],
  MORE: [
    'ID cotação',
    'Kwp',
    'Cliente',
    'Solicitante',
    'Simulações',
    'Última modificação',
    'Financiamento',
  ],
}

export const FINANCING_PER_VALUE_COLUMNS = {
  ONE: ['ID'],
  LESS: ['ID', 'Valor', 'Solicitante'],
  MORE: [
    'ID',
    'Valor',
    'Solicitante',
    'Simulações',
    'Última modificação',
    'Status',
  ],
}

export enum FINANCING_STAGE {
  FORMALIZACAO = 'formalizacao',
}

export enum FINANCING_STATUS {
  APROVADO = 'Aprovado',
}
