import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialTextField from '@material-ui/core/TextField'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import InputAdornment from '@material-ui/core/InputAdornment'
import styles from './styles'

function TextArea({
  caption,
  validationState,
  clearble,
  setValue,
  children,
  submit,
  ...props
}) {
  const classes = styles({ validationState })
  const TextAreaClass = classNames(classes.textArea)

  const TextAreaPropsDefault = {
    disableUnderline: true,
  }

  const TextAreaPropsRight = {
    disableUnderline: true,
    endAdornment: (
      <InputAdornment position="end">
        {clearble && props.value ? (
          <CloseRoundedIcon
            onClick={() => setValue('')}
            className={classes.closeadornment}
          />
        ) : (
          ''
        )}
      </InputAdornment>
    ),
  }

  const handleTextAreaProps = () => {
    if (clearble) {
      return TextAreaPropsRight
    }
    return TextAreaPropsDefault
  }

  return (
    <MaterialTextField
      {...props}
      className={TextAreaClass}
      variant="filled"
      fullWidth
      multiline
      onChange={(e) => setValue && setValue(e.target.value)}
      helperText={caption}
      SelectProps={{ native: true }}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          if (submit) {
            submit()
            ev.target.blur()
          }
        }
      }}
      InputProps={handleTextAreaProps()}
    >
      {children}
    </MaterialTextField>
  )
}

TextArea.propTypes = {
  caption: PropTypes.string,
  validationState: PropTypes.oneOf(['error', 'success', 'none']),
  clearble: PropTypes.bool,
  setValue: PropTypes.func,
  submit: PropTypes.func,
  label: PropTypes.string,
}

TextArea.defaultProps = {
  label: '',
  caption: '',
  validationState: 'none',
  clearble: true,
  setValue: null,
  submit: null,
}

export default TextArea
