import { Input } from '@77sol-ui/atoms'
import { AutoCompleteIntegrator } from '../../components/ui/AutoCompleteIntegrator'
import { useCreateFinancingPerValueFormContext } from '../../hooks/useCreateFinancingPerValueForm'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { ClearFieldsButton } from '../ui/ClearFieldsButton'
import { RadioPersonType } from '../ui/RadioPersonType'
import { MaskedMonetaryInput } from 'components/Controllers/MaskedMonetaryInput'
import * as S from './styles'

export function Commom() {
  const {
    register,
    control,
    formState: { errors },
  } = useCreateFinancingPerValueFormContext()

  const isAdmin = useIsAdmin()

  return (
    <S.Container>
      <section>
        <h3>Dados do financiamento</h3>

        <S.InputGroup>
          <MaskedMonetaryInput
            autoFocus
            label="Valor a Financiar"
            placeholder="R$0,00"
            error={errors.total_value?.message}
            control={control}
            name="total_value"
          />

          <Input
            type="text"
            error={errors.title?.message}
            {...register('title')}
            label="Título da Solicitação"
            placeholder="Insira o nome do projeto ou cliente"
          />
        </S.InputGroup>

        {isAdmin && (
          <AutoCompleteIntegrator
            label="Integrador Responsável"
            control={control}
            name="integrator"
            autoComplete="off"
            contentSide="top"
            offset={-20}
            error={errors.integrator?.id?.message}
          />
        )}
      </section>

      <section>
        <h3>Dados pessoais do cliente</h3>

        <div className="manage-person-type">
          <RadioPersonType />
          <ClearFieldsButton>Limpar dados</ClearFieldsButton>
        </div>
      </section>

      <section>
        <Input
          label="Nome do Solicitante"
          placeholder="José Maria"
          type="text"
          error={errors.client_name?.message}
          {...register('client_name')}
        />
      </section>
    </S.Container>
  )
}
