import styled from 'styled-components'

export const Container = styled.div`
  margin: 48px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  span {
    font-family: 'DM Sans';

    strong {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.blue[300]};
      cursor: pointer;
    }
  }
`
