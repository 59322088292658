import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export interface IWatchRoutesProps {
  routes: string[]
  active: boolean
  onAccessedTheRoute: (route: string) => void
  onNoRouteAccessed?: () => void
}

export function useWatchRoutes({
  routes,
  onAccessedTheRoute,
  active,
  onNoRouteAccessed,
}: IWatchRoutesProps) {
  const location = useLocation()

  useEffect(() => {
    if (active) {
      if (routes.includes(location.pathname)) {
        onAccessedTheRoute(location.pathname)
      } else {
        onNoRouteAccessed?.()
      }
    }
  }, [onAccessedTheRoute, onNoRouteAccessed, location, active, routes])
}
