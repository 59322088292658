import { makeStyles } from '@material-ui/core/styles'
import palette from 'app_palette'

const validationBorder = {
  error: `2px solid ${palette.red[300]} !important`,
  success: `2px solid ${palette.green[300]} !important`,
  none: `2px solid ${palette.primary[300]} !important`,
}

const validationBackground = {
  error: `${palette.red[50]} !important`,
  success: `${palette.green[50]} !important`,
  none: `${palette.grayscale[200]}`,
}

const validationLabelColor = {
  error: `${palette.red[400]} !important`,
  success: `${palette.green[400]} !important`,
  none: `${palette.grayscale[800]}`,
}

const validationTextColor = {
  error: `${palette.grayscale[800]} !important`,
  success: `${palette.grayscale[800]} !important`,
  none: `${palette.grayscale[800]}`,
}

const styles = makeStyles(theme => ({
  input: ({ size, validationState, label, adornmentPosition }) => ({
    '& .MuiFilledInput-root': {
      border:
        validationState === 'none' ? `none` : validationBorder[validationState],
      height: size === 'small' ? '56px' : '64px',
      backgroundColor:
        validationState === 'none'
          ? `${palette.grayscale[200]}`
          : validationBackground[validationState],
      borderRadius: '16px',
      fontWeight: 400,
    },
    '& .MuiFilledInput-input': {
      //padding: '6px 24px',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationTextColor[validationState],
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      padding:
        adornmentPosition === 'left'
          ? size === 'small'
            ? '16px 24px 16px 0px'
            : '20px 24px 20px 0px'
          : size === 'small'
          ? '16px 24px'
          : '20px 24px',
      paddingRight: adornmentPosition === 'right' && '0px',
      height: 'auto',
      paddingTop:
        label && (size === 'small' ? '24px !important' : '28px !important'),
    },
    '& .MuiSelect-root': {
      borderRadius: '16px',
      backgroundColor: 'nono',
      paddingTop:
        label && (size === 'small' ? '20px !important' : '20px !important'),
      paddingBottom:
        label && (size === 'small' ? '12px !important' : '16px !important'),
    },
    '& .MuiInputLabel-filled': {
      border: 'none !important',
      background: 'none !important',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
      top: '-5px',
      left: '12px',
      '&.MuiInputLabel-shrink': {
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
      },
    },
    '& .Mui-focused': {
      border:
        validationState === 'none'
          ? `2px solid ${palette.primary[300]}`
          : validationBorder[validationState],
      background:
        validationState === 'none'
          ? 'none !important'
          : validationBackground[validationState],
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationTextColor[validationState],
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
    },
    '& .Mui-disabled': {
      backgroundColor: `${palette.grayscale[200]}`,
      opacity: 0.5,
    },
    '& .MuiFormHelperText-contained': {
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '22px',
      letterSpacing: '0.25px',
      border: 'none !important',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
      background: 'none !important',
      marginTop: '8px',
    },
    '& .MuiInputAdornment-root': {
      lineHeight: '22px',
      letterSpacing: '0.25px',
      marginTop: '0px !important',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
    },
  }),
  closeIcon: ({ size, validationState }) => ({
    color:
      validationState === 'none'
        ? `${palette.grayscale[500]}`
        : validationLabelColor[validationState],
    fontiSize: '16px',
    cursor: 'pointer',
  }),
}))

export default styles
