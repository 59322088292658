import { apiV2 } from 'apis/apiV2'
import { GET_RANKING_SUMMARY } from '../constants/endpointKeys'
import { type IRakingSummaryAllResponse } from './types'

export const rankingSummaryAll = {
  get: async () => {
    const { data } = await apiV2.get<IRakingSummaryAllResponse>(
      GET_RANKING_SUMMARY,
    )
    return data
  },
}
