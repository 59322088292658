export const CNPJ_DOCUMENTS = [
  {
    title: 'Cartão CNPJ',
    description:
      'Comprovante de Cadastro Nacional de Pessoa Jurídica emitido pela Receita Federal.',
    type: 'cnpj',
    hasBeenSent: false,
    documentType: 'cnpj',
  },
  {
    title: 'Contrato social e ou procuração',
    description:
      'O contrato social é um documento onde constam as regras e as condições sob as quais a empresa funcionará e onde estão estabelecidos os direitos e as obrigações para cada um dos proprietários que compõem a sociedade.',
    type: 'social_contract',
    hasBeenSent: false,
    documentType: 'cnpj',
  },
  {
    title: 'Documento de identidade do avalista',
    description:
      'Documento emitido pelos órgãos competentes que sejam válidos como identificação em território nacional. Ex: RG, CREA, CRM e OAB. O documento deve estar dentro do prazo de validade ou, com até 10 anos da data de emissão no caso do RG. A imagem do documento deve ser legível. Caso o cliente necessite de uma procuração, esta também deve ser anexada junto à identificação do cliente.',
    type: 'guarantor_identity_document',
    hasBeenSent: false,
    documentType: 'cnpj',
  },
  {
    title: 'Comprovante de renda',
    description:
      'Faturamento dos últimos 12 meses/ Decore/ Extrato bancário dos últimos 3 meses.',
    type: 'proof_income',
    hasBeenSent: false,
    documentType: 'cnpj',
  },
  {
    title: 'Comprovante de endereço do local de instalação',
    description: 'O documento precisa ser do último mês.',
    type: 'voucher_local_installation_address',
    hasBeenSent: false,
    documentType: 'cnpj',
  },
]
