import { NOTIFICATION_URLS } from 'containers/Notifications/constants/redirectNotificationURLs'
import { type INotificationDTO } from 'dto/NotificationDTO'
import { type INotificationFinancingParams } from 'dto/NotificationDTO/financing'

export const redirectFinancingNotification = (
  notification: INotificationDTO,
) => {
  const { financingId, simulationId, financingType } =
    notification.params as INotificationFinancingParams

  const financingTypeTraduction = {
    value: 'valor',
    cotacao: 'cotacao',
  }

  const path = NOTIFICATION_URLS[notification.type].concat(
    `/${financingTypeTraduction[financingType]}/${financingId}/simulacao/${simulationId}`,
  )

  return path
}
