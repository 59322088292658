import React, { useState, useEffect } from 'react'
import { Typography, Avatar, useMediaQuery } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { withSize } from 'react-sizeme'
import { Paper } from '@77sol/core'
import palette from 'app_palette'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { FullScreenExpandResizeIcon } from '@77sol/icons/dist'
import { useSelector } from 'react-redux'
import API from 'api'
import { useStyles } from './styles'
import { initialPdf } from './constants'
import { ContainerPdf } from './components/ContainerPdf'

const ContainerListPdf = React.memo(
  ({ containers, logo, userName, stateQuotesPdf }) =>
    containers
      .filter((container) => container.visible == true)
      .map((container, index) => (
        <>
          {container.visible && (
            <ContainerPdf
              container={container}
              index={index}
              logo={logo}
              userName={userName}
              stateQuotesPdf={stateQuotesPdf}
            />
          )}
        </>
      )),
)

function FullscreenPdfProposal({ setPropostaStep, isFromProposalList }) {
  const proposal = useSelector((state) => state.Reducer1)

  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [documentUsername, setDocumentUsername] = useState()

  const { userName } = proposal
  const logo = proposal.proposal.logo ? proposal.proposal.logo : ''

  const classes = useStyles()

  const [statePdf, setStatePdf] = useStateWithCallbackLazy({
    containers: initialPdf,
  })

  const [stateQuotesPdf, setStateQuotesPdf] = useStateWithCallbackLazy([])
  const [signatureState, setSignatureState] = useStateWithCallbackLazy({})

  useEffect(() => {
    API.get(`/proposal/pdf-structure/${proposal.proposal.id}`).then((res) => {
      if (res.data[0]) {
        setStatePdf(res.data[0])
        setStateQuotesPdf(res.data[1])
        const pdfItems = res.data[0].containers
        const quotesItemsInitial = res.data[1].length
        const quotesItems = res.data[1]

        if (res.data[1].length != proposal.quotes.length) {
          const newQuote = proposal.quotes.filter(
            (quote, i) => i > quotesItems.length - 1,
          )
          const pdfItems2 = pdfItems.splice(
            pdfItems.findIndex(
              (item) => item.id == `QuotePdf${quotesItems.length - 1}`,
            ) + 1,
          )
          const newItems = []

          newQuote.map((item, i) => {
            newItems.push({
              id: `QuotePdf${quotesItemsInitial + i}`,
              content: `item-quotePdf${quotesItemsInitial + i}`,
              display: `Cotação ${quotesItemsInitial + i + 1}`,
              visible: true,
            })
            quotesItems.push([
              {
                id: `returnQuote${quotesItemsInitial + i}`,
                content: `item-clientPdf${quotesItemsInitial + i}`,
                display: 'Retorno de investimento',
                visible: true,
              },
              {
                id: `quoteData${quotesItemsInitial + i}`,
                content: `item-quoteData${quotesItemsInitial + i}`,
                display: 'Dados do projeto',
                visible: true,
              },
              {
                id: `productionQuote${quotesItemsInitial + i}`,
                content: `item-productionQuote${quotesItemsInitial + i}`,
                display: 'Produção e consumo',
                visible: true,
              },
              {
                id: `paymentQuote${quotesItemsInitial + i}`,
                content: `item-paymentQuote${quotesItemsInitial + i}`,
                display: 'Formas de pagamento',
                visible: true,
              },
            ])
          })
          const finalArray2 = [...newItems, ...pdfItems2]
          const newArray = [...pdfItems, ...finalArray2]
          setStatePdf({ containers: newArray })
          setStateQuotesPdf([...quotesItems])
        }
        if (res.data[2]) {
          setSignatureState(res.data[2])
        }
      } else {
        const pdfItems = []
        const quotesItems = []
        proposal.quotes.map((item, i) => {
          pdfItems.push({
            id: `QuotePdf${i}`,
            content: `item-quotePdf${i}`,
            display: `Cotação ${i + 1}`,
            visible: true,
          })
          quotesItems.push([
            {
              id: `returnQuote${i}`,
              content: `item-clientPdf${i}`,
              display: 'Retorno de investimento',
              visible: true,
            },
            {
              id: `quoteData${i}`,
              content: `item-quoteData${i}`,
              display: 'Dados do projeto',
              visible: true,
            },
            {
              id: `productionQuote${i}`,
              content: `item-productionQuote${i}`,
              display: 'Produção e consumo',
              visible: true,
            },
            {
              id: `paymentQuote${i}`,
              content: `item-paymentQuote${i}`,
              display: 'Formas de pagamento',
              visible: true,
            },
          ])
        })
        if (proposal?.extraConfigs?.extra_configs) {
          proposal.extraConfigs.extra_configs.map((item) => {
            pdfItems.push({
              id: item.id,
              content: item.content,
              display: item.title,
              visible: true,
            })
          })
        }
        pdfItems.unshift({
          id: 'ClientPdf',
          content: 'item-clientPdf',
          display: 'Introdução',
          visible: true,
        })
        const newArrPdfContainers = [...statePdf.containers]

        if (proposal.extraConfigs.schedules.data.length == 0) {
          const indexPdf = newArrPdfContainers.findIndex(
            (item) => item.id == 'SchedulesPdf',
          )
          newArrPdfContainers[indexPdf].visible = false
        }

        const newArray = [...pdfItems, ...newArrPdfContainers]
        setStatePdf({ containers: newArray })
        setStateQuotesPdf([...quotesItems])
      }
    })
  }, [])

  useEffect(() => {
    const pdfItems = []
    const quotesItems = []
    proposal.quotes.map((item, i) => {
      pdfItems.push({
        id: `QuotePdf${i}`,
        content: `item-quotePdf${i}`,
        display: `Cotação ${i + 1}`,
        visible: true,
      })
      quotesItems.push([
        {
          id: `returnQuote${i}`,
          content: `item-clientPdf${i}`,
          display: 'Retorno de investimento',
          visible: true,
        },
        {
          id: `quoteData${i}`,
          content: `item-quoteData${i}`,
          display: 'Dados do projeto',
          visible: true,
        },
        {
          id: `productionQuote${i}`,
          content: `item-productionQuote${i}`,
          display: 'Produção e consumo',
          visible: true,
        },
        {
          id: `paymentQuote${i}`,
          content: `item-paymentQuote${i}`,
          display: 'Formas de pagamento',
          visible: true,
        },
      ])
    })
    pdfItems.unshift({
      id: 'ClientPdf',
      content: 'item-clientPdf',
      display: 'Introdução',
      visible: true,
    })
    const newArray = [...pdfItems, ...statePdf.containers]
    setStatePdf({ containers: newArray })
    setStateQuotesPdf([...quotesItems])
  }, [])

  useEffect(() => {
    setDocumentUsername(
      userLogged[0].cnpj ? userLogged[0].cnpj : userLogged[0].cpfResponsavel,
    )
  }, [])

  return downSm ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FFF',
        height: isFromProposalList ? 'calc(var(--app-height) - 152px)' : '100%',
      }}>
      <Paper
        style={{
          width: '630px',
          position: 'relative',
          height: '100%',
          overflowY: 'auto',
          padding: downSm ? '24px 32px' : '32px 40px',
          boxShadow: 'rgb(0 0 0 / 14%) 1px 1px 20px',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
            paddingBottom: downSm ? '200px' : '100px',
          }}>
          <ContainerListPdf
            containers={statePdf.containers}
            proposal={proposal}
            logo={logo}
            userName={userName}
            stateQuotesPdf={stateQuotesPdf}
          />
          {signatureState.visible && (
            <div style={{ width: '220px' }}>
              <br />
              <div
                style={{
                  borderBottom: '1px solid black',
                  paddingBottom: '24px',
                }}>
                <Typography
                  style={{
                    fontSize: '10px',
                    color: palette.grayscale[700],
                    textAlign: 'center',
                    fontWeight: 800,
                  }}>
                  Assinatura do Integrador
                </Typography>
                <Typography
                  style={{
                    fontSize: '9px',
                    color: palette.grayscale[700],
                    textAlign: 'center',
                  }}>
                  {userName}
                </Typography>
                <Typography
                  style={{
                    fontSize: '9px',
                    color: palette.grayscale[700],
                    textAlign: 'center',
                  }}>
                  {documentUsername}
                </Typography>
              </div>
              <br />
              <div
                style={{
                  borderBottom: '1px solid black',
                  paddingBottom: '24px',
                }}>
                <Typography
                  style={{
                    fontSize: '10px',
                    color: palette.grayscale[700],
                    textAlign: 'center',
                    fontWeight: 800,
                  }}>
                  Assinatura do cliente
                </Typography>
                <Typography
                  style={{
                    fontSize: '9px',
                    color: palette.grayscale[700],
                    textAlign: 'center',
                  }}>
                  {
                    proposal?.clients.find(
                      (client) => client.id == proposal.proposal.customer_id,
                    )?.label
                  }
                </Typography>
                {proposal?.clients.find(
                  (client) => client.id == proposal.proposal.customer_id,
                )?.cpf && (
                  <Typography
                    style={{
                      fontSize: '9px',
                      color: palette.grayscale[700],
                      textAlign: 'center',
                    }}>
                    {
                      proposal?.clients.find(
                        (client) => client.id == proposal.proposal.customer_id,
                      )?.cpf
                    }
                  </Typography>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: '12px',
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              bottom: downSm ? '70px' : '32px',
              left: 'calc(50% - 20px)',
            }}>
            <Avatar
              variant="rounded"
              style={{
                height: 'auto',
                width: 'auto',
                marginRight: '16px',
                backgroundColor: !logo && '#D9DBE9',
                fontSize: '40px',
              }}
              imgProps={{
                style: {
                  height: 'initial',
                  width: 'initial',
                  maxHeight: '40px',
                  maxWidth: '80px',
                },
              }}
              src={logo}>
              {userName
                ?.match(/(\b\S)?/g)
                .join('')
                .match(/(^\S|\S$)?/g)
                .join('')
                .toUpperCase()}
            </Avatar>
          </div>
        </div>
      </Paper>
    </div>
  ) : (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open
      onClose={() => setPropostaStep('structuringProposal')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#FFF',
            height: '100%',
          }}>
          <FullScreenExpandResizeIcon
            width={downSm ? '48' : '60'}
            onClick={() => setPropostaStep('structuringProposal')}
            style={{
              color: 'rgb(20,55,196)',
              position: 'fixed',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              zIndex: 1000,
              padding: '10px',
              borderRadius: '50%',
              boxShadow: '0 0 8px rgb(6 62 249 / 50%)',
              backgroundColor: palette.white,
              height: downSm && '48px',
            }}
          />
          <Paper
            style={{
              width: '630px',
              position: 'relative',
              maxHeight: downSm ? '100vh' : '95vh',
              overflowY: 'auto',
              padding: downSm ? '24px 32px' : '32px 40px',
              boxShadow: 'rgb(0 0 0 / 14%) 1px 1px 20px',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
                paddingBottom: downSm ? '200px' : '100px',
              }}>
              <ContainerListPdf
                containers={statePdf.containers}
                proposal={proposal}
                logo={logo}
                userName={userName}
                stateQuotesPdf={stateQuotesPdf}
              />
              {signatureState.visible && (
                <div style={{ width: '220px' }}>
                  <br />
                  <div
                    style={{
                      borderBottom: '1px solid black',
                      paddingBottom: '24px',
                    }}>
                    <Typography
                      style={{
                        fontSize: '10px',
                        color: palette.grayscale[700],
                        textAlign: 'center',
                        fontWeight: 800,
                      }}>
                      Assinatura do Integrador
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '9px',
                        color: palette.grayscale[700],
                        textAlign: 'center',
                      }}>
                      {userName}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '9px',
                        color: palette.grayscale[700],
                        textAlign: 'center',
                      }}>
                      {documentUsername}
                    </Typography>
                  </div>
                  <br />
                  <div
                    style={{
                      borderBottom: '1px solid black',
                      paddingBottom: '24px',
                    }}>
                    <Typography
                      style={{
                        fontSize: '10px',
                        color: palette.grayscale[700],
                        textAlign: 'center',
                        fontWeight: 800,
                      }}>
                      Assinatura do cliente
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '9px',
                        color: palette.grayscale[700],
                        textAlign: 'center',
                      }}>
                      {
                        proposal?.clients.find(
                          (client) =>
                            client.id == proposal.proposal.customer_id,
                        )?.label
                      }
                    </Typography>
                    {proposal?.clients.find(
                      (client) => client.id == proposal.proposal.customer_id,
                    )?.cpf && (
                      <Typography
                        style={{
                          fontSize: '9px',
                          color: palette.grayscale[700],
                          textAlign: 'center',
                        }}>
                        {
                          proposal?.clients.find(
                            (client) =>
                              client.id == proposal.proposal.customer_id,
                          )?.cpf
                        }
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  marginTop: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: downSm ? '70px' : '32px',
                  left: 'calc(50% - 20px)',
                }}>
                <Avatar
                  variant="rounded"
                  style={{
                    height: 'auto',
                    width: 'auto',
                    marginRight: '16px',
                    backgroundColor: !logo && '#D9DBE9',
                    fontSize: '40px',
                  }}
                  imgProps={{
                    style: {
                      height: 'initial',
                      width: 'initial',
                      maxHeight: '40px',
                      maxWidth: '80px',
                    },
                  }}
                  src={logo}>
                  {userName
                    ?.match(/(\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}
                </Avatar>
              </div>
            </div>
          </Paper>
        </div>
      </Fade>
    </Modal>
  )
}

export default withSize()(FullscreenPdfProposal)
