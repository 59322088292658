import * as Yup from 'yup'

const { number, object, string, mixed } = Yup

const validadeNFValue = (
  nf_origin: string[],
  nf_value: Yup.NumberSchema<Yup.Maybe<number | undefined>>,
) => {
  if (nf_origin[0] === 'manual') {
    return nf_value.min(
      Yup.ref('equipment_value'),
      'A nota fiscal deve ser maior ou igual ao valor dos equipamentos',
    )
  }
  return nf_value
}

export const orderPendingSchema = object({
  address_payment: object({
    file: mixed().nullable(),
    name_file: string().nullable(),
    url: string().nullable(),
  }),
  billing_letter: object({
    file: mixed().nullable(),
    name_file: string().nullable(),
    url: string().nullable(),
  }),
  equipment_value: number(),
  nf_origin: string(),
  birth_date: string().notRequired(),
  customer_email: string().email('E-mail inválido').notRequired(),
  customer_phone: string()
    .notRequired()
    .test(
      'empty-or-8-characters-check',
      'Telefone inválido',
      (customerPhone) => !customerPhone || customerPhone.length >= 10,
    ),
  nf_value: number()
    .notRequired()
    .when('nf_origin', (nf_origin, nf_value) =>
      validadeNFValue(nf_origin, nf_value),
    ),
  financial_institution: string().notRequired(),
  payment_type: string().notRequired(),
})
