import { EmptyState as PrimitiveEmptyState } from '@77sol-ui/molecules'
import { ButtonAsLink, StyledLink, Wrapper } from './styles'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import { Search } from 'lucide-react'

export interface IEmptyStateProps {
  alreadyCreatedFinancing?: boolean
  filterApplied?: boolean
}

export function EmptyState({
  alreadyCreatedFinancing,
  filterApplied,
}: IEmptyStateProps) {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()

  if (filterApplied) {
    return (
      <Wrapper height="100%" maxWidth="100%" spacing={4}>
        <Search strokeWidth={2} />

        <PrimitiveEmptyState.Subtitle size="lg">
          Nenhum financiamento encontrado para o filtro selecionado.
        </PrimitiveEmptyState.Subtitle>
      </Wrapper>
    )
  }

  if (alreadyCreatedFinancing) {
    return (
      <Wrapper height="100%" maxWidth="100%" spacing={4} variant="table">
        <Search />

        <PrimitiveEmptyState.Subtitle size="lg">
          Você ainda não possui financiamentos criados.
        </PrimitiveEmptyState.Subtitle>
        <ButtonAsLink onClick={setFinancingPerValueModalOpen}>
          Crie um financiamento agora!
        </ButtonAsLink>
      </Wrapper>
    )
  }

  return (
    <PrimitiveEmptyState.Root
      height="300px"
      maxWidth="100%"
      spacing={10}
      variant="table"
    >
      <PrimitiveEmptyState.Icon emoji="👋" size="lg" />
      <PrimitiveEmptyState.Title size="md">
        Simule um financiamento para começar a usar
      </PrimitiveEmptyState.Title>
      <PrimitiveEmptyState.Subtitle size="md">
        Ou realize uma proposta <StyledLink to="/dimensionar">aqui</StyledLink>
      </PrimitiveEmptyState.Subtitle>
      <PrimitiveEmptyState.Button onClick={setFinancingPerValueModalOpen}>
        Simular financiamento
      </PrimitiveEmptyState.Button>
    </PrimitiveEmptyState.Root>
  )
}
