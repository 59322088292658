import { useQuery } from '@tanstack/react-query'
import { leadsAlert } from '../services/api'
import { LEADS_ALERT } from '../constants/endpointKeys'

export function useLeadsAlert() {
  const { ...methods } = useQuery({
    queryKey: [LEADS_ALERT],
    queryFn: async () => await leadsAlert(),
  })

  return { ...methods }
}
