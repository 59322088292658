import { useMutation } from '@tanstack/react-query'
import { quotationService } from '../api'
import { type AddQuotationEquipmentPayload } from '../types'

export function useAddEquipmentMutation() {
  const mutation = useMutation({
    mutationFn: async (data: AddQuotationEquipmentPayload) =>
      await quotationService.addNewEquipment(data),
  })

  return mutation
}
