export const MESSAGES = {
  TITLE: {
    error: 'Erro',
    paid_card: 'Pago',
    pending_card: 'Pagamento com cartão em andamento',
    pending_billet: 'Boleto gerado',
  },
  SUBTITLE: {
    error: 'Tente novamente!',
    paid_card: 'Pagamento efetuado com sucesso!',
    pending_card:
      'Em breve você receberá um e-mail de confirmação referente ao pagamento efetuado.',
    pending_billet: 'Efetue o pagamento em até 24 horas.',
  },
}
