import { useCallback, useRef, useState } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import { type IImageCropRef } from '@77sol-ui/atoms'

interface IUseProfilePhotoProps {
  onSave: (file: File) => void
}
function useProfilePhoto({ onSave }: IUseProfilePhotoProps) {
  const [zoom, setZoom] = useState(1)
  const imageRef = useRef<IImageCropRef>(null)

  const { width } = useWindowSize()

  const isMobile = width < 991
  const cropSize = isMobile ? 250 : 360

  const zoomLimit = 13
  const zoomMin = 1

  const handleSave = useCallback(async () => {
    if (imageRef?.current) {
      // @ts-expect-error getImageScaledToCanvas
      const canvasScaled = imageRef?.current?.getImageScaledToCanvas()
      const dataURL = canvasScaled.toDataURL('image/png')
      const response = await fetch(dataURL)
      const blob = await response.blob()
      const file = new File([blob], 'new_profile_photo', { type: 'image/png' })
      onSave(file)
    }
  }, [onSave])

  const handleIncreaseZoom = () => {
    const addZoom = zoom === zoomLimit ? zoom : zoom + 0.5
    setZoom(addZoom)
  }

  const handleDecreaseZoom = () => {
    const addZoom = zoom === zoomMin ? zoom : zoom - 0.5
    setZoom(addZoom)
  }

  return {
    zoom,
    cropSize,
    setZoom,
    handleSave,
    imageRef,
    handleIncreaseZoom,
    handleDecreaseZoom,
    zoomLimit,
    zoomMin,
  }
}

export { useProfilePhoto }
