import yup from 'libs/yup'

const NOT_IS_NUMBER = /\D/g
const ELEVEN_NUMBERS = /\d{11}/

export const sendWhatsAppSchema = yup.object({
  celular: yup
    .string()
    .required('Campo obrigatório')
    .matches(ELEVEN_NUMBERS, 'Número inválido')
    .transform((value: string) => value.replace(NOT_IS_NUMBER, '')),
})

export type ISendWhatsAppForm = yup.InferType<typeof sendWhatsAppSchema>
