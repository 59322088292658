import { useModalRemoveQuotation } from './hooks/useDeleteQuotationQueue'
import { QuotationDeleteModal } from 'containers/Modals/QuotationDeleteModal'

export function ModalRemoveQuotation() {
  const { quotationDelete, successDeleteQuotation, handleCloseDeleteModal } =
    useModalRemoveQuotation()

  if (!quotationDelete) {
    return null
  }

  return (
    <QuotationDeleteModal
      quotation={quotationDelete}
      onClose={handleCloseDeleteModal}
      onSuccess={successDeleteQuotation}
    />
  )
}
