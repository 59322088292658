import { palette } from '@77sol/styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: ({ headerSticky }) => ({
    boxShadow: 'none',
    padding: '0',
    overflowY: headerSticky && 'auto',
    overflowX: 'auto',
    maxHeight: headerSticky && '440px',
    head: {
      backgroundColor: 'transparent',
      '& > th': {
        border: 'none',
      },
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0px 8px',
    },
    titles: {
      padding: '0px 16px',
    },
  }),
  head: () => ({
    backgroundColor: 'transparent',
    '& > th': {
      border: 'none',
    },
  }),
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',

    '& > tr': {
      border: '3px solid blue',
    },
  },
  titles: ({ headerSticky }) => ({
    padding: '0px 16px',
    backgroundColor: headerSticky && '#FFF',
  }),
  highlighted: {
    transition: 'background 200ms',
    background: palette.primary[300],
    '& td': {
      '& p': {
        color: palette.white,
      },
    },

    '&:hover': {
      background: palette.primary[400],
    },
  },
}))

export default useStyles
