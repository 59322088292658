import { EmptyState, TableList, Loading } from 'components'
import { ListContainer } from './styles'
import { InfiniteScrollLoading } from '../../../InfiniteScrollLoading'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Hidden } from '@material-ui/core'
import ResponsiveCard from '../../../ResponsiveCard'
import { useListFinancingQuotation } from './hooks/useListFinancingQuotation'
import Icons from 'containers/FinancingContainer/components/Icons'

export function ListFinancingQuotation({
  isOpenSimulations,
  isOpenSimulationDetails,
  onRowClick,
  selectedFinancing,
  onIconClick,
  financingId,
  defaultTab,
}) {
  const { columns, values, fetchNextPage, hasNextPage, isLoading } =
    useListFinancingQuotation({
      isOpenSimulations,
      isOpenSimulationDetails,
      onRowClick,
      financingId,
      defaultTab,
    })

  return (
    <Loading isLoading={isLoading} minHeight="250px">
      {values.length === 0 ? (
        <EmptyState text="Não há nenhum financiamento." />
      ) : (
        <ListContainer>
          <Hidden smDown={!isOpenSimulations && !isOpenSimulationDetails}>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={values.length}
              height="calc(var(--app-height) - 215px - 80px)"
              loader={<InfiniteScrollLoading />}
            >
              <TableList
                onRowClick={onRowClick}
                values={values}
                columns={columns}
                selectedRow={selectedFinancing}
                LastItem={({ row }) =>
                  !isOpenSimulations && (
                    <Icons
                      onClick={(type) => {
                        onIconClick(type, row)
                      }}
                    />
                  )
                }
              />
            </InfiniteScroll>
          </Hidden>
          <Hidden mdUp>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={values.length}
              height="calc(var(--app-height) - 265px - 40px)"
              loader={<InfiniteScrollLoading />}
            >
              {values?.map((values, index) => (
                <ResponsiveCard
                  key={index}
                  values={values}
                  onClick={() => {
                    onRowClick(values)
                  }}
                  handleIconClick={(type) => {
                    onIconClick(type, values)
                  }}
                />
              ))}
            </InfiniteScroll>
          </Hidden>
        </ListContainer>
      )}
    </Loading>
  )
}
