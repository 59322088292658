export const solLeadsTrackers = {
  actions: {
    viewedAlert: 'SolLeads: Visualizou o alerta',
    closeAlert: 'SolLeads: Clicou em fechar o alerta',
    acceptLead: 'SolLeads: Clicou em aceitar o lead',
    rejectLead: 'SolLeads: Clicou em rejeitar o(s) lead(s)',
    selectLead: 'SolLeads: Selecionou um lead',
    openLeadsModal: 'SolLeads: Abriu o modal de leads',
    closeLeadModal: 'SolLeads: Fechou o modal de leads',
    openRejectModal: 'SolLeads: Abriu o modal de rejeite',
    closeRejectModal: 'SolLeads: Fechou o modal de rejeite',
  },
}
