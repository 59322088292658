import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    backgroundColor: palette.grayscale[100],
  },
  financingContainer: ({ isFromProposalList }) => ({
    height: 'calc(var(--app-height) - 250px)',
    overflow: 'auto',
    [breakpoints.down('md')]: {
      height: `calc(var(--app-height) - ${
        isFromProposalList ? '187px' : '193px'
      })`,
    },
  }),
  containerStatus: {
    display: 'flex',
    padding: '32px 0px',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      padding: '16px 0px',
    },
  },
  equipmentContainer: {
    margin: '32px 0px',
    padding: '0px 32px',
    borderRadius: '8px',
    backgroundColor: 'white',
    [breakpoints.down('md')]: {
      padding: '0px 16px',
    },
  },
  equipmentContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '32px 0px',
  },
  equipmentDescription: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '24px',
    width: '90%',
    [breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
  },
  equipmentTitle: {
    color: '#193ABC',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  equipmentDate: {
    color: '#464F5B',
    fontSize: '14px',
    fontWeight: '400',
  },
  equipmentQuantity: {
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
    padding: '12px',
  },
  invoiceContainer: {
    backgroundColor: 'white',
    padding: '24px',
    color: '#464F5B',
    borderRadius: '8px',
    boxShadow: '0px 4px 4px 0px #0000000D',
    marginBottom: '8px',
  },
  invoiceContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'inherit',
    },
  },
  invoiceDescription: {
    maxWidth: '60%',
    [breakpoints.down('md')]: {
      maxWidth: '100%',
      paddingBottom: '16px',
    },
  },
}))

export default useStyles
