import { ModalAlterarProduto } from 'views/DimensionarV2/components/StepTwo/components'
import { useChangeEquipment } from './hooks/useChangeEquipment'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

interface ChangeEquipmentModalProps {
  equipment: IQuotationProductDTO
  onClose: () => void
}

export function ChangeEquipmentModal({
  equipment,
  onClose,
}: ChangeEquipmentModalProps) {
  const { quotationSelected, handleChangeEquipment } = useChangeEquipment({
    handleCloseModal: onClose,
  })

  const currentQuotation = {
    quotation: {
      ...quotationSelected,
    },
    ...(equipment.has_error && {
      firstKit: {
        providers: true,
        changeProduct: equipment.categoria,
      },
    }),
  }

  return (
    <ModalAlterarProduto
      isOpen={Boolean(equipment)}
      onClose={onClose}
      product={equipment}
      quotationId={currentQuotation?.quotation?.id}
      onChangeProduct={handleChangeEquipment}
    />
  )
}
