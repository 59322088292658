import { useToast } from '@77sol/core'
import { useQueryClient } from '@tanstack/react-query'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { useAddEquipmentMutation } from 'domains/quotation/services/hooks/useAddEquipmentMutation'
import { useFetchEquipmentsBrands } from 'domains/quotation/services/hooks/useFetchEquipmentsBrands'
import { useFetchEquipmentsCategories } from 'domains/quotation/services/hooks/useFetchEquipmentsCategories'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useSelector } from 'react-redux'

export function useAddEquipment() {
  const { onErrorOpenToast, onSuccessOpenToast } = useToast()
  const queryClient = useQueryClient()
  const {
    quotationSelected,
    dimensioningId,
    openModalToAddNewEquipment,
    setOpenModalToAddNewEquipment,
  } = useEquipmentContainerStore()

  const userProfile = useSelector(
    (state: any) => state.ReducerProfile.userProfile,
  )

  const { data: categories } = useFetchEquipmentsCategories()
  const { data: brands } = useFetchEquipmentsBrands()

  const { mutate, isLoading } = useAddEquipmentMutation()

  function onAddNewEquipment(equipment: IQuotationProductDTO) {
    if (!quotationSelected?.id) {
      return
    }
    mutate(
      {
        extra_items: [equipment.id],
        quotation_id: quotationSelected?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([GET_DIMENSIONING, dimensioningId])
          onSuccessOpenToast('Equipamento adicionado com sucesso!')
          setOpenModalToAddNewEquipment(false)
        },
        onError: () => {
          onErrorOpenToast('Não foi possível adicionar o Equipamento!')
        },
      },
    )
  }

  return {
    quotationSelected,
    onAddNewEquipment,
    isLoading,
    categories,
    brands,
    openModalToAddNewEquipment,
    setOpenModalToAddNewEquipment,
    userProfile,
  }
}
