import * as yup from 'yup'

export const baseSchema = yup.object().shape({
  system_potency: yup
    .string()
    .test({
      message: 'Potência mínima é de 1,2 kWp.',
      test: (value) => Number(value) >= 1.2,
    })
    .required('Informe a potência em kWp do sistema.'),
  structure_type: yup.string().required('Selecione o tipo de estrutura.'),
  delivery_state: yup.string().required('Selecione um estado para a entrega.'),
  type_test: yup.string(),
  voltage: yup.string().removeEmptyString(),
  power_fase: yup.string().removeEmptyString(),
})

export const adminSchema = baseSchema.concat(
  yup.object().shape({
    integrator: yup.object().shape({
      id: yup.string().required('Selecione um integrador.'),
      cnpj: yup.string().nullable(),
      companyName: yup.string().nullable(),
      email: yup.string(),
      name: yup.string(),
      tradingName: yup.string().nullable(),
      uf: yup.string().nullable(),
    }),
    discount: yup.string().removeEmptyString(),
    origin_lead: yup.string(),
  }),
)
