import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const StyledButton = styled.button`
  color: ${colors.gray[300]};

  position: absolute;

  left: 10px;
  top: 8px;
  border-radius: 4px;
  transition: color 0.2s ease-in-out 0s;
  &:not(:disabled):hover {
    color: ${colors.blue[400]};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline-color: ${colors.blue[300]};
  }
`
