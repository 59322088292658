import React, { useState } from 'react'
import { Button, Spacer, Typography } from '@77sol/core'
import { Grid, Slide, useMediaQuery } from '@material-ui/core'
import { Header, ModalDescriptionEquipment } from 'components'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { useSlideStyle } from './hooks/useSlideStyle'

export function ProductDetail({
  openSidebarProduct,
  setOpenSidebarProduct,
  productViewDetail,
}) {
  const [openModalDescriptionEquipment, setOpenModalDescriptionEquipment] =
    useState()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [technicalInfo, setTechnicalInfo] = useState({})

  const handleOpenTechnicalInfo = () => {
    setTechnicalInfo({
      title: productViewDetail.nome,
      description: productViewDetail.descricao,
      image: productViewDetail.imagens,
      datasheet: productViewDetail.datasheet || '',
    })
    setOpenModalDescriptionEquipment(true)
  }

  const { productDetailStyle } = useSlideStyle()

  return (
    <>
      <Slide
        style={productDetailStyle}
        class
        direction="left"
        in={openSidebarProduct}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <Header
            title={
              <Typography
                style={{ padding: '7.5px 0px' }}
                type={downSm ? 'text_medium' : 'text_large'}
                color={downSm ? 'white' : 'grayscale'}
                colorWeight="700"
              >
                Produto
              </Typography>
            }
            size="huge"
            noMargin
            sticky
            onBack={() => {
              setOpenSidebarProduct(false)
            }}
          />
          <div style={{ padding: '24px', width: downSm && '100%' }}>
            <Grid
              spacing={3}
              container
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item md={6}>
                <img
                  src={productViewDetail.imagens}
                  alt="Imagem do produto"
                  style={{ width: downSm ? '164px' : '100%' }}
                />
              </Grid>
              <Grid item md={6}>
                <Typography size="xsmall" color="primary" colorWeight="300">
                  {currentEnterpriseConfigs.enterpriseName}
                </Typography>
                <Spacer size="12" weight="700" direction="horizontal" />
                <Typography size="small">{productViewDetail.nome}</Typography>
              </Grid>
            </Grid>

            <Grid spacing={3} container style={{ marginTop: '20px' }}>
              <Grid item md={12}>
                <Button
                  id="productDetail_button_technicalData"
                  onClick={handleOpenTechnicalInfo}
                  size="small"
                  variant="outlined"
                  style={{ marginLeft: '0px' }}
                >
                  Ver dados técnicos
                </Button>
              </Grid>
            </Grid>

            {downSm && <Spacer size="100" direction="horizontal" />}
          </div>
        </div>
      </Slide>

      {openModalDescriptionEquipment && (
        <ModalDescriptionEquipment
          {...technicalInfo}
          openModalDescriptionEquipment={openModalDescriptionEquipment}
          setOpenModalDescriptionEquipment={setOpenModalDescriptionEquipment}
        />
      )}
    </>
  )
}
