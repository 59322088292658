export const GROUP_FIELDS_TITLE = {
  pessoa_fisica_dados_pessoais: 'Dados pessoais',
  pessoa_fisica_dados_complementares: 'Dados Complementares',
  pessoa_fisica_informacoes_de_endereco: 'Endereço',
  pessoa_juridica_informacoes_da_empresa: 'Informações da Empresa',
  pessoa_juridica_dados_bancarios_referencias_bancarias:
    'Dados Bancários Pessoa Juridica',
  pessoa_juridica_informacoes_de_endereco: 'Endereço Pessoa Juridica',
  pessoa_juridica_qsa_quadro_societario_e_administradores: 'Quadro Societário',
  pessoa_juridica_qsa_quadro_societario_e_administradores_endereco:
    'Endereço Sócios',
  pessoa_juridica_avalista_informacoes_do_avalista: 'Avalista',
  pessoa_juridica_avalista_informacoes_do_avalista_endereco:
    'Endereço Avalista',
  pessoa_juridica_procurador_informacoes_do_procurador: 'Procurador',
  pessoa_juridica_procurador_informacoes_do_procurador_endereco:
    'Endereço do Procurador',
}

export const FINANCING_TABS_OPTIONS = [
  'Status',
  'Solicitante',
  'Equipamentos',
  'Documentos',
]

export const FINANCING_TABS_INDEX = {
  STATUS: 0,
  REQUESTER: 1,
  EQUIPMENTS: 2,
  DOCUMENTS: 3,
}

export const FINANCING_CANCEL_COLUMNS = [
  'ID',
  'Solicitante',
  'Banco',
  'Valor',
  'Status',
]

export const FINANCING_TYPE = {
  0: 'Valor',
  1: 'Cotação',
}

export const FINANCING_TYPE_SLUG = {
  valor: 0,
  cotacao: 1,
}

export enum FINANCING_TYPE_ENUM {
  VALOR = 0,
  COTACAO = 1,
}
