import React, { useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { UploadIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { Button, Typography } from '@77sol/core'
import { Box } from '@material-ui/core'
import { isEmpty } from 'utils'
import styles from './styles'

function SingleImageUpload({ handleImageUploaded }) {
  const [imagem, setImagem] = useState()
  const classes = styles()

  const handleChangeStatus = ({ file }, status) => {
    if (status === 'preparing') {
      // setImageLoading(true)
      setImagem(file)
      handleImageUploaded(file)
    }
    if (status === 'headers_received') {
      // setImagem(file)
      // handleImageUploaded(file)
    }

    // setImageLoading(false)
  }

  return (
    <div className={classes.paperRendered}>
      <div>
        <Dropzone
          maxFiles={1}
          // getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          multiple={false}
          inputContent={
            isEmpty(imagem) ? (
              <Box>
                <UploadIcon width="40px" color={palette.grayscale[600]} />
                <Typography size="small" colorWeight="600">
                  Arraste o arquivo ou clique aqui para selecioná-lo
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box>
                  <img
                    style={{
                      maxHeight: '160px',
                      width: 'auto',
                      maxWidth: '280px',
                      borderRadius: '4px',
                    }}
                    src={imagem}
                    alt="imagem do arquivo"
                  />
                  <span style={{ marginLeft: '20px' }}>
                    {typeof imagem === 'string'
                      && (imagem.split('/site/')[1]
                        ? imagem.split('/site/')[1]
                        : 'ImagemPadrao.png')}
                  </span>
                </Box>
                <Button onClick={() => setImagem()}>Remover</Button>
              </Box>
            )
          }
          accept="image/*, application/pdf"
          styles={{
            dropzone: {
              padding: '24px 0px',
              border: '2px dashed #929EAB',
              borderRadius: '5px',
              flexDirection: 'column',
              overflow: 'hidden',
              width: '100%',
              minHeight: '200px',
            },
            preview: {
              border: 'none',
            },
            input: {
              display: 'none',
            },
            inputLabel: {
              padding: '16px',
              textAlign: 'center',
            },
          }}
        />
      </div>
    </div>
  )
}

export default SingleImageUpload
