import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import { type IDeleteQuotationButtonProps } from '../types'
import { useCallback } from 'react'

export function useDeleteQuotationButton({
  quotation,
}: Pick<IDeleteQuotationButtonProps, 'quotation'>) {
  const setOpenModalToDeleteQuotation = useEquipmentContainerStore(
    (state) => state.setOpenModalToDeleteQuotation,
  )

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const hasOnlyOneQuotation = data?.quotations.length === 1

  const deleteQuotation = useCallback(() => {
    if (quotation) {
      setOpenModalToDeleteQuotation(quotation)
    }
  }, [quotation])

  return { deleteQuotation, hasOnlyOneQuotation }
}
