import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 3,
    width: 206,
    minWidth: 206,
    flex: '0 0 auto',
    overflowY: 'auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1',
  },
  formatAlignIcon: {
    color: '#B0BAC9',
    cursor: 'pointer',
    position: 'fixed',
    zIndex: '999',
    top: '20px',
    left: '20px',
  },
}))
