import { Typography } from '@77sol/core'
import { palette } from '@77sol/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { FinancialStats } from 'views/DimensionarV2/components/ResultadoDimensionamento/components'

const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export function ProductionAndConsumption({ quote }) {
  const proposal = useSelector((state) => state.Reducer1)

  return (
    <>
      <div style={{ width: '100%', marginTop: '12px' }}>
        <Typography
          style={{
            fontSize: '10px',
            backgroundColor: palette.grayscale[300],
            color: palette.grayscale[700],
            padding: '4px 10px',
            lineHeight: '15px',
          }}>
          Dados de produção e consumo
        </Typography>
        <table style={{ borderSpacing: '0px', width: '100%' }}>
          <tr>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="10">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Potência | Produção
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {(
                  Number(
                    proposal.sizings?.find(
                      (sizing) => sizing?.id == quote?.dimensioning.id,
                    ).potenciaCC,
                  ) / 1000
                ).toFixed(2)}{' '}
                kWp
              </Typography>
            </td>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="10">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Consumo | Benefício
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {proposal.sizings
                  ?.find((sizing) => sizing?.id == quote?.dimensioning.id)
                  .consumoMensalMedio.toFixed(2)}{' '}
                kWh/mês
              </Typography>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="5">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Produção mensal
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {(
                  Number(
                    proposal.sizings?.find(
                      (sizing) => sizing?.id == quote?.dimensioning.id,
                    ).energiaGerada,
                  ) / 12
                ).toFixed(2)}{' '}
                kWh
              </Typography>
            </td>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="5">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Produção anual
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {proposal.sizings
                  ?.find((sizing) => sizing?.id == quote?.dimensioning.id)
                  .energiaGerada.toFixed(2)}{' '}
                kWh
              </Typography>
            </td>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="5">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Menos emissões de CO2
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {proposal.sizings
                  ?.find((sizing) => sizing?.id == quote?.dimensioning.id)
                  .co2.toFixed(0)}{' '}
                Tons
              </Typography>
            </td>
            <td
              style={{
                border: '1px solid #DFE3E8',
                padding: '4px 10px',
              }}
              colSpan="5">
              <Typography
                style={{
                  color: palette.grayscale[500],
                  fontSize: '8px',
                  lineHeight: '12px',
                }}>
                Árvores plantadas
              </Typography>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: palette.grayscale[700],
                }}>
                {proposal.sizings
                  ?.find((sizing) => sizing?.id == quote?.dimensioning.id)
                  .arvores.toFixed(0)}{' '}
                Unid
              </Typography>
            </td>
          </tr>
          <tr>
            <td colSpan="20">
              <FinancialStats
                pdf
                proposal={proposal.sizings?.find(
                  (sizing) => sizing?.id == quote?.dimensioning.id,
                )}
              />
            </td>
          </tr>
        </table>
      </div>
      <div style={{ width: '100%' }}>
        <table style={{ borderSpacing: '0px', width: '100%' }}>
          <tr style={{ backgroundColor: palette.grayscale[300] }}>
            <td style={{ padding: '4px 10px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: palette.grayscale[700],
                }}>
                Mês
              </Typography>
            </td>
            <td style={{ padding: '4px 10px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: palette.grayscale[700],
                }}>
                Consumo inicial
              </Typography>
            </td>
            <td style={{ padding: '4px 10px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: palette.grayscale[700],
                }}>
                Consumo projetado
              </Typography>
            </td>
            <td style={{ padding: '4px 10px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: palette.grayscale[700],
                }}>
                Produção
              </Typography>
            </td>
            <td style={{ padding: '4px 10px' }}>
              <Typography
                style={{
                  fontSize: '10px',
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: palette.grayscale[700],
                }}>
                Diferença
              </Typography>
            </td>
          </tr>
          {Array.from(Array(parseInt(12)).keys()).map((item, i) => (
            <tr key={i}>
              <td
                style={{
                  border: '1px solid #DFE3E8',
                  padding: '4px 10px',
                }}>
                <Typography
                  style={{
                    fontSize: '8px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    color: palette.grayscale[700],
                  }}>
                  {monthList[i]}
                </Typography>
              </td>
              <td
                style={{
                  border: '1px solid #DFE3E8',
                  padding: '4px 10px',
                }}>
                <Typography
                  style={{
                    fontSize: '8px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    color: palette.grayscale[700],
                  }}>
                  {parseFloat(
                    proposal.sizings?.find(
                      (sizing) => sizing?.id == quote?.dimensioning.id,
                    ).consumoMensalInicial[i],
                  ).toFixed(2)}{' '}
                  kWh
                </Typography>
              </td>
              <td
                style={{
                  border: '1px solid #DFE3E8',
                  padding: '4px 10px',
                }}>
                <Typography
                  style={{
                    fontSize: '8px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    color: palette.grayscale[700],
                  }}>
                  {parseFloat(
                    proposal.sizings?.find(
                      (sizing) => sizing?.id == quote?.dimensioning.id,
                    ).consumoMensal[i],
                  ).toFixed(2)}{' '}
                  kWh
                </Typography>
              </td>
              <td
                style={{
                  border: '1px solid #DFE3E8',
                  padding: '4px 10px',
                }}>
                <Typography
                  style={{
                    fontSize: '8px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    color: palette.grayscale[700],
                  }}>
                  {parseFloat(
                    proposal.sizings?.find(
                      (sizing) => sizing?.id == quote?.dimensioning.id,
                    ).comp[i + 1],
                  ).toFixed(2)}{' '}
                  kWh
                </Typography>
              </td>
              <td
                style={{
                  border: '1px solid #DFE3E8',
                  padding: '4px 10px',
                }}>
                <Typography
                  style={{
                    fontSize: '8px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    color: palette.grayscale[700],
                  }}>
                  {parseFloat(
                    Number(
                      proposal.sizings?.find(
                        (sizing) => sizing?.id == quote?.dimensioning.id,
                      ).comp[i + 1],
                    ) -
                      Number(
                        proposal.sizings?.find(
                          (sizing) => sizing?.id == quote?.dimensioning.id,
                        ).consumoMensalInicial[i],
                      ),
                  ).toFixed(2)}{' '}
                  kWh
                </Typography>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  )
}
