import styled from 'styled-components'

export const DescriptionContainer = styled.div`
  margin-top: 18px;
  width: 100%;

  p {
    width: 680px;
  }

  @media (max-width: 768px) {
    p {
      width: 100%;
      text-align: center;
    }
  }
`
