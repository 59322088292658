import * as S from './styles'
import { useState } from 'react'
import { SendModal } from 'components/SendModal'
import { SendEmailForm } from 'components/SendModal/components/SendEmailForm'
import { SendWhatsAppForm } from 'components/SendModal/components/SendWhatsAppForm'
import { LinkIcon, MailIcon, ExportIcon, WhatsappIcon } from '@77sol/icons/dist'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { type IShareType } from './types/shareType'
import { useProposalShare } from './hooks/useProposalShare'
import { Button, TableList } from '@77sol/core'
import {
  PROPOSAL_SHARE_ICONS,
  PROPOSAL_SHARE_TITLES,
} from './constants/proposalTitles'

interface IOrderShareModalProps {
  isOpen: boolean
  onlyButtons: boolean
  proposal: IProposalDTO
  onClose: () => void
  onSend: (type: IShareType) => void
}

export function ProposalShareModal({
  isOpen,
  onlyButtons,
  proposal,
  onClose,
  onSend,
}: IOrderShareModalProps) {
  const [shareType, setShareType] = useState<IShareType>('')

  const {
    proposalTableData,
    handleEmailSubmit,
    handleWhatsAppSubmit,
    handleProposalLinkCopy,
    handleProposalExport,
  } = useProposalShare({
    proposal,
    onClose,
    onSend,
  })

  const handleFormCancel = () => {
    setShareType('')
  }

  const handleShareType = (type: IShareType) => {
    onSend(type)
    setShareType(type)
  }

  return (
    <SendModal
      open={isOpen}
      onClose={onClose}
      Icon={PROPOSAL_SHARE_ICONS[shareType] || PROPOSAL_SHARE_ICONS.default}
      title={PROPOSAL_SHARE_TITLES[shareType] || PROPOSAL_SHARE_TITLES.default}
    >
      {shareType === 'email' && (
        <SendEmailForm
          customer={proposal?.customer}
          onSubmit={handleEmailSubmit}
          onCancel={handleFormCancel}
        />
      )}
      {shareType === 'whatsapp' && (
        <SendWhatsAppForm
          customer={proposal?.customer}
          onSubmit={handleWhatsAppSubmit}
          onCancel={handleFormCancel}
        />
      )}
      {!shareType && (
        <>
          {!onlyButtons && (
            <TableList
              columns={[
                '',
                'ID',
                'Dimensionamentos',
                'Cotações',
                'Status',
                'Criação',
                'Financiamento',
              ]}
              values={[proposalTableData]}
              noSelectRow
              LastItem={null}
              onRowClick={() => null}
            />
          )}
          <S.Buttons>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<LinkIcon />}
              onClick={handleProposalLinkCopy}
            >
              Copiar link
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<WhatsappIcon />}
              onClick={() => {
                handleShareType('whatsapp')
              }}
            >
              Enviar por WhatsApp
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<ExportIcon />}
              onClick={handleProposalExport}
            >
              Exportar
            </Button>
            <Button
              size="small"
              color="primary"
              startIcon={<MailIcon />}
              onClick={() => {
                handleShareType('email')
              }}
            >
              Enviar por e-mail
            </Button>
          </S.Buttons>
        </>
      )}
    </SendModal>
  )
}
