import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import { type FieldValues } from 'react-hook-form'

export const SelectAlgorithm = <T extends FieldValues>({
  ...props
}: Omit<ISelectProps<T>, 'options'>) => {
  const statesOptions = [
    { label: 'Velho', value: 'old' },
    { label: 'Novo', value: 'new' },
  ]

  return <Select {...props} options={statesOptions} />
}
