import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(({ breakpoints, palette }) => ({
  paperRendered: {
    width: '100%',
    padding: '16px 16px 32px 16px',
    display: 'flex',
    height: '100%',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  outlinedButtonRed: {
    backgroundColor: 'none',
    border: '1px solid #EB5757',
    color: '#EB5757',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  rightBorder: {
    '@media (min-width:350px)': {
      borderRight: 'none',
      paddingRight: '0px',
    },
    '@media (min-width:1920px)': {
      borderRight: `1px solid ${palette.grayscale[300]}`,
      paddingRight: '24px',
    },
  },
  paddingRight: {
    '@media (min-width:350px)': {
      paddingRight: '0px',
    },
    '@media (min-width:1920px)': {
      paddingRight: '24px',
    },
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  angleStep: {
    [breakpoints.down('md')]: {
      marginTop: '0px !important',
    },
  },
}))
