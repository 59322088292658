import React from 'react'
import { ContainerPdf } from '..'

const ContainerListPdf = ({
  containers,
  proposal,
  logo,
  userName,
  setStatePdf,
  stateQuotesPdf,
  setStateQuotesPdf,
  editRef,
  checkPdfDragAndDrop,
  checkDragAndDrop,
  updatePdfStructure,
  updateStructure,
  isEditing,
  setIsEditing,
  isEditingMore,
  setIsEditingMore,
}) => {
  return containers.map((container, index) => (
    <div style={{ width: '100%' }} key={container.id}>
      {container.visible && (
        <ContainerPdf
          containers={containers}
          container={container}
          index={index}
          proposal={proposal}
          logo={logo}
          userName={userName}
          setStatePdf={setStatePdf}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isEditingMore={isEditingMore}
          setIsEditingMore={setIsEditingMore}
          stateQuotesPdf={stateQuotesPdf}
          setStateQuotesPdf={setStateQuotesPdf}
          editRef={editRef}
          checkPdfDragAndDrop={checkPdfDragAndDrop}
          checkDragAndDrop={checkDragAndDrop}
          updatePdfStructure={updatePdfStructure}
          updateStructure={updateStructure}
        />
      )}
    </div>
  ))
}

export default ContainerListPdf
