import React from 'react'
import DeliveryAddress from 'components/DeliveryAddress'
import OrderHistory from 'containers/OrderHistory'
import OrderTimeline from 'containers/OrderTimeline'

import ScheduledDelivery from './ScheduledDelivery'

import { type IOrderDTO } from 'dto/OrderDTO'

import * as S from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'

interface IStatusProps {
  activeStep: number
  order: IOrderDTO
  address: {
    streetName: string
    city: string
    zipCode: string
    state: string
    number: string
  }
}

const Status: React.FC<IStatusProps> = ({ activeStep, address, order }) => {
  const { logEvent } = useAmplitude()
  const showSceduledDelivery =
    order.data || order.data_pagamento || order.prazo_entrega

  return (
    <S.Container>
      {showSceduledDelivery && <ScheduledDelivery order={order} />}
      <OrderTimeline orderId={order.id} currentStatus={activeStep} />
      <OrderHistory
        orderId={order.id}
        onClick={() => {
          logEvent(ordersTracker.actions.clickOrderHistory, {
            origin: '/pedidos - Detalhes do pedido',
          })
        }}
      />
      <S.AddressContainer>
        <DeliveryAddress address={address} />
      </S.AddressContainer>
    </S.Container>
  )
}

export default Status
