import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { type IActions, type IState } from './types'

const DEFAULT_VALUES = {
  isOpen: false,
}

const useSidebarDesktopStore = create<IState & IActions>()(
  persist(
    devtools(
      (set) => ({
        ...DEFAULT_VALUES,
        setIsOpen: (isOpen) => {
          set({ isOpen })
        },
        reset: () => {
          set(DEFAULT_VALUES)
        },
      }),
      { name: 'SidebarDesktopStore' },
    ),
    {
      name: '@77sol-admin:sidebarStore',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export { useSidebarDesktopStore }
