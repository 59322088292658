import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'
import { useValidateKit } from './useValidateKit'

export const useCreateProject = () => {
  const setOpenProjectModal = useEquipmentContainerStore(
    (state) => state.setOpenModalToCreateProject,
  )

  const setContinueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.setContinueQuotationWithChangesCallback,
  )

  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )

  const { validateAllQuotations } = useValidateKit()

  const handleCreateProject = () => {
    const hasChanged = validateAllQuotations()

    if (hasChanged) {
      setContinueQuotationWithChangesCallback(() => {
        setOpenProjectModal(true)
      })
      setOpenModalContinueWithChanged(true)
      // eslint-disable-next-line no-useless-return
      return
    }

    setOpenProjectModal(true)
  }

  return { handleCreateProject }
}
