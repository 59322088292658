import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { AccordionPaymentMethod } from 'components'
import { Button, Dropdown, Input, Tooltip, Typography } from '@77sol/core'
import { InfoIcon } from '@77sol/icons/dist'
import { INITIAL } from './initial'
import { OPTIONS_BILLET } from './options'
import { CONVERT_BILLET, countBillets, separeBillets, sumValues } from './utils'
import useStyles from './styles'

function Billets({
  minPercent = 0,
  userMethods = [],
  proposalMethods = null,
  setProposalMethod = () => {},
}) {
  const [listBillets, setListBillets] = useState([])
  const [checkedBillet, setCheckedBillet] = useState(false)
  const [quantityBillets, setQuantityBillets] = useState(0)
  const [expandAccordion, setExpandAccordion] = useState(false)
  const [valuesBillets, setValuesBillets] = useState(INITIAL.VALUES)

  const totalValues = sumValues(valuesBillets)
  const classes = useStyles()

  useEffect(() => {
    const userConfigs = userMethods || []
    setListBillets([
      userConfigs[0] || {},
      userConfigs[1] || {},
      userConfigs[2] || {},
      userConfigs[3] || {},
    ])
  }, [userMethods])

  useEffect(() => {
    setValuesBillets(proposalMethods || {})
    setCheckedBillet(Boolean(proposalMethods))
    setQuantityBillets(countBillets(proposalMethods || {}))
  }, [proposalMethods])

  const handleUpdateMethod = () => {
    setProposalMethod(valuesBillets)
  }

  const handleCheckedMethod = (checked) => {
    setProposalMethod(checked ? INITIAL.VALUES : null)
  }

  const handleQuantityBillet = (quantity = quantityBillets) => {
    setQuantityBillets(quantity)
    setValuesBillets({
      ...valuesBillets,
      billet_one: separeBillets(1, minPercent, parseInt(quantity)),
      billet_two: separeBillets(2, minPercent, parseInt(quantity)),
      billet_three: separeBillets(3, minPercent, parseInt(quantity)),
      billet_four: separeBillets(4, minPercent, parseInt(quantity)),
    })
  }

  return (
    <AccordionPaymentMethod
      title="Boleto"
      subtitle={`Pagamento em ${quantityBillets} boletos`}
      checked={checkedBillet}
      expand={expandAccordion}
      setExpand={setExpandAccordion}
      setChecked={handleCheckedMethod}>
      <Box>
        <Box marginBottom={2}>
          {totalValues === 100 ? (
            <Typography type="xsmall" color="grayscale" colorWeight="400">
              Organize a distribuição do pagamentos dentro da quantidade de
              boletos.
            </Typography>
          ) : (
            <Typography type="xsmall" color="red" colorWeight="300">
              A distribuição de pagamento dos boletos não é igual a 100%.
              Organize novamente as porcentagens.
            </Typography>
          )}
        </Box>
        <Box>
          <Box display="flex" margin={2}>
            <Typography type="xsmall">Quantidade de boletos</Typography>
            <Tooltip title="O primeiro boleto possui como valor mínimo os equipamentos da cotação">
              <Box className={classes.infoIcon}>
                <InfoIcon width={10} />
              </Box>
            </Tooltip>
          </Box>
          <Dropdown
            fullWidth
            title="Selecione a quantidade"
            value={quantityBillets}
            options={OPTIONS_BILLET}
            onBlur={handleUpdateMethod}
            onChange={handleQuantityBillet}
            disabled={minPercent === 100}
          />
        </Box>
        <Box>
          {listBillets
            .map(({ title }, i) => (
              <Box key={i}>
                <Box margin={2}>
                  <Box display="flex">
                    <Typography
                      type="small"
                      color="grayscale"
                      colorWeight="700">
                      {title || `${i + 1}º Boleto`}
                    </Typography>
                  </Box>
                </Box>
                <Input
                  type="number"
                  placeholder="0"
                  endAdornment="%"
                  value={valuesBillets[CONVERT_BILLET[i]]}
                  onBlur={handleUpdateMethod}
                  onChange={(value) =>
                    setValuesBillets((state) => {
                      const valuesUpdated = {
                        ...state,
                        [CONVERT_BILLET[i]]: value,
                      }
                      if (sumValues(valuesUpdated) <= 100) {
                        return valuesUpdated
                      }
                      return valuesBillets
                    })
                  }
                />
              </Box>
            ))
            .filter((_, i) => i < quantityBillets)}
        </Box>
        <Box marginTop={2}>
          <Button
            fullWidth
            color="primary"
            onBlur={() => handleUpdateMethod()}
            onClick={() => handleQuantityBillet()}>
            Redefinir porcentagens
          </Button>
        </Box>
      </Box>
    </AccordionPaymentMethod>
  )
}

export default Billets
