import { separateProductName } from 'utils/productName'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useMemo } from 'react'

interface IUseProductName {
  product: IQuotationProductDTO | null
}

export function useProductName({ product = null }: IUseProductName) {
  const { productName, availabilityMessage } = useMemo(() => {
    if (product) {
      return separateProductName(product.titulo, product.available_in)
    }

    return {
      productName: '',
      availabilityMessage: '',
    }
  }, [product])

  return {
    productName,
    availabilityMessage,
  }
}
