import React from 'react'
import classNames from 'classnames'
import { Box, TableCell, TableRow } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/ArrowForwardIos'
import { Typography } from '@77sol/core'
import { isEmpty } from '../../utils'
import styles from './styles'

const removeRowDataFromTableCells = ({ rowData, ...rest }) => ({
  ...rest,
})

function TableLine({
  onRowClick,
  values,
  rowIndex,
  isRowSelected,
  onClickFullRow,
  noArrow,
  LastItem,
  rowOutlined,
  disabled,
  StartItem,
  headerSticky,
  fontSize,
  ...props
}) {
  const classes = styles({
    isRowSelected,
    rowOutlined,
    headerSticky,
    hightlight: values?.rowData?.hightlight,
  })

  const handleCLick = () => {
    onRowClick({ ...values, rowData: { ...values.rowData, rowIndex } })
  }

  const valuesToShow = removeRowDataFromTableCells(values)
  const tableValues = Object.values(valuesToShow)

  return (
    <TableRow
      {...props}
      classes={{
        root: disabled ? classes.rootDisabled : classes.root,
        selected: classes.selected,
      }}
      onClick={() => (onClickFullRow ? handleCLick() : null)}
      selected={isRowSelected}
    >
      {!isEmpty(tableValues) && (
        <>
          {StartItem && (
            <TableCell
              className={classNames(
                classes.lastItem,
                classes.tableCell,
                classes.firstItem,
              )}
              align="right"
            >
              <Box display="flex" justifyContent="flex-end">
                <StartItem
                  isRowSelected={isRowSelected}
                  row={{
                    ...values,
                    rowData: { ...values.rowData, rowIndex },
                  }}
                />
              </Box>
            </TableCell>
          )}
          {tableValues.map((value, index) => (
            <TableCell
              key={index}
              onClick={() => handleCLick()}
              className={classes.tableCell}
              align="left"
            >
              {value && value.type === 'component' ? (
                <div style={{ width: 'fit-content' }}>
                  <value.Component isRowSelected={isRowSelected} />
                </div>
              ) : (
                <Typography
                  colorWeight={disabled && '400'}
                  color={
                    rowOutlined
                      ? 'grayscale'
                      : isRowSelected
                      ? 'white'
                      : 'grayscale'
                  }
                  size={fontSize && fontSize}
                >
                  {value}
                </Typography>
              )}
            </TableCell>
          ))}
          {LastItem && (
            <TableCell
              className={classNames(classes.lastItem, classes.tableCell)}
              align="right"
            >
              <Box display="flex" justifyContent="flex-end">
                <LastItem
                  isRowSelected={isRowSelected}
                  row={{
                    ...values,
                    rowData: { ...values.rowData, rowIndex },
                  }}
                />
              </Box>
            </TableCell>
          )}
          {!noArrow && (
            <TableCell
              className={classes.arrowCell}
              align="right"
              onClick={() => handleCLick()}
            >
              <ArrowIcon
                width="32px"
                height="32px"
                style={{ fill: isRowSelected ? 'white' : 'transparent' }}
              />
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  )
}

export default TableLine
