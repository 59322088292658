import { type Address } from 'types/localization'

type IAddressKeys = keyof Address

export function addressEquals(first: Address, secound: Address) {
  const addressFields: IAddressKeys[] = [
    'cep',
    'endereco',
    'numero',
    'bairro',
    'complemento',
    'cidade',
    'estado',
  ]

  return addressFields.every((field) => first[field] === secound[field])
}
