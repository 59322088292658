import { Badge } from 'components/Badge'

interface IWarrantyProps {
  size?: string
  years: string | number
}

export function Warranty({ size, years }: IWarrantyProps) {
  const label = `Garantia de ${years} anos`

  return <Badge size={size} color="primary" label={label} />
}
