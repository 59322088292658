export enum CATEGORY_ENUM {
  INVERSOR = 61,
  MODULO = 62,
  ESTRUTURA = 63,
  CONECTOR = 64,
  CABO_PRETO = 65,
  STRING_BOX = 66,
  CABO_VERMELHO = 68,
  CABO_TRONCO = 69,
  END_CAP = 70,
  COMUNICADOR = 73,
  MICRO_INVERSOR = 74,
  OTIMIZADOR = 75,
  TRANSFORMADOR = 76,
  CONJUNTO_PORCA_E_PARAFUSO = 77,
  CONECTOR_CABO_TRONCO = 78,
  PORTA_CABO_TRONCO = 79,
}
