export function saveFile(file: Blob, fileName: string) {
  const aElement = document.createElement('a')
  aElement.setAttribute('download', fileName)
  const href = URL.createObjectURL(file)
  aElement.href = href
  aElement.setAttribute('target', '_blank')
  aElement.click()
  aElement.remove()
  URL.revokeObjectURL(href)
}
