import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grayscale[800],
  },
  iconDisabled: {
    color: theme.palette.grayscale[400],
  },
  root: {
    position: 'relative',
    backgroundColor: theme.palette.grayscale[100],
    '& > .title': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
}))
