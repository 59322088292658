import styled from 'styled-components'

export const AddressContainer = styled.div`
  background-color: #ffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 6px;
`

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`
