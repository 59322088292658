import { type HTMLAttributes } from 'react'
import { StyledInsuranceTrigger } from './styles'
import { ChevronDownIcon } from 'lucide-react'
import { useAcl } from 'acl/hooks/useAcl'
import { type PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { INSURANCE_YEARS_OLD_LIMIT } from 'containers/Financing/constants'

interface IInsuranceTriggerProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen: boolean
  isEnabledInsurance: boolean
  clientType: PERSON_TYPE_ENUM
  clientYearsOld: number
}

export function InsuranceTrigger({
  isOpen = false,
  isEnabledInsurance = false,
  clientType,
  clientYearsOld,
  ...rest
}: IInsuranceTriggerProps) {
  const { userType } = useAcl()

  if (
    userType === 'distributor' ||
    clientType === 'cnpj' ||
    clientYearsOld > INSURANCE_YEARS_OLD_LIMIT
  ) {
    return (
      <StyledInsuranceTrigger {...rest} isOpen={isOpen}>
        Proteção indisponível <ChevronDownIcon />
      </StyledInsuranceTrigger>
    )
  }

  const BUTTON_TITLE = isEnabledInsurance
    ? 'Proteção inclusa'
    : 'Proteção não inclusa'

  return (
    <StyledInsuranceTrigger {...rest} isOpen={isOpen}>
      {BUTTON_TITLE} <ChevronDownIcon />
    </StyledInsuranceTrigger>
  )
}
