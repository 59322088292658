import { SaveProposalRedux } from 'store/actions'
import { useSelector, useDispatch } from 'react-redux'

export function useByPassSaveProposal() {
  const dispatch = useDispatch()

  const currentProposal = useSelector((state) => state?.Reducer1?.proposal)
  const byPassSaveProposal = Boolean(currentProposal?.byPassSaveProposal)

  function handleByPassSaveProposal(active) {
    if (!currentProposal?.id) {
      return
    }

    dispatch(
      SaveProposalRedux({
        ...currentProposal,
        byPassSaveProposal: active,
      }),
    )
  }

  return {
    byPassSaveProposal,
    handleByPassSaveProposal,
  }
}
