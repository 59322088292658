import styled from 'styled-components'
import { colors } from '@77sol-ui/tokens'
import { Button } from '@77sol-ui/atoms'
import { Checkbox } from '@77sol-ui/atoms'

interface KitCardWrapperProps {
  isChecked: boolean
}

export const KitCardWrapper = styled.button<KitCardWrapperProps>`
  margin-top: 24px;
  background-color: #ffff;
  border-radius: 8px;
  padding: 26px 20px;
  height: 656px;
  text-align: unset;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  border-color: ${({ isChecked }) =>
    isChecked ? colors.blue[300] : colors.gray[100]};

  & .checkbox {
    margin-left: auto;
  }

  & .logoContainer {
    margin-bottom: auto;
    display: grid;
    place-items: center;
    width: 100%;
    margin-top: 8%;
    min-height: 180px;
    gap: 12px;

    & img {
      width: 50%;
      max-width: 200px;
    }
  }

  .content {
    margin: 20px 0px;
  }

  .title,
  .total {
    color: #363636;
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.6px;
  }

  .description {
    margin-top: 12px;
    color: #828282;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .total {
    margin-top: 24px;
  }

  .cta {
    flex: 1;
    align-items: flex-end;
    display: flex;
    width: 100%;
  }

  hr {
    margin-top: 12px;
    color: #ccc;
  }

  .delivery-info p {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 12px;
    color: #828282;
    font-family: DM Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .delivery-info p > svg {
    width: 16px;
    color: #828282;
  }
`

export const ChooseKitButton = styled(Button)`
  width: 100%;
  &:disabled {
    background-color: #ffff;
  }
`

export const CheckboxStyled = styled(Checkbox)`
  button:disabled {
    background-color: #ffff;
  }
`
