import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;

  & span {
    color: ${palette.grayscale[700]};
  }

  & span:nth-child(4) {
    color: ${palette.grayscale[500]};
    font-size: 14px;
  }

  & .title {
    font-weight: 700;
  }

  & .locationContainer {
    background-color: ${palette.primary[100]};
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 50%;
  }
`
