import styled from 'styled-components'

export const ListContainer = styled.div`
  height: calc(var(--app-height) - 260px);
  overflow: auto;

  @media (min-width: 1280px) {
    height: calc(var(--app-height) - 210px);
  }
`
