import { ScrollTable } from '@77sol-ui/molecules'
import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Wrapper = styled(ScrollTable.Container)<{ numberOfRows: number }>`
  height: ${(props) =>
    `calc(72px * ${props.numberOfRows} + 60px + 75px + 8px)`};

  .table {
    height: fit-content;
  }

  .table-scroll-area {
    flex: unset;
  }

  .table-scroll-area:has(tbody > tr) {
    height: 100%;
  }

  .table-footer {
    align-items: end;
    border: none;
  }

  > [role='presentation'] {
    height: unset;
    flex: 1;
  }

  & button:disabled {
    cursor: default;
    opacity: 1;
  }
`

export const ButtonAsLink = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.blue[300]};
  font-weight: 700;
  padding: 2px;

  &:focus-visible {
    border-radius: 4px;
    background-color: ${colors.blue[50]};
  }

  font-family: DM Sans;
  font-size: 14px;
`
