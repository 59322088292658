import { Skeleton } from 'components/Skeleton'
import * as S from './styles'

export function ChooseKitSkeleton() {
  return (
    <S.SkeletonWrapper>
      <Skeleton height="300px" width="100%" />
      <Skeleton height="300px" width="100%" />
      <Skeleton height="300px" width="100%" />
    </S.SkeletonWrapper>
  )
}
