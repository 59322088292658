import { useMediaQuery } from '@material-ui/core'

export function useSlideStyle() {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const productDetailStyle = {
    position: !downSm ? 'relative' : 'absolute',
    ...(downSm && {
      backgroundColor: '#fff',
      inset: '0',
    }),
    maxHeight: 'var(--app-height)',
    overflowY: 'auto',
    width: downSm ? '100%' : '492px',
    transition: 'width 0.3s ease-in-out 0s',
    borderLeft: '2px solid #D9DBE9',
  }

  return { productDetailStyle }
}
