import { useToast } from '@77sol/core'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { useCancelProposalMutation } from 'domains/quotation/proposal-cancel/hooks/useCancelProposalMutation'
import useObserveQuery from 'hooks/useObserverQuery'

interface IUseModalSaveQuotationWrongProductProps {
  onSuccess: () => void
}

export function useModalSaveQuotationWrongProduct({
  onSuccess,
}: IUseModalSaveQuotationWrongProductProps) {
  const { dimensioningId } = useEquipmentContainerStore()
  const { onErrorOpenToast, onSuccessOpenToast } = useToast()
  const { mutate, isLoading } = useCancelProposalMutation({
    onSuccess: () => {
      onSuccessOpenToast('Cotação cancelada com sucesso')
      onSuccess()
    },
    onError: () => onErrorOpenToast('Não foi possível cancelar a cotação'),
  })

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  function handleCancelProposal() {
    if (!data?.proposal?.id) {
      return
    }
    mutate(data.proposal.id)
  }

  return {
    handleCancelProposal,
    isLoading,
  }
}
