import { type IQuotationDTO } from 'domains/dimensioning/services/types'

import { useScrollToCenter } from 'hooks/useScrollToCenter'
import { useCallback, useEffect, useRef } from 'react'
import { type IQuotationCardQueueProps } from '../types'

export function useQuotationCardQueue({
  quotationSelected,
}: Pick<IQuotationCardQueueProps, 'quotationSelected'>) {
  const scrollContainerRef = useRef(null)
  const { scrollToCenter } = useScrollToCenter()

  const handleScrollToCenter = useCallback(
    (quotation: IQuotationDTO | null) => {
      if (scrollContainerRef.current && quotation) {
        scrollToCenter(scrollContainerRef.current, `card-${quotation.id}`)
      }
    },
    [],
  )

  useEffect(() => {
    if (quotationSelected) {
      handleScrollToCenter(quotationSelected)
    }
  }, [quotationSelected])

  return {
    scrollContainerRef,
  }
}
