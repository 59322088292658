import { type ReactNode } from 'react'
import * as S from './styles'

interface CompareKitCardHeaderInfoProps {
  children: ReactNode
}

export function CompareKitCardHeaderInfo({
  children,
}: CompareKitCardHeaderInfoProps) {
  return <S.Title>{children}</S.Title>
}
