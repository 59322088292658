import { useQuery } from '@tanstack/react-query'
import { GET_DIMENSIONING } from '../constants/endpointKeys'
import { dimensioningService } from '../services/api'
import { type IDimensioningGETResponse } from '../services/types'

interface IUseGetDimensioningQuotationProps {
  dimensioningId: string
  onSuccess?: (response: IDimensioningGETResponse) => void
}

export const useGetDimensioningQuotation = ({
  dimensioningId,
  onSuccess,
}: IUseGetDimensioningQuotationProps) => {
  const data = useQuery({
    queryKey: [GET_DIMENSIONING, Number(dimensioningId)],
    queryFn: async () => {
      const response = await dimensioningService.getQuotations(dimensioningId)
      onSuccess?.(response)
      return response
    },
  })
  return data
}
