import { Alert } from '@77sol-ui/atoms'
import * as S from '../../styles'
import { InsuranceSwitch } from '../Switch'
import { InsuranceInformation } from '../InsuranceInformation'
import { useInsuranceValue } from '../../hooks/useInsuranceValue'
import { type IInsuranceControl } from '../../types/insuranceControl'

interface IInsuranceAlertProps extends IInsuranceControl {}

export function IntegratorContent({
  financingValue = 0,
  isEnabledInsurance,
  handleControlInsurance,
}: IInsuranceAlertProps) {
  const { insuranceValue } = useInsuranceValue({
    financingValue,
    isEnabledInsurance,
  })

  const FORMATED_VALUE = insuranceValue.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })

  const alertVariant = isEnabledInsurance ? 'info' : 'danger'

  return (
    <Alert.Root variant={alertVariant} rounded>
      <S.StyledRootWrapper>
        <InsuranceSwitch
          isChecked={isEnabledInsurance}
          onChange={handleControlInsurance}
        />
        <Alert.Content>
          <S.StyledTitle>
            Aprimore a segurança do seu financiamento contra imprevistos com a
            Proteção para Financiamentos da 77Sol.
          </S.StyledTitle>
          <S.StyledInsuranceAlertDescription
            data-testid="insurance-description-list"
            isEnabled={isEnabledInsurance}
          >
            <InsuranceInformation value={FORMATED_VALUE} />
            {!isEnabledInsurance && (
              <p className="attention-paragraph">
                ATENÇÃO: ao seguir em frente, seu cliente estará desprotegido!
              </p>
            )}
          </S.StyledInsuranceAlertDescription>
        </Alert.Content>
      </S.StyledRootWrapper>
    </Alert.Root>
  )
}
