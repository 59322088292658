import { defineState } from 'redux-localstore'

const INITIAL_STATE = {
  userLogged: {},
}

const initialState = defineState(INITIAL_STATE)('ReducerAuth')

export function ReducerAuth(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_LOGGED':
      return { ...state, userLogged: action.userLogged }
    default:
      return state
  }
}
