import palette from 'app_palette'
import { Button } from '@77sol/core'
import { IconButton, Tooltip } from '@material-ui/core'
import { BuildingGovIcon, EditIcon } from '@77sol/icons/dist'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'

export function BudgetActions({ icons = false, onEdit }) {
  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()

  if (icons) {
    return (
      <>
        <Tooltip title="Financiar">
          <IconButton
            id="projects_button_finance"
            aria-label="visualizar"
            onClick={setFinancingPerValueModalOpen}
          >
            <BuildingGovIcon color={palette.grayscale[800]} width="24px" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Editar">
          <IconButton
            id="quoteEdit_button_editBudget"
            aria-label="Editar"
            onClick={onEdit}
          >
            <EditIcon width="24" color={palette.grayscale[700]} />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <Button
        noMargin
        size="small"
        variant="outlined"
        onClick={setFinancingPerValueModalOpen}
      >
        Financiar
      </Button>
      <Button
        noMargin
        size="small"
        variant="outlined"
        id="quoteEdit_button_editBudget"
        onClick={onEdit}
      >
        Editar
      </Button>
    </>
  )
}
