import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialRadio from '@material-ui/core/Radio'
import clsx from 'clsx'
import styles from './styles'

function Radio({
  checked, setChecked, color, ...props
}) {
  const classes = styles({ color })
  const RadioClass = classNames(classes.radio)

  return (
    <MaterialRadio
      className={RadioClass}
      checked={checked}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      color="primary"
      onChange={(e) => setChecked(e.target.value)}
      {...props}
    />
  )
}

Radio.propTypes = {
  color: PropTypes.string,
}

Radio.defaultProps = {
  color: 'primary',
}

export default Radio
