import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { AccordionPaymentMethod } from 'components'
import { Dropdown, Typography } from '@77sol/core'
import { OPTIONS_INSTALLMENTS } from 'utils/constants'
import { INITIAL } from './initial'

function CreditCard({
  userMethods = {},
  proposalMethods = null,
  setProposalMethod = () => {},
}) {
  const [checkedCard, setCheckedCard] = useState(false)
  const [valuesCard, setValuesCard] = useState(INITIAL.VALUES)
  const [expandAccordion, setExpandAccordion] = useState(false)

  useEffect(() => {
    setCheckedCard(Boolean(proposalMethods))
    setValuesCard(proposalMethods || INITIAL.VALUES)
  }, [proposalMethods])

  const handleUpdateMethod = () => {
    setProposalMethod(valuesCard)
  }

  const handleCheckedMethod = (checked) => {
    const { installments: number_installments } = userMethods || {}
    setProposalMethod(checked ? { number_installments } : null)
  }

  return (
    <AccordionPaymentMethod
      title="Cartão de crédito"
      subtitle={`Pagamento em até ${valuesCard.number_installments || 0}x`}
      checked={checkedCard}
      expand={expandAccordion}
      setExpand={setExpandAccordion}
      setChecked={handleCheckedMethod}>
      <>
        <Box margin={2}>
          <Typography type="xsmall" color="grayscale" colorWeight="800">
            Quantidade máxima de parcelas
          </Typography>
        </Box>
        <Dropdown
          fullWidth
          title="Selecionar parcelas"
          options={OPTIONS_INSTALLMENTS}
          value={valuesCard.number_installments}
          onBlur={handleUpdateMethod}
          onChange={(number_installments) =>
            setValuesCard((state) => ({
              ...state,
              number_installments,
            }))
          }
        />
      </>
    </AccordionPaymentMethod>
  )
}

export default CreditCard
