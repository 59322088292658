import API from 'api'
import { useState } from 'react'

/**
 * Hook to check the blacklist and validate the data
 * @returns
 */
export const useBlacklist = () => {
  const [blacklist, setBlacklist] = useState([])

  const validBlacklist = blacklist.length === 0

  const existInBlacklist = (type = '') => {
    return blacklist.includes(type)
  }

  const consultBlacklist = async (type = '', data = '') => {
    try {
      if (type && data) {
        const {
          data: { blocked },
        } = await API.post('/black-list/list-person', {
          type,
          data,
        })
        if (blocked) {
          setBlacklist((prevState) => {
            return [...prevState, type].filter((key, i, newState) => {
              return newState.indexOf(key) === i
            })
          })
        } else {
          setBlacklist((prevState) => {
            return prevState.filter((key) => key !== type)
          })
        }
        return blocked
      }
      return false
    } catch {
      return false
    }
  }

  return {
    validBlacklist,
    existInBlacklist,
    consultBlacklist,
  }
}
