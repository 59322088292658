import { SmartCard } from 'components/SmartCard'
import styled from 'styled-components'

export interface IFlagProps {
  borderRadius: string
}

export const Flag = styled(SmartCard.Flag)<IFlagProps>`
  border-radius: ${(props) => props?.borderRadius};
`

export const FlagContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  gap: 8px;
`
