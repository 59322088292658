import { Title, Spacer, Typography, Button, Modal } from "@77sol/core";
import { CircleActionsAlertInfoIcon } from "@77sol/icons/dist";
import { Box, useMediaQuery } from "@material-ui/core";
import { isMobile } from 'react-device-detect';
import palette from "app_palette";
import { useStyles } from "./styles";

export function DiscardProposalModal({
  isOpenModal,
  setIsOpenModal,
  handleCancel,
  handleContinue,
  isCancelingQuotationOrProject,
  isQuotation,
  hasProviderError,
}) {
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const classes = useStyles(isMobile);
  return (
    <Modal
      open={isOpenModal}
      onClose={() => setIsOpenModal(false)}
    >
      <div className={classes.contentWrapper}>
        <Box className={classes.boxModal}>
          <Box display="flex" flexDirection={downXs ? 'column' : 'row'}>
            <CircleActionsAlertInfoIcon
              width="25"
              color={palette.primary[300]}
            />
            <Spacer size="12" direction={downXs ? "horizontal" : "vertical"} />
            <Title
              size="xsmall"
              color="primary"
              colorWeight="300"
              weight="bold"
              style={{ textAlign: 'center' }}
            >
              {hasProviderError
                ? `Cancelar ${isQuotation ? 'cotação' : 'projeto'}?`
                : `Sair ${isQuotation ? 'da cotação' : 'do projeto'}?`
              }
            </Title>
          </Box>
          <Spacer size="12" direction="horizontal" />
          <Typography style={{ textAlign: 'center', maxWidth: '800px' }} size="small">
            {hasProviderError
              ? `Ao seguir em frente, você estará saindo ${isQuotation ? 'da cotação' : 'do projeto'} sem salvar. ${isQuotation ? 'A cotação será cancelada' : 'O projeto será cancelado'}.`
              : `Você está prestes a sair ${isQuotation ? 'da cotação' : 'do projeto'}. Você pode salvar ${isQuotation ? 'essa cotação' : 'esse projeto'} na sua aba de Projetos, do menu lateral, para revisitar depois, ou pode excluí-la.`
            }
          </Typography>
          <Spacer size="24" direction="horizontal" />
          <div style={{ display: 'flex', flexDirection: downXs ? 'column' : 'row' }}>
            <Button
              variant="outlined"
              color="red"
              size="small"
              onClick={handleCancel}
              disabled={isCancelingQuotationOrProject}
              isLoading={isCancelingQuotationOrProject}
              fullWidth={downXs}
            >
              {hasProviderError
                ? `Cancelar ${isQuotation ? 'cotação' : 'projeto'}`
                : 'Excluir e sair'
              }
            </Button>
            <Spacer size="18" direction={downXs ? "horizontal" : "vertical"} />
            <Button
              color="primary"
              size="small"
              onClick={hasProviderError ? () => setIsOpenModal(false) : handleContinue}
              disabled={isCancelingQuotationOrProject}
              fullWidth={downXs}
            >
              {hasProviderError ? 'Continuar editando' : 'Salvar e sair'}
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
  )
}