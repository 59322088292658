import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import moment from 'moment'
import { Input, Typography } from 'components'
import { Spacer } from '@77sol/core'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '34px 24px',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
})

function ValidadeEdit(props) {
  const classes = useStyles()

  const {
    handleFinish, value, setValue,
  } = props
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <div className={classes.paperRendered}>
      <div>
        <Typography
          type={downSm ? 'text_small' : 'text_medium'}
          color="grayscale"
          colorWeight={downSm ? '800' : '700'}
        >
          Defina a validade dessa proposta
        </Typography>
        <Typography
          type="text_x_small"
          color="grayscale"
          colorWeight={downSm ? '600' : '500'}
        >
          Valores e disponibilidades dos produtos estão sujeitos a mudanças
        </Typography>
      </div>
      <Spacer size="16" direction="horizontal" />
      <div style={{ display: 'flex', width: '100%' }}>
        <Input
          type="date"
          value={value}
          setValue={setValue}
          submit={() => {}}
          onBlur={() => handleFinish(value)}
          placeholder="Data"
          minDate={moment()
            .format()
            .substring(0, 10)}
        />
      </div>
      {/*
            <Button disabled={!value} onClick={() => handleFinish(value)} className={classes.filledButton}>
                {loadingExtras ?
                    <CircularProgress disableShrink style={{color: '#FFF'}} size={20} thickness={4} />
                :
                    'Salvar alterações'
                }
            </Button>
            */}
    </div>
  )
}

export default ValidadeEdit
