import React from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {},
  },
}))(TableRow)

const useStyles = makeStyles((theme) => ({
  modalFluxo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  paperFluxo: {
    padding: theme.spacing(2, 4, 3),
    background: 'white',
    borderRadius: '0px',
    border: 'none',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
  tituloModalConfigMiddle: {
    textAlign: 'center',
    marginTop: '30px',
    color: '#707072',
    fontSize: '30px',
  },
  subtituloModalConfigMiddle: {
    textAlign: 'center',
    width: '100%',
    margin: 'auto',
    color: '#707072',
    fontSize: '16px',
    paddingTop: '20px',
    lineHeight: '1.2',
    marginBottom: '15px',
  },
}))

function CashFlowModal(props) {
  const classes = useStyles()
  const theme = useTheme()
  const {
    fluxos, retornoConfig, modalInfoFluxo, setModalInfoFLuxo,
  } = props

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalFluxo}
      open={modalInfoFluxo}
      onClose={() => setModalInfoFLuxo(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalInfoFluxo} style={{ border: 'none' }}>
        <div className={classes.paperFluxo}>
          <CloseIcon
            onClick={() => setModalInfoFLuxo(false)}
            style={{
              color: 'rgb(20,55,196)',
              position: 'fixed',
              top: '20px',
              right: '30px',
              fontSize: '40px',
              cursor: 'pointer',
              zIndex: '9999',
            }}
          />

          <Grid container>
            <Grid item lg={1} sm={1} xs={1} />
            <Grid item lg={10} sm={10} xs={10}>
              <Typography
                variant="h4"
                className={classes.tituloModalConfigMiddle}
              >
                Retorno de investimento
              </Typography>
              <Typography
                variant="h6"
                className={classes.subtituloModalConfigMiddle}
              >
                Na tabela abaixo é possível ver a projeção do investimento
                realizado ao longo de
                {' '}
                {retornoConfig}
                {' '}
                anos
              </Typography>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead style={{ border: '1px solid #1335c6' }}>
                    <StyledTableRow>
                      <StyledTableCell
                        style={{
                          border: '1px solid #1335c6',
                          color: '#1335c6',
                        }}
                        align="center"
                      >
                        #
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          border: '1px solid #1335c6',
                          color: '#1335c6',
                        }}
                        align="center"
                      >
                        Produção anual
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          border: '1px solid #1335c6',
                          color: '#1335c6',
                        }}
                        align="center"
                      >
                        Tarifa anual
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          border: '1px solid #1335c6',
                          color: '#1335c6',
                        }}
                        align="center"
                      >
                        Receita anual
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          border: '1px solid #1335c6',
                          color: '#1335c6',
                        }}
                        align="center"
                      >
                        Fluxo acumulado
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {fluxos &&
                      <>
                        {fluxos[0] && (
                          <StyledTableRow
                            style={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                          >
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              0
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              0 kWh
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              0
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {(0).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {(
                                -fluxos[0].receitaAnual
                                + fluxos[0].fluxoAcumuladoAnual
                              ).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {fluxos.slice(0, retornoConfig).map((fluxo, i) => (
                          <StyledTableRow
                            style={{
                              backgroundColor: theme.palette.background.paper,
                            }}
                            key={i}
                          >
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {i + 1}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {fluxo.producaoAnual.toFixed(2)}
                              kWh
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {fluxo.tarifaAnual.toFixed(2)}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {fluxo.receitaAnual.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                border: '1px solid #1335c6',
                                color: '#707072',
                              }}
                            >
                              {fluxo.fluxoAcumuladoAnual.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    }
                  </TableBody>
                </Table>
              </div>
            </Grid>
            <Grid item lg={1} sm={1} xs={1} />
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

export default CashFlowModal
