import { Modal } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Info } from 'lucide-react'
import * as S from './styles'

interface SaveAndQuitQuotationModalProps {
  open?: boolean
  onOpenChange?: () => void
  onSuccess: () => void
  onClose?: () => void
}

export function SaveAndQuitQuotationModal({
  open,
  onOpenChange,
  onSuccess,
  onClose,
}: SaveAndQuitQuotationModalProps) {
  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <S.Overlay />

        <S.Wrapper maxWidth="640px" maxHeight="90vh" id="modal-quit-quotation">
          <Modal.Close onClick={onClose} />
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Title>Deseja salvar e sair da cotação?</Modal.Title>
            <Modal.Description>
              Você está prestes a sair da cotação. Você pode salvar essa cotação
              na sua aba de projetos para revisitar depois.
            </Modal.Description>
          </Modal.Header>

          <S.Footer>
            <Button fullWidth variant="outline" onClick={onOpenChange}>
              Continuar editando
            </Button>

            <Button fullWidth onClick={onSuccess}>
              Salvar e sair
            </Button>
          </S.Footer>
        </S.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
