export enum EnumSizingType {
  ConsumptionAverage = 'average_consumption_kwh',
  AverageConsumptionValue = 'average_consumption_value',
  SystemPower = 'system_potency',
  MonthlyConsumption = 'monthly_consumption',
  BasicSystemPower = 'basic_system_potency',
  BasicAverageConsumptionValue = 'basic_average_consumption_value',
}

export const DETAILED_SIZING_TYPE = [
  EnumSizingType.ConsumptionAverage,
  EnumSizingType.AverageConsumptionValue,
  EnumSizingType.SystemPower,
  EnumSizingType.MonthlyConsumption,
]

export const BASIC_SIZING_TYPE = [
  EnumSizingType.BasicSystemPower,
  EnumSizingType.BasicAverageConsumptionValue,
]

export const BASIC_SIZING_HEADER_TEXT = {
  title: 'Dimensionamento básico',
  description: 'Faça um dimensionamento rápido e obtenha o kit ideal',
}

export const DETAILED_SIZING_HEADER_TEXT = {
  title: 'Dimensionamento completo',
  description: 'Calcule com precisão os dados do projeto',
}
