import { colors } from '@77sol-ui/tokens'
import { type IRootProps } from './types'
import styled, { css } from 'styled-components'

export const Wrapper = styled.button<Pick<IRootProps, 'isSelected'>>`
  width: 412px;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  background: white;
  box-shadow: 0 0 0 1px ${colors.gray[100]};
  transition: box-shadow, border-color 0.2s ease-in-out 0s;

  &:focus-visible {
    outline-color: ${colors.blue[300]};
  }

  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: 0 0 0 1px ${colors.blue[300]},
        rgb(236, 242, 254) 0px 0px 0px 4px;
    `}
`
