import { Modal } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Info, Loader2 } from 'lucide-react'
import * as S from './styles'
import { useModalSaveQuotationBeforeQuit } from './hooks/useModalSaveQuotationBeforeQuit'

interface IModalSaveQuotationBeforeQuitProps {
  open?: boolean
  onOpenChange?: (value: boolean) => void
  onSuccess: () => void
  onClose?: () => void
}

export function ModalSaveQuotationBeforeQuit({
  open,
  onOpenChange,
  onSuccess,
  onClose,
}: IModalSaveQuotationBeforeQuitProps) {
  const { handleCancelProposal, isLoading } = useModalSaveQuotationBeforeQuit({
    onSuccess,
  })

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <S.Overlay />

        <S.Wrapper maxWidth="640px" maxHeight="90vh" id="modal-quit-quotation">
          <Modal.Close onClick={onClose} />
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Title>Deseja sair da cotação?</Modal.Title>
            <Modal.Description>
              Você está prestes a sair da cotação. Você pode salvá-la na sua aba
              de projetos para revisitar depois, ou pode excluí-la.
            </Modal.Description>
          </Modal.Header>

          <S.Footer>
            <Button
              fullWidth
              variant="outline"
              onClick={handleCancelProposal}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 size={24} className="atm-animate-spinSlow" />
              ) : (
                'Excluir e sair'
              )}
            </Button>

            <Button fullWidth onClick={onSuccess} disabled={isLoading}>
              Salvar e sair
            </Button>
          </S.Footer>
        </S.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
