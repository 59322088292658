import React, { useState, useEffect } from 'react'
import { Backdrop, Fade, Modal, useMediaQuery, Grid } from '@material-ui/core'
import { Button, Paper, Spacer, Typography, Title } from '@77sol/core'
import { RefreshIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { Pro77SolTeam } from 'components/Adverts/Pro77SolTeam'
import { useSelector } from 'react-redux'

export function ModalReverterKit({
  isOpenRevertModal,
  setIsOpenRevertModal,
  handleRevertQuotationKit,
  isLoadingRevertKit,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const planInfos = useSelector((state) => state.ReducerProfile.planInfos)
  const userProfile = useSelector((state) => state.ReducerProfile.userProfile)

  const [isSelection77Campaign, setIsSelection77Campaign] = useState(false)
  useEffect(() => {
    if (userProfile?.tags) {
      userProfile.tags.forEach((tag) => {
        if (tag.slug === 'selecao77') {
          setIsSelection77Campaign(true)
        }
      })
    }
  }, [userProfile])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpenRevertModal}
      onClose={() => setIsOpenRevertModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={isOpenRevertModal} style={{ border: 'none' }}>
        <Paper
          style={{
            width: !downSm ? '70%' : '96%',
            padding: '24px',
            borderRadius: '12px',
          }}
        >
          <Spacer direction="horizontal" size={32} />
          <RefreshIcon
            width="50"
            height="50"
            color={palette.primary[300]}
            style={{
              display: 'block',
              margin: 'auto',
            }}
          />
          <Spacer direction="horizontal" size={32} />
          <Title
            size="small"
            color="primary"
            colorWeight={300}
            style={{
              textAlign: 'center',
              display: 'block',
            }}
          >
            Reverter
          </Title>
          <Typography
            color="grayscale"
            colorWeight={500}
            style={{
              width: downSm ? '90%' : '70%',
              textAlign: 'center',
              display: 'block',
              margin: 'auto',
            }}
          >
            Você está prestes a reverter as modificações manuais que fez no kit
            e voltar para os produtos da cotação original da plataforma.
          </Typography>

          {isSelection77Campaign && planInfos?.pago <= 0 && (
            <>
              <Spacer direction="horizontal" size={32} />

              <Pro77SolTeam badge setCurrentModalClose={setIsOpenRevertModal}>
                <Typography>
                  FURA FILA de Financiamento! Tenha sempre prioridade{' '}
                  <strong>em suas solicitações</strong>.
                </Typography>
                <Typography>
                  Faça parte agora da Seleção 77 e assine o 77PRO!
                </Typography>
              </Pro77SolTeam>
            </>
          )}

          <Spacer direction="horizontal" size={32} />

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setIsOpenRevertModal(false)}
                disabled={isLoadingRevertKit}
                fullWidth={downSm}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleRevertQuotationKit}
                disabled={isLoadingRevertKit}
                isLoading={isLoadingRevertKit}
                fullWidth={downSm}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
          <Spacer direction="horizontal" size={32} />
        </Paper>
      </Fade>
    </Modal>
  )
}
