import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useQuotationRemoveProduct } from 'domains/quotation/product/hooks/useQuotationRemoveProduct'
import { useState } from 'react'
import { useToast } from '@77sol/core'
import { useDispatch } from 'react-redux'
import { UpdateQuoteRedux } from 'store/actions'

interface IUseRemoveProductProps {
  product: IQuotationProductDTO | null
  quotationId?: number
  onSuccess?: () => Promise<void> | void
  onError?: () => Promise<void> | void
}

export function useRemoveProduct({
  product,
  quotationId,
  onSuccess,
  onError,
}: IUseRemoveProductProps) {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { mutateAsync: removeProduct } = useQuotationRemoveProduct()

  async function handleRemove() {
    setIsLoading(true)

    if (!product || !quotationId) {
      setIsLoading(false)

      return
    }

    try {
      const response = await removeProduct({
        product_id: product.id,
        quotation_id: quotationId,
      })

      dispatch(UpdateQuoteRedux(response))

      await onSuccess?.()
      onSuccessOpenToast('Produto excluído com sucesso!')
    } catch {
      await onError?.()
      onErrorOpenToast('Ocorreu um erro ao excluir o produto.')
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, handleRemove }
}
