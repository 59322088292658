/**
 * Convert grace options to dropdown
 * @param {*} shortages
 * @returns
 */
export const convertShortage = (shortages = []) => {
  return shortages.length
    ? shortages.map((shortage) => ({
        label: `${shortage} dias`,
        value: shortage,
      }))
    : null
}

/**
 * Convert installments options to dropdown
 * @param {*} installments
 * @returns
 */
export const convertInstallments = (installments = []) => {
  return installments.length
    ? installments.map((instellment) => ({
        label: `${instellment} parcelas`,
        value: instellment,
      }))
    : null
}

/**
 * Sum percent first billet
 * @param {*} quotation
 * @returns
 */
export const percentBillet = (quotation = {}) => {
  const { equipment_value = 0, total_value = 0 } = quotation
  return (parseFloat(equipment_value) / parseFloat(total_value)) * 100
}

/**
 * Check if there is a method selected
 * @param {*} methods
 * @param {*} ignore
 * @returns
 */
export const beOneMethod = (methods = {}, ignore = []) => {
  return Object.entries(methods).every(
    ([key, value]) => ignore.includes(key) || !value,
  )
}
