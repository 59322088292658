import { Button } from '@77sol-ui/atoms'
import { Modal, Table } from '@77sol-ui/molecules'
import { format } from 'date-fns'
import { Trash } from 'lucide-react'

interface DeleteProjectModalProps {
  isOpen: boolean
  onClose: () => void
  onContinue: () => void
  isLoading: boolean
  project: any
}

const COLUMNS_SIZE = '0.7fr 1.7fr 0.6fr 0.6fr 1.5fr'

export function DeleteProjectModal({
  isOpen,
  onClose,
  onContinue,
  isLoading,
  project,
}: DeleteProjectModalProps) {
  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />

        <Modal.Wrapper maxWidth="800px" maxHeight="90vh">
          <Modal.Close />

          <Modal.Header>
            <Modal.Icon icon={Trash} />
            <Modal.Title>
              Tem certeza que deseja excluir esse projeto?
            </Modal.Title>
            <Modal.Description>
              Essa ação não poderá ser desfeita.
            </Modal.Description>
          </Modal.Header>

          <Modal.Content>
            <Table.Root variant="bordered">
              <Table.Header headerTemplateColumns={COLUMNS_SIZE}>
                <Table.Head>Projeto</Table.Head>
                <Table.Head>Cliente</Table.Head>
                <Table.Head>Propostas</Table.Head>
                <Table.Head>Cotações</Table.Head>
                <Table.Head>Data</Table.Head>
              </Table.Header>
              <Table.Body>
                <Table.Row rowTemplateColumns={COLUMNS_SIZE}>
                  <Table.Cell mobileHead="Projeto">#{project?.id}</Table.Cell>
                  <Table.Cell mobileHead="Cliente">
                    {project?.customer ?? '-'}
                  </Table.Cell>
                  <Table.Cell mobileHead="Propostas">
                    {project?.proposals}
                  </Table.Cell>
                  <Table.Cell mobileHead="Cotações">
                    {project?.quotations}
                  </Table.Cell>
                  <Table.Cell mobileHead="Data">
                    {project?.updated_at &&
                      format(new Date(project?.updated_at), 'dd/MM/yyyy HH:mm')}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table.Root>
          </Modal.Content>

          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline" disabled={isLoading}>
                Cancelar
              </Button>
            </Modal.Close>

            <Button fullWidth onClick={onContinue} disabled={isLoading}>
              Excluir
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
