import { DropdownMenu } from '@77sol/core'
import { MoreInfo } from '@77sol/icons/dist'
import { type IOrder } from 'domains/loja/loja-pedidos/services/types'
import { type IOrderDTO } from 'dto/OrderDTO'
import { INTEGRATOR_MANUAL } from 'views/Pedidos/constants'
import * as S from './styles'
import OrderInvoice from 'components/OrderInvoice'
import { useState } from 'react'
import { useWhatsappSend } from 'hooks/useWhatsappSend'
import { DEFAULT_WHATSAPP_NUMBER } from 'constants/general'

interface MoreActionsMenuProps {
  order: IOrder
  orderIsCanceled: boolean
  orderIsReserved: boolean
  onClickTrackOrder: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: IOrderDTO,
  ) => void
  onDownloadInvoice?: () => void
  onCancelOrder: (id: string) => void
  onClickTalkToManager: () => void
  onDownlodManual?: () => void
}

export function MoreActionsMenu({
  order,
  orderIsCanceled,
  orderIsReserved,
  onClickTrackOrder,
  onDownloadInvoice,
  onCancelOrder,
  onClickTalkToManager,
  onDownlodManual,
}: MoreActionsMenuProps) {
  const [downloadInvoice, setDownloadInvoice] = useState(false)
  const { handleWhatsAppSend } = useWhatsappSend()

  const WHATSAPP_TO_SEND_MESSAGE =
    order?.cellphone_closer ?? DEFAULT_WHATSAPP_NUMBER

  const onOptionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation()
  }

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <S.Button>
            <MoreInfo />
          </S.Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content align="end">
            <DropdownMenu.Item
              onClick={onOptionClick}
              onSelect={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
              ) => {
                onClickTrackOrder(event, order as unknown as IOrderDTO)
              }}
              disabled={orderIsCanceled}
            >
              Rastrear pedido
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={onOptionClick}
              onSelect={() => {
                setDownloadInvoice(true)
                onDownloadInvoice?.()
              }}
              disabled={orderIsCanceled}
            >
              Fatura
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={onOptionClick}
              disabled={!order?.nota_fiscal}
              onSelect={() => {
                window.open(order?.nota_fiscal || '', '_blank')
              }}
            >
              Nota Fiscal
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={onOptionClick}
              onSelect={() => {
                window.open(INTEGRATOR_MANUAL, '_blank')
                onDownlodManual?.()
              }}
            >
              Manual do Integrador
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={onOptionClick}
              onSelect={() => {
                handleWhatsAppSend(
                  WHATSAPP_TO_SEND_MESSAGE,
                  `Olá, estou com dúvidas sobre o pedido #${order.codigo}.`,
                )
                onClickTalkToManager?.()
              }}
            >
              Falar com gerente
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={onOptionClick}
              onSelect={() => {
                onCancelOrder(String(order.id))
              }}
              disabled={!orderIsReserved}
            >
              Cancelar
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        {downloadInvoice && (
          <OrderInvoice
            stateOrder={[
              order.id,
              () => {
                setDownloadInvoice(false)
              },
            ]}
          />
        )}
      </DropdownMenu.Root>
    </>
  )
}
