import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  groupButtonMobile: () => ({
    padding: '4px',
    borderRadius: '10px',
    backgroundColor: palette.grayscale[200],
    display: 'flex',
  }),
}))

export default styles
