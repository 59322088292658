import { QUERY_KEYS } from 'services/constant'
import API from 'api'
import { type ProposalQuotationGETResponse } from './types'

export const proposalQuotation = {
  get: async (id: number) =>
    await API.get<ProposalQuotationGETResponse>(
      `${QUERY_KEYS.PROPOSAL.QUOTATION}/${id}`,
    ).then(({ data }) => data),
}
