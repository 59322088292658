import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { financingCreateSimulation } from '../services/api'
import {
  type INewFinancingSimulationPOSTResponse,
  type INewFinancingSimulationPOSTPayload,
} from '../services/types'

interface IUseFinancingCreateSimulationProps {
  onSuccess?: (data: INewFinancingSimulationPOSTResponse) => void
  onError?: () => void
}

export const useFinancingCreateSimulation = ({
  onSuccess,
  onError,
}: IUseFinancingCreateSimulationProps = {}) => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (payload: INewFinancingSimulationPOSTPayload) =>
      await financingCreateSimulation.post(payload),
    onSuccess,
    onError: () => {
      onErrorOpenToast(
        'Tivemos um problema ao tentar criar um novo financimento por valor, tente novamente mais tarde.',
      )
      onError?.()
    },
  })
}
