import { ORDER_STATUS_TRADUCTION_ENUM } from 'enums/order/OderStatusTraductionEnum'

export enum EnumOrderStatusClient {
  pedido_vencido = 1,
  reservado = 2,
  em_analise = 3,
  pago = 4,
  aguardando_chegada_de_equipamanetos = 5,
  fila_de_separacao = 6,
  montagem = 7,
  expedicao_e_faturamento = 8,
  em_coletao = 9,
  em_transporte = 10,
  pedido_perto_de_voce = 11,
  entregue = 12,
  cancelado = 13,
}

export const ORDER_CLOSED_CLIENT_STATUS = [
  EnumOrderStatusClient.pedido_vencido,
  EnumOrderStatusClient.cancelado,
]

export const STATUS_TAB = 0
export const PENDINGS_TAB = 1
export const ITEMS_TAB = 2

export const ENABLE_PENDING_FIELDS_ORDER = [EnumOrderStatusClient.reservado]

export enum EnumOrderStatus {
  pedido_vencido = '1',
  cancelado = '20',
  entregue = '19',
}

export const ORDER_CLOSED_STATUS = [
  ORDER_STATUS_TRADUCTION_ENUM.PEDIDO_VENCIDO,
  ORDER_STATUS_TRADUCTION_ENUM.CANCELADO,
]

export const TABS_ENUM = {
  STATUS: 'Status',
  PENDENCIAS: 'Pendências',
  ITENS: 'Itens',
  FATURAMENTO: 'Faturamento',
  OBSERVACOES: 'Observações',
}

const { STATUS, FATURAMENTO, ITENS, OBSERVACOES, PENDENCIAS } = TABS_ENUM

export const ORDER_TABS_WITH_PENDINGS = [
  STATUS,
  PENDENCIAS,
  ITENS,
  FATURAMENTO,
  OBSERVACOES,
]

export const ORDER_TABS_WITHOUT_PENDINGS = [
  STATUS,
  ITENS,
  FATURAMENTO,
  OBSERVACOES,
]

export const INTEGRATOR_MANUAL =
  'https://77solassets.s3.sa-east-1.amazonaws.com/77SOL+Manual+de+Integrador.pdf'
