import React, { useState } from 'react'
import { DigitalProposalContainer } from '..'

const DigitalProposalContainerList = React.memo(
  ({
    containers,
    investingContainers,
    investingSections,
    setState,
    stateEquipmentsSections,
    statePaymentSections,
    ProposalInfosSections,
    setStateInvesting,
    setStateInvestingSections,
    setStateEquipmentsSections,
    setStatePaymentSections,
    setProposalInfosSections,
    selectedQuote,
    isViewing,
    setIsViewing,
    editMode,
    setEditMode,
    proposal,
    size,
    paymentOptionsContainers,
    setPaymentOptionsContainers,
    moreInfosContainers,
    setMoreInfosContainers,
    ProposalSubInfosSections,
    setProposalSubInfosSections,
    tirVplContainers,
    setTirVplContainers,
    retornoSubCards,
    setRetornoSubCards,
    colors,
    budgetEdit,
    setBudgetEdit,
    returnData,
    setReturnData,
    handleGetBudget,
    isFromProposalList,
    setIsProPlanModalOpen,
    isUserProPlan,
    editRef,
    checkDragAndDrop,
    checkPdfDragAndDrop,
    updatePdfStructure,
    updateStructure,
  }) => {
    const [editingContainer, setEditingContainer] = useState('')

    return containers.map((container, index) => (
      <div key={index}>
        {container.visible && (
          <DigitalProposalContainer
            onClick={() => {
              if (
                container.id == 'scheduleCard' ||
                container.id == 'constructionImagesCard' ||
                Number.isInteger(container.id)
              ) {
                setEditingContainer(container.id)
              } else if (editingContainer == container.id) {
                setEditingContainer(false)
                setIsViewing(container.id)
                setEditMode(container.id)
              } else {
                setEditingContainer(container.id)
              }
            }}
            isEditing={editingContainer == container.id}
            container={container}
            index={index}
            key={container.id}
            allContainers={containers}
            setState={setState}
            setIsEditing={setEditingContainer}
            isViewing={isViewing}
            setIsViewing={setIsViewing}
            editMode={editMode}
            setEditMode={setEditMode}
            investingContainers={investingContainers}
            investingSections={investingSections}
            stateEquipmentsSections={stateEquipmentsSections}
            statePaymentSections={statePaymentSections}
            ProposalInfosSections={ProposalInfosSections}
            setStateInvesting={setStateInvesting}
            setStateInvestingSections={setStateInvestingSections}
            setStateEquipmentsSections={setStateEquipmentsSections}
            setStatePaymentSections={setStatePaymentSections}
            setProposalInfosSections={setProposalInfosSections}
            selectedQuote={selectedQuote}
            proposal={proposal}
            size={size}
            paymentOptionsContainers={paymentOptionsContainers}
            setPaymentOptionsContainers={setPaymentOptionsContainers}
            moreInfosContainers={moreInfosContainers}
            setMoreInfosContainers={setMoreInfosContainers}
            ProposalSubInfosSections={ProposalSubInfosSections}
            setProposalSubInfosSections={setProposalSubInfosSections}
            tirVplContainers={tirVplContainers}
            setTirVplContainers={setTirVplContainers}
            retornoSubCards={retornoSubCards}
            setRetornoSubCards={setRetornoSubCards}
            colors={colors}
            budgetEdit={budgetEdit}
            setBudgetEdit={setBudgetEdit}
            returnData={returnData}
            setReturnData={setReturnData}
            handleGetBudget={handleGetBudget}
            isFromProposalList={isFromProposalList}
            setIsProPlanModalOpen={setIsProPlanModalOpen}
            isUserProPlan={isUserProPlan}
            editRef={editRef}
            checkDragAndDrop={checkDragAndDrop}
            checkPdfDragAndDrop={checkPdfDragAndDrop}
            updatePdfStructure={updatePdfStructure}
            updateStructure={updateStructure}
          />
        )}
      </div>
    ))
  },
)

export default DigitalProposalContainerList
