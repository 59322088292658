import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px 0px',
  },
})

export default styles
