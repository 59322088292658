import * as S from './styles'

interface ICircularProgressProps {
  text?: string
}

export const CircularProgress = ({ text }: ICircularProgressProps) => {
  return (
    <S.Container text={text}>
      <div>
        <div data-testid="circular-progress" className="spinner">
          <div></div>
        </div>
      </div>
    </S.Container>
  )
}
