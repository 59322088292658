import { Percent } from 'lucide-react'
import { type IFlagProps } from '../types'
import * as S from '../styles'
import { type IFlagVariant } from 'components/SmartCard/Flag/types'

export function BlackFridayFlag({ borderRadius, ...props }: IFlagProps) {
  const defaultFlag = {
    text: 'BLACK FRIDAY',
    Icon: Percent,
    variant: 'gray',
  }

  return (
    <S.Flag
      {...props}
      {...defaultFlag}
      iconDividerDisabled
      borderRadius={borderRadius ?? '0px 8px 0px 8px'}
      variant={defaultFlag.variant as IFlagVariant}
    />
  )
}
