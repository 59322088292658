import styled from 'styled-components'
import { Button } from '@77sol-ui/atoms'

export const ButtonDesktop = styled(Button)`
  display: none;
  font-family: DM Sans;
  @media (min-width: 768px) {
    display: flex;
  }
`
export const ButtonMobile = styled(Button)`
  display: flex;

  > span {
    font-family: DM Sans;
  }

  @media (min-width: 768px) {
    display: none;
  }
`
