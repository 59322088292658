export function getRoiMessage(payback: string) {
  if (!payback) {
    return ''
  }

  const years = Number(payback)
  const roiMessage = `${years} ${years > 1 ? 'anos' : 'ano'}`

  return roiMessage
}
