import { AvailableFlag } from 'containers/EquipmentQuotationContainer/components/Flags/AvailableFlag'
import { BlackFridayFlag } from 'containers/EquipmentQuotationContainer/components/Flags/BlackFridayFlag'
import { ChangedKitFlag } from 'containers/EquipmentQuotationContainer/components/Flags/ChangedKitFlag'
import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'
import { useBlackFridayFlag } from './hooks/useBlackFridayFlag'
import * as S from './styles'

interface IManageFlagsProps {
  kit: IKitDTOWithId
}

export const ManageFlags = ({ kit }: IManageFlagsProps) => {
  const { canShowBlackFriday } = useBlackFridayFlag(kit)

  const cantShowWhenKitIsChanged = kit?.changed && !canShowBlackFriday

  return (
    <S.Container>
      {canShowBlackFriday && <BlackFridayFlag borderRadius="0px 0px 8px 8px" />}
      <AvailableFlag
        borderRadius="0px 0px 8px 8px"
        availableAt={kit.available_at}
        deliveredBy={kit.delivered_by}
      />
      {cantShowWhenKitIsChanged && <ChangedKitFlag />}
    </S.Container>
  )
}
