import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 4px 6px;

  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;

  & svg {
    width: 24px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`
