interface IInsuranceInformationProps {
  value: number | string
}

export function InsuranceInformation({ value }: IInsuranceInformationProps) {
  return (
    <>
      <p>Proteja o financiamento em caso de desemprego, invalidez ou morte.</p>
      <li>
        Proteção em caso de perda de renda, o seguro cobre até 4 parcelas do
        financiamento limitado ao valor de R$ 1.500,00, com carência de 30 dias.
      </li>
      <li>
        Proteção em caso de invalidez permanente total por acidente, o
        financiamento é quitado limitado ao valor de R$ 75.000,00.
      </li>
      <li>
        Proteção em caso de morte por qualquer causa, o financiamento é quitado
        limitado ao valor de R$ 75.000,00.
      </li>
      <li>
        Acréscimo aproximado de apenas {value} na parcela do seu financiamento.
      </li>
    </>
  )
}
