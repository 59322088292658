import { type IPerformanceFiltersProps } from '..'
import { PERIODS_FILTER_ENUM } from '../constants'

export function useChartFilters({
  filters,
  setFilters,
}: IPerformanceFiltersProps) {
  const INTERVAL =
    PERIODS_FILTER_ENUM[filters.period as keyof typeof PERIODS_FILTER_ENUM]

  function onChangePeriod(period: string) {
    const newInterval =
      PERIODS_FILTER_ENUM[period as keyof typeof PERIODS_FILTER_ENUM]
    setFilters((prev) => ({
      ...prev,
      period,
      interval: newInterval[0].value,
    }))
  }

  return {
    INTERVAL,
    onChangePeriod,
  }
}
