import { formatDistance, isValid, subHours } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const showNotificationTime = (date: string) => {
  const notificationDate = new Date(date)
  if (!isValid(notificationDate)) return

  const realDate = subHours(notificationDate, 3)
  return formatDistance(realDate, new Date(), {
    addSuffix: true,
    locale: ptBR,
  })
}
