import API from 'api'
import { type ICreateDimensioningPayload } from './types'
import { CREATE_DIMENSIONING } from '../constants/endpointKeys'

export const createDimensioning = async (
  dimensioningData: ICreateDimensioningPayload,
) => {
  return await API.post(CREATE_DIMENSIONING, dimensioningData).then(
    (response) => response.data,
  )
}
