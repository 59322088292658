import API from 'api'
import { QUERY_KEYS } from 'services/constant'
import { type LojaMeuEnderecoGETResponse } from './types'

export const lojaMeuEndereco = {
  get: async () => {
    return await API.get<LojaMeuEnderecoGETResponse>(
      QUERY_KEYS.LOJA.MEU_ENDERECO,
    ).then(({ data }) => data.endereco)
  },
}
