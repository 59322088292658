import styled from 'styled-components'

export const Content = styled.div`
  margin-top: 5px;

  > ol,
  ul {
    padding-left: 24px;
  }

  > ul {
    list-style: disc;
  }

  > ol {
    list-style: decimal;
  }

  > table,
  th,
  td {
    border: 1px solid #ccc;
  }
`
