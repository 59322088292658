import * as S from './styles'
import { Toggle, Button } from '@77sol/core'
import { useState } from 'react'
import { Typography, Input } from 'components'
import { useFetchEquipmentsCategories } from 'domains/quotation/services/hooks/useFetchEquipmentsCategories'
import { useFetchEquipmentsBrands } from 'domains/quotation/services/hooks/useFetchEquipmentsBrands'
import { MODULO_OR_INVERSOR } from '../../constants/productValidations'

export function Filters({ product, isMobile, onFilter, onApply }) {
  const { categoria } = product

  const { data: categories } = useFetchEquipmentsCategories()
  const { data: brands } = useFetchEquipmentsBrands({ category: categoria })

  const isModuleOrInverter = MODULO_OR_INVERSOR.includes(parseInt(categoria))
  const productCategory = categories?.find(
    ({ id }) => id === parseInt(categoria),
  )?.nome

  const [nameFilter, setNameFilter] = useState('')
  const [brandFilter, setBrandFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')
  const [bestOptionFilter, setBestOptionFilter] = useState(false)

  function handleBestPrice() {
    const bestPriceChanged = !bestOptionFilter
    setBestOptionFilter(bestPriceChanged)
    onFilter('best_option', bestPriceChanged ? 1 : 0)
  }

  return (
    <S.Form>
      <Typography type="small" color="grayscale" colorWeight="700">
        {productCategory || ''}
      </Typography>
      <Typography type="small" color="grayscale" colorWeight="500">
        Filtros
      </Typography>
      <S.BestPrice>
        <Toggle
          size="small"
          checked={bestOptionFilter}
          onChange={handleBestPrice}
        />
        <Typography type="text_small" color="primary" colorWeight="300">
          Mesmo estoque
        </Typography>
      </S.BestPrice>
      <Input
        size="small"
        variant="outlined"
        label="Busca por nome"
        value={nameFilter}
        setValue={setNameFilter}
        onBlur={() => onFilter('keyword', nameFilter)}
      />
      {isModuleOrInverter && (
        <>
          <Typography type="small" color="black">
            Marca
          </Typography>
          <Input
            select
            size="small"
            variant="outlined"
            value={brandFilter}
            setValue={(value) => {
              setBrandFilter(value)
              onFilter('brand', value)
            }}
          >
            <option key="" value="">
              Sem filtro
            </option>
            {brands?.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.nome}
              </option>
            ))}
          </Input>
        </>
      )}
      <Typography type="text_small" color="black">
        Categoria
      </Typography>
      <Input
        select
        disabled
        size="small"
        variant="outlined"
        value={categoryFilter}
        setValue={(value) => {
          setCategoryFilter(value)
          onFilter('category', value)
        }}
      >
        <option key="" value="">
          Sem filtro
        </option>
        {categories?.map((categorie) => (
          <option key={categorie.id} value={categorie.id}>
            {categorie.nome}
          </option>
        ))}
      </Input>
      {isMobile && (
        <Button fullWidth size="small" onClick={onApply}>
          Aplicar filtros
        </Button>
      )}
    </S.Form>
  )
}
