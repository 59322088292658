import { DropdownControlled } from 'components/Controllers/Dropdown'
import utilsOptions from 'utils/options'
import * as S from '../styles'
import { useFormContext, useWatch } from 'react-hook-form'

export const DeliveryStateForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const fieldValues = useWatch({ control })

  return (
    <S.Container>
      <S.Label>Estado de entrega do frete</S.Label>
      <DropdownControlled
        control={control}
        id="sizing_select_deliveryState"
        fullWidth
        label="Selecione um estado"
        name="delivery_state"
        options={utilsOptions.frete()}
        value={fieldValues.delivery_state}
        error={errors.delivery_state?.message as string}
      />
    </S.Container>
  )
}
