import { ModalFrete } from 'views/DimensionarV2/components/StepTwo/components'
import { useModalUpdateQuotationFreight } from './hooks/useModalUpdateQuotationFreight'
import { useChangeFreightQuotation } from './hooks/useChangeFreightQuotation'

export function ModalUpdateQuotationFreight() {
  const { quote, setOpenModalToChangeQuotationFreight } =
    useModalUpdateQuotationFreight()

  const { isLoading, handleUpdateDeliveryQuotation } =
    useChangeFreightQuotation({
      onSuccess: () => {
        setOpenModalToChangeQuotationFreight(false)
      },
    })

  return (
    <ModalFrete
      loadingDelivery={isLoading}
      actualQuote={quote}
      handleChangeShipmentValue={handleUpdateDeliveryQuotation}
      openModalFrete
      setOpenModalFrete={() => {
        setOpenModalToChangeQuotationFreight(false)
      }}
    />
  )
}
