import { DropdownControlled } from 'components/Controllers/Dropdown'
import { LEAD_TYPE } from 'utils/constants'
import * as S from '../styles'
import { useFormContext } from 'react-hook-form'

export const OriginLeadForm = () => {
  const { control } = useFormContext()

  return (
    <S.Container>
      <S.Label>Origem do Lead</S.Label>
      <DropdownControlled
        control={control}
        label="Origem do Lead"
        options={LEAD_TYPE}
        fullWidth
        name="origin_lead"
      />
    </S.Container>
  )
}
