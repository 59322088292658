import * as S from './styles'

interface IModalWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const ModalWrapper = ({ children, ...rest }: IModalWrapperProps) => {
  return <S.ModalWrapper {...rest}>{children}</S.ModalWrapper>
}

export { ModalWrapper }
