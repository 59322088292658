import { Modal } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Info, Loader2 } from 'lucide-react'
import * as S from './styles'
import { useModalSaveQuotationWrongProduct } from './hooks/useModalSaveQuotationWrongProduct'

interface IModalSaveQuotationWithWrongProductProps {
  open?: boolean
  onOpenChange?: (value: boolean) => void
  onSuccess: () => void
  onClose?: () => void
}

export function ModalSaveQuotationWithWrongProduct({
  open,
  onOpenChange,
  onSuccess,
  onClose,
}: IModalSaveQuotationWithWrongProductProps) {
  const { handleCancelProposal, isLoading } = useModalSaveQuotationWrongProduct(
    {
      onSuccess,
    },
  )
  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <S.Overlay />

        <S.Wrapper
          maxWidth="790px"
          maxHeight="90vh"
          id="modal-cancel-quotation"
        >
          <Modal.Close onClick={onClose} />
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Title>Atenção! Sua cotação contém erros.</Modal.Title>
            <Modal.Description>
              Identificamos um item incompatível em sua cotação. Antes de
              prosseguir, por favor, corrija o(s) produto(s) incompatível(is).
              Caso contrário, a cotação será excluída.
            </Modal.Description>
          </Modal.Header>

          <S.Footer>
            <Button
              fullWidth
              variant="outline"
              onClick={handleCancelProposal}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader2 size={24} className="atm-animate-spinSlow" />
              ) : (
                'Excluir e sair'
              )}
            </Button>

            <Button fullWidth onClick={onClose} disabled={isLoading}>
              Continuar editando
            </Button>
          </S.Footer>
        </S.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
