import styled from 'styled-components'

export const Container = styled.div`
  .panel {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
      display: grid;
      flex-direction: row;
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (min-width: 1536px) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
`
