export function setProModalToggle(proModalIsOpen) {
  return {
    type: 'SET_PRO_MODAL_TOGGLE',
    proModalIsOpen,
  }
}

export function setProModalOpen() {
  return {
    type: 'SET_PRO_MODAL_OPEN',
    proModalIsOpen: true,
  }
}

export function setProModalClose() {
  return {
    type: 'SET_PRO_MODAL_CLOSE',
    proModalIsOpen: false,
  }
}
