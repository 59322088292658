import { Modal } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const StyledOverlay = styled(Modal.Overlay)`
  z-index: 1400;
`

export const ModalContent = styled(Modal.Content)`
  .compare-price-wrapper {
    display: flex;
    justify-content: space-evenly;

    p {
      font-family: DM Sans;
      text-align: center;
      font-weight: 500;
    }

    .price {
      min-width: 230px;
      margin-top: 12px;
      border-radius: 8px;
      padding: 16px 36px;
      font-weight: 700;
    }

    .current-price {
      p {
        color: #2d69f6;
      }

      p:last-child {
        background-color: #ecf2fe;
        border: 1px solid #8dbef5;
      }
    }

    .old-price {
      p {
        color: #828282;
      }

      p:last-child {
        border: 1px solid #f2f2f2;
      }
    }

    @media (max-width: 768px) {
      margin: 20px 0;
      flex-direction: column;
      gap: 30px;
    }
  }
`
