import { Modal } from '@77sol-ui/molecules'
import { Content } from './components/Content'
import { useCreateFinancingPerQuotationModal } from './hooks/useCreateFinancingPerQuotationModal'
import { useCreateFinancingPerQuotationModalStore } from './store'
import { Loading } from 'containers/Financing/components/Loading'
import { useFetchFinancingInsurancePercentage } from 'domains/financing/hooks/useFinancing'
import { useEffect } from 'react'

export function CreateFinancingPerQuotationModal() {
  const {
    isLoading,
    isError,
    handleCreateFinancingPerQuotationRequest,
    requestFallback,
  } = useCreateFinancingPerQuotationModal()
  const { getInsurancePercentage } = useFetchFinancingInsurancePercentage()

  const {
    isFinancingPerQuotationModalOpen,
    setFinancingPerQuotationModalClose,
  } = useCreateFinancingPerQuotationModalStore()

  const onCloseModal = () => {
    setFinancingPerQuotationModalClose()
  }

  useEffect(() => {
    getInsurancePercentage()
  }, [])

  return (
    <Modal.Root
      open={isFinancingPerQuotationModalOpen}
      onOpenChange={onCloseModal}
    >
      <Modal.Portal>
        <Modal.Overlay />

        {!isLoading && (
          <Content
            onCreateFinancing={handleCreateFinancingPerQuotationRequest}
            onCloseModal={onCloseModal}
            requestFallback={requestFallback}
          />
        )}
        {isLoading && !isError && <Loading />}
      </Modal.Portal>
    </Modal.Root>
  )
}
