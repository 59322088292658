import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, useMediaQuery, Hidden } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import { isMobile } from 'react-device-detect'
import { Paper, Typography, Accordion } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { EditIcon } from '@77sol/icons/dist'
import { Divider, Button, Spacer } from '@77sol/core'
import palette from 'app_palette'
import API from 'api'
import { setIsProPlanModalOpen } from 'store/actions'
import * as ProposalAction from 'store/actions/index'
import { parseBRL } from 'utils'
import ResultadoDimensionamento from '../../../ResultadoDimensionamento'
import EquipmentsList from '../EquipmentsList'
import {
  OrcamentoPanel,
  ConcessionariaCard,
  TarifaCard,
  CorrecaoAnualCard,
  InvestimentoRetornoCard,
} from '..'

const useStyles = makeStyles({
  paperRendered: {
    paddingTop: '24px',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  selectedOption: {
    padding: '12px 16px',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #DFE3E8',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    color: '#FFF',
  },
  NotSelectedOption: {
    padding: '12px 16px',
    width: '100%',
    display: 'flex',
    borderBottom: '1px solid #DFE3E8',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFF',
  },
})

function SizingEditAll({
  sizingActivePanel,
  setSizingActivePanel,
  sizingEdit,
  quoteEdit,
  setQuoteEdit,
  proposal,
  proposalDragDrop,
  oneQuoteId,
  consumptionPanel,
}) {
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')
  // Redux
  const dispatch = useDispatch()
  const isProPlan = useSelector((state) => state.ReducerProfile.isProPlan)

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))

  const [loadingCreateQuote, setLoadingCreateQuote] = useState(false)
  const [selectedQuote, setSelectedQuote] = useState()
  const [budgetEdit, setBudgetEdit] = useState([])
  const [loadingBudget, setLoadingBudget] = useState([false, 0])
  const [loadingReturn, setLoadingReturn] = useState([false, 0])
  const [idEditingQuote, setIdEditingQuote] = useState('')
  const nivelLogin = proposal?.nivelLogin

  const handleCreateQuote = () => {
    setLoadingCreateQuote(true)

    API.post('/proposal/create/dimensioning/quotation', {
      potency: sizingEdit.potenciaCC / 1000,
      structure: sizingEdit.estrutura,
      dimensioning_id: sizingEdit.id,
      quotations_quantity: 3,
    }).then((res) => {
      setLoadingCreateQuote(false)
      for (const quotation of res.data) {
        dispatch(ProposalAction.SaveQuoteRedux(quotation))
      }
      API.get(`/proposal/quotation/roi/calculate/${res.data.quotation.id}`)
        .then(() => {})
        .catch((err) => {
          console.log(err)
        })
      API.post('/proposal/quotation/budget/finalized', {
        quotation_id: res.data.quotation.id,
      })
        .then((res) => {
          dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  const [returnData, setReturnData] = useState()

  const handleShowRoi = (sizing, values, quoteId) => {
    API.get(`/proposal/quotation/roi/calculate/${quoteId}`)
      .then((res) => {
        setSelectedQuote(quoteId)
        setReturnData(res.data)
        setSizingActivePanel('investmentReturn')
        setLoadingReturn(false, 0)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGetBudget = (id, type) => {
    if (type == 'budget') {
      setLoadingBudget([true, id])
    }
    if (type == 'investmentReturn') {
      setLoadingReturn([true, id])
    }

    API.get(`/proposal/list/budgets/${id}`)
      .then((res) => {
        if (type == 'budget') {
          setSizingActivePanel('budget')
        }
        if (res.data.length == 0) {
          API.post('/proposal/create/quotation/budget/item', {
            quotation_id: id,
          })
            .then((res) => {
              setSelectedQuote(id)
              setBudgetEdit(res.data)
              setLoadingBudget([false, 0])
              if (type == 'investmentReturn') {
                let sizing = {}
                const quoteDimensioningId = proposal.quotes.find(
                  (quote) => quote.quotation.id == id,
                ).dimensioning.id
                sizing = proposal.sizings.find(
                  (sizing) => sizing.id == quoteDimensioningId,
                )
                handleShowRoi(sizing, res.data, id)
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          setSelectedQuote(id)
          setBudgetEdit(res.data)
          setLoadingBudget([false, 0])
          if (type == 'investmentReturn') {
            let sizing = {}
            const quoteDimensioningId = proposal.quotes.find(
              (quote) => quote.quotation.id == id,
            ).dimensioning.id
            sizing = proposal.sizings.find(
              (sizing) => sizing.id == quoteDimensioningId,
            )
            handleShowRoi(sizing, res.data, id)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        height: proposalDragDrop ? '100%' : 'calc(100% - 89px)',
      }}
    >
      {proposalDragDrop && sizingActivePanel != 'all' ? (
        <></>
      ) : (
        sizingActivePanel && (
          <div
            style={{
              width:
                sizingActivePanel == 'all' || proposalDragDrop ? '100%' : '50%',
              borderRight: proposalDragDrop ? 'none' : '1px solid #e2e6e7',
              overflowY: 'auto',
            }}
            className={classes.paperRendered}
          >
            {(!oneQuoteId || consumptionPanel) && (
              <Paper
                style={{
                  marginTop: proposalDragDrop ? '0px' : '16px',
                  boxShadow: 'none',
                  border: 'none',
                  height: 'auto',
                  padding: '0px',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      type="text_large"
                      color="primary"
                      colorWeight="300"
                    >
                      Dimensionamento{' '}
                      {proposal.sizings.findIndex(
                        (sizing) => sizing.id == sizingEdit.id,
                      ) + 1}
                    </Typography>
                    {sizingActivePanel == 'all' && (
                      <Button
                        onClick={() => setSizingActivePanel('sizing')}
                        variant="outlined"
                        noMargin
                        style={{ height: 'fit-content' }}
                        size="small"
                      >
                        Editar
                      </Button>
                    )}
                  </div>
                  <Spacer size="16" direction="horizontal" />
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Typography
                        type="text_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        Potência do sistema
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography
                          type="display_x_small_x_bold"
                          color="grayscale"
                          colorWeight="700"
                        >
                          {(sizingEdit.potenciaCC / 1000).toFixed(2)}
                        </Typography>
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          kWp
                        </Typography>
                      </div>
                    </div>
                    <Spacer size="32" direction="vertical" />
                    <div>
                      <Typography
                        type="text_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        Média de consumo
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography
                          type="display_x_small_x_bold"
                          color="grayscale"
                          colorWeight="700"
                        >
                          {sizingEdit.consumoMensalMedio.toFixed(2)}
                        </Typography>
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          kWh/mês
                        </Typography>
                      </div>
                    </div>
                    <Spacer size="4" direction="horizontal" />
                    {/*
                                                <div>
                                                    <Typography
                                                        type="text_small"
                                                        color="grayscale"
                                                        colorWeight="600"
                                                    >
                                                        Local de instalação
                                                    </Typography>
                                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                        <Typography
                                                            type="display_x_small_x_bold"
                                                            color="grayscale"
                                                            colorWeight="700"
                                                        >
                                                            {structureLabel(sizingEdit.estrutura)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            */}
                  </div>
                </div>
              </Paper>
            )}
            {oneQuoteId ? (
              <>
                {proposal.quotes
                  .filter((quote) => quote.quotation.id == oneQuoteId)
                  .map((quote, i) => (
                    <>
                      {!consumptionPanel && nivelLogin < 4 && (
                        <Paper
                          style={{
                            height: 'auto',
                            borderRadius: '16px',
                            boxShadow: 'none',
                            border: `2px solid ${palette.grayscale[300]}`,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              type={
                                isMobile
                                  ? 'link_small'
                                  : upXl
                                  ? 'link_medium'
                                  : 'text_small'
                              }
                              color="grayscale"
                              colorWeight="700"
                            >
                              Orçamento
                            </Typography>
                            {isMobile ? (
                              <EditIcon
                                width="24"
                                color={palette.grayscale[700]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  // setSizingActivePanel('budget');
                                  handleGetBudget(quote.quotation.id, 'budget')
                                  setIdEditingQuote(quote.quotation.id)
                                }}
                              />
                            ) : (
                              <>
                                <Hidden xlUp>
                                  <EditIcon
                                    width="24"
                                    color={palette.grayscale[700]}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      // setSizingActivePanel('budget');
                                      handleGetBudget(
                                        quote.quotation.id,
                                        'budget',
                                      )
                                      setIdEditingQuote(quote.quotation.id)
                                    }}
                                  />
                                </Hidden>
                                <Hidden lgDown>
                                  <Button
                                    onClick={() => {
                                      // setSizingActivePanel('budget');
                                      handleGetBudget(
                                        quote.quotation.id,
                                        'budget',
                                      )
                                      setIdEditingQuote(quote.quotation.id)
                                    }}
                                    variant="outlined"
                                    noMargin
                                    size="small"
                                  >
                                    Editar
                                  </Button>
                                </Hidden>
                              </>
                            )}
                          </div>
                          <Spacer size="4" direction="horizontal" />
                          <div>
                            <Typography
                              type="text_x_small"
                              color="grayscale"
                              colorWeight="600"
                            >
                              Valor total do projeto
                            </Typography>
                            <Spacer size="10" direction="horizontal" />
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                              }}
                            >
                              <Typography
                                type="text_x_small"
                                color="grayscale"
                                colorWeight="600"
                              >
                                R$
                              </Typography>
                              <Spacer size="4" direction="vertical" />
                              <Typography
                                type={
                                  isMobile
                                    ? 'display_x_small_bold'
                                    : upXl
                                    ? 'display_small_bold'
                                    : 'display_x_small_bold'
                                }
                                color="grayscale"
                                colorWeight="700"
                              >
                                {parseBRL(
                                  quote?.quotation?.total_value,
                                ).replace('R$', '')}
                              </Typography>
                            </div>
                          </div>
                        </Paper>
                      )}
                      <Spacer size="8" direction="horizontal" />
                      <Paper
                        style={{
                          height: 'auto',
                          borderRadius: '16px',
                          boxShadow: 'none',
                          border: `2px solid ${palette.grayscale[300]}`,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            type={
                              isMobile
                                ? 'link_small'
                                : upXl
                                ? 'link_medium'
                                : 'text_small'
                            }
                            color="grayscale"
                            colorWeight="700"
                          >
                            Equipamentos
                          </Typography>
                          {isMobile ? (
                            <EditIcon
                              width="24"
                              color={palette.grayscale[700]}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSizingActivePanel('equipments')
                                setQuoteEdit(quote)
                                setIdEditingQuote(quote.quotation.id)
                              }}
                            />
                          ) : (
                            <>
                              <Hidden xlUp>
                                <EditIcon
                                  width="24"
                                  color={palette.grayscale[700]}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSizingActivePanel('equipments')
                                    setQuoteEdit(quote)
                                    setIdEditingQuote(quote.quotation.id)
                                  }}
                                />
                              </Hidden>
                              <Hidden lgDown>
                                <Button
                                  variant="outlined"
                                  noMargin
                                  size="small"
                                  onClick={() => {
                                    setSizingActivePanel('equipments')
                                    setQuoteEdit(quote)
                                    setIdEditingQuote(quote.quotation.id)
                                  }}
                                >
                                  Editar
                                </Button>
                              </Hidden>
                            </>
                          )}
                        </div>
                        <Spacer size="4" direction="horizontal" />
                        <div>
                          <Typography
                            type="text_x_small"
                            color="grayscale"
                            colorWeight="600"
                          >
                            Valor total de equipamentos
                          </Typography>
                          <Spacer size="10" direction="horizontal" />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            <Typography
                              type="text_x_small"
                              color="grayscale"
                              colorWeight="600"
                            >
                              R$
                            </Typography>
                            <Spacer size="4" direction="vertical" />
                            <Typography
                              type={
                                isMobile
                                  ? 'display_x_small_bold'
                                  : upXl
                                  ? 'display_small_bold'
                                  : 'display_x_small_bold'
                              }
                              color="grayscale"
                              colorWeight="700"
                            >
                              {parseBRL(
                                parseFloat(quote?.quotation.equipment_value),
                              ).replace('R$', '')}
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </>
                  ))}
              </>
            ) : (
              <>
                <Spacer size="16" direction="horizontal" />
                <Divider />
                {proposal.quotes
                  .filter((quote) => quote.dimensioning.id == sizingEdit.id)
                  .map((quote, i) => (
                    <Accordion
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: '16px',
                              marginBottom: '6px',
                              color: palette.primary[300],
                            }}
                          >
                            Cotação - # {quote.quotation.id}
                          </Typography>
                        </div>
                      }
                      size="small"
                      style={{ marginTop: '16px' }}
                    >
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Typography type="link_small">
                            Equipamentos
                          </Typography>
                          {sizingActivePanel != 'equipments' ||
                          idEditingQuote != quote.quotation.id ? (
                            <Button
                              onClick={() => {
                                setSizingActivePanel('equipments')
                                setQuoteEdit(quote)
                                setIdEditingQuote(quote.quotation.id)
                              }}
                              size="small"
                              noMargin
                              variant="outlined"
                            >
                              Editar
                            </Button>
                          ) : (
                            <ArrowForwardIosRoundedIcon
                              style={{ color: '#FFF', margin: '8px 0px' }}
                            />
                          )}
                        </div>
                        {nivelLogin < 4 && (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '8px',
                              alignItems: 'center',
                            }}
                          >
                            <Typography type="link_small">Orçamento</Typography>
                            {sizingActivePanel != 'budget' ||
                            idEditingQuote != quote.quotation.id ? (
                              <Button
                                onClick={() => {
                                  handleGetBudget(quote.quotation.id, 'budget')
                                  setIdEditingQuote(quote.quotation.id)
                                }}
                                size="small"
                                noMargin
                                variant="outlined"
                              >
                                {loadingBudget[0] &&
                                loadingBudget[1] == quote.quotation.id ? (
                                  <CircularProgress
                                    disableShrink
                                    style={{ color: '#1335c6' }}
                                    size={20}
                                    thickness={4}
                                  />
                                ) : (
                                  'Editar'
                                )}
                              </Button>
                            ) : (
                              <ArrowForwardIosRoundedIcon
                                style={{ color: '#FFF', margin: '8px 0px' }}
                              />
                            )}
                          </div>
                        )}
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '8px',
                            alignItems: 'center',
                          }}
                        >
                          <Typography type="link_small">
                            Retorno de investimento
                          </Typography>
                          {sizingActivePanel != 'investmentReturn' ||
                          idEditingQuote != quote.quotation.id ? (
                            <Button
                              onClick={() => {
                                handleGetBudget(
                                  quote.quotation.id,
                                  'investmentReturn',
                                )
                                // setSizingActivePanel('investmentReturn');
                                setIdEditingQuote(quote.quotation.id)
                              }}
                              size="small"
                              noMargin
                              variant="outlined"
                            >
                              {loadingReturn[0] &&
                              loadingReturn[1] == quote.quotation.id ? (
                                <CircularProgress
                                  disableShrink
                                  style={{ color: '#1335c6' }}
                                  size={20}
                                  thickness={4}
                                />
                              ) : (
                                'Editar'
                              )}
                            </Button>
                          ) : (
                            <ArrowForwardIosRoundedIcon
                              style={{ color: '#FFF', margin: '8px 0px' }}
                            />
                          )}
                        </div>
                      </div>
                    </Accordion>
                  ))}
              </>
            )}
            {!oneQuoteId && (
              <Button
                onClick={() => {
                  if (!isProPlan) {
                    return dispatch(setIsProPlanModalOpen(true))
                  }
                  handleCreateQuote()
                }}
                style={{ marginTop: '16px', justifyContent: 'center' }}
                fullWidth
                variant="outlined"
                size="small"
                noMargin
              >
                {loadingCreateQuote ? (
                  <CircularProgress
                    disableShrink
                    style={{ color: palette.primary[300] }}
                    size={20}
                    thickness={4}
                  />
                ) : (
                  'Fazer nova cotação'
                )}
              </Button>
            )}
          </div>
        )
      )}
      {sizingActivePanel == 'sizing' && (
        <div
          style={{
            marginTop: '16px',
            width:
              sizingActivePanel == 'all' || proposalDragDrop ? '100%' : '50%',
            overflowY: 'auto',
          }}
        >
          <ResultadoDimensionamento
            resumido
            sizingEdit={sizingEdit}
            fromDragAndDrop
          />
        </div>
      )}
      {sizingActivePanel == 'equipments' && (
        <div
          style={{
            width:
              sizingActivePanel == 'all' || proposalDragDrop ? '100%' : '50%',
            overflowY: 'auto',
          }}
        >
          <EquipmentsList quoteEdit={quoteEdit} onlyVerticalPadding />
        </div>
      )}
      {sizingActivePanel == 'budget' && (
        <div
          style={{
            width:
              sizingActivePanel == 'all' || proposalDragDrop ? '100%' : '50%',
            overflowY: 'auto',
          }}
        >
          <OrcamentoPanel
            noPadding
            noBoxShadow
            handleGetBudget={handleGetBudget}
            budgetEdit={budgetEdit}
            selectedQuote={selectedQuote}
          />
        </div>
      )}
      {sizingActivePanel == 'investmentReturn' && (
        <div
          style={{
            width:
              sizingActivePanel == 'all' || proposalDragDrop ? '100%' : '50%',
            overflowY: 'auto',
          }}
        >
          <Grid
            style={{
              width: '100%',
              padding: '16px',
              margin: '0px',
              marginTop: '16px',
            }}
            direction="column"
            container
            spacing={2}
          >
            <Grid xs={12} md={12} item>
              <ConcessionariaCard proposal={proposal} />
            </Grid>
            <Grid xs={12} md={12} item>
              <TarifaCard
                setReturnData={setReturnData}
                selectedQuote={selectedQuote}
                returnData={returnData}
              />
            </Grid>
            <Grid xs={12} md={12} item>
              <CorrecaoAnualCard
                setReturnData={setReturnData}
                selectedQuote={selectedQuote}
                returnData={returnData}
              />
            </Grid>
            <Grid xs={12} md={12} item>
              <InvestimentoRetornoCard
                sizingEdit={sizingEdit}
                returnData={returnData}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  )
}

export default SizingEditAll
