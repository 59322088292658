import React from 'react'
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Spacer, Paper } from '@77sol/core'
import palette from 'app_palette'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UploadIcon, EditIcon, DeleteIcon } from '@77sol/icons/dist'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

function CoverImageEdit(props) {
  const {
    image,
    handleFinish,
    handleRemoveImage,
    square,
    hideText,
    equips,
    noBackground,
    loading,
  } = props

  // const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'preparing') {
      // setImageLoading(true)
      remove()
      handleFinish(file)
    }
    if (status === 'uploading') {
      // let newArray = imagens;
      // newArray.push(file);
      // setImagens([...newArray]);
      // setImageLoading(false)
    }
  }

  return (
    <div style={{ padding: hideText ? '0px' : '0px 32px' }}>
      {!hideText && (
        <div>
          <Typography
            type={downSm ? 'text_small' : 'text_medium'}
            color="grayscale"
            colorWeight="700"
          >
            Upload de imagem
          </Typography>
          <Typography type="text_x_small" color="grayscale" colorWeight="500">
            São suportados apenas arquivos em PNG, JPEG e GIF de até 10MB
          </Typography>
        </div>
      )}
      <Spacer size="16" direction="horizontal" />
      <Grid spacing={2} container>
        <Grid item xs={12} md={12}>
          <Paper
            style={{
              display: 'flex',
              width: square ? '200px' : '100%',
              height: noBackground ? '150px' : '200px',
              alignItems: 'center',
              padding: noBackground ? '0px' : '16px',
              background: noBackground && 'none',
            }}
          >
            <Dropzone
              maxFiles={1}
              // getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              multiple={false}
              PreviewComponent={() => (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    disableShrink
                    style={{ color: palette.primary[300] }}
                    size={30}
                    thickness={4}
                  />
                </div>
              )}
              inputContent={(
                loading ? (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: palette.primary[300] }}
                      size={30}
                      thickness={4}
                    />
                  </div>
                ) : (
                  image ? (
                    <div
                      style={{
                        backgroundImage:
                              equips && typeof image !== 'string'
                                ? `url(${URL.createObjectURL(image)})`
                                : `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Paper
                        style={{
                          position: 'absolute',
                          bottom: '8px',
                          right: '8px',
                          display: 'flex',
                          width: 'auto',
                          height: 'auto',
                          padding: '8px',
                        }}
                      >
                        <Tooltip title="Alterar">
                          <EditIcon
                            width="24"
                            color={palette.grayscale[800]}
                          />
                        </Tooltip>
                        <Spacer size="16" direction="vertical" />
                        <Tooltip title="Remover">
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemoveImage(image)
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                            width="24"
                            color={palette.red[300]}
                          />
                        </Tooltip>
                      </Paper>
                    </div>
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '250px',
                      }}
                    >
                      <UploadIcon
                        width="32"
                        height="32"
                        color={palette.grayscale[600]}
                      />
                      <Spacer size="10" direction="horizontal" />
                      <Typography
                        type="text_x_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        Clique aqui para fazer o upload da imagem
                      </Typography>
                    </span>
                  )
                )
              )}
              accept="image/*"
              styles={{
                dropzone: {
                  padding: '30px',
                  border: image
                    ? 'none'
                    : `1px dashed ${palette.grayscale[400]}`,
                  flexGrow: 1,
                  alignSelf: 'stretch',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  overflow: 'hidden',
                  color: '#1335c6',
                  width: '100%',
                  height: image ? '100%' : 'auto',
                },
                preview: {
                  border: 'none',
                },
                input: {
                  display: 'none',
                },
                inputLabel: {
                  color: '#1335c6',
                  fontSize: '16px',
                  textAlign: 'center',
                  padding: image ? '0px' : '16px',
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default CoverImageEdit
