import { useQuery } from '@tanstack/react-query'
import { leadsModal } from '../services/api'
import { LEADS_MODAL } from '../constants/endpointKeys'

export function useLeadsModal() {
  const { data = [], ...methods } = useQuery({
    queryKey: [LEADS_MODAL],
    queryFn: async () => await leadsModal(),
  })

  return { ...methods, data }
}
