import { useQuery } from '@tanstack/react-query'
import { DASHBOARD_FINANCING_REQUESTS } from '../constants/endpointKeys'
import { type IGetFinancingRequest } from '../services/types'
import { getDashboardFinancingData } from '../services/api'

interface IUseGetFinancingRequestsDataProps {
  enabled?: boolean
  params: IGetFinancingRequest['params']
}

export function useGetFinancingRequestsData({
  enabled,
  params,
}: IUseGetFinancingRequestsDataProps) {
  const response = useQuery({
    queryKey: [DASHBOARD_FINANCING_REQUESTS, params],
    queryFn: async () => await getDashboardFinancingData({ params }),
    enabled,
    staleTime: 0,
  })

  return response
}
