import { useFinancingAll } from 'domains/financing/financing-all/hooks/useFinancingAll'
import { useFinancingPerOrigin } from 'domains/financing/financing-per-origin/hooks/useFinancingPerOrigin'
import { useMemo } from 'react'
import {
  formatFinancingPerQuotationList,
  getMinTableInfoFinancingPerQuotations,
} from 'views/Financing/utils'

export function useListFinancingQuotation({
  isOpenSimulations,
  isOpenSimulationDetails,
  financingId,
  onRowClick,
  defaultTab,
}) {
  const { search = '' } = window.location

  const {
    financingList,
    fetchNextPage,
    hasNextPage = false,
    isLoading: isLoadingAll,
  } = useFinancingAll({
    enabled: !financingId && (!defaultTab || defaultTab === 'cotacao'),
    staleTime: 60 * 1000, // 1min
    search,
  })

  const { data: financing, isLoading: isLoadinPerOrigin } =
    useFinancingPerOrigin({
      financingId,
      enabled: !!financingId && (!defaultTab || defaultTab === 'cotacao'),

      onSuccess: (responseData) => {
        const [currentFinancing] = responseData.requisitions

        const id = Number(financingId)

        const hasFoundTheIdOfFinancingByValue =
          currentFinancing.financing_id === id

        const hasFoundTheIdOfFinancingByQuotation =
          Number(currentFinancing.id_origin) === id

        const urlIdIsValid =
          hasFoundTheIdOfFinancingByValue || hasFoundTheIdOfFinancingByQuotation

        if (urlIdIsValid) {
          onRowClick({
            ...currentFinancing,
            rowData: {
              ...currentFinancing,
              rowIndex: 0,
            },
          })
        }
      },
    })

  const data = useMemo(() => {
    if (!financingId && financingList) {
      return formatFinancingPerQuotationList(financingList)
    }

    if (financingId && financing) {
      return formatFinancingPerQuotationList(financing)
    }

    return []
  }, [financingList, financing, financingId])

  const { values = [], columns = [] } = getMinTableInfoFinancingPerQuotations(
    data,
    isOpenSimulations,
    isOpenSimulationDetails,
  )

  const isLoading =
    (!financingId && isLoadingAll) ||
    (Boolean(financingId) && isLoadinPerOrigin)

  return {
    values,
    columns,
    fetchNextPage,
    hasNextPage: hasNextPage && !financingId,
    isLoading,
  }
}
