import { FINANCING_REQUEST_STATUS_ENUM } from 'enums/financingRequest/FinancingRequestStatusEnum'
import { PROPOSAL_STATUS_ENUM } from 'enums/proposals/ProposalsStatusEnum'

export enum FinancingAndProposalTab {
  FINANCINGS = 'financings',
  PROPOSALS = 'proposals',
}

export const TabEnum = {
  proposals: 'Propostas',
  financings: 'Financiamentos',
}

export const LIMIT_OF_ROWS = 4

export const ALL_STATUS_FILTER = {
  label: 'Todos os status',
  value: undefined,
  color: 'gray',
}

export const FINANCING_STATUS_FILTER = [
  {
    label: 'Aprovado',
    value: FINANCING_REQUEST_STATUS_ENUM.APROVADO,
    color: 'green',
  },
  {
    label: 'Pré aprovado',
    value: FINANCING_REQUEST_STATUS_ENUM.PRE_APROVADO,
    color: 'blue',
  },
  {
    label: 'Solicitado',
    value: FINANCING_REQUEST_STATUS_ENUM.SOLICITADO,
    color: 'orange',
  },
  {
    label: 'Negado',
    value: FINANCING_REQUEST_STATUS_ENUM.NEGADO,
    color: 'red',
  },
  {
    label: 'Cancelado',
    value: FINANCING_REQUEST_STATUS_ENUM.CANCELADO,
    color: 'gray',
  },
]

export const PROPOSALS_STATUS_FILTER = [
  {
    label: 'Rascunho',
    value: PROPOSAL_STATUS_ENUM.RASCUNHO,
    color: 'gray',
  },
  {
    label: 'Enviada',
    value: PROPOSAL_STATUS_ENUM.ENVIADA,
    color: 'blue',
  },
  {
    label: 'Em andamento',
    value: PROPOSAL_STATUS_ENUM.EM_ANDAMENTO,
    color: 'orange',
  },
  {
    label: 'Aceita',
    value: PROPOSAL_STATUS_ENUM.ACEITA,
    color: 'green',
  },
  {
    label: 'Recusada',
    value: PROPOSAL_STATUS_ENUM.RECUSADA,
    color: 'red',
  },
  {
    label: 'Iniciada',
    value: PROPOSAL_STATUS_ENUM.INICIADA,
    color: 'green',
  },
  {
    label: 'Finalizada',
    value: PROPOSAL_STATUS_ENUM.FINALIZADA,
    color: 'blue',
  },
]
