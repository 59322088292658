import { Carousel as Base } from '@77sol-ui/molecules'
import { ClickableItem } from './ClickableItem'
import { useCarousel } from './hooks/useCarousel'
import { CARROUSEL_CONTENT } from './constants'

export interface IBannerImage {
  bannerName: string
  image: string
  mobileImage: string
  largeImage: string
  url: string
  event: string
  target: '_blank' | '_self' | ''
}

export function Carousel() {
  const { carouselConfigs, onClickBanner } = useCarousel()

  const renderImage = (image: IBannerImage) => {
    if (image.url) {
      return (
        <ClickableItem
          key={image.image}
          onClick={() => {
            onClickBanner(image, image.target)
          }}
        >
          <Base.Image
            mobileSrc={image.mobileImage}
            src={image.image}
            largeSrc={image.largeImage}
          />
        </ClickableItem>
      )
    }

    return (
      <Base.Image
        key={image.image}
        mobileSrc={image.mobileImage}
        largeSrc={image.largeImage}
        src={image.image}
      />
    )
  }

  return (
    <Base.Root {...carouselConfigs}>
      {CARROUSEL_CONTENT.map((image) => renderImage(image))}
    </Base.Root>
  )
}
