import styled from 'styled-components'

import { palette } from '@77sol/styles'

export const closeIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 13px;
  padding-top: 13px;
  outline: none;
  border: 0;
  background-color: transparent;
  width: 100%;

  & svg {
    cursor: pointer;
    color: ${palette.grayscale[600]};
  }

  & svg:hover {
    color: ${palette.grayscale[800]};
  }
`
