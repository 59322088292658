import moment from 'moment'

const formatDate = (datetToFormat, onlyDate = false) => {
  const date = moment(datetToFormat)
  if (date.isValid()) {
    return onlyDate
      ? date.format('DD/MM/YYYY')
      : date.format('DD/MM/YYYY - HH:mm:ss')
  }

  return datetToFormat
}

export default formatDate
