import { Button, useToast } from '@77sol/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as S from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'

export function ProceedToApproval({ selectedFinancing }) {
  const { logEvent } = useAmplitude()
  const { onSuccessOpenToast } = useToast()

  const handleOpenLink = () => {
    logEvent(financingTracker.actions.openFormalizationLink, {
      origin: '/financiamentos',
    })
  }

  const handleCopyLink = () => {
    logEvent(financingTracker.actions.copyFormalizationLink, {
      origin: '/financiamentos',
    })

    onSuccessOpenToast('Link copiado!')
  }

  return (
    <S.Container>
      <a
        href={selectedFinancing?.link || '/#$'}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleOpenLink}
      >
        <Button size="small" variant="outlined">
          Preencher dados
        </Button>
      </a>
      <CopyToClipboard text={selectedFinancing?.link}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleCopyLink()}
        >
          Enviar Link
        </Button>
      </CopyToClipboard>
    </S.Container>
  )
}
