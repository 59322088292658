import { apiV2 } from 'apis/apiV2'
import { DASHBOARD_ANALYSIS_ORIGINATION } from '../constants/endpointKeys'
import {
  type IGetOriginationRequest,
  type IResponseGetOriginationData,
} from './types'

export const getOriginationData = async ({
  params,
}: IGetOriginationRequest): Promise<IResponseGetOriginationData> => {
  return await apiV2
    .get(DASHBOARD_ANALYSIS_ORIGINATION, {
      params,
    })
    .then((response) => {
      return response.data
    })
}
