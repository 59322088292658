import { useState } from 'react'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { ViewIcon, HideIcon } from '@77sol/icons/dist'
import { Input, DatePicker, Dropdown } from '@77sol/core'
import palette from 'app_palette'
import { useStyles } from '../styles'
import { useAdminStructureTypesProducts } from 'domains/admin/structure-types-products/hooks/useAdminStructureTypesProducts'

function EditForm({
  nomeClientEdit,
  setNomeClientEdit,
  cepClientEdit,
  setCepClientEdit,
  enderecoClientEdit,
  setEnderecoClientEdit,
  numeroClientEdit,
  setNumeroClientEdit,
  complementoClientEdit,
  setComplementoClientEdit,
  bairroClientEdit,
  setBairroClientEdit,
  cidadeClientEdit,
  setCidadeClientEdit,
  estadoClientEdit,
  setEstadoClientEdit,
  emailClientEdit,
  setEmailClientEdit,
  telefoneClientEdit,
  setTelefoneClientEdit,
  cpfClientEdit,
  setCpfClientEdit,
  nascimentoClientEdit,
  setNascimentoClientEdit,
  valorConsumoEdit,
  setValorConsumoEdit,
  tipoEstruturaEdit,
  setTipoEstruturaEdit,
  getEndereco,
  loadingCep,
  handleEditClientValues,
  moreDataDisplayed,
  onDisplayMoreData,
}) {
  const classes = useStyles()

  const { structureTypesData, isLoading: isLoadingStructures } =
    useAdminStructureTypesProducts()

  const [cepError, setCepError] = useState('')

  function handleValidateCep() {
    if (cepClientEdit.replace(/[^+\d]+/g, '')?.length < 8) {
      setCepError('invalid')
    } else {
      getEndereco()
      handleEditClientValues && handleEditClientValues()
      setCepError('')
    }
  }

  function handleDisplayData() {
    onDisplayMoreData(!moreDataDisplayed)
  }

  return (
    <Grid spacing={2} container>
      <Grid className={classes.container} item xs={12} md={12}>
        <Input
          id="editForm_input_nameOrCorporateName"
          value={nomeClientEdit}
          onChange={setNomeClientEdit}
          title="Nome completo / Razão social*"
          onBlur={(value) => {
            if (value) {
              if (handleEditClientValues) {
                handleEditClientValues()
              }
            }
          }}
        />
      </Grid>
      <Grid className={classes.container} item xs={12} md={12}>
        <Typography
          id="editForm_button_hideOrShow"
          onClick={handleDisplayData}
          color="primary"
          colorWeight="400"
          type="link_x_small"
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          {!moreDataDisplayed ? (
            <ViewIcon
              color={palette.primary[400]}
              width="16"
              style={{ marginRight: '8px' }}
            />
          ) : (
            <HideIcon
              color={palette.primary[400]}
              width="16"
              style={{ marginRight: '8px' }}
            />
          )}
          {!moreDataDisplayed ? 'Completar dados' : 'Esconder dados'}
        </Typography>
      </Grid>
      {moreDataDisplayed && (
        <>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="editForm_input_cep"
              mask="cep"
              title="CEP"
              value={cepClientEdit}
              onChange={setCepClientEdit}
              isLoading={loadingCep}
              onBlur={handleValidateCep}
              validationState={cepError}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={7}>
            <Input
              id="editForm_input_address"
              title="Endereço"
              value={enderecoClientEdit}
              onChange={setEnderecoClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={2}>
            <Input
              id="editForm_input_addressNumber"
              type="number"
              title="Numero"
              value={numeroClientEdit}
              onChange={setNumeroClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="editForm_input_complement"
              title="Complemento"
              value={complementoClientEdit}
              onChange={setComplementoClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="editForm_input_neighborhood"
              title="Bairro"
              value={bairroClientEdit}
              onChange={setBairroClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={4}>
            <Input
              id="editForm_input_city"
              title="Cidade"
              value={cidadeClientEdit}
              onChange={setCidadeClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={2}>
            <Input
              id="editForm_input_state"
              title="Estado"
              value={estadoClientEdit}
              onChange={setEstadoClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="editForm_input_email"
              title="E-mail"
              value={emailClientEdit}
              onChange={setEmailClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="editForm_input_phoneNumber"
              mask="phoneNumber"
              title="Telefone"
              value={telefoneClientEdit}
              onChange={setTelefoneClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="editForm_input_cpfOrCnpj"
              mask="cpfCnpj"
              title="CPF / CNPJ"
              value={cpfClientEdit}
              onChange={setCpfClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <DatePicker
              id="editForm_datePicker_birthDate"
              title="Data de nascimento / Fundação"
              value={nascimentoClientEdit}
              dateChange={setNascimentoClientEdit}
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="editForm_input_billValue"
              mask="real"
              value={valorConsumoEdit}
              onChange={setValorConsumoEdit}
              title="Valor de consumo médio"
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Dropdown
              fullWidth
              key={isLoadingStructures}
              id="editForm_input_structureType"
              value={tipoEstruturaEdit}
              onChange={setTipoEstruturaEdit}
              options={structureTypesData}
              title="Tipo de estrutura"
              onBlur={() => handleEditClientValues && handleEditClientValues()}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default EditForm
