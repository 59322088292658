import API from 'api'
import { type IGetKitsResponse } from './types'
import { GET_KITS } from '../constants/endpointKeys'

export const kitService = {
  getKits: async (dimensioningId: string) => {
    if (!dimensioningId) return
    return await API.get<IGetKitsResponse>(
      `${GET_KITS}/${dimensioningId}`,
    ).then(({ data }) => {
      return data
    })
  },
}
