import { Badge, type IBadgeProps } from '@77sol-ui/atoms'
import { ScrollTable } from '@77sol-ui/molecules'

import { formatDate, parseBRL } from 'utils'
import { useHistory } from 'react-router-dom'
import { Row } from './styles'
import { type IProposal } from 'domains/home/dashboard-proposals/services/types'
import { useProposalBadge } from './hooks/useProposalBadge'
import { useAmplitude } from 'hooks/useAmplitude'
import { tableFiltersTrackers } from 'services/tracker/events/tableFilters/trackers'

export interface IProposalItemProps {
  proposal: IProposal
}

export function ProposalItem({ proposal }: IProposalItemProps) {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const status = useProposalBadge(proposal.status)
  const potency = proposal?.potency ? `${proposal.potency}kWp` : '-'

  function redirectToProposal() {
    logEvent(tableFiltersTrackers.actions.rowClick, {
      origin: '/dashboard',
      table: 'Propostas',
    })
    history.push({
      pathname: '/projetos/propostas',
      state: {
        projectId: proposal.id,
        customerName: proposal.requester_name,
        customerId: proposal.requester_id,
        selectedProposalId: proposal.proposal_id,
      },
    })
  }

  return (
    <Row key={proposal.id} role="button" onClick={redirectToProposal}>
      <ScrollTable.Td pin>#{proposal.id}</ScrollTable.Td>
      <ScrollTable.Td truncate>{proposal.requester_name}</ScrollTable.Td>
      <ScrollTable.Td>{parseBRL(proposal.value)} </ScrollTable.Td>
      <ScrollTable.Td>{potency}</ScrollTable.Td>
      <ScrollTable.Td>
        <Badge
          variant="filled"
          color={status.color as IBadgeProps['color']}
          text={status.text}
        />
      </ScrollTable.Td>
      <ScrollTable.Td>{formatDate(proposal.created_at, true)}</ScrollTable.Td>
    </Row>
  )
}
