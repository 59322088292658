import styled from 'styled-components'

const BREAKPOINT_TABLET = 768
const BREAKPOINT_MOBILE = 550

export const Container = styled.div`
  width: 100%;
  height: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  .infinite-scroll-component__outerdiv {
    width: inherit;
    height: inherit;
  }
`

export const Loader = styled.span`
  display: flex;
  justify-content: center;
  padding: 8px 0;

  ::before {
    content: 'Carregando os produtos...';
    color: gray;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: solid lightgray;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    display: grid;
    grid-gap: 10px;
    justify-content: stretch;
  }
`

export const Image = styled.div`
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 72px;
    max-height: 72px;
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    display: none;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Badge = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 8px;
`

export const Button = styled.div`
  button {
    white-space: nowrap;
  }

  button > span:empty::before {
    content: 'Selecionar produto';
  }

  @media (max-width: ${BREAKPOINT_TABLET}px) {
    button > span:empty::before {
      content: 'Selecionar';
    }
  }
`
