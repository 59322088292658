import { Portal } from 'components/Portal'
import { CustomizeKitHeader } from 'containers/EquipmentQuotationContainer/components/Tab/components/CustomizeKit/components/CustomizeKitHeader'
import { CustomizeKitFooter } from './components/Footer'
import { Equipments } from './components/Equipments'
import { ModalUpdateQuotationFreight } from 'containers/EquipmentQuotationContainer/components/ModalUpdateQuotationFreight'
import { CustomizeKitSkeleton } from 'containers/EquipmentQuotationContainer/components/Tab/components/CustomizeKit/components/CustomizeKitSkeleton'
import { useCustomizeKit } from './hooks/useCustomizeKit'
import { Skeleton } from 'components/Skeleton'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function CustomizeKit() {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { isLoading, openModalToChangeQuotationFreight } = useCustomizeKit(
    dimensioningId as number,
  )

  if (isLoading) return <CustomizeKitSkeleton />

  return (
    <>
      <div className="tab-content">
        <CustomizeKitHeader />

        {openModalToChangeQuotationFreight && <ModalUpdateQuotationFreight />}

        <Equipments />
      </div>

      <Portal id="quotation-container-footer">
        {isLoading ? (
          <Skeleton width="100%" height="40px" />
        ) : (
          <CustomizeKitFooter />
        )}
      </Portal>
    </>
  )
}
