import API from 'api'
import { type IUpdateDimensioningKitsPayload } from './types'
import { UPDATE_DIMENSIONING_KITS } from '../constants/endpointKeys'

export const updateDimensioningKits = async (
  id: number | null,
  payload: IUpdateDimensioningKitsPayload,
) => {
  if (id) {
    return await API.patch(`${UPDATE_DIMENSIONING_KITS}/${id}`, payload).then(
      (response) => response.data,
    )
  }
}
