import { create } from 'zustand'
import { TAB_ENUM } from '../enums/tabEnum'
import { type IActions, type IState } from './types'
import { devtools } from 'zustand/middleware'
import { chooseKitSlice } from './slices/ChooseKitSlice'
import { type IQuotationDTO } from 'domains/dimensioning/services/types'

const DEFAULT_VALUES: IState = {
  currentTab: TAB_ENUM.CHOOSE_KIT,
  quotations: [],
  selectedKits: [],
  dimensioningId: null,
  quotationSelected: null as unknown as IQuotationDTO,
  openModalToDeleteQuotation: null as unknown as IQuotationDTO,
  openModalToChangeQuotationFreight: false,
  openModalToAddNewEquipment: false,
  openModalToCancelProposal: false,
  openModalToCreateProject: false,
  openModalContinueWithChanged: false,
  continueQuotationWithChangesCallback: null,
  openModalQuotationDiscount: false,
  firstLoadPage: true,
  kits: [],
}

const useEquipmentContainerStore = create<IState & IActions>()(
  devtools(
    (set) => ({
      ...chooseKitSlice(set),
      ...DEFAULT_VALUES,
      setDimensioningId: (id) => {
        set(() => ({ dimensioningId: id }))
      },
      changeTab: (tab: TAB_ENUM) => {
        set(() => ({ currentTab: tab }))
      },
      setQuotationSelected: (quotation) => {
        set(() => ({ quotationSelected: quotation }))
      },
      setOpenModalToDeleteQuotation: (value) => {
        set(() => ({ openModalToDeleteQuotation: value }))
      },
      setOpenModalToChangeQuotationFreight: (value) => {
        set(() => ({ openModalToChangeQuotationFreight: value }))
      },
      setOpenModalToAddNewEquipment: (value) => {
        set(() => ({ openModalToAddNewEquipment: value }))
      },
      setOpenModalToCancelProposal: (value) => {
        set(() => ({ openModalToCancelProposal: value }))
      },
      setOpenModalToCreateProject: (value) => {
        set(() => ({ openModalToCreateProject: value }))
      },
      setOpenModalContinueWithChanged: (value) => {
        set(() => ({ openModalContinueWithChanged: value }))
      },
      setContinueQuotationWithChangesCallback: (callback) => {
        set(() => ({ continueQuotationWithChangesCallback: callback }))
      },
      setOpenModalQuotationDiscount: (value) => {
        set(() => ({ openModalQuotationDiscount: value }))
      },
      setFirstLoadPage: (value) => {
        set(() => ({ firstLoadPage: value }))
      },
      reset: () => {
        set({ ...DEFAULT_VALUES })
      },
    }),
    { name: 'useEquipmentContainerStore' },
  ),
)

export { useEquipmentContainerStore }
