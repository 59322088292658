import { Wrapper } from './styles'

export type IBadgeContainerProps = React.HtmlHTMLAttributes<HTMLDivElement>

export const BadgeContainer = ({
  children,
  ...props
}: IBadgeContainerProps) => {
  return (
    <Wrapper data-testid="badges-container" {...props}>
      {children}
    </Wrapper>
  )
}
