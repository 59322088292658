import { Header } from '@77sol-ui/organisms'
import { ChevronDown } from 'lucide-react'
import { useRequestFinancing } from 'containers/RequestFinancingButton/hooks/useRequestFinancing'
import { useAmplitude } from 'hooks/useAmplitude'
import { useState } from 'react'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { triggerTypes } from 'services/tracker/properties/triggerType'

export function CreateButton() {
  const [open, setOpen] = useState(false)
  const { handleOpenFinancingPerValueModal } = useRequestFinancing()
  const { logEvent } = useAmplitude()

  function handleOpenDropdown() {
    setOpen((oldValue) => !oldValue)
    logEvent(headerTracker.eventName, {
      actions: headerTracker.actions.quoteEquipmentKey,
      origin: location.pathname,
      type: triggerTypes.dropdownMenu.trigger,
      state: !open ? 'Aberto' : 'Fechado',
    })
  }

  return (
    <Header.DropdownRoot
      open={open}
      onOpenChange={handleOpenDropdown}
      modal={false}
    >
      <Header.DropdownTrigger>
        <Header.Button data-testid="dropdown-button-create">
          <span>Criar</span>
          <ChevronDown size={16} />
        </Header.Button>
      </Header.DropdownTrigger>
      <Header.DropdownContent
        sideOffset={8}
        onCloseAutoFocus={(e: Event) => {
          e.preventDefault()
        }}
      >
        <Header.DropdownItem
          data-testid="dropdown-item-request-financing"
          onClick={() => {
            handleOpenFinancingPerValueModal({ triggeredByDropdown: true })
          }}
        >
          Solicitar Financiamento
        </Header.DropdownItem>
      </Header.DropdownContent>
    </Header.DropdownRoot>
  )
}
