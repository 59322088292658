import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import {
  type IQuotationDTO,
  type IDimensioningGETResponse,
} from 'domains/dimensioning/services/types'
import useObserveQuery from 'hooks/useObserverQuery'
import useWindowSize from 'hooks/useWindowSize'
import { useCallback } from 'react'

export function useCustomizeKitHeader() {
  const { width } = useWindowSize()
  const isMobile = width <= 575

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setQuotationSelected = useEquipmentContainerStore(
    (state) => state.setQuotationSelected,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const quotations = data?.quotations || []

  const handleSelectLastQuotationCreated = useCallback(
    (quotations?: IQuotationDTO[]) => {
      if (quotations && quotations.length > 0) {
        const lastQuotationAdded = quotations[quotations.length - 1]

        if (lastQuotationAdded) {
          setQuotationSelected(lastQuotationAdded)
        }
      }
    },
    [],
  )

  return {
    isMobile,
    quotations,
    quotationSelected,
    setQuotationSelected,
    handleSelectLastQuotationCreated,
  }
}
