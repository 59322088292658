import React from 'react'
import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import { Spacer, Typography } from '@77sol/core'
import styles from './styles'

function Loading({
  isLoading, children, minHeight, type, value, title, variant,
}) {
  const classes = styles({ minHeight })

  return isLoading ? (
    <div className={classes.container}>
      {type === 'linear' ? (
        <Box
          display="flex"
          flexDirection="column"
          width="80%"
          textAlign="center"
        >
          <Typography colorWeight="500">{title}</Typography>
          {!variant ?
            <>
              <Box display="flex" alignItems="center">
                <LinearProgress
                  classes={{
                    root: classes.linearRoot,
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                />
              </Box>
            </>
          :
            <>
              <Box display="flex" alignItems="center">
                <LinearProgress
                  variant="determinate"
                  value={value}
                  classes={{
                    root: classes.linearRoot,
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                />
                <Spacer size="8" direction="both" />
                <Typography size="small" colorWeight="500">
                  {value}
                  %
                </Typography>
              </Box>
            </>
          }
        </Box>
      ) : (
        <CircularProgress className={classes.icon} />
      )}
    </div>
  ) : (
    children
  )
}

export default Loading
