export enum StatusSolLeadsEnum {
  ENVIADO = 'Enviado',
  NEGADO = 'Negado',
  ACEITO = 'Aceito',
  EFETIVADO = 'Efetivado',
  PROPOSTA = 'Proposta',
  PROPOSTA_DIGITAL_ENVIADA = 'Proposta digital enviada',
  PROPOSTA_DIGITAL_ABERTA = 'Proposta digital aberta',
  FINANCIAMENTO = 'Financiamento',
  PEDIDO = 'Pedido',
  VENCIDO = 'Vencido',
}

export type StatusSolLeadsEnumKeys = keyof typeof StatusSolLeadsEnum

export type StatusSolLeadsEnumValues = `${StatusSolLeadsEnum}`
