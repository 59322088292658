import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Input } from 'components'
import { Spacer, Button } from '@77sol/core'
import { ActionPlusIcon, DeleteIcon } from '@77sol/icons/dist'
import palette from 'app_palette'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '34px 32px',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
})

function CronogramaEdit(props) {
  /* eslint eqeqeq: 0 */ 
  const classes = useStyles()

  const {
    qtdSchedule,
    setQtdSchedule,
    schedules,
    setSchedules,
    handleFinish,
  } = props

  const [disable, setDisable] = useState(false)

  const [removingSchedule, setRemovingSchedule] = useState(false)

  useEffect(() => {
    if (removingSchedule) {
      handleFinish(schedules)
      setRemovingSchedule(false)
    }
    // eslint-disable-next-line
  }, [removingSchedule])

  return (
    <div className={classes.paperRendered}>
      <div>
        <Typography type="text_medium" color="grayscale" colorWeight="700">
          Defina o cronograma do projeto
        </Typography>
        <Typography type="text_x_small" color="grayscale" colorWeight="500">
          Entitule as etapas e quanto tempo irá levar para fazer cada atividade
        </Typography>
      </div>
      <Spacer direction="horizontal" size="16" />
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        {Array.from(Array(qtdSchedule).keys()).map((schedule, i) => (
          <div
            style={{
              display: 'flex',
              marginBottom: '16px',
              alignItems: 'center',
            }}
          >
            <Input
              value={schedules[i].title}
              setValue={(value) => {
                const newArray = schedules
                newArray[i].title = value
                setSchedules([...newArray])
              }}
              onBlur={() => {
                if (
                  schedules.filter(
                    (schedule) => !schedule.title || !schedule.weeks,
                  ).length != 0
                ) {
                } else {
                  handleFinish(schedules)
                  setDisable(false)
                }
              }}
              style={{ width: '-webkit-fill-available' }}
              size="small"
              id="standard-basic"
              label="Título"
            />
            <Input
              style={{ marginLeft: '16px' }}
              label="Semanas"
              size="small"
              type="number"
              variant="outlined"
              value={schedules[i].weeks}
              onBlur={() => {
                if (
                  schedules.filter(
                    (schedule) => !schedule.title || !schedule.weeks,
                  ).length != 0
                ) {
                } else {
                  handleFinish(schedules)
                  setDisable(false)
                }
              }}
              setValue={(value) => {
                const newArray = schedules
                newArray[i].weeks = value
                setSchedules([...newArray])
              }}
            />
            <Spacer direction="vertical" size="16" />
            <DeleteIcon
              onClick={() => {
                if (qtdSchedule != 1) {
                  setRemovingSchedule(true)
                  const arrSchedules = schedules
                  arrSchedules.splice(i, 1)
                  setQtdSchedule(qtdSchedule - 1)
                  setSchedules([...arrSchedules])
                }
              }}
              width="20"
              color={
                qtdSchedule == 1 ? palette.grayscale[400] : palette.red[300]
              }
              style={{
                cursor: qtdSchedule == 1 ? 'auto' : 'pointer',
                minWidth: '20px',
              }}
            />
          </div>
        ))}
        <Button
          onClick={() => {
            setQtdSchedule(qtdSchedule + 1)
            setSchedules([...schedules, { title: '', weeks: '' }])
            setDisable(true)
          }}
          disabled={disable}
          noMargin
          variant="text"
        >
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <ActionPlusIcon width="16" color={palette.primary[300]} />
            <Spacer direction="vertical" size="12" />
            Adicionar atividade
          </div>
        </Button>
      </div>
      {/*
            <Button
                onClick={() => handleFinish(schedules)}
                className={classes.filledButton}
                disabled={schedules.filter(schedule => !schedule.title || !schedule.weeks).length}
            >
                {loadingExtras ?
                    <CircularProgress disableShrink style={{color: '#FFF'}} size={20} thickness={4} />
                :
                    'Salvar alterações'
                }
            </Button>
            */}
    </div>
  )
}

export default CronogramaEdit
