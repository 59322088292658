import { Truck } from 'lucide-react'
import { type IFlagProps } from '../types'
import * as S from '../styles'

export const DeliveryFlag: React.FC<IFlagProps> = ({
  borderRadius = '0px 0px 8px 8px',
}) => {
  return (
    <S.Flag
      borderRadius={borderRadius}
      text="FRETE INCLUSO"
      variant="green"
      Icon={Truck}
    />
  )
}
