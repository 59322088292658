import { Header } from 'components'
import { DropdownMenu } from '@77sol/core'
import { Button } from '@77sol-ui/atoms'
import { ArrowChevronDownIcon } from '@77sol/icons/dist'
import * as S from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { useState } from 'react'
import { SaveAndQuitQuotationModal } from 'containers/EquipmentQuotationContainer/components/SaveAndQuitQuotationModal'
import { useSaveAndQuit } from 'containers/EquipmentQuotationContainer/components/Header/components/SaveAndQuitButton/hooks/useSaveAndQuit'
import { useHistory } from 'react-router-dom'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function QuotationHeader() {
  const history = useHistory()
  const { isOpenSaveAndQuitModal, setIsOpenSaveAndQuitModal } = useSaveAndQuit()
  const { logEvent } = useAmplitude()
  const [openDropdown, setOpenDropdown] = useState(false)

  const setOpenModalToCancelProposal = useEquipmentContainerStore(
    (state) => state.setOpenModalToCancelProposal,
  )

  const handleSaveAndQuit = () => {
    logEvent(headerTracker.actions.saveAndExitKey, {
      origin: '/Cotar',
    })
    setIsOpenSaveAndQuitModal(true)
  }

  function handleOpenDropdown(value: boolean) {
    setOpenDropdown(value)
  }

  return (
    <>
      <Header noMargin primary>
        <DropdownMenu.Root
          modal={false}
          open={openDropdown}
          onOpenChange={handleOpenDropdown}
        >
          <DropdownMenu.Trigger asChild>
            <Button id="button-exit-quotation">
              <span>Sair</span>
              <ArrowChevronDownIcon width={16} />
            </Button>
          </DropdownMenu.Trigger>
          <S.DropdownMenuPortal>
            <S.DropdownMenuContent sideOffset={10} align="end">
              <DropdownMenu.Item
                onClick={() => {
                  setOpenModalToCancelProposal(true)
                }}
              >
                Cancelar
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={handleSaveAndQuit}>
                Salvar e sair
              </DropdownMenu.Item>
            </S.DropdownMenuContent>
          </S.DropdownMenuPortal>
        </DropdownMenu.Root>
      </Header>
      <SaveAndQuitQuotationModal
        open={isOpenSaveAndQuitModal}
        onOpenChange={() => {
          setIsOpenSaveAndQuitModal(!isOpenSaveAndQuitModal)
        }}
        onSuccess={() => {
          history.push('/projetos')
        }}
      />
    </>
  )
}
