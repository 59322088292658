import { type IDefineDiscount, type ISaveDiscount } from './types'
import { Button, InputMask } from '@77sol-ui/atoms'
import { useDefineDiscount } from './hooks/useDefineDiscount'
import { useSaveDiscount } from './hooks/useSaveDiscount'
import { Settings } from 'lucide-react'
import { Modal } from '@77sol-ui/molecules'

export interface IDiscountModalProps extends IDefineDiscount, ISaveDiscount {
  onClose: () => void
}

export function DiscountModal({
  initialDiscount,
  quotationId,
  onSuccess,
  onError,
  onClose,
}: IDiscountModalProps) {
  const { handleSaveDiscount, isLoading: isLoadingSave } = useSaveDiscount({
    quotationId,
    onSuccess,
    onError,
  })

  const { discount, handleChangeDiscount } = useDefineDiscount({
    initialDiscount,
  })

  return (
    <Modal.Root open onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper
          id="discount-modal-content"
          maxWidth="500px"
          maxHeight="90dvh"
        >
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={Settings} />
            <Modal.Title>Configurações de desconto</Modal.Title>
          </Modal.Header>
          <Modal.Content>
            <InputMask.Percentage
              name="discount"
              label="Desconto"
              placeholder="Insira o desconto"
              data-testid="discount-modal-input"
              value={discount}
              onChange={({ target }) => {
                handleChangeDiscount(Number(target.value))
              }}
            />
          </Modal.Content>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button
                fullWidth
                variant="outline"
                data-testid="discount-modal-cancel-button"
              >
                Cancelar
              </Button>
            </Modal.Close>
            <Button
              fullWidth
              isLoading={isLoadingSave}
              data-testid="discount-modal-confirm-button"
              onClick={() => {
                handleSaveDiscount(discount)
              }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
