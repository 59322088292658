import { useCallback, useEffect, useState } from 'react'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { useChangeEquipmentQuotation } from 'domains/quotation/services/hooks/useChangeEquipmentQuotation'
import { useToast } from '@77sol/core'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { queryClient } from 'services/queryClient'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import useObserveQuery from 'hooks/useObserverQuery'

type IProduct = IQuotationProductDTO | null

export function useEquipmentCard() {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { quotationSelected, dimensioningId, setQuotationSelected } =
    useEquipmentContainerStore()

  const [productToShowDescription, setProductToShowDescription] =
    useState<IProduct>(null)
  const [productToDelete, setProductToDelete] = useState<IProduct>(null)
  const [productToChange, setProductToChange] = useState<IProduct>(null)

  const { changeEquipmentQuantityMutation } = useChangeEquipmentQuotation()

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const updateSelectedQuotation = useCallback(() => {
    if (data?.quotations && data.quotations.length > 0) {
      const currentQuotation = data.quotations.find(
        (quotation) => quotation.id === quotationSelected?.id,
      )

      if (currentQuotation) {
        setQuotationSelected(currentQuotation)
      }
    }
  }, [data])

  useEffect(() => {
    updateSelectedQuotation()
  }, [updateSelectedQuotation])

  function handleOpenRemoveProduct(product: IProduct) {
    const waitCloseMenu = () => {
      setProductToDelete(product)
    }
    setTimeout(waitCloseMenu, 100)
  }

  function handleCloseRemoveProduct() {
    setProductToDelete(null)
  }

  async function handleSuccessDeleteProduct() {
    await queryClient.invalidateQueries([GET_DIMENSIONING, dimensioningId])
    handleCloseRemoveProduct()
  }

  function handleChangeProductAmount(product: IQuotationProductDTO) {
    if (!quotationSelected) {
      return
    }

    const property = product?.is_added ? 'added_products' : 'products'

    const productIndex = quotationSelected[property].findIndex(
      (item) => item.id === product.id,
    )

    const updatedQuotation = { ...quotationSelected }

    updatedQuotation[property][productIndex].qtde = product.qtde as any
    setQuotationSelected(updatedQuotation)
  }

  async function handleUpdateProductAmountOnBlur(
    product: IQuotationProductDTO,
  ) {
    if (!quotationSelected) {
      return
    }

    changeEquipmentQuantityMutation.mutate(
      {
        product_id: product.id,
        qty_product: product.qtde < 1 ? 1 : product.qtde,
        quotation_id: quotationSelected?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([GET_DIMENSIONING, dimensioningId])
          onSuccessOpenToast('Quantidade alterada com sucesso!')
        },
        onError: (error: any) => {
          onErrorOpenToast(
            error.response?.data?.message ||
              'Não foi possível alterar a quantidade!',
          )
        },
      },
    )
  }

  function onIncreaseProductAmount(product: IQuotationProductDTO) {
    const newQtde = Number(product.qtde) + 1
    handleChangeProductAmount({ ...product, qtde: newQtde })
    handleUpdateProductAmountOnBlur({ ...product, qtde: newQtde })
  }

  function onDecreaseProductAmount(product: IQuotationProductDTO) {
    const newQtde = Number(product.qtde) - 1
    handleChangeProductAmount({ ...product, qtde: newQtde })
    handleUpdateProductAmountOnBlur({ ...product, qtde: newQtde })
  }

  return {
    productToShowDescription,
    productToDelete,
    setProductToShowDescription,
    handleOpenRemoveProduct,
    handleCloseRemoveProduct,
    handleChangeProductAmount,
    handleUpdateProductAmountOnBlur,
    productToChange,
    setProductToChange,
    handleSuccessDeleteProduct,
    onIncreaseProductAmount,
    onDecreaseProductAmount,
  }
}
