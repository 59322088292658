import React from 'react'
import DragIcon from '../../img/drag.png'

function DragPaper() {
  return (
    <div
      style={{
        top: '0px',
        left: '0px',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: 'rgba(112, 112, 114, 0.25)',
      }}
    >
      <img alt="drag" style={{ height: '40px' }} src={DragIcon} />
    </div>
  )
}

export default DragPaper
