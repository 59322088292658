import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type IProposalCustomer } from 'dto/ProposalDTO'
import { type ISendWhatsAppForm, sendWhatsAppSchema } from '../validators'

const INITIAL_SEND_WHATSAPP_FIELDS = {
  celular: '',
}

interface IUseSendWhatsAppForm {
  customer: IProposalCustomer
  onCancel: () => void
  onSubmit: (data: ISendWhatsAppForm) => void
}

export function useSendWhatsAppForm({
  customer,
  onCancel,
  onSubmit,
}: IUseSendWhatsAppForm) {
  const { handleSubmit, reset, ...rest } = useForm({
    defaultValues: {
      ...INITIAL_SEND_WHATSAPP_FIELDS,
      ...(customer?.celular && {
        celular: customer.celular,
      }),
    },
    resolver: yupResolver(sendWhatsAppSchema),
  })

  const handleSendWhatsAppCancel = () => {
    reset()
    onCancel()
  }

  const handleSendWhatsAppSubmit = handleSubmit((data) => {
    onSubmit(data)
  })

  return { handleSendWhatsAppSubmit, handleSendWhatsAppCancel, ...rest }
}
