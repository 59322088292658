import { useNotificationsContext } from 'context/NotificationsContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'

export interface IUseOpenNotificationProps {
  onOpenedSuccess?: () => void
}

export function useOpenNotification({
  onOpenedSuccess,
}: IUseOpenNotificationProps) {
  const {
    notificationsOpen,
    handleOpenNotifications,
    handleCloseNotifications,
  } = useNotificationsContext()

  const { logEvent } = useAmplitude()

  const handleToggle = (value: boolean) => {
    if (value) {
      handleOpenNotifications()
      onOpenedSuccess?.()
    } else {
      handleCloseNotifications()
      logEvent(notificationsTracker.actions.closeNotifications)
    }
  }

  return { notificationsOpen, handleToggle }
}
