import styled from 'styled-components'
import { BREAKPOINT_MOBILE } from './constants/breakpointMobile'

export const Container = styled.div`
  width: 90vw;
  height: 80vh;
  overflow: auto;

  padding: 20px;
  border-radius: 8px;
  background-color: #fff;

  display: grid;
  grid-gap: 16px;
  grid-template: 'HEADER HEADER' 'FILTER LIST' / 300px auto;

  .header,
  .filter,
  .list {
    padding: 8px 16px;
  }

  .header {
    grid-area: HEADER;
    border-bottom: 2px solid lightgray;
  }

  .filter {
    grid-area: FILTER;
    border-right: 2px solid lightgray;
  }

  .list {
    grid-area: LIST;
    height: 50vh;
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;

    position: fixed;
    inset: 0;

    .header {
      padding: 0;
    }

    .filter {
      border-right: none;
    }

    .list,
    .filter {
      height: 70vh;
    }

    &[data-filters-visible='true'] {
      grid-template: 'HEADER' min-content 'FILTER' max-content / auto;

      .list {
        display: none;
      }
    }

    &[data-filters-visible='false'] {
      grid-template: 'HEADER' min-content 'LIST' max-content / auto;

      .filter {
        display: none;
      }
    }
  }
`
