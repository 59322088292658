import { useEffect } from 'react'
import { EnumSizingType } from '../constants'
import { useSolLeadStore } from 'containers/SolLeadAlert/stores/useSolLeadStore'

export function useSolLeads({
  selectedClient,
  setSelectedClient,
  setCurrentSizingType,
  setClientDataDisplayed,
}) {
  const { lead, resetLead } = useSolLeadStore()

  useEffect(() => {
    if (lead) {
      setSelectedClient({
        label: lead.nome,
        ...lead,
      })
      setClientDataDisplayed(true)
      setCurrentSizingType(EnumSizingType.AverageConsumptionValue)
    }
  }, [lead])

  useEffect(() => {
    if (!selectedClient) {
      resetLead()
    }
  }, [selectedClient])
}
