import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { proposalQuotationCalculateDeliveryValue } from '../services/api'
import { type IProposalQuotationCalculateDeliveryValuePOSTPayload } from '../services/types'

export const useAddProposalQuotationCalculateDeliveryValue = () => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (
      payload: IProposalQuotationCalculateDeliveryValuePOSTPayload,
    ) => await proposalQuotationCalculateDeliveryValue.post(payload),
    onError: () => onErrorOpenToast('Houve um problema ao recalcular o frete.'),
  })
}
