import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { queryClient } from 'services/queryClient'
import useObserveQuery from 'hooks/useObserverQuery'
import { TAB_ENUM } from 'containers/EquipmentQuotationContainer/enums/tabEnum'

export function useModalRemoveQuotation() {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setQuotationSelected = useEquipmentContainerStore(
    (state) => state.setQuotationSelected,
  )

  const quotationDelete = useEquipmentContainerStore(
    (state) => state.openModalToDeleteQuotation,
  )

  const setOpenModalToDeleteQuotation = useEquipmentContainerStore(
    (state) => state.setOpenModalToDeleteQuotation,
  )

  const changeTab = useEquipmentContainerStore((state) => state.changeTab)

  const queryKey = [GET_DIMENSIONING, dimensioningId]

  const { data } = useObserveQuery<IDimensioningGETResponse>(queryKey)

  function redirectToChooseKit() {
    if (data?.quotations?.length === 1) {
      changeTab(TAB_ENUM.CHOOSE_KIT)
    }
  }

  function handleCloseDeleteModal() {
    setOpenModalToDeleteQuotation(null)
  }

  async function successDeleteQuotation() {
    await queryClient.cancelQueries(queryKey)

    queryClient.setQueryData<IDimensioningGETResponse>(queryKey, (old) => {
      if (old) {
        const quotationId = quotationDelete?.id
        const updatedData = {
          ...old,
          quotations: old?.quotations.filter(
            (quotation) => quotation.id !== quotationId,
          ),
        }

        if (quotationSelected?.id === quotationId && updatedData?.quotations) {
          setQuotationSelected(updatedData?.quotations[0])
        }

        return updatedData
      }

      return old
    })

    handleCloseDeleteModal()
    redirectToChooseKit()
  }

  return {
    quotationDelete,
    successDeleteQuotation,
    handleCloseDeleteModal,
  }
}
