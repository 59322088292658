import React from 'react'
import PropTypes from 'prop-types'
import { ViewIcon } from '@77sol/icons/dist'
import { Box, IconButton, useMediaQuery } from '@material-ui/core'
import { Paper, Tooltip, Typography } from '@77sol/core'

function PaperRedirectTo({
  text = '',
  margin = 0,
  tooltip = '',
  iconSize = 0,
  textSize = 'small',
  redirectTo = () => null,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Box margin={margin}>
      {downSm ? (
        <Paper onClick={redirectTo}>
          <Typography size={textSize}>{text}</Typography>
        </Paper>
      ) : (
        <Paper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography size={textSize}>{text}</Typography>
            <Tooltip title={tooltip}>
              <IconButton color="inherit" onClick={redirectTo}>
                <ViewIcon width={iconSize} cursor="pointer" />
              </IconButton>
            </Tooltip>
          </Box>
        </Paper>
      )}
    </Box>
  )
}

PaperRedirectTo.propTypes = {
  text: PropTypes.string,
  margin: PropTypes.number,
  tooltip: PropTypes.string,
  iconSize: PropTypes.number,
  textSize: PropTypes.string,
  redirectTo: PropTypes.func,
}

export default PaperRedirectTo
