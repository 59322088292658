export const BADGE_PLAN_COLORS = {
  BRONZE: {
    id: 1,
    color: 'orange',
    weight: 100,
  },
  PRATA: {
    id: 2,
    color: 'grayscale',
    weight: 200,
  },
  OURO: {
    id: 3,
    color: 'yellow',
    weight: 100,
  },
  RUBI: {
    id: 4,
    color: 'red',
    weight: 100,
  },
  DIAMANTE: {
    id: 5,
    color: 'secondary',
    weight: 100,
  },
  PLATINA: {
    id: 6,
    color: 'primary',
    weight: 100,
  },
}
