import { palette } from '@77sol/styles'
import { TextField } from 'components/Controllers/TextField'
import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
  gap: 20px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;

    & button {
      width: 100%;
    }
  }
`

export const NFValueInput = styled(TextField as any)`
  input {
    &:disabled {
      color: #262338;
    }
  }
`

export const PendingsForm = styled.form`
  background-color: #ffff;
  padding: 20px;
  border-radius: 8px;

  > div:nth-child(2) {
    margin-top: 48px;
  }
`

export const ClusterContainer = styled.div`
  .title {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: ${palette.grayscale[600]};
  }

  .helperText {
    color: ${palette.grayscale[400]};
    font-size: 0.75rem;
    margin-top: -12px;
  }
`

export const NFContainer = styled.div`
  background-color: #ffff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 4px;
  margin-top: 40px;

  & div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    & span {
      color: ${palette.grayscale[700]};
      font-size: 0.938rem;
    }

    & .chargesContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      & span {
        font-size: 1.875rem;
      }
    }

    & .total {
      font-weight: 700;
    }
  }

  @media (min-width: 576px) {
    gap: 6px;

    & div {
      flex-direction: row;
    }
  }
`

export const IconContainer = styled.div`
  cursor: pointer;
`
