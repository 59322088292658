import styled from 'styled-components'

export const QuotationTotal = styled.div`
  * {
    font-family: 'DM Sans';
  }

  p {
    font-weight: 700;
    display: flex;
    gap: 4px;
  }

  span {
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      align-items: center;
    }
  }
`
