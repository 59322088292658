import styled from 'styled-components'

export const Label = styled.label`
  display: block;
`

export const Client = styled.span`
  max-width: 500px;
  font-size: 1.4rem;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 10px;
`

export const Field = styled.div`
  display: block;

  label {
    padding-bottom: 10px;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 32px;
`
