import { type UseMutationOptions, useMutation } from '@tanstack/react-query'

import { generatePdfQuotation } from '../services/api'

export function useGeneratePdfQuotation<TError = unknown, TContext = unknown>({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: UseMutationOptions<string, TError, number, TContext>) {
  const data = useMutation({
    mutationFn: async (data) => {
      return await generatePdfQuotation(data)
    },
    onMutate,
    onSettled,
    onSuccess,
    onError,
  })
  return data
}
