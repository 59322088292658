import { type HTMLAttributes } from 'react'
import { Clock, Truck } from 'lucide-react'
import { useKitCard } from './hooks/useKitCard'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'
import { ManageFlags } from '../ManageFlags'
import * as S from './styles'

interface IKitCardProps extends HTMLAttributes<HTMLButtonElement> {
  kit: IKitDTOWithId
  disabled?: boolean
}

const KitCard: React.FC<IKitCardProps> = ({ kit, ...props }) => {
  const {
    firstLogo,
    hasTwoLogos,
    isChecked,
    secondLogo,
    totalValueFormated,
    buttonLabel,
    handleSelectKit,
  } = useKitCard(kit)

  const { logEvent } = useAmplitude()

  const eventAction = isChecked ? 'Desmarcou um kit' : 'Selecionou um kit'

  function handleClickCard() {
    logEvent(quotationTracker.actions.clickedOnKit, {
      origin: '/Cotar',
      action: eventAction,
      component: 'Card',
    })

    handleSelectKit()
  }

  function handleClickCheckbox(event: any) {
    event.stopPropagation()

    logEvent(quotationTracker.actions.clickedOnKit, {
      origin: '/Cotar',
      action: eventAction,
      component: 'Checkbox',
    })

    handleSelectKit()
  }

  function handleClickButton(event: any) {
    event.stopPropagation()

    logEvent(quotationTracker.actions.clickedOnKit, {
      origin: '/Cotar',
      action: eventAction,
      component: 'Button',
    })

    handleSelectKit()
  }

  return (
    <S.KitCardWrapper
      isChecked={isChecked}
      onClick={handleClickCard}
      {...props}
    >
      <S.CheckboxStyled
        id={kit?.title}
        checked={isChecked}
        className="checkbox"
        onClick={handleClickCheckbox}
        disabled={props.disabled}
      />
      <ManageFlags kit={kit} />
      <div className="logoContainer">
        <img src={firstLogo} alt="Logotipo" />
        {hasTwoLogos && <img src={secondLogo} alt="Logotipo" />}
      </div>
      <div className="content">
        <p className="title">{kit?.title}</p>
        <p className="description">{kit.description}</p>
        <p className="total">{totalValueFormated}</p>
        <hr />
        <div className="delivery-info">
          <p>
            <Truck />
            Frete Incluso. Entregue por: {kit?.delivered_by}
          </p>
          <p>
            <Clock />
            {kit?.delivery_description}
          </p>
        </div>
      </div>
      <S.ChooseKitButton
        variant="secondary"
        onClick={handleClickButton}
        disabled={props.disabled}
      >
        {buttonLabel}
      </S.ChooseKitButton>
    </S.KitCardWrapper>
  )
}
export { KitCard }
