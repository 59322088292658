import API from 'api'
import { type AdminInfosDisponibilityProductsGETResponse } from './types'

export const adminInfosDisponibilityProducts = {
  get: async (currentPhase?: string) => {
    return await API.get<AdminInfosDisponibilityProductsGETResponse>(
      '/admin/infos-disponibility-products',
      {
        params: {
          ...(currentPhase && { phase: currentPhase }),
        },
      },
    ).then(({ data }) => data)
  },
}
