import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button, Paper } from '@77sol/core'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './styles'

function ModalSalvarCotacao({
  openModalSalvarCotacao,
  setOpenModalSalvarCotacao,
  quotes,
  quoteToSave,
  handleSaveCotation,
  loadingSave,
}) {
  const classes = useStyles()

  const handlePotency = (products) => {
    if (products) {
      const modulo = products.find((x) => x.categoria == 62)
      const potency =
        parseFloat(modulo.potenciaModulo) * parseFloat(modulo.qtde)
      return parseFloat((potency / 1000).toFixed(2))
    }
  }

  const handleOverload = (products) => {
    if (products) {
      const modulo = products.find((x) => x.categoria == 62)
      const potency =
        parseFloat(modulo.potenciaModulo) * parseFloat(modulo.qtde)
      const inversor = products.find((x) => x.categoria == 61)
      const sobrecarga =
        parseFloat(inversor.potenciaInversor) * parseFloat(inversor.qtde)
      return ((potency / sobrecarga - 1) * 100).toFixed(2)
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={openModalSalvarCotacao}
      onClose={() => setOpenModalSalvarCotacao(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalSalvarCotacao} style={{ border: 'none' }}>
        <div className={classes.paperModalCards}>
          <Grid
            style={{
              height: '-webkit-fill-available',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            spacing={3}
            container
          >
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={12}
            >
              <SaveRoundedIcon
                style={{ fontSize: '100px', color: '#1335c6' }}
              />
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '20px 0px',
              }}
              item
              xs={12}
              md={12}
            >
              <Typography
                style={{
                  fontSize: '26px',
                  color: '#464F5B',
                  marginBottom: '35px',
                }}
              >
                Tem certeza que deseja salvar a cotação:
              </Typography>
              <Grid
                style={{ overflowY: 'auto', maxHeight: '40vh' }}
                justify="center"
                container
                spacing={2}
              >
                <Paper
                  elevation={1}
                  style={{
                    padding: '16px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px',
                  }}
                >
                  <Typography>
                    Cotação{' '}
                    {quoteToSave.quotation &&
                      quotes.findIndex(
                        (quote) =>
                          quote.quotation.id == quoteToSave.quotation.id,
                      ) + 1}
                  </Typography>
                  <Typography style={{ fontSize: '14px', color: '#464F5B' }}>
                    Potencia do kit: <b>{handlePotency(quoteToSave?.kit)}</b>
                  </Typography>
                  <Typography>
                    Sobrecarga: <b>{handleOverload(quoteToSave?.kit)}</b>
                  </Typography>
                  <Typography>
                    Valor total:{' '}
                    <b>
                      {quoteToSave.quotation?.total_value.toLocaleString(
                        'pt-br',
                        { style: 'currency', currency: 'BRL' },
                      )}
                    </b>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'center' }}
              item
              xs={12}
              md={12}
            >
              <Button
                onClick={() => handleSaveCotation(quoteToSave.quotation.id)}
                className={classes.filledButton}
                style={{ marginRight: '16px' }}
              >
                {loadingSave ? (
                  <CircularProgress
                    disableShrink
                    style={{ color: '#FFF' }}
                    size={20}
                    thickness={4}
                  />
                ) : (
                  'Salvar cotação'
                )}
              </Button>
              <Button
                onClick={() => setOpenModalSalvarCotacao(false)}
                className={classes.outlinedButton}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalSalvarCotacao
