import { useAcl } from 'acl/hooks/useAcl'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { type INestedRoute } from 'types/routes'

function RouteWithSubRoutes(route: INestedRoute) {
  const { checkIfUserCanAccessRoute } = useAcl()

  function validatePermission(children: React.ReactNode) {
    if (checkIfUserCanAccessRoute(route.path)) {
      return children
    }
    return <Redirect to="/erro/401" />
  }

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) =>
        validatePermission(<route.component {...props} route={route.routes} />)
      }
    />
  )
}

export default RouteWithSubRoutes
