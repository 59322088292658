import { useQuery } from '@tanstack/react-query'
import { orderHistoric } from '../services/api'
import { GET_ORDER_HISTORY } from '../constants/endpointKeys'

export const useOrderHistory = (orderId: number) => {
  const data = useQuery({
    queryKey: [GET_ORDER_HISTORY, orderId],
    queryFn: async () => await orderHistoric.get(orderId),
    enabled: Boolean(orderId),
  })

  return data
}
