import React from 'react'
import { Fade, Modal, Backdrop, Box } from '@material-ui/core'
import { Button, Paper } from '@77sol/core'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { configTracker } from 'services/tracker/events/configs/trackers'
import { useStyles } from '../../styles'

export function ModalMoreOptionsMobile({
  modalMoreOptionsMobile,
  setModalMoreOptionsMobile,
  onDeleteCurrentQuotation,
  setComparacao,
  comparacao,
  handleCreateQuote,
  loadingCreateQuote,
  quotes,
}) {
  const classes = useStyles()

  const { logEvent } = useAmplitude()

  function handleDeleteQuotation() {
    setModalMoreOptionsMobile(false)
    onDeleteCurrentQuotation()
  }

  return (
    <Modal
      className={classes.modalSm}
      open={modalMoreOptionsMobile}
      onClose={() => setModalMoreOptionsMobile(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalMoreOptionsMobile} style={{ border: 'none' }}>
        <Paper style={{ height: 'auto', width: '90%' }}>
          <Box display="grid" gridGap="16px">
            <Button
              fullWidth
              size="small"
              variant="outlined"
              disabled={quotes.length === 1}
              onClick={() => {
                logEvent(configTracker.actions.compare)
                if (comparacao) {
                  setComparacao(false)
                  setModalMoreOptionsMobile(false)
                } else {
                  setComparacao(true)
                  setModalMoreOptionsMobile(false)
                }
              }}
            >
              Comparar
            </Button>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              isLoading={loadingCreateQuote}
              onClick={() => {
                logEvent(quotationTracker.actions.newQuotation)
                handleCreateQuote()
              }}
            >
              Adicionar nova cotação
            </Button>
            <Button
              fullWidth
              color="red"
              size="small"
              variant="outlined"
              isLoading={loadingCreateQuote}
              onClick={handleDeleteQuotation}
            >
              Excluir cotação
            </Button>
            <Button
              fullWidth
              size="small"
              variant="text"
              onClick={() => setModalMoreOptionsMobile(false)}
            >
              Cancelar
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}
