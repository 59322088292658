import { useSelector } from 'react-redux'
import { type IReduxStore } from 'types/redux'

export const useIsAdmin = () => {
  const { role_id, privilegios_admin } = useSelector(
    (state: IReduxStore) => state.ReducerProfile.userProfile,
  )

  if (role_id === 1 || privilegios_admin === 1) {
    return true
  }

  return false
}
