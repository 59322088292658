import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  margin-top: 24px;

  h1,
  p,
  span {
    font-family: DM Sans;
  }

  h1 {
    font-weight: 700;
    font-size: 20px;
  }

  .quotation-main-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 16px;
    }
  }
`
