import { useEffect } from 'react'
import { Typography } from '@77sol/core'
import { useFetchShowCEP } from 'domains/showcep'
import { useFormContext } from 'react-hook-form'
import { TextField } from 'components/Controllers/TextField'
import { Checkbox } from '@77sol/core'
import { Container, CopyAddress } from './styles'
import { useCopyDeliveryAddress } from './hooks/useCopyDeliveryAddress'
import { type IShipmentFormValues } from '../../ShipmentForm.types'
import 'styles/grids.scss'
import { useFetchCnpjInfo } from 'domains/document/hooks/useDocument'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { ufList } from 'utils/constants'

export function BillingAddressForm(): JSX.Element {
  const {
    watch,
    setValue,
    reset,
    clearErrors,
    control,
    formState: {
      errors: { billingAddress },
    },
  } = useFormContext<IShipmentFormValues>()

  const { isCheckedCopy, handleCheckedCopy } = useCopyDeliveryAddress()

  const cep = watch('billingAddress.cep')
  const document = watch('billingAddress.documento')

  const { isFetching, cepData } = useFetchShowCEP(
    {
      cep,
    },
    {
      enabled: Boolean(cep),
    },
  )

  useEffect(() => {
    if (cepData) {
      clearErrors([
        'billingAddress.endereco',
        'billingAddress.bairro',
        'billingAddress.cidade',
        'billingAddress.estado',
      ])
      setValue('billingAddress.endereco', cepData.logradouro || '')
      setValue('billingAddress.bairro', cepData.bairro || '')
      setValue('billingAddress.cidade', cepData.cidade)
      setValue('billingAddress.estado', cepData.uf)
    }
  }, [cepData])

  const { documentInfo, isFetchingDocument } = useFetchCnpjInfo(
    {
      document,
    },
    {
      enabled: Boolean(document),
    },
  )

  useEffect(() => {
    reset({}, { keepValues: true })
    if (documentInfo) {
      setValue('billingAddress.documento', documentInfo.cnpj)
      setValue('billingAddress.nome', documentInfo.nome_empresarial || '')
      setValue('billingAddress.endereco', documentInfo.logradouro)
      setValue('billingAddress.numero', documentInfo.numero)
      setValue('billingAddress.complemento', documentInfo.complemento)
      setValue('billingAddress.bairro', documentInfo.bairro)
      setValue('billingAddress.estado', documentInfo.uf)
      setValue('billingAddress.cidade', documentInfo.municipio)
      setValue('billingAddress.cep', documentInfo.cep)
    }
  }, [documentInfo])

  return (
    <section>
      <Typography color="primary" colorWeight="300" size="large">
        Dados de faturamento
      </Typography>

      <Container data-testid="billing-form-container">
        <div className="form-grid">
          <TextField
            className="g-xs-12"
            name="billingAddress.documento"
            control={control}
            label={'CPF/CNPJ*'}
            mask="cpfCnpj"
            error={billingAddress?.documento?.message}
            isLoading={isFetchingDocument}
          />

          <TextField
            className="g-xs-12"
            name="billingAddress.nome"
            control={control}
            label={'Nome*'}
            error={billingAddress?.nome?.message}
          />
        </div>

        <CopyAddress>
          <Checkbox
            size="small"
            checked={isCheckedCopy}
            onChange={handleCheckedCopy}
            data-testid="copy-address-checkbox"
          />
          Copiar informações de endereço de entrega
        </CopyAddress>

        <div className="form-grid">
          <TextField
            className="g-xs-12 g-md-3"
            name="billingAddress.cep"
            control={control}
            label={'CEP*'}
            mask="cep"
            isLoading={isFetching}
            error={billingAddress?.cep?.message}
          />

          <TextField
            className="g-xs-12 g-md-6"
            name="billingAddress.endereco"
            control={control}
            label={'Endereço*'}
            error={billingAddress?.endereco?.message}
          />

          <TextField
            className="g-xs-4 g-md-3"
            name="billingAddress.numero"
            control={control}
            label={'Número*'}
            error={billingAddress?.numero?.message}
          />

          <TextField
            className="g-xs-8 g-md-4"
            name="billingAddress.complemento"
            control={control}
            label={'Complemento'}
          />

          <TextField
            className="g-xs-12 g-md-3"
            name="billingAddress.bairro"
            control={control}
            label={'Bairro*'}
            error={billingAddress?.bairro?.message}
          />

          <TextField
            className="g-xs-8 g-md-3"
            name="billingAddress.cidade"
            control={control}
            label={'Cidade*'}
            error={billingAddress?.cidade?.message}
          />

          <DropdownControlled
            fullWidth
            options={ufList}
            className="g-xs-4 g-md-2"
            name="billingAddress.estado"
            control={control}
            label={'UF*'}
            error={billingAddress?.estado?.message}
          />
        </div>
      </Container>
    </section>
  )
}
