import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 16px;
`

export const Label = styled.p`
  font-size: 0.875rem;
  letter-spacing: 0.75px;
  color: ${palette.grayscale[700]};
  margin-bottom: 10px;
`
