import { SidebarMobile } from '@77sol-ui/organisms'
import styled from 'styled-components'

export const SidebarMobileLogout = styled(SidebarMobile.Logout)`
  * {
    font-family: DM Sans, sans-serif;
  }

  margin-bottom: 60px;
`
