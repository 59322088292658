import { useWhatsappSend } from 'hooks/useWhatsappSend'
import { useToast } from '@77sol/core'
import { type IProposalDTO } from 'dto/ProposalDTO'
import { useCallback } from 'react'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import useObserveQuery from 'hooks/useObserverQuery'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'

export function useNotifyIntegrator({
  integrator,
}: Pick<IProposalDTO, 'integrator'>) {
  const { handleWhatsAppSend } = useWhatsappSend()
  const { onErrorOpenToast } = useToast()

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const handleNotifyIntegrator = useCallback(() => {
    const currentHost = location.host
    const quotationPreviewUrl = `${currentHost}/visualizar/cotacaoV2`

    if (!data) return
    if (!integrator?.phone) {
      return onErrorOpenToast('Telefone do integrador não cadastrado!')
    }

    const firstLine = `Olá ${integrator.name}. Você pode encontrar sua nova cotação personalizada em sua listagem de projetos pelo código #${data.proposal.project_id}.`
    const secondLine = `Ou acesse: ${quotationPreviewUrl}/${data.proposal.id} para visualizar e reservar seu kit.`

    handleWhatsAppSend(integrator.phone, `${firstLine} ${secondLine}`)
  }, [integrator, data])

  return { handleNotifyIntegrator }
}
