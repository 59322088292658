import { palette } from '@77sol/styles'
import styled from 'styled-components'

export * from '../styles'

export const Warning = styled.div`
  margin: 32px 0;

  p {
    font-size: 0.875rem;
    line-height: 24px;
    color: ${palette.grayscale[700]};
    letter-spacing: 0.75px;
  }

  p + p {
    margin-bottom: 8px;
    color: ${palette.grayscale[400]};

    span {
      cursor: pointer;
      color: ${palette.primary[300]};
    }
  }
`
