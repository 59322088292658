import React, { useEffect, useState } from 'react'
import { Input, Typography } from 'components'
import { ActionPlusIcon, DeleteIcon } from '@77sol/icons/dist'
import { Spacer } from '@77sol/core'
import palette from 'app_palette'

function ScheduleEdit(props) {
  /* eslint eqeqeq: 0 */ 
  const {
    schedules,
    setSchedules,
    qtdSchedule,
    setQtdSchedule,
    handleSave,
  } = props

  const [removingSchedule, setRemovingSchedule] = useState(false)

  useEffect(() => {
    if (removingSchedule) {
      handleSave()
      setRemovingSchedule(false)
    }
  }, [removingSchedule, handleSave])

  return (
    <div style={{ padding: '0px 32px' }}>
      <div>
        <Typography type="text_medium" color="grayscale" colorWeight="700">
          Defina o cronograma do projeto
        </Typography>
        <Typography type="text_x_small" color="grayscale" colorWeight="500">
          Entitule as etapas e quanto tempo irá levar para fazer cada atividade
        </Typography>
      </div>
      <Spacer size="16" direction="horizontal" />
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        {Array.from(Array(qtdSchedule).keys()).map((schedule, i) => (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                value={schedules[i]?.title}
                setValue={(value) => {
                  const newArray = schedules
                  newArray[i].title = value
                  setSchedules([...newArray])
                }}
                style={{ width: 'auto' }}
                size="small"
                label="Título"
                onBlur={() => handleSave()}
              />
              <Spacer size="16" direction="vertical" />
              <Input
                label="semanas"
                size="small"
                type="number"
                style={{ width: '40%' }}
                value={schedules[i]?.weeks}
                setValue={(value) => {
                  const newArray = schedules
                  newArray[i].weeks = value
                  setSchedules([...newArray])
                }}
                InputProps={{
                  endAdornment: 'Semanas',
                  inputProps: { min: 0 },
                }}
                onBlur={() => handleSave()}
              />
              <Spacer size="16" direction="vertical" />
              <DeleteIcon
                onClick={() => {
                  if (qtdSchedule != 1) {
                    setRemovingSchedule(true)
                    const arrSchedules = schedules
                    arrSchedules.splice(i, 1)
                    setQtdSchedule(qtdSchedule - 1)
                    setSchedules([...arrSchedules])
                  }
                }}
                width="20"
                color={
                  qtdSchedule == 1 ? palette.grayscale[400] : palette.red[300]
                }
                style={{
                  cursor: qtdSchedule == 1 ? 'auto' : 'pointer',
                  minWidth: '20px',
                }}
              />
            </div>
            <Spacer size="16" direction="horizontal" />
          </>
        ))}
        <Spacer size="16" direction="horizontal" />
        <div
          onClick={() => {
            setQtdSchedule(qtdSchedule + 1)
            setSchedules([...schedules, { title: '', weeks: '' }])
          }}
          style={{
            color: '#1335c6',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <ActionPlusIcon width="16px" color={palette.primary[300]} />
          <Spacer size="16" direction="vertical" />
          <Typography type="link_x_small" color="primary" colorWeight="300">
            Adicionar atividade
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ScheduleEdit
