import { format } from 'date-fns'
import { parseBRL } from 'utils'
import { FinancialLabel } from 'components'

export const handleFinancingName = (value) => {
  const labels = {
    '77sol': 'Simulação 77Sol',
    santander: 'Santander',
    bv: 'Banco BV',
    bdn: 'Banco do Nordeste',
    solfacil: 'Solfácil',
    personalizado: 'Personalizado',
  }

  return labels[value] || value
}

export const getFirstName = (fullName) => {
  return fullName ? fullName.split(' ')[0] : ''
}

export const formatSimulation = (financing, isOpenFinancingTabs) => {
  return financing?.simulations
    ?.sort((item) => (item.status === 'Aprovado' ? -1 : 1))
    ?.map((simulation, index) => {
      const { id, status, financier, bank, created_at, total_value } =
        simulation

      return {
        id: `#${id}`,
        status: {
          type: 'component',
          Component: ({ isRowSelected }) => (
            <FinancialLabel
              status={status}
              colorWeight={isRowSelected ? '300' : '700'}
              size="xsmall"
            />
          ),
        },
        financier: bank ? handleFinancingName(bank) : financier,
        ...(!isOpenFinancingTabs && {
          simulation_date: format(new Date(created_at), 'dd/MM/yyyy HH:mm'),
          total_value: parseBRL(total_value),
        }),
        rowData: {
          ...simulation,
          proposalId: financing.proposalId,
          rowIndex: index,
        },
      }
    })
}
