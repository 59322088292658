import {
  type UseInterceptClickProps,
  useInterceptableClick,
} from 'hooks/useInterceptableClick'
import { type UsePromptProps, usePrompt } from 'hooks/usePrompt'
import { useCallback, useEffect } from 'react'

interface UseInterceptsClicksAndNavigationProps
  extends Pick<
      UseInterceptClickProps,
      | 'excludeIds'
      | 'canInterceptElements'
      | 'interceptIds'
      | 'excludeClassNames'
    >,
    Pick<
      UsePromptProps,
      'blockPopNavigation' | 'blockPushNavigation' | 'blockReplaceNavigation'
    > {
  isEnabled: boolean
}

export function useInterceptsClickAndNavigation({
  isEnabled,
  blockPopNavigation,
  blockPushNavigation,
  blockReplaceNavigation,
  canInterceptElements,
  excludeIds,
  interceptIds,
  excludeClassNames,
}: UseInterceptsClicksAndNavigationProps) {
  const {
    eventIsEnabled,
    clickIntercepted,
    setClickIntercepted,
    handleEnableEvent,
    handleResetEvent,
    handleDisableEvent,
    handleUninterceptClick,
  } = useInterceptableClick({
    excludeIds,
    interceptIds,
    canInterceptElements,
    excludeClassNames,
  })

  const { allowNavigation, handleReset: handleResetPrompt } = usePrompt({
    when: eventIsEnabled,
    blockPopNavigation,
    blockPushNavigation,
    blockReplaceNavigation,
    onNavigationBlocked: () => {
      setClickIntercepted(true)
    },
  })

  const allowClick = useCallback(async () => {
    await handleUninterceptClick()
    allowNavigation()
    handleDisableEvent()
  }, [handleUninterceptClick, handleDisableEvent, allowNavigation])

  const handleClose = useCallback(() => {
    handleResetPrompt()
    handleResetEvent()
  }, [handleResetPrompt, handleResetEvent])

  useEffect(() => {
    if (isEnabled) {
      handleEnableEvent()
    } else {
      handleDisableEvent()
    }
  }, [isEnabled])

  return {
    clickIntercepted,
    setClickIntercepted,
    allowClick,
    handleClose,
  }
}
