import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > span {
    color: rgb(110, 113, 145);
  }
`
