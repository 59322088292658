import { useCreateFinancingPerQuotationFormContext } from '../../hooks/useCreateFinancingPerQuotationForm'
import { MaskedMonetaryInput } from 'components/Controllers/MaskedMonetaryInput'
import * as S from './styles'
import { SelectGraceDays } from '../ui/SelectGraceDays'
import { useEffect, useMemo } from 'react'
import { parseBRL } from 'utils'
import { type IQuotationDTO } from 'dto/QuotationDTO'

interface PaymentProps {
  choosedQuotation: IQuotationDTO
}

export function Payment({ choosedQuotation }: PaymentProps) {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useCreateFinancingPerQuotationFormContext()

  const input_value = watch('input_value')

  useEffect(() => {
    setValue('project_value', choosedQuotation.total_value)
    setValue('financing_value', choosedQuotation.total_value)
  }, [])

  useMemo(() => {
    if (Number(input_value) > Number(choosedQuotation.total_value)) {
      setValue('financing_value', parseBRL(0.0))
      return
    }

    setValue(
      'financing_value',
      parseBRL(choosedQuotation.total_value - input_value),
    )
  }, [input_value, choosedQuotation.total_value])

  return (
    <S.Container>
      <section>
        <h3>Informações de pagamento</h3>
        <S.InputGroup>
          <SelectGraceDays
            control={control}
            label="Dias de Carência"
            placeholder="Selecione os dias de Carência"
            name="grace_days"
            error={errors.grace_days?.message}
          />
          <MaskedMonetaryInput
            label="Entrada"
            placeholder="R$0,00"
            error={errors.input_value?.message}
            control={control}
            name="input_value"
            required={false}
          />
        </S.InputGroup>
      </section>

      <section>
        <h3>Simulação de financiamento</h3>
        <S.InputGroup>
          <MaskedMonetaryInput
            label="Valor do Projeto"
            placeholder="R$0,00"
            name="project_value"
            control={control}
            disabled
          />

          <MaskedMonetaryInput
            label="Valor Financiado"
            placeholder="R$0,00"
            name="financing_value"
            disabled
          />
        </S.InputGroup>
      </section>
    </S.Container>
  )
}
