import { useProposalQuotationAll } from 'domains/proposal/proposal-quotation-all/hooks/useProposalQuotationAll'
import { useState } from 'react'

export function useBuyModal({ handleCloseModal, proposalId }) {
  const [selectedQuotation, setSelectedQuotation] = useState({})
  const [isCartLoading, setIsCartLoading] = useState(false)
  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false)
  const [cartQuote, setCartQuote] = useState()

  const handleConfirm = async () => {
    setIsCartLoading(true)
    setCartQuote(selectedQuotation?.id)

    setIsCartLoading(false)
    setIsCheckoutModalOpen(true)
  }

  const handleClose = () => {
    setSelectedQuotation({})
    handleCloseModal()
  }

  const { data: quotations, isLoading } = useProposalQuotationAll({
    proposalId,
  })

  return {
    quotations,
    handleClose,
    cartQuote,
    handleConfirm,
    selectedQuotation,
    setSelectedQuotation,
    isCartLoading,
    isLoading,
    setIsCheckoutModalOpen,
    isCheckoutModalOpen,
  }
}
