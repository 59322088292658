import { InputMask, type InputProps } from '@77sol-ui/atoms'

import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
} from 'react-hook-form'
import { applyInputMask, formatInputValue } from './utils/formatter'

interface IMaskedMonetaryInputProps<T extends FieldValues> extends InputProps {
  control?: Control<T, object>
  name: Path<T>
}

/**
 * This component is designed to offer a monetary
 * input field, providing a visual representation for users as
 * they type values and observe the applied formatting. Utilizing
 * the controlled component approach, it allows for the clean capture
 * of the entered value without formatting, converting it into a decimal
 * representation.
 */

export const MaskedMonetaryInput = <T extends FieldValues>({
  error,
  control,
  name,
  ...rest
}: IMaskedMonetaryInputProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name, onBlur } }) => (
        <InputMask.Money
          {...rest}
          value={applyInputMask(value)}
          onChange={(event) => {
            event.target.value = formatInputValue(event.target.value)
            onChange(event, formatInputValue(event.target.value))
          }}
          onBlur={onBlur}
          name={name}
          error={error}
        />
      )}
    />
  )
}
