import { Typography } from '@77sol/core'
import { palette } from '@77sol/styles'
import React from 'react'
import { parseBRL } from 'utils'

export function PaymentMethods({ paymentMethods, billetNumber }) {
  return (
    <div>
      {paymentMethods.length != 0 && (
        <div style={{ marginTop: '12px' }}>
          <Typography
            style={{
              fontSize: '10px',
              backgroundColor: palette.grayscale[300],
              padding: '4px 10px',
            }}>
            Formas de pagamento
          </Typography>
          <table style={{ borderSpacing: '0px', width: '100%' }}>
            <tr>
              {paymentMethods?.billet && (
                <td
                  colSpan="1"
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      lineHeight: '15px',
                      color: palette.grayscale[700],
                    }}>
                    Boleto
                  </Typography>
                  <Typography
                    style={{
                      color: palette.grayscale[500],
                      fontSize: '8px',
                      lineHeight: '12px',
                    }}>
                    Em {billetNumber} boletos
                  </Typography>
                </td>
              )}
              {paymentMethods?.card && (
                <td
                  colSpan="1"
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      lineHeight: '15px',
                      color: palette.grayscale[700],
                    }}>
                    Cartão de crédito
                  </Typography>
                  <Typography
                    style={{
                      color: palette.grayscale[500],
                      fontSize: '8px',
                      lineHeight: '12px',
                    }}>
                    Em até {paymentMethods.card.number_installments}x
                  </Typography>
                </td>
              )}
              {paymentMethods?.bank_transfer && (
                <td
                  colSpan="1"
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      lineHeight: '15px',
                      color: palette.grayscale[700],
                    }}>
                    Transferência bancária
                  </Typography>
                </td>
              )}
            </tr>
            {paymentMethods?.financing.length > 0 && (
              <>
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      border: '1px solid #DFE3E8',
                      padding: '4px 10px',
                    }}>
                    <Typography
                      style={{
                        fontSize: '10px',
                        lineHeight: '15px',
                        color: palette.grayscale[700],
                      }}>
                      Financiamento
                    </Typography>
                    <Typography
                      style={{
                        color: palette.grayscale[500],
                        fontSize: '8px',
                        lineHeight: '12px',
                      }}>
                      Simulado em {paymentMethods?.financing?.length}{' '}
                      financiador(es)
                    </Typography>
                  </td>
                </tr>
                {paymentMethods?.financing?.map((financing, key) => (
                  <tr key={key}>
                    <td
                      colSpan="5"
                      style={{
                        border: '1px solid #DFE3E8',
                        padding: '4px 10px',
                      }}>
                      <div style={{ display: 'flex' }}>
                        <img
                          style={{
                            width: 32,
                            marginRight: 16,
                            objectFit: 'contain',
                          }}
                          src={financing.image_financier}
                          alt={financing.name_financier}
                        />
                        <div>
                          <Typography
                            style={{
                              fontSize: '10px',
                              lineHeight: '15px',
                              color: palette.grayscale[700],
                            }}>
                            {financing.name_financier === '77Sol'
                              ? `Simulação ${financing.name_financier}`
                              : `Banco ${financing.name_financier}`}
                          </Typography>
                          {financing.waiting_period && (
                            <Typography
                              style={{
                                color: palette.grayscale[500],
                                fontSize: '8px',
                                lineHeight: '12px',
                              }}>
                              Carência de {financing.waiting_period} dia(s)
                            </Typography>
                          )}
                          {financing.range_installments && (
                            <Typography
                              style={{
                                color: palette.grayscale[500],
                                fontSize: '8px',
                                lineHeight: '12px',
                              }}>
                              {financing.range_installments?.length} opção(ões)
                              de parcela(s) disponível(is)
                            </Typography>
                          )}
                          {financing.number_installments && (
                            <Typography
                              style={{
                                color: palette.grayscale[500],
                                fontSize: '8px',
                                lineHeight: '12px',
                              }}>
                              {`${
                                financing.number_installments
                              } parcelas de ${parseBRL(
                                financing.value_installment,
                              )}`}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </table>
        </div>
      )}
    </div>
  )
}
