import React, { useEffect, useState } from 'react'
import API from 'api'
import useRedirect from 'hooks/useRedirect'
import { useToast } from '@77sol/core'
import { Box, Hidden, useMediaQuery } from '@material-ui/core'
import { OrderValueFinancingLinkerModal } from 'containers/Modals'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { Header } from './components/Header'
import { Content } from './components/Content'
import { formatSimulation, getFirstName } from './utils'
import { useFinancingSimulations } from './hooks/useFinancingSimulations'

function FinancingSimulations({
  onClose,
  onCloseFinancingTabs,
  isFromProposalList,
  isOpenSimulationDetails,
  selectedFinancing,
  selectedSimulation,
  onSelectSimulation,
  isFinancingPerValue,
  simulationId,
}) {
  const quotationId = selectedFinancing.rowData?.id_origin
  const selectedFinancingId = selectedFinancing.rowData?.financing_id
  const {
    data,
    isLoading,
    handleCancelFinancingQueries,
    refetchSimulationsList,
  } = useFinancingSimulations({
    quotationId,
    isFinancingPerValue,
    selectedFinancingId,
  })

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const downXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const { logEvent } = useAmplitude()
  const { redirectTo } = useRedirect()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [financingToBind, setFinancingToBind] = useState({})
  const [formattedSimulations, setFormattedSimulations] = useState([])

  const [
    isOrderValueFinancingLinkerModalOpen,
    setIsOrderValueFinancingLinkerModalOpen,
  ] = useState(false)

  const onRowClick = (value) => {
    const orderCanceled = value.rowData.status === 'Cancelado'
    if (orderCanceled) {
      onCloseFinancingTabs()
      onErrorOpenToast('Este financiamento foi cancelado!')
      return
    }

    onSelectSimulation(value)
  }

  const redirectToProject = () => {
    logEvent(financingTracker.actions.viewProject, {
      financingType: 'Cotação',
      origin: '/financiamentos',
    })

    redirectTo('/projetos/propostas', {
      projectId: selectedFinancing?.rowData?.project_id,
      customerId: selectedFinancing?.rowData?.client_id,
      customerName: getFirstName(selectedFinancing?.rowData?.customer),
    })
  }

  const openBindFinancing = (financing = {}) => {
    logEvent(financingTracker.actions.handleAttachToOrderModal, {
      financingType: 'Valor',
    })

    setFinancingToBind(financing)
    setIsOrderValueFinancingLinkerModalOpen(true)
  }

  const bindFinancingToOrder = (order = {}) => {
    logEvent(financingTracker.actions.handleBindToOrder, {
      financingType: 'Valor',
    })

    API.patch('/financing/bind-financing-to-order', {
      financing_id: financingToBind?.rowData?.id,
      order_id: order?.id,
    })
      .then(() => {
        refetchSimulationsList()
        onSuccessOpenToast('Financiamento atrelado com sucesso!')
        setIsOrderValueFinancingLinkerModalOpen(false)
      })
      .catch(({ response }) => {
        if (response.status === 406) {
          return onErrorOpenToast(response.data.message)
        }
        onErrorOpenToast('Erro ao tentar atrelar o pedido ao financiamento!')
      })
  }

  useEffect(() => {
    if (simulationId && data.simulations.length > 0) {
      const simulationToShow = data.simulations.find(
        (simulation) => simulation.id === Number(simulationId),
      )
      const rowIndex = data.simulations.findIndex(
        (simulation) => simulation.id === Number(simulationId),
      )
      if (simulationToShow) {
        onSelectSimulation({
          rowData: { ...simulationToShow, rowIndex },
        })
      }
    }
  }, [simulationId, data.simulations])

  useEffect(() => {
    const allSimulations = formatSimulation(data, isOpenSimulationDetails)
    if (allSimulations) {
      setFormattedSimulations(allSimulations)

      if (isOpenSimulationDetails && !selectedSimulation.rowData) {
        const [firstSimulation] = allSimulations
        onRowClick(firstSimulation)
      }
    }
  }, [data, isOpenSimulationDetails, isFromProposalList])

  function onCloseSlide() {
    handleCancelFinancingQueries()
    onClose()
  }

  return (
    <>
      <Box height="100%" display="flex">
        <Hidden xlDown={isFromProposalList}>
          <Box width="100%" marginTop={isFromProposalList ? '16px' : '0px'}>
            {!isFromProposalList && (
              <Header
                title={
                  downXs || isOpenSimulationDetails
                    ? 'Simulações'
                    : 'Simulações de financiamento'
                }
                onBack={onCloseSlide}
                viewProject={redirectToProject}
                visibleButtons={
                  !isFinancingPerValue && !isOpenSimulationDetails
                }
              />
            )}
            <Content
              onClick={onRowClick}
              isLoading={isLoading}
              bindedFinancing={data?.bindedFinancing || {}}
              openBindFinancing={openBindFinancing}
              selectedFinancing={selectedSimulation}
              financinigsList={formattedSimulations}
              isFinancingPerValue={isFinancingPerValue}
              table={!downSm && isOpenSimulationDetails}
            />
          </Box>
        </Hidden>
      </Box>

      {isOrderValueFinancingLinkerModalOpen && (
        <OrderValueFinancingLinkerModal
          isOpen={isOrderValueFinancingLinkerModalOpen}
          onClose={() => setIsOrderValueFinancingLinkerModalOpen(false)}
          handleBindFinancingValueToOrder={bindFinancingToOrder}
          valueFinancingDataInfo={financingToBind?.rowData?.id_financing_origin}
        />
      )}
    </>
  )
}

export default FinancingSimulations
