import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import { useAdminStructureTypesProducts } from 'domains/admin/structure-types-products/hooks/useAdminStructureTypesProducts'

import { type FieldValues } from 'react-hook-form'

export const SelectStructureType = <T extends FieldValues>({
  ...props
}: Omit<ISelectProps<T>, 'options'>) => {
  const { structureTypesData } = useAdminStructureTypesProducts()

  return <Select {...props} options={structureTypesData} />
}
