import React, { useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Input } from 'components'
import API from '../../../../../../api'

function TarifaCard(props) {
  const tokenRegister = localStorage.getItem('sessionToken')
  const { returnData, selectedQuote, setReturnData } = props
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [newValue, setNewValue] = useState()
  const [loading, setLoading] = useState(false)

  const handleChangeValue = () => {
    setLoading(true)

    const finalValue = newValue
      ? newValue.replace(',', '.')
      : parseFloat(returnData.tarifaConsiderada.toFixed(2))

    API.post(
      '/proposal/dimensioning/calculate/viability',
      {
        quotation_id: selectedQuote,
        tariff: finalValue,
        annual_correction: returnData.correcaoAnualTarifa * 100,
        opex: returnData.opex ? returnData.opex : 0,
        project_total_cost: returnData.custoTotalProjeto,
      },
      {
        headers: {
          token: tokenRegister,
        },
      },
    )
      .then((res) => {
        setReturnData(res.data)
        setLoading(false)
      })
      .catch((err) => {})
  }

  return (
    <div>
      <Input
        label="Tarifa"
        loading={loading}
        defaultValue={parseFloat(returnData?.tarifaConsiderada?.toFixed(2))}
        value={newValue}
        size="small"
        currencyFormat
        setValue={setNewValue}
        onBlur={handleChangeValue}
        submit={handleChangeValue}
        adornment="kWh/R$"
        adornmentPosition="right"
        type={downSm ? 'tel' : 'text'}
      />
    </div>
  )
}

export default TarifaCard
