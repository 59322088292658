import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import { type IKitDTOWithId } from '../hooks/useLoadKits'
import { v4 as uuid } from 'uuid'

const convertQuotationToKit = (quotation: IQuotationDTO) => {
  const newKit: IKitDTOWithId = {
    id: quotation?.id,
    kitId: uuid(),
    title: quotation?.title,
    description: quotation?.description,
    logos: quotation?.logos,
    valueTotal: quotation?.total_value,
    delivery_description: quotation?.delivery_description,
    delivered_by: quotation?.delivered_by,
    products: quotation?.products,
    ready_to_delivery: quotation?.ready_to_delivery,
    available_at: quotation?.available_at || '',
    changed: true,
  }

  return newKit
}

export { convertQuotationToKit }
