import { useNotificationsContext } from 'context/NotificationsContext'
import { Notification } from '@77sol-ui/organisms'
import { Bell } from 'lucide-react'
import React, { forwardRef } from 'react'

export const NotificationButton = forwardRef<
  HTMLButtonElement,
  React.HtmlHTMLAttributes<HTMLButtonElement>
>(({ ...props }, ref) => {
  const { unreadNotifications } = useNotificationsContext()

  return (
    <Notification.Trigger
      data-testid="notification-button"
      {...props}
      asIcon
      variant="ghost"
      ref={ref}
    >
      {unreadNotifications && <Notification.Marker animate />}
      <Bell size={20} />
    </Notification.Trigger>
  )
})
