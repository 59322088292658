import React from 'react'
import { Spacer } from '@77sol/core'
import { Typography } from 'components'
import { useMediaQuery } from '@material-ui/core'
import animationSolarEnergy from './animation-solar-energy.gif'

export function AnimationSolarEnergy({ animationText }) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <div>
      <img
        style={{
          width: '100%',
        }}
        src={animationSolarEnergy}
        alt="animação solar"
      />
      <Spacer size="16" direction="horizontal" />
      <center>
        <Typography
          type={downSm ? 'link_medium' : 'display_x_small_x_bold'}
          color="grayscale"
          colorWeight="500"
        >
          {animationText ? animationText : 'Estamos gerando seu kit...'}
        </Typography>
      </center>
      <Spacer size="16" direction="horizontal" />
    </div>
  )
}
