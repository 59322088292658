export const ordersTracker = {
  successOrderModal: {
    default: {
      orderToPending: 'Pedido Realizado: Acelere a confirmação do pedido',
      orderToOrderList: 'Pedido Realizado: Continuar sem acelerar',
    },
    valueFinancing: {
      orderToValueFinancing:
        'Pedido Realizado: Atrelar este pedido ao Financiamento por Valor',
      orderToOrderList: 'Pedido Realizado: Continuar sem atrelar',
    },
  },
  actions: {
    createOrder:
      'Finalização do Pedido (Modal): Clicou em Reservar equipamentos',
    confirmShippingChange:
      'Reservar Pedido: Clicou em confirmar a alteração do custo de frete',
    cancelShippingChange:
      'Reservar Pedido: Clicou em cancelar a alteração do custo de frete',
    sendPending: 'Pedido: Enviou as informações do formulário de pendências',
    discardPending:
      'Pedido: Descartou as informações do formulário de pendências',
    changeTab: 'Pedido: Mudança de aba',
    purchase: 'Pedido: Clicou em comprar',
    intentionToCancelOrder: 'Intenção de cancelar pedido',
    clickOrder: 'Pedido: Clicou no pedido da listagem',
    cancelOrder: 'Pedido: Clicou em cancelar o pedido',
    gaveUpCancellation: 'Pedido: Desistiu do cancelamento do pedido',
    trackOrder: 'Pedido: Clicou no link de rastreio',
    clickedOnTheTagButton: 'Pedido: Clicou no botão tag',
    talkToManager: 'Pedido: Clicou em falar com o gerente',
    clickOrderHistory: 'Pedido: Clicou em histórico do pedido',
    filterOrders: 'Pedido: Clicou no botão de aplicar filtros',
    clearFilterOrders: 'Pedido: Clicou no botão de limpar filtros',
    reserveEquipment: 'Clicou em reservar equipamentos',
    seeQuotationOrigin: 'Clicou em visualizar cotação de origem',
  },
}
