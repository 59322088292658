import { Tooltip } from '@77sol/core'
import { X } from 'lucide-react'
import { Button } from './styles'

import { type IDeleteQuotationButtonProps } from './types'
import { useDeleteQuotationButton } from './hooks/useDeleteQuotationButton'
import { useCallback } from 'react'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { TAB_ENUM_AMPLITUDE } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function DeleteQuotationButton({
  quotation,
}: IDeleteQuotationButtonProps) {
  const { deleteQuotation, hasOnlyOneQuotation } = useDeleteQuotationButton({
    quotation,
  })
  const currentTab = useEquipmentContainerStore((state) => state.currentTab)

  const { logEvent } = useAmplitude()

  const handleDeleteQuotation = useCallback(() => {
    logEvent(quotationTracker.actions.removeQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })

    deleteQuotation()
  }, [currentTab])

  return (
    <Tooltip title="Excluir cotação">
      <Button onClick={handleDeleteQuotation} disabled={hasOnlyOneQuotation}>
        <X />
      </Button>
    </Tooltip>
  )
}
