import * as S from './styles'
import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'

interface RejectModalProps {
  onReject: () => void
  onToggleModal: (value: boolean) => void
}

export function RejectModal({ onReject, onToggleModal }: RejectModalProps) {
  return (
    <Modal.Root open onOpenChange={onToggleModal}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper maxHeight="90vh" maxWidth="50vw">
          <S.ModalClose />
          <Modal.Header>
            <Modal.Title>
              Tem certeza de que deseja rejeitar o(s) lead(s)?
            </Modal.Title>
            <Modal.Description>
              Essa ação não poderá ser desfeita.
            </Modal.Description>
          </Modal.Header>
          <Modal.Footer>
            <Button
              fullWidth
              variant="outline"
              onClick={() => {
                onReject()
              }}
            >
              Rejeitar
            </Button>
            <Modal.Close asChild>
              <Button fullWidth>Cancelar</Button>
            </Modal.Close>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
