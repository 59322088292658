import api from 'api'
import { QUOTATION_REMOVE_PRODUCT } from '../constants/endpointKeys'
import { type IQuotationProductRemovePayload } from './types'

export const quotationRemoveProduct = async (
  payload: IQuotationProductRemovePayload,
) => {
  return await api
    .post(QUOTATION_REMOVE_PRODUCT, payload)
    .then(({ data }) => data)
}
