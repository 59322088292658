import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
  closeXIcon: {
    position: 'absolute',
    top: '20px',
    right: '30px',
    cursor: 'pointer',
    width: '30px',
    zIndex: 999999,
    '@media (max-width: 779px)': {
      right: '20px',
    },

    '@media (max-width:1279px)': {
      top: '24px',
      width: '24px',
    },
  },
  paper: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '16px',
    border: 'none',
    width: '95%',
    height: '95%',
    position: 'absolute',

    '& > div': {
      borderRadius: 'inherit',
    },

    '@media (max-width:1279px)': {
      overflowY: 'auto',
      borderRadius: '0px',
      width: '100%',
      height: '100%',
    },

    '@media (min-width: 1280px)': {
      maxWidth: '1680px',
      maxHeight: '1080px',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',

    '@media (min-width: 1280px)': {
      height: '100%',
    },
  },
})
