import React from 'react'
import PropTypes from 'prop-types'
import palette from 'app_palette'
import { Paper, Typography } from 'components'
import { Spacer } from '@77sol/core'
import { Hidden, useMediaQuery } from '@material-ui/core'
import { ActionPlusIcon } from '@77sol/icons/dist'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import styles from './styles'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

function PaperSelect({
  onClick,
  firstText,
  secondText,
  title,
  type,
  icon,
  asModal,
  labelPro,
}) {
  const classes = styles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.divPaper} style={stylesAnimation.bounce}>
        <Paper
          borderColor={
            type === 'new' ? 'primary' : asModal ? 'grayscale' : 'none'
          }
          pointer
          style={{ padding: downSm ? '16px' : '24px' }}
          fillDirection="horizontal"
          onClick={onClick}
          noBackground={type === 'new'}
        >
          {type === 'show' && (
            <>
              <Typography color="grayscale" colorWeight="700" type="link_large">
                {title}
              </Typography>
              <Spacer size="4" direction="horizontal" />
              <Typography
                color="grayscale"
                colorWeight="500"
                type="text_x_small"
              >
                {firstText}
              </Typography>
              <Spacer size="4" direction="horizontal" />
              <Typography
                color="grayscale"
                colorWeight="500"
                type="text_x_small"
              >
                {secondText}
              </Typography>
            </>
          )}
          {type === 'new' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                color: palette.primary[300],
              }}
            >
              <ActionPlusIcon width="24" />
              <Spacer size="16" direction="vertical" />
              <Typography color="primary" colorWeight="300" type="link_medium">
                {title}
              </Typography>
            </div>
          )}
          {type === 'withIcon' && (
            <>
              <Hidden smUp>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: palette.grayscale[800],
                  }}
                >
                  {icon}
                  <Spacer size="16" direction="vertical" />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        color="grayscale"
                        colorWeight="700"
                        type="link_small"
                      >
                        {title}
                      </Typography>
                      <Typography
                        color={
                          firstText == 'Recomendado' ? 'primary' : 'grayscale'
                        }
                        colorWeight={firstText == 'Recomendado' ? '300' : '400'}
                        type="link_x_small"
                      >
                        {firstText}
                      </Typography>
                    </div>
                    {labelPro && (
                      <div
                        style={{
                          padding: '8px',
                          backgroundColor: palette.primary[300],
                          borderRadius: '8px',
                          fontSize: '8px',
                          lineHeight: '8px',
                          letterSpacing: '0.25px',
                          color: 'white',
                          height: 'max-content',
                        }}
                      >
                        PRO
                      </div>
                    )}
                  </div>
                </div>
              </Hidden>
              <Hidden xsDown>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: palette.grayscale[800],
                  }}
                >
                  {icon}
                  {labelPro && (
                    <div
                      style={{
                        position: 'absolute',
                        padding: '8px',
                        backgroundColor: palette.primary[300],
                        borderRadius: '8px',
                        fontSize: '8px',
                        lineHeight: '8px',
                        letterSpacing: '0.25px',
                        color: 'white',
                        marginRight: '-42px',
                        marginTop: '-32px',
                      }}
                    >
                      PRO
                    </div>
                  )}
                  <Spacer size="12" direction="horizontal" />
                  <Typography
                    style={{ textAlign: 'center' }}
                    color="grayscale"
                    colorWeight="700"
                    type="link_small"
                  >
                    {title}
                  </Typography>
                  <Spacer size="4" direction="horizontal" />
                  <Typography
                    style={{ textAlign: 'center' }}
                    color={firstText == 'Recomendado' ? 'primary' : 'grayscale'}
                    colorWeight={firstText == 'Recomendado' ? '300' : '400'}
                    type="link_x_small"
                  >
                    {firstText}
                  </Typography>
                </div>
              </Hidden>
            </>
          )}
        </Paper>
      </div>
    </StyleRoot>
  )
}

PaperSelect.propTypes = {
  title: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  type: PropTypes.oneOf(['show', 'new', 'withIcon']),
  icon: PropTypes.node,
}

PaperSelect.defaultProps = {
  title: '',
  firstText: '',
  secondText: '',
  type: 'show',
  icon: null,
}

export default PaperSelect
