import { useState } from 'react'

export function useProductFilter() {
  const [filters, setFilters] = useState({})
  const [filtersIsVisible, setFiltersIsVisible] = useState(false)

  function handleDisplayFilters() {
    setFiltersIsVisible(!filtersIsVisible)
  }

  function handleApplyFilter(filter, value) {
    setFilters({
      ...filters,
      [filter]: value,
    })
  }

  return {
    filters,
    filtersIsVisible,
    handleApplyFilter,
    handleDisplayFilters,
  }
}
