import React, { useState } from 'react'
import { Box, Hidden } from '@material-ui/core'
import { TableList } from 'components'
import { handlePotency, parseBRL } from 'utils'
import { WeatherLightningIcon, SolarPanelIcon, BagIcon } from '@77sol/icons/dist'
import {
  Title, Typography, Spacer, Radio,
} from '@77sol/core'
import palette from 'app_palette'

const formatQuotation = (quotes, isFinancingModal) => {
  let quotations = [...quotes];
  if (quotations[0]?.quotation) {
    quotations = quotations?.map((item) => {
      item.quotation.stock = item.quotation.filter_stock
      item.quotation.products = item.kit
      return item?.quotation
    })
  }
  return quotations.filter((quote) => quote?.stock === '77sol' || !quote?.stock)
    .map((quotation) => ({
      selected: {
        type: 'component',
        notPrevent: 'true',
        Component: ({ isRowSelected }) => (!isFinancingModal && quotation?.reserved ? (
          <BagIcon width="24" color={palette.grayscale[300]} />
        ) : (
          <Radio
            checked={isRowSelected}
            size="small"
          />
        )),
      },
      id: quotation.id,
      potency: `${handlePotency(quotation?.products)} kWp`,
      equipment_value: parseBRL(quotation?.equipment_value),
      extra_costs: parseBRL(quotation?.extra_costs),
      total_value: (
        <Typography
          size="large"
          color={!isFinancingModal && quotation?.reserved ? 'grayscale' : 'primary'}
          colorWeight={false ? '400' : '300'}
        >
          {parseBRL(quotation?.total_value)}
        </Typography>
      ),
      rowData: {
        ...quotation,
        disabled: !!quotation?.reserved && !isFinancingModal,
      },
    }))
}

function QuotationContainer({
  TitleIcon,
  title,
  subtitle,
  quotations,
  onChange,
  isFinancingModal,
}) {
  /*eslint-disable */
  const [selectedQuotation, setSelectedQuotation] = useState({})

  const handleSelectQuotation = quotation => {
    if (quotation.id === selectedQuotation.id) {
      onChange({})
      return setSelectedQuotation({})
    }

    onChange(quotation)
    setSelectedQuotation(quotation)
  }

  return (
    <Box>
      <Box>
        <Hidden smDown>
          <Title size="xsmall" color="primary" colorWeight="300" style={{ maxWidth: '1000px' }}>
            <TitleIcon width="48px" />
            <Spacer size="32" direction="vertical" />
            {formatQuotation(quotations, isFinancingModal).length == 0 ? (
              'Não é possível financiar um kit de estoque próprio!'
            ) : (
              <>{title}</>
            )}
          </Title>
          <Spacer size="16" direction="both" />
          <Typography colorWeight="500" size="small">
            {formatQuotation(quotations, isFinancingModal).length == 0 ? '' : <>{subtitle}</>}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography
            type="link"
            size="small"
            color="primary"
            colorWeight="300"
            style={{ flexDirection: 'column', textAlign: 'center' }}
          >
            <TitleIcon width="24px" />
            <Spacer size="8" direction="horizontal" />
            {formatQuotation(quotations, isFinancingModal).length == 0 ? (
              'Não é possível financiar um kit de estoque próprio!'
            ) : (
              <>{title}</>
            )}
          </Typography>
          <Spacer size="16" direction="both" />
          <Typography colorWeight="500" size="xsmall">
            {formatQuotation(quotations, isFinancingModal).length == 0 ? '' : <>{subtitle}</>}
          </Typography>
        </Hidden>
      </Box>
      <Box paddingY={2}>
        {formatQuotation(quotations, isFinancingModal).length != 0 && (
          <Hidden smDown>
            <TableList
              columns={[
                '',
                'ID',
                'Potência do kit',
                'Valor Equipamentos',
                'Custos extras',
                'Valor Total',
              ]}
              onClickFullRow
              selectedRow={selectedQuotation}
              values={formatQuotation(quotations, isFinancingModal)}
              onRowClick={handleSelectQuotation}
              rowOutlined
              LastItem={null}
            />
          </Hidden>
        )}
        <Hidden mdUp>
          {formatQuotation(quotations, isFinancingModal).map(values => (
            <Box
              display="flex"
              alignItems="center"
              borderRadius="8px"
              padding={1}
              marginY={1}
              width="100%"
              border={
                selectedQuotation.id === values.id
                  ? `1px solid ${palette.primary[300]}`
                  : `1px solid ${palette.grayscale[300]}`
              }
              onClick={() => {
                if (values.disabled) {
                  //handleSelectQuotation(values)
                } else {
                  handleSelectQuotation(values)
                }
              }}
            >
              <Box>
                <values.selected.Component
                  isRowSelected={selectedQuotation.id === values.id}
                />
              </Box>
              <Spacer size="16" direction="both" />
              <Box width="100%">
                <Box display="flex">
                  <Typography size="xsmall">#{values.id}</Typography>
                  <Spacer size="24" direction="both" />
                  <Typography size="xsmall">
                    <WeatherLightningIcon width="16px" />
                    <Spacer size="8" direction="both" />
                    {values.potency}
                  </Typography>
                </Box>
                <Box>
                  {values.total_value}
                  <Typography size="xsmall" colorWeight="500">
                    <SolarPanelIcon width="16px" />
                    <Spacer size="8" direction="both" />
                    {values.equipment_value}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Hidden>
      </Box>
    </Box>
  )
}

export default QuotationContainer
