import API from '../api'
const tokenRegister = localStorage.getItem('sessionToken')

const getFinancingStatus = async () => {
  try {
    const { data } = await API.get('/financing/status', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })

    let result = data
    let newArray = []

    for (var i = 0; i < result.length; i++) {
      newArray.push({
        id: result[i].id,
        value: result[i].id,
        label: result[i].name,
        slug: result[i].slug,
      })
    }

    newArray.unshift({
      label: 'Todos',
      value: 'all',
    })

    return newArray
  } catch (e) {
    console.log(e)
  }
}

export default getFinancingStatus
