import React, { useState, useEffect, createRef } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import { Typography, Paper } from '@material-ui/core'
import palette from 'app_palette'
import {
  EditIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ViewIcon,
} from '@77sol/icons/dist'
import smoothscroll from 'smoothscroll-polyfill'
import { useSelector } from 'react-redux'
import API from 'api'
import { SizingEdit, PagamentoEdit } from '../../..'
import {
  PaymentMethods,
  Equipments,
  ProductionAndConsumption,
  ProjectBudget,
} from './components'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function ContainerQuotePdf({
  container,
  containerFather,
  index,
  quote,
  i,
  isEditing,
  setIsEditing,
  isEditingMore,
  setIsEditingMore,
  stateQuotesPdf,
  setStateQuotesPdf,
}) {
  const proposal = useSelector((state) => state.Reducer1)

  const [paymentMethods, setPaymentsMethods] = useState([])
  const [billetNumber, setBilletNumber] = useState()

  const [sizingActivePanel, setSizingActivePanel] = useState('all')
  const [quoteEdit, setQuoteEdit] = useState({})

  const SizingStepLabel = (value) => {
    const labels = {
      sizing: 'Dados do dimensionamento',
      equipments: `Cotação ${quote.quotation.id} - Equipamentos`,
      budget: `Cotação ${quote.quotation.id} - Orçamento`,
      investmentReturn: `Cotação ${quote.quotation.id} - Retorno de investimento`,
    }

    return labels[value] || ''
  }

  const getPayments = () => {
    API.get(`/proposal/quotation/payment_forms/${quote.quotation.id}`).then(
      (res) => {
        const { methods } = res.data
        if (methods.length == 0) {
          setPaymentsMethods({ financing: [] })
        } else {
          if (methods.financing) {
            setPaymentsMethods({ ...methods })
          } else {
            methods.financing = []
            setPaymentsMethods({ ...methods })
          }
          if (!methods.billet) {
            setBilletNumber(0)
          }
          if (methods.billet.billet_one) {
            setBilletNumber(1)
          }
          if (methods.billet.billet_two) {
            setBilletNumber(2)
          }
          if (methods.billet.billet_three) {
            setBilletNumber(3)
          }
          if (methods.billet.billet_four) {
            setBilletNumber(4)
          }
        }
      },
    )
  }

  const [budgetEdit, setBudgetEdit] = useState([])
  const [returnData, setReturnData] = useState()

  const handleShowRoi = () => {
    API.get(`/proposal/quotation/roi/calculate/${quote.quotation.id}`).then(
      (res) => {
        setReturnData(res.data)
      },
    )
  }

  const handleGetBudget = (id) => {
    API.get(`/proposal/list/budgets/${id}`).then((res) => {
      setBudgetEdit(res.data)
      handleShowRoi()
    })
  }

  useEffect(() => {
    if (!isEditingMore) {
      handleGetBudget(quote.quotation.id)
      getPayments()
    }
  }, [isEditingMore])

  // Scroll
  const refContainer = createRef()
  const handleClickContainer = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (refContainer.current) {
      refContainer.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    if (!refContainer.current) {
      return
    }
    handleClickContainer()
  }, [refContainer])

  return (
    <Draggable
      isDragDisabled
      type="droppableItem"
      key={container.id}
      draggableId={container.id}
      index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          onClick={() => {
            if (isEditing == container.id) {
              setIsEditing('')
            } else if (isEditing?.toString().indexOf(i) == -1) {
              setIsEditing(containerFather.id)
            } else {
              setIsEditing(container.id)
            }
          }}>
          <div
            style={{
              position: 'relative',
              width: '100%',
              border:
                isEditing == container.id &&
                `2px solid ${palette.primary[300]}`,
              boxSizing: 'content-box',
            }}>
            {isEditing == container.id && (
              <div
                ref={(el) => {
                  refContainer.current = el
                }}
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-30px',
                  right: '-30px',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  padding: '12px',
                  backgroundColor: '#FFF',
                  cursor: 'auto',
                  maxHeight: '70vh',
                  overflowY: 'auto',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: '#707072',
                      paddingRight: '12px',
                      borderRight: '1px solid #e2e6e7',
                    }}>
                    {isEditing == container.id && container.display}
                  </Typography>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {container.id != 'LinkPdf' && container.id != 'ClientPdf' && (
                      <Tooltip title="Editar">
                        <EditIcon
                          onClick={() => {
                            if (isEditingMore == container.id) {
                              setIsEditingMore('')
                            } else {
                              setIsEditingMore(container.id)
                            }
                          }}
                          style={{
                            cursor: 'pointer',
                            marginRight: '8px',
                          }}
                          width="16"
                          color={palette.grayscale[600]}
                        />
                      </Tooltip>
                    )}
                    {index != 0 &&
                      stateQuotesPdf[i].filter(
                        (container) => container.visible == true,
                      )[0].id != container.id && (
                        <Tooltip title="Mover para cima">
                          <ArrowUpIcon
                            style={{
                              cursor: 'pointer',
                              marginRight: '8px',
                            }}
                            width="16"
                            color={palette.grayscale[600]}
                            onClick={() => {
                              if (index != 0) {
                                const arrayNew = stateQuotesPdf
                                const newContainers = reorder(
                                  stateQuotesPdf[i],
                                  index,
                                  index - 1,
                                )
                                arrayNew[i] = newContainers
                                setStateQuotesPdf([...arrayNew])
                              }
                            }}
                          />
                        </Tooltip>
                      )}
                    {index != stateQuotesPdf[i].length - 1 &&
                      stateQuotesPdf[i]
                        .filter((container) => container.visible == true)
                        .reverse()[0].id != container.id && (
                        <Tooltip title="Mover para baixo">
                          <ArrowDownIcon
                            onClick={() => {
                              const arrayNew = stateQuotesPdf
                              const newContainers = reorder(
                                stateQuotesPdf[i],
                                index,
                                index + 1,
                              )
                              arrayNew[i] = newContainers
                              setStateQuotesPdf([...arrayNew])
                            }}
                            style={{
                              cursor: 'pointer',
                              marginRight: '8px',
                            }}
                            width="16"
                            color={palette.grayscale[600]}
                          />
                        </Tooltip>
                      )}
                    {container.id != `paymentQuote${i}` && (
                      <Tooltip title="Esconder">
                        <ViewIcon
                          onClick={() => {
                            const arrayNew = stateQuotesPdf
                            const newContainer = Array.from(stateQuotesPdf[i])
                            newContainer[index].visible = false
                            arrayNew[i] = newContainer
                            setStateQuotesPdf([...arrayNew])
                            setIsEditing('')
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                          width="16"
                          color={palette.grayscale[600]}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
                {isEditingMore == container.id && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {container.id == `returnQuote${i}` && (
                      <div style={{ minWidth: '25vw' }}>
                        {sizingActivePanel != 'all' && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '12px',
                            }}>
                            <div
                              onClick={() => setSizingActivePanel('all')}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}>
                              <ArrowBackIosRoundedIcon
                                style={{
                                  color: '707072',
                                  fontSize: '15px',
                                  marginRight: '8px',
                                }}
                              />
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  marginRight: '16px',
                                  color: '#707072',
                                }}>
                                Voltar
                              </Typography>
                            </div>
                            <Paper
                              style={{
                                backgroundColor: palette.primary[300],
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '6px',
                                fontSize: '13px',
                                borderRadius: '8px',
                                width: '-webkit-fill-available',
                              }}>
                              {SizingStepLabel(sizingActivePanel)}
                            </Paper>
                          </div>
                        )}
                        <SizingEdit
                          sizingActivePanel={sizingActivePanel}
                          setSizingActivePanel={setSizingActivePanel}
                          quoteEdit={quoteEdit}
                          setQuoteEdit={setQuoteEdit}
                          proposal={proposal}
                          proposalDragDrop
                          oneQuoteId={quote.quotation.id}
                          handleGetBudget={handleGetBudget}
                          budgetEdit={budgetEdit}
                          selectedQuote={quote.quotation.id}
                        />
                      </div>
                    )}
                    {container.id == `quoteData${i}` && (
                      <div style={{ minWidth: '25vw' }}>
                        {sizingActivePanel != 'all' && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '12px',
                            }}>
                            <div
                              onClick={() => setSizingActivePanel('all')}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}>
                              <ArrowBackIosRoundedIcon
                                style={{
                                  color: '707072',
                                  fontSize: '15px',
                                  marginRight: '8px',
                                }}
                              />
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  marginRight: '16px',
                                  color: '#707072',
                                }}>
                                Voltar
                              </Typography>
                            </div>
                            <Paper
                              style={{
                                backgroundColor: palette.primary[300],
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '6px',
                                fontSize: '13px',
                                borderRadius: '8px',
                                width: '-webkit-fill-available',
                              }}>
                              {SizingStepLabel(sizingActivePanel)}
                            </Paper>
                          </div>
                        )}
                        <SizingEdit
                          sizingActivePanel={sizingActivePanel}
                          setSizingActivePanel={setSizingActivePanel}
                          quoteEdit={quoteEdit}
                          setQuoteEdit={setQuoteEdit}
                          proposal={proposal}
                          proposalDragDrop
                          oneQuoteId={quote.quotation.id}
                          handleGetBudget={handleGetBudget}
                          budgetEdit={budgetEdit}
                          selectedQuote={quote.quotation.id}
                        />
                      </div>
                    )}
                    {container.id == `productionQuote${i}` && (
                      <div style={{ minWidth: '25vw' }}>
                        {sizingActivePanel != 'all' && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '12px',
                            }}>
                            <div
                              onClick={() => setSizingActivePanel('all')}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}>
                              <ArrowBackIosRoundedIcon
                                style={{
                                  color: '707072',
                                  fontSize: '15px',
                                  marginRight: '8px',
                                }}
                              />
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  marginRight: '16px',
                                  color: '#707072',
                                }}>
                                Voltar
                              </Typography>
                            </div>
                            <Paper
                              style={{
                                backgroundColor: palette.primary[300],
                                color: '#FFF',
                                textAlign: 'center',
                                padding: '6px',
                                fontSize: '13px',
                                borderRadius: '8px',
                                width: '-webkit-fill-available',
                              }}>
                              {SizingStepLabel(sizingActivePanel)}
                            </Paper>
                          </div>
                        )}
                        <SizingEdit
                          sizingActivePanel={sizingActivePanel}
                          setSizingActivePanel={setSizingActivePanel}
                          quoteEdit={quoteEdit}
                          setQuoteEdit={setQuoteEdit}
                          oneQuoteId={quote.quotation.id}
                          proposal={proposal}
                          proposalDragDrop
                          consumptionPanel
                          sizingEdit={proposal.sizings.find(
                            (sizing) =>
                              sizing.id ==
                              proposal.quotes.find(
                                (quoteFind) =>
                                  quoteFind.quotation.id == quote.quotation.id,
                              ).dimensioning.id,
                          )}
                          handleGetBudget={handleGetBudget}
                          budgetEdit={budgetEdit}
                          selectedQuote={quote.quotation.id}
                        />
                      </div>
                    )}
                    {container.id == `paymentQuote${i}` && (
                      <div style={{ minWidth: '35vw' }}>
                        <PagamentoEdit
                          proposal={proposal}
                          selectedQuoteEdit={quote.quotation.id}
                          setMethodsQuoteEdit={() => {}}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {container.id === `returnQuote${i}` && container.visible && (
              <ProjectBudget quote={quote} returnData={returnData} i={i} />
            )}
            {container.id === `quoteData${i}` && container.visible && (
              <Equipments budgetEdit={budgetEdit} i={i} />
            )}
            {container.id === `productionQuote${i}` && container.visible && (
              <ProductionAndConsumption quote={quote} />
            )}
            {container.id === `paymentQuote${i}` && container.visible && (
              <PaymentMethods
                paymentMethods={paymentMethods}
                billetNumber={billetNumber}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default ContainerQuotePdf
