export const PAYMENT_OPTIONS = [
  {
    label: 'Boleto (À vista)',
    value: 'A vista (boleto)',
  },
  {
    label: 'PIX (À vista)',
    value: 'A vista (PIX)',
  },
  {
    label: 'Transferência (À vista)',
    value: 'A vista (Transferência)',
  },
  {
    label: 'Cartão de Crédito',
    value: 'Yapay Cartão de Crédito',
  },
  {
    label: 'Consórcio',
    value: 'consorcio',
  },
  {
    label: 'Financiamento via 77Sol',
    value: 'financiamento_77sol',
  },
  {
    label: 'Financiamento via Cliente',
    value: 'financiamento_cliente',
  },
]

export const CONSORCIO_OPTIONS = [
  {
    label: 'Banco do Brasil',
    value: 'Consórcio Banco do Brasil',
  },
  {
    label: 'Bradesco',
    value: 'Consórcio Bradesco',
  },
]

export const FINANCIAMENTO_77SOL_OPTIONS = [
  {
    label: 'BTG',
    value: 'Financiamento BTG 77',
  },
  {
    label: 'BV',
    value: 'Financiamento BV 77',
  },
  {
    label: 'Losango',
    value: 'Financiamento Losango 77',
  },
  {
    label: 'Santander',
    value: 'Financiamento Santander 77',
  },
]

export const FINANCIAMENTO_CLIENTE_OPTIONS = [
  {
    label: 'Alfa',
    value: 'Financiamento Alfa',
  },
  {
    label: 'Banco do Brasil',
    value: 'Financiamento Banco do Brasil',
  },
  {
    label: 'Banco do Nordeste',
    value: 'Financiamento Banco do Nordeste',
  },
  {
    label: 'Banco Votorantim',
    value: 'Financiamento Banco Votorantim',
  },
  {
    label: 'Banrisul',
    value: 'Financiamento Banrisul',
  },
  {
    label: 'Bradesco',
    value: 'Financiamento Bradesco',
  },
  {
    label: 'BV (meu financiamento solar)',
    value: 'Financiamento BV (meu financiamento solar)',
  },
  {
    label: 'BTG (Integrador Corban)',
    value: 'Financiamento BTG (Integrador Corban)',
  },
  {
    label: 'Caixa',
    value: 'Financiamento Caixa',
  },
  {
    label: 'Itaú',
    value: 'Financiamento Itaú',
  },
  {
    label: 'Outro Banco',
    value: 'Financiamento Próprio (banco desconhecido)',
  },
  {
    label: 'Safra',
    value: 'Financiamento Safra',
  },
  {
    label: 'Santander',
    value: 'Financiamento Santander',
  },
  {
    label: 'Sicoob',
    value: 'Financiamento Sicoob',
  },
  {
    label: 'Sicredi',
    value: 'Financiamento Sicredi',
  },
  {
    label: 'Solfacil',
    value: 'Financiamento Solfacil',
  },
  {
    label: 'XP',
    value: 'Financiamento XP',
  },
]

export const ALL_OPTIONS = [
  ...CONSORCIO_OPTIONS,
  ...FINANCIAMENTO_77SOL_OPTIONS,
  ...FINANCIAMENTO_CLIENTE_OPTIONS,
]
