import styled from 'styled-components'
import { Modal as ModalCore } from '@77sol/core'

export const ModalWrapper = styled(ModalCore)`
  padding: 0 !important;

  cursor: pointer;
  overflow: hidden;

  width: fit-content;
  height: fit-content;
`

export const ModalContent = styled.div`
  position: relative;
  width: fit-content;
  height: 100%;
  overflow: hidden;
`

export const ImageContainer = styled.div`
  height: 100%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .desktop {
    display: initial;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: initial;
    }
  }
`
