import { ScrollTable } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const Wrapper = styled(ScrollTable.Container)<{ numberOfRows: number }>`
  height: ${(props) =>
    `calc(72px * ${props.numberOfRows} + 60px + 75px + 8px)`};

  .table {
    height: fit-content;
  }

  .table-scroll-area {
    flex: unset;
  }

  .table-scroll-area:has(tbody > tr) {
    height: 100%;
  }

  .table-footer {
    align-items: end;
    border: none;
  }

  > [role='presentation'] {
    height: unset;
    flex: 1;
  }
`
