import API from 'api'
import { type IDocumentPayload, type IDocumentResponse } from './types'

export const document = {
  get: async (payload: IDocumentPayload) =>
    await API.post<IDocumentResponse>('/document', payload)
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      }),
}
