import { useFormContext } from 'react-hook-form'
import { TextField } from 'components/Controllers/TextField'
import * as S from './styles'

export const ConsumptionAverage = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <S.Container>
      <S.Label>
        Insira a média de consumo referente aos últimos 12 meses
      </S.Label>
      <TextField
        autoFocus
        control={control}
        label="Consumo médio*"
        name="average_consumption_kwh"
        type="number"
        endAdornment="kWh"
        error={errors.average_consumption_kwh?.message as string}
      />
    </S.Container>
  )
}
