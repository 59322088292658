import styled from 'styled-components'

export const PerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const PerformanceInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const PerformanceTitle = styled.div`
  * {
    font-family: DM Sans;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #4f4f4f;
  }
`
