import { apiV2 } from 'apis/apiV2'
import { DASHBOARD_PROPOSALS } from '../constants/endpointKeys'
import {
  type IGetProposalsRequest,
  type IResponseGetProposalsData,
} from './types'

export const getDashboardProposalsData = async ({
  params,
}: IGetProposalsRequest): Promise<IResponseGetProposalsData> => {
  return await apiV2
    .get(DASHBOARD_PROPOSALS, {
      params,
    })
    .then((response) => {
      return response.data
    })
}
