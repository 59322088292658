import React, { useState, useEffect, useRef } from 'react'
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import { Input, Typography, Paper } from 'components'
import { Spacer, Button, useToast } from '@77sol/core'
import { useDispatch } from 'react-redux'
import { InfoIcon, DeleteIcon, EditIcon } from '@77sol/icons/dist'
import CircularProgress from '@material-ui/core/CircularProgress'
import palette from 'app_palette'
import { Steps } from 'intro.js-react'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import 'intro.js/introjs.css'
import './introJsTheme.css'
import { useStyles } from './styles'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

function AzimuteCard({
  resumido,
  showButtons,
  proposal,
  setShowAzimute,
  locked,
  sizingEdit,
  setOthersLoading,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [position, setPosition] = useState(proposal.posicaoSolInstalacao)
  const [generationLoss, setGenerationLoss] = useState(
    proposal.perdaEstimadaGeracao,
  )

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setOthersLoading(loading)
  }, [loading])

  const [azimuteHelper, setAzimuteHelper] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: '.angleHelper',
        title: 'Ângulo ideal para instalação:',
        intro: (
          <div>
            <div className="innerTextHelper" style={{ marginBottom: '10px' }}>
              A inclinação dos módulos resultam em um melhor desempenho da usina
              solar!
            </div>
            <div className="innerTextHelper" style={{ marginBottom: '10px' }}>
              Para que se tenha uma garantia de melhor desempenho do sistema
              fotovoltaico com uma produção de energia aproximadamente constante
              ao longo do ano, uma regra interessante é inclinar os módulos com
              o mesmo ângulo de latitude local.
            </div>
            <div className="innerTextHelper">
              O grau de inclinação apresentando corresponde a maior média de
              incidência solar para o CEP atribuído a este projeto.
            </div>
          </div>
        ),
        tooltipClass: classes.angleStep,
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.positionHelper',
        title: 'Posição do sol em relação a instalação:',
        intro: (
          <div>
            <div className="innerTextHelper" style={{ marginBottom: '10px' }}>
              O posicionamento do local de instalação em relação ao sol pode
              interferir na geração de energia.
            </div>
            <div className="innerTextHelper">
              Sendo assim, quando o sol está a norte da instalação, a perda é
              0%, enquanto que nas outras localidades geográficas, oferecemos
              perdas percentuais estudadas e simuladas.
            </div>
          </div>
        ),
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
      {
        element: '.lossHelper',
        title: 'Perda estimada de geração:',
        intro: (
          <div>
            <div className="innerTextHelper" style={{ marginBottom: '10px' }}>
              A perda estimada em percentual irá adequar o dimensionamento, seja
              ele, calculado inicialmente por potência ou consumo para se
              adequar a esta nova condição.
            </div>
            <div className="innerTextHelper">
              Demais perdas após a instalação podem ocorrer, como por
              sombreamento, poeira, cabeamento e/ou temperatura.
            </div>
          </div>
        ),
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      },
    ],
  })

  const onExitHelper = () => {
    const newObj = { ...azimuteHelper }
    newObj.stepsEnabled = false
    setAzimuteHelper(newObj)
  }

  const onHelper = () => {
    const newObj = { ...azimuteHelper }
    newObj.stepsEnabled = true
    setAzimuteHelper(newObj)
  }

  const handleChangePosition = (value, type) => {
    if (type == 'remove') {
      setLoadingRemove(true)
    } else {
      setLoading(true)
    }

    const perdaInicial = parseFloat(proposal.perdaEstimadaGeracao)
    let perda = parseFloat(proposal.perdaEstimadaGeracao)
    let angulo = ''

    if (parseInt(proposal.anguloIdealInicial) < 0) {
      angulo = parseInt(proposal.anguloIdealInicial) * -1
    } else {
      angulo = parseInt(proposal.anguloIdealInicial)
    }

    if (parseInt(angulo) <= 5) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 0
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 0
      }
      if (value === 'leste') {
        perda = 0
      }
    }

    if (parseInt(angulo) === 6) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 0
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 1
      }
      if (value === 'leste') {
        perda = 1
      }
    }

    if (parseInt(angulo) === 7) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 1
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 1
      }
      if (value === 'leste') {
        perda = 2
      }
    }

    if (parseInt(angulo) === 8) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 1
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 1
      }
      if (value === 'leste') {
        perda = 2
      }
    }

    if (parseInt(angulo) === 9) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 2
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 2
      }
      if (value === 'leste') {
        perda = 3
      }
    }

    if (parseInt(angulo) === 10) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 3
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 2
      }
      if (value === 'leste') {
        perda = 4
      }
    }

    if (parseInt(angulo) === 11) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 3
      }
      if (value === 'noroeste') {
        perda = 0
      }
      if (value === 'nordeste') {
        perda = 2
      }
      if (value === 'leste') {
        perda = 4
      }
    }

    if (parseInt(angulo) === 12) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 4
      }
      if (value === 'noroeste') {
        perda = 1
      }
      if (value === 'nordeste') {
        perda = 2
      }
      if (value === 'leste') {
        perda = 5
      }
    }

    if (parseInt(angulo) === 13) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 4
      }
      if (value === 'noroeste') {
        perda = 1
      }
      if (value === 'nordeste') {
        perda = 2
      }
      if (value === 'leste') {
        perda = 5
      }
    }

    if (parseInt(angulo) === 14) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 5
      }
      if (value === 'noroeste') {
        perda = 1
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 6
      }
    }

    if (parseInt(angulo) === 15) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 6
      }
      if (value === 'noroeste') {
        perda = 1
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 7
      }
    }

    if (parseInt(angulo) === 16) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 6
      }
      if (value === 'noroeste') {
        perda = 1
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 7
      }
    }

    if (parseInt(angulo) === 17) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 7
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 8
      }
    }

    if (parseInt(angulo) === 18) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 7
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 8
      }
    }

    if (parseInt(angulo) === 19) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 8
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 3
      }
      if (value === 'leste') {
        perda = 9
      }
    }

    if (parseInt(angulo) === 20) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 9
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 10
      }
    }

    if (parseInt(angulo) === 21) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 9
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 10
      }
    }

    if (parseInt(angulo) === 22) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 10
      }
      if (value === 'noroeste') {
        perda = 2
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 11
      }
    }

    if (parseInt(angulo) === 23) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 10
      }
      if (value === 'noroeste') {
        perda = 3
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 12
      }
    }

    if (parseInt(angulo) === 24) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 11
      }
      if (value === 'noroeste') {
        perda = 3
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 12
      }
    }

    if (parseInt(angulo) === 25) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 11
      }
      if (value === 'noroeste') {
        perda = 3
      }
      if (value === 'nordeste') {
        perda = 4
      }
      if (value === 'leste') {
        perda = 13
      }
    }

    if (parseInt(angulo) >= 26) {
      if (value === 'norte') {
        perda = 0
      }
      if (value === 'oeste') {
        perda = 12
      }
      if (value === 'noroeste') {
        perda = 3
      }
      if (value === 'nordeste') {
        perda = 5
      }
      if (value === 'leste') {
        perda = 13
      }
    }

    let valorFinal = ''

    if (perdaInicial == 0 || locked == 'potencia') {
      valorFinal = (proposal.potenciaCC / 1000) * (1 + perda / 100)
    } else if (perdaInicial > perda) {
      valorFinal =
        proposal.potenciaCC / 1000 / (1 + (perdaInicial - perda) / 100)
    } else {
      const valorInicial = proposal.potenciaCC / 1000 / (1 + perdaInicial / 100)
      valorFinal = valorInicial * (1 + perda / 100)
    }

    if (locked == 'potencia') {
      valorFinal =
        proposal.potenciaCC / 1000 - (valorFinal - proposal.potenciaCC / 1000)
    }

    API.post('/proposal/dimensioning/update/azimute', {
      dimensioning_id: proposal.id,
      initial_ideal_angle: angulo,
      estimated_generation_loss: editing ? generationLoss : perda,
      position_sun_installation: value,
      potency: valorFinal,
    })
      .then((res) => {
        setLoading(false)
        setLoadingRemove(false)
        setEditing(false)
        setPosition(value)
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        if (type == 'remove') {
          setShowAzimute(false)
        }
        onSuccessOpenToast('Azimute alterado com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o azimute!')
        setLoading(false)
        setLoadingRemove(false)
      })
  }

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.divPaper} style={stylesAnimation.bounce}>
        <Paper
          style={{
            border: sizingEdit ? `2px solid ${palette.grayscale[300]}` : 'none',
            boxShadow: sizingEdit ? 'none' : 'rgb(0 0 0 / 4%) 0px 4px 6px',
          }}
        >
          <Steps
            enabled={azimuteHelper.stepsEnabled}
            steps={azimuteHelper.steps}
            initialStep={azimuteHelper.initialStep}
            onExit={onExitHelper}
            options={{
              hidePrev: true,
              showBullets: true,
              showProgress: false,
              nextLabel: 'Avançar',
              prevLabel: 'Voltar',
              doneLabel: 'Pronto',
            }}
          />
          <Grid container>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              item
              xs={12}
            >
              <Typography
                type={downSm ? 'link_small' : 'text_medium'}
                color="grayscale"
                colorWeight="700"
              >
                Perda relacionada ao ângulo azimutal
              </Typography>
              <div style={{ display: 'flex' }}>
                <Tooltip title="Informações sobre a perda relacionada ao ângulo azimutal">
                  <div>
                    <InfoIcon
                      onClick={() => onHelper()}
                      width="24px"
                      color={palette.primary[300]}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </Tooltip>
                <Spacer size="16" direction="vertical" />
                {loadingRemove ? (
                  <CircularProgress
                    disableShrink
                    style={{ color: palette.primary[300] }}
                    size={24}
                    thickness={4}
                  />
                ) : (
                  <Tooltip title="Remover perda relacionada ao ângulo azimutal">
                    <div>
                      <DeleteIcon
                        onClick={() => {
                          handleChangePosition('norte', 'remove')
                        }}
                        width="24px"
                        color={palette.red[300]}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Spacer size="16" direction="horizontal" />
            </Grid>
            <Grid
              className={classes.paddingRight}
              item
              xs={12}
              md={12}
              lg={12}
              xl={resumido ? 12 : 8}
              style={{ display: 'flex' }}
            >
              <Grid className={!resumido && classes.rightBorder} container>
                <Grid
                  className="angleHelper"
                  item
                  xs={resumido ? 12 : 6}
                  md={resumido ? 12 : 6}
                  lg={resumido ? 12 : 6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      type={downSm ? 'text_small' : 'text_medium'}
                      color="grayscale"
                      colorWeight="700"
                    >
                      Ângulo ideal para instalação
                    </Typography>
                  </div>
                  <Spacer size="16" direction="horizontal" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: resumido ? '100%' : '90%',
                        justifyContent: 'space-between',
                        alignItems: resumido && 'center',
                      }}
                    >
                      <Typography
                        color="grayscale"
                        colorWeight="700"
                        type={
                          downSm ? 'display_x_small_bold' : 'display_small_bold'
                        }
                      >
                        {proposal.anguloIdealInicial}° N
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {resumido && (
                  <Grid item xs={12}>
                    <Spacer
                      style={{
                        borderBottom: `2px solid ${palette.grayscale[300]}`,
                      }}
                      size="16"
                      direction="horizontal"
                    />
                    <Spacer size="16" direction="horizontal" />
                  </Grid>
                )}
                <Grid
                  className="positionHelper"
                  item
                  xs={resumido ? 12 : 6}
                  md={resumido ? 12 : 6}
                  lg={resumido ? 12 : 6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      type={downSm ? 'text_small' : 'text_medium'}
                      color="grayscale"
                      colorWeight="700"
                    >
                      Posição do sol em relação a instalação
                    </Typography>
                  </div>
                  <Spacer size="16" direction="horizontal" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {showButtons == false ? (
                      <Typography
                        style={{
                          fontSize: '32px',
                          color: '#464F5B',
                          textTransform: 'capitalize',
                        }}
                      >
                        {proposal.posicaoSolInstalacao}
                      </Typography>
                    ) : (
                      <Input
                        setValue={handleChangePosition}
                        value={position}
                        select
                        size="small"
                        loading={loading}
                      >
                        <option value="norte">Norte</option>
                        <option value="oeste">Oeste</option>
                        <option value="noroeste">Noroeste</option>
                        <option value="nordeste">Nordeste</option>
                        <option value="leste">Leste</option>
                      </Input>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {resumido && (
              <Grid item xs={12}>
                <Spacer
                  style={{
                    borderBottom: `2px solid ${palette.grayscale[300]}`,
                  }}
                  size="16"
                  direction="horizontal"
                />
                <Spacer size="16" direction="horizontal" />
              </Grid>
            )}
            <Grid
              className="lossHelper"
              item
              xs={12}
              md={12}
              lg={12}
              xl={resumido ? 12 : 4}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type={downSm ? 'text_small' : 'text_medium'}
                  color="grayscale"
                  colorWeight="700"
                >
                  Perda estimada de geração
                </Typography>
              </div>
              <Spacer size="16" direction="horizontal" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent:
                    upXl || downSm ? 'space-between' : 'flex-start',
                  alignItems: 'center',
                }}
              >
                {editing ? (
                  <Input
                    label="Perda estimada"
                    loading={loading}
                    type="number"
                    onBlur={(e) => handleChangePosition(e.target.value)}
                    value={generationLoss}
                    setValue={(value) => {
                      if (value.length <= 1) {
                        setGenerationLoss(value)
                      } else if (value < 0) {
                        setGenerationLoss(0)
                      } else if (value > 12) {
                        setGenerationLoss(12)
                      } else {
                        setGenerationLoss(value)
                      }
                    }}
                    fullWidth
                    adornment="%"
                    adornmentPosition="right"
                    size="small"
                    minDate={0}
                    maxDate={12}
                  />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography
                      color="grayscale"
                      colorWeight="700"
                      type={
                        downSm ? 'display_x_small_bold' : 'display_small_bold'
                      }
                    >
                      {proposal.perdaEstimadaGeracao
                        ? proposal.perdaEstimadaGeracao
                        : 0}
                    </Typography>
                    <Spacer size="4" direction="vertical" />
                    <Typography
                      color="grayscale"
                      colorWeight="600"
                      type="text_x_small"
                    >
                      %
                    </Typography>
                  </div>
                )}
                {!editing && (
                  <>
                    {showButtons == false ? (
                      ''
                    ) : (
                      <>
                        {downSm || !upXl ? (
                          <>
                            {!editing && (
                              <EditIcon
                                width="24"
                                onClick={() =>
                                  editing ? setEditing(false) : setEditing(true)
                                }
                                color={palette.grayscale[800]}
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '24px',
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <Button
                            noMargin
                            size="medium"
                            style={{ marginLeft: '24px' }}
                            onClick={() =>
                              editing ? setEditing(false) : setEditing(true)
                            }
                          >
                            {editing ? 'Confirmar' : 'Alterar'}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </StyleRoot>
  )
}

export default AzimuteCard
