import { X } from 'lucide-react'
import { StyledButton } from './styles'

export type IButtonCloseProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export function ButtonClose({ onClick, ...props }: IButtonCloseProps) {
  return (
    <StyledButton
      data-testid="button-close"
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
    >
      <X size={22} />
    </StyledButton>
  )
}
