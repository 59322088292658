import { Button } from '@77sol-ui/atoms'
import { Loader2 } from 'lucide-react'
import { useRevertKitChanges } from '../../../../hooks/useRevertKitChanges'

export function RevertKitButton() {
  const { isDisabledRevertButton, onRevertKit, isLoading } =
    useRevertKitChanges()

  return (
    <Button
      variant="outline"
      disabled={isDisabledRevertButton}
      onClick={onRevertKit}
    >
      {isLoading ? (
        <Loader2 className="atm-animate-spinSlow atm-h-4 atm-w-4" />
      ) : (
        'Reverter'
      )}
    </Button>
  )
}
