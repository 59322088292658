import { palette } from '@77sol/styles'
import styled from 'styled-components'

interface IResponsiveCardProps {
  isActive: boolean
  orderIsSelected: any
  isReserved: boolean
}
const COLUMN_HEADER_SPACING = '0.3fr 200px 0.6fr'
const COLUMN_BODY_SPACING = '0.3fr 140px 0.7fr 230px 0.5fr 0.3fr 1fr'

type IHeaderListProps = Pick<IResponsiveCardProps, 'orderIsSelected'>
type IOrdersListContainerProps = Pick<
  IResponsiveCardProps,
  'orderIsSelected'
> & {
  isOpenFilterSlide: boolean
}

const getRowColor = (isActive: boolean, isReserved: boolean) => {
  if (isActive) return palette.primary[300]

  if (isReserved) return palette.primary[50]

  return 'white'
}

export const HeaderList = styled.div<IHeaderListProps>`
  display: grid;
  margin-bottom: 12px;
  padding: 0px 16px;
  font-size: 17px;

  grid-template-columns: ${({ orderIsSelected }) =>
    orderIsSelected ? COLUMN_HEADER_SPACING : COLUMN_BODY_SPACING};
  gap: 10px;
`
export const OrdersListContainer = styled.div<IOrdersListContainerProps>`
  width: 100%;
  padding: 24px;
  max-height: 85vh;
  overflow-y: auto;

  .icon {
    color: ${palette.grayscale[800]};
  }
  .iconDisabled {
    color: ${palette.grayscale[400]};
  }

  @media screen and (max-width: 1280px) {
    display: ${({ orderIsSelected, isOpenFilterSlide }) =>
      (orderIsSelected || isOpenFilterSlide) && 'none'};
  }
`

export const ResponsiveCardOrder = styled.button<IResponsiveCardProps>`
  border-radius: 8px;
  padding: 0px 16px;
  margin-bottom: 8px;
  display: grid;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 64px;
  font-size: 17px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
  transition: 0.2s;
  border: none;
  text-align: left;
  background-color: ${({ isActive, isReserved }) =>
    getRowColor(isActive, isReserved)};

  color: ${({ isActive }) => isActive && palette.white};

  grid-template-columns: ${({ orderIsSelected }) =>
    orderIsSelected ? COLUMN_HEADER_SPACING : COLUMN_BODY_SPACING};

  gap: 10px;

  & .arrowRightIcon {
    margin-left: auto;
  }

  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? 'none' : palette.primary[50]};
  }

  .billing-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
    row-gap: 8px;
    height: unset;
    padding: 16px;
    position: relative;

    & .billing-name {
      order: 3;
      max-width: none;
    }

    & .badge {
      order: 4;
    }

    & .date {
      order: 2;
    }

    & .date,
    .orderId {
      font-size: 13px;
    }
  }
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  gap: 6px;

  @media screen and (max-width: 1280px) {
    position: absolute;
    bottom: 16px;
    right: 16px;
    padding-right: 0px;
    & svg {
      width: 18px;
    }
  }

  @media (max-width: 768px) {
    bottom: 14px;
    right: 10px;
  }

  @media (max-width: 425px) {
    gap: 0;
  }
`

export const Loading = styled.div`
  padding: 10px;
  text-align: center;

  ::before {
    content: 'Carregando...';
    font-size: 13px;
    color: ${palette.grayscale[500]};
  }
`
