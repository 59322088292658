export const CONVERT_BILLET = [
  'billet_one',
  'billet_two',
  'billet_three',
  'billet_four',
]

/**
 * Sum billet values
 * @param {*} values
 * @returns
 */
export const sumValues = (values = {}) => {
  return Math.round(
    Object.entries(values).reduce((acc = 0, [key, value]) => {
      return CONVERT_BILLET.includes(key) ? acc + parseFloat(value || 0) : acc
    }, 0),
  )
}

/**
 * Separate billet for values
 * @param {*} billet
 * @param {*} total
 * @param {*} quantity
 * @returns
 */
export const separeBillets = (billet = 0, total = 0, quantity = 0) => {
  const first = parseFloat(total)
  const next = parseFloat((100 - total) / (quantity - 1))
  if (billet === 1) {
    return parseFloat(quantity === 1 ? 100 : first).toFixed(2)
  }
  if (billet <= quantity) {
    return parseFloat(next).toFixed(2)
  }
  return null
}

/**
 * Count billets quantity
 * @param {*} values
 * @returns
 */
export const countBillets = (values = {}) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    return Boolean(value) && CONVERT_BILLET.includes(key) ? acc + 1 : acc
  }, 0)
}
