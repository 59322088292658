import { Form } from './styles'
import { useCreateFinancingPerQuotationFormContext } from './hooks/useCreateFinancingPerQuotationForm'
import { type ICreateFinancingPerQuotationFormProps } from './types'
import { FormPart } from './components/FormPart'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export function FormManager({
  formId,
  onSubmit,
  choosedQuotation,
}: ICreateFinancingPerQuotationFormProps) {
  const { handleSubmit, getValues } =
    useCreateFinancingPerQuotationFormContext()

  const choosedPersonType = getValues('client_type')

  return (
    <Form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data, choosedQuotation.id)
      })}
    >
      <FormPart.Commom />

      {choosedPersonType === PERSON_TYPE_ENUM.NATURAL && (
        <FormPart.NaturalPerson />
      )}
      {choosedPersonType === PERSON_TYPE_ENUM.JURIDICAL && (
        <FormPart.JuridicalPerson />
      )}

      <FormPart.Payment choosedQuotation={choosedQuotation} />
    </Form>
  )
}
