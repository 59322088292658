import React, { useState } from 'react'
import { Modal, Fade, Backdrop, useMediaQuery, Paper } from '@material-ui/core'
import { Button, Typography, Spacer, useToast } from '@77sol/core'
import { CircleActionsCloseIcon } from '@77sol/icons/dist'
import API from 'api'
import palette from 'app_palette'
import { useStyles } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useQueryClient } from '@tanstack/react-query'
import { GET_ORDERS } from 'domains/loja/loja-pedidos/constants/endpointKeys'
import { GET_SIGNALS } from 'domains/user/signal/constants/endpointKeys'

export function ModalCancelOrder({
  modalCancelOrder,
  setModalCancelOrder,
  handleConfirmOrderCancel = () => {},
}) {
  const downSm = useMediaQuery((media) => media.breakpoints.down('sm'))
  const classes = useStyles()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { logEvent } = useAmplitude()
  const queryClient = useQueryClient()
  const [loadingCancel, setLoadingCancel] = useState(false)

  const handleCancelOrder = () => {
    setLoadingCancel(true)

    logEvent(ordersTracker.actions.cancelOrder, {
      origin: 'modal de cancelar pedido',
    })

    API.post(`/loja/cancel-order/${modalCancelOrder}`, {
      order_id: modalCancelOrder,
    })
      .then(async () => {
        await queryClient.invalidateQueries([GET_ORDERS, GET_SIGNALS])
        onSuccessOpenToast('Pedido cancelado com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível cancelar o pedido!')
      })
      .finally(() => {
        setModalCancelOrder(false)
        setLoadingCancel(false)
        handleConfirmOrderCancel()
      })
  }

  const handleCloseModal = () => {
    setModalCancelOrder(false)
    logEvent(ordersTracker.actions.gaveUpCancellation, {
      origin: 'modal de cancelar pedido',
    })
  }

  return (
    <Modal
      id="cancel-order-modal"
      className={classes.modalSm}
      open={modalCancelOrder}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalCancelOrder} style={{ border: 'none' }}>
        <Paper
          style={{
            height: 'auto',
            width: 'auto',
            padding: downSm ? '24px' : '24px 72px 24px 24px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircleActionsCloseIcon
              width="40"
              color={palette.primary[300]}
              style={{ marginRight: '16px' }}
            />
            <Typography size="medium" color="primary" colorWeight="400">
              Deseja realmente cancelar este pedido?
            </Typography>
          </div>
          <Spacer size="24" direction="horizontal" />
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button size="small" variant="outlined" onClick={handleCloseModal}>
              Voltar
            </Button>
            <Spacer size="8" direction="both" />
            <Button
              color="red"
              size="small"
              onClick={() => handleCancelOrder()}
              isLoading={loadingCancel}
            >
              Cancelar pedido
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
