import {
  Select,
  type ISelectProps,
} from 'components/forms/react-hook-form/Select'
import { type FieldValues } from 'react-hook-form'

const GRACE_DAYS_OPTIONS = [
  { label: '30 dias', value: '30' },
  { label: '60 dias', value: '60' },
  { label: '90 dias', value: '90' },
  { label: '120 dias', value: '120' },
]

export const SelectGraceDays = <T extends FieldValues>({
  ...props
}: Omit<ISelectProps<T>, 'options'>) => {
  return <Select {...props} options={GRACE_DAYS_OPTIONS} />
}
