import { ChevronUp } from 'lucide-react'
import * as S from './styles'
import { useState, type HTMLAttributes } from 'react'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { TAB_ENUM } from 'containers/EquipmentQuotationContainer/enums/tabEnum'

interface FooterProps extends HTMLAttributes<HTMLDivElement> {}

export function Footer({ children, ...rest }: FooterProps) {
  const [mobileOpen, setMobileOpen] = useState(true)
  const selectedKits = useEquipmentContainerStore((state) => state.selectedKits)
  const currentTab = useEquipmentContainerStore((state) => state.currentTab)

  const canShowFooterInChooseKitPage =
    selectedKits?.length > 0 && currentTab === TAB_ENUM.CHOOSE_KIT

  const canShowFooter =
    currentTab !== TAB_ENUM.CHOOSE_KIT || canShowFooterInChooseKitPage

  return (
    <S.Wrapper
      data-open={mobileOpen}
      id="footer-wrapper"
      canShowFooter={canShowFooter}
    >
      <div
        className="arrow"
        onClick={() => {
          setMobileOpen(!mobileOpen)
        }}
      >
        <ChevronUp size={22} data-open={mobileOpen} />
      </div>
      <div className="content" {...rest} data-open={mobileOpen}>
        {children}
      </div>
    </S.Wrapper>
  )
}
