import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, Tooltip } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import { Typography } from 'components'
import Backdrop from '@material-ui/core/Backdrop'
import { Spacer, Paper, Button } from '@77sol/core'
import palette from 'app_palette'
import { CircleActionsAlertInfoIcon } from '@77sol/icons/dist'
import { handlePotency } from '../../../../../../utils'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function ModalSizingAlert(props) {
  const classes = useStyles()
  /* eslint eqeqeq: 0 */

  const {
    showAlertDiferency,
    setShowAlertDiferency,
    alertQuotation,
    proposal,
    loadingUpdateKitPotency,
    loadingChangePotency,
    setHideAlert,
    handleUpdateKitPotency,
    handleChangePotency,
  } = props
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  return (
    <Modal
      className={classes.modalSm}
      open={showAlertDiferency}
      onClose={() => {
        setShowAlertDiferency(false)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={showAlertDiferency} style={{ border: 'none' }}>
        <Paper
          boxShadow="small"
          style={{ height: 'auto', width: downSm ? '90%' : '50%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Potência desatualizada">
                <>
                  <CircleActionsAlertInfoIcon
                    width="24"
                    height="24"
                    color={palette.orange[300]}
                    style={{ marginRight: '8px' }}
                  />
                </>
              </Tooltip>
            </div>
            <Typography type="link_small" color="orange" colorWeight="300">
              Os equipamentos selecionados estão fora do dimensionamento
            </Typography>
          </div>
          <Spacer size="32" direction="horizontal" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography type="text_medium" color="grayscale" colorWeight="700">
              Potência do kit
            </Typography>
            <Typography type="link_large" color="grayscale" colorWeight="700">
              {handlePotency(
                alertQuotation?.quotation?.added_items
                  ? filterAddedItems(
                      alertQuotation?.kit,
                      alertQuotation?.quotation?.added_items,
                    )
                  : alertQuotation?.kit,
              )}
              kWp
            </Typography>
          </div>
          <Spacer size="16" direction="horizontal" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography type="text_medium" color="grayscale" colorWeight="700">
              Potência do dimensionamento
            </Typography>
            <Typography type="link_large" color="grayscale" colorWeight="700">
              {(
                proposal?.sizings?.find(
                  (item) => alertQuotation?.dimensioning?.id == item.id,
                )?.potenciaCC / 1000
              ).toFixed(2)}
              kWp
            </Typography>
          </div>
          <Spacer size="16" direction="horizontal" />
          <div>
            <Button
              id="modalSizingAlert_button_updateEquipment"
              onClick={() =>
                handleUpdateKitPotency(
                  parseFloat(
                    proposal?.sizings?.find(
                      (item) => alertQuotation?.dimensioning?.id == item.id,
                    )?.potenciaCC / 1000,
                  ).toFixed(2),
                  alertQuotation?.quotation?.id,
                )
              }
              isLoading={loadingUpdateKitPotency}
              variant="outlined"
              noMargin
              size="small"
              fullWidth
              style={{ justifyContent: 'center' }}>
              Atualizar equipamentos
            </Button>
            <Spacer size="8" direction="horizontal" />
            <Button
              id="modalSizingAlert_button_updateSizing"
              onClick={() =>
                handleChangePotency(
                  handlePotency(
                    alertQuotation?.quotation?.added_items
                      ? filterAddedItems(
                          alertQuotation?.kit,
                          alertQuotation?.quotation?.added_items,
                        )
                      : alertQuotation?.kit,
                  ),
                )
              }
              isLoading={loadingChangePotency}
              variant="outlined"
              noMargin
              size="small"
              fullWidth
              style={{ justifyContent: 'center' }}>
              Atualizar dimensionamento
            </Button>
            <Spacer size="8" direction="horizontal" />
            <Button
              id="modalSizingAlert_button_cancel"
              variant="text"
              noMargin
              size="small"
              fullWidth
              onClick={() => {
                setHideAlert(true)
                setShowAlertDiferency(false)
              }}
              style={{ justifyContent: 'center' }}>
              Cancelar
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default ModalSizingAlert
