import { Typography } from '@77sol/core'
import { palette } from '@77sol/styles'
import { Grid } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'

export function Equipments({ budgetEdit, i }) {
  const proposal = useSelector((state) => state.Reducer1)

  return (
    <>
      <div
        style={{
          width: '100%',
          marginTop: '12px',
          border: '1px solid #DFE3E8',
        }}>
        <Typography
          style={{
            fontSize: '10px',
            backgroundColor: palette.grayscale[300],
            color: palette.grayscale[700],
            padding: '4px 10px',
          }}>
          Incluso no projeto
        </Typography>
        <div>
          <Typography
            style={{
              fontSize: '8px',
              color: palette.grayscale[500],
              padding: '4px 8px',
              marginTop: '4px',
            }}>
            Equipamentos
          </Typography>
        </div>
        <Grid container>
          {proposal.quotes[i].kit.map((product, key) => (
            <Grid
              key={key}
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px 10px',
                borderBottom: '1px solid #e2e6e7',
                margin: '0px 10px',
              }}>
              <div style={{ display: 'flex' }}>
                <img
                  alt="Product"
                  style={{ width: '32px', height: '32px' }}
                  src={product.url}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '8px',
                      paddingLeft: '12px',
                      color: palette.grayscale[700],
                      lineHeight: 'normal',
                    }}>
                    {product.titulo}
                  </Typography>
                  {product.garantia && (
                    <Typography
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '8px',
                        paddingLeft: '12px',
                        color: palette.green[300],
                        lineHeight: 'normal',
                      }}>
                      {product.garantia} anos de garantia
                    </Typography>
                  )}
                </div>
              </div>
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '8px',
                  minWidth: 'fit-content',
                  color: palette.grayscale[700],
                }}>
                {product.qtde} un
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
      <div
        style={{
          width: '100%',
          border: '1px solid #DFE3E8',
          marginTop: '12px',
        }}>
        <Typography
          style={{
            fontSize: '10px',
            backgroundColor: palette.grayscale[300],
            color: palette.grayscale[700],
            padding: '4px 10px',
          }}>
          Incluso no projeto
        </Typography>
        <table style={{ borderSpacing: '0px', width: '100%' }}>
          <tr>
            {budgetEdit
              ?.filter((bud) => bud.cost_front_slug.indexOf('margem') === -1)
              ?.slice(0, 4)
              .map((item, key) => (
                <td
                  key={key}
                  style={{
                    border: '1px solid #DFE3E8',
                    padding: '4px 10px',
                  }}>
                  <Typography
                    style={{
                      color: palette.grayscale[700],
                      fontSize: '8px',
                      lineHeight: '12px',
                    }}>
                    {item.cost_front}
                  </Typography>
                </td>
              ))}
          </tr>
          {budgetEdit?.filter(
            (bud) => bud.cost_front_slug.indexOf('margem') === -1,
          )?.length > 4 && (
            <tr>
              {budgetEdit
                ?.filter((bud) => bud.cost_front_slug.indexOf('margem') === -1)
                ?.slice(4, 8)
                .map((item, key) => (
                  <td
                    key={key}
                    style={{
                      border: '1px solid #DFE3E8',
                      padding: '4px 10px',
                    }}>
                    <Typography
                      style={{
                        color: palette.grayscale[700],
                        fontSize: '8px',
                        lineHeight: '12px',
                      }}>
                      {item.cost_front}
                    </Typography>
                  </td>
                ))}
            </tr>
          )}
          {budgetEdit?.filter(
            (bud) => bud.cost_front_slug.indexOf('margem') === -1,
          )?.length > 8 && (
            <tr>
              {budgetEdit
                ?.filter((bud) => bud.cost_front_slug.indexOf('margem') === -1)
                ?.slice(8, 12)
                .map((item, key) => (
                  <td
                    key={key}
                    style={{
                      border: '1px solid #DFE3E8',
                      padding: '4px 10px',
                    }}>
                    <Typography
                      style={{
                        color: palette.grayscale[700],
                        fontSize: '8px',
                        lineHeight: '12px',
                      }}>
                      {item.cost_front}
                    </Typography>
                  </td>
                ))}
            </tr>
          )}
          {budgetEdit?.filter(
            (bud) => bud.cost_front_slug.indexOf('margem') === -1,
          )?.length > 12 && (
            <tr>
              {budgetEdit
                ?.filter((bud) => bud.cost_front_slug.indexOf('margem') === -1)
                ?.slice(12, 16)
                .map((item, key) => (
                  <td
                    key={key}
                    style={{
                      border: '1px solid #DFE3E8',
                      padding: '4px 10px',
                    }}>
                    <Typography
                      style={{
                        color: palette.grayscale[700],
                        fontSize: '8px',
                        lineHeight: '12px',
                      }}>
                      {item.cost_front}
                    </Typography>
                  </td>
                ))}
            </tr>
          )}
          {budgetEdit?.filter(
            (bud) => bud.cost_front_slug.indexOf('margem') === -1,
          )?.length > 16 && (
            <tr>
              {budgetEdit
                ?.filter((bud) => bud.cost_front_slug.indexOf('margem') === -1)
                ?.slice(16, 20)
                .map((item, key) => (
                  <td
                    key={key}
                    style={{
                      border: '1px solid #DFE3E8',
                      padding: '4px 10px',
                    }}>
                    <Typography
                      style={{
                        color: palette.grayscale[700],
                        fontSize: '8px',
                        lineHeight: '12px',
                      }}>
                      {item.cost_front}
                    </Typography>
                  </td>
                ))}
            </tr>
          )}
        </table>
      </div>
    </>
  )
}
