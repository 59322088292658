import { type ComponentPropsWithRef } from 'react'
import { StyledTitle } from './styles'

export interface ITitleProps extends ComponentPropsWithRef<'h1'> {
  text: string
}

export function Title({ text, ...props }: ITitleProps) {
  return <StyledTitle {...props}>{text}</StyledTitle>
}
