import { format, isBefore, isToday, startOfDay } from 'date-fns'
import { Clock, Zap } from 'lucide-react'
import { type IFlagProps } from '../types'
import * as S from '../styles'
import { type IFlagVariant } from 'components/SmartCard/Flag/types'
import { DELIVERED_BY } from './constants'

interface IAvailableFlagProps extends IFlagProps {
  availableAt: Date | string
  deliveredBy: string
}

export function AvailableFlag({
  availableAt,
  borderRadius,
  deliveredBy,
  ...props
}: IAvailableFlagProps) {
  const today = startOfDay(new Date())
  const availableDate = startOfDay(new Date(availableAt))
  const isAvailable = isToday(availableDate) || isBefore(availableDate, today)

  const textInStock =
    deliveredBy === DELIVERED_BY.VANDEMMIA ? 'ENVIO IMEDIATO' : 'EM ESTOQUE'

  const formattedDate = format(availableDate, 'dd/MM')

  const defaultFlag = {
    text: isAvailable ? textInStock : `DISP EM ${formattedDate}`,
    Icon: isAvailable ? Zap : Clock,
    variant: isAvailable ? 'blue' : 'orange',
  }

  return (
    <S.Flag
      {...props}
      {...defaultFlag}
      borderRadius={borderRadius ?? '0px 8px 0px 8px'}
      variant={defaultFlag.variant as IFlagVariant}
    />
  )
}
