import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  container: ({ minHeight }) => ({
    minHeight,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  linearRoot: {
    width: '100%',
  },
  colorPrimary: {
    backgroundColor: palette.primary[100],
  },
  barColorPrimary: {
    backgroundColor: palette.primary[300],
  },
  icon: {
    color: palette.grayscale[700],
    width: '60px',
    height: '60px',
  },
}))

export default styles
