import { useSelector, useDispatch } from 'react-redux'
import { setProModalOpen } from 'store/actions/proModal'

export const usePlan = () => {
  const { isProPlan } = useSelector((state) => state.ReducerProfile)
  const dispatch = useDispatch()

  const isPro = () => {
    if (!isProPlan) {
      dispatch(setProModalOpen())
      return false
    }
    return true
  }

  return { isPro }
}
