import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import styles from './styles'

function Toggle({
  checked, setChecked, color, lightColor, ...props
}) {
  const classes = styles({ color, lightColor })
  const ToggleClass = classNames(classes.toggle)

  return (
    <Switch
      className={ToggleClass}
      checked={checked}
      onChange={(e) => setChecked(e.target.value)}
      {...props}
    />
  )
}

Toggle.propTypes = {
  color: PropTypes.string,
  lightColor: PropTypes.bool,
}

Toggle.defaultProps = {
  color: 'primary',
  lightColor: false,
}

export default Toggle
