import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  toggle: ({ color, lightColor }) => ({
    '&.MuiSwitch-root': {
      width: '48px !important',
      height: '24px !important',
      padding: '0px !important',
      borderRadius: '40px !important',
      display: 'flex',
    },
    '& .MuiSwitch-switchBase': {
      padding: '2px !important',
      backgroundColor: palette.grayscale[50],
      top: '2px',
      left: '2px',
      '&.Mui-checked': {
        transform: 'translateX(24px)',
      },
    },
    '& .MuiSwitch-thumb': {
      width: '16px !important',
      height: '16px !important',
      boxShadow: 'none',
      opacity: '1 !important',
      '&$checked': {
        color: palette.grayscale[100],
        backgroundColor: palette.grayscale[100],
        opacity: 1,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: palette.grayscale[lightColor ? 200 : 300],
      '&:hover': {
        color: palette.grayscale[400],
        backgroundColor: `${palette.grayscale[400]} !important`,
      },
    },
    '& .MuiIconButton-label': {
      color: palette.grayscale[50],
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      color: palette[color][300],
      backgroundColor: `${palette[color][lightColor ? 200 : 300]} !important`,
      '&:hover': {
        color: palette.primary[400],
        backgroundColor: `${palette[color][400]} !important`,
      },
    },
    '& .MuiDisabled': {
      opacity: 1,
    },
    '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.4,
      backgroundColor: palette.grayscale[lightColor ? 200 : 300],
    },
  }),
}))

export default styles
