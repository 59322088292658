import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  backdrop: {
    zIndex: 1000,
    color: '#fff',
    flexDirection: 'column',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  context: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 32,

    '& svg': {
      marginTop: 32,
      marginBottom: 24,
    },

    '& h4': {
      marginBottom: 10,
    },
  },
  actions: {
    marginBottom: 32,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    flexDirection: 'column',

    '@media (min-width:1280px)': {
      flexDirection: 'row',
    },
  },
})
