import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { SaveNewSizingRedux } from 'store/actions'
import { useAmplitude } from 'hooks/useAmplitude'
import { clientsTracker } from 'services/tracker/events/clients/tracker'

export function useClientsSelect({
  asModal,
  getClients,
  createClient,
  newClient,
  setNewClient,
  selectedClient,
  setChangingClient,
  setSelectedClient,
  setActiveDimStep,
  setMoreDataDisplayed,
  handleChangeProposalCustomer,
  handleCreateProposal,
  handleSetEditValues,
  handleClickClient,
}) {
  const dispatch = useDispatch()
  const proposal = useSelector((state) => state.Reducer1)

  const origin = asModal ? 'Cotação rápida' : 'Dimensionar e cotar'
  const formType = newClient ? 'Adicionar cliente' : 'Editar cliente'

  const { logEvent } = useAmplitude()

  function handleSearchClient() {
    logEvent(clientsTracker.actions.searchCustomer, {
      origin,
    })

    getClients()
  }

  function handleNewClient() {
    logEvent(clientsTracker.actions.newCustomer, {
      origin,
    })

    setNewClient(true)
  }

  function handleSelectClient(client) {
    const customerType = client.status_solleads ? 'SolLeads' : 'Comum'

    logEvent(clientsTracker.actions.selectCustomer, {
      origin,
      customerType,
    })

    if (proposal?.proposal?.customer_id) {
      handleChangeProposalCustomer(client)
    } else {
      handleSetEditValues(client)
    }
    handleClickClient?.()
  }

  function handleCancelClient() {
    logEvent(clientsTracker.actions.customerFormCancel, {
      origin,
      formType,
    })

    if (proposal.newSizing) {
      dispatch(SaveNewSizingRedux(false))
      setActiveDimStep(3)
    } else if (newClient) {
      setSelectedClient(null)
      setNewClient(false)
      getClients()
    } else {
      handleSetEditValues(null)
      setChangingClient(true)
    }
  }

  function handleSaveClient() {
    logEvent(clientsTracker.actions.customerFormSave, {
      origin,
    })

    createClient()
    handleClickClient?.()
  }

  function handleContinueProposal() {
    logEvent(clientsTracker.actions.customerFormNext, {
      origin,
    })

    handleCreateProposal(selectedClient.id)
  }

  function handleDisplayMoreData(display) {
    const eventKey = display
      ? clientsTracker.actions.customerFormMoreData
      : clientsTracker.actions.customerFormLessData

    logEvent(eventKey, {
      origin,
      formType,
    })

    setMoreDataDisplayed(display)
  }

  return {
    handleSearchClient,
    handleNewClient,
    handleSaveClient,
    handleSelectClient,
    handleCancelClient,
    handleDisplayMoreData,
    handleContinueProposal,
  }
}
