import React from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { Modal, Button, Spacer, Title, Typography } from '@77sol/core'
import { CircleActionsAlertInfoIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles';

const RemakeOrderModal = ({ isOpen, onClose, onConfirm }) => {
  const downXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box>
        <Box display="flex" alignItems="center" flexDirection={downXs ? 'column' : 'row'}>
          <CircleActionsAlertInfoIcon
            color={palette.primary[300]}
            width={downXs ? "30px" : "20px"}
          />
          <Spacer
            size="8"
            direction={downXs ? "horizontal" : "vertical"}
          />
          <Title
            size="xsmall"
            color="primary"
            colorWeight="300"
            style={{ textAlign: downXs && 'center' }}
          >
            Pedidos vencem em 2 dias úteis.
          </Title>
        </Box>
        <Spacer size="12" direction="horizontal" />
        <Typography
          size="small"
          style={{
            maxWidth: !downXs ? '650px' : 'auto',
            textAlign: downXs && 'center'
          }}
        >
          Você está prestes a gerar um novo pedido baseado na cotação deste.
          Este pedido será movido para o status cancelado. Deseja continuar?
        </Typography>
        <Spacer size="18" direction="horizontal" />
        <Box
          display="flex"
          flexDirection={downXs ? 'column' : 'row'}
          justifyContent={downXs ? 'center' : 'end'}
        >
          {downXs ? (
            <Button size="small" color="primary" variant="contained" onClick={onConfirm}>
              Confirmar
            </Button>
          ) : (
            <Button size="small" color="red" variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
          )}
          <Spacer size="12" direction={downXs ? 'horizontal' : 'vertical'} />
          {downXs ? (
            <Button size="small" color="red" variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
          ) : (
            <Button size="small" color="primary" variant="contained" onClick={onConfirm}>
              Confirmar
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default RemakeOrderModal
