import * as S from './styles'
import { Button } from '@77sol/core'
import { useSendWhatsAppForm } from './hooks/useSendWhatsAppForm'
import { type ISendWhatsAppForm } from './validators'
import { type IProposalCustomer } from 'dto/ProposalDTO'
import { TextField } from 'components/Controllers/TextField'

interface ISendWhatsAppFormProps {
  customer: IProposalCustomer
  onCancel: () => void
  onSubmit: (data: ISendWhatsAppForm) => void
}

export function SendWhatsAppForm({
  customer,
  onSubmit,
  onCancel,
}: ISendWhatsAppFormProps) {
  const {
    control,
    formState,
    handleSendWhatsAppSubmit,
    handleSendWhatsAppCancel,
  } = useSendWhatsAppForm({
    customer,
    onSubmit,
    onCancel,
  })

  return (
    <>
      <S.Label>Cliente</S.Label>
      <S.Client>{customer?.nome}</S.Client>
      <form onSubmit={handleSendWhatsAppSubmit}>
        <S.Field>
          <S.Label>Número</S.Label>
          <TextField
            name="celular"
            mask="phoneNumber"
            control={control}
            placeholder="(99) 99999-9999"
            error={formState.errors.celular?.message}
          />
        </S.Field>
        <S.Buttons>
          <Button
            size="small"
            variant="outlined"
            onClick={handleSendWhatsAppCancel}
            disabled={formState.isSubmitting}
          >
            Voltar
          </Button>
          <Button size="small" type="submit">
            Enviar
          </Button>
        </S.Buttons>
      </form>
    </>
  )
}
