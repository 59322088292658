import { ORDER_STATUS_CLIENT_ENUM } from 'enums/order/OderStatusClientEnum'
import { useCallback, useLayoutEffect, useRef } from 'react'

export const useFocusInput = (orderStatus: number) => {
  const formRef = useRef<HTMLFormElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const birthInputRef = useRef<HTMLInputElement>(null)
  const addressPaymentRef = useRef<HTMLInputElement>(null)
  const nfOriginRef = useRef<HTMLInputElement>(null)
  const nfValueRef = useRef<HTMLInputElement>(null)
  const paymentTypeRef = useRef<HTMLInputElement>(null)
  const billingLetterRef = useRef<HTMLInputElement>(null)
  const titleRef = useRef<HTMLElement>(null)

  const focusEmptyInput = useCallback(
    (
      ref: React.RefObject<HTMLInputElement>,
      touchpoint: React.RefObject<HTMLInputElement | HTMLElement>,
    ) => {
      if (ref?.current && !ref.current.value) {
        ref.current.focus()
        touchpoint.current?.scrollIntoView({
          behavior: 'smooth',
        })
        return true
      }
      return false
    },
    [],
  )

  useLayoutEffect(() => {
    if (orderStatus != ORDER_STATUS_CLIENT_ENUM.RESERVADO) return
    const inputRefs = [
      emailInputRef,
      phoneInputRef,
      birthInputRef,
      addressPaymentRef,
      nfOriginRef,
      nfValueRef,
      paymentTypeRef,
      billingLetterRef,
    ]
    let shouldContinue = true
    const handleFocus = () => (shouldContinue = false)

    const timeout = setTimeout(() => {
      inputRefs.forEach((ref) =>
        ref.current?.addEventListener('focus', handleFocus),
      )

      const touchpoints = [titleRef, addressPaymentRef]

      inputRefs.some(
        (ref, index) =>
          shouldContinue &&
          focusEmptyInput(ref, index <= 3 ? touchpoints[0] : touchpoints[1]),
      )
    }, 500)

    return () => {
      inputRefs.forEach((ref) =>
        ref.current?.removeEventListener('focus', handleFocus),
      )
      clearTimeout(timeout)
    }
  }, [
    emailInputRef,
    phoneInputRef,
    birthInputRef,
    addressPaymentRef,
    nfOriginRef,
    nfValueRef,
    paymentTypeRef,
    billingLetterRef,
    focusEmptyInput,
    orderStatus,
  ])

  return {
    formRef,
    emailInputRef,
    phoneInputRef,
    birthInputRef,
    addressPaymentRef,
    nfOriginRef,
    nfValueRef,
    paymentTypeRef,
    billingLetterRef,
    titleRef,
  }
}
