import { forwardRef } from 'react'
import { type IRootProps } from './types'
import { Wrapper } from './styles'

const Root = forwardRef<HTMLButtonElement, IRootProps>(
  ({ isSelected, children, ...props }, ref) => {
    return (
      <Wrapper
        isSelected={isSelected}
        data-testid="card-component"
        {...props}
        ref={ref}
      >
        {children}
      </Wrapper>
    )
  },
)

export { Root, type IRootProps }
