import { Button } from '@77sol-ui/atoms'
import { Pencil } from 'lucide-react'
import { ProductBadge } from 'containers/ProductBadge'
import * as S from './styles'
import { ModalDescricao } from 'views/DimensionarV2/components/StepTwo/components'
import { useEquipmentCard } from './hooks/useEquipmentCard'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { ChangeEquipmentModal } from './components/ChangeEquipmentModal'
import { XCircle } from 'lucide-react'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { EquipmentMenu } from './components/EquipmentMenu'
import { ProductRemoveModal } from 'containers/Modals/ProductRemoveModal'
import { useProductName } from 'hooks/useProductName'
import { InputQuantity } from 'components/InputQuantity'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'
import { useDisableChangeButton } from './hooks/useHideChangeButton'

interface EquipmentCardProps {
  product: IQuotationProductDTO
}

export function EquipmentCard({ product }: EquipmentCardProps) {
  const { logEvent } = useAmplitude()
  const { quotationSelected } = useEquipmentContainerStore()
  const { disableChangeButton } = useDisableChangeButton(product.categoria)
  const {
    productToShowDescription,
    productToDelete,
    setProductToShowDescription,
    handleOpenRemoveProduct,
    handleCloseRemoveProduct,
    handleChangeProductAmount,
    handleUpdateProductAmountOnBlur,
    productToChange,
    setProductToChange,
    handleSuccessDeleteProduct,
    onIncreaseProductAmount,
    onDecreaseProductAmount,
  } = useEquipmentCard()

  const hasError = Boolean(product?.has_error)
  const errorMessage =
    Array.isArray(product?.error_message) && product.error_message.length > 0
      ? product.error_message[0]
      : 'Erro ao alterar o produto. Por favor, tente novamente.'

  const { productName, availabilityMessage } = useProductName({ product })

  function handleOpenChangeEquipmentModal(equipment: IQuotationProductDTO) {
    logEvent(quotationTracker.actions.changeEquipment, {
      origin: '/cotar',
    })
    setProductToChange(equipment)
  }

  const buttonChangeClass = !hasError
    ? 'change-equipment-button'
    : 'provider-error-button'

  return (
    <>
      <S.EquipmentListWrapper hasError={Boolean(hasError)}>
        <S.EquipmentsInfo>
          <img src={product?.url} alt={product?.titulo} />
          <div className="infos-wrapper">
            <div className="badges">
              {product?.garantia && (
                <ProductBadge.Warranty years={product.garantia} />
              )}
              {product?.is_changed && <ProductBadge.Changed />}
              {product?.is_added && <ProductBadge.Added />}
            </div>
            <div className="info">
              <p>{productName}</p>
              {!hasError ? (
                <p className="disponibility">{availabilityMessage}</p>
              ) : (
                <p className="provider-error">
                  <XCircle />
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
        </S.EquipmentsInfo>
        <S.EquipmentsActions hasError={Boolean(hasError)}>
          <div>
            <p>Quantidade</p>
            <div className="actions">
              <InputQuantity
                name="quantity"
                value={product?.qtde}
                onChange={(event) => {
                  handleChangeProductAmount({
                    ...product,
                    qtde: event.target.value as unknown as number,
                  })
                }}
                onBlur={async (event) => {
                  handleUpdateProductAmountOnBlur({
                    ...product,
                    qtde: Number(event.target.value),
                  })
                }}
                onIncrease={() => {
                  onIncreaseProductAmount(product)
                }}
                onDecrease={() => {
                  onDecreaseProductAmount(product)
                }}
                disabledDecrease={product.qtde === 1}
              />
              <Button
                variant="outline"
                disabled={disableChangeButton}
                className={buttonChangeClass}
                onClick={() => {
                  handleOpenChangeEquipmentModal(product)
                }}
              >
                <span>Trocar</span>
                <Pencil size={16} />
              </Button>
              <EquipmentMenu
                productCategory={product.categoria}
                onShowTechnicalData={() => {
                  setProductToShowDescription(product)
                }}
                onDelete={() => {
                  handleOpenRemoveProduct(product)
                }}
              />
            </div>
          </div>
        </S.EquipmentsActions>
      </S.EquipmentListWrapper>

      {Boolean(productToChange) && (
        <ChangeEquipmentModal
          equipment={productToChange as IQuotationProductDTO}
          onClose={() => {
            setProductToChange(null)
          }}
        />
      )}

      {Boolean(productToShowDescription) && (
        <ModalDescricao
          datasheetProduct={productToShowDescription}
          modalDescricao={Boolean(productToShowDescription)}
          setModalDescricao={() => {
            setProductToShowDescription(null)
          }}
        />
      )}

      {productToDelete && (
        <ProductRemoveModal
          product={productToDelete}
          quotationId={quotationSelected?.id}
          onSuccess={handleSuccessDeleteProduct}
          onClose={handleCloseRemoveProduct}
        />
      )}
    </>
  )
}
