import React, { useEffect, useState } from 'react'
import { EditIcon, ImageIcon, InfoIcon } from '@77sol/icons/dist'
import {
  Checkbox,
  Divider,
  Dropdown,
  Input,
  Tooltip,
  Typography,
} from '@77sol/core'
import { Skeleton } from '@material-ui/lab'
import { AccordionPaymentMethod } from 'components'
import { Box, IconButton } from '@material-ui/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { palette } from '@77sol/styles'
import { parseBRL } from 'utils'
import { INITIAL } from './initial'
import { countFinancing, searchFinancing } from './utils'

const {
  optionsEntrie = [],
  optionsShortage = [],
  optionsInstallments = [],
} = currentEnterpriseConfigs.pagamentoEdit

function Financing({
  userMethods = [],
  proposalMethods = [],
  setProposalMethod = () => {},
}) {
  const [financingList, setFinancingList] = useState([])
  const [expandAccordion, setExpandAccordion] = useState(false)
  const [checkedFinancing, setCheckedFinancing] = useState(false)
  const [quantityFinancing, setQuantityFinancing] = useState(0)
  const [valuesFinancing, setValuesFinancing] = useState(INITIAL.VALUES)

  useEffect(() => {
    const { total_value, value_installment } = searchFinancing(
      proposalMethods,
      valuesFinancing,
    )
    if (valuesFinancing.status) {
      setValuesFinancing((state) => ({
        ...state,
        total_value,
        value_installment,
      }))
    }
  }, [proposalMethods])

  useEffect(() => {
    setFinancingList(proposalMethods)
    setQuantityFinancing(countFinancing(proposalMethods))
    setCheckedFinancing(Boolean(countFinancing(proposalMethods)))
  }, [proposalMethods])

  const handleShowEdit = (bank = valuesFinancing) => {
    if (valuesFinancing.name_financier === bank.name_financier) {
      setValuesFinancing(INITIAL.VALUES)
    } else {
      setValuesFinancing(bank)
    }
  }

  const handleUpdateMethod = (bank = valuesFinancing) => {
    const financing = financingList.map((item) => {
      return item.name_financier === bank.name_financier ? bank : item
    })
    setProposalMethod(financing)
    setValuesFinancing(bank.status ? bank : INITIAL.VALUES)
  }

  const handleCheckedMethod = (checked = false) => {
    setValuesFinancing(checked ? valuesFinancing : INITIAL.VALUES)
    setProposalMethod(checked ? userMethods : null)
  }

  return (
    <Box position="relative">
      <Box position="absolute" top={5} right={5}>
        <Tooltip title="Use a calculadora de financiamentos para mostrar para seu cliente uma aproximação de condições de financiamento que poderá encontrar, ajudando na tomada de decisão. Caso o cliente queira um financiamento, será necessário fazer a solicitação e aguardar aprovação. A calculadora de financiamentos não serve como solicitação de financiamento.">
          <div>
            <InfoIcon width={12} />
          </div>
        </Tooltip>
      </Box>
      <AccordionPaymentMethod
        title="Calculadora de financiamentos"
        subtitle={`Simulações em ${quantityFinancing} bancos`}
        description="A calculadora de financiamento não serve como uma solicitação de financiamento."
        checked={checkedFinancing}
        expand={expandAccordion}
        setExpand={setExpandAccordion}
        setChecked={handleCheckedMethod}>
        {financingList?.map((financing, i) => (
          <Box key={i} display="flex" alignItems="center">
            <Checkbox
              size="small"
              checked={financing.status}
              onChange={() =>
                handleUpdateMethod({
                  ...financing,
                  status: financing.status ? 0 : 1,
                })
              }
            />
            <Box display="flex" alignItems="center">
              <Box
                width={30}
                height={30}
                marginX={1}
                display="flex"
                alignItems="center"
                justifyContent="center">
                {financing?.image_financier ? (
                  <Box
                    component="img"
                    margin="auto"
                    maxWidth={20}
                    maxHeight={20}
                    alt={financing?.name_financier}
                    src={financing?.image_financier}
                  />
                ) : (
                  <ImageIcon width={20} color={palette.grayscale[300]} />
                )}
              </Box>
              <Typography
                type="xsmall"
                color="grayscale"
                colorWeight="700"
                style={{
                  whiteSpace: 'nowrap',
                  marginLeft: financing?.image_financier ? '0px' : '8px',
                }}>
                {financing.name_financier}
              </Typography>
            </Box>
            {Boolean(financing.status) && (
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  style={{
                    padding: 10,
                    marginLeft: 6,
                  }}
                  onClick={() => handleShowEdit(financing)}>
                  <EditIcon width={15} color={palette.primary[400]} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ))}
        {Boolean(valuesFinancing.status) && (
          <>
            <Box marginY={2}>
              <Divider />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                marginX={1}
                maxWidth={25}
                maxHeight={25}
                component="img"
                src={valuesFinancing.image_financier}
                alt={valuesFinancing.name_financier}
              />
              <Typography>{valuesFinancing.name_financier}</Typography>
            </Box>
            <Box marginY={2}>
              <Typography type="xsmall" color="primary" colorWeight="300">
                Ao gerar uma proposta, use a calculadora de financiamentos -
                atualizando a taxa se necessário - para mostrar a seus clientes
                uma aproximação do custo de um financiamento, e aumentar suas
                vendas.
              </Typography>
              <Box margin={2}>
                <Typography type="xsmall">Taxa de juros mensal</Typography>
              </Box>
              <Input
                endAdornment="%"
                value={valuesFinancing.monthly_interest_rate}
                onBlur={() => handleUpdateMethod()}
                onChange={(value) =>
                  setValuesFinancing((state) => ({
                    ...state,
                    monthly_interest_rate: value.toString().replace(',', '.'),
                  }))
                }
              />
              <Box margin={2}>
                <Typography type="xsmall">Entrada</Typography>
              </Box>
              {valuesFinancing.entry_type === 'entry_value' ? (
                <Input
                  mask="real"
                  value={String(valuesFinancing.entrie)}
                  onBlur={() => handleUpdateMethod()}
                  onChange={(entrie) =>
                    setValuesFinancing((state) => ({
                      ...state,
                      entrie,
                    }))
                  }
                />
              ) : (
                <Dropdown
                  fullWidth
                  options={optionsEntrie}
                  value={String(valuesFinancing.entrie)}
                  onBlur={() => handleUpdateMethod()}
                  onChange={(entrie) =>
                    setValuesFinancing((state) => ({
                      ...state,
                      entrie,
                    }))
                  }
                />
              )}
              <Box margin={2}>
                <Typography type="xsmall">
                  Carência da primeira parcela
                </Typography>
              </Box>
              <Dropdown
                fullWidth
                options={valuesFinancing.grace_days || optionsShortage}
                value={String(valuesFinancing.waiting_period)}
                onBlur={() => handleUpdateMethod()}
                onChange={(waiting_period) =>
                  setValuesFinancing((state) => ({
                    ...state,
                    waiting_period,
                  }))
                }
              />
              <Box margin={2}>
                <Typography type="xsmall">Quantidade de parcelas</Typography>
              </Box>
              <Dropdown
                fullWidth
                options={valuesFinancing.quotas || optionsInstallments}
                value={String(valuesFinancing.number_installments)}
                onBlur={() => handleUpdateMethod()}
                onChange={(number_installments) =>
                  setValuesFinancing((state) => ({
                    ...state,
                    number_installments,
                  }))
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between" marginX={2}>
              <Box textAlign="center">
                <Typography type="xsmall" color="grayscale" colorWeight="700">
                  Valor da parcela
                </Typography>
                <Typography type="xsmall" color="grayscale" colorWeight="700">
                  {parseBRL(valuesFinancing.value_installment) || (
                    <Skeleton height={30} width={120} />
                  )}
                </Typography>
              </Box>
              <Box textAlign="center">
                <Typography type="xsmall" color="grayscale" colorWeight="700">
                  Valor total
                </Typography>
                <Typography type="xsmall" color="grayscale" colorWeight="700">
                  {parseBRL(valuesFinancing.total_value) || (
                    <Skeleton height={30} width={120} />
                  )}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </AccordionPaymentMethod>
    </Box>
  )
}

export default Financing
