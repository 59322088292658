export const orderOptions = [
  {
    label: 'Modificação',
    value: '1',
  },
]

export const deleteColumns = [
  'Projeto',
  'Cliente',
  'Propostas',
  'Cotações',
  'Última modificação',
]

export const deleteProposalColumns = [
  '',
  'ID',
  'Dimensionamentos',
  'Cotações',
  'Status',
  'Criação',
  'Financiamento',
]
