import { CATEGORY_ENUM } from 'enums/products/CategoryEnum'

export function useDisableChangeButton(category: CATEGORY_ENUM) {
  const enableEditItems = [
    CATEGORY_ENUM.INVERSOR,
    CATEGORY_ENUM.MODULO,
    CATEGORY_ENUM.MICRO_INVERSOR,
    CATEGORY_ENUM.ESTRUTURA,
    CATEGORY_ENUM.STRING_BOX,
  ]

  const disableChangeButton = !enableEditItems.includes(category)

  return { disableChangeButton }
}
