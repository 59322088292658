import React from 'react'
import { Box, Divider, Hidden } from '@material-ui/core'
import { Spacer, Typography } from '@77sol/core'
import { EmptyState } from 'components'
import { useStyles } from './styles'
import { separateProductName } from 'utils/productName'

export function Equipments({ products }) {
  const classes = useStyles()

  function normalizeProductName({ nome = '' }) {
    return separateProductName(nome)
  }

  return (
    <>
      {!products && (
        <EmptyState text="Equipamentos só ficam disponíveis ao atrelar um pedido" />
      )}

      {products && (
        <div className={classes.equipmentContainer}>
          {products?.map(
            ({ titulo: title, url: image, qtde: quantity }, index) => (
              <div key={`${title}${index}`}>
                <div className={classes.equipmentContent}>
                  <Box display="flex" style={{ width: '80%' }}>
                    <Hidden mdDown>
                      <img width="64px" src={image} alt="Imagem de produto" />
                    </Hidden>
                    <Box className={classes.equipmentDescription}>
                      <Typography size="small">
                        {normalizeProductName(title).productName}
                      </Typography>
                      <Typography size="xsmall" colorWeight="400">
                        {normalizeProductName(title).availabilityMessage}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Typography size="large">{quantity}</Typography>
                    <Spacer size="8" direction="both" />
                    <Typography size="xsmall">un</Typography>
                  </Box>
                </div>
                {products.length !== index + 1 && <Divider />}
              </div>
            ),
          )}
        </div>
      )}
    </>
  )
}
