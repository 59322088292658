import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Spacer, Paper } from '@77sol/core'
import palette from 'app_palette'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '16px 16px 32px 16px',
    display: 'flex',
    height: '100%',
  },
  rightBorder: {
    borderRight: `1px solid ${palette.grayscale[300]}`,
    paddingRight: '24px',
  },
  paddingLeft: {
    paddingLeft: '24px',
  },
  resize: {
    fontSize: '32px',
    color: '#464F5B',
    width: '100px',
  },
  div: {
    height: '100%',
  },
})

function ProductionCardAbridged(props) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const {
    resumido,
    proposal,
    setActualEdit,
    sizingEdit,
  } = props

  const [editing, ,] = useState(false)


  useEffect(() => {
    if (editing) {
      setActualEdit('consumption')
    } else {
      setActualEdit('')
    }
  }, [editing, setActualEdit])

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.div} style={stylesAnimation.bounce}>
        <Paper
          style={{
            border: sizingEdit ? `2px solid ${palette.grayscale[300]}` : 'none',
            boxShadow: sizingEdit ? 'none' : 'rgb(0 0 0 / 4%) 0px 4px 6px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: resumido ? 'column' : 'row',
            }}
          >
            <div
              className={resumido ? '' : classes.paddingLeft}
              style={{ display: 'flex', width: '-webkit-fill-available' }}
            >
              <Grid container justify="space-between">
                <Grid
                  item
                  xs={resumido ? 12 : 6}
                  md={resumido ? 12 : 6}
                  lg={resumido ? 12 : 6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      type={downSm ? 'link_small' : 'text_medium'}
                      color="grayscale"
                      colorWeight="700"
                    >
                      Produção anual
                    </Typography>
                  </div>
                  <Spacer size="16" direction="horizontal" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Typography
                        color="grayscale"
                        colorWeight="700"
                        type={
                          downSm ? 'display_x_small_bold' : 'display_small_bold'
                        }
                      >
                        {proposal.energiaGerada.toFixed(0)}
                      </Typography>
                      <Spacer size="4" direction="vertical" />
                      <Typography
                        color="grayscale"
                        colorWeight="600"
                        type="text_x_small"
                      >
                        kWh
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Spacer
                    style={{
                      borderBottom: `2px solid ${palette.grayscale[300]}`,
                    }}
                    size="16"
                    direction="horizontal"
                  />
                  <Spacer size="16" direction="horizontal" />
                </Grid>
                <Grid
                  item
                  xs={resumido ? 12 : 6}
                  md={resumido ? 12 : 6}
                  lg={resumido ? 12 : 6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      type={downSm ? 'link_small' : 'text_medium'}
                      color="grayscale"
                      colorWeight="700"
                    >
                      Produção mensal
                    </Typography>
                  </div>
                  <Spacer size="16" direction="horizontal" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Typography
                        color="grayscale"
                        colorWeight="700"
                        type={
                          downSm ? 'display_x_small_bold' : 'display_small_bold'
                        }
                      >
                        {(proposal.energiaGerada / 12).toFixed(0)}
                      </Typography>
                      <Spacer size="4" direction="vertical" />
                      <Typography
                        color="grayscale"
                        colorWeight="600"
                        type="text_x_small"
                      >
                        kWh
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </div>
    </StyleRoot>
  )
}

export default ProductionCardAbridged
