import { apiV2 } from 'apis/apiV2'
import { DASHBOARD_FINANCING_REQUESTS } from '../constants/endpointKeys'
import {
  type IGetFinancingRequest,
  type IResponseGetFinancingRequestsData,
} from './types'

export const getDashboardFinancingData = async ({
  params,
}: IGetFinancingRequest): Promise<IResponseGetFinancingRequestsData> => {
  return await apiV2
    .get(DASHBOARD_FINANCING_REQUESTS, {
      params,
    })
    .then((response) => {
      return response.data
    })
}
