import { selectKitAction } from './Actions/selectKitAction'
import { type IState } from '../../types'
import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'

export const chooseKitSlice = (set: any) => ({
  selectedKits: [],
  kits: [],
  setSelectedKits: (kits: IKitDTOWithId[]) => set({ selectedKits: kits }),
  setKits: (kits: IKitDTOWithId[]) => set({ kits }),
  selectKit: (kit: IKitDTOWithId) =>
    set((state: IState) =>
      selectKitAction({ selectedKits: state.selectedKits, kit }),
    ),
})
