import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  container: {
    borderRadius: '8px',
    backgroundColor: palette.white,
  },
}))

export default useStyles
