import { type Location, type Action } from 'history'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export interface UsePromptProps {
  when: boolean
  blockPopNavigation?: boolean
  blockPushNavigation?: boolean
  blockReplaceNavigation?: boolean
  onNavigationBlocked: (location: Location, action: Action) => void
}

export interface UsePromptResult {
  allowNavigation: () => void
  handleDisableEvent: () => void
  handleReset: () => void
  historyEvent: (() => void) | null
}

export function usePrompt({
  when,
  blockPopNavigation = true,
  blockPushNavigation = false,
  blockReplaceNavigation = false,
  onNavigationBlocked,
}: UsePromptProps): UsePromptResult {
  const historyEvent: React.MutableRefObject<(() => void) | null> = useRef(null)
  const interceptedPath: React.MutableRefObject<Location | null> = useRef(null)

  const history = useHistory()

  const handleReset = useCallback(() => {
    interceptedPath.current = null
  }, [])

  const handleDisableEvent = useCallback(() => {
    historyEvent?.current?.()
    historyEvent.current = null
  }, [])

  const allowNavigation = useCallback(() => {
    if (interceptedPath.current) {
      history.push(interceptedPath.current)
      handleReset()
    }
  }, [handleReset])

  const blockNavigation = {
    POP: blockPopNavigation,
    PUSH: blockPushNavigation,
    REPLACE: blockReplaceNavigation,
  }

  useEffect(() => {
    if (when) {
      historyEvent.current = history.block(
        (location: Location, action: Action) => {
          if (blockNavigation[action]) {
            interceptedPath.current = location
            onNavigationBlocked(location, action)
            return false
          }

          return true
        },
      )
    }

    return () => {
      handleDisableEvent()
    }
  }, [history, when, handleDisableEvent])

  return {
    allowNavigation,
    handleReset,
    handleDisableEvent,
    historyEvent: historyEvent.current,
  }
}
