import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paperModalCards: {
    '@media (min-width:350px)': {
      backgroundColor: '#F7F7FC',
      padding: '24px',
      borderRadius: '0px',
      border: 'none',
      width: '100vw',
      height: 'var(--app-height)',
      marginTop: '0px',
      display: 'flex',
      position: 'absolute',
      boxShadow: 'none',
      flexDirection: 'column',
    },
    '@media (min-width:1250px)': {
      backgroundColor: theme.palette.background.paper,
      padding: '38px',
      borderRadius: '8px',
      border: 'none',
      width: '40vw',
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      flexDirection: 'column',
    },
  },
  filledButton: {
    background: 'linear-gradient(to right, #eb0000 0%, #ff4c4c 100%)',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(to right, #eb0000 0%, #ff4c4c 100%)',
      borderRadius: '8px',
    },
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
