import { Modal as ModalMolecule } from '@77sol-ui/molecules'
import { ModalContent } from './Content'
import { ModalWrapper } from './Wrapper'

const Modal = {
  ...ModalMolecule,
  Content: ModalContent,
  Wrapper: ModalWrapper,
}

export { Modal }
