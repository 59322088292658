import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  iconButton: {
    transition: 'transform 0.3s',
    transform: 'rotate(180deg)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconExpanded: {
    transition: 'transform 0.3s',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  input: {
    borderRadius: '8px',
  },
  scrollButtons: {
    color: '#707072',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalPloomes: () => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      width: '100%',
    },
  }),
}))
