import API from 'api'
import { type IStoreOrdersNotFinancingGETResponse } from './types'

export const storeOrdersNotFinancing = {
  get: async () => {
    return await API.get<IStoreOrdersNotFinancingGETResponse>(
      '/loja/orders-not-financing',
    ).then(({ data }) => data)
  },
}
