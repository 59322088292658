import { Portal } from 'components/Portal'
import { CompareKitFooter } from './components/Footer'
import { CompareKitHeader } from './components/CompareKitHeader'
import { CompareKitCardsContainer } from './components/CompareKitCardsContainer'

export function CompareKit() {
  return (
    <>
      <div className="tab-content">
        <CompareKitHeader />
        <CompareKitCardsContainer />
      </div>
      <Portal id="quotation-container-footer">
        <CompareKitFooter />
      </Portal>
    </>
  )
}
