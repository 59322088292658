import { type IKitDTOWithId } from 'containers/EquipmentQuotationContainer/hooks/useLoadKits'

interface ISelectKitsActionsProps {
  selectedKits: IKitDTOWithId[]
  kit: IKitDTOWithId
}

const selectKitAction = ({ selectedKits, kit }: ISelectKitsActionsProps) => {
  const isKitSelected = selectedKits.some(
    (selectedKit) => selectedKit.kitId === kit.kitId,
  )

  if (isKitSelected) {
    return {
      selectedKits: selectedKits.filter((item) => item.kitId !== kit.kitId),
    }
  } else {
    return {
      selectedKits: [...selectedKits, kit],
    }
  }
}

export { selectKitAction }
