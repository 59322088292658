import React from 'react'
import { ArrowCaretDownIcon } from '@77sol/icons/dist'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Title, Typography } from '@77sol/core'
import { withStyles } from '@material-ui/core/styles'
import palette from 'app_palette'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(() => ({
  root: {
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:focus': {
      // backgroundColor: palette.primary[300],
      // color: '#FFF !important',
      // borderRadius: '12px'
    },
  },
}))(MenuItem)

function Dropdown({
  options,
  selectedOption,
  setSelectedOption,
  idProperty,
  labelProperty,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <div
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Title weight="bold" size="xsmall">
          {
            options.find((option) => option[idProperty] === selectedOption)[
              labelProperty
            ]
          }
        </Title>
        <ArrowCaretDownIcon width="24" color={palette.grayscale[400]} />
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((item, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => {
              setSelectedOption(item[idProperty])
            }}
          >
            <Typography
              size="small"
              type="link"
              color={
                selectedOption === item[idProperty] ? 'white' : 'grayscale'
              }
              colorWeight={selectedOption === item[idProperty] ? '300' : '700'}
              style={{
                padding: '8px 12px',
                borderRadius: '12px',
                backgroundColor:
                  selectedOption === item[idProperty]
                    ? palette.primary[300]
                    : '',
              }}
            >
              {item[labelProperty]}
            </Typography>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

Dropdown.propTypes = {}

Dropdown.defaultProps = {}

export default Dropdown
