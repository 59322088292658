import propTypes from 'prop-types'
import { Status } from '@77sol/core'
import { financialLabels } from '../../utils'

function FinancialLabel({ status, size, color, colorWeight }) {
  const { ellipseColor, textLabel } = financialLabels(status)

  return (
    <Status
      label={textLabel}
      ellipseColor={ellipseColor}
      size={size}
      color={color}
      colorWeight={colorWeight}
    />
  )
}

FinancialLabel.propTypes = {
  size: propTypes.string,
  status: propTypes.string,
  color: propTypes.string,
  colorWeight: propTypes.string,
}

export default FinancialLabel
