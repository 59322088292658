import { Button } from '@77sol-ui/atoms'
import styled, { keyframes } from 'styled-components'

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0 16px 4px 16px;
  background-color: #fff;
`

export const StyledButton = styled(Button)`
  position: sticky;
  height: auto;
  min-height: 184px;

  & > .spin {
    animation: ${spin} 3s linear infinite;
  }
`
