import { DesktopContainer, MobileContainer, Wrapper } from './styles'
import { type IFinancingFilterProps } from './types'

import { MobileFilter } from './components/MobileFilter'
import { FiltersForm } from './components/FiltersForm'

export function FinancingFilters({ isPerValue }: IFinancingFilterProps) {
  return (
    <Wrapper>
      <MobileContainer>
        <MobileFilter isPerValue={isPerValue} />
      </MobileContainer>

      <DesktopContainer>
        <FiltersForm isPerValue={isPerValue} />
      </DesktopContainer>
    </Wrapper>
  )
}
