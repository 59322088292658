import { Input, Button } from '@77sol/core'
import './input.scss'
import { forwardRef, type ForwardRefRenderFunction, type Ref } from 'react'

interface UploadFileInputProps {
  title: string
  value: string
  disabled: boolean
  onRemoveFile: () => void
  onUploadFile: (file: File) => void
  className?: string
}

const UploadFileInput: ForwardRefRenderFunction<
  HTMLElement,
  UploadFileInputProps
> = (
  { title, value, disabled, onRemoveFile, onUploadFile, className },
  ref: Ref<HTMLElement>,
) => {
  function uploadFile() {
    const inputFile = document.createElement('input')
    inputFile.type = 'file'
    inputFile.click()
    inputFile.onchange = (event: Event): void => {
      const input = event.target as HTMLInputElement
      if (!input.files?.length) {
        return
      }
      onUploadFile(input.files[0])
    }
  }

  return (
    <Input
      ref={ref}
      className={`${className || ''} ${
        !value ? 'input-file' : 'input-upload-file-with-file'
      }`}
      title={title}
      value={value}
      InputProps={{
        readOnly: true,
        endAdornment: value ? (
          <Button
            variant="outlined"
            size="small"
            color="red"
            onClick={onRemoveFile}
            disabled={disabled}
          >
            Excluir
          </Button>
        ) : (
          <Button
            color="primary"
            size="small"
            onClick={uploadFile}
            disabled={disabled}
          >
            Anexar
          </Button>
        ),
      }}
      disabled={disabled}
    />
  )
}

export default forwardRef(UploadFileInput)
