import { type AxiosResponse } from 'axios'
import { type IAcceptLeadResponse } from '../services/types'

export function resolveStructure(response: AxiosResponse<IAcceptLeadResponse>) {
  const responseUpdated = response

  if (responseUpdated.data.customer.structure === 'fibrocimento') {
    responseUpdated.data.customer.structure = 'fibrocimento-madeira'
  }

  return responseUpdated
}
