import styled from 'styled-components'

export const CarouselWrapper = styled.div`
  margin-top: 13px;
`

export const Container = styled.main`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`
