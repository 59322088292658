import React, { createRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Typography } from '@material-ui/core'
import { Paper } from '@77sol/core'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import palette from 'app_palette'
import Tooltip from '@material-ui/core/Tooltip'
import { ViewIcon } from '@77sol/icons/dist'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { ContainerListPdf } from '..'
import appTheme from '../../../../../../../../app_theme'
import logoImgCopernico from '../../../../../../../../assets/img/logo-copernico.png'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
})

function PdfTab({
  logo,
  onDragEndPdf,
  statePdf,
  proposal,
  userName,
  setStatePdf,
  stateQuotesPdf,
  setStateQuotesPdf,
  checkPdfDragAndDrop,
  checkDragAndDrop,
  updatePdfStructure,
  updateStructure,
  documentUsername,
  signatureState,
  setSignatureState,
}) {
  const classes = useStyles()
  const editRef = createRef()

  const [isEditing, setIsEditing] = useState('')
  const [isEditingMore, setIsEditingMore] = useState('')

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Paper
        className="test-pdf"
        style={{
          width: '630px',
          margin: '24px 0px',
          padding: '32px 40px',
          position: 'relative',
          paddingBottom: '80px',
        }}>
        <div
          ref={(el) => {
            editRef.current = el
          }}>
          <DragDropContext onDragEnd={onDragEndPdf}>
            <Droppable type="droppableItemPdf" droppableId="droppablePdf">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                  className={classes.paperRendered}>
                  <ContainerListPdf
                    containers={statePdf.containers}
                    proposal={proposal}
                    logo={logo}
                    userName={userName}
                    setStatePdf={setStatePdf}
                    stateQuotesPdf={stateQuotesPdf}
                    setStateQuotesPdf={setStateQuotesPdf}
                    editRef={editRef}
                    checkPdfDragAndDrop={checkPdfDragAndDrop}
                    checkDragAndDrop={checkDragAndDrop}
                    updatePdfStructure={updatePdfStructure}
                    updateStructure={updateStructure}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isEditingMore={isEditingMore}
                    setIsEditingMore={setIsEditingMore}
                  />
                  {signatureState.visible && (
                    <div
                      style={{
                        border:
                          isEditing === 'signature' &&
                          `2px solid ${palette.primary[300]}`,
                        borderRadius: '8px',
                        padding: isEditing === 'signature' && '8px',
                        width: '220px',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                      onClick={() =>
                        isEditing === 'signature'
                          ? setIsEditing('')
                          : setIsEditing('signature')
                      }>
                      {isEditing === 'signature' && (
                        <div
                          onClick={(e) => e.stopPropagation()}
                          style={{
                            position: 'absolute',
                            zIndex: 10,
                            top: '-30px',
                            right: '-30px',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                            padding: '12px',
                            backgroundColor: '#FFF',
                            cursor: 'auto',
                            maxWidth: '100%',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}>
                            <Typography
                              style={{
                                fontSize: '14px',
                                color: '#707072',
                                paddingRight: '12px',
                                borderRight: '1px solid #e2e6e7',
                              }}>
                              Assinatura
                            </Typography>
                            <div
                              style={{
                                paddingLeft: '12px',
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <Tooltip title="Esconder">
                                <ViewIcon
                                  onClick={() => {
                                    const newObj = signatureState
                                    newObj.visible = false
                                    setSignatureState({ ...newObj })
                                  }}
                                  width="16"
                                  color={palette.grayscale[500]}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )}
                      <br />
                      <div
                        style={{
                          borderTop: '1px solid black',
                          paddingTop: '4px',
                          marginTop: '24px',
                        }}>
                        <Typography
                          style={{
                            fontSize: '10px',
                            color: palette.grayscale[700],
                            textAlign: 'center',
                            fontWeight: 800,
                          }}>
                          Assinatura do Integrador
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '9px',
                            color: palette.grayscale[700],
                            textAlign: 'center',
                          }}>
                          {userName}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '9px',
                            color: palette.grayscale[700],
                            textAlign: 'center',
                          }}>
                          {documentUsername}
                        </Typography>
                      </div>
                      <br />
                      <div
                        style={{
                          borderTop: '1px solid black',
                          paddingTop: '4px',
                          marginTop: '24px',
                        }}>
                        <Typography
                          style={{
                            fontSize: '10px',
                            color: palette.grayscale[700],
                            textAlign: 'center',
                            fontWeight: 800,
                          }}>
                          Assinatura do cliente
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '9px',
                            color: palette.grayscale[700],
                            textAlign: 'center',
                          }}>
                          {
                            proposal?.clients.find(
                              (client) =>
                                client.id === proposal.proposal.customer_id,
                            )?.label
                          }
                        </Typography>
                        {proposal?.clients.find(
                          (client) =>
                            client.id === proposal.proposal.customer_id,
                        )?.cpf && (
                          <Typography
                            style={{
                              fontSize: '9px',
                              color: palette.grayscale[700],
                              textAlign: 'center',
                            }}>
                            {
                              proposal?.clients.find(
                                (client) =>
                                  client.id === proposal.proposal.customer_id,
                              )?.cpf
                            }
                          </Typography>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: '100%',
                      marginTop: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'absolute',
                      bottom: '40px',
                      left: '0',
                    }}>
                    <Avatar
                      variant="rounded"
                      style={{
                        height: 'auto',
                        width: 'auto',
                        backgroundColor:
                          !logo && appTheme !== 'copernico' && '#d9dbe9',
                        fontSize: '24px',
                        borderRadius: '8px',
                      }}
                      imgProps={{
                        style: {
                          height: 'initial',
                          width: 'initial',
                          maxHeight: currentEnterpriseConfigs.pdfTab.maxHeight,
                          maxWidth: currentEnterpriseConfigs.pdfTab.maxWidth,
                        },
                      }}
                      src={
                        currentEnterpriseConfigs?.whiteLabelLogo &&
                        logoImgCopernico
                      }>
                      {userName
                        ?.match(/(\b\S)?/g)
                        .join('')
                        .match(/(^\S|\S$)?/g)
                        .join('')
                        .toUpperCase()}
                    </Avatar>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Paper>
    </div>
  )
}

export default PdfTab
