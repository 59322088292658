export const headerTracker = {
  eventName: 'Header Principal',
  actions: {
    quoteEquipmentKey: 'Cotar equipamentos',
    quickQuoteKey: 'Cotação rápida',
    sizingAndQuoteKey: 'Dimensionar e cotar',
    requestFinancingKey: 'Solicitar financiamento',
    saveAndExitKey: 'Menu Superior: Botão Salvar e sair',
  },
}
