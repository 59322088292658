import { useMutation } from '@tanstack/react-query'
import { acceptLead } from '../services/api'
import { resolveStructure } from '../utils/resolveStructure'
import { type IAcceptLeadPayload } from '../services/types'

export function useAcceptLead() {
  return useMutation({
    mutationFn: async (payload: IAcceptLeadPayload) =>
      await acceptLead(payload).then(resolveStructure),
  })
}
