import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { CloseXIcon, EditIcon, ViewIcon } from '@77sol/icons/dist'
import { ReturnGraphPaper, Spacer, Paper } from '@77sol/core'
import smoothscroll from 'smoothscroll-polyfill'
import palette from 'app_palette'
import { DragPaper } from '../../..'
import DragIcon from '../../../../img/dragGrey.png'
import DragIconBlue from '../../../../img/dragBlue.png'
import {
  ConcessionariaCard,
  TarifaCard,
  CorrecaoAnualCard,
  InvestimentoRetornoCard,
} from '../../..'
import { DigitalInvestingContainer } from '..'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
})

function DigitalInvestingSection({
  container,
  index,
  investingContainers,
  editMode,
  internalEditing,
  setInternalEditing,
  investingSections,
  setStateInvestingSections,
  setStateInvesting,
  drag,
  setDrag,
  editingMore,
  selectedQuote,
  setEditingMore,
  proposal,
  size,
  tirVplContainers,
  setTirVplContainers,
  retornoSubCards,
  setRetornoSubCards,
  colors,
  budgetEdit,
  returnData,
  setReturnData,
  handleGetBudget,
}) {
  /* eslint eqeqeq: 0 */
  const classes = useStyles()

  return (
    <Draggable
      isDragDisabled={
        editMode != 'investingContainer' ||
        editingMore == container.id ||
        editingMore == 'voceInvesteContainer' ||
        editingMore == 'TirContainer' ||
        editingMore == 'VplContainer' ||
        editingMore == 'retornoEmContainer' ||
        editingMore == '30AnosContainer' ||
        editingMore == 'RetornoContainer'
      }
      key={container.id}
      draggableId={container.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Grid
          item
          className={classes.paddingSection}
          xs={12}
          md={12}
          lg={12}
          xl={
            investingSections.filter((container) => container.visible).length !=
              1 ||
            (investingContainers.find(
              (container) => container.id == 'RetornoContainer',
            ).visible &&
              tirVplContainers.filter((container) => container.visible)
                .length != 0 &&
              retornoSubCards.filter((container) => container.visible).length !=
                0)
              ? 6
              : 12
          }
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div
            style={{ height: '100%' }}
            className={snapshot.isDragging && classes.dragging}
            onClick={(e) => {
              if (editMode == 'investingContainer') {
                if (container.id == 'retornoGrafico') {
                  internalEditing && internalEditing == 'retornoGrafico'
                    ? setInternalEditing('')
                    : setInternalEditing('retornoGrafico')
                  setEditingMore('')
                }
              }
            }}
          >
            {container.id == 'allCards' && (
              <Droppable
                type="droppableSubItemInvesting"
                droppableId="droppableSubItemInvesting"
              >
                {(provided, snapshot) => (
                  <Grid
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ height: '101.5%' }}
                    container
                    spacing={1}
                  >
                    {investingContainers.map((container, index) => (
                      <>
                        {container.visible && (
                          <>
                            {((container.id == 'retornoSubCards' &&
                              retornoSubCards.filter(
                                (container) => container.visible,
                              ).length != 0) ||
                              (container.id == 'tirVplContainers' &&
                                tirVplContainers.filter(
                                  (container) => container.visible,
                                ).length != 0) ||
                              (container.id != 'tirVplContainers' &&
                                container.id != 'retornoSubCards')) && (
                              <DigitalInvestingContainer
                                editingMore={editingMore}
                                selectedQuote={selectedQuote}
                                setEditingMore={setEditingMore}
                                drag={drag}
                                setDrag={setDrag}
                                internalEditing={internalEditing}
                                setInternalEditing={setInternalEditing}
                                investingContainers={investingContainers}
                                setStateInvesting={setStateInvesting}
                                container={container}
                                index={index}
                                editMode={editMode}
                                proposal={proposal}
                                size={size}
                                tirVplContainers={tirVplContainers}
                                setTirVplContainers={setTirVplContainers}
                                retornoSubCards={retornoSubCards}
                                setRetornoSubCards={setRetornoSubCards}
                                budgetEdit={budgetEdit}
                                returnData={returnData}
                                setReturnData={setReturnData}
                                handleGetBudget={handleGetBudget}
                                colors={colors}
                              />
                            )}
                          </>
                        )}
                      </>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            )}
            {container.id == 'retornoGrafico' && returnData && (
              <ReturnGraphPaper returnData={returnData} colors={colors} />
            )}
          </div>
          {snapshot.isDragging && <DragPaper />}
          {editMode == 'investingContainer' &&
            internalEditing == container.id && (
              <div
                onClick={(e) => e.stopPropagation()}
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  top: '-15px',
                  left: '-15px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Paper
                  style={{
                    padding: '16px 24px',
                    boxShadow: 'none',
                    cursor: 'auto',
                    maxHeight: '50vh',
                    width: 'auto',
                    border: `1px solid ${palette.grayscale[300]}`,
                    overflowY: 'auto',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography
                      type="link_small"
                      color="grayscale"
                      colorWeight="700"
                    >
                      {container.display}
                    </Typography>
                    <Spacer size="24" direction="vertical" />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {editingMore == container.id ? (
                        <Tooltip title="Fechar">
                          <CloseXIcon
                            width="24"
                            onClick={() =>
                              editingMore == container.id
                                ? setEditingMore('')
                                : setEditingMore(container.id)
                            }
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="Editar">
                            <EditIcon
                              onClick={() =>
                                editingMore == container.id
                                  ? setEditingMore('')
                                  : setEditingMore(container.id)
                              }
                              style={{ cursor: 'pointer' }}
                              width="24"
                              color={palette.grayscale[700]}
                            />
                          </Tooltip>
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Arrastar">
                            <img
                              alt="drag icon"
                              onClick={() =>
                                drag == container.id
                                  ? setDrag('')
                                  : setDrag(container.id)
                              }
                              src={
                                internalEditing == container.id
                                  ? DragIconBlue
                                  : DragIcon
                              }
                              style={{
                                width: '20px',
                                cursor: 'pointer',
                                color:
                                  drag == container.id
                                    ? '#1335c6'
                                    : 'rgb(112, 112, 114)',
                              }}
                            />
                          </Tooltip>
                          <Spacer size="12" direction="vertical" />
                          <Tooltip title="Esconder">
                            <ViewIcon
                              onClick={() => {
                                const newArr = [...investingSections]
                                const index = newArr.findIndex(
                                  (item) => item.id == container.id,
                                )
                                newArr[index].visible = !newArr[index].visible
                                setStateInvestingSections({
                                  containers: newArr,
                                })
                              }}
                              style={{ cursor: 'pointer' }}
                              width="24"
                              color={palette.grayscale[700]}
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                  {editingMore == container.id && (
                    <div>
                      <Grid
                        style={{
                          width: '100%',
                          padding: '0px',
                          margin: '0px',
                          marginTop: '16px',
                          maxWidth: '700px',
                        }}
                        container
                        spacing={2}
                      >
                        <Grid xs={4} md={4} item>
                          <ConcessionariaCard proposal={proposal} />
                        </Grid>
                        <Grid xs={4} md={4} item>
                          <TarifaCard
                            selectedQuote={selectedQuote}
                            setReturnData={setReturnData}
                            returnData={returnData}
                          />
                        </Grid>
                        <Grid xs={4} md={4} item>
                          <CorrecaoAnualCard
                            selectedQuote={selectedQuote}
                            setReturnData={setReturnData}
                            returnData={returnData}
                          />
                        </Grid>
                        <Grid xs={12} md={12} item>
                          <InvestimentoRetornoCard
                            returnData={returnData}
                            onlyShow
                            noPadding
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Paper>
              </div>
            )}
        </Grid>
      )}
    </Draggable>
  )
}

export default DigitalInvestingSection
