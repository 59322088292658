import { Select as SelectUi } from '@77sol-ui/molecules'
import { type ISelectProps } from './types'

export function Select({
  open,
  onOpenChange,
  onValueChange,
  label,
  required,
  placeholder,
  error,
  side,
  sideOffset,
  align,
  alignOffset,
  maxWidth,
  maxHeight,
  disabledItems,
  options,
  isLoading,
  customIcon: Icon,
  ...props
}: ISelectProps) {
  const hasError = Boolean(error)

  function selectValueChange(value: string) {
    const resetField = Boolean(value === placeholder)

    const updatedValue = resetField ? '' : value

    onValueChange?.(updatedValue)
  }

  return (
    <SelectUi.Root
      open={open}
      onOpenChange={onOpenChange}
      onValueChange={selectValueChange}
      {...props}
    >
      <div>
        {label && <SelectUi.Label label={label} required={required} />}

        <SelectUi.Trigger error={hasError} isLoading={isLoading}>
          <SelectUi.Value placeholder={placeholder} />

          {Icon && (
            <SelectUi.Icon error={hasError} isLoading={isLoading}>
              <Icon />
            </SelectUi.Icon>
          )}

          {!Icon && <SelectUi.Icon error={hasError} isLoading={isLoading} />}
        </SelectUi.Trigger>
        {hasError && <SelectUi.Error errorText={error as string} />}
      </div>

      <SelectUi.Portal>
        <SelectUi.Content
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
        >
          <SelectUi.Viewport>
            {options.length > 0 &&
              options.map((option) => (
                <SelectUi.Item
                  key={option.value}
                  value={option.value || ''}
                  disabled={disabledItems?.includes(option.value || '')}
                >
                  {option.label}
                </SelectUi.Item>
              ))}
          </SelectUi.Viewport>
        </SelectUi.Content>
      </SelectUi.Portal>
    </SelectUi.Root>
  )
}
