import React, { useState, useEffect, createRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography, Input } from 'components'
import { Button, Paper } from '@77sol/core'
import { EditIcon, CloseXIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { useDispatch } from 'react-redux'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import { CashFlowModal, ClienteEdit, SizingEditAll, ColorsEdit } from '../../..'
import { DigitalProposalContainerList } from '..'

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  input: {
    padding: '10px',
  },
})

function DigitalProposalTab(props) {
  const {
    backgroundImage,
    sizingEdit,
    logo,
    editClientExpanded,
    setEditClientExpanded,
    setEditClient,
    editClient,
    editSizingExpanded,
    setEditSizingExpanded,
    sizingActivePanel,
    setSizingActivePanel,
    quoteEdit,
    setQuoteEdit,
    setSelectedQuote,
    onDragEnd,
    stateInvestingSections,
    stateInvesting,
    state,
    modalInfoFluxo,
    setModalInfoFLuxo,
    setState,
    setStateInvesting,
    setStateInvestingSections,
    stateEquipmentsSections,
    setStateEquipmentsSections,
    statePaymentSections,
    setStatePaymentSections,
    ProposalInfosSections,
    setProposalInfosSections,
    selectedQuote,
    isViewing,
    setIsViewing,
    editMode,
    setEditMode,
    proposal,
    size,
    paymentOptionsContainers,
    setPaymentOptionsContainers,
    moreInfosContainers,
    setMoreInfosContainers,
    ProposalSubInfosSections,
    setProposalSubInfosSections,
    tirVplContainers,
    setTirVplContainers,
    retornoSubCards,
    setRetornoSubCards,
    colors,
    isFromProposalList,
    setIsProPlanModalOpen,
    isUserProPlan,
    checkDragAndDrop,
    checkPdfDragAndDrop,
    updatePdfStructure,
    updateStructure,
    getFirstPageClients,
    clients,
    getMoreClients,
    hasMoreClients,
    setFilterClientName,
    filterClientName,
    resetFieldSearch,
  } = props

  /* eslint eqeqeq: 0 */
  const classes = useStyles()
  const dispatch = useDispatch()

  const tokenRegister = localStorage.getItem('sessionToken')

  const SizingStepLabel = (value) => {
    const labels = {
      sizing: 'Dados do dimensionamento',
      equipments: `Cotação ${selectedQuote} - Equipamentos`,
      budget: `Cotação ${selectedQuote} - Orçamento`,
      investmentReturn: `Cotação ${selectedQuote} - Retorno de investimento`,
    }

    return labels[value] || ''
  }
  const { userName } = proposal

  const seeResponse = (res) => {
    setSelectedQuote(Number(res))
  }

  const [budgetEdit, setBudgetEdit] = useState([])
  const [returnData, setReturnData] = useState()

  const [editColorsExpanded, setEditColorsExpanded] = useState(false)

  const handleShowRoi = (sizing, values) => {
    API.get(`/proposal/quotation/roi/calculate/${selectedQuote}`, {
      headers: {
        token: tokenRegister,
      },
    })
      .then((res) => {
        setReturnData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGetBudget = (id) => {
    API.get(`/proposal/list/budgets/${selectedQuote}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        setTimeout(() => {
          setBudgetEdit(res.data)
          handleShowRoi()
        }, [500])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpdateColors = (
    background,
    primary,
    secondary,
    texts,
    primaryGraph,
    secondaryGraph,
    positive,
    negative,
    backgroundContent,
  ) => {
    API.patch(
      '/proposal/color/config',
      {
        proposal_id: proposal.proposal.id,
        background,
        primary_color: primary,
        secondary_color: secondary,
        texts,
        primary_color_graphics: primaryGraph,
        secondary_color_graphics: secondaryGraph,
        positive_values: positive,
        negative_values: negative,
        background_content: backgroundContent,
      },
      {
        headers: {
          token: tokenRegister,
        },
      },
    )
      .then((res) => {
        handleGetExtraConfigs(proposal.proposal.id)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGetExtraConfigs = (id) => {
    API.get(`/proposal/extra-configs/${id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        dispatch(ProposalAction.SaveExtraConfigsRedux(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    handleGetBudget(selectedQuote)
    // eslint-disable-next-line
  }, [selectedQuote])

  useEffect(() => {
    if (!editSizingExpanded) {
      handleGetBudget(selectedQuote)
      setSizingActivePanel('all')
    }
    // eslint-disable-next-line
  }, [editSizingExpanded])

  const editRef = createRef()
  const clientRef = createRef()

  const handleClickOutsideClient = (event) => {
    if (clientRef.current && !clientRef.current.contains(event.target)) {
      setEditClient(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideClient, true)
    return () => {
      document.removeEventListener('click', handleClickOutsideClient, true)
    }
  })

  return (
    <div
      style={{ backgroundColor: colors && colors.background }}
      className={classes.paperRendered}
    >
      <div>
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            minHeight: '25vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
            position: 'relative',
            backgroundColor: !backgroundImage && '#d9dbe9',
            top: '-24px',
            left: '-24px',
            width: 'calc(100% + 48px)',
            height: '240px',
          }}
        >
          <div
            ref={(el) => {
              clientRef.current = el
            }}
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <Paper
              boxShadow="small"
              onClick={() => {
                editClient ? setEditClient(false) : setEditClient(true)
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                position: 'absolute',
                bottom: '-23%',
                width: 'auto',
                height: 'auto',
                backgroundColor: colors?.background_content
                  ? colors?.background_content
                  : '#FFF',
              }}
            >
              <Avatar
                variant="rounded"
                style={{
                  height: 'auto',
                  width: 'auto',
                  marginRight: '16px',
                  backgroundColor: !logo && '#D9DBE9',
                  fontSize: '40px',
                }}
                imgProps={{
                  style: {
                    height: 'initial',
                    width: 'initial',
                    maxHeight: '140px',
                    maxWidth: '180px',
                  },
                }}
                src={logo}
              >
                {userName
                  ?.match(/(\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </Avatar>
              <div>
                <Typography
                  style={{ color: colors?.primary_color }}
                  type="display_small_x_bold"
                  color="primary"
                  colorWeight="300"
                >
                  Olá
{' '}
                  {
                    proposal?.clients?.find(
                      (client) => client.id == proposal.proposal.customer_id,
                    )?.label
                  }
                  ,
                </Typography>
                <Typography
                  style={{ color: colors?.primary_color }}
                  type="text_large"
                  color="primary"
                  colorWeight="300"
                >
                  seja bem-vindo à sua proposta solar!
                </Typography>
              </div>
              {editClient && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    position: 'absolute',
                    zIndex: 105,
                    top: '120px',
                    left: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                      padding: '16px 24px',
                      backgroundColor: '#FFF',
                      cursor: 'auto',
                    }}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Typography
                        type="link_small"
                        color="grayscale"
                        colorWeight="700"
                      >
                        Cliente
                      </Typography>
                      <div
                        style={{
                          paddingLeft: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip title="Editar">
                          {editClientExpanded ? (
                            <CloseXIcon
                              onClick={() => {
                                editClientExpanded
                                  ? setEditClientExpanded(false)
                                  : setEditClientExpanded(true)
                              }}
                              width="24"
                              color={palette.grayscale[700]}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <EditIcon
                              onClick={() => {
                                editClientExpanded
                                  ? setEditClientExpanded(false)
                                  : setEditClientExpanded(true)
                              }}
                              width="24"
                              color={palette.grayscale[700]}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </Tooltip>
                      </div>
                    </div>
                    {editClientExpanded && (
                      <div
                        style={{
                          minWidth: '40vw',
                          maxHeight: '60vh',
                          overflow: 'auto',
                        }}
                      >
                        <ClienteEdit
                          proposal={proposal}
                          getClientsFromStepFour={getFirstPageClients}
                          getMoreClients={getMoreClients}
                          hasMoreClients={hasMoreClients}
                          setFilterClientName={setFilterClientName}
                          filterClientName={filterClientName}
                          resetFieldSearch={resetFieldSearch}
                          clients={clients}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Paper>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div
              style={{ position: 'relative' }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFF',
                  cursor: 'auto',
                  marginRight: '214px',
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  zIndex: 50,
                  maxHeight: '75vh',
                  overflow: 'auto',
                  padding: '16px 24px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    type="link_small"
                    color="grayscale"
                    colorWeight="700"
                  >
                    Cores
                  </Typography>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title="Editar">
                      {editColorsExpanded ? (
                        <CloseXIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            editColorsExpanded
                              ? setEditColorsExpanded(false)
                              : setEditColorsExpanded(true)
                          }}
                          width="24"
                          color={palette.grayscale[700]}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <EditIcon
                          onClick={(e) => {
                            // if (!isUserProPlan) {
                            //   return setIsProPlanModalOpen(true)
                            // }
                            e.stopPropagation()
                            editColorsExpanded
                              ? setEditColorsExpanded(false)
                              : setEditColorsExpanded(true)
                          }}
                          width="24"
                          color={palette.grayscale[700]}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>
                {editColorsExpanded && (
                  <div
                    style={{
                      minWidth: '35vw',
                      maxWidth: '40vw',
                      maxHeight: '40vw',
                      overflowY: 'auto',
                    }}
                  >
                    <ColorsEdit
                      proposal={proposal}
                      handleUpdateColors={handleUpdateColors}
                      isFromDragAndDrop
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ position: 'relative' }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                style={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  padding: '16px 24px',
                  backgroundColor: '#FFF',
                  cursor: 'auto',
                  marginRight: '16px',
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  zIndex: 50,
                  maxHeight: '75vh',
                  overflow: 'auto',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography
                    type="link_small"
                    color="grayscale"
                    colorWeight="700"
                  >
                    Orçamentos
                  </Typography>
                  <div
                    style={{
                      paddingLeft: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip title="Editar">
                      {editSizingExpanded ? (
                        <CloseXIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            editSizingExpanded
                              ? setEditSizingExpanded(false)
                              : setEditSizingExpanded(true)
                          }}
                          width="24"
                          color={palette.grayscale[700]}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <EditIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            editSizingExpanded
                              ? setEditSizingExpanded(false)
                              : setEditSizingExpanded(true)
                          }}
                          width="24"
                          color={palette.grayscale[700]}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Tooltip>
                  </div>
                </div>
                {editSizingExpanded && (
                  <div
                    style={{
                      minWidth: '35vw',
                      maxWidth: '40vw',
                      maxHeight: '40vw',
                      overflowY: 'auto',
                    }}
                  >
                    {sizingActivePanel != 'all' && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          marginTop: '12px',
                        }}
                      >
                        <div
                          onClick={() => setSizingActivePanel('all')}
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                          }}
                        >
                          <ArrowBackIosRoundedIcon
                            style={{
                              color: '707072',
                              fontSize: '15px',
                              marginRight: '8px',
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: '14px',
                              marginRight: '16px',
                              color: '#707072',
                            }}
                          >
                            Voltar
                          </Typography>
                        </div>
                        <Paper
                          style={{
                            backgroundColor: palette.primary[300],
                            color: '#FFF',
                            textAlign: 'center',
                            padding: '6px',
                            fontSize: '13px',
                            borderRadius: '8px',
                            width: '-webkit-fill-available',
                          }}
                        >
                          {SizingStepLabel(sizingActivePanel)}
                        </Paper>
                      </div>
                    )}
                    <SizingEditAll
                      sizingEdit={sizingEdit}
                      sizingActivePanel={sizingActivePanel}
                      setSizingActivePanel={setSizingActivePanel}
                      quoteEdit={quoteEdit}
                      setQuoteEdit={setQuoteEdit}
                      proposal={proposal}
                      proposalDragDrop
                    />
                  </div>
                )}
              </div>
            </div>
            <Input
              native
              size="small"
              value={selectedQuote}
              setValue={seeResponse}
              select
            >
              {proposal &&
                proposal.sizings.map((sizing, index) => (
                  <optgroup label={`Dimensionamento ${index + 1}`}>
                    {proposal.quotes
                      .filter((quote) => quote.dimensioning.id == sizing.id)
                      .map((quote, i) => (
                        <option value={quote.quotation.id}>
                          Projeto 
{' '}
{index + 1} - Cotação 
{' '}
{i + 1}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </Input>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{
            marginTop: '16px',
            marginBottom: '16px',
            backgroundColor: colors && colors.primary_color,
            border: colors && `2px solid ${colors.primary_color}`,
            zIndex: 100,
          }}
        >
          Financiar projeto
        </Button>
      </div>
      <div
        ref={(el) => {
          editRef.current = el
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable type="droppableItem" droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DigitalProposalContainerList
                  setState={setState}
                  investingSections={stateInvestingSections.containers}
                  setStateInvesting={setStateInvesting}
                  investingContainers={stateInvesting.containers}
                  setStateInvestingSections={setStateInvestingSections}
                  stateEquipmentsSections={stateEquipmentsSections.containers}
                  setStateEquipmentsSections={setStateEquipmentsSections}
                  statePaymentSections={statePaymentSections.containers}
                  setStatePaymentSections={setStatePaymentSections}
                  ProposalInfosSections={ProposalInfosSections.containers}
                  setProposalInfosSections={setProposalInfosSections}
                  containers={state.containers}
                  selectedQuote={selectedQuote}
                  isViewing={isViewing}
                  setIsViewing={setIsViewing}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  proposal={proposal}
                  size={size}
                  paymentOptionsContainers={paymentOptionsContainers.containers}
                  setPaymentOptionsContainers={setPaymentOptionsContainers}
                  moreInfosContainers={moreInfosContainers.containers}
                  setMoreInfosContainers={setMoreInfosContainers}
                  ProposalSubInfosSections={ProposalSubInfosSections.containers}
                  setProposalSubInfosSections={setProposalSubInfosSections}
                  tirVplContainers={tirVplContainers.containers}
                  setTirVplContainers={setTirVplContainers}
                  retornoSubCards={retornoSubCards.containers}
                  setRetornoSubCards={setRetornoSubCards}
                  colors={colors}
                  budgetEdit={budgetEdit}
                  setBudgetEdit={setBudgetEdit}
                  returnData={returnData}
                  setReturnData={setReturnData}
                  handleGetBudget={handleGetBudget}
                  isFromProposalList={isFromProposalList}
                  setIsProPlanModalOpen={setIsProPlanModalOpen}
                  isUserProPlan={isUserProPlan}
                  editRef={editRef}
                  checkDragAndDrop={checkDragAndDrop}
                  checkPdfDragAndDrop={checkPdfDragAndDrop}
                  updatePdfStructure={updatePdfStructure}
                  updateStructure={updateStructure}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <CashFlowModal
        retornoConfig={25}
        fluxos={
          returnData?.dadosConsolidados
        }
        modalInfoFluxo={modalInfoFluxo}
        setModalInfoFLuxo={setModalInfoFLuxo}
      />
    </div>
  )
}

export default DigitalProposalTab
