import React from 'react'
import * as S from '../styles'

const ChangedKitFlag: React.FC = () => {
  return (
    <S.Flag
      borderRadius="0px 0px 8px 8px;"
      text="KIT ALTERADO"
      variant="orange"
    />
  )
}

export { ChangedKitFlag }
