import { useQuery } from '@tanstack/react-query'
import { DASHBOARD_PROPOSALS } from '../constants/endpointKeys'
import { getDashboardProposalsData } from '../services/api'
import { type IGetProposalsRequest } from '../services/types'

interface IUseGetProposalsDataProps {
  enabled?: boolean
  params: IGetProposalsRequest['params']
}

export function useGetProposalsData({
  enabled,
  params,
}: IUseGetProposalsDataProps) {
  const response = useQuery({
    queryKey: [DASHBOARD_PROPOSALS, params],
    queryFn: async () => await getDashboardProposalsData({ params }),
    enabled,
    staleTime: 0,
  })

  return response
}
