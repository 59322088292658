import React from 'react'
import { Page } from 'components'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { EquipmentsStore } from './components/EquipmentsStore'
import { useStyles } from './styles'
import { Header } from 'components/NewHeader'

function Overview() {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title={currentEnterpriseConfigs.titlePage('Equipamentos')}
    >
      <Header />
      <EquipmentsStore />
    </Page>
  )
}

export default Overview
