import { useHistory, useLocation } from 'react-router-dom'

export const useCancelQuotationContainer = () => {
  const { search = '' } = useLocation()
  const history = useHistory()

  const handleCancelQuotationRequest = () => {
    if (search.includes('quotationModal')) {
      history.push('/')
    }
  }
  return { handleCancelQuotationRequest }
}
