import { useQuery } from '@tanstack/react-query'
import { adminInfosDisponibilityProducts } from '../services/api'
import { GET_ADMIN_INFOS_DISPONIBILITY_PRODUCTS } from '../constants/endpointKeys'

const STALE_TIME_IN_MINUTES = 1000 * 60 * 10

export const useAdminInfosDisponibilityProducts = (currentPhase?: string) => {
  const { data, isSuccess, isError, isFetching } = useQuery({
    queryKey: [GET_ADMIN_INFOS_DISPONIBILITY_PRODUCTS, currentPhase],
    queryFn: async () =>
      await adminInfosDisponibilityProducts.get(currentPhase),
    staleTime: STALE_TIME_IN_MINUTES,
  })

  const phasesData = [
    { label: 'Selecione a fase', value: 'a' },
    ...(data?.phases || []),
  ]

  const voltagesData = [
    { label: 'Seleciona a tensão', value: 'a' },
    ...(data?.voltages || []),
  ]

  return {
    data,
    dataOptions: { phasesData, voltagesData },
    isFetching,
    isSuccess,
    isError,
  }
}
