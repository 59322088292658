import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    h1 {
      color: #1f2633;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      font-family: DM Sans;
    }

    p {
      font-family: DM Sans;
      margin-top: 8px;
      color: #4e4b66;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    @media (max-width: 768px) {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 16px;
        margin-top: 4px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  @media (max-width: 1280px) {
    .actions {
      display: none;
    }
  }
`
