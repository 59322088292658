import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { proposalCreateDimensioning } from '../services/api'
import { type Payload } from '../services/types'

export const useAddProposalCreateDimensioning = () => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (payload: Payload) =>
      await proposalCreateDimensioning.post(payload),
    onError: () => onErrorOpenToast('Não foi possível criar a cotação!'),
  })
}
