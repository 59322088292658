import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
  margin-bottom: 4px;
  color: ${palette.grayscale[700]};

  @media screen and (min-width: 1280px) {
    font-size: 17px;
    line-height: 34px;
  }
`

export const Description = styled.p`
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: ${palette.grayscale[500]};
`
