import { Header, type IHeaderRootProps } from '@77sol-ui/organisms'
import { QuotationButton } from 'containers/QuotationButton'
import { RequestFinancingButton } from 'containers/RequestFinancingButton'
import { useHistory } from 'react-router-dom'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { HamburgerMenu } from '../components/HamburgerMenu'
import { Notification } from 'containers/Notifications'
import { useAmplitude } from 'hooks/useAmplitude'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'
import { sidebarTracker } from 'services/tracker/events/sidebar/tracker'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { triggerTypes } from 'services/tracker/properties/triggerType'
import logo from 'assets/img/logo-77sol-new.svg'

export function Tablet({ ...props }: Omit<IHeaderRootProps, 'children'>) {
  const history = useHistory()

  const { logEvent } = useAmplitude()

  const setOpenSidebar = useSidebarMobileStore((state) => state.setIsOpen)

  function handleOpenedNotifications() {
    logEvent(notificationsTracker.actions.openNotifications, {
      origin: 'header',
      type: 'Button',
    })
  }

  function handleOpenSidebar() {
    logEvent(sidebarTracker.actions.expandSidebar, {
      origin: 'header',
      type: 'Button',
    })
    setOpenSidebar(true)
  }

  function handleOpenedDropdown(opened: boolean) {
    logEvent(headerTracker.eventName, {
      actions: headerTracker.actions.quoteEquipmentKey,
      origin: location.pathname,
      type: triggerTypes.dropdownMenu.trigger,
      state: opened ? 'Aberto' : 'Fechado',
    })
  }

  return (
    <Header.Root {...props}>
      <Header.Content>
        <HamburgerMenu onClick={handleOpenSidebar} />

        <Header.Logo
          onClick={() => history.push('/')}
          src={logo}
          data-testid="header-logo"
        />
      </Header.Content>

      <Header.Content data-testid="header-content">
        <QuotationButton
          text="Criar Cotação"
          onOpenedDropdown={handleOpenedDropdown}
        />
        <RequestFinancingButton />
        <Notification.Popover
          align="end"
          data-testid="header-notification"
          onOpenedSuccess={handleOpenedNotifications}
        />
      </Header.Content>
    </Header.Root>
  )
}
