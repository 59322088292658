import api from 'api'
import { ADMIN_PDF_GENERATE_QUOTATION } from '../constants/endpointKeys'

export const generatePdfQuotation = async (quotationId: number) => {
  return await api
    .get(`${ADMIN_PDF_GENERATE_QUOTATION}/${quotationId}`, {
      headers: {
        responseType: 'blob',
      },
      responseType: 'blob',
    })
    .then((response) => {
      return response.data
    })
}
