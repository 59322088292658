// import { useAmplitude } from 'hooks/useAmplitude'
// import { BannersTrackers } from 'services/tracker/events/Banners'
import * as S from './styles'

export function BannerWithImage() {
  // const { logEvent } = useAmplitude()

  // function onClickQuotationBanner() {
  //   logEvent(BannersTrackers.actions.clickOnQuotationBanner, {
  //     origin: '/cotar',
  //     banner: 'Homologação',
  //   })
  //   window.open('https://runrun.it/share/form/cPs_CLIwHgEdKMp8', '_blank')
  // }

  return (
    <S.ImageBanner>
      <picture>
        <source
          srcSet={`https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/quotation/banner_quotation_mobile.png`}
          media="(max-width: 1100px)"
        />
        <img
          src={`https://77solassets.s3.sa-east-1.amazonaws.com/banners/${process.env.REACT_APP_S3_PREFIX}/quotation/banner_quotation.png`}
          alt="Homologação"
        />
      </picture>
    </S.ImageBanner>
  )
}
