import { Radio } from '@77sol-ui/atoms'
import { Table } from '@77sol-ui/molecules'
import { useFetchProposalQuotationsAll } from 'domains/proposal/quotations_all'
import { parseBRL } from 'utils'
import { CUSTOM_TABLE_SPACING, TABLE_COLUMN_NAME } from './constants'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { CircularProgress } from 'components/CircularProgress'
import * as S from './styled'

interface ChooseQuotationByProposalTableContainerProps {
  choosedQuotation: IQuotationDTO | undefined
  setChoosedQuotation: (value: IQuotationDTO) => void
  proposalID: number
}

export const ChooseQuotationByProposalTableContainer = ({
  choosedQuotation,
  setChoosedQuotation,
  proposalID,
}: ChooseQuotationByProposalTableContainerProps) => {
  const { proposalQuotationsAllData: quotations, isLoading } =
    useFetchProposalQuotationsAll(proposalID)

  if (isLoading)
    return (
      <S.Container>
        <CircularProgress />
      </S.Container>
    )

  if (quotations.length <= 0)
    return (
      <S.Container>
        <span>Nenhum dimensionamento foi encontrado para esta proposta.</span>
      </S.Container>
    )

  return (
    <Radio.Root>
      <Radio.Group>
        <Table.Root variant="default">
          <Table.Header headerTemplateColumns={CUSTOM_TABLE_SPACING}>
            {TABLE_COLUMN_NAME.map((column) => (
              <Table.Head key={column}>{column}</Table.Head>
            ))}
          </Table.Header>
          <Table.Body>
            {quotations.map((data) => (
              <Table.Row
                selected={data === choosedQuotation}
                key={data.id}
                rowTemplateColumns={CUSTOM_TABLE_SPACING}
                rowType="table"
                onClick={() => {
                  setChoosedQuotation(data)
                }}
              >
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[0]}>
                  <Radio.Item
                    name="quotations"
                    value={String(data.id)}
                    checked={choosedQuotation?.id === data.id}
                  />
                </Table.Cell>
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[1]}>
                  {data.id}
                </Table.Cell>
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[2]}>
                  {data.potency} kWp
                </Table.Cell>
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[3]}>
                  {parseBRL(data.equipment_value)}
                </Table.Cell>
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[4]}>
                  {parseBRL(data.extra_costs)}
                </Table.Cell>
                <Table.Cell mobileHead={TABLE_COLUMN_NAME[5]}>
                  {parseBRL(data.total_value)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Radio.Group>
    </Radio.Root>
  )
}
