/* eslint-disable */ 
import React, { useState, useEffect, useRef } from 'react'
import {
  Divider, Hidden, useMediaQuery, Tooltip,
} from '@material-ui/core'
import smoothscroll from 'smoothscroll-polyfill'
import { withSize } from 'react-sizeme'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import {
  Typography, Toggle, Header, GroupButtonMobile,
} from 'components'
import {
  SendIcon,
  FullScreenExpandResizeIcon,
  AutoLayoutIcon,
  LockIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from '@77sol/icons/dist'
import {
  Button, TabList, Paper, Spacer,
} from '@77sol/core'
import palette from 'app_palette'
import {
  initial,
  initialPdf,
  initialRetornoSubCards,
  initialTirVplContainers,
  initialInvesting,
  initialInvestingSections,
  initialEqupmentsSections,
  initialPaymentOptionsContainers,
  initialMoreInfosContainers,
  initialPaymentOptionsSections,
} from './initialValues'
import { PdfTab, DigitalProposalTab, ProposalPopover } from './components'
import API from '../../../../../../api'
import { useIsMount } from './useIsMount'
import { SaveDefaultComponent } from '..';
import { useSelector } from 'react-redux'
import { ProposalShareModal } from 'containers/Modals/ProposalShareModal'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const reorder = (list, startIndex, endIndex, direction) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function ProposalStructurer({    
  setPropostaStep,
  proposal,
  isFromProposalList,
  size,
  setIsProPlanModalOpen,
  isUserProPlan,
  checkPdfDragAndDrop,
  checkDragAndDrop,
  getMoreClients,
  hasMoreClients,
  setFilterClientName,
  filterClientName,
  resetFieldSearch,
  clients,
  getFirstPageClients
}) {
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)
  const downLg = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const {logEvent} = useAmplitude()
  const isMount = useIsMount()
  const tokenRegister = localStorage.getItem('sessionToken')

  const buttonRef = useRef(null)

  const [modalInfoFluxo, setModalInfoFLuxo] = useState(false)
  const [valueTab, setValueTab] = useState(0)
  const [isEditingSections, setIsEditingSections] = useState(false)
  const isEditingSectionsExpanded = true
  const [editClient, setEditClient] = useState(false)
  const [editClientExpanded, setEditClientExpanded] = useState(false)
  const [editSizingExpanded, setEditSizingExpanded] = useState(false)
  const [openSaveModalDefaultConfig, setOpenSaveModalDefaultConfig] = useState(false);

  const [sizingActivePanel, setSizingActivePanel] = useState('all')
  const sizingEdit = proposal.sizings[0]
  const [selectedQuote, setSelectedQuote] = useState(
    proposal?.quotes[0]?.quotation.id,
  )

  const [isViewing, setIsViewing] = useState('')
  const [editMode, setEditMode] = useState('')

  const [quoteEdit, setQuoteEdit] = useState({})
  const [sendModalStepFour, setSendModalStepFour] = useState(false)

  const { userName } = proposal
  const logo = proposal.proposal.logo ? proposal.proposal.logo : ''
  const backgroundImage = proposal?.extraConfigs?.cover_image?.data?.url
    ? proposal?.extraConfigs?.cover_image?.data?.url
    : ''
  const colors = proposal.extraConfigs.color_configs
    ? proposal.extraConfigs.color_configs
    : ''

  const logTabEvent = (tab) => {
    const tabs = ['Proposta virtual','PDF']
    logEvent(proposalTracker.actions.editProposalTab, { tab: tabs[tab] })
  }
  
  const handleChange = (event, newValue) => {
    logTabEvent(event)
    setValueTab(event)
  }

  const [state, setState] = useStateWithCallbackLazy({
    containers: initial,
  })

  const [stateInvesting, setStateInvesting] = useStateWithCallbackLazy({
    containers: initialInvesting,
  })

  const [
    stateInvestingSections,
    setStateInvestingSections,
  ] = useStateWithCallbackLazy({
    containers: initialInvestingSections,
  })

  const [
    stateEquipmentsSections,
    setStateEquipmentsSections,
  ] = useStateWithCallbackLazy({
    containers: initialEqupmentsSections,
  })

  const [
    statePaymentSections,
    setStatePaymentSections,
  ] = useStateWithCallbackLazy({
    containers: initialPaymentOptionsSections,
  })

  const [
    ProposalInfosSections,
    setProposalInfosSections,
  ] = useStateWithCallbackLazy({
    containers: [],
  })

  const [
    ProposalSubInfosSections,
    setProposalSubInfosSections,
  ] = useStateWithCallbackLazy({
    containers: [],
  })

  const [
    paymentOptionsContainers,
    setPaymentOptionsContainers,
  ] = useStateWithCallbackLazy({
    containers: initialPaymentOptionsContainers,
  })

  const [
    moreInfosContainers,
    setMoreInfosContainers,
  ] = useStateWithCallbackLazy({
    containers: initialMoreInfosContainers,
  })

  const [tirVplContainers, setTirVplContainers] = useStateWithCallbackLazy({
    containers: initialTirVplContainers,
  })

  const [retornoSubCards, setRetornoSubCards] = useStateWithCallbackLazy({
    containers: initialRetornoSubCards,
  })

  const [signatureState, setSignatureState] = useStateWithCallbackLazy({
    id: 'signature',
    content: 'item-signature',
    display: 'Assinatura',
    visible: true,
  })

  const [getDone, setGetDone] = useState(false)
  const [getDonePdf, setGetDonePdf] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(true)

  const [statePdf, setStatePdf] = useStateWithCallbackLazy({
    containers: initialPdf,
  })

  const [stateQuotesPdf, setStateQuotesPdf] = useStateWithCallbackLazy([])

  function handleOpenSendProposalModal() {
    logEvent(proposalTracker.actions.openModalSavedConfigs, {
      origin: '/dimensionar - Proposta virtual'
    })
    
    setSendModalStepFour(true)
  }

  function onDragEndPdf(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    if (result.type === 'droppableItemPdf') {
      const containers = reorder(
        statePdf.containers,
        result.source.index,
        result.destination.index,
      )
      setStatePdf({ containers })
    }

    if (result.type === 'droppableQuotePdf') {
      const containers = reorder(
        stateQuotesPdf.containers,
        result.source.index,
        result.destination.index,
      )
      setStateQuotesPdf({ containers })
    }
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    if (result.type === 'droppableItem') {
      const containers = reorder(
        state.containers,
        result.source.index,
        result.destination.index,
      )
      setState({ containers })
    }

    if (result.type === 'droppableSubItemInvesting') {
      const containers = reorder(
        stateInvesting.containers,
        result.source.index,
        result.destination.index,
      )
      setStateInvesting({ containers })
    }

    if (result.type === 'droppableSubItemInvestingSection') {
      const containers = reorder(
        stateInvestingSections.containers,
        result.source.index,
        result.destination.index,
      )
      setStateInvestingSections({ containers })
    }

    if (result.type === 'droppableEquipmentsSection') {
      const containers = reorder(
        stateEquipmentsSections.containers,
        result.source.index,
        result.destination.index,
      )
      setStateEquipmentsSections({ containers })
    }

    if (result.type === 'droppablePaymentsSection') {
      const containers = reorder(
        statePaymentSections.containers,
        result.source.index,
        result.destination.index,
      )
      setStatePaymentSections({ containers })
    }

    if (result.type === 'droppablePaymentOptionsContainers') {
      const containers = reorder(
        paymentOptionsContainers.containers,
        result.source.index,
        result.destination.index,
      )
      setPaymentOptionsContainers({ containers })
    }

    if (result.type === 'droppableMoreInfosContainers') {
      const containers = reorder(
        moreInfosContainers.containers,
        result.source.index,
        result.destination.index,
      )
      setMoreInfosContainers({ containers })
    }

    if (result.type === 'droppableMoreInfosContainers') {
      const containers = reorder(
        moreInfosContainers.containers,
        result.source.index,
        result.destination.index,
      )
      setMoreInfosContainers({ containers })
    }

    if (result.type === 'droppableTirVplContainers') {
      const containers = reorder(
        tirVplContainers.containers,
        result.source.index,
        result.destination.index,
      )
      setTirVplContainers({ containers })
    }

    if (result.type === 'droppableRetornoSubCards') {
      const containers = reorder(
        retornoSubCards.containers,
        result.source.index,
        result.destination.index,
      )
      setRetornoSubCards({ containers })
    }
  }

  const [, setPaymentsMethods] = useState([])
  const [, setBilletNumber] = useState()

  const getPayments = () => {
    API.get(`/proposal/quotation/payment_forms/${selectedQuote}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        const { methods } = res.data
        if (methods.length == 0) {
        } else {
          setPaymentsMethods(methods)
          if (methods.billet.billet_one) {
            setBilletNumber(1)
          }
          if (methods.billet.billet_two) {
            setBilletNumber(2)
          }
          if (methods.billet.billet_three) {
            setBilletNumber(3)
          }
          if (methods.billet.billet_four) {
            setBilletNumber(4)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getPayments()
  }, [selectedQuote])

  useEffect(() => {
    if (isMount) {
    } else if (getDonePdf) {
      const pdfProposalsContainers = [statePdf, stateQuotesPdf, signatureState]
      API.post(
        '/proposal/pdf-structure',
        {
          proposal_id: proposal.proposal.id,
          pdf_structure: pdfProposalsContainers,
        },
        {
          headers: {
            token: tokenRegister,
          },
        },
      )
        .then((res) => {})
        .catch((err) => {
          console.log(err)
        })
    }
  }, [statePdf, stateQuotesPdf, signatureState])

  const handleSaveDragAndDrop = () => {
    const ProposalsContainers = [
      state,
      stateInvesting,
      stateInvestingSections,
      stateEquipmentsSections,
      statePaymentSections,
      paymentOptionsContainers,
      moreInfosContainers,
      tirVplContainers,
      retornoSubCards,
    ]

    API.post(
      '/proposal/drag-and-drop',
      {
        proposal_id: proposal.proposal.id,
        data: ProposalsContainers,
      },
      {
        headers: {
          token: tokenRegister,
        },
      },
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (isMount) {
    } else if (getDone) {
      handleSaveDragAndDrop()
    }
  }, [
    state,
    stateInvesting,
    stateInvestingSections,
    stateEquipmentsSections,
    statePaymentSections,
    ProposalInfosSections,
    ProposalSubInfosSections,
    paymentOptionsContainers,
    moreInfosContainers,
    tirVplContainers,
    retornoSubCards,
  ])

  useEffect(() => {
    if (proposal.quotes.length != stateQuotesPdf.length) {
      updatePdfStructure()
    }
  }, [proposal])

  const updatePdfStructure = () => {
    API.get(`/proposal/pdf-structure/${proposal.proposal.id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        if (res.data[0]) {
          setStatePdf(res.data[0])
          setStateQuotesPdf(res.data[1])
          const pdfItems = res.data[0].containers
          const quotesItemsInitial = res.data[1].length
          const quotesItems = res.data[1]

          if (res.data[1].length != proposal.quotes.length) {
            const newQuote = proposal.quotes.filter(
              (quote, i) => i > quotesItems.length - 1,
            )
            const pdfItems2 = pdfItems.splice(
              pdfItems.findIndex(
                (item) => item.id == `QuotePdf${quotesItems.length - 1}`,
              ) + 1,
            )
            const newItems = []

            newQuote.map((item, i) => {
              newItems.push({
                id: `QuotePdf${quotesItemsInitial + i}`,
                content: `item-quotePdf${quotesItemsInitial + i}`,
                display: `Cotação ${quotesItemsInitial + i + 1}`,
                visible: true,
              })
              quotesItems.push([
                {
                  id: `returnQuote${quotesItemsInitial + i}`,
                  content: `item-clientPdf${quotesItemsInitial + i}`,
                  display: 'Retorno de investimento',
                  visible: true,
                },
                {
                  id: `quoteData${quotesItemsInitial + i}`,
                  content: `item-quoteData${quotesItemsInitial + i}`,
                  display: 'Dados do projeto',
                  visible: true,
                },
                {
                  id: `productionQuote${quotesItemsInitial + i}`,
                  content: `item-productionQuote${quotesItemsInitial + i}`,
                  display: 'Produção e consumo',
                  visible: true,
                },
                {
                  id: `paymentQuote${quotesItemsInitial + i}`,
                  content: `item-paymentQuote${quotesItemsInitial + i}`,
                  display: 'Formas de pagamento',
                  visible: true,
                },
              ])
            })
            const finalArray2 = [...newItems, ...pdfItems2]
            const newArray = [...pdfItems, ...finalArray2]
            setStatePdf({ containers: newArray })
            setStateQuotesPdf([...quotesItems])
          }

          if (res.data[2]) {
            setSignatureState(res.data[2])
          }

          setTimeout(() => {
            setGetDonePdf(true)
            setLoadingPdf(false)
          }, 100)
        } else {
          setTimeout(() => {
            setGetDonePdf(true)
            setLoadingPdf(false)
          }, 100)
        }
      })
      .catch((res) => {})
  }

  const [documentUsername, setDocumentUsername] = useState()

  useEffect(() => {
    if (userLogged) {
      setDocumentUsername(
        userLogged[0].cnpj ? userLogged[0].cnpj : userLogged[0].cpfResponsavel,
      )
    }

  }, [0])

  const updateStructure = () => {
    API.get(`/proposal/drag-and-drop/${proposal.proposal.id}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        if (res.data[0]) {
          setState(res.data[0])
          setStateInvesting(res.data[1])
          setStateInvestingSections(res.data[2])
          setStateEquipmentsSections(res.data[3])
          setStatePaymentSections(res.data[4])
          setPaymentOptionsContainers(res.data[5])
          setMoreInfosContainers(res.data[6])
          setTirVplContainers(res.data[7])
          setRetornoSubCards(res.data[8])
          setTimeout(() => {
            setGetDone(true)
          }, 100)
        } else {
          setGetDone(true)
        }
      })
      .catch((res) => {})
  }

  useEffect(() => {
    updatePdfStructure()
    updateStructure()
  }, [0])

  const handleTitlePdfContainer = (containerId) => {
    if (containerId.toString().includes('QuotePdf')) {
      return statePdf.containers.find((item) => item.id == containerId).display
    }
    if (containerId == 'LinkPdf') {
      return 'Link da proposta'
    }
    if (containerId == 'SchedulesPdf') {
      return 'Cronograma'
    }
    if (containerId == 'ClientPdf') {
      return 'Introdução'
    }
    if (Number.isInteger(containerId)) {
      return statePdf.containers
        .find((item) => item.id == containerId)
        .display.split('/')[1]
        ? (statePdf.containers
          .find((item) => item.id == containerId)
          .display.split('/')[0] == 'covers'
            && `${`${'Capa'
              + ' ('}${
              statePdf.containers
                ?.filter((item) => item.display.split('/')[0] == 'covers')
                .findIndex((item) => item.id == containerId)
                + 1}`})`)
            || (statePdf.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'explanations'
              && `${`${'Explicações'
                + ' ('}${
                statePdf.containers
                  ?.filter((item) => item.display.split('/')[0] == 'explanations')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || (statePdf.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'benefits'
              && `${`${'Vantagens'
                + ' ('}${
                statePdf.containers
                  ?.filter((item) => item.display.split('/')[0] == 'benefits')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || (statePdf.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'partners'
              && `${`${'Parceiros'
                + ' ('}${
                statePdf.containers
                  ?.filter((item) => item.display.split('/')[0] == 'partners')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || statePdf.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0]
        : statePdf.containers.find((item) => item.id == containerId).display
    }
  }

  const handleTitleContainer = (containerId) => {
    if (containerId == 'investingContainer') {
      return 'Retorno de investimento'
    }
    if (containerId == 'equipmentsContainer') {
      return 'Dados do projeto'
    }
    if (containerId == 'paymentsOptionsContainer') {
      return 'Financiamento e pagamento'
    }
    if (containerId == 'InfosContainer') {
      return 'Informações extras'
    }
    if (containerId == 'scheduleCard') {
      return 'Cronograma'
    }
    if (containerId == 'constructionImagesCard') {
      return 'Imagens de obras'
    }
    if (Number.isInteger(containerId)) {
      return state.containers
        .find((item) => item.id == containerId)
        .display.split('/')[1]
        ? (state.containers
          .find((item) => item.id == containerId)
          .display.split('/')[0] == 'covers'
            && `${`${'Capa'
              + ' ('}${
              state.containers
                ?.filter((item) => item.display.split('/')[0] == 'covers')
                .findIndex((item) => item.id == containerId)
                + 1}`})`)
            || (state.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'explanations'
              && `${`${'Explicações'
                + ' ('}${
                state.containers
                  ?.filter((item) => item.display.split('/')[0] == 'explanations')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || (state.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'benefits'
              && `${`${'Vantagens'
                + ' ('}${
                state.containers
                  ?.filter((item) => item.display.split('/')[0] == 'benefits')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || (state.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0] == 'partners'
              && `${`${'Parceiros'
                + ' ('}${
                state.containers
                  ?.filter((item) => item.display.split('/')[0] == 'partners')
                  .findIndex((item) => item.id == containerId)
                  + 1}`})`)
            || state.containers
              .find((item) => item.id == containerId)
              .display.split('/')[0]
        : state.containers.find((item) => item.id == containerId).display
    }
  }

  function handleBackSlide(){
    if (downSm) {
      if (tabMobile == 0) {
        setPropostaStep('fullscreen')
      } else {
        setPropostaStep('fullscreenPdf')
      }
    } else {
      setPropostaStep('configsExtras')
    }
  }

  const [tabMobile, setTabMobile] = useState(0)


  return (
    <>
      {!isViewing && (
        <Header
          noMargin
          sticky={downSm}
          style={{ zIndex: 40 }}
          onBack={() => setOpenSaveModalDefaultConfig(true)}
          title={(
            <Typography
              style={{ padding: '7.5px 0px' }}
              type={downLg ? 'text_medium' : 'text_large'}
              color={downSm ? 'white' : 'grayscale'}
              colorWeight="700"
            >
              {downLg ? 'Editar proposta' : 'Estruture sua proposta'}
            </Typography>
          )}
        >
          {downSm ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                right: '24px',
              }}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
              }}
            >
              <TabList
                value={valueTab}
                onChange={getDonePdf && handleChange}
                variant="scrollable"
                scrollButtons="auto"
                titles={
                  loadingPdf
                    ? ['Proposta virtual']
                    : ['Proposta virtual', 'PDF']
                }
              />
              {isFromProposalList ? (
                <>
                  <Spacer size="42" direction="vertical" />
                  <Tooltip title="Seções ocultadas">
                    <div ref={buttonRef}>
                      <AutoLayoutIcon
                        width="24"
                        color={palette.grayscale[700]}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setIsViewing('')
                          setEditMode('')
                          isEditingSections
                            ? setIsEditingSections(false)
                            : setIsEditingSections(true)
                        }}
                      />
                    </div>
                  </Tooltip>
                  <Spacer size="24" direction="vertical" />
                  <Tooltip title="Ver em tela cheia">
                    <div>
                      <FullScreenExpandResizeIcon
                        width="24"
                        color={palette.grayscale[700]}
                        style={{ cursor: 'pointer' }}
                        onClick={() => (valueTab == 0
                          ? setPropostaStep('fullscreen')
                          : setPropostaStep('fullscreenPdf'))}
                      />
                    </div>
                  </Tooltip>
                  <Spacer size="24" direction="vertical" />
                  <Tooltip title="Enviar">
                    <div>
                      <SendIcon
                        width="24"
                        color={palette.grayscale[700]}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpenSendProposalModal()}
                      />
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Spacer size="16" direction="vertical" />
                  <div ref={buttonRef}>
                    <Button
                      onClick={(e) => {
                        setIsViewing('')
                        setEditMode('')
                        isEditingSections
                          ? setIsEditingSections(false)
                          : setIsEditingSections(true)
                      }}
                      noMargin
                      size="small"
                      variant="outlined"
                      startIcon={<AutoLayoutIcon />}
                    >
                      Seções ocultadas
                    </Button>
                  </div>
                  <Spacer size="16" direction="vertical" />
                  <Button
                    onClick={() => (valueTab == 0
                      ? setPropostaStep('fullscreen')
                      : setPropostaStep('fullscreenPdf'))}
                    size="small"
                    variant="outlined"
                    startIcon={<FullScreenExpandResizeIcon />}
                    noMargin
                  >
                    Ver em tela cheia
                  </Button>
                  <Spacer size="16" direction="vertical" />
                  <Button
                    onClick={() => handleOpenSendProposalModal()}
                    noMargin
                    size="small"
                    startIcon={<SendIcon />}
                  >
                    Enviar
                  </Button>
                </>
              )}
            </div>
          )}
        </Header>
      )}
      <Hidden smDown>
        <ProposalPopover
          title="Seções ocultadas"
          anchorRef={buttonRef.current}
          value={isEditingSections}
          setValue={setIsEditingSections}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isEditingSectionsExpanded && valueTab == 0 && (
            <div>
              <Spacer size="16" direction="horizontal" />
              <Paper
                style={{
                  backgroundColor: '#647381',
                  color: '#FFF',
                  textAlign: 'center',
                  padding: '4px',
                  borderRadius: '8px',
                }}
              >
                <Typography type="link_small" color="white">
                  Visualização
                </Typography>
              </Paper>
              <Spacer size="16" direction="horizontal" />
              {state.containers.map((option, i) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    minWidth: '368px',
                  }}
                >
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="700"
                  >
                    {option.display[1]
                      ? (option.display.split('/')[0] == 'covers'
                          && `${`${'Capa'
                            + ' ('}${
                            state.containers
                              ?.filter(
                                (item) => item.display.split('/')[0] == 'covers',
                              )
                              .findIndex((item) => item.id == option.id)
                              + 1}`})`)
                        || (option.display?.split('/')[0] == 'explanations'
                          && `${`${'Explicações'
                            + ' ('}${
                            state.containers
                              ?.filter(
                                (item) => item.display.split('/')[0] == 'explanations',
                              )
                              .findIndex((item) => item.id == option.id)
                              + 1}`})`)
                        || (option.display?.split('/')[0] == 'benefits'
                          && `${`${'Vantagens'
                            + ' ('}${
                            state.containers
                              ?.filter(
                                (item) => item.display.split('/')[0] == 'benefits',
                              )
                              .findIndex((item) => item.id == option.id)
                              + 1}`})`)
                        || (option.display?.split('/')[0] == 'partners'
                          && `${`${'Parceiros'
                            + ' ('}${
                            state.containers
                              ?.filter(
                                (item) => item.display.split('/')[0] == 'partners',
                              )
                              .findIndex((item) => item.id == option.id)
                              + 1}`})`)
                        || option.display
                      : option.display}
                  </Typography>
                  {option.id == 'paymentsOptionsContainer' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '48px',
                      }}
                    >
                      <LockIcon width="24" color={palette.grayscale[700]} />
                    </div>
                  ) : (
                    <Toggle
                      checked={option.visible}
                      onChange={() => {
                        if (
                          !isUserProPlan
                          && (option.id == 'scheduleCard'
                            || option.id == 'constructionImagesCard')
                        ) {
                          setIsEditingSections(false)
                          return setIsProPlanModalOpen(true)
                        }
                        const newArr = [...state.containers]
                        newArr[i].visible = !newArr[i].visible
                        setState({ containers: newArr })
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {isEditingSectionsExpanded && valueTab == 1 && (
            <div>
              <Spacer size="16" direction="horizontal" />
              <Paper
                style={{
                  backgroundColor: '#647381',
                  color: '#FFF',
                  textAlign: 'center',
                  padding: '4px',
                  borderRadius: '8px',
                }}
              >
                <Typography type="link_small" color="white">
                  Visualização
                </Typography>
              </Paper>
              <Spacer size="16" direction="horizontal" />
              {statePdf.containers.map((option, i) => (option.id == 'SchedulesPdf' ? (
                ''
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    minWidth: '328px',
                  }}
                >
                  <Typography
                    type="text_small"
                    color="grayscale"
                    colorWeight="700"
                  >
                    {option.display[1]
                      ? (option.display.split('/')[0] == 'covers'
                            && `${`${'Capa'
                              + ' ('}${
                              statePdf.containers
                                ?.filter(
                                  (item) => item.display.split('/')[0] == 'covers',
                                )
                                .findIndex((item) => item.id == option.id)
                                + 1}`})`)
                          || (option.display?.split('/')[0] == 'explanations'
                            && `${`${'Explicações'
                              + ' ('}${
                              statePdf.containers
                                ?.filter(
                                  (item) => item.display.split('/')[0]
                                    == 'explanations',
                                )
                                .findIndex((item) => item.id == option.id)
                                + 1}`})`)
                          || (option.display?.split('/')[0] == 'benefits'
                            && `${`${'Vantagens'
                              + ' ('}${
                              statePdf.containers
                                ?.filter(
                                  (item) => item.display.split('/')[0] == 'benefits',
                                )
                                .findIndex((item) => item.id == option.id)
                                + 1}`})`)
                          || (option.display?.split('/')[0] == 'partners'
                            && `${`${'Parceiros'
                              + ' ('}${
                              statePdf.containers
                                ?.filter(
                                  (item) => item.display.split('/')[0] == 'partners',
                                )
                                .findIndex((item) => item.id == option.id)
                                + 1}`})`)
                          || option.display
                      : option.display}
                  </Typography>
                  <Toggle
                    checked={option.visible}
                    onChange={() => {
                      if (!isUserProPlan && option.id == 'SchedulesPdf') {
                        setIsEditingSections(false)
                        return setIsProPlanModalOpen(true)
                      }
                      const newArr = [...statePdf.containers]
                      newArr[i].visible = !newArr[i].visible
                      setStatePdf({ containers: newArr })
                    }}
                  />
                </div>
              )))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px 16px',
                  minWidth: '328px',
                }}
              >
                <Typography
                  type="text_small"
                  color="grayscale"
                  colorWeight="700"
                >
                  {signatureState.display}
                </Typography>
                <Toggle
                  checked={signatureState.visible}
                  onChange={() => {
                    const newObj = signatureState
                    newObj.visible = !newObj.visible
                    setSignatureState({ ...newObj })
                  }}
                />
              </div>
            </div>
          )}
        </ProposalPopover>
        <div
          style={{
            height: isFromProposalList
              ? isViewing
                ? '100%'
                : 'calc(var(--app-height) - 180px)'
              : isViewing
                ? '100%'
                : 'calc(100% - 102px)',
            overflowY: 'auto',
            top: '0px',
            position: isViewing ? 'absolute' : 'relative',
          }}
        >
          {valueTab == 0 && (
            <DigitalProposalTab
              sizingEdit={sizingEdit}
              setState={setState}
              investingSections={stateInvestingSections}
              setStateInvesting={setStateInvesting}
              investingContainers={stateInvesting}
              setStateInvestingSections={setStateInvestingSections}
              stateEquipmentsSections={stateEquipmentsSections}
              setStateEquipmentsSections={setStateEquipmentsSections}
              statePaymentSections={statePaymentSections}
              setStatePaymentSections={setStatePaymentSections}
              ProposalInfosSections={ProposalInfosSections}
              setProposalInfosSections={setProposalInfosSections}
              containers={state}
              selectedQuote={selectedQuote}
              isViewing={isViewing}
              setIsViewing={setIsViewing}
              editMode={editMode}
              setEditMode={setEditMode}
              proposal={proposal}
              size={size}
              paymentOptionsContainers={paymentOptionsContainers}
              setPaymentOptionsContainers={setPaymentOptionsContainers}
              moreInfosContainers={moreInfosContainers}
              setMoreInfosContainers={setMoreInfosContainers}
              ProposalSubInfosSections={ProposalSubInfosSections}
              setProposalSubInfosSections={setProposalSubInfosSections}
              tirVplContainers={tirVplContainers}
              setTirVplContainers={setTirVplContainers}
              retornoSubCards={retornoSubCards}
              setRetornoSubCards={setRetornoSubCards}
              backgroundImage={backgroundImage}
              logo={logo}
              editClientExpanded={editClientExpanded}
              setEditClientExpanded={setEditClientExpanded}
              setEditClient={setEditClient}
              editClient={editClient}
              editSizingExpanded={editSizingExpanded}
              setEditSizingExpanded={setEditSizingExpanded}
              sizingActivePanel={sizingActivePanel}
              setSizingActivePanel={setSizingActivePanel}
              quoteEdit={quoteEdit}
              setQuoteEdit={setQuoteEdit}
              setSelectedQuote={setSelectedQuote}
              onDragEnd={onDragEnd}
              stateInvestingSections={stateInvestingSections}
              stateInvesting={stateInvesting}
              state={state}
              modalInfoFluxo={modalInfoFluxo}
              setModalInfoFLuxo={setModalInfoFLuxo}
              colors={colors}
              isFromProposalList={isFromProposalList}
              setIsProPlanModalOpen={setIsProPlanModalOpen}
              isUserProPlan={isUserProPlan}
              checkDragAndDrop={checkDragAndDrop}
              checkPdfDragAndDrop={checkPdfDragAndDrop}
              updatePdfStructure={updatePdfStructure}
              updateStructure={updateStructure}
              getMoreClients={getMoreClients}
              hasMoreClients={hasMoreClients}
              setFilterClientName={setFilterClientName}
              filterClientName={filterClientName}
              resetFieldSearch={resetFieldSearch}
              clients={clients}
              getFirstPageClients={getFirstPageClients}
            />
          )}
          {valueTab == 1 && (
            <PdfTab
              logo={logo}
              onDragEndPdf={onDragEndPdf}
              statePdf={statePdf}
              proposal={proposal}
              userName={userName}
              setStatePdf={setStatePdf}
              stateQuotesPdf={stateQuotesPdf}
              setStateQuotesPdf={setStateQuotesPdf}
              checkPdfDragAndDrop={checkPdfDragAndDrop}
              checkDragAndDrop={checkDragAndDrop}
              updatePdfStructure={updatePdfStructure}
              updateStructure={updateStructure}
              documentUsername={documentUsername}
              signatureState={signatureState}
              setSignatureState={setSignatureState}
            />
          )}
        </div>
      </Hidden>
      <Hidden mdUp>
        <div style={{ padding: '24px' }}>
          <Typography type="link_small" color="grayscale" colorWeight="800">
            Edite do seu jeito
          </Typography>
          <Typography type="text_x_small" color="grayscale" colorWeight="600">
            Escolha os tópicos que deseja exibir na proposta e ordene-os como
            quiser
          </Typography>
          <Spacer size="16" direction="horizontal" />
          <GroupButtonMobile
            value={tabMobile}
            setValue={value => {
              setTabMobile(value)
              logTabEvent(value)
            }}
            labels={['Proposta virtual', 'PDF']}
          />
          <Spacer size="16" direction="horizontal" />
          {tabMobile == 0 && (
            <>
              {[...state.containers].map((container, index) => (
                <div key={container.id}>
                  <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {container.id == 'paymentsOptionsContainer' ? (
                          <LockIcon width="24" color={palette.grayscale[300]} />
                        ) : (
                          <Toggle
                            checked={container.visible}
                            setChecked={() => {}}
                            onClick={() => {
                              if (
                                !isUserProPlan
                                && (container.id == 'scheduleCard'
                                  || container.id == 'constructionImagesCard')
                              ) {
                                setIsProPlanModalOpen(true)
                              } else if (
                                state.containers.findIndex(
                                  (item) => item.id == container.id,
                                ) != -1
                              ) {
                                const newArr = [...state.containers]
                                const indexinfo = newArr.findIndex(
                                  (item) => item.id == container.id,
                                )
                                newArr[indexinfo].visible = !newArr[indexinfo]
                                  .visible
                                setState({ containers: [...newArr] })
                              }
                            }}
                          />
                        )}
                        <Spacer size="16" direction="vertical" />
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {handleTitleContainer(container.id)}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {index != 0 && state.containers[0].id != container.id && (
                          <ArrowUpIcon
                            width="24"
                            color={palette.grayscale[800]}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (index != 0) {
                                const containers = reorder(
                                  state.containers,
                                  index,
                                  index - 1,
                                  'up',
                                )
                                setState({ containers: [...containers] })
                              }
                            }}
                          />
                        )}
                        {index != state.containers.length - 1
                          && [...state.containers].reverse()[0].id
                            != container.id && (
                            <>
                              <Spacer size="16" direction="vertical" />
                              <ArrowDownIcon
                                width="24"
                                color={palette.grayscale[800]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (index != state.containers.length - 1) {
                                    const containers = reorder(
                                      state.containers,
                                      index,
                                      index + 1,
                                      'down',
                                    )
                                    setState({ containers: [...containers] })
                                  }
                                }}
                              />
                            </>
                        )}
                      </div>
                    </div>
                    {(container.id == 'investingContainer'
                      || container.id == 'equipmentsContainer'
                      || container.id == 'paymentsOptionsContainer') && (
                      <>
                        <Spacer size="24" direction="horizontal" />
                        <Divider
                          style={{
                            height: '2px',
                            backgroundColor: palette.grayscale[300],
                          }}
                        />
                        <Spacer size="24" direction="horizontal" />
                      </>
                    )}
                    {container.id == 'investingContainer' && (
                      <div>
                        {stateInvestingSections.containers.map(
                          (containerSec, indexSec) => (
                            <>
                              {containerSec.id == 'allCards' && (
                                <div
                                  style={{
                                    paddingLeft: '8px',
                                    borderLeft: `2px solid ${palette.grayscale[300]}`,
                                    borderRadius: '8px',
                                  }}
                                >
                                  {[...stateInvesting.containers].map(
                                    (containerInv, indexInv) => (
                                      <>
                                        {containerInv.id == 'retornoSubCards'
                                        || containerInv.id
                                          == 'tirVplContainers' ? (
                                            <>
                                              {containerInv.id
                                              == 'tirVplContainers' && (
                                              <div
                                                style={{
                                                  paddingLeft: '8px',
                                                  borderLeft: `2px solid ${palette.grayscale[300]}`,
                                                  marginTop: '8px',
                                                  borderRadius: '8px',
                                                }}
                                              >
                                                {tirVplContainers.containers.map(
                                                  (containerTir, indexTir) => (
                                                    <div
                                                      key={containerTir.id}
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'space-between',
                                                        padding: '12px 0px',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <Toggle
                                                          checked={
                                                            containerTir.visible
                                                          }
                                                          setChecked={() => {}}
                                                          onClick={() => {
                                                            if (
                                                              tirVplContainers.containers.findIndex(
                                                                (item) => item.id
                                                                  == containerTir.id,
                                                              ) != -1
                                                            ) {
                                                              const newArrInfo = [
                                                                ...tirVplContainers.containers,
                                                              ]
                                                              const indexinfo = newArrInfo.findIndex(
                                                                (item) => item.id
                                                                  == containerTir.id,
                                                              )
                                                              newArrInfo[
                                                                indexinfo
                                                              ].visible = !newArrInfo[
                                                                indexinfo
                                                              ].visible
                                                              setTirVplContainers(
                                                                {
                                                                  containers: [
                                                                    ...newArrInfo,
                                                                  ],
                                                                },
                                                              )
                                                            }

                                                            if (
                                                              tirVplContainers.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 0
                                                              && retornoSubCards.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 0
                                                              && stateInvesting.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 2
                                                              && stateInvestingSections.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 1
                                                            ) {
                                                              const newArr = [
                                                                ...state.containers,
                                                              ]
                                                              const indexinfo = newArr.findIndex(
                                                                (item) => item.id
                                                                  == 'investingContainer',
                                                              )
                                                              newArr[
                                                                indexinfo
                                                              ].visible = false
                                                              setState({
                                                                containers: [
                                                                  ...newArr,
                                                                ],
                                                              })
                                                            }
                                                          }}
                                                        />
                                                        <Spacer
                                                          size="16"
                                                          direction="vertical"
                                                        />
                                                        <Typography
                                                          type="text_x_small"
                                                          color="grayscale"
                                                          colorWeight="600"
                                                        >
                                                          {containerTir.display}
                                                        </Typography>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        {indexTir != 0
                                                          && tirVplContainers
                                                            .containers[0].id
                                                            != containerTir.id && (
                                                            <ArrowUpIcon
                                                              width="24"
                                                              color={
                                                                palette
                                                                  .grayscale[800]
                                                              }
                                                              style={{
                                                                cursor:
                                                                  'pointer',
                                                              }}
                                                              onClick={() => {
                                                                const containers = reorder(
                                                                  tirVplContainers.containers,
                                                                  indexTir,
                                                                  indexTir - 1,
                                                                  'up',
                                                                )
                                                                setTirVplContainers(
                                                                  {
                                                                    containers: [
                                                                      ...containers,
                                                                    ],
                                                                  },
                                                                )
                                                              }}
                                                            />
                                                        )}
                                                        {indexTir
                                                          != tirVplContainers
                                                            .containers.length
                                                            - 1
                                                          && [
                                                            ...tirVplContainers.containers,
                                                          ].reverse()[0].id
                                                            != containerTir.id && (
                                                            <>
                                                              <Spacer
                                                                size="16"
                                                                direction="vertical"
                                                              />
                                                              <ArrowDownIcon
                                                                width="24"
                                                                color={
                                                                  palette
                                                                    .grayscale[800]
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                                onClick={() => {
                                                                  const containers = reorder(
                                                                    tirVplContainers.containers,
                                                                    indexTir,
                                                                    indexTir
                                                                      + 1,
                                                                    'down',
                                                                  )
                                                                  setTirVplContainers(
                                                                    {
                                                                      containers: [
                                                                        ...containers,
                                                                      ],
                                                                    },
                                                                  )
                                                                }}
                                                              />
                                                            </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ),
                                                )}
                                              </div>
                                              )}
                                              {containerInv.id
                                              == 'retornoSubCards' && (
                                              <div
                                                style={{
                                                  paddingLeft: '8px',
                                                  borderLeft: `2px solid ${palette.grayscale[300]}`,
                                                  marginTop: '8px',
                                                  borderRadius: '8px',
                                                }}
                                              >
                                                {retornoSubCards.containers.map(
                                                  (
                                                    containerSubCards,
                                                    indexSubCards,
                                                  ) => (
                                                    <div
                                                      key={containerSubCards.id}
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                          'space-between',
                                                        padding: '12px 0px',
                                                        alignItems: 'center',
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        <Toggle
                                                          checked={
                                                            containerSubCards.visible
                                                          }
                                                          setChecked={() => {}}
                                                          onClick={() => {
                                                            if (
                                                              retornoSubCards.containers.findIndex(
                                                                (item) => item.id
                                                                  == containerSubCards.id,
                                                              ) != -1
                                                            ) {
                                                              const newArrInfo = [
                                                                ...retornoSubCards.containers,
                                                              ]
                                                              const indexinfo = newArrInfo.findIndex(
                                                                (item) => item.id
                                                                  == containerSubCards.id,
                                                              )
                                                              newArrInfo[
                                                                indexinfo
                                                              ].visible = !newArrInfo[
                                                                indexinfo
                                                              ].visible
                                                              setRetornoSubCards(
                                                                {
                                                                  containers: [
                                                                    ...newArrInfo,
                                                                  ],
                                                                },
                                                              )
                                                            }

                                                            if (
                                                              tirVplContainers.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 0
                                                              && retornoSubCards.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 0
                                                              && stateInvesting.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 2
                                                              && stateInvestingSections.containers.filter(
                                                                (item) => item.visible
                                                                  == true,
                                                              ).length == 1
                                                            ) {
                                                              const newArr = [
                                                                ...state.containers,
                                                              ]
                                                              const indexinfo = newArr.findIndex(
                                                                (item) => item.id
                                                                  == 'investingContainer',
                                                              )
                                                              newArr[
                                                                indexinfo
                                                              ].visible = false
                                                              setState({
                                                                containers: [
                                                                  ...newArr,
                                                                ],
                                                              })
                                                            }
                                                          }}
                                                        />
                                                        <Spacer
                                                          size="16"
                                                          direction="vertical"
                                                        />
                                                        <Typography
                                                          type="text_x_small"
                                                          color="grayscale"
                                                          colorWeight="600"
                                                        >
                                                          {
                                                            containerSubCards.display
                                                          }
                                                        </Typography>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        {indexSubCards != 0
                                                          && retornoSubCards
                                                            .containers[0].id
                                                            != containerSubCards.id && (
                                                            <ArrowUpIcon
                                                              width="24"
                                                              color={
                                                                palette
                                                                  .grayscale[800]
                                                              }
                                                              style={{
                                                                cursor:
                                                                  'pointer',
                                                              }}
                                                              onClick={() => {
                                                                const containers = reorder(
                                                                  retornoSubCards.containers,
                                                                  indexSubCards,
                                                                  indexSubCards
                                                                    - 1,
                                                                  'up',
                                                                )
                                                                setRetornoSubCards(
                                                                  {
                                                                    containers: [
                                                                      ...containers,
                                                                    ],
                                                                  },
                                                                )
                                                              }}
                                                            />
                                                        )}
                                                        {indexSubCards
                                                          != retornoSubCards
                                                            .containers.length
                                                            - 1
                                                          && [
                                                            ...retornoSubCards.containers,
                                                          ].reverse()[0].id
                                                            != containerSubCards.id && (
                                                            <>
                                                              <Spacer
                                                                size="16"
                                                                direction="vertical"
                                                              />
                                                              <ArrowDownIcon
                                                                width="24"
                                                                color={
                                                                  palette
                                                                    .grayscale[800]
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    'pointer',
                                                                }}
                                                                onClick={() => {
                                                                  const containers = reorder(
                                                                    retornoSubCards.containers,
                                                                    indexSubCards,
                                                                    indexSubCards
                                                                      + 1,
                                                                    'down',
                                                                  )
                                                                  setRetornoSubCards(
                                                                    {
                                                                      containers: [
                                                                        ...containers,
                                                                      ],
                                                                    },
                                                                  )
                                                                }}
                                                              />
                                                            </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ),
                                                )}
                                              </div>
                                              )}
                                            </>
                                          ) : (
                                            <div
                                              key={containerInv.id}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '12px 0px',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <Toggle
                                                  checked={containerInv.visible}
                                                  setChecked={() => {}}
                                                  onClick={() => {
                                                    if (
                                                      stateInvesting.containers.findIndex(
                                                        (item) => item.id
                                                        == containerInv.id,
                                                      ) != -1
                                                    ) {
                                                      const newArrInfo = [
                                                        ...stateInvesting.containers,
                                                      ]
                                                      const indexinfo = newArrInfo.findIndex(
                                                        (item) => item.id
                                                        == containerInv.id,
                                                      )
                                                      newArrInfo[
                                                        indexinfo
                                                      ].visible = !newArrInfo[
                                                        indexinfo
                                                      ].visible
                                                      setStateInvesting({
                                                        containers: [
                                                          ...newArrInfo,
                                                        ],
                                                      })
                                                    }

                                                    if (
                                                      tirVplContainers.containers.filter(
                                                        (item) => item.visible == true,
                                                      ).length == 0
                                                    && retornoSubCards.containers.filter(
                                                      (item) => item.visible == true,
                                                    ).length == 0
                                                    && stateInvesting.containers.filter(
                                                      (item) => item.visible == true,
                                                    ).length == 2
                                                    && stateInvestingSections.containers.filter(
                                                      (item) => item.visible == true,
                                                    ).length == 1
                                                    ) {
                                                      const newArr = [
                                                        ...state.containers,
                                                      ]
                                                      const indexinfo = newArr.findIndex(
                                                        (item) => item.id
                                                        == 'investingContainer',
                                                      )
                                                      newArr[
                                                        indexinfo
                                                      ].visible = false
                                                      setState({
                                                        containers: [...newArr],
                                                      })
                                                    }
                                                  }}
                                                />
                                                <Spacer
                                                  size="16"
                                                  direction="vertical"
                                                />
                                                <Typography
                                                  type="text_x_small"
                                                  color="grayscale"
                                                  colorWeight="600"
                                                >
                                                  {containerInv.display}
                                                </Typography>
                                              </div>
                                              <div style={{ display: 'flex' }}>
                                                {indexInv != 0
                                                && stateInvesting.containers[0]
                                                  .id != containerInv.id && (
                                                  <ArrowUpIcon
                                                    width="24"
                                                    color={
                                                      palette.grayscale[800]
                                                    }
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                      const containers = reorder(
                                                        stateInvesting.containers,
                                                        indexInv,
                                                        indexInv - 1,
                                                        'up',
                                                      )
                                                      setStateInvesting({
                                                        containers: [
                                                          ...containers,
                                                        ],
                                                      })
                                                    }}
                                                  />
                                                )}
                                                {indexInv
                                                != stateInvesting.containers
                                                  .length
                                                  - 1
                                                && [
                                                  ...stateInvesting.containers,
                                                ].reverse()[0].id
                                                  != containerInv.id && (
                                                  <>
                                                    <Spacer
                                                      size="16"
                                                      direction="vertical"
                                                    />
                                                    <ArrowDownIcon
                                                      width="24"
                                                      color={
                                                        palette.grayscale[800]
                                                      }
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() => {
                                                        const containers = reorder(
                                                          stateInvesting.containers,
                                                          indexInv,
                                                          indexInv + 1,
                                                          'down',
                                                        )
                                                        setStateInvesting({
                                                          containers: [
                                                            ...containers,
                                                          ],
                                                        })
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                      </>
                                    ),
                                  )}
                                </div>
                              )}
                              {containerSec.id == 'retornoGrafico' && (
                                <div
                                  key={containerSec.id}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '12px 0px',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Toggle
                                      checked={containerSec.visible}
                                      setChecked={() => {}}
                                      onClick={() => {
                                        if (
                                          stateInvestingSections.containers.findIndex(
                                            (item) => item.id == containerSec.id,
                                          ) != -1
                                        ) {
                                          const newArrInfo = [
                                            ...stateInvestingSections.containers,
                                          ]
                                          const indexinfo = newArrInfo.findIndex(
                                            (item) => item.id == containerSec.id,
                                          )
                                          newArrInfo[
                                            indexinfo
                                          ].visible = !newArrInfo[indexinfo]
                                            .visible
                                          setStateInvestingSections({
                                            containers: [...newArrInfo],
                                          })
                                        }

                                        if (
                                          tirVplContainers.containers.filter(
                                            (item) => item.visible == true,
                                          ).length == 0
                                          && retornoSubCards.containers.filter(
                                            (item) => item.visible == true,
                                          ).length == 0
                                          && stateInvestingSections.containers.filter(
                                            (item) => item.visible == true,
                                          ).length == 2
                                          && stateInvestingSections.containers.filter(
                                            (item) => item.visible == true,
                                          ).length == 1
                                        ) {
                                          const newArr = [...state.containers]
                                          const indexinfo = newArr.findIndex(
                                            (item) => item.id == 'investingContainer',
                                          )
                                          newArr[indexinfo].visible = false
                                          setState({ containers: [...newArr] })
                                        }
                                      }}
                                    />
                                    <Spacer size="16" direction="vertical" />
                                    <Typography
                                      type="text_x_small"
                                      color="grayscale"
                                      colorWeight="600"
                                    >
                                      {containerSec.display}
                                    </Typography>
                                  </div>
                                  <div style={{ display: 'flex' }}>
                                    {indexSec != 0
                                      && stateInvestingSections.containers[0].id
                                        != containerSec.id && (
                                        <ArrowUpIcon
                                          width="24"
                                          color={palette.grayscale[800]}
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            const containers = reorder(
                                              stateInvestingSections.containers,
                                              indexSec,
                                              indexSec - 1,
                                              'up',
                                            )
                                            setStateInvestingSections({
                                              containers: [...containers],
                                            })
                                          }}
                                        />
                                    )}
                                    {indexSec
                                      != stateInvestingSections.containers.length
                                        - 1
                                      && [
                                        ...stateInvestingSections.containers,
                                      ].reverse()[0].id != containerSec.id && (
                                        <>
                                          <Spacer
                                            size="16"
                                            direction="vertical"
                                          />
                                          <ArrowDownIcon
                                            width="24"
                                            color={palette.grayscale[800]}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              const containers = reorder(
                                                stateInvestingSections.containers,
                                                indexSec,
                                                indexSec + 1,
                                                'down',
                                              )
                                              setStateInvestingSections({
                                                containers: [...containers],
                                              })
                                            }}
                                          />
                                        </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          ),
                        )}
                      </div>
                    )}
                    {container.id == 'equipmentsContainer' && (
                      <div>
                        {[...stateEquipmentsSections.containers].map(
                          (containerEquips, indexEquips) => (
                            <div
                              key={containerEquips.id}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding:
                                  indexEquips == 0
                                    ? '0px 0px 12px 0px'
                                    : '12px 0px',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Toggle
                                  checked={containerEquips.visible}
                                  setChecked={() => {}}
                                  onClick={() => {
                                    if (
                                      stateEquipmentsSections.containers.findIndex(
                                        (item) => item.id == containerEquips.id,
                                      ) != -1
                                    ) {
                                      const newArrInfo = [
                                        ...stateEquipmentsSections.containers,
                                      ]
                                      const indexinfo = newArrInfo.findIndex(
                                        (item) => item.id == containerEquips.id,
                                      )
                                      newArrInfo[
                                        indexinfo
                                      ].visible = !newArrInfo[indexinfo].visible
                                      setStateEquipmentsSections({
                                        containers: [...newArrInfo],
                                      })
                                    }

                                    if (
                                      stateEquipmentsSections.containers.filter(
                                        (item) => item.visible == true,
                                      ).length == 0
                                    ) {
                                      const newArr = [...state.containers]
                                      const indexinfo = newArr.findIndex(
                                        (item) => item.id == 'equipmentsContainer',
                                      )
                                      newArr[indexinfo].visible = false
                                      setState({ containers: [...newArr] })
                                    }
                                  }}
                                />
                                <Spacer size="16" direction="vertical" />
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="600"
                                >
                                  {containerEquips.display}
                                </Typography>
                              </div>
                              <div style={{ display: 'flex' }}>
                                {indexEquips != 0
                                  && stateEquipmentsSections.containers[0].id
                                    != containerEquips.id && (
                                    <ArrowUpIcon
                                      width="24"
                                      color={palette.grayscale[800]}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const containers = reorder(
                                          stateEquipmentsSections.containers,
                                          indexEquips,
                                          indexEquips - 1,
                                          'up',
                                        )
                                        setStateEquipmentsSections({
                                          containers: [...containers],
                                        })
                                      }}
                                    />
                                )}
                                {indexEquips
                                  != stateEquipmentsSections.containers.length
                                    - 1
                                  && [
                                    ...stateEquipmentsSections.containers,
                                  ].reverse()[0].id != containerEquips.id && (
                                    <>
                                      <Spacer size="16" direction="vertical" />
                                      <ArrowDownIcon
                                        width="24"
                                        color={palette.grayscale[800]}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          const containers = reorder(
                                            stateEquipmentsSections.containers,
                                            indexEquips,
                                            indexEquips + 1,
                                            'down',
                                          )
                                          setStateEquipmentsSections({
                                            containers: [...containers],
                                          })
                                        }}
                                      />
                                    </>
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                    {container.id == 'paymentsOptionsContainer' && (
                      <div>
                        {[...statePaymentSections.containers].map(
                          (containerPaym, indexPaym) => (
                            <div
                              key={containerPaym.id}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding:
                                  indexPaym == 0
                                    ? '0px 0px 12px 0px'
                                    : '12px 0px',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <LockIcon
                                  width="24"
                                  color={palette.grayscale[300]}
                                />
                                <Spacer size="16" direction="vertical" />
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="600"
                                >
                                  {containerPaym.display}
                                </Typography>
                              </div>
                              <div style={{ display: 'flex' }}>
                                {indexPaym != 0
                                  && statePaymentSections.containers[0].id
                                    != containerPaym.id && (
                                    <ArrowUpIcon
                                      width="24"
                                      color={palette.grayscale[800]}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const containers = reorder(
                                          statePaymentSections.containers,
                                          indexPaym,
                                          indexPaym - 1,
                                          'up',
                                        )
                                        setStatePaymentSections({
                                          containers: [...containers],
                                        })
                                      }}
                                    />
                                )}
                                {indexPaym
                                  != statePaymentSections.containers.length - 1
                                  && [
                                    ...statePaymentSections.containers,
                                  ].reverse()[0].id != containerPaym.id && (
                                    <>
                                      <Spacer size="16" direction="vertical" />
                                      <ArrowDownIcon
                                        width="24"
                                        color={palette.grayscale[800]}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          const containers = reorder(
                                            statePaymentSections.containers,
                                            indexPaym,
                                            indexPaym + 1,
                                            'down',
                                          )
                                          setStatePaymentSections({
                                            containers: [...containers],
                                          })
                                        }}
                                      />
                                    </>
                                )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                  </Paper>
                </div>
              ))}
              <Spacer size="42" direction="horizontal" />
            </>
          )}
          {tabMobile == 1 && (
            <div>
              {statePdf.containers.map((container, index) => (
                <div key={container.id}>
                  <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Toggle
                          checked={container.visible}
                          setChecked={() => {}}
                          onClick={() => {
                            if (
                              !isUserProPlan
                              && container.id == 'SchedulesPdf'
                            ) {
                              setIsProPlanModalOpen(true)
                            } else if (
                              statePdf.containers.findIndex(
                                (item) => item.id == container.id,
                              ) != -1
                            ) {
                              const newArr = [...statePdf.containers]
                              const indexinfo = newArr.findIndex(
                                (item) => item.id == container.id,
                              )
                              newArr[indexinfo].visible = !newArr[indexinfo]
                                .visible
                              setStatePdf({ containers: newArr })
                            }
                          }}
                        />
                        <Spacer size="16" direction="vertical" />
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          {handleTitlePdfContainer(container.id)}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {index != 0
                          && statePdf.containers[0].id != container.id && (
                            <ArrowUpIcon
                              width="24"
                              color={palette.grayscale[800]}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (index != 0) {
                                  const containers = reorder(
                                    statePdf.containers,
                                    index,
                                    index - 1,
                                    'up',
                                  )
                                  setStatePdf({ containers })
                                }
                              }}
                            />
                        )}
                        {index != statePdf.containers.length - 1
                          && [...statePdf.containers].reverse()[0].id
                            != container.id && (
                            <>
                              <Spacer size="16" direction="vertical" />
                              <ArrowDownIcon
                                width="24"
                                color={palette.grayscale[800]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (index != statePdf.containers.length - 1) {
                                    const containers = reorder(
                                      statePdf.containers,
                                      index,
                                      index + 1,
                                      'down',
                                    )
                                    setStatePdf({ containers })
                                  }
                                }}
                              />
                            </>
                        )}
                      </div>
                    </div>
                    {container.id.toString().includes('QuotePdf') && (
                      <>
                        <Spacer size="24" direction="horizontal" />
                        <Divider
                          style={{
                            height: '2px',
                            backgroundColor: palette.grayscale[300],
                          }}
                        />
                        <Spacer size="24" direction="horizontal" />
                      </>
                    )}
                    {container.id.toString().includes('QuotePdf') && (
                      <div>
                        {stateQuotesPdf[
                          Number(container.id.replace('QuotePdf', ''))
                        ].map((containerWithin, indexWithin) => (
                          <div
                            key={containerWithin.id}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding:
                                indexWithin == 0
                                  ? '0px 0px 12px 0px'
                                  : '12px 0px',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Toggle
                                checked={containerWithin.visible}
                                setChecked={() => {}}
                                onClick={() => {
                                  if (
                                    stateQuotesPdf[
                                      Number(
                                        container.id.replace('QuotePdf', ''),
                                      )
                                    ].findIndex(
                                      (item) => item.id == containerWithin.id,
                                    ) != -1
                                  ) {
                                    const newArrInfo = [
                                      ...stateQuotesPdf[
                                        Number(
                                          container.id.replace('QuotePdf', ''),
                                        )
                                      ],
                                    ]
                                    const indexinfo = newArrInfo.findIndex(
                                      (item) => item.id == containerWithin.id,
                                    )
                                    newArrInfo[indexinfo].visible = !newArrInfo[
                                      indexinfo
                                    ].visible
                                    const newArrAll = [...stateQuotesPdf]
                                    newArrAll[
                                      Number(
                                        container.id.replace('QuotePdf', ''),
                                      )
                                    ] = newArrInfo
                                    setStateQuotesPdf(newArrAll)
                                  }
                                }}
                              />
                              <Spacer size="16" direction="vertical" />
                              <Typography
                                type="text_x_small"
                                color="grayscale"
                                colorWeight="600"
                              >
                                {containerWithin.display}
                              </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                              {indexWithin != 0
                                && stateQuotesPdf[
                                  Number(container.id.replace('QuotePdf', ''))
                                ][0].id != containerWithin.id && (
                                  <ArrowUpIcon
                                    width="24"
                                    color={palette.grayscale[800]}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const containers = reorder(
                                        stateQuotesPdf[
                                          Number(
                                            container.id.replace(
                                              'QuotePdf',
                                              '',
                                            ),
                                          )
                                        ],
                                        indexWithin,
                                        indexWithin - 1,
                                        'up',
                                      )
                                      const newArrAll = [...stateQuotesPdf]
                                      newArrAll[
                                        Number(
                                          container.id.replace('QuotePdf', ''),
                                        )
                                      ] = containers
                                      setStateQuotesPdf(newArrAll)
                                    }}
                                  />
                              )}
                              {indexWithin
                                != stateQuotesPdf[
                                  Number(container.id.replace('QuotePdf', ''))
                                ].length
                                  - 1
                                && [
                                  ...stateQuotesPdf[
                                    Number(container.id.replace('QuotePdf', ''))
                                  ],
                                ].reverse()[0].id != containerWithin.id && (
                                  <>
                                    <Spacer size="16" direction="vertical" />
                                    <ArrowDownIcon
                                      width="24"
                                      color={palette.grayscale[800]}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        const containers = reorder(
                                          stateQuotesPdf[
                                            Number(
                                              container.id.replace(
                                                'QuotePdf',
                                                '',
                                              ),
                                            )
                                          ],
                                          indexWithin,
                                          indexWithin + 1,
                                          'down',
                                        )
                                        const newArrAll = [...stateQuotesPdf]
                                        newArrAll[
                                          Number(
                                            container.id.replace(
                                              'QuotePdf',
                                              '',
                                            ),
                                          )
                                        ] = containers
                                        setStateQuotesPdf(newArrAll)
                                      }}
                                    />
                                  </>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Paper>
                </div>
              ))}
              <div>
                <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Toggle
                        checked={signatureState.visible}
                        setChecked={() => {}}
                        onClick={() => {
                          const newObj = signatureState
                          newObj.visible = !newObj.visible
                          setSignatureState({ ...newObj })
                        }}
                      />
                      <Spacer size="16" direction="vertical" />
                      <Typography
                        type="text_x_small"
                        color="grayscale"
                        colorWeight="600"
                      >
                        {signatureState.display}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </div>
              <Spacer size="42" direction="horizontal" />
            </div>
          )}
        </div>
      </Hidden>

      {openSaveModalDefaultConfig && (
        <SaveDefaultComponent
          openModalSavedConfigs={openSaveModalDefaultConfig}
          setOpenModalSavedConfigs={setOpenSaveModalDefaultConfig}
          callback={handleBackSlide}
          isFromProposalEdit
        />
      )}

      {sendModalStepFour && (
        <ProposalShareModal
          onlyButtons
          isOpen={sendModalStepFour}
          onClose={() => setSendModalStepFour(false)}
          proposal={{
            ...proposal.proposal,
            dimensionings: proposal.sizings.length,
            quotations: proposal.quotes.length,
          }}
          onSend={(sendBy) => {
            logEvent(proposalTracker.actions.sendProposalToCustomer, {
              sendBy,
              origin: '/dimensionar - Proposta virtual',
            })
          }}
        />
      )}

    </>
  )
}

export default withSize()(ProposalStructurer)
