import styled from 'styled-components'
import { palette } from '@77sol/styles'

export const Container = styled.div`
  margin: 10px 0;
`

export const CopyAddress = styled.label`
  cursor: pointer;

  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  margin: 25px 10px;

  font-size: 0.9rem;
  color: ${palette.grayscale[500]};
`
