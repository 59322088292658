import styled from 'styled-components'

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const PeriodFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  * {
    font-family: DM Sans;
  }

  p {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
  }

  button {
    width: 130px;
  }
`
