import { type UseMutationOptions, useMutation } from '@tanstack/react-query'

import { updateDeliveryQuotation } from '../services/api'
import { type IPayloadQuotationUpdateDelivery } from '../services/types'

export function useUpdateDeliveryQuotation<
  TError = unknown,
  TContext = unknown,
>({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: UseMutationOptions<
  unknown,
  TError,
  IPayloadQuotationUpdateDelivery,
  TContext
>) {
  const data = useMutation({
    mutationFn: async (data) => {
      return await updateDeliveryQuotation(data)
    },
    onMutate,
    onSettled,
    onSuccess,
    onError,
  })
  return data
}
