import { useHistory, useLocation } from 'react-router-dom'

const useRedirect = () => {
  const history = useHistory()
  const location = useLocation()

  const stateHistory = location.state || {}

  const redirectTo = (pathname = '', state = stateHistory) => {
    history.push({
      pathname,
      state,
    })
  }

  const clearHistory = (pathname = location.pathname) => {
    history.push({
      pathname,
      state: {},
    })
  }

  return {
    redirectTo,
    clearHistory,
    stateHistory,
  }
}

export default useRedirect
