import { type IIntegrator } from 'domains/integrators/services/types'
import { useEffect, useState } from 'react'

export const useSearchIntegratorInput = (
  defaultIntegratorValue?: IIntegrator,
) => {
  const [optionsIsOpen, setOptionsIsOpen] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [isSelected, setIsSelected] = useState(false)
  const [inputValue, setInputValue] = useState(
    defaultIntegratorValue?.name ?? '',
  )

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (inputValue.length > 3 && !isSelected) {
        if (defaultIntegratorValue?.name !== inputValue) setOptionsIsOpen(true)
        setSearchParam(inputValue)
      } else {
        setIsSelected(false)
        setOptionsIsOpen(false)
        setSearchParam('')
      }
    }, 500)

    return () => {
      clearTimeout(debounceTimer)
    }
  }, [inputValue])

  return {
    setOptionsIsOpen,
    setIsSelected,
    setInputValue,
    optionsIsOpen,
    searchParam,
    inputValue,
    isSelected,
  }
}
