const orderStatus = {
  Reservado: {
    color: 'primary',
  },
  'Pedido vencido': {
    color: 'grayscale',
  },
  'Em análise': {
    color: 'orange',
  },
  Pago: {
    color: 'purple',
  },
  'Em preparação': {
    color: 'lightyellow',
  },
  'Em transporte': {
    color: 'pink',
  },
  Entregue: {
    color: 'green',
  },
  Cancelado: {
    color: 'red',
  },
}

export default orderStatus
