import { QUERY_KEYS } from 'services/constant'
import { proposalQuotation } from '../services/api'
import { useQuery } from '@tanstack/react-query'

export const useFetchProposalQuotation = (id: number) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: [QUERY_KEYS.PROPOSAL.QUOTATION, id],
    queryFn: async () => await proposalQuotation.get(id),
    cacheTime: 0, // 0 => Temporário pois temos que invalidar a query toda vez que houver uma ação de mudança do KIT
  })
  return { proposalQuotationData: data, isLoading, isSuccess, isError }
}
