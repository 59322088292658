import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((isMobile) => ({
  boxModal: {
    width: isMobile ? '100%' : '50%',
    height: 'auto',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));