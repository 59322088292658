export const proposalColorsDefault = {
  texts: '#4E4B66',
  background: '#EFF0F6',
  background_content: '#FFF',
  positive_values: '#00CC67',
  negative_values: '#FF0000',
  primary_color: '#063EF9',
  secondary_color: '#00CCCC',
  primary_color_graphics: '#00FFFF',
  secondary_color_graphics: '#006666',
}
