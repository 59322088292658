import { useState, useEffect } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { PaperSelect, Typography } from 'components'
import { Spacer, Button, Input, useToast } from '@77sol/core'
import { SearchIcon, CloseXIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import InfiniteScroll from 'react-infinite-scroll-component'
import API from 'api'
import { getAllCustomers } from 'utils/requests'
import * as ProposalAction from 'store/actions/index'
import { EditForm, AddForm } from './components'
import { ClientPaper } from '../../../ClientPaper'
import { useSolLeads } from '../../hooks/useSolLeads'
import { useClientsSelect } from './hooks/useClientsSelect'

function ClientsSelect({
  clients,
  selectedClient,
  setSelectedClient,
  getClients,
  activeStep,
  setActiveStep,
  handleCreateProposal,
  loadingCreateProposal,
  handleClickClient,
  refClient,
  hideText,
  listHeight,
  setActiveDimStep,
  asModal,
  setModalNewClientValidation,
  getMoreClients,
  hasMoreClients,
  filterClientName,
  setFilterClientName,
  resetFieldSearch,
  formMethods,
  setCurrentSizingType,
}) {
  const dispatch = useDispatch()
  const proposal = useSelector((state) => state.Reducer1)
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { onSuccessOpenToast, onErrorOpenToast, onWarningOpenToast } =
    useToast()

  // Edit Client
  const [nomeClientEdit, setNomeClientEdit] = useState('')
  const [cepClientEdit, setCepClientEdit] = useState('')
  const [enderecoClientEdit, setEnderecoClientEdit] = useState('')
  const [numeroClientEdit, setNumeroClientEdit] = useState('')
  const [complementoClientEdit, setComplementoClientEdit] = useState('')
  const [bairroClientEdit, setBairroClientEdit] = useState('')
  const [cidadeClientEdit, setCidadeClientEdit] = useState('')
  const [estadoClientEdit, setEstadoClientEdit] = useState('')
  const [emailClientEdit, setEmailClientEdit] = useState('')
  const [telefoneClientEdit, setTelefoneClientEdit] = useState('')
  const [cpfClientEdit, setCpfClientEdit] = useState('')
  const [nascimentoClientEdit, setNascimentoClientEdit] = useState('')
  const [valorConsumoEdit, setValorConsumoEdit] = useState('')
  const [tipoEstruturaEdit, setTipoEstruturaEdit] = useState('')

  // Add Client
  const [newClient, setNewClient] = useState(false)
  const [nomeClientAdd, setNomeClientAdd] = useState('')
  const [cepClientAdd, setCepClientAdd] = useState('')
  const [enderecoClientAdd, setEnderecoClientAdd] = useState('')
  const [numeroClientAdd, setNumeroClientAdd] = useState('')
  const [complementoClientAdd, setComplementoClientAdd] = useState('')
  const [bairroClientAdd, setBairroClientAdd] = useState('')
  const [cidadeClientAdd, setCidadeClientAdd] = useState('')
  const [estadoClientAdd, setEstadoClientAdd] = useState('')
  const [emailClientAdd, setEmailClientAdd] = useState('')
  const [telefoneClientAdd, setTelefoneClientAdd] = useState('')
  const [cpfClientAdd, setCpfClientAdd] = useState('')
  const [nascimentoClientAdd, setNascimentoClientAdd] = useState('')
  const [valorConsumoAdd, setValorConsumoAdd] = useState('')
  const [tipoEstruturaAdd, setTipoEstruturaAdd] = useState('')
  const [loadindCreateClient, setLoadindCreateClient] = useState(false)

  const [changingClient, setChangingClient] = useState(false)
  const [triedSave, setTriedSave] = useState(false)
  const [moreDataDisplayed, setMoreDataDisplayed] = useState(false)

  const handleSetEditValues = (newValue) => {
    setSelectedClient(newValue || null)
    setNomeClientEdit(newValue?.label || newValue?.nome || '')
    setCepClientEdit(newValue?.cep || '')
    setEnderecoClientEdit(newValue?.endereco || '')
    setNumeroClientEdit(newValue?.numero || '')
    setComplementoClientEdit(newValue?.complemento || '')
    setBairroClientEdit(newValue?.bairro || '')
    setCidadeClientEdit(newValue?.cidade || '')
    setEstadoClientEdit(newValue?.uf || '')
    setEmailClientEdit(newValue?.email || '')
    setTelefoneClientEdit(newValue?.telefone || '')
    setCpfClientEdit(newValue?.cpf || '')
    setNascimentoClientEdit(newValue?.nascimento || '')
    setValorConsumoEdit(newValue?.energy_bill_value || '')
    setTipoEstruturaEdit(newValue?.structure || '')
  }

  async function setarCreatedClient(id) {
    const newArray = await getAllCustomers()
    getClients()
    const newSelectedClient = newArray?.find((client) => client.id == id)
    setNewClient(false)
    setSelectedClient(newSelectedClient)
    handleSetEditValues(newSelectedClient)
    setLoadindCreateClient(false)
  }

  const createClient = () => {
    setLoadindCreateClient(true)

    API.post('/clientes/add', {
      nome: nomeClientAdd,
      email: emailClientAdd,
      telefone: telefoneClientAdd,
      cpf: cpfClientAdd,
      nascimento: nascimentoClientAdd,
      cep: cepClientAdd,
      cidade: cidadeClientAdd,
      bairro: bairroClientAdd,
      uf: estadoClientAdd,
      endereco: enderecoClientAdd,
      numero: numeroClientAdd,
      complemento: complementoClientAdd,
      energy_bill_value: valorConsumoAdd,
      structure: tipoEstruturaAdd,
      status: '0',
      tipo: '1',
    })
      .then((res) => {
        handleClickClient?.()
        onSuccessOpenToast('Cliente criado com sucesso!')
        setarCreatedClient(res.data.id)
        setSelectedClient(res.data)
        setTimeout(() => {
          if (proposal.proposal.customer_id) {
            handleChangeProposalCustomer(res.data)
          } else {
            handleCreateProposal(res.data.id)
          }
        }, [1000])
      })
      .catch((err) => {
        setLoadindCreateClient(false)
        if (err.response.data.email) {
          onWarningOpenToast('Este e-mail já foi utilizado!')
        } else {
          onErrorOpenToast('Não foi possível cadastrar cliente')
        }
      })
  }

  const handleChangeProposalCustomer = (value) => {
    API.post('/proposal/change/customer', {
      customer_id: value.id,
      proposal_id: proposal.proposal.id,
    })
      .then((res) => {
        handleClickClient?.()
        handleSetEditValues(value)
        dispatch(ProposalAction.SaveProposalRedux(res.data))
        onSuccessOpenToast('Cliente alterado com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o cliente!')
      })
  }

  const {
    handleSearchClient,
    handleNewClient,
    handleSaveClient,
    handleSelectClient,
    handleCancelClient,
    handleDisplayMoreData,
    handleContinueProposal,
  } = useClientsSelect({
    asModal,
    getClients,
    createClient,
    newClient,
    setNewClient,
    selectedClient,
    setChangingClient,
    setSelectedClient,
    setActiveDimStep,
    setMoreDataDisplayed,
    handleChangeProposalCustomer,
    handleCreateProposal,
    handleSetEditValues,
    handleClickClient,
  })

  useSolLeads({
    selectedClient,
    setSelectedClient,
    setCurrentSizingType,
    setClientDataDisplayed: setMoreDataDisplayed,
  })

  useEffect(() => {
    if (setModalNewClientValidation) {
      setModalNewClientValidation(newClient)
    }
  }, [newClient, setModalNewClientValidation])

  const handleEditClientValues = () => {
    API.patch(`/clientes/update/${selectedClient.id}`, {
      nome: nomeClientEdit,
      email: emailClientEdit,
      telefone: telefoneClientEdit,
      cpf: cpfClientEdit,
      nascimento: nascimentoClientEdit,
      cep: cepClientEdit,
      cidade: cidadeClientEdit,
      bairro: bairroClientEdit,
      uf: estadoClientEdit,
      endereco: enderecoClientEdit,
      numero: numeroClientEdit,
      complemento: complementoClientEdit,
      energy_bill_value: valorConsumoEdit,
      structure: tipoEstruturaEdit,
      status: '0',
      tipo: '1',
    }).then((res) => {
      getClients()
      setSelectedClient(res.data)
      formMethods?.setValue('delivery_state', estadoClientEdit)
    })
  }

  // Cep
  const [loadingCep, setLoadingCep] = useState(false)

  const getEndereco = () => {
    if (newClient && cepClientAdd) {
      setLoadingCep(true)
      API.get(`/showcep?cep=${cepClientAdd}`).then((res) => {
        setLoadingCep(false)
        setCidadeClientAdd(res.data.cidade)
        setEstadoClientAdd(res.data.uf)
        setEnderecoClientAdd(res.data.logradouro)
        setBairroClientAdd(res.data.bairro)
      })
    } else if (cepClientEdit) {
      setLoadingCep(true)
      API.get(`/showcep?cep=${cepClientEdit}`).then((res) => {
        setLoadingCep(false)
        setCidadeClientEdit(res.data.cidade)
        setEstadoClientEdit(res.data.uf)
        setEnderecoClientEdit(res.data.logradouro)
        setBairroClientEdit(res.data.bairro)
      })
    }
  }

  useEffect(() => {
    if (changingClient) {
      setChangingClient(false)
    } else {
      if (selectedClient) {
        handleSetEditValues(selectedClient)
      }

      if (clients && clients.length) {
        if (proposal.proposal.customer_id) {
          if (!proposal.sizings.length) {
            setActiveStep('dadosDim')
          }
        }
      }
    }
  }, [selectedClient, clients])

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={refClient}
        style={{ position: 'absolute', marginTop: '-24px' }}
      />
      {!hideText && (
        <>
          <Typography
            type={downSm ? 'link_small' : 'display_x_small_bold'}
            color="grayscale"
            colorWeight={downSm ? '800' : '700'}
          >
            Selecione o cliente que deseja enviar a proposta
          </Typography>
          <Spacer size="24" direction="horizontal" />
        </>
      )}
      <div
        style={{
          display: downSm && !selectedClient && !newClient ? 'block' : 'flex',
          width: upXl
            ? asModal
              ? '100%'
              : '60%'
            : downSm
            ? '100%'
            : asModal
            ? '100%'
            : '80%',
        }}
      >
        {selectedClient || newClient ? (
          <>
            {newClient && (
              <Button
                style={{
                  width: '-webkit-fill-available',
                  justifyContent: 'flex-start',
                }}
                size="small"
                margin="none"
              >
                Novo Cliente
              </Button>
            )}
            {selectedClient && (
              <Button
                style={{
                  width: '-webkit-fill-available',
                  justifyContent: 'flex-start',
                }}
                size="small"
                margin="none"
              >
                {nomeClientEdit}
              </Button>
            )}
          </>
        ) : (
          <Input
            style={{ width: downSm ? '100%' : '300px' }}
            value={filterClientName}
            onBlur={handleSearchClient}
            onChange={setFilterClientName}
            placeholder="Pesquise pelo nome do cliente"
            endAdornment={
              !filterClientName ? (
                <SearchIcon width="18" color={palette.grayscale[500]} />
              ) : (
                <CloseXIcon
                  width="18"
                  color={palette.grayscale[500]}
                  onClick={() => resetFieldSearch(true)}
                />
              )
            }
          />
        )}
        {(selectedClient || newClient) && (
          <Spacer size="16" direction="vertical" />
        )}
        {(selectedClient || newClient) && (
          <>
            {selectedClient && downSm ? (
              <Button
                onClick={handleCancelClient}
                variant="outlined"
                size="small"
                color="primary"
                noMargin
                onlyIcon={<CloseXIcon width="16" />}
              />
            ) : (
              <Button
                onClick={handleCancelClient}
                variant="outlined"
                size="small"
                color="red"
                noMargin
              >
                Cancelar
              </Button>
            )}
          </>
        )}
      </div>
      <Spacer size="24" direction="horizontal" />
      {!selectedClient && !newClient ? (
        <>
          {clients && (
            <InfiniteScroll
              next={getMoreClients}
              hasMore={hasMoreClients}
              dataLength={clients.length}
              height={listHeight}
            >
              <Grid
                style={{ width: '100%', margin: '0px' }}
                container
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={4} xl={3}>
                  <PaperSelect
                    id="clientsSelect_button_newClient"
                    onClick={handleNewClient}
                    type="new"
                    title="Novo cliente"
                    asModal={asModal}
                  />
                </Grid>

                {clients.length > 0 &&
                  clients.map((client, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
                      <ClientPaper
                        id={`clientsSelect_button_${index}`}
                        clientData={client}
                        onClick={() => handleSelectClient(client)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </InfiniteScroll>
          )}
        </>
      ) : (
        <div
          style={{
            width: upXl
              ? asModal
                ? '100%'
                : '60%'
              : downSm
              ? '100%'
              : asModal
              ? '100%'
              : '80%',
          }}
        >
          {newClient ? (
            <div style={{ paddingBottom: '10vh' }}>
              <AddForm
                nomeClientAdd={nomeClientAdd}
                setNomeClientAdd={setNomeClientAdd}
                cepClientAdd={cepClientAdd}
                setCepClientAdd={setCepClientAdd}
                enderecoClientAdd={enderecoClientAdd}
                setEnderecoClientAdd={setEnderecoClientAdd}
                numeroClientAdd={numeroClientAdd}
                setNumeroClientAdd={setNumeroClientAdd}
                complementoClientAdd={complementoClientAdd}
                setComplementoClientAdd={setComplementoClientAdd}
                bairroClientAdd={bairroClientAdd}
                setBairroClientAdd={setBairroClientAdd}
                cidadeClientAdd={cidadeClientAdd}
                setCidadeClientAdd={setCidadeClientAdd}
                estadoClientAdd={estadoClientAdd}
                setEstadoClientAdd={setEstadoClientAdd}
                emailClientAdd={emailClientAdd}
                setEmailClientAdd={setEmailClientAdd}
                telefoneClientAdd={telefoneClientAdd}
                setTelefoneClientAdd={setTelefoneClientAdd}
                cpfClientAdd={cpfClientAdd}
                setCpfClientAdd={setCpfClientAdd}
                nascimentoClientAdd={nascimentoClientAdd}
                setNascimentoClientAdd={setNascimentoClientAdd}
                valorConsumoAdd={valorConsumoAdd}
                setValorConsumoAdd={setValorConsumoAdd}
                tipoEstruturaAdd={tipoEstruturaAdd}
                setTipoEstruturaAdd={setTipoEstruturaAdd}
                getEndereco={getEndereco}
                loadingCep={loadingCep}
                triedSave={triedSave}
                setTriedSave={setTriedSave}
                moreDataDisplayed={moreDataDisplayed}
                onDisplayMoreData={handleDisplayMoreData}
              />
              <Spacer size="48" direction="horizontal" />
              <Button
                id="clientsSelect_button_save"
                onClick={handleSaveClient}
                size="small"
                noMargin
                disabled={!nomeClientAdd}
                isLoading={loadindCreateClient}
              >
                Salvar cliente
              </Button>
            </div>
          ) : (
            <>
              <EditForm
                nomeClientEdit={nomeClientEdit}
                setNomeClientEdit={setNomeClientEdit}
                cepClientEdit={cepClientEdit}
                setCepClientEdit={setCepClientEdit}
                enderecoClientEdit={enderecoClientEdit}
                setEnderecoClientEdit={setEnderecoClientEdit}
                numeroClientEdit={numeroClientEdit}
                setNumeroClientEdit={setNumeroClientEdit}
                complementoClientEdit={complementoClientEdit}
                setComplementoClientEdit={setComplementoClientEdit}
                bairroClientEdit={bairroClientEdit}
                setBairroClientEdit={setBairroClientEdit}
                cidadeClientEdit={cidadeClientEdit}
                setCidadeClientEdit={setCidadeClientEdit}
                estadoClientEdit={estadoClientEdit}
                setEstadoClientEdit={setEstadoClientEdit}
                emailClientEdit={emailClientEdit}
                setEmailClientEdit={setEmailClientEdit}
                telefoneClientEdit={telefoneClientEdit}
                setTelefoneClientEdit={setTelefoneClientEdit}
                cpfClientEdit={cpfClientEdit}
                setCpfClientEdit={setCpfClientEdit}
                nascimentoClientEdit={nascimentoClientEdit}
                setNascimentoClientEdit={setNascimentoClientEdit}
                valorConsumoEdit={valorConsumoEdit}
                setValorConsumoEdit={setValorConsumoEdit}
                tipoEstruturaEdit={tipoEstruturaEdit}
                setTipoEstruturaEdit={setTipoEstruturaEdit}
                getEndereco={getEndereco}
                loadingCep={loadingCep}
                handleEditClientValues={handleEditClientValues}
                moreDataDisplayed={moreDataDisplayed}
                onDisplayMoreData={handleDisplayMoreData}
              />
              <Spacer size="48" direction="horizontal" />
              {activeStep === 'cliente' && (
                <div style={{ paddingBottom: '10vh' }}>
                  <Button
                    id="sizing_button_next"
                    onClick={handleContinueProposal}
                    size="small"
                    fullWidth={downSm}
                    noMargin
                    isLoading={loadingCreateProposal}
                  >
                    Próximo
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ClientsSelect
