import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  accordion: ({ title, size }) => ({
    '&.MuiAccordion-root': {
      border: 'none',
      backgroundColor: palette.grayscale[100],
      borderRadius: '16px',
      boxShadow: 'none',
      '& .Mui-disabled': {
        opacity: 0.4,
      },
      '&.Mui-expanded': {
        border: `2px solid ${palette.grayscale[300]}`,
      },
    },
    '& .MuiAccordionSummary-root': {
      height: size === 'small' ? '88px' : '112px',
      padding: size === 'small' ? '0px 32px' : '0px 40px',
    },
    '& .MuiAccordionDetails-root': {
      padding: size === 'small' ? '32px' : '40px',
      paddingTop: size === 'small' ? '12px' : '24px',
    },
    '& .MuiAccordionSummary-expandIcon': {
      color: palette.primary[300],
    },
  }),
}))

export default styles
