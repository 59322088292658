import * as S from '../../styles'

interface IOriginalProps {
  width: number
  height: number
}

export function White({ ...size }: IOriginalProps) {
  return (
    <S.Container {...size}>
      <S.Logo alt="Logo 77Sol" src="/assets/logo/main/logo-77sol-branco.svg" />
    </S.Container>
  )
}
