import { Modal } from '@77sol-ui/molecules'
import { AlertCircle, Loader2 } from 'lucide-react'
import { Button } from '@77sol-ui/atoms'
import { ModalFooter } from './styles'

interface AlertModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  description: string
  isLoading?: boolean
}

export function AlertModal({
  isOpen,
  onClose,
  title,
  description,
  onConfirm,
  isLoading = false,
}: AlertModalProps) {
  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />

        <Modal.Wrapper maxWidth="750px" maxHeight="90vh">
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={AlertCircle} />
            <Modal.Title>{title}</Modal.Title>
            <Modal.Description>{description}</Modal.Description>
          </Modal.Header>

          <ModalFooter>
            <Modal.Close asChild>
              <Button fullWidth variant="outline" disabled={isLoading}>
                Cancelar
              </Button>
            </Modal.Close>

            <Button fullWidth onClick={onConfirm} disabled={isLoading}>
              {isLoading && (
                <Loader2 className="atm-animate-spinSlow atm-h-4 atm-w-4 atm-ml-2" />
              )}
              Continuar
            </Button>
          </ModalFooter>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
