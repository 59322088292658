import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { useUpdateDeliveryQuotation } from 'domains/quotation/proposal-quotation-update-delivery/hooks/useUpdateDeliveryQuotation'
import { queryClient } from 'services/queryClient'
import { useToast } from '@77sol/core'
import { useCallback } from 'react'

export interface IUseChangeFreightQuotationProps {
  onSuccess?: () => void
}

export function useChangeFreightQuotation({
  onSuccess,
}: IUseChangeFreightQuotationProps) {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const queryKey = [GET_DIMENSIONING, dimensioningId]

  async function handleUpdateQuotations() {
    const data: IDimensioningGETResponse = await queryClient.fetchQuery(
      queryKey,
    )

    if (data?.quotations) {
      onSuccessOpenToast('Frete atualizado com sucesso')
      onSuccess?.()
    }
  }

  const { mutate: onUpdateDeliveryQuotation, isLoading } =
    useUpdateDeliveryQuotation({
      onSuccess: handleUpdateQuotations,
      onError: () => {
        onErrorOpenToast('Erro ao alterar o frete')
      },
    })

  const handleUpdateDeliveryQuotation = useCallback(
    (values: { city: string | null; state: string }) => {
      if (!quotationSelected?.id) return
      onUpdateDeliveryQuotation({
        delivery_city: values?.city,
        delivery_state: values?.state,
        quotation_id: quotationSelected.id,
      })
    },
    [quotationSelected, onUpdateDeliveryQuotation],
  )

  return { handleUpdateDeliveryQuotation, isLoading }
}
