import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ breakpoints }) => ({
  equipmentContainer: {
    margin: '32px 0px',
    padding: '0px 32px',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 4px 6px',
    [breakpoints.down('md')]: {
      padding: '0px 16px',
    },
  },
  equipmentContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '32px 0px',
  },
  equipmentDescription: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '24px',
    width: '90%',
    [breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
  },
}))
