/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import { useAmplitude } from 'hooks/useAmplitude'
import { pageLoadedTrackers } from 'services/tracker/events/pageLoaded/trackers'

const { NODE_ENV } = process.env
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

function Page(props) {
  const { title, children, ...rest } = props
  const { logEvent } = useAmplitude()
  const router = useHistory()

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title,
      })
    }
  }, [title, router])

  useEffect(() => {
    const pageEvent = pageLoadedTrackers.find(
      (event) => event.pathName === router.location.pathname,
    )
    if (pageEvent) {
      logEvent(pageEvent.key, { pathName: router.location.pathname })
    }
  }, [router])

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default Page
