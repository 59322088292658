import { Box, IconButton } from '@material-ui/core'
import { Typography, Paper, Badge, Spacer } from '@77sol/core'
import { FinancialLabel } from 'components'
import {
  FilledSaveFavoriteBookmark,
  SaveFavoriteBookmarkIcon,
} from '@77sol/icons/dist'
import palette from 'app_palette'
import { proposalFilterList } from '../../utils'

function ResponsiveCard({ values, onClick, handleFavorite, modal }) {
  const proposalStatus =
    proposalFilterList.find(({ value }) => value === values?.rowData?.status) ||
    proposalFilterList[0]

  return (
    <Box marginBottom={2} marginLeft={modal ? '-24px' : '0px'}>
      <Paper boxShadow={modal ? 'none' : 'small'}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={modal ? 'column' : 'row'}
          >
            <Box onClick={onClick} width="100%">
              <Typography size="xsmall" colorWeight="600">
                {values?.id}
              </Typography>
            </Box>
            <Box>
              {!modal && (
                <IconButton onClick={handleFavorite}>
                  {values?.rowData?.is_favorite ? (
                    <FilledSaveFavoriteBookmark
                      width="24px"
                      color={palette.yellow[400]}
                    />
                  ) : (
                    <SaveFavoriteBookmarkIcon
                      width="24px"
                      color={palette.grayscale[300]}
                    />
                  )}
                </IconButton>
              )}
            </Box>
          </Box>
          <Box onClick={onClick}>
            <Typography size="xsmall">
              Dimensionamentos: {values?.dimensionings}
            </Typography>
            <Typography size="xsmall">
              Cotações: {values?.quotations}
            </Typography>
            <Box paddingY={1}>
              <Typography size="xsmall">
                Financiamento:
                <Spacer size="8" direction="both" />
                <FinancialLabel
                  status={values?.rowData?.financing_status}
                  size="xsmall"
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            {modal ? (
              <Badge
                label={proposalStatus.label}
                color={proposalStatus.color}
              />
            ) : (
              <values.status.Component />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default ResponsiveCard
