import React from 'react'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import { CloseXIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { useStyles } from './styles'

export function Modal77SolTeam({ setIsCheckoutOpen, handleClose }) {
  const classes = useStyles()

  return (
    <Box
      onClick={() => {
        setIsCheckoutOpen(true)
      }}
      className={classes.container}
    >
      <Box className={classes.closeModal}>
        <Tooltip title="Fechar">
          <IconButton onClick={handleClose}>
            <CloseXIcon width="24px" color={palette.grayscale[800]} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}
