import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { CloseXIcon, EditIcon } from '@77sol/icons/dist'
import { ProposalInfoPaper, UserOptionsPaper, Spacer, Paper } from '@77sol/core'
import smoothscroll from 'smoothscroll-polyfill'
import { useDispatch } from 'react-redux'
import palette from 'app_palette'
import * as ProposalAction from 'store/actions'
import API from 'api'
import { DragPaper, ValidadeEdit } from '../../..'
import DragIconBlue from '../../../../img/dragBlue.png'
import DragIcon from '../../../../img/dragGrey.png'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
})

function DigitalMoreInfosContainers({
  size,
  moreInfosContainers,
  setMoreInfosContainers,
  editMode,
  drag,
  internalEditing,
  editingMore,
  setEditingMore,
  setDrag,
  setInternalEditing,
  proposal,
  colors,
}) {
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')
  const dispatch = useDispatch()

  const [validade, setValidade] = useState(proposal.proposal.validity)
  const [loadingExtras, setLoadingExtras] = useState(false)

  const handleSaveValidity = (validity) => {
    setLoadingExtras(true)

    API.post(
      '/proposal/edit/validity',
      {
        proposal_id: proposal.proposal.id,
        validity,
      },
      {
        headers: {
          token: tokenRegister,
        },
      },
    )
      .then((res) => {
        setLoadingExtras(false)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        setEditingMore('')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Droppable
      direction="horizontal"
      type="droppableMoreInfosContainers"
      droppableId="droppableMoreInfosContainers"
    >
      {(provided, snapshot) => (
        <Grid
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ height: '100%' }}
          container
        >
          {moreInfosContainers.map((container, index) => (
            <Draggable
              isDragDisabled={
                editMode !== 'paymentsOptionsContainer' ||
                internalEditing !== container.id ||
                editingMore === container.id
              }
              key={container.id}
              draggableId={container.id}
              index={index}
            >
              {(provided, snapshot) => (
                <Grid
                  item
                  className={classes.paddingSection}
                  xs={12}
                  sm={12}
                  md={
                    moreInfosContainers.filter((item) => item.visible === true)
                      .length === 1
                      ? 12
                      : 6
                  }
                  lg={
                    moreInfosContainers.filter((item) => item.visible === true)
                      .length === 1
                      ? 12
                      : 6
                  }
                  xl={
                    moreInfosContainers.filter((item) => item.visible === true)
                      .length === 1
                      ? 12
                      : container.breakpoint
                  }
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  onClick={(e) => {
                    if (editMode === 'paymentsOptionsContainer') {
                      internalEditing && internalEditing === container.id
                        ? setInternalEditing('')
                        : setInternalEditing(container.id)
                      setEditingMore('')
                    }
                  }}
                >
                  {container.visible && (
                    <>
                      {container.id === 'userOptionsCard' && (
                        <UserOptionsPaper colors={colors} />
                      )}
                      {container.id === 'proposalInfosCard' && (
                        <ProposalInfoPaper
                          name={
                            proposal?.clients?.find(
                              (client) =>
                                client.id === proposal.proposal.customer_id,
                            )?.label
                          }
                          userName={proposal.userName}
                          colors={colors}
                          proposal={proposal}
                        />
                      )}
                      {snapshot.isDragging && <DragPaper />}
                      {editMode === 'paymentsOptionsContainer' &&
                        internalEditing === container.id && (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              position: 'absolute',
                              zIndex: 10,
                              top: '-15px',
                              left: '-15px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Paper
                              style={{
                                padding: '16px 24px',
                                boxShadow: 'none',
                                cursor: 'auto',
                                maxHeight: '50vh',
                                width: 'auto',
                                border: `1px solid ${palette.grayscale[300]}`,
                                overflowY: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Typography
                                  type="link_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  {container.display}
                                </Typography>
                                <Spacer size="24" direction="vertical" />
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {editingMore === container.id ? (
                                    <Tooltip title="Fechar">
                                      <CloseXIcon
                                        width="24"
                                        onClick={() =>
                                          editingMore === container.id
                                            ? setEditingMore('')
                                            : setEditingMore(container.id)
                                        }
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {(container.id === 'proposalInfosCard' ||
                                        container.id ===
                                          'paymentOptionsCard') && (
                                          <Tooltip title="Editar">
                                          <EditIcon
                                              onClick={() =>
                                              editingMore === container.id
                                                ? setEditingMore('')
                                                : setEditingMore(container.id)
                                            }
                                              style={{ cursor: 'pointer' }}
                                              width="24"
                                              color={palette.grayscale[700]}
                                            />
                                        </Tooltip>
                                      )}
                                      <Spacer size="12" direction="vertical" />
                                      <Tooltip title="Arrastar">
                                        <img
                                          onClick={() =>
                                            drag === container.id
                                              ? setDrag('')
                                              : setDrag(container.id)
                                          }
                                          src={
                                            internalEditing === container.id
                                              ? DragIconBlue
                                              : DragIcon
                                          }
                                          style={{
                                            width: '20px',
                                            cursor: 'pointer',
                                            color:
                                              drag === container.id
                                                ? '#1335c6'
                                                : 'rgb(112, 112, 114)',
                                          }}
                                          alt="icone de arrastar"
                                        />
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                              {editingMore === 'proposalInfosCard' && (
                                <div>
                                  <ValidadeEdit
                                    handleFinish={handleSaveValidity}
                                    loadingExtras={loadingExtras}
                                    value={validade}
                                    setValue={setValidade}
                                  />
                                </div>
                              )}
                            </Paper>
                          </div>
                        )}
                    </>
                  )}
                </Grid>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  )
}

export default DigitalMoreInfosContainers
