import {
  Alert,
  type IAlertCloseProps,
  type IAlertButtonProps,
  type IAlertRootProps,
  type IAlertDescriptionProps,
  type IAlertTitleProps,
} from '@77sol-ui/atoms'

export interface IAlertWithTitle
  extends Pick<IAlertDescriptionProps, 'description'>,
    Pick<IAlertButtonProps, 'onClick'>,
    Pick<IAlertRootProps, 'variant'> {
  withIcon?: boolean
  withButton?: boolean
  buttonText?: IAlertButtonProps['text']
  title?: IAlertTitleProps['title']
  onClose?: IAlertCloseProps['onClose']
}

export function AlertWithTitle({
  title,
  description,
  onClose,
  onClick,
  variant,
  buttonText,
  withIcon = true,
  withButton = true,
}: IAlertWithTitle) {
  return (
    <Alert.Root variant={variant}>
      {withIcon && <Alert.Icon variant={variant} />}
      <Alert.Content>
        {title && <Alert.Title title={title} />}
        <Alert.Description description={description} />
        {withButton && buttonText && (
          <Alert.Button text={buttonText} onClick={onClick} variant={variant} />
        )}
      </Alert.Content>
      {onClose && <Alert.Close onClose={onClose} variant={variant} />}
    </Alert.Root>
  )
}
