import API from 'api'
import { GET_DIMENSIONING } from '../constants/endpointKeys'
import { type IDimensioningGETResponse } from './types'

export const dimensioningService = {
  getQuotations: async (dimensioningId: string) => {
    return await API.get<IDimensioningGETResponse>(
      `${GET_DIMENSIONING}/${dimensioningId}`,
    ).then(({ data }) => {
      return data
    })
  },
}
