import { type Dispatch, type SetStateAction } from 'react'
import { type IFilters } from '../../types'
import { DesktopFilters } from './components/Desktop'
import { MobileFilters } from './components/Mobile'
import useWindowSize from 'hooks/useWindowSize'

export interface IPerformanceFiltersProps {
  filters: IFilters
  setFilters: Dispatch<SetStateAction<IFilters>>
}

export function PerformanceFilters({
  filters,
  setFilters,
}: IPerformanceFiltersProps) {
  const { width } = useWindowSize()
  const showMobileFilters = width < 1280

  if (showMobileFilters) {
    return (
      <MobileFilters
        onApply={(filters) => {
          setFilters(filters)
        }}
        initialFilters={filters}
      />
    )
  }

  return <DesktopFilters filters={filters} setFilters={setFilters} />
}
