import { type IQuotationDTO } from 'domains/dimensioning/services/types'

type IConvertProducts = IQuotationDTO['products'] | string | null

export function convertProducts(products: IConvertProducts) {
  if (typeof products === 'string') {
    return JSON.parse(products || '[]')
  }

  return products ?? []
}
