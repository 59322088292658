import { useAmplitude } from 'hooks/useAmplitude'
import useWindowSize from 'hooks/useWindowSize'
import { BannersTrackers } from 'services/tracker/events/Banners'
import { useSidebarDesktopStore } from 'store/modals/SidebarStore'
import { CAROUSEL_DEFAULT_CONFIGS } from '../constants'
import { type IBannerImage } from '..'
import { useHistory } from 'react-router-dom'

let instance

export function useCarousel() {
  const { logEvent } = useAmplitude()
  const { isOpen } = useSidebarDesktopStore()
  const { width } = useWindowSize()

  const history = useHistory()

  const isDesktop = width >= 1280
  const isLarge = width >= 1920

  let centerPaddingDesktop = isOpen ? '35px' : '120px'
  const centerPaddingBanner = isDesktop ? centerPaddingDesktop : '20px'

  if (isLarge) centerPaddingDesktop = isOpen ? '35px' : '200px'

  const carouselConfigs = {
    ...CAROUSEL_DEFAULT_CONFIGS,
    dots: isDesktop,
    slidesToShow: 1,
    centerPadding: centerPaddingBanner,
  }

  function onClickBanner(
    image: IBannerImage,
    target: '_blank' | '_self' | '' = '_blank',
  ) {
    logEvent(BannersTrackers.actions.clickOnBanner, {
      origin: '/dashboard',
      banner: image.bannerName,
    })
    if (target === '_blank') window.open(image.url)
    else history.push(image.url)
  }

  instance = { carouselConfigs, onClickBanner }

  return instance
}
