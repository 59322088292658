import {
  type ITooltipContentProps,
  type ITooltipProviderProps,
  Tooltip,
} from '@77sol-ui/molecules'
import { StyledLink } from './styles'
import { useCallback, useState } from 'react'

interface ITooltipLockedToFaasProps extends ITooltipContentProps {
  children: React.ReactNode
  delayDuration?: ITooltipProviderProps['delayDuration']
}

export function TooltipLockedToFaas({
  children,
  side = 'bottom',
  sideOffset = 10,
  align = 'center',
  maxWidth = '90dvw',
  textAlign = 'start',
  variant = 'blue',
  delayDuration = 50,
  ...props
}: ITooltipLockedToFaasProps) {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (open) {
        e.preventDefault()
      }
      setOpen(true)
    },
    [open],
  )

  const handleClose = useCallback(
    (event: Event) => {
      if (!open) {
        event.preventDefault()
      }
      setOpen(false)
    },
    [open],
  )

  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger onClick={handleOpen} asChild>
          <div>{children}</div>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content
            align={align}
            maxWidth={maxWidth}
            side={side}
            sideOffset={sideOffset}
            textAlign={textAlign}
            variant={variant}
            {...props}
            onPointerDownOutside={handleClose}
            data-testid="tooltip-locked-to-faas"
          >
            Opa, tá afim de conhecer mais da Plataforma da 77Sol? Fale com{' '}
            <StyledLink
              data-testid="link-to-contact-us"
              href="mailto:produto@77sol.com.br"
            >
              {' '}
              produto@77sol.com.br
            </StyledLink>{' '}
            para saber mais!
            <Tooltip.Arrow variant={variant} />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
