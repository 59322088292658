import { useQuery } from '@tanstack/react-query'
import { getOriginationData } from '../services/api'
import { DASHBOARD_ANALYSIS_ORIGINATION } from '../constants/endpointKeys'
import {
  type IResponseGetOriginationData,
  type IGetOriginationRequest,
} from '../services/types'

interface IUseGetOriginationDataProps extends IGetOriginationRequest {
  onSuccess?: (data: IResponseGetOriginationData) => void
  enabled?: boolean
  onError?: (data: unknown) => void
}

export function useGetOriginationData({
  onSuccess,
  onError,
  enabled,
  params,
}: IUseGetOriginationDataProps) {
  return useQuery({
    queryKey: [DASHBOARD_ANALYSIS_ORIGINATION, params],
    queryFn: async () => {
      return await getOriginationData({
        params,
      })
        .then((data) => {
          onSuccess?.(data)
          return data
        })
        .catch((error: unknown) => {
          onError?.(error)
          throw new Error(error as any)
        })
    },
    enabled,
    retry: false,
  })
}
