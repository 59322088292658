import React from 'react'
import { Fade, Modal, Backdrop, useMediaQuery } from '@material-ui/core'
import { Typography, Spacer, Button, Paper } from '@77sol/core'
import { RefreshIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { useStyles } from '../../styles'

export function ModalDefaultFilter({
  loadingDefaultFilter,
  handleRedefine,
  setModalDefaultFilter,
  modalDefaultFilter,
}) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Modal
      className={classes.modalSm}
      open={modalDefaultFilter}
      onClose={() => {
        setModalDefaultFilter(false)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalDefaultFilter} style={{ border: 'none' }}>
        <Paper
          style={{
            height: 'auto',
            width: 'auto',
            padding: '24px 64px 24px 24px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RefreshIcon
              width={downSm ? '32' : '40'}
              color={palette.primary[300]}
              style={{
                minWidth: downSm ? '32px' : '40px',
                minHeight: downSm ? '32px' : '40px',
              }}
            />
            <Spacer size="16" direction="vertical" />
            <Typography
              type="display_x_small_bold"
              color="primary"
              colorWeight="300"
            >
              Deseja redefinir o filtro?
            </Typography>
          </div>
          <Spacer size="24" direction="horizontal" />
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => {
                setModalDefaultFilter(false)
              }}
              size="small"
              variant="outlined"
              style={{ padding: '15px 22px' }}
            >
              Cancelar
            </Button>
            <Spacer size="8" direction="both" />
            <Button
              onClick={handleRedefine}
              size="small"
              isLoading={loadingDefaultFilter}
            >
              Redefinir
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
