import { SmartCard } from 'components/SmartCard'
import { type IQuotationCardProps } from './types'
import { formatReal } from 'utils/format'
import { AvailableFlag } from 'containers/EquipmentQuotationContainer/components/Flags/AvailableFlag'
import * as S from './styles'
import { DeliveryFlag } from 'containers/EquipmentQuotationContainer/components/Flags/DeliveryFlag'

export function QuotationCard({
  id,
  quotation,
  cannotDelete = false,
  onSelect,
  onDelete,
  isSelected,
}: IQuotationCardProps) {
  const quotationTotalValue = formatReal(quotation?.equipment_value)

  return (
    <SmartCard.Root
      id={id}
      isSelected={isSelected}
      onClick={() => {
        onSelect(quotation)
      }}
    >
      <SmartCard.ButtonClose
        disabled={cannotDelete}
        onClick={() => {
          onDelete?.(quotation.id)
        }}
      />
      <S.FlagContainer>
        <DeliveryFlag />
        <AvailableFlag
          availableAt={quotation.available_at}
          deliveredBy={quotation.delivered_by}
        />
      </S.FlagContainer>
      <SmartCard.Content>
        <SmartCard.ImagesContainer>
          {quotation.logos.map((logo) => (
            <SmartCard.Image key={logo} src={logo} />
          ))}
        </SmartCard.ImagesContainer>

        <SmartCard.BadgeContainer>
          <SmartCard.Badge text={`Valor do Kit: ${quotationTotalValue}`} />
          <SmartCard.Badge text={`Sobrecarga: ${quotation.overload}%`} />
          <SmartCard.Badge
            text={`Potência do sistema: ${quotation.potency} kwp`}
          />
        </SmartCard.BadgeContainer>
      </SmartCard.Content>
    </SmartCard.Root>
  )
}
