import styled from 'styled-components'

interface EquipmentListWrapperProps {
  hasError: boolean
}

export const EquipmentListWrapper = styled.div<EquipmentListWrapperProps>`
  padding: 12px 32px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border: ${({ hasError }) => hasError && '1px solid #EB5757'};
  background-color: ${({ hasError }) => hasError && '#FFF6F8'};

  .infos-wrapper > .badges {
    display: ${({ hasError }) => hasError && 'none !important'};
  }

  @media (max-width: 768px) {
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;

    border: ${({ hasError }) =>
      hasError ? '1px solid #EB5757' : '1px solid #f2f2f2'};
    background-color: ${({ hasError }) => hasError && '#FFF6F8'};
  }
`

export const EquipmentsInfo = styled.div`
  * {
    font-family: 'DM Sans';
  }

  display: flex;
  align-items: center;
  gap: 24px;

  img {
    min-width: 72px;
    max-width: 72px;
    min-height: 72px;
    max-height: 72px;
  }

  .infos-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .badges {
      display: flex;
      gap: 8px;

      @media (max-width: 768px) {
        order: 2;
      }
    }

    .info {
      p:first-child {
        font-family: DM Sans;
        font-size: 17px;
        color: #4f4f4f;
        font-weight: 400;
        letter-spacing: 0.75px;
      }

      p.disponibility {
        margin-top: 4px;
        color: #828282;
        font-family: DM Sans;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.75px;
      }

      p.provider-error {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 4px;

        color: #14142b;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        svg {
          min-width: 20px;
          color: #eb5757;
        }
      }

      @media (max-width: 1024px) {
        p:first-child {
          max-width: 280px;
        }
      }

      @media (max-width: 768px) {
        order: 1;
        p:first-child {
          font-size: 14px;
          max-width: none;
        }

        p:last-child {
          margin-top: 12px;
          font-size: 12px;
        }
      }
    }

    @media (max-width: 768px) {
      gap: 12px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    img {
      display: none;
    }
  }
`

export const EquipmentsActions = styled.div<EquipmentListWrapperProps>`
  p {
    font-size: 14px;
    font-weight: 400;
    font-family: DM Sans;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-top: 4px;

    justify-content: ${({ hasError }) => hasError && 'space-between'};

    & .provider-error-button {
      font-weight: 700;
      font-size: 16px;
      border: 1px solid #eb5757;
      color: #eb5757;
      background-color: #fff6f8;

      &:hover {
        background-color: #f4e5e9;
      }
    }

    button > span {
      font-family: 'DM Sans';
    }

    @media (max-width: 769px) {
      > div {
        width: 100%;
      }

      button {
        span {
          display: none;
        }
      }
    }

    .change-equipment-button,
    .provider-error-button {
      > span {
        display: none;
      }

      @media (min-width: 769px) {
        > span {
          display: inline;
        }
      }
    }
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`
