import { useEffect } from 'react'
import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'
import { useLoadKits } from './useLoadKits'

interface IUseEquipmentQuotationContainer {
  dimensioningId: string
}

export function useEquipmentQuotationContainer({
  dimensioningId,
}: IUseEquipmentQuotationContainer) {
  const setKits = useEquipmentContainerStore((state) => state.setKits)
  const setSelectedKits = useEquipmentContainerStore(
    (state) => state.setSelectedKits,
  )

  const { kits, selectedKits } = useLoadKits(dimensioningId)

  setKits(kits)
  setSelectedKits(selectedKits)

  const reset = useEquipmentContainerStore((state) => state.reset)
  const setDimensioningId = useEquipmentContainerStore(
    (state) => state.setDimensioningId,
  )

  useEffect(() => {
    setDimensioningId(Number(dimensioningId))
    return () => {
      reset()
    }
  }, [reset, dimensioningId, setDimensioningId])

  const openModalToDeleteQuotation = useEquipmentContainerStore(
    (state) => state.openModalToDeleteQuotation,
  )
  const openModalToCancelProposal = useEquipmentContainerStore(
    (state) => state.openModalToCancelProposal,
  )
  const openModalToCreateProject = useEquipmentContainerStore(
    (state) => state.openModalToCreateProject,
  )
  const openModalQuotationDiscount = useEquipmentContainerStore(
    (state) => state.openModalQuotationDiscount,
  )

  return {
    openModalToDeleteQuotation,
    openModalToCancelProposal,
    openModalToCreateProject,
    openModalQuotationDiscount,
  }
}
