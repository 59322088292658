import { Plus } from 'lucide-react'
import { ButtonDesktop, ButtonMobile } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'

export function ButtonAddQuotation() {
  const { logEvent } = useAmplitude()

  const currentTab = useEquipmentContainerStore((state) => state.currentTab)
  const handleChangeTab = useEquipmentContainerStore((state) => state.changeTab)

  function handleCreateNewQuotation() {
    logEvent(quotationTracker.actions.newQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })
    handleChangeTab(TAB_ENUM.CHOOSE_KIT)
  }

  return (
    <>
      <ButtonDesktop variant="outline" onClick={handleCreateNewQuotation}>
        Adicionar cotação
      </ButtonDesktop>

      <ButtonMobile
        fullWidth
        variant="secondary"
        onClick={handleCreateNewQuotation}
      >
        <Plus size={20} />
        <span>Adicionar cotação</span>
      </ButtonMobile>
    </>
  )
}
