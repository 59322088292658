import * as S from './styles'
import { CompareKitCard } from '../CompareKitCard'
import { useCompareKitCardsContainer } from './hooks/useCompareKitCardsContainer'

export function CompareKitCardsContainer() {
  const { quotations } = useCompareKitCardsContainer()
  return (
    <S.Container>
      {quotations.map((quotation) => (
        <CompareKitCard key={quotation.id} quotation={quotation} />
      ))}
    </S.Container>
  )
}
