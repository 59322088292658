import React, { useMemo } from 'react'
import moment from 'moment'
import { formatDate } from 'utils/moment'
import { type IOrderDTO } from 'dto/OrderDTO'
import { Typography } from 'components'

import * as S from './styles'
import { ORDER_CLOSED_STATUS } from 'views/Pedidos/constants'

interface ScheduledDeliveryProps {
  order: IOrderDTO
}

const ScheduledDelivery: React.FC<ScheduledDeliveryProps> = ({ order }) => {
  const orderIsClosed = ORDER_CLOSED_STATUS?.includes(Number(order?.status))
  const entrega = useMemo(() => {
    const { prazo_entrega, delivery_forecast, status_interno_id } = order
    const delivered = moment(prazo_entrega).isValid() && status_interno_id === 9
    const dateDelivered = formatDate(
      delivered ? prazo_entrega : delivery_forecast,
    )
    return {
      label: delivered ? 'Entregue em:' : 'Previsão de entrega:',
      date: dateDelivered || 'Aguardando previsão',
    }
  }, [order])

  return (
    <S.DatesContainer>
      {order.data && (
        // @ts-expect-error typography
        <Typography>
          Data do pedido:&nbsp;
          {formatDate(order.data)}
        </Typography>
      )}
      {order.data_pagamento && (
        // @ts-expect-error typography
        <Typography>
          Data de Pagamento:&nbsp;
          {formatDate(order.data_pagamento)}
        </Typography>
      )}
      {!orderIsClosed && (
        // @ts-expect-error typography
        <Typography>{`${entrega.label} ${entrega.date}`}</Typography>
      )}
    </S.DatesContainer>
  )
}

export default ScheduledDelivery
