import React, { useState } from 'react'
import { Input } from 'components'
import API from '../../../../../../api'

function CorrecaoAnualCard(props) {
  const tokenRegister = localStorage.getItem('sessionToken')
  const { returnData, selectedQuote, setReturnData } = props

  const [newValue, setNewValue] = useState()
  const [loading, setLoading] = useState(false)

  const handleChangeValue = () => {
    setLoading(true)

    const finalValue = newValue
      ? parseFloat(newValue.replace(',', '.'))
      : parseFloat((returnData.correcaoAnualTarifa * 100).toFixed(0))

    API.post(
      '/proposal/dimensioning/calculate/viability',
      {
        quotation_id: selectedQuote,
        tariff: returnData.tarifaConsiderada,
        annual_correction: finalValue,
        opex: returnData.opex ? returnData.opex : 0,
        project_total_cost: returnData.custoTotalProjeto,
      },
      {
        headers: {
          token: tokenRegister,
        },
      },
    )
      .then((res) => {
        setReturnData(res.data)
        setLoading(false)
      })
      .catch((err) => {})
  }

  return (
    <div>
      <Input
        label="Correção anual"
        loading={loading}
        defaultValue={parseFloat(
          (returnData?.correcaoAnualTarifa * 100).toFixed(0),
        )}
        type="number"
        value={newValue}
        size="small"
        setValue={setNewValue}
        onBlur={handleChangeValue}
        submit={handleChangeValue}
        adornment="%"
        adornmentPosition="right"
      />
    </div>
  )
}

export default CorrecaoAnualCard
