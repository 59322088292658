import { useEffect } from 'react'
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
} from './index'
import { useLocation } from 'react-router-dom'
import { useAcl } from 'acl/hooks/useAcl'
import { UserTypeDictionary } from 'acl/config/types'
import { useSelector } from 'react-redux'

export const IntercomProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const location = useLocation()

  // @ts-expect-error redux no types
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)

  const { userType } = useAcl()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadIntercom()
      bootIntercom({
        name: userLogged?.[0]?.nome,
        email: userLogged?.[0]?.email,
        phone: userLogged?.[0]?.celular,
        cnpj: userLogged?.[0]?.cnpj,
        closer: userLogged?.[0]?.nomeResponsavel,
        user_type: UserTypeDictionary[userType],
      })
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        updateIntercom()
      }
    }

    handleRouteChange()
  }, [location])

  return children
}
