import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 16px 0;

  svg {
    color: ${({ theme }) => theme.colors.gray[300]};
  }
  span {
    color: ${({ theme }) => theme.colors.gray[300]};
  }
`
