import { useCancelProposal } from 'containers/EquipmentQuotationContainer/hooks/useCancelProposal'
import { Modal } from '@77sol-ui/molecules'
import { Info, Loader2 } from 'lucide-react'
import { Button } from '@77sol-ui/atoms'
import * as S from './styles'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function ModalConfirmProposalCancellation() {
  const { onCancelProposal, isLoading } = useCancelProposal()

  const setOpenModalToCancelProposal = useEquipmentContainerStore(
    (state) => state.setOpenModalToCancelProposal,
  )

  return (
    <Modal.Root open onOpenChange={setOpenModalToCancelProposal}>
      <Modal.Portal>
        <Modal.Overlay />

        <Modal.Wrapper
          maxWidth="640px"
          maxHeight="90vh"
          id="modal-cancel-proposal"
        >
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={Info} />
            <Modal.Title>
              Tem certeza que deseja cancelar essa cotação?
            </Modal.Title>
            <Modal.Description>
              Ao sair da cotação todas as informações serão perdidas.
            </Modal.Description>
          </Modal.Header>

          <S.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline" disabled={isLoading}>
                Voltar
              </Button>
            </Modal.Close>

            <Button fullWidth disabled={isLoading} onClick={onCancelProposal}>
              {isLoading ? (
                <Loader2 size={24} className="atm-animate-spinSlow" />
              ) : (
                ' Cancelar cotação'
              )}
            </Button>
          </S.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
