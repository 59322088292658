import { useAmplitude } from 'hooks/useAmplitude'
import { useHistory } from 'react-router-dom'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import {
  TRACKER_OPTIONS_DEFAULT,
  TRACKER_OPTIONS_FINANCING,
} from '../constants/TrackerOptions'

interface IUseFooterActionsProps {
  financingId: number | null
  orderId: number
  onClose: () => void
}

const ROUTES = {
  financing: (financingId: number) => `/financiamentos/valor/${financingId}`,
  orders: '/pedidos',
  orderDetails: (orderId: number) => `/pedidos/${orderId}`,
}

export const useFooterActions = ({
  financingId,
  orderId,
  onClose,
}: IUseFooterActionsProps) => {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const eventOptions = financingId
    ? TRACKER_OPTIONS_FINANCING
    : TRACKER_OPTIONS_DEFAULT

  const continueText = financingId ? 'Atrelar pedido' : 'Acelerar confirmação'

  const exitText = 'Agora não'

  const handleContinue = () => {
    const event = financingId
      ? ordersTracker.successOrderModal.valueFinancing.orderToValueFinancing
      : ordersTracker.successOrderModal.default.orderToPending

    const route = financingId
      ? ROUTES.financing(financingId)
      : ROUTES.orderDetails(orderId)

    logEvent(event, eventOptions)
    history.push(route)
    onClose()
  }

  const handleExit = () => {
    const event = financingId
      ? ordersTracker.successOrderModal.valueFinancing.orderToOrderList
      : ordersTracker.successOrderModal.default.orderToOrderList

    logEvent(event, eventOptions)
    history.push(ROUTES.orders)
    onClose()
  }

  return { handleContinue, handleExit, continueText, exitText }
}
