import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1px',
    padding: '8px 0',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  amount: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: 20,
  },
})
