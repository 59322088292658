import React from 'react'
import { Box, Divider, Grid } from '@material-ui/core'
import { LabelItem } from '../components'
import styles from './styles'

export function Faturamento({ values }) {
  const classes = styles()

  return (
    <Box p={3} className={classes.container}>
      <Grid container spacing={4}>
        <Grid item sm="6">
          <LabelItem
            title="Nome/Razão Social:"
            text={values.nome || values.razaoSocial}
          />
        </Grid>
        <Grid item sm="6">
          <LabelItem title="CPF/CNPJ" text={values.doc} />
        </Grid>
        <Grid item sm={12}>
          <Divider />
        </Grid>
        <Grid item sm={12}>
          <LabelItem title="Endereço (logradouro):" text={values.endereco} />
        </Grid>
        <Grid item sm={4}>
          <LabelItem title="Número:" text={values.numero} />
        </Grid>
        <Grid item sm={4}>
          <LabelItem
            title="Complemento:"
            text={values.complemento || 'Sem complemento'}
          />
        </Grid>
        <Grid item sm={4}>
          <LabelItem title="Bairro:" text={values.bairro} />
        </Grid>
        <Grid item sm={4}>
          <LabelItem title="CEP:" text={values.cep} />
        </Grid>
        <Grid item sm={4}>
          <LabelItem title="Cidade:" text={values.cidade} />
        </Grid>
        <Grid item sm={4}>
          <LabelItem title="Estado:" text={values.estado} />
        </Grid>
      </Grid>
    </Box>
  )
}
