import { useAcl } from 'acl/hooks/useAcl'
import {
  SIDEBAR_DESKTOP_LOGO,
  SIDEBAR_MOBILE_LOGO,
} from 'components/Sidebar/constants'
import { useAmplitude } from 'hooks/useAmplitude'
import { useSignals } from 'layouts/Dashboard/components/NavBar/components/NavigationListItem/hooks/useSignals'
import { useHistory } from 'react-router-dom'
import { type INewSidebarNavMenuItems } from '../constants/integratorNavbarItems'
import { useWatchRoutes } from 'hooks/useWatchRoutes'
import { useSidebarDesktopStore } from 'store/modals/SidebarStore'

export function useSidebarDesktop() {
  const history = useHistory()
  const { checkIfUserCanAccessRoute } = useAcl()
  const { logEvent } = useAmplitude()
  const { signals } = useSignals()
  const isOpen = useSidebarDesktopStore((state) => state.isOpen)
  const setIsOpen = useSidebarDesktopStore((state) => state.setIsOpen)

  const validatePermission = (page: INewSidebarNavMenuItems) => {
    return checkIfUserCanAccessRoute(page.href)
  }

  function onRedirect(item: INewSidebarNavMenuItems) {
    logEvent(`Menu Lateral esquerdo: ${item.title}`)
  }

  function handleControlSidebar(currentState: boolean) {
    localStorage.setItem('@77sol-admin:sidebarExpanded', String(currentState))
    setIsOpen(currentState)
  }

  const LOGO = isOpen ? SIDEBAR_DESKTOP_LOGO : SIDEBAR_MOBILE_LOGO

  useWatchRoutes({
    routes: ['/'],
    active: !localStorage.getItem('@77sol-admin:sidebarExpanded'),
    onAccessedTheRoute: () => {
      setIsOpen(true)
    },
    onNoRouteAccessed: () => {
      setIsOpen(false)
    },
  })

  return {
    isExpanded: isOpen,
    validatePermission,
    onRedirect,
    handleControlSidebar,
    history,
    LOGO,
    signals,
  }
}
