import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  svg {
    color: #828282;
    width: 24px;
  }

  @media (max-width: 768px) {
    gap: 18px;
    display: none;
  }
`
