import { Input } from '@77sol-ui/atoms'
import { useCreateFinancingPerQuotationFormContext } from '../../hooks/useCreateFinancingPerQuotationForm'
import { ClearFieldsButton } from '../ui/ClearFieldsButton'
import { RadioPersonType } from '../ui/RadioPersonType'
import * as S from './styles'

export function Commom() {
  const {
    register,
    formState: { errors },
  } = useCreateFinancingPerQuotationFormContext()

  return (
    <S.Container>
      <section>
        <h3>Dados pessoais do cliente</h3>

        <div className="manage-person-type">
          <RadioPersonType />
          <ClearFieldsButton>Limpar dados</ClearFieldsButton>
        </div>

        <Input
          label="Nome do Solicitante"
          placeholder="José Maria"
          type="text"
          error={errors.client_name?.message}
          {...register('client_name')}
        />
      </section>
    </S.Container>
  )
}
