import { DropdownMenu, type IButtonProps } from '@77sol-ui/atoms'
import { useRequestQuotation } from './hooks/useRequestQuotation'
import { DropdownButton, DropdownContent, DropdownItem } from './styles'
import { useState } from 'react'

interface IQuotationButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  onOpenedDropdown?: (value: boolean) => void
}

export function QuotationButton({
  text = 'Cotar Equipamentos',
  onOpenedDropdown,
  ...props
}: IQuotationButtonProps & IButtonProps) {
  const [open, setOpen] = useState(false)
  const { handleQuoteEquipment, handleNewProposal } = useRequestQuotation()

  function handleOpenDropdown() {
    setOpen((oldValue) => !oldValue)
    onOpenedDropdown?.(!open)
  }

  return (
    <DropdownMenu.Root open={open} onOpenChange={handleOpenDropdown}>
      <DropdownMenu.Trigger asChild>
        <DropdownButton
          variant="outline"
          data-testid="quotation-dropdown-button"
          {...props}
        >
          {text}
        </DropdownButton>
      </DropdownMenu.Trigger>
      <DropdownContent sideOffset={10}>
        <DropdownItem
          data-testid="dropdown-item-quote-equipment"
          onClick={() => {
            handleQuoteEquipment({ triggeredByDropdown: true })
          }}
        >
          Cotação rápida
        </DropdownItem>
        <DropdownItem
          data-testid="dropdown-item-new-dimensioning"
          onClick={() => {
            handleNewProposal({ triggeredByDropdown: true })
          }}
        >
          Dimensionar e cotar
        </DropdownItem>
      </DropdownContent>
    </DropdownMenu.Root>
  )
}
