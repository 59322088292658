import React, { Fragment, Suspense, useState, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { RouteWithSubRoutes } from 'components'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 3,
    width: 206,
    minWidth: 206,
    flex: '0 0 auto',
    overflowY: 'auto',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
}))

const Preview = props => {
  const { route } = props

  const classes = useStyles()

  const renderRoutesFunction = route?.route?.map((route, i) => (
    <RouteWithSubRoutes key={i} {...route} />
  ));

  useEffect(() => {
    var page = window.location.pathname
    setPagePath(page)
  }, [renderRoutesFunction])

  const [, setPagePath] = useState()

  return (
    <Fragment>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {route?.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Suspense>
      </main>
    </Fragment>
  )
}

Preview.propTypes = {
  route: PropTypes.object,
}

export default Preview
