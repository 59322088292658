import * as yup from 'yup'
import { type PersonType } from '../types'
import { parse } from 'date-fns'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

const formatDateToValid = (originalValue: string) => {
  if (originalValue.length < 10) return
  const parsedDate = parse(originalValue, 'dd/MM/yyyy', new Date())
  if (parsedDate < new Date()) return parsedDate
}

export const baseSchema = yup.object().shape({
  title: yup
    .string()
    .required('Insira o título da solicitação.')
    .min(4, 'O campo precisa ter no mínimo 4 caracteres.'),
  client_type: yup.string().required(),
  client_name: yup
    .string()
    .required('Insira o nome do solicitante.')
    .min(4, 'O campo precisa ter no mínimo 4 caracteres.'),
  client_birth_date: yup.date().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup
        .date()
        .transform((_, originalValue) => formatDateToValid(originalValue))
        .required('Insira a data de nascimento.')
        .typeError('Insira a data de nascimento.'),
    otherwise: () =>
      yup
        .date()
        .transform((_, originalValue) => formatDateToValid(originalValue))
        .required('Insira a data de fundação.')
        .typeError('Insira a data de fundação.'),
  }),
  client_document: yup.string().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup.string().required('Insira um CPF.').cpf('Insira um CPF válido.'),
    otherwise: () =>
      yup.string().required('Insira um CNPJ.').cnpj('Insira um CNPJ válido.'),
  }),
  client_income: yup.number().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup
        .number()
        .min(1, 'Insira a renda mensal.')
        .typeError('Insira a renda mensal.')
        .required('Insira a renda mensal.'),
    otherwise: () =>
      yup
        .number()
        .min(1, 'Insira o faturamento anual.')
        .typeError('Insira o faturamento anual.')
        .required('Insira o faturamento anual.'),
  }),
  grace_days: yup.string().required('Selecione o tempo de carência.'),
  total_value: yup
    .number()
    .min(1, 'Insira o valor.')
    .required('Insira o valor.')
    .typeError('Insira o valor.'),
})

export const roleAdminSchema = baseSchema.concat(
  yup.object().shape({
    integrator: yup.object().shape({
      id: yup.string().required('Selecione um integrador.'),
      cnpj: yup.string().nullable(),
      companyName: yup.string().nullable(),
      email: yup.string(),
      name: yup.string(),
      tradingName: yup.string().nullable(),
      uf: yup.string().nullable(),
    }),
  }),
)
