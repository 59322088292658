import styled from 'styled-components'

export const Wrapper = styled.div`
  height: calc(var(--app-height) - 78px);
  overflow: hidden;
  position: relative;

  @media (min-width: 1280px) {
    height: calc(var(--app-height) - 88px);
  }
`

export const Container = styled.div`
  height: 100%;
  display: flex;
  position: relative;
`

export const Content = styled.div`
  flex-grow: 1;
  padding-left: 32px;
  padding-right: 32px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
  min-height: 75px;
  flex-direction: column;

  @media (min-width: 1280px) {
    flex-direction: row;
  }
`

export const ListContainer = styled.div`
  height: calc(var(--app-height) - 260px);
  overflow: auto;

  @media (min-width: 1280px) {
    height: calc(var(--app-height) - 210px);
  }
`
