import { Select } from '@77sol-ui/molecules'
import { SelectItemButton } from './styles'
import { type ISelectQuotationProps } from './types'
import { useSelectQuotation } from './hooks/useSelectQuotation'

export const SelectQuotation = ({
  onAddQuotation,
  quotations,
  onQuotationSelect,
  quotationSelected,
  isLoading,
}: ISelectQuotationProps) => {
  const { handleValueChange, selectedQuotationId } = useSelectQuotation({
    onAddQuotation,
    quotations,
    onQuotationSelect,
    quotationSelected,
  })

  return (
    <Select.Root value={selectedQuotationId} onValueChange={handleValueChange}>
      <Select.Trigger isLoading={isLoading}>
        <Select.Value />
        <Select.Icon isLoading={isLoading} />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content>
          <Select.Viewport>
            <SelectItemButton value="create-quotation">
              Adicionar nova cotação
            </SelectItemButton>
            {quotations.map((quotation) => (
              <Select.Item
                key={quotation.id}
                value={quotation.id.toString()}
              >{`Cotação #${quotation.id}`}</Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
