import { useContentHeight } from './hooks/useContentHeight'
import * as S from './styles'

interface IModalContentProps {
  children: React.ReactNode
  scrollOn?: number
}

const ModalContent = ({ children }: IModalContentProps) => {
  const { hasScroll, contentRef } = useContentHeight()

  return (
    <S.ModalContent ref={contentRef} isScrolling={hasScroll}>
      {children}
    </S.ModalContent>
  )
}

export { ModalContent }
