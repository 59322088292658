import React from 'react'
import { Modal, Fade, Backdrop } from '@material-ui/core'
import { Button, Spacer, Paper, Typography } from '@77sol/core'
import { useStyles } from './styles'

export function ModalRemoveCustomField({
  deletingField,
  setDeletingField,
  handleRemoveField,
  loadingExtras,
}) {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modalSm}
      open={deletingField}
      onClose={() => setDeletingField('')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={deletingField} style={{ border: 'none' }}>
        <Paper style={{ height: 'auto', width: 'auto' }}>
          <Typography size="xsmall" color="primary" colorWeight="700">
            Deseja realmente remover este campo personalizado?
          </Typography>
          <Spacer size="24" direction="horizontal" />
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleRemoveField(deletingField)}
              size="small"
              color="red"
              isLoading={loadingExtras}
            >
              Remover
            </Button>
            <Spacer size="8" direction="both" />
            <Button
              onClick={() => setDeletingField('')}
              size="small"
              variant="outlined"
            >
              Cancelar
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  )
}
