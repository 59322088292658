import useObserveQuery from 'hooks/useObserverQuery'
import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'

export function useValidateKit() {
  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  function validateAllQuotations() {
    const hasKitChanged = data?.quotations.some((quotation) => {
      return quotation.products.some((product) => {
        return Boolean(product.has_error || product.is_changed)
      })
    })

    const hasAddedProducts = data?.quotations.some(
      (quotation) =>
        quotation?.added_products && quotation.added_products.length > 0,
    )

    const hasExcludedItems = data?.quotations.some(
      (quotation) => quotation?.has_excluded_items,
    )

    return hasKitChanged || hasAddedProducts || hasExcludedItems
  }

  function checkIfQuotationHasChanged() {
    const hasItensChangedOrWithError = quotationSelected?.products.some(
      (product) => product?.is_changed || product.has_error,
    )

    const hasAddedProducts =
      quotationSelected?.added_products &&
      quotationSelected?.added_products?.length > 0

    return (
      hasItensChangedOrWithError ||
      hasAddedProducts ||
      quotationSelected?.has_excluded_items
    )
  }

  return {
    validateAllQuotations,
    checkIfQuotationHasChanged,
  }
}
