import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'

export const useReserveEquipments = () => {
  const checkoutModal = useCheckoutModalStore((state) => state)

  const handleReserveEquipments = (quotationId: number) => {
    checkoutModal.setIsOpen(true)
    checkoutModal.setQuotationId(quotationId)
  }

  return { handleReserveEquipments }
}
