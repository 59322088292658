import { type ReactElement } from 'react'
import * as S from './styles'

export interface ItemButtonProps {
  icon: ReactElement
  tag?: string
  label: string
  description?: string
  highlightDescription?: boolean
  onClick: () => void
}

export const ItemButton = ({
  icon: Icon,
  label,
  description,
  tag,
  highlightDescription,
  onClick,
}: ItemButtonProps) => {
  return (
    <S.Container onClick={onClick} tag={tag}>
      <S.Content tag={tag} highlightDescription={highlightDescription}>
        <div className="block">{Icon}</div>
        <div className="context">
          <span>{label}</span>
          <span>{description}</span>
        </div>
      </S.Content>
    </S.Container>
  )
}
