import React, { type ChangeEvent } from 'react'
import {
  AutoComplete,
  type IAutoCompleteInputProps,
  type IAutoCompleteContentProps,
} from '@77sol-ui/molecules'
import { useSearchIntegrator } from '../../domains/integrators/hooks/useSearchIntegrator'
import { Search } from 'lucide-react'
import { useSearchIntegratorInput } from './hooks/useSearchIntegratorInput'
import { type IIntegrator } from '../../domains/integrators/services/types'

import * as S from './styles'

export interface ISearchIntegratorInputProps
  extends Omit<Omit<IAutoCompleteInputProps, 'name'>, 'onChange'> {
  name?: string
  onChange?: (integrator: IIntegrator | undefined) => void
  defaultIntegratorValue?: IIntegrator
  contentSide?: 'top' | 'bottom'
  offset?: IAutoCompleteContentProps['offset']
  label?: string
}

const SearchIntegratorInput: React.FC<ISearchIntegratorInputProps> = ({
  onChange,
  name = 'integrator',
  defaultIntegratorValue,
  contentSide = 'bottom',
  offset,
  label = 'Integrador',
  ...props
}) => {
  const {
    optionsIsOpen,
    searchParam,
    setOptionsIsOpen,
    setIsSelected,
    inputValue,
    setInputValue,
  } = useSearchIntegratorInput(defaultIntegratorValue)

  const { data, isLoading } = useSearchIntegrator(searchParam)

  const integrators = data?.data ?? []

  const showIntegrators = integrators.length > 0 && !isLoading

  const handleCickIntegrator = (integrator: IIntegrator) => {
    setIsSelected(true)
    setOptionsIsOpen(false)
    setInputValue(integrator.name)
    if (onChange) {
      onChange(integrator)
    }
  }
  const integratorNotFound = searchParam && !integrators.length && !isLoading

  const formatOptionText = (integrator: IIntegrator) => {
    const companyName = integrator?.companyName
      ? `- ${integrator.companyName}`
      : ''
    return `${integrator.name} ${companyName} (${integrator.email})`
  }

  return (
    <S.InputContainer isLoading={isLoading}>
      <AutoComplete.Root>
        <AutoComplete.Input
          onFocus={() => {
            setOptionsIsOpen(true)
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
            if (onChange && inputValue !== searchParam) onChange(undefined)
          }}
          value={inputValue}
          {...props}
          name={name}
          label={label}
          loading={isLoading}
          icon={Search}
          placeholder="Buscar integrador"
          className="inputSearch"
        />
        {optionsIsOpen && (
          <>
            <AutoComplete.Content
              data-testid="content"
              className="content"
              offset={offset}
              side={contentSide}
            >
              {showIntegrators &&
                integrators?.map((integrator) => (
                  <AutoComplete.Item
                    key={integrator?.id}
                    highlight={inputValue}
                    data-testid={`${integrator.name}-item`}
                    onClick={() => {
                      handleCickIntegrator(integrator)
                    }}
                  >
                    {formatOptionText(integrator)}
                  </AutoComplete.Item>
                ))}
              {integratorNotFound && (
                <AutoComplete.Empty data-testid="integrator-not-found">
                  Integrador não encontrado.
                </AutoComplete.Empty>
              )}
            </AutoComplete.Content>
            <AutoComplete.Backdrop
              data-testid="auto-complete-backdrop"
              onClick={() => {
                setOptionsIsOpen(false)
              }}
            />
          </>
        )}
      </AutoComplete.Root>
    </S.InputContainer>
  )
}

export { SearchIntegratorInput }
