export function validateEmail(value = '') {
  const validator = {
    spaces: !/(\s)/g.test(value),
    dots: !/(^\.)|(\.\.)|(\.$)/gi.test(value),
    chatacters: !/(?![-+.@]|\w)(.)/gi.test(value),
    format: /^(.+)(@)(\w+)(\.)((\w+)|(\w+\.+\w+))$/gi.test(value),
  }

  return Object.values(validator).every((test) => test)
}
