import { ModalSelectClient as ModalSelectClientOld } from 'views/DimensionarV2/components/StepTwo/components'
import { useModalSelectClient } from './hooks/useModalSelectClient'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { getAllCustomers } from 'utils/requests'
import { type ICreateProjectResponse } from 'domains/dimensioning/services/types'

export function ModalSelectClient() {
  const { openModalToCreateProject, setOpenModalToCreateProject } =
    useEquipmentContainerStore()
  const { data, proposal, onCreateProject } = useModalSelectClient()

  return (
    <ModalSelectClientOld
      proposal={proposal}
      clients={data}
      getClients={getAllCustomers}
      selectClientModal={openModalToCreateProject}
      setSelectClientModal={() => {
        setOpenModalToCreateProject(false)
      }}
      fromStepFour={false}
      proposalListHandleClose={null}
      onCreateProject={(data: ICreateProjectResponse) => {
        onCreateProject(data)
      }}
    />
  )
}
