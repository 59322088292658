import useWindowSize from 'hooks/useWindowSize'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import { Tablet } from './Tablet'
import { type IHeaderRootProps } from '@77sol-ui/organisms'

export function Header({ ...props }: Omit<IHeaderRootProps, 'children'>) {
  const { width } = useWindowSize()

  const isMobile = width < 768
  const isTablet = width >= 768 && width < 1280

  const dataTestId = 'header'

  if (isMobile) {
    return <Mobile data-testid={dataTestId} {...props} />
  }

  if (isTablet) {
    return <Tablet data-testid={dataTestId} {...props} />
  }

  return <Desktop data-testid={dataTestId} {...props} />
}
