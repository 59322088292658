import * as S from './styles'

import { CancelButton } from './components/CancelButton'
import { SaveAndQuitButton } from './components/SaveAndQuitButton'

export function Header() {
  return (
    <S.HeaderWrapper>
      <div className="title">
        <h1>Escolha e compare os melhores kits</h1>
        <p>
          Separamos os melhores kits disponíveis agora no mercado para você
          fechar negócio.
        </p>
      </div>

      <div className="actions">
        <CancelButton />
        <SaveAndQuitButton />
      </div>
    </S.HeaderWrapper>
  )
}
