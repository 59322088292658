import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type IActions, type IState } from './types'

const INICIAL_VALUES: IState = {
  proposalID: null,
  isFinancingPerQuotationModalOpen: false,
}

export const useCreateFinancingPerQuotationModalStore = create<
  IState & IActions
>()(
  devtools(
    (set) => ({
      ...INICIAL_VALUES,
      setProposalID: (proposalID) => {
        set({ proposalID })
      },
      setFinancingPerQuotationModalOpen: (proposalID) => {
        set({ proposalID, isFinancingPerQuotationModalOpen: true })
      },
      setFinancingPerQuotationModalClose: () => {
        set({ isFinancingPerQuotationModalOpen: false })
      },
    }),
    { name: 'CreateFinancingPerQuotationModalStore' },
  ),
)
