import logo_77sol from '../../assets/img/favicon-77sol.svg'

export const defaultConfig = {
  enterpriseName: '77Sol',
  stockName: 'Estoque 77Sol',
  requestSiteVideoUrl: 'https://www.youtube.com/embed/q8WkM3cbHdg?controls=0',
  terms_of_use:
    'https://77solassets.s3.sa-east-1.amazonaws.com/TERMOS_DE_USO_V7.pdf',
  termsUse: true,
  titlePage: (page: string) => `${page} | 77Sol`,
  orderButtonTitle: 'Reservar equipamentos',
  whatsappNumber:
    'https://api.whatsapp.com/send?phone=5511930607771&text=Ol%C3%A1!%20Tenho%20uma%20dúvida!',
  wantProUserContactUsWhatsapp:
    'https://api.whatsapp.com/send?phone=5511964619033&text=Ol%C3%A1!%20Gostaria%20de%20ser%20um%20usu%C3%A1rio%20PRO',
  budgetTitle: 'Qual orçamento deseja reservar',
  budgetSubtitle:
    'Selecione o orçamento dentro da proposta selecionada que deseja reservar os equipamentos',
  budgetButtonTitle: 'Reservar',
  phoneNumber: 551145504601,
  orderGeneratedText: 'Reservado',
  textToGenerateOrder: 'Reservar',
  showBestOption: true,
  deliveryForecast: true,
  planBadge: true,
  hideQuotationFilterColumn: true,
  hasFinancingPerValue: true,
  pdfTab: {
    maxHeight: '40px',
  },
  quotationModal: {
    deliveryState: true,
    hasFilterProperty: true,
    canShowFilterInversor: true,
  },
  stepOne: {
    filters: {
      phaseAndVoltage: {
        hasFilter: true,
        isRequired: false,
      },
    },
    deliveryState: true,
  },
  pagamentoEdit: {
    defaultBank: '77Sol',
    imageBank: logo_77sol,
    monthlyInterestRate: '0.79',
    optionsEntrie: [
      { label: '0%', value: '0.00' },
      { label: '10%', value: '0.10' },
      { label: '20%', value: '0.20' },
      { label: '30%', value: '0.30' },
      { label: '40%', value: '0.40' },
      { label: '50%', value: '0.50' },
    ],
    optionsShortage: [
      { label: '30 dias', value: 30 },
      { label: '90 dias', value: 90 },
      { label: '120 dias', value: 120 },
      { label: '150 dias', value: 150 },
      { label: '180 dias', value: 180 },
    ],
    optionsInstallments: [
      { label: '12 parcelas', value: 12 },
      { label: '24 parcelas', value: 24 },
      { label: '36 parcelas', value: 36 },
      { label: '48 parcelas', value: 48 },
      { label: '60 parcelas', value: 60 },
      { label: '72 parcelas', value: 72 },
      { label: '84 parcelas', value: 84 },
      { label: '96 parcelas', value: 96 },
      { label: '108 parcelas', value: 108 },
      { label: '120 parcelas', value: 120 },
    ],
  },
  teamName: 'Time Comercial 77Sol',
  formatProductName: () => '',
  logoHeight: '100px',
  domainName: '.77sol.com.br',
  quotationNegociation: true,
  defaultDiscount: true,
  leadType: true,
  canReport: true,
  newStatusOrder: true,
  canRemakeOrder: true,
  tariffRole: true,
}
