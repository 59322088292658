import QueryString from 'qs'

export function useWhatsappSend() {
  const handleWhatsAppSend = (phone: string, text: string) => {
    if (!phone) return
    const params = QueryString.stringify({ text })
    const phoneToSend = phone.replace(/\D/g, '')
    window.open(`https://wa.me/55${phoneToSend}?${params}`)
  }

  return {
    handleWhatsAppSend,
  }
}
