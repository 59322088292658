import { useFetchOrdersStatus } from 'domains/loja/loja-pedidos/hooks/useOrderStatus'
import { useAmplitude } from 'hooks/useAmplitude'
import { useParams } from 'hooks/useParams'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { ordersTracker } from 'services/tracker/events/orders/trackers'

type AmplitudeFields = Record<string, string>

export interface FilterFields {
  status: string
  date_initial: string | null
  date_end: string | null
  search: string | null
}

export function useOrderFilters() {
  const { orderStatus = [] } = useFetchOrdersStatus()
  const [isOpenFilterSlide, setIsOpenFilterSlide] = useState(false)
  const { logEvent } = useAmplitude()
  const { history, params } = useParams()
  const { handleSubmit, control, setValue, reset } = useForm<FilterFields>({
    defaultValues: {
      status: '',
      date_initial: null,
      date_end: null,
      search: null,
    },
  })

  const filters = useWatch({
    control,
  })

  useEffect(() => {
    const paramsKeys = Array.from(params.entries()).filter(
      (item) => item[0] !== 'page',
    )
    for (const param of paramsKeys) {
      const field = param[0] as any
      const value = param[1]
      setValue(field, value)
    }
  }, [params, setValue])

  function handleFilter(filters: FilterFields) {
    const filteredFields: AmplitudeFields = {}
    Object.entries(filters).forEach(([field, value]) => {
      if (value) {
        params.set(field, value)
        filteredFields[field] = value
      }
      if (!value) params.delete(field)
    })

    if (Object.keys(filteredFields).length > 0) {
      logEvent(ordersTracker.actions.filterOrders, {
        origin: '/pedidos',
        ...filteredFields,
        status: orderStatus.find(
          (status) => filteredFields.status === String(status.value),
        )?.label,
      })
    }

    setIsOpenFilterSlide(false)
    history.push(`?${params.toString()}`)
  }

  function handleClearFilters() {
    const paramsKeys = Array.from(params.keys())
    for (const param of paramsKeys) {
      params.delete(param)
    }

    reset()
    logEvent(ordersTracker.actions.clearFilterOrders, {
      origin: '/pedidos',
    })
    history.push('/pedidos')
  }

  return {
    filters,
    handleFilter,
    handleSubmit,
    control,
    isOpenFilterSlide,
    setIsOpenFilterSlide,
    orderStatus,
    handleClearFilters,
  }
}
