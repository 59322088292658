import { useQuery } from '@tanstack/react-query'
import { EQUIPMENT_CATEGORIES } from '../constants/endpointKeys'
import { equipmentCategories } from '../services/api'
import { type IEquipmentCategoriesParams } from '../services/types'

export function useEquipmentCategories(params: IEquipmentCategoriesParams) {
  const { ...methods } = useQuery({
    queryKey: [EQUIPMENT_CATEGORIES, params],
    queryFn: async () => await equipmentCategories(params),
  })

  return { ...methods }
}
