import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function useModalUpdateQuotationFreight() {
  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setOpenModalToChangeQuotationFreight = useEquipmentContainerStore(
    (state) => state.setOpenModalToChangeQuotationFreight,
  )

  const quote = { quotation: quotationSelected }

  return {
    setOpenModalToChangeQuotationFreight,
    quote,
  }
}
