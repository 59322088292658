import { ScrollTable } from '@77sol-ui/molecules'
import { Skeleton } from 'components/Skeleton'

export function ListSkeleton({ numberOfRows = 4 }: { numberOfRows?: number }) {
  return (
    <>
      {new Array(numberOfRows).fill(null).map((_, key) => (
        <ScrollTable.Tr key={key}>
          <ScrollTable.Td pin>
            <Skeleton height="20px" width="60px" />
          </ScrollTable.Td>
          <ScrollTable.Td>
            <Skeleton height="20px" width="120px" />
          </ScrollTable.Td>
          <ScrollTable.Td>
            <Skeleton height="20px" width="95px" />
          </ScrollTable.Td>
          <ScrollTable.Td>
            <Skeleton height="20px" width="50px" />
          </ScrollTable.Td>
          <ScrollTable.Td>
            <Skeleton height="20px" width="170px" />
          </ScrollTable.Td>
          <ScrollTable.Td>
            <Skeleton height="20px" width="100px" />
          </ScrollTable.Td>
        </ScrollTable.Tr>
      ))}
    </>
  )
}
