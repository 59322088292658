import { colors } from '@77sol-ui/tokens'

export const SCREEN = {
  SM: 640,
  XL: 1280,
}

export const FIRST_POSITION = 1
export const THIRD_POSITION = 3

export const RANKING_CONTEXT = {
  nonPodium: {
    text: 'Confira sua posição no Ranking 77Sol',
    palette: {
      color: {
        text: colors.gray[500],
        context: colors.gray[500],
      },
      background: colors.gray[100],
      divider: colors.gray[300],
    },
  },
  first: {
    text: 'Parabéns! Você está no topo do Ranking 77Sol',
    palette: {
      color: {
        text: colors.white[0],
        context: colors.white[0],
      },
      background: colors.blue[300],
      divider: colors.blue[200],
    },
  },
  second: {
    text: 'Falta pouco para você estar no topo do Ranking 77Sol',
    palette: {
      color: {
        text: colors.blue[700],
        context: colors.blue[300],
      },
      background: colors.blue[200],
      divider: colors.default[100],
    },
  },
  third: {
    text: 'Mantenha o ritmo e fique no pódio do Rank 77',
    palette: {
      color: {
        text: colors.blue[700],
        context: colors.blue[300],
      },
      background: colors.default[200],
      divider: colors.default[100],
    },
  },
  unclassified: {
    text: 'Clique aqui e participe do Ranking 77Sol',
    palette: {
      color: {
        text: colors.white[0],
      },
      background: colors.blue[700],
      divider: colors.blue[200],
    },
  },
}
