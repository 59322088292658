export const DIFFERENTS_STATES_ERROR = {
  KEY: 'differents_state_error',
  DESCRIPTION: 'The delivery state must be the same as the billing state',
  GENERIC_ERROR: {
    message:
      'O estado do endereço de entrega precisa ser o mesmo que o de faturamento.',
    path: 'genericError',
  },
  DELIVERY_STATE: {
    message: 'Deve ser igual ao do faturamento.',
    path: 'deliveryAddress.estado',
  },
  BILLING_STATE: {
    message: 'Deve ser igual ao da entrega.',
    path: 'billingAddress.estado',
  },
}
