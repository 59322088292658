import { Home, Landmark, UserCircle2, Users } from 'lucide-react'
import { FeaturesRoutes } from 'acl/config/routes.types'
import { type INavbarItem } from 'layouts/Dashboard/components/NavBar/components/NavbarItems/types'
import { type INewSidebarNavMenuItems } from 'components/Sidebar/Desktop/constants/integratorNavbarItems'

const DISTRIBUTOR_SIDEBAR_MOBILE_ITEMS: INewSidebarNavMenuItems[] = [
  {
    title: 'Início',
    href: FeaturesRoutes.DASHBOARD,
    icon: Home,
  },
  {
    title: 'Financiamentos',
    href: FeaturesRoutes.FINANCIAMENTOS,
    icon: Landmark,
  },
]

const CONFIGS_GROUP_ITEMS: INavbarItem[] = [
  {
    title: 'Minha Conta',
    href: FeaturesRoutes.CONFIGURACOES,
    icon: UserCircle2,
  },
  {
    title: 'Meu Time',
    href: FeaturesRoutes.MEUTIME,
    icon: Users,
  },
]

export { DISTRIBUTOR_SIDEBAR_MOBILE_ITEMS, CONFIGS_GROUP_ITEMS }
