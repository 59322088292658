import API from 'api'
import { GET_NOTIFICATIONS_READONE } from '../constants/endpointKeys'
import { type INotificationsReadoneGETResponse } from './types'

export const notificationsReadone = {
  get: async (id: string) =>
    await API.get<INotificationsReadoneGETResponse>(
      GET_NOTIFICATIONS_READONE(id),
    )
      .then(({ data }) => data)
      .catch((err) => {
        console.error(err)
      }),
}
