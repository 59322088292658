import { differenceInYears } from 'date-fns'

export function calculateClientYearsOld(birthDate: string) {
  let yearsOld = 0
  if (!birthDate) return { yearsOld: 0 }

  const [day, month, year] = birthDate.split('/').map(Number)
  const formatedDate = new Date(year, month - 1, day)
  yearsOld = differenceInYears(new Date(), new Date(formatedDate))

  return { yearsOld }
}
