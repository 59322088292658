import {
  BASIC_SIZING_TYPE,
  DETAILED_SIZING_TYPE,
  type EnumSizingType,
} from '../constants'

export const isDetailedSizing = (currentSizingType: EnumSizingType) =>
  DETAILED_SIZING_TYPE.find((value) => currentSizingType === value)

export const isBasicSizing = (currentSizingType: EnumSizingType) =>
  BASIC_SIZING_TYPE.find((value) => currentSizingType === value)
