import { palette } from '@77sol/styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  arrow: ({ expand }) => ({
    cursor: 'pointer',
    transition: 'transform .2s',
    color: palette.grayscale[800],
    transform: expand ? 'rotate(0deg)' : 'rotate(180deg)',
  }),
}))

export default useStyles
