import { SidebarMobile } from '@77sol-ui/organisms'
import { LogOut } from 'lucide-react'
import { SIDEBAR_DESKTOP_LOGO } from '../constants'
import {
  CONFIGS_GROUP_ITEMS,
  DISTRIBUTOR_SIDEBAR_MOBILE_ITEMS,
} from './constants/navItems'
import { ConfigsAndAccountGroup } from './components/ConfigsGroup'
import { SidebarMobileProfileWrapper } from './components/ProfileWrapper'
import { ACCORDION_DEFAULT_VALUE } from './constants'
import { useSidebarMobile } from './hooks/useSidebarMobile'
import { Notification } from 'containers/Notifications'
import { SidebarMobileLogout } from './styles'
import { Link } from 'react-router-dom'
import { checkIfCurrentPageIsActive } from '../utils'

export function SidebarMobileSlide() {
  const {
    isOpen,
    reset,
    signals,
    validatePermission,
    onRedirect,
    handleLogout,
    IS_MOBILE,
    handleOpenedNotifications,
    handleClickOnLogo,
  } = useSidebarMobile()

  return (
    <SidebarMobile.Root open={isOpen} onOpenChange={reset}>
      <SidebarMobile.Portal>
        <SidebarMobile.Overlay />

        <SidebarMobile.Wrapper>
          <SidebarMobile.Content>
            <SidebarMobile.Logo
              src={SIDEBAR_DESKTOP_LOGO}
              alt="Logo"
              onClick={handleClickOnLogo}
            />
            <SidebarMobile.ProfileRoot>
              <SidebarMobileProfileWrapper />

              {IS_MOBILE && (
                <Notification.Slide
                  onOpenedSuccess={handleOpenedNotifications}
                  onClickNotification={() => {
                    reset()
                  }}
                />
              )}
            </SidebarMobile.ProfileRoot>
            <SidebarMobile.NavRoot>
              {DISTRIBUTOR_SIDEBAR_MOBILE_ITEMS.filter(validatePermission).map(
                (item) => {
                  const Icon = item.icon
                  const hasSignalization =
                    signals?.[item?.signalType as keyof typeof signals]
                  return (
                    <SidebarMobile.NavItem
                      key={item.href}
                      onClick={() => {
                        onRedirect(item)
                      }}
                      active={checkIfCurrentPageIsActive(item.href)}
                      asChild
                    >
                      <Link to={item.href}>
                        <SidebarMobile.NavIcon>
                          <Icon />
                          {hasSignalization && <SidebarMobile.Marker />}
                        </SidebarMobile.NavIcon>
                        <SidebarMobile.NavTitle>
                          {item.title}
                        </SidebarMobile.NavTitle>
                      </Link>
                    </SidebarMobile.NavItem>
                  )
                },
              )}
            </SidebarMobile.NavRoot>

            {CONFIGS_GROUP_ITEMS.find(validatePermission) && (
              <SidebarMobile.AccordionGroup
                type="multiple"
                gap={16}
                defaultValue={ACCORDION_DEFAULT_VALUE}
              >
                <SidebarMobile.Separator />
                <ConfigsAndAccountGroup
                  onRedirect={onRedirect}
                  signals={signals}
                />
              </SidebarMobile.AccordionGroup>
            )}

            <SidebarMobile.Separator />
            <SidebarMobileLogout onClick={handleLogout}>
              <LogOut /> Sair
            </SidebarMobileLogout>
          </SidebarMobile.Content>
        </SidebarMobile.Wrapper>
      </SidebarMobile.Portal>
    </SidebarMobile.Root>
  )
}
