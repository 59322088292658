import React, { useState } from 'react'
import { Grid, Tooltip, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { Spacer, Paper } from '@77sol/core'
import palette from 'app_palette'
import { UploadIcon, EditIcon, DeleteIcon } from '@77sol/icons/dist'
import CircularProgress from '@material-ui/core/CircularProgress'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

function WorkImagesEdit(props) {
  const {
    imagens,
    handleRemoveImage,
    handleSaveWorkImages,
    handleEditImage,
    noPadding,
    loading,
  } = props

  const [editImage, setEditImage] = useState('')

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'preparing') {
      // setImageLoading(true)
      remove()
      if (editImage) {
        if (handleEditImage) {
          handleEditImage(file, editImage)
        } else {
          handleSaveWorkImages(file)
        }
        setEditImage('')
      } else {
        handleSaveWorkImages(file)
      }
    }
    if (status === 'headers_received') {
      // let newArray = imagens;
      // newArray.push(file);
      // setImagens([...newArray]);
      // setImageLoading(false)
    }
  }

  return (
    <div style={{ padding: noPadding ? '0px' : '0px 32px' }}>
      <div>
        <Typography
          type={downSm ? 'text_small' : 'text_medium'}
          color="grayscale"
          colorWeight="700"
        >
          Insira imagens dos seus projetos passados
        </Typography>
        <Typography type="text_x_small" color="grayscale" colorWeight="500">
          Deixe seu portfólio pronto para a hora de gerar nova proposta
          selecionar as imagens desejadas
        </Typography>
      </div>
      <Spacer size="16" direction="horizontal" />
      <Grid spacing={2} container>
        {Array.from(
          Array(imagens.length + 1 >= 6 ? 6 : imagens.length + 1).keys(),
        ).map((image, i) => (
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <Paper
              style={{
                display: 'flex',
                width: '100%',
                height: '200px',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <Dropzone
                maxFiles={1}
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                PreviewComponent={(props) => (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      disableShrink
                      style={{ color: palette.primary[300] }}
                      size={30}
                      thickness={4}
                    />
                  </div>
                )}
                inputContent={(
                  imagens && imagens[i] ? (
                    <div
                      style={{
                        backgroundImage: `url(${imagens[i]})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Paper
                        style={{
                          position: 'absolute',
                          bottom: '8px',
                          right: '8px',
                          display: 'flex',
                          width: 'auto',
                          height: 'auto',
                          padding: '8px',
                        }}
                      >
                        <Tooltip title="Alterar">
                          <EditIcon
                            width="24"
                            color={palette.grayscale[800]}
                            onClick={() => {
                              setEditImage(imagens[i])
                            }}
                          />
                        </Tooltip>
                        <Spacer size="16" direction="vertical" />
                        <Tooltip title="Remover">
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemoveImage(imagens[i])
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                            width="24"
                            color={palette.red[300]}
                          />
                        </Tooltip>
                      </Paper>
                    </div>
                  ) : (
                    loading ? (
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress
                          disableShrink
                          style={{ color: palette.primary[300] }}
                          size={30}
                          thickness={4}
                        />
                      </div>
                    ) : (
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          maxWidth: '250px',
                        }}
                      >
                        <UploadIcon
                          width="32"
                          height="32"
                          color={palette.grayscale[600]}
                        />
                        <Spacer size="10" direction="horizontal" />
                        <Typography
                          type="text_x_small"
                          color="grayscale"
                          colorWeight="600"
                        >
                          Clique aqui para fazer o upload da imagem
                        </Typography>
                      </span>
                    )
                  )
                )}
                accept="image/*"
                styles={{
                  dropzone: {
                    padding: '30px',
                    border:
                      imagens && imagens[i]
                        ? 'none'
                        : `1px dashed ${palette.grayscale[400]}`,
                    flexGrow: 1,
                    alignSelf: 'stretch',
                    borderRadius: '8px',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    color: '#1335c6',
                    width: '100%',
                    height: imagens && imagens[i] ? '100%' : 'auto',
                  },
                  preview: {
                    border: 'none',
                  },
                  input: {
                    display: 'none',
                  },
                  inputLabel: {
                    color: '#1335c6',
                    fontSize: '16px',
                    textAlign: 'center',
                    padding: imagens && imagens[i] ? '0px' : '16px',
                  },
                }}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default WorkImagesEdit
