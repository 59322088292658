import { useState } from 'react'
import { Button, Radio, Badge } from '@77sol/core'
import { TableList, EmptyState, CircularProgress } from 'components'
import { formatReal } from 'utils/format'
import { format } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroll-component'
import orderStatus from 'views/Pedidos/orderStatus'
import { useFetchStoreOrdersNotFinancing } from 'domains/loja/orders-not-financing/hooks/useFetchOrderNotFinancing'
import * as S from './styles'
import {
  type IOrderValueFinancingLinkerModalProps,
  type IRowSelected,
} from './OrderValueFinancingLinkerModal.types'
import { ORDER_TABLE_COLUMNS, TRACKER_OPTIONS } from './constants'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useHistory } from 'react-router-dom'
import { type OrderStatus } from 'dto/OrderDTO'
import { useAmplitude } from 'hooks/useAmplitude'
import { globalTracker } from 'services/tracker/events/globals/trackers'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'

export const OrderValueFinancingLinkerModal = ({
  isOpen,
  handleBindFinancingValueToOrder,
  onClose,
  valueFinancingDataInfo,
}: IOrderValueFinancingLinkerModalProps) => {
  const history = useHistory()
  const { logEvent } = useAmplitude()

  const setFinancingId = useCheckoutModalStore((state) => state.setFinancingId)
  const { ordersAvailableForFinancing, isLoading, isError } =
    useFetchStoreOrdersNotFinancing()

  const [selectedOrder, setSelectedOrder] = useState<IRowSelected>(
    {} as IRowSelected,
  )
  const { setIsQuotationModalOpen } = useQuotationModalContext()
  const disabledButton = !selectedOrder.rowData

  const ordersList = ordersAvailableForFinancing?.map((order) => {
    const propsRadio = {
      size: 'small',
      checked: order.id === selectedOrder?.rowData?.id,
    }

    const propsBadge = {
      label: order.status,
      color: orderStatus[order.status as OrderStatus]?.color,
    }

    return {
      selected: <Radio {...propsRadio} />,
      rowData: order,
      code: `#${order.code}`,
      date: format(new Date(order.created_at), 'dd/MM/yyyy'),
      status: <Badge {...propsBadge} />,
      value: formatReal(order.total),
    }
  })

  const handleSelectedRow = (order: IRowSelected) => {
    setSelectedOrder(
      order.code === selectedOrder.code ? ({} as IRowSelected) : order,
    )
  }

  const handleCreateQuotation = () => {
    logEvent(globalTracker.actions.buttonQuotationKey, TRACKER_OPTIONS)
    setFinancingId(valueFinancingDataInfo)
    onClose()
    setIsQuotationModalOpen(true)
  }

  const handleCreateProposal = () => {
    logEvent(globalTracker.actions.buttonProposalKey, TRACKER_OPTIONS)
    setFinancingId(valueFinancingDataInfo)
    onClose()
    history.push('/dimensionar')
  }

  return (
    <S.Container open={isOpen} onClose={onClose}>
      <S.Header>
        <div className="nav">
          <S.CloseModal onClick={onClose} />
        </div>
        <h3>
          Selecione o pedido que deseja atrelar a este financiamento
        </h3>
        <p>
          Você pode associar qualquer pedido com status "Reservado” ao
          financiamento desejado. Em caso de dúvida, acesse ”Meus
          pedidos” para mais informações.
        </p>
      </S.Header>
      <S.Content>
        {isLoading && <CircularProgress />}
        {isError && (
          <EmptyState text="Ocorreu um erro, não foi possível encontrar pedidos dispoíveis, tente novamente mais tarde!" />
        )}
        {ordersAvailableForFinancing && (
          <>
            {ordersAvailableForFinancing.length <= 0 && (
              <EmptyState text="Não há pedidos para atrelar" />
            )}
            {ordersAvailableForFinancing && (
              <>
                {/* @ts-expect-error */}
                <InfiniteScroll style={S.Scroll} dataLength={ordersList.length}>
                  {/* @ts-expect-error */}
                  <TableList
                    rowOutlined
                    onClickFullRow
                    values={ordersList}
                    columns={ORDER_TABLE_COLUMNS}
                    selectedRow={selectedOrder}
                    onRowClick={handleSelectedRow}
                    size={16}
                    headerSize="small"
                  />
                </InfiniteScroll>
              </>
            )}
          </>
        )}
      </S.Content>
      <S.Actions>
        <div>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleCreateQuotation}
          >
            Cotar equipamentos
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleCreateProposal}
          >
            Criar nova proposta
          </Button>
        </div>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            handleBindFinancingValueToOrder(selectedOrder?.rowData)
          }}
          disabled={disabledButton}
        >
          Concluir
        </Button>
      </S.Actions>
    </S.Container>
  )
}
