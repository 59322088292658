import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useToast } from '@77sol/core'
import { queryClient } from 'services/queryClient'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { useRevertKitQuotation } from 'domains/quotation/services/hooks/useRevertKitQuotation'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

export function useRevertKitChanges() {
  const { logEvent } = useAmplitude()
  const { onErrorOpenToast, onSuccessOpenToast } = useToast()
  const { quotationSelected, dimensioningId } = useEquipmentContainerStore()

  const HAS_SOME_CHANGED_EQUIPMENT = quotationSelected?.isChanged

  const revertKitMutation = useRevertKitQuotation({
    onSuccess: async () => {
      const getDimensioningQueryKey = [GET_DIMENSIONING, dimensioningId]
      await queryClient.invalidateQueries(getDimensioningQueryKey)

      onSuccessOpenToast('KIT Redefinido com sucesso!')
    },
    onError: () => onErrorOpenToast('Não foi possível redefinir o KIT!'),
  })

  function onRevertKit() {
    if (!quotationSelected?.id) {
      return
    }

    logEvent(quotationTracker.actions.revertQuotationKit, {
      origin: '/cotar',
    })

    revertKitMutation.mutate({
      quotation_id: quotationSelected?.id ?? 0,
      redefine_kit: true,
    })
  }

  return {
    revertKitMutation,
    isDisabledRevertButton:
      revertKitMutation.isLoading || !HAS_SOME_CHANGED_EQUIPMENT,
    onRevertKit,
    isLoading: revertKitMutation.isLoading,
  }
}
