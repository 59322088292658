interface IUseDescriptionProps {
  financingId: number | null
}

export const useDescription = ({ financingId }: IUseDescriptionProps) => {
  const content = financingId ? 'financing' : 'default'

  const descriptionContent = {
    default:
      'Complete agora o cadastro para garantir a cotação e acelerar seu pedido!',
    financing:
      'Dê continuidade ao fluxo de financiamento, atrelando esse pedido ao seu financiamento.',
  }

  const description = descriptionContent[content]

  return { description }
}
