import { parseBRL } from 'utils'
import { type ILeadAlert } from 'domains/solleads/get-leads-alert/services/types'

export function getAlertTexts(count: number, leads?: ILeadAlert) {
  if (count === 1) {
    const { nome = '', consumo_medio = 0 } = leads ?? {}

    return {
      title: 'Você tem 1 novo lead disponível.',
      description: `O cliente ${nome} quer um projeto para atender uma média de consumo de ${parseBRL(
        consumo_medio,
      )}.`,
      button: 'Aceitar Lead',
    }
  }

  return {
    title: `Você tem ${count} novos leads disponíveis.`,
    description:
      'Clique no botão abaixo para visualizar todos os leads aguardando sua aprovação.',
    button: `Visualizar Leads`,
  }
}
