import { Tab } from '@77sol-ui/molecules'
import * as S from './styles'
import { FinancingAndProposalTab, LIMIT_OF_ROWS } from './constants'
import { useFinancingAndProposalsFilter } from './hooks/useFinancingAndProposalsFIlter'
import { StatusSelect } from './components/StatusSelect'
import { useGetFinancingsAndProposalsData } from 'domains/home/dashboard-financings-and-proposals/hooks/useGetFinancingsAndProposals'
import { FinancingList } from './components/FinancingList'
import { ProposalList } from './components/ProposalList'
import useGetMaxNumberOfRows from './hooks/useGetMaxNumberOfRows'

export function FinancingAndProposalsTable() {
  const {
    selectedStatus,
    selectedTab,
    onChangeTab,
    onChangeFilter,
    FILTER_STATUS,
  } = useFinancingAndProposalsFilter()

  const { financingQuery, proposalQuery, isLoading } =
    useGetFinancingsAndProposalsData({
      financingParams: {
        limit: LIMIT_OF_ROWS,
        status:
          selectedTab === FinancingAndProposalTab.FINANCINGS
            ? selectedStatus?.value
            : undefined,
      },
      proposalParams: {
        limit: LIMIT_OF_ROWS,
        status:
          selectedTab === FinancingAndProposalTab.PROPOSALS
            ? selectedStatus?.value
            : undefined,
      },
    })

  const alreadyCreatedFinancingOrProposal =
    financingQuery?.data?.already_has_financing ||
    proposalQuery?.data?.already_has_proposal

  const { numberOfRows } = useGetMaxNumberOfRows({
    active: !selectedStatus?.value,
    financingsQty: financingQuery?.data?.financings?.length,
    proposalsQty: proposalQuery?.data?.proposals?.length,
    defaultValue: LIMIT_OF_ROWS,
  })

  return (
    <S.TableContainer data-testid="financing-and-proposal-list">
      <S.TableInfos>
        <S.Title>
          <h3>Financiamentos e Propostas</h3>
          <p>Acompanhe o status de seus financiamentos e propostas</p>
        </S.Title>

        <StatusSelect
          options={FILTER_STATUS}
          onChangeFilter={onChangeFilter}
          selectedStatus={selectedStatus}
          key={selectedTab}
        />

        <S.TabRoot
          onValueChange={(value) => {
            onChangeTab(value)
          }}
          value={selectedTab}
        >
          <Tab.List>
            <Tab.Trigger
              value={FinancingAndProposalTab.FINANCINGS}
              data-testid="financing-tab-trigger"
            >
              Financiamentos
            </Tab.Trigger>
            <Tab.Trigger
              value={FinancingAndProposalTab.PROPOSALS}
              data-testid="proposals-tab-trigger"
            >
              Propostas
            </Tab.Trigger>
          </Tab.List>
          <Tab.Content value={FinancingAndProposalTab.FINANCINGS}>
            <FinancingList
              numberOfRows={numberOfRows}
              financings={financingQuery?.data?.financings}
              alreadyCreatedFinancing={alreadyCreatedFinancingOrProposal}
              isLoading={isLoading}
              isError={financingQuery.isError}
              filterApplied={Boolean(selectedStatus?.value)}
            />
          </Tab.Content>
          <Tab.Content value={FinancingAndProposalTab.PROPOSALS}>
            <ProposalList
              numberOfRows={numberOfRows}
              proposals={proposalQuery?.data?.proposals}
              alreadyCreatedProposal={alreadyCreatedFinancingOrProposal}
              isLoading={isLoading}
              isError={proposalQuery.isError}
              filterApplied={Boolean(selectedStatus?.value)}
            />
          </Tab.Content>
        </S.TabRoot>
      </S.TableInfos>
    </S.TableContainer>
  )
}
