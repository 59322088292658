import styled from 'styled-components'

export const Title = styled.p`
  font-size: 15px;
  color: #4e4b66;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-weight: 700;
    color: #262338;
  }

  span.main {
    font-size: 24px;
    margin: 0 4px;
  }

  @media (max-width: 768px) {
    p {
      font-size: 14px;
    }

    span.main {
      font-size: 20px;
    }
  }
`
