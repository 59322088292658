import { type IQuotationDTO } from 'domains/dimensioning/services/types'
import { type IKitDTOWithId } from '../hooks/useLoadKits'

const findKitInQuotation = (
  quotation: IQuotationDTO,
  kits?: IKitDTOWithId[],
) => {
  const kitFound = kits?.find(({ products }) => {
    if (quotation.isChanged) {
      return false
    }

    return quotation.products.every((product, i) => {
      return JSON.stringify(product) === JSON.stringify(products[i])
    })
  })

  if (kitFound) {
    return {
      id: quotation.id,
      ...kitFound,
    }
  }

  return null
}

export { findKitInQuotation }