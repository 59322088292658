import { DropdownMenu } from '@77sol/core'
import { KebabMenu } from 'components/KebabMenu'
import { CATEGORY_ENUM } from 'enums/products/CategoryEnum'
import { useAmplitude } from 'hooks/useAmplitude'
import React from 'react'
import { productTracker } from 'services/tracker/events/product/trackers'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

interface IEquipmentMenuProps {
  onShowTechnicalData: () => void
  onDelete: () => void
  productCategory: CATEGORY_ENUM
}

const EquipmentMenu: React.FC<IEquipmentMenuProps> = ({
  onShowTechnicalData,
  onDelete,
  productCategory,
}) => {
  const { logEvent } = useAmplitude()

  const cannotBeDeleted = [
    CATEGORY_ENUM.INVERSOR,
    CATEGORY_ENUM.MICRO_INVERSOR,
    CATEGORY_ENUM.MODULO,
  ]

  const shouldDelete = !cannotBeDeleted.includes(productCategory)

  const handleShowTechnicalData = () => {
    logEvent(productTracker.actions.showTechnicalData, {
      origin: '/Cotar',
    })
    onShowTechnicalData()
  }

  const handleDelete = () => {
    logEvent(quotationTracker.actions.deleteProduct, {
      origin: '/Cotar',
    })
    onDelete()
  }

  return (
    <KebabMenu color="#828282">
      <DropdownMenu.Item onClick={handleShowTechnicalData}>
        Ver dados técnicos
      </DropdownMenu.Item>
      {shouldDelete && (
        <DropdownMenu.Item onClick={handleDelete}>Excluir</DropdownMenu.Item>
      )}
    </KebabMenu>
  )
}

export { EquipmentMenu }
