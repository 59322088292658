import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-bottom: 10vh;

  @media screen and (min-width: 1280px) {
    width: 80%;
  }

  @media screen and (min-width: 1920px) {
    width: 60%;
  }
`
