import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputDescription: {
    marginBottom: '8px',
    fontSize: '14px',

    color: '#707072',
  },
  inputRequired: {
    color: '#FF0000',
    marginLeft: '2px',
  },
  inputText: {
    color: '#FFF',
  },
  inputTextNotSelected: {
    color: '#464f5b',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
})
