import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@77sol/core'
import { Box } from '@material-ui/core'
import { FilledStarIcon } from '@77sol/icons/dist'
import useStyles from './styles'

function TooltipContainer({ children, tooltip }) {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children}</Tooltip>
  }
  return children
}

function FeaturedStar({
  width = 20,
  color = 'primary',
  spacing = 2,
  tooltip = '',
  colorWeight = 300,
}) {
  const classes = useStyles({ color, colorWeight })

  return (
    <TooltipContainer tooltip={tooltip}>
      <Box className={classes.root} marginX={spacing}>
        <FilledStarIcon width={width} />
      </Box>
    </TooltipContainer>
  )
}

FeaturedStar.propTypes = {
  width: PropTypes.number,
  spacing: PropTypes.number,
  color: PropTypes.string,
  colorWeight: PropTypes.number,
  tooltip: PropTypes.string,
}

export default FeaturedStar
