import { type IPopoverProps, Notification } from '@77sol-ui/organisms'
import { NotificationButton } from '../components/NotificationButton'
import { NotificationsList } from '../components/NotificationsList'
import { useOpenNotification } from '../hooks/useOpenNotification'

export interface INotificationPopoverProps {
  onClickNotification?: () => void
  onOpenedSuccess?: () => void
}

export function Popover({
  onClickNotification,
  onOpenedSuccess,
  ...props
}: INotificationPopoverProps & IPopoverProps) {
  const { handleToggle, notificationsOpen } = useOpenNotification({
    onOpenedSuccess,
  })

  return (
    <Notification.Popover
      open={notificationsOpen}
      onOpenChange={handleToggle}
      trigger={<NotificationButton />}
      zIndex={2}
      sideOffset={30}
      {...props}
    >
      <NotificationsList onClickNotification={onClickNotification} />
    </Notification.Popover>
  )
}
