import API from 'api'
import { mappedAllCustomer } from 'utils/mappedAllCustomer'

const getAllCustomers = async (page = 1) => {
  try {
    const {
      data: { data: customersList, last_page: lastPage },
    } = await API.get('/clientes/all', {
      params: {
        page,
      },
    })

    const allCustomers = customersList.map(mappedAllCustomer)

    if (lastPage > page) {
      return allCustomers.concat(await getAllCustomers(page + 1))
    }

    return allCustomers
  } catch (e) {
    console.log(e)
  }
}

export default getAllCustomers
