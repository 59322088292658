import React, { useState, useEffect } from 'react'
import API from 'api'
import { useSelector } from 'react-redux'
import {
  Equipments,
  PaymentMethods,
  ProductionAndConsumption,
  ProjectBudget,
} from '../../../ProposalStructurer/components/ContainerQuotePdf/components'

export function ContainerQuotePdf({ container, quote, i }) {
  const proposal = useSelector((state) => state.Reducer1)

  const [paymentMethods, setPaymentsMethods] = useState([])
  const [billetNumber, setBilletNumber] = useState()

  const getPayments = () => {
    API.get(`/proposal/quotation/payment_forms/${quote.quotation.id}`).then(
      (res) => {
        const { methods } = res.data
        setPaymentsMethods(methods)
        if (methods.billet.billet_one) {
          setBilletNumber(1)
        }
        if (methods.billet.billet_two) {
          setBilletNumber(2)
        }
        if (methods.billet.billet_three) {
          setBilletNumber(3)
        }
        if (methods.billet.billet_four) {
          setBilletNumber(4)
        }
      },
    )
  }

  useEffect(() => {
    getPayments()
  }, [])

  const [budgetEdit, setBudgetEdit] = useState([])
  const [returnData, setReturnData] = useState()

  const handleShowRoi = () => {
    API.get(`/proposal/quotation/roi/calculate/${quote.quotation.id}`).then(
      (res) => {
        setReturnData(res.data)
      },
    )
  }

  const handleGetBudget = (id) => {
    API.get(`/proposal/list/budgets/${id}`).then((res) => {
      setBudgetEdit(res.data)
      handleShowRoi(
        proposal.sizings.find(
          (sizing) =>
            sizing.id ==
            proposal.quotes.find(
              (quotefind) => quotefind.quotation.id == quote.quotation.id,
            ).dimensioning.id,
        ),
        res.data,
      )
    })
  }

  useEffect(() => {
    handleGetBudget(quote.quotation.id)
  }, [])

  return (
    <div>
      <div style={{ position: 'relative' }}>
        {container.id === `returnQuote${i}` && container.visible && (
          <ProjectBudget quote={quote} returnData={returnData} i={i} />
        )}
        {container.id === `quoteData${i}` && container.visible && (
          <Equipments budgetEdit={budgetEdit} i={i} />
        )}
        {container.id === `productionQuote${i}` && container.visible && (
          <ProductionAndConsumption quote={quote} />
        )}
        {container.id === `paymentQuote${i}` && container.visible && (
          <PaymentMethods
            paymentMethods={paymentMethods}
            billetNumber={billetNumber}
          />
        )}
      </div>
    </div>
  )
}
