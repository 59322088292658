import styled from 'styled-components'

export const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;

  .quotation-cards {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .quotation-select {
    display: none;
  }

  .quotation-actions {
    margin-top: 32px;

    .buttons {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group {
        display: flex;
        gap: 10px;
      }
    }
  }

  .quotation-products {
    margin-top: 32px;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    .product-mobile {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .quotation-cards {
      display: none;
    }

    .quotation-select {
      margin: 20px 0;
      display: block;
    }

    .quotation-actions {
      div:first-child {
        width: 100%;
      }

      .buttons {
        display: none;
      }
    }

    .quotation-products {
      .product {
        display: none;
      }

      .product-mobile {
        display: block;
      }
    }
  }
`
