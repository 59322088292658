import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  & .emptyText {
    color: ${palette.grayscale[500]};
    font-weight: 400;
  }
`
export const StatusContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  & .title {
    font-size: 1rem;
  }

  & .description {
    font-weight: 400;
    font-size: 0.875rem;
    color: ${palette.grayscale[800]};
  }

  & .date {
    font-size: 0.75rem;
  }

  & .bold {
    font-weight: 700;
  }
`
