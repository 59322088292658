import { Tab } from '@77sol-ui/molecules'
import { CompareKit } from './components/CompareKit'
import { ChooseKit } from './components/ChooseKit'
import { CustomizeKit } from './components/CustomizeKit'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { useDisableTabs } from './hooks/useDisableTabs'
import * as S from './styles'

const { CHOOSE_KIT, COMPARE_KIT, CUSTOMIZE_KIT } = TAB_ENUM

export function Tabs() {
  const currentTab = useEquipmentContainerStore((state) => state.currentTab)
  const changeTab = useEquipmentContainerStore((state) => state.changeTab)
  const { logEvent } = useAmplitude()
  const { disableTabs } = useDisableTabs()

  function handleChangeTab(value: string) {
    const tab = value as TAB_ENUM

    if (currentTab === tab) return

    changeTab(tab)
    logEvent(quotationTracker.actions.changeQuotationTabs, {
      origin: '/cotar',
      tab: TAB_ENUM_AMPLITUDE[tab],
    })
  }

  return (
    <S.Root value={currentTab} onValueChange={handleChangeTab}>
      <Tab.List>
        <S.Trigger value={CHOOSE_KIT}>Escolha seus Kits</S.Trigger>
        <S.Trigger
          value={CUSTOMIZE_KIT}
          disabled={disableTabs}
          id="customize-kit"
        >
          Personalize seus Kits
        </S.Trigger>
        <S.Trigger value={COMPARE_KIT} disabled={disableTabs}>
          Compare seus Kits
        </S.Trigger>
      </Tab.List>
      <S.Content value={CHOOSE_KIT}>
        <ChooseKit />
      </S.Content>
      <S.Content value={CUSTOMIZE_KIT}>
        <CustomizeKit />
      </S.Content>
      <S.Content value={COMPARE_KIT}>
        <CompareKit />
      </S.Content>
    </S.Root>
  )
}
