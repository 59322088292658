import { Button } from '@77sol-ui/atoms'
import { Modal } from '@77sol-ui/molecules'
import { Loader2, Trash } from 'lucide-react'
import { QuotationRow } from './components/QuotationRow'
import { useDeleteQuotation } from './hooks/useDeleteQuotation'
import { type IQuotationDTO } from 'domains/dimensioning/services/types'

interface IQuotationDeleteModalProps {
  quotation: IQuotationDTO
  onClose: () => void
  onError?: () => void
  onSuccess?: () => void
  onStopDelete?: () => Promise<void> | void
}

export function QuotationDeleteModal({
  quotation,
  onClose,
  onError,
  onSuccess,
  onStopDelete,
}: IQuotationDeleteModalProps) {
  const { isLoading, handleDelete } = useDeleteQuotation({
    quotation,
    onError,
    onSuccess,
    onStopDelete,
  })

  return (
    <Modal.Root open onOpenChange={onClose}>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper id="modal-remove-quotation" maxWidth="800px">
          <Modal.Header>
            <Modal.Icon icon={Trash} />
            <Modal.Close />
            <Modal.Title>
              Tem certeza que deseja excluir essa cotação?
            </Modal.Title>
            <Modal.Description>
              Essa ação não poderá ser desfeita.
            </Modal.Description>
          </Modal.Header>
          <Modal.Content>
            <QuotationRow quotation={quotation} />
          </Modal.Content>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline">
                Cancelar
              </Button>
            </Modal.Close>
            <Button fullWidth disabled={isLoading} onClick={handleDelete}>
              {isLoading ? (
                <Loader2 size={24} className="atm-animate-spinSlow" />
              ) : (
                'Excluir'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
