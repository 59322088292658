export enum UserType {
  INTEGRATOR = 'integrator',
  DISTRIBUTOR = 'distributor',
}

export enum UserRole {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  GESTOR = 3,
  VENDEDOR = 4,
}

export enum UserTypeDictionary {
  'integrator' = 'integrador',
  'distributor' = 'distribuidor',
}
