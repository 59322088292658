import { useState } from 'react'
import { ImageUploader } from 'components'
import { isEmpty } from 'utils'
import { Typography, Button, Spacer, Modal } from '@77sol/core'
import { Box } from '@material-ui/core'

export function ModalUploadFile({
  isModalOpen,
  setIsModalOpen,
  handleSendDocument,
  title,
}) {
  const [fileToSend, setFileToSend] = useState('')

  const handleSendFile = () => {
    handleSendDocument(fileToSend)
    setIsModalOpen(false)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>{title}</Typography>
        <ImageUploader handleImageUploaded={setFileToSend} />
        <Box display="flex">
          <Button
            size="small"
            disabled={isEmpty(fileToSend)}
            onClick={handleSendFile}
          >
            Enviar arquivo
          </Button>
          <Spacer size="16" direction="both" />
          <Button size="small" variant="outlined" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
