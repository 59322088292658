import yup from 'libs/yup'

export const sendEmailSchema = yup.object({
  email: yup
    .string()
    .required('Campo obrigatório')
    .emailDeepValidate('E-mail inválido'),
})

export type ISendEmailForm = yup.InferType<typeof sendEmailSchema>
