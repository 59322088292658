import { Slide, Button } from '@77sol/core'
import { FilterIcon } from '@77sol/icons/dist'
import { useState } from 'react'
import * as S from './styles'
import { Header } from 'components'
import { FiltersForm } from '../FiltersForm'

interface IMobileFilterProps {
  isPerValue: boolean
}

export function MobileFilter({ isPerValue }: IMobileFilterProps) {
  const [openFilterSlide, setOpenFilterSlide] = useState(false)

  const slideStyle = {
    width: '100%',
    minWidth: '100%',
    position: 'absolute',
    top: '0',
    height: 'unset',
    bottom: '0',
    left: '0',
    background: 'white',
    zIndex: '1000',
  }

  return (
    <>
      <Slide style={slideStyle} isOpen={openFilterSlide}>
        <S.SlideContent>
          <Header
            onBack={() => {
              setOpenFilterSlide(false)
            }}
            size="huge"
            title="Filtros"
          />
          <S.SlideContentContainer>
            <FiltersForm
              isPerValue={isPerValue}
              onFilter={() => {
                setOpenFilterSlide(false)
              }}
            />
          </S.SlideContentContainer>
        </S.SlideContent>
      </Slide>

      <Button
        variant="text"
        startIcon={<FilterIcon />}
        onClick={() => {
          setOpenFilterSlide((oldValue) => !oldValue)
        }}
      >
        Filtrar
      </Button>
    </>
  )
}
