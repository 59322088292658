import { useEffect, useState } from 'react'
import { useToast } from '@77sol/core'
import { useHistory } from 'react-router'
import { useAmplitude } from 'hooks/useAmplitude'
import { useSolLeadStore } from 'containers/SolLeadAlert/stores/useSolLeadStore'
import { solLeadsTrackers } from 'services/tracker/events/solLeads/trackers'
import { useLeadsAlert } from 'domains/solleads/get-leads-alert/hooks/useLeadsAlert'
import { useAcceptLead } from 'domains/solleads/accept-lead/hooks/useAcceptLead'
import { useRejectLeads } from 'domains/solleads/reject-leads/hooks/useRejectLeads'
import { getAlertTexts } from '../helpers/alertTexts'
import {
  acceptLeadMessages,
  rejectLeadsMessages,
} from '../constants/leadsMessages'
import { type ICustomerDTO } from 'dto/CustomerDTO'
import { useSolLeadsModalStore } from '../components/LeadsModal/store/useSolLeadsModalStore'
import { queryClient } from 'services/queryClient'
import { LEADS_ALERT } from 'domains/solleads/get-leads-alert/constants/endpointKeys'
import { LEADS_MODAL } from 'domains/solleads/get-leads-modal/constants/endpointKeys'

export function useSolLeadAlert() {
  const history = useHistory()

  const { logEvent } = useAmplitude()
  const { setLead } = useSolLeadStore()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const { mutateAsync: acceptLead } = useAcceptLead()
  const { mutateAsync: rejectLead } = useRejectLeads()
  const { data: leadsAlertData } = useLeadsAlert()

  const {
    lead: singleLead,
    leads: leadsAlert = [],
    count: countLeads = 0,
  } = leadsAlertData ?? {}

  const setIsSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.setIsSolLeadsModalOpen,
  )

  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false)

  const alertIsOpen = countLeads > 0
  const eventProperties = { origin: singleLead ? 'Alert' : 'Modal' }

  const alertTexts = getAlertTexts(countLeads, singleLead)

  useEffect(() => {
    if (alertIsOpen) {
      logEvent(solLeadsTrackers.actions.viewedAlert)
    }
  }, [alertIsOpen])

  function handleToggleRejectModal(open: boolean) {
    const { openRejectModal, closeRejectModal } = solLeadsTrackers.actions
    logEvent(open ? openRejectModal : closeRejectModal)

    setRejectModalIsOpen(open)
  }

  function handleLeadsModal(open: boolean) {
    const { openLeadsModal, closeLeadModal } = solLeadsTrackers.actions
    logEvent(open ? openLeadsModal : closeLeadModal)

    setIsSolLeadsModalOpen(open)
  }

  function handleCloseAlert() {
    logEvent(solLeadsTrackers.actions.closeAlert)

    handleToggleRejectModal(true)
  }

  function redirectToProposal(customer: ICustomerDTO) {
    setLead(customer)
    history.push('/dimensionar')
  }

  function handleAcceptLead() {
    if (singleLead) {
      logEvent(solLeadsTrackers.actions.acceptLead, eventProperties)

      acceptLead({
        leads: [singleLead.distribution_lead_id],
      })
        .then(({ data }) => {
          queryClient.invalidateQueries({ queryKey: [LEADS_ALERT] })
          onSuccessOpenToast(acceptLeadMessages.success)
          redirectToProposal(data.customer)
        })
        .catch(() => {
          onErrorOpenToast(acceptLeadMessages.error)
        })

      return
    }

    handleLeadsModal(true)
  }

  function handleRejectLeads(leads = leadsAlert) {
    logEvent(solLeadsTrackers.actions.rejectLead, eventProperties)

    if (leads) {
      rejectLead({ leads })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: [LEADS_ALERT],
          })
          queryClient.invalidateQueries({
            queryKey: [LEADS_MODAL],
          })
          onSuccessOpenToast(rejectLeadsMessages.success)

          if (singleLead) {
            handleToggleRejectModal(false)
          }
        })
        .catch(() => {
          onErrorOpenToast(rejectLeadsMessages.error)
        })
    }
  }

  return {
    alertTexts,
    alertIsOpen,
    rejectModalIsOpen,
    handleAcceptLead,
    handleRejectLeads,
    handleCloseAlert,
    handleLeadsModal,
    handleToggleRejectModal,
    redirectToProposal,
    eventProperties,
  }
}
