import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'
import MaterialTextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import InputAdornment from '@material-ui/core/InputAdornment'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import palette from 'app_palette'
import styles from './styles'

const CustomPopper = function (props) {
  const classes = styles({})
  return (
    <Popper
      {...props}
      disablePortal
      className={classes.popper}
      placement="bottom-start"
    />
  )
}

function Select({
  setValue,
  children,
  icon,
  adornment,
  optionLabel,
  placeholder,
  onInputChange,
  ...props
}) {
  const classes = styles({})
  const SelectClass = classNames(classes.select)

  return (
    <MaterialAutocomplete
      native
      {...props}
      getOptionLabel={(option) =>
        option[optionLabel] ? option[optionLabel] : ''
      }
      onInputChange={({ target: { value } }) =>
        onInputChange && onInputChange(value)
      }
      onChange={(event, newValue) => setValue(newValue)}
      popupIcon={null}
      PopperComponent={CustomPopper}
      renderInput={(params) => (
        <MaterialTextField
          {...params}
          style={{ width: '100%' }}
          className={SelectClass}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  {adornment && adornment}
                  {icon && icon}
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      classes={{
        option: classes.option,
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option[optionLabel], inputValue)
        const parts = parse(option[optionLabel], matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  color: part.highlight
                    ? palette.grayscale[800]
                    : palette.grayscale[500],
                  fontSize: '17px',
                  lineHeight: '34px',
                  letterSpacing: '0.75px',
                }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}

Select.propTypes = {
  optionLabel: PropTypes.string,
}

Select.defaultProps = {
  optionLabel: 'title',
}

export default Select
