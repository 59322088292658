import React from 'react'

/** Material UI */
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Button, Grid } from '@material-ui/core'

/** Assets */
import night from './night.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#1335C6',
  },
  title: {
    color: '#FFF',
    fontSize: '40px',
    textAlign: 'center',
  },
  icon: {
    width: '100px',
    marginBottom: '20px',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  href: {
    textDecoration: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #FFF',
    color: '#FFF',
    marginTop: '20px',
    textTransform: 'none',
    fontSize: '16px',
  },
}))

function NotFoundPage(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid direction="column" container alignItems="center">
          <img className={classes.icon} src={night} alt="Lua"/>
          <Typography className={classes.title} variant="h6">
            {props.title ? props.title : 'Página não encontrada'}
          </Typography>
          <a href="/" className={classes.href}>
            <Button className={classes.button}>
              Voltar para a página inicial
            </Button>
          </a>
        </Grid>
      </div>
    </div>
  )
}

export default NotFoundPage
