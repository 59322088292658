import { DropdownControlled } from 'components/Controllers/Dropdown'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAdminStructureTypesProducts } from 'domains/admin/structure-types-products/hooks/useAdminStructureTypesProducts'
import * as S from './styles'
import { useAdminInfosDisponibilityProducts } from 'domains/admin/infos-disponibility-products/hooks/useAdminInfosDisponibilityProducts'

export const StructureTypeForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const fieldValues = useWatch({ control })

  const { structureTypesData } = useAdminStructureTypesProducts()
  const {
    dataOptions: { phasesData, voltagesData },
  } = useAdminInfosDisponibilityProducts(fieldValues.filter.power_fase)

  return (
    <>
      <S.Container>
        <S.Label>Selecione o tipo de estrutura da instalação</S.Label>
        <DropdownControlled
          control={control}
          label="Tipo de estrutura"
          id="sizing_select_structureType"
          options={structureTypesData}
          name="structure_type"
          value={fieldValues.structure_type}
          error={errors.structure_type?.message as string}
          fullWidth
        />
      </S.Container>

      <S.Wrapper>
        <DropdownControlled
          control={control}
          label="Selecione a fase"
          id="sizing_select_phase"
          options={phasesData}
          name="filter.power_fase"
          value={fieldValues.filter.power_fase}
          fullWidth
        />
        <DropdownControlled
          control={control}
          label="Selecione a tensão"
          id="sizing_select_voltage"
          options={voltagesData}
          name="filter.voltage"
          value={fieldValues.filter.voltage}
          fullWidth
        />
      </S.Wrapper>
    </>
  )
}
