import { useMutation } from '@tanstack/react-query'
import { createDimensioning } from '../services/api'
import { type ICreateDimensioningPayload } from '../services/types'

export const useCreateDimensioning = () => {
  const mutation = useMutation({
    mutationFn: async (dimensioningData: ICreateDimensioningPayload) => {
      return await createDimensioning(dimensioningData)
    },
  })

  return mutation
}
