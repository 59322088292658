import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  padding: 0 8px;

  margin-bottom: 56px;

  @media (min-width: 768px) {
    padding: 0 16px;
  }
`

export const ChartContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  height: 300px;

  @media (min-width: 768px) {
    height: 40vh;
  }

  @media (min-width: 1536px) {
    height: 45vh;
  }
`
