import { useSolLeadsModalStore } from 'containers/SolLeadAlert/components/LeadsModal/store/useSolLeadsModalStore'
import { useHistory, useLocation } from 'react-router-dom'

export const useSolLeadsNotificationAction = () => {
  const history = useHistory()
  const { pathname }: { pathname: string } = useLocation()

  const setIsSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.setIsSolLeadsModalOpen,
  )
  const openSolLeadsModalNotification = () => {
    if (pathname === '/dimensionar' || pathname.includes('/cotar')) {
      sessionStorage.setItem('open-sol-leads-modal', 'true')
      return history.push('/')
    }

    setIsSolLeadsModalOpen(true)
  }

  return {
    openSolLeadsModalNotification,
  }
}
