import { useMediaQuery } from '@material-ui/core'
import { Button, useToast } from '@77sol/core'
import {
  CEPForm,
  ConcessionaireForm,
  ProductionForm,
  DeliveryStateForm,
  OriginLeadForm,
  StructureTypeForm,
  TypesDimensioningForm,
} from './components/FormPart'
import { useIsAdmin } from 'hooks/useIsAdmin'
import { EnumSizingType } from '../../constants'
import { InfoRule14300 } from 'components/InfoRule14300'
import { useCreateSizing } from './hooks/useCreateSizing'
import { useFormContext } from 'react-hook-form'
import { isBasicSizing, isDetailedSizing } from '../../utils'
import { useMemo } from 'react'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import * as S from './styles'

interface ISizingTypesProps {
  currentSizingType: EnumSizingType
  setStatusPercentageFioB: (value: boolean) => void
  statusPercentageFioB: boolean
  porcentagemProducao: number
  setPorcentagemProducao: (value: number) => void
  loadingCreateSizing: boolean
  handleCreateBasicSizing: () => void
  setIsDimensioningDataScreen: (value: boolean) => void
  setResultadoDimensionamento: (value: boolean) => void
}

export function SizingTypes({
  currentSizingType,
  setStatusPercentageFioB,
  statusPercentageFioB,
  porcentagemProducao,
  setPorcentagemProducao,
  loadingCreateSizing,
  handleCreateBasicSizing,
  setIsDimensioningDataScreen,
  setResultadoDimensionamento,
}: ISizingTypesProps) {
  const { handleSubmit } = useFormContext()
  const downSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const { onErrorOpenToast } = useToast()

  const isAdmin = useIsAdmin()

  const { handleCreateDetailedSizing, proposalCreateDimensioning } =
    useCreateSizing({
      setIsDimensioningDataScreen,
      setResultadoDimensionamento,
      currentSizingType,
      statusPercentageFioB,
      porcentagemProducao,
    })

  const isDetailed = useMemo(
    () => isDetailedSizing(currentSizingType),
    [currentEnterpriseConfigs],
  )

  const isBasic = useMemo(
    () => isBasicSizing(currentSizingType),
    [currentSizingType],
  )

  const handleCreateSizing = () => {
    if (isDetailed) {
      handleCreateDetailedSizing()
    } else if (isBasic) {
      handleCreateBasicSizing()
    }
  }

  const onSubmitFailed = () => {
    onErrorOpenToast(
      'Preencha todos os campos obrigatórios (*) para continuar.',
    )
  }

  return (
    <form onSubmit={handleSubmit(handleCreateSizing, onSubmitFailed)}>
      {isDetailed && (
        <>
          {currentSizingType === EnumSizingType.ConsumptionAverage && (
            <TypesDimensioningForm.ConsumptionAverage />
          )}
          {currentSizingType === EnumSizingType.AverageConsumptionValue && (
            <TypesDimensioningForm.AverageConsumptionValue />
          )}
          {currentSizingType === EnumSizingType.SystemPower && (
            <TypesDimensioningForm.SystemPower />
          )}
          {currentSizingType === EnumSizingType.MonthlyConsumption && (
            <TypesDimensioningForm.MonthlyConsumption />
          )}

          <CEPForm />

          <ConcessionaireForm
            setStatusPercentageFioB={setStatusPercentageFioB}
            statusPercentageFioB={statusPercentageFioB}
          />

          {currentSizingType !== EnumSizingType.SystemPower && (
            <ProductionForm
              porcentagemProducao={porcentagemProducao}
              setPorcentagemProducao={setPorcentagemProducao}
            />
          )}
        </>
      )}

      {isBasic && (
        <>
          {currentSizingType === EnumSizingType.BasicSystemPower && (
            <TypesDimensioningForm.SystemPower />
          )}
          {currentSizingType ===
            EnumSizingType.BasicAverageConsumptionValue && (
            <TypesDimensioningForm.AverageConsumptionValue />
          )}
        </>
      )}

      <StructureTypeForm />

      {isBasic && <DeliveryStateForm />}

      {isAdmin && <OriginLeadForm />}

      {isBasic && <InfoRule14300 />}

      <S.Actions>
        <Button
          id="sizing_button_simulate"
          size="medium"
          isLoading={
            loadingCreateSizing || proposalCreateDimensioning.isLoading
          }
          fullWidth={!!downSm}
          type="submit"
        >
          {isDetailed ? 'Calcular dimensionamento' : 'Simular projeto'}
        </Button>
      </S.Actions>
    </form>
  )
}
