import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Bar, defaults } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/core/styles'

require('./RoundedBars.js')

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}))

function Chart(props) {
  const {
    data: dataProp, labels, className, pdf, ...rest
  } = props

  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    defaults.global.defaultFontFamily = 'Nexa'
    defaults.global.defaultFontSize = 9
  }, [])

  const data = {
    datasets: [
      {
        label: 'Consumo Inicial',
        backgroundColor: 'rgba(62, 82, 179, 0.2)',
        data: dataProp.consumoInicial,
      },
      {
        label: 'Consumo Projetado',
        backgroundColor: theme.palette.primary.main,
        data: dataProp.consumoProjetado,
      },
      {
        label: 'Produção',
        backgroundColor: '#04cc54',
        data: dataProp.producao,
      },
    ],
    labels,
  }

  const options = pdf
    ? {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      cornerRadius: 20,
      legend: {
        display: false,
      },
      layout: {
        padding: 0,
      },
      scales: {
        xAxes: [
          {
            barThickness: 6,
            maxBarThickness: 6,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              padding: 20,
              fontColor: theme.palette.text.secondary,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: theme.palette.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              zeroLineColor: theme.palette.divider,
            },
            ticks: {
              padding: 20,
              fontColor: theme.palette.text.secondary,
              beginAtZero: true,
              min: 0,
              maxTicksLimit: 5,
              callback: (value) => (value > 0 ? `${value}K` : value),
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.white,
        titleFontColor: theme.palette.text.primary,
        bodyFontColor: theme.palette.text.secondary,
        footerFontColor: theme.palette.text.secondary,
        callbacks: {
          title: () => {},
          label: (tooltipItem) => {
            let label = ''
            if (tooltipItem.datasetIndex === 0) {
              label = ` Consumo inicial: ${tooltipItem.yLabel.toFixed(2)} kWh`
            }
            if (tooltipItem.datasetIndex === 1) {
              label = ` Consumo projetado: ${tooltipItem.yLabel.toFixed(
                2,
              )} kWh`
            }
            if (tooltipItem.datasetIndex === 2) {
              label = ` Produção: ${tooltipItem.yLabel.toFixed(2)} kWh`
            }
            return label
          },
        },
      },
    }
    : {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      cornerRadius: 20,
      legend: {
        display: false,
      },
      layout: {
        padding: 0,
      },
      scales: {
        xAxes: [
          {
            barThickness: 12,
            maxBarThickness: 10,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              padding: 20,
              fontColor: theme.palette.text.secondary,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: theme.palette.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              zeroLineColor: theme.palette.divider,
            },
            ticks: {
              padding: 20,
              fontColor: theme.palette.text.secondary,
              beginAtZero: true,
              min: 0,
              maxTicksLimit: 5,
              callback: (value) => (value > 0 ? `${value}K` : value),
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        caretSize: 10,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.white,
        titleFontColor: theme.palette.text.primary,
        bodyFontColor: theme.palette.text.secondary,
        footerFontColor: theme.palette.text.secondary,
        callbacks: {
          title: () => {},
          label: (tooltipItem) => {
            let label = ''
            if (tooltipItem.datasetIndex === 0) {
              label = ` Consumo inicial: ${tooltipItem.yLabel.toFixed(2)} kWh`
            }
            if (tooltipItem.datasetIndex === 1) {
              label = ` Consumo projetado: ${tooltipItem.yLabel.toFixed(
                2,
              )} kWh`
            }
            if (tooltipItem.datasetIndex === 2) {
              label = ` Produção: ${tooltipItem.yLabel.toFixed(2)} kWh`
            }
            return label
          },
        },
      },
    }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Bar data={data} options={options} />
    </div>
  )
}

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
}

export default Chart
