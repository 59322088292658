import { forwardRef } from 'react'
import { Wrapper } from './styles'

export type IScrollContainerProps = React.HtmlHTMLAttributes<HTMLDivElement>

export const ScrollContainer = forwardRef<
  HTMLDivElement,
  IScrollContainerProps
>(({ children, ...props }, ref) => {
  return (
    <Wrapper ref={ref} data-testid="scroll-container" {...props}>
      {children}
    </Wrapper>
  )
})
