import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { quotationService } from '../api'
import {
  type RevertKitQuotationResponse,
  type RedefineQuotationKitPayload,
} from '../types'

export function useRevertKitQuotation<TError = unknown, TContext = unknown>({
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: UseMutationOptions<
  RevertKitQuotationResponse,
  TError,
  RedefineQuotationKitPayload,
  TContext
>) {
  const revertKitQuotationMutation = useMutation({
    mutationFn: async (data) => await quotationService.redefineKit(data),
    onMutate,
    onSuccess,
    onError,
    onSettled,
  })
  return revertKitQuotationMutation
}
