import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  filledButton: {
    background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
    borderRadius: '8px',
    color: '#FFF',
    textTransform: 'none',
    padding: '8px 12px',
    '&:hover': {
      background: 'linear-gradient(90deg, rgb(20, 55, 196), rgb(55, 112, 212))',
      borderRadius: '8px',
    },
  },
  backdrop: {
    zIndex: 1000,
    color: '#fff',
    flexDirection: 'column',
  },
  outlinedButton: {
    backgroundColor: 'none',
    border: '1px solid #1335c6',
    color: '#1335c6',
    textTransform: 'none',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  topTitle: ({ isFromProposalList }) => ({
    width: isFromProposalList ? 'auto' : '100%',
    '@media (min-width:350px)': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginBottom: '16px',
    },
    '@media (min-width:1250px)': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: '0px',
    },
  }),
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
