import { Loader2 } from 'lucide-react'
import { Wrapper } from './styles'

export interface ILoadingProps {
  text: string
}

export function FullScreenLoading({ text }: ILoadingProps) {
  return (
    <Wrapper>
      <Loader2 size={48} />
      <span>{text}</span>
    </Wrapper>
  )
}
