import { palette } from '@77sol/styles'
import styled from 'styled-components'
import { ArrowChevronBackIcon } from '@77sol/icons/dist'

export const Header = styled.div`
  background-color: #ffff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 6px;
  height: 92px;

  @media screen and (max-width: 1280px) {
    background-color: ${palette.primary[300]};
    padding: 12px 24px;
    height: 62px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`
export const Title = styled.span`
  font-size: 20px;

  @media screen and (max-width: 1280px) {
    color: #ffff;
  }
`

export const BackIcon = styled(ArrowChevronBackIcon)`
  @media screen and (max-width: 1280px) {
    color: #ffff;
  }
`
