import { DropdownMenu } from '@77sol/core'
import { FullScreenLoading } from '../../../FullScreenLoading'
import { KebabMenu } from 'components/KebabMenu'
import { colors } from '@77sol-ui/tokens'
import { useRevertKitChanges } from '../../../Equipments/hooks/useRevertKitChanges'
import { useQuotationKebabMenu } from './hooks/useQuotationKebabMenu'
import { logEvent } from '@amplitude/analytics-browser'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { TAB_ENUM_AMPLITUDE } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'

export function QuotationKebabMenu() {
  const {
    handleChangeQuotationFreight,
    handleOpenAddEquipmentModal,
    handleOpenDiscountModal,
    deleteQuotation,
    handleGeneratePdfQuotation,
    isGeneratingQuotationPdf,
  } = useQuotationKebabMenu()
  const { isDisabledRevertButton, onRevertKit } = useRevertKitChanges()

  const currentTab = useEquipmentContainerStore((state) => state.currentTab)
  const { disabled } = useDisableActionButtons()

  function handleDeleteQuotation() {
    logEvent(quotationTracker.actions.removeQuotation, {
      origin: '/Cotar',
      tab: TAB_ENUM_AMPLITUDE[currentTab],
    })
    deleteQuotation()
  }

  return (
    <>
      <KebabMenu color={colors.gray[400]}>
        <DropdownMenu.Item onClick={handleOpenAddEquipmentModal}>
          Adicionar item
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={() => {
            !isDisabledRevertButton && onRevertKit()
          }}
          disabled={isDisabledRevertButton}
        >
          Reverter
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={() => {
            if (disabled) return
            handleGeneratePdfQuotation()
          }}
          disabled={disabled}
        >
          Baixar PDF
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleChangeQuotationFreight}>
          Alterar Frete
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleOpenDiscountModal}>
          Desconto
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleDeleteQuotation}>
          Excluir Cotação
        </DropdownMenu.Item>
      </KebabMenu>

      {isGeneratingQuotationPdf && <FullScreenLoading text="Carregando PDF" />}
    </>
  )
}
