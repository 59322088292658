import { useQuery } from '@tanstack/react-query'
import { rankingSummaryAll } from '../services/api'
import { GET_RANKING_SUMMARY } from '../constants/endpointKeys'

const STALE_TIME = 60 * 1000 // 1 min

export const useFetchRankingSummary = () => {
  const { data, ...rest } = useQuery({
    queryKey: [GET_RANKING_SUMMARY],
    queryFn: async () => {
      return await rankingSummaryAll.get()
    },
    staleTime: STALE_TIME,
  })

  if (!data)
    return {
      rankingSummaryData: { position: 0 },
      ...rest,
    }

  return {
    rankingSummaryData: data,
    ...rest,
  }
}
