import { signalsAll } from '../services/api'
import { useQuery } from '@tanstack/react-query'
import { GET_SIGNALS } from '../constants/endpointKeys'

export const useFetchSignals = () => {
  const data = useQuery({
    queryKey: [GET_SIGNALS],
    queryFn: async () => {
      return await signalsAll.get()
    },
    staleTime: 60 * 1000, // 1min
  })

  return {
    ...data,
  }
}
