import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

/** Plugins */
import PerfectScrollbar from 'react-perfect-scrollbar'

/** Material UI */
import { makeStyles } from '@material-ui/core/styles'
import {
  Card, CardContent,
} from '@material-ui/core'

/** Components */
import { Chart } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: 'none',
    overflowX: 'auto',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400,
  },
  pdfChart: {
    height: 'auto',
    minHeight: '200px',
  },
  pdfInner: {
    width: 'auto',
    marginTop: '12px',
  },
}))

function FinancialStats(props) {
  const {
    edit, className, proposal, pdf, ...rest
  } = props

  const classes = useStyles()

  const data = {
    consumoInicial: [
      proposal.consumoMensalInicial[0],
      proposal.consumoMensalInicial[1],
      proposal.consumoMensalInicial[2],
      proposal.consumoMensalInicial[3],
      proposal.consumoMensalInicial[4],
      proposal.consumoMensalInicial[5],
      proposal.consumoMensalInicial[6],
      proposal.consumoMensalInicial[7],
      proposal.consumoMensalInicial[8],
      proposal.consumoMensalInicial[9],
      proposal.consumoMensalInicial[10],
      proposal.consumoMensalInicial[11],
    ],
    consumoProjetado: [
      proposal.consumoMensal[0],
      proposal.consumoMensal[1],
      proposal.consumoMensal[2],
      proposal.consumoMensal[3],
      proposal.consumoMensal[4],
      proposal.consumoMensal[5],
      proposal.consumoMensal[6],
      proposal.consumoMensal[7],
      proposal.consumoMensal[8],
      proposal.consumoMensal[9],
      proposal.consumoMensal[10],
      proposal.consumoMensal[11],
    ],
    producao: [
      proposal.comp[1],
      proposal.comp[2],
      proposal.comp[3],
      proposal.comp[4],
      proposal.comp[5],
      proposal.comp[6],
      proposal.comp[7],
      proposal.comp[8],
      proposal.comp[9],
      proposal.comp[10],
      proposal.comp[11],
      proposal.comp[12],
    ],
  }

  const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      style={{ boxShadow: 'none' }}
    >
      {/* <CardHeader
        action={<GenericMoreButton />}
        title="Financial Stats"
      />
      <Divider /> */}
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={pdf ? classes.pdfInner : classes.inner}>
            <Chart
              className={pdf ? classes.pdfChart : classes.chart}
              data={data}
              labels={labels}
              pdf={pdf}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

FinancialStats.propTypes = {
  className: PropTypes.string,
}

export default FinancialStats
