import API from 'api'
import {
  GET_FINANCING_INSURANCE_PERCENTAGE,
  GET_FINANCING_PER_QUOTATION_SIMULATIONS,
  GET_FINANCING_PER_VALUE_SIMULATIONS,
} from '../constants/endpointKeys'

interface GetFinancingPerValueSimulationsProps {
  financingId: string
  signal: AbortSignal | undefined
}

interface GetFinancingPerQuotationSimulationsProps {
  quotationId: string
  signal: AbortSignal | undefined
}

export const financing = {
  getFinancingPerValueSimulations: async ({
    financingId,
    signal,
  }: GetFinancingPerValueSimulationsProps) => {
    if (!financingId) return

    return await API.get(
      `${GET_FINANCING_PER_VALUE_SIMULATIONS}/${financingId}`,
      {
        params: {
          origin: 'value',
        },
        signal,
      },
    )
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      })
  },
  getFinancingPerQuotationSimulations: async ({
    quotationId,
    signal,
  }: GetFinancingPerQuotationSimulationsProps) => {
    if (!quotationId) return

    return await API.get(
      `${GET_FINANCING_PER_QUOTATION_SIMULATIONS}/${quotationId}`,
      {
        signal,
      },
    )
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      })
  },
  getFinancingInsurancePercentage: async (signal: AbortSignal | undefined) => {
    return await API.get(`${GET_FINANCING_INSURANCE_PERCENTAGE}`, {
      signal,
    })
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      })
  },
}
