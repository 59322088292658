const parseBRL = (value) => {
  const numberToFormat = parseFloat(value) || 0

  return numberToFormat.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
}

export default parseBRL
