import { WeatherMoonIcon } from '@77sol/icons/dist'
import * as S from './styles'

interface EmptyStateProps {
  text: string
}

export function EmptyState({ text }: EmptyStateProps) {
  return (
    <S.Container>
      <WeatherMoonIcon width="24" />
      <span>{text}</span>
    </S.Container>
  )
}
