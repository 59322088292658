import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAmplitude } from 'hooks/useAmplitude'
import { useCampaignModals } from 'domains/campaign/modals/hooks/useCampaignModals'
import { advertisingModalTrackers } from 'services/tracker/events/advertisingModal/trackers'
import { useAdvertisingModalStore } from '../stores/useAdvertisingModalStore'
import { ADVERTISING_REDIRECT_URL } from '../constants/advertisingRedirectURL'
import { useAcl } from 'acl/hooks/useAcl'

export function useAdvertisingModal() {
  const history = useHistory()

  const { logEvent } = useAmplitude()
  const { data: campaignModalsData, isFetching: isFetchingCampaignModals } =
    useCampaignModals()
  const {
    advertisingModalIsOpen,
    openAdvertisingModal,
    closeAdvertisingModal,
  } = useAdvertisingModalStore()

  const { userType } = useAcl()

  const advertisingProps = campaignModalsData?.campaign
  const waitForAdvertising = isFetchingCampaignModals
  const existingAdvertising = Boolean(advertisingProps)
  const eventProperties = {
    advertising: advertisingProps?.slug,
  }

  const displayedAdvertisingModal = Boolean(
    localStorage.getItem('displayedAdvertisingModal'),
  )

  function handleOpenModal() {
    if (userType === 'distributor') return
    logEvent(advertisingModalTrackers.actions.modalAppeared, eventProperties)

    openAdvertisingModal()
    localStorage.setItem('displayedAdvertisingModal', 'true')
  }

  function handleCloseModal() {
    logEvent(advertisingModalTrackers.actions.closeModal, eventProperties)

    closeAdvertisingModal()
  }

  function handleRedirect() {
    logEvent(advertisingModalTrackers.actions.clicked, eventProperties)

    if (advertisingProps) {
      const redirect = ADVERTISING_REDIRECT_URL[advertisingProps.slug]

      handleCloseModal()

      if (redirect.newTab) {
        window.open(redirect.url, '_blank')
        return
      }

      history.push(redirect.url)
    }
  }

  useEffect(() => {
    if (!displayedAdvertisingModal && existingAdvertising) {
      handleOpenModal()
    }
  }, [displayedAdvertisingModal, existingAdvertising])

  return {
    advertisingProps,
    waitForAdvertising,
    existingAdvertising,
    advertisingModalIsOpen,
    handleOpenModal,
    handleCloseModal,
    handleRedirect,
  }
}
