import styled from 'styled-components'
import { palette } from '@77sol/styles'

export const Container = styled.div`
  display: flex;
  margin-top: 32px;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 2px solid #d9dbe9;
  }
`

export const Time = styled.div`
  display: flex;
  padding: 16px;
  min-width: 190px;

  @media (min-width: 1280px) {
    border-right: 2px solid #d9dbe9;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;

    @media (min-width: 1280px) {
      padding: 16px 0 16px 16px;
    }
  }
`

export const Context = styled.div`
  > h6 {
    color: ${(props) => props.titleColor};
  }

  a {
    color: ${palette.primary[300]};
    cursor: pointer;
  }
`
export const Icon = styled.div`
  width: 42px;

  > svg {
    color: ${(props) => props.color} !important;
  }
`
