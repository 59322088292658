import { useMemo } from 'react'
import { useFetchFinancingInsurancePercentage } from 'domains/financing/hooks/useFinancing'
import { type IInsuranceControl } from '../types/insuranceControl'

interface IUseInsuranceValue
  extends Omit<IInsuranceControl, 'handleControlInsurance'> {}

const INSTALLMENT_DEFAULT = 60
const MAX_FINANCING_VALUE = 75000

export function useInsuranceValue({
  financingValue = 0,
  isEnabledInsurance,
}: IUseInsuranceValue) {
  const { percentage } = useFetchFinancingInsurancePercentage()

  const insuranceValue = useMemo(() => {
    if (!isEnabledInsurance) {
      return 0
    }

    if (financingValue > MAX_FINANCING_VALUE) {
      return (MAX_FINANCING_VALUE * percentage) / INSTALLMENT_DEFAULT
    }

    return (financingValue * percentage) / INSTALLMENT_DEFAULT
  }, [percentage, financingValue, isEnabledInsurance])

  return {
    insuranceValue,
  }
}
