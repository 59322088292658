import { useInfiniteQuery } from '@tanstack/react-query'
import { FINANCING_ALL } from '../constants/endpointKeys'

import { type IGetFinancingAllResponse, type IOrder } from '../services/types'
import { getFinancingAll } from '../services/api'
import { useMemo } from 'react'

interface IUseFinancingAllProps {
  onSuccess?: (data: IGetFinancingAllResponse) => void
  enabled?: boolean
  onError?: (data: unknown) => void
  order?: IOrder
  staleTime?: number
  search?: string
}

export function useFinancingAll({
  order = 'desc',
  onSuccess,
  enabled = true,
  onError,
  staleTime,
  search = '',
}: IUseFinancingAllProps) {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: [FINANCING_ALL, order, search],
    queryFn: async ({ pageParam = 1 }) => {
      return await getFinancingAll({
        params: {
          page: pageParam,
          order,
          search,
        },
      })
        .then((response) => {
          const { data } = response
          onSuccess?.(data)
          return data
        })
        .catch((error: unknown) => {
          onError?.(error)
          throw new Error(error as any)
        })
    },
    enabled,
    staleTime,
    getNextPageParam: (data) => {
      const { current_page, last_page } = data.requisitions
      if (current_page < last_page) {
        return current_page + 1
      }
    },
  })

  const financingList = useMemo(() => {
    return data?.pages.flatMap((page) => page.requisitions.data) || []
  }, [data])

  return {
    financingList,
    ...rest,
  }
}
