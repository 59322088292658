import { useFormContext } from 'react-hook-form'
import { TextField } from 'components/Controllers/TextField'
import * as S from './styles'

export const AverageConsumptionValue = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <S.Container>
      <S.Label>
        Insira o valor médio pago referente aos últimos 12 meses
      </S.Label>
      <TextField
        autoFocus
        mask="real"
        control={control}
        label="Valor médio*"
        name="average_consumption_value"
        type="tel"
        error={errors.average_consumption_value?.message as string}
      />
    </S.Container>
  )
}
