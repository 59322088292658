import { QUERY_KEYS } from 'services/constant'
import API from '../../../api'
import { type ShowCEPGETResponse, type ShowCEPParams } from './types'

export const showCEP = {
  get: async (params: ShowCEPParams) =>
    await API.get<ShowCEPGETResponse>(QUERY_KEYS.SHOWCEP._, { params })
      .then(({ data }) => data)
      .catch(() => {
        throw new Error()
      }),
}
