import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { proposalQuotationChangeDeliveryValue } from '../services/api'
import { type IProposalQuotationChangeDeliveryValuePOSTPayload } from '../services/types'

export const useAddProposalQuotationChangeDeliveryValue = () => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (
      payload: IProposalQuotationChangeDeliveryValuePOSTPayload,
    ) => await proposalQuotationChangeDeliveryValue.post(payload),
    onError: () =>
      onErrorOpenToast('Ocorreu um erro ao tentar alterar o frete!'),
  })
}
