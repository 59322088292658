import api from 'api'
import { VALIDATE_QUOTATION_KIT } from '../constants/endpointKeys'
import { type IValidateQuotationKitResponse } from './types'

export const validateQuotationKitChanges = async (id: number) => {
  return await api
    .post<IValidateQuotationKitResponse>(`${VALIDATE_QUOTATION_KIT}`, {
      quotation_id: id,
    })
    .then(({ data }) => data)
}
