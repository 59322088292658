import { QueryClient } from '@tanstack/react-query'

const TIME_IN_MINUTES = 0

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: TIME_IN_MINUTES,
      refetchOnWindowFocus: false,
    },
  },
})
