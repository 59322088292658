import React, { useState } from 'react'
import { Modal, useMediaQuery } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Typography } from 'components'
import { Paper, Button, Spacer, useToast } from '@77sol/core'
import { useDispatch } from 'react-redux'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import { useAmplitude } from 'hooks/useAmplitude'
import { clientsTracker } from 'services/tracker/events/clients/tracker'
import { ClientsSelect } from '../../../StepOne/components'
import { useStyles } from './styles'

function ModalSelectClient({
  selectClientModal,
  setSelectClientModal,
  clients,
  getClients,
  proposal,
  fromStepFour,
  proposalListHandleClose,
  onCreateProject,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const dispatch = useDispatch()

  const { logEvent } = useAmplitude()
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [selectedClient, setSelectedClient] = useState()
  const [loading, setLoading] = useState(false)

  const [modalNewClientValidation, setModalNewClientValidation] =
    useState(false)

  const handleChangeClient = (client) => {
    logEvent(clientsTracker.actions.associateCustomer)
    setLoading(true)

    API.post('/link/quotation', {
      quotation_id: proposal.quotes[0].quotation.id,
      customer_id: client || selectedClient?.id,
    })
      .then((res) => {
        setSelectClientModal(false)
        dispatch(ProposalAction.SaveProposalRedux(res.data.proposal))
        dispatch(ProposalAction.SaveStepRedux('3'))
        onSuccessOpenToast('Projeto criado com sucesso!')
        onCreateProject?.(res.data)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o cliente!')
      })
      .finally(() => setLoading(false))
  }

  const handleCancelClient = () => {
    logEvent(clientsTracker.actions.cancelCustomer)

    if (fromStepFour) {
      setSelectClientModal(false)
      proposalListHandleClose()
    } else {
      setSelectClientModal(false)
    }
  }

  return (
    <Modal
      id="modal-select-client"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={selectClientModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={() => (fromStepFour ? '' : setSelectClientModal(false))}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={selectClientModal} style={{ border: 'none' }}>
        <Paper
          style={{
            width: downSm ? '95%' : '80%',
            maxHeight: selectedClient && !downSm ? 'auto' : '80%',
            padding: downSm ? '24px' : '32px 64px',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <Typography
            type={downSm ? 'link_medium' : 'display_x_small_x_bold'}
            color="primary"
            colorWeight="300"
          >
            {fromStepFour
              ? 'Para avançar com esta cotação atribua um cliente'
              : 'Para salvar esta cotação atribua um cliente'}
          </Typography>
          <Spacer size="32" direction="horizontal" />
          <ClientsSelect
            listHeight="calc(100vh - 460px)"
            getClients={getClients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            handleCreateProposal={handleChangeClient}
            clients={clients}
            hideText
            asModal
            setModalNewClientValidation={setModalNewClientValidation}
          />
          {!modalNewClientValidation && (
            <div
              style={{
                display: 'flex',
                position: 'sticky',
                bottom: '-32px',
                padding: '24px 0px',
                backgroundColor: 'white',
                width: '100%',
                left: '0px',
                zIndex: 120,
              }}
            >
              <Button
                size="small"
                noMargin
                onClick={() => handleChangeClient()}
                disabled={!selectedClient}
                isLoading={loading}
              >
                Salvar
              </Button>
              <Spacer size="16" direction="vertical" />
              <Button
                size="small"
                noMargin
                variant="outlined"
                onClick={handleCancelClient}
              >
                Cancelar
              </Button>
            </div>
          )}
        </Paper>
      </Fade>
    </Modal>
  )
}

export default ModalSelectClient
