import yup from 'libs/yup'

const required = 'Campo obrigatório'

export const billingAddressSchema = yup
  .object()
  .shape(
    {
      nome: yup.string().required(required),
      documento: yup.string().when('documento', {
        is: (value: string) => {
          return value?.length > 11
        },
        then: () => yup.string().required(required).cnpj('CNPJ Inválido'),
        otherwise: () => yup.string().required(required).cpf('CPF Inválido'),
      }),
      cep: yup.string().required(required).min(8, 'Insira um CEP válido'),
      endereco: yup.string().required(required),
      numero: yup.string().required(required),
      complemento: yup.string(),
      bairro: yup.string().required(required),
      cidade: yup.string().required(required),
      estado: yup
        .string()
        .required(required)
        .min(2, 'Insira um UF válido')
        .max(2),
    },
    [['documento', 'documento']],
  )
  .required()
