import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type ISendEmailForm, sendEmailSchema } from '../validators'
import { type IProposalCustomer } from 'dto/ProposalDTO'

const INITIAL_SEND_EMAIL_FIELDS = {
  email: '',
}

interface IUseSendEmailForm {
  customer: IProposalCustomer
  onCancel: () => void
  onSubmit: (data: ISendEmailForm) => Promise<void>
}

export function useSendEmailForm({
  customer,
  onCancel,
  onSubmit,
}: IUseSendEmailForm) {
  const { handleSubmit, reset, ...rest } = useForm({
    defaultValues: {
      ...INITIAL_SEND_EMAIL_FIELDS,
      ...(customer?.email && {
        email: customer.email,
      }),
    },
    resolver: yupResolver(sendEmailSchema),
  })

  const handleSendEmailCancel = () => {
    reset()
    onCancel()
  }

  const handleSendEmailSubmit = handleSubmit(async (data) => {
    await onSubmit(data)
  })

  return { handleSendEmailSubmit, handleSendEmailCancel, ...rest }
}
