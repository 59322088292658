import { Modal } from 'components/Modal'
import { Button } from '@77sol-ui/atoms'
import { ChooseQuotationByProposalTableContainer } from 'containers/ChooseQuotationByProposalTableContainer'
import { type IQuotationDTO } from 'dto/QuotationDTO'
import { type FormEvent } from 'react'
import { type IContentProps } from '../'
import { useCreateFinancingPerQuotationModalStore } from '../../../store'

interface QuotationListStepProps {
  choosedQuotation: IQuotationDTO | undefined
  setChooedQuotation: (value: IQuotationDTO) => void
  onCloseModal: IContentProps['onCloseModal']
  onNextStep: (e: FormEvent) => void
}

export const QuotationListStep = ({
  choosedQuotation,
  setChooedQuotation,
  onCloseModal,
  onNextStep,
}: QuotationListStepProps) => {
  const proposalID = useCreateFinancingPerQuotationModalStore(
    (state) => state.proposalID,
  )

  return (
    <>
      <Modal.Content>
        <ChooseQuotationByProposalTableContainer
          proposalID={proposalID as number}
          choosedQuotation={choosedQuotation}
          setChoosedQuotation={setChooedQuotation}
        />
      </Modal.Content>

      <Modal.Footer>
        <Modal.Close asChild>
          <Button onClick={onCloseModal} fullWidth variant="outline">
            Cancelar
          </Button>
        </Modal.Close>

        <Button
          onClick={onNextStep}
          fullWidth
          type="button"
          disabled={!choosedQuotation}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </>
  )
}
