import { CloseXIcon } from '@77sol/icons/dist'
import { useStyles } from './styles'
import { OrderSummary } from './components/OrderSummary'
import { ShipmentForm } from './components/ShipmentForm'
import { CircularProgress } from '../../components/CircularProgress'
import { ModalWrapper } from '../../components/ModalWrapper'
import { useFetchProposalQuotation } from 'domains/proposal/proposal-quotation'
import { type CheckoutModalProps } from './CheckoutModal.types'
import { SuccessOrderModal } from './components/Modals'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'

function CheckoutModal({
  openModal,
  cotacaoId,
  onCloseModal,
}: CheckoutModalProps): JSX.Element {
  const classes = useStyles()

  const { proposalQuotationData, isLoading } =
    useFetchProposalQuotation(cotacaoId)

  const orderId = useCheckoutModalStore((state) => state.orderId)

  if (orderId)
    return <SuccessOrderModal isOpen onClose={onCloseModal} orderId={orderId} />

  return (
    <ModalWrapper
      id="modal-checkout-equipment"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      isOpen={openModal}
    >
      <>
        {isLoading && <CircularProgress />}
        {proposalQuotationData && (
          <div className={classes.paper}>
            <div className={classes.container}>
              <CloseXIcon
                onClick={onCloseModal}
                className={classes.closeXIcon}
              />
              <OrderSummary
                data-testid="kit-container"
                kit={proposalQuotationData.kit}
                totalValue={proposalQuotationData.quotation.equipment_value}
              />
              <ShipmentForm
                quotation={proposalQuotationData.quotation}
                proposalId={proposalQuotationData.dimensioning.proposal_id}
                isCustomer={!!proposalQuotationData.customer_id}
                kit={proposalQuotationData.kit}
                quotationId={cotacaoId}
                onCloseCheckoutModal={onCloseModal}
              />
            </div>
          </div>
        )}
      </>
    </ModalWrapper>
  )
}

export default CheckoutModal
