import React, { useEffect, useState } from 'react'
import { Modal, useMediaQuery, Backdrop, Fade } from '@material-ui/core'
import { Paper, Spacer, Button } from '@77sol/core'
import { Typography, Input } from 'components'
import palette from 'app_palette'
import { ArrowChevronBackIcon } from '@77sol/icons/dist'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import axios from 'axios'
import optionsUtils from '../../../../../../utils/options'
import { useStyles } from './styles'

function ModalFrete({
  openModalFrete,
  setOpenModalFrete,
  handleChangeShipmentValue,
  actualQuote,
  loadingDelivery,
}) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const initialsValues = {
    city: null,
    state: actualQuote?.quotation?.delivery_state,
  }

  const [optionsCity, setOptionsCity] = useState([])
  const [city, setCity] = useState(initialsValues.city)
  const [state, setState] = useState(initialsValues.state)

  useEffect(() => {
    if (
      currentEnterpriseConfigs?.modalFrete?.copernicoStates &&
      state !== `sem_frete`
    ) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`,
        )
        .then((res) => {
          setOptionsCity([
            {
              label: 'Selecione uma cidade',
              value: '',
            },
            ...res.data.map(({ nome }) => ({
              label: nome,
              value: nome,
            })),
          ])
        })
    }
  }, [state])

  return (
    <Modal
      id="modal-update-freight"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalSm}
      open={openModalFrete}
      onClose={() => setOpenModalFrete(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalFrete} style={{ border: 'none' }}>
        <div className={classes.paperModalCards}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: '32px',
              alignItems: 'center',
            }}
          >
            {downSm ? (
              <Paper
                style={{
                  backgroundColor: palette.primary[300],
                  height: 'auto',
                  width: '100%',
                  borderRadius: '0px',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  padding: '16px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{ display: 'flex', cursor: 'pointer', color: 'white' }}
                  onClick={() => {
                    setOpenModalFrete(false)
                  }}
                >
                  <ArrowChevronBackIcon width="24" color={palette.white} />
                  <Spacer size="8" direction="vertical" />
                  <Typography type="link_small" color="white">
                    Alterar frete
                  </Typography>
                </div>
              </Paper>
            ) : (
              <Typography
                type="display_x_small_x_bold"
                color="primary"
                colorWeight="300"
              >
                Alterar frete
              </Typography>
            )}
          </div>
          {downSm && <Spacer size="46" direction="horizontal" />}
          <div style={{ width: '100%' }}>
            <Input
              select
              size="small"
              value={state}
              style={{ width: '100%' }}
              setValue={setState}
            >
              {optionsUtils
                .frete(currentEnterpriseConfigs?.modalFrete?.freeShipping)
                .map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
            </Input>
            {currentEnterpriseConfigs?.modalFrete?.copernicoStates &&
              state !== 'sem_frete' && (
                <>
                  <Spacer size="16" direction="horizontal" />
                  <Input
                    select
                    size="small"
                    value={city}
                    style={{ width: '100%' }}
                    setValue={setCity}
                  >
                    {optionsCity.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </>
              )}
          </div>
          <div style={{ display: 'flex', width: '100%', marginTop: '48px' }}>
            <Button
              size="small"
              noMargin
              onClick={() =>
                handleChangeShipmentValue(
                  { state, city },
                  actualQuote.quotation.id,
                )
              }
              isLoading={loadingDelivery}
            >
              Confirmar
            </Button>
            <Spacer size="16" direction="vertical" />
            <Button
              onClick={() => setOpenModalFrete(false)}
              size="small"
              noMargin
              variant="outlined"
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ModalFrete
