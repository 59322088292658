import * as S from './styles'
import { TextField } from 'components/Controllers/TextField'
import { useFormContext } from 'react-hook-form'

export const MonthlyConsumption = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <S.Container>
      <div>
        <S.Label>Insira o consumo de energia por mês</S.Label>
      </div>
      <div className="form-grid">
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_januaryConsumption"
          autoFocus
          label="Janeiro*"
          name="consumption_potency_january"
          endAdornment="kWh"
          error={errors.consumption_potency_january?.message as string}
          type="number"
          control={control}
        />

        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_februaryConsumption"
          label="Fevereiro*"
          name="consumption_potency_february"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_february?.message as string}
          control={control}
        />

        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_marchConsumption"
          label="Março*"
          name="consumption_potency_march"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_march?.message as string}
          control={control}
        />
      </div>
      <div className="form-grid">
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_aprilConsumption"
          label="Abril*"
          name="consumption_potency_april"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_april?.message as string}
          control={control}
        />

        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_mayConsumption"
          label="Maio*"
          name="consumption_potency_may"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_may?.message as string}
          control={control}
        />

        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_juneConsumption"
          label="Junho*"
          name="consumption_potency_june"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_june?.message as string}
          control={control}
        />
      </div>
      <div className="form-grid">
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_julyConsumption"
          label="Julho*"
          name="consumption_potency_july"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_july?.message as string}
          control={control}
        />
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_augustConsumption"
          label="Agosto*"
          name="consumption_potency_august"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_august?.message as string}
          control={control}
        />

        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_septemberConsumption"
          label="Setembro*"
          name="consumption_potency_september"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_september?.message as string}
          control={control}
        />
      </div>
      <div className="form-grid">
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_octoberConsumption"
          label="Outubro*"
          name="consumption_potency_october"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_october?.message as string}
          control={control}
        />
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_novemberConsumption"
          label="Novembro*"
          name="consumption_potency_november"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_potency_november?.message as string}
          control={control}
        />
        <TextField
          className="g-xs-12 g-md-4"
          id="sizing_input_decemberConsumption"
          label="Dezembro*"
          name="consumption_december"
          endAdornment="kWh"
          type="number"
          error={errors.consumption_december?.message as string}
          control={control}
        />
      </div>
    </S.Container>
  )
}
