import { CloseXIcon } from '@77sol/icons/dist'
import * as S from './styles'
export type CloseButtonProps = React.ComponentProps<typeof S.closeIcon>

export const CloseButton = ({ ...props }: CloseButtonProps) => {
  return (
    <S.closeIcon {...props}>
      <CloseXIcon width="24" />
    </S.closeIcon>
  )
}
