import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  paper: ({ fillDirection, pointer, borderColor, noBackground }) => ({
    width:
      fillDirection === 'horizontal' || fillDirection === 'both'
        ? '100%'
        : 'auto',
    height:
      fillDirection === 'horizontal' || fillDirection === 'both'
        ? '100%'
        : 'auto',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.04)',
    padding: '24px',
    borderRadius: '8px',
    cursor: pointer && 'pointer',
    border:
      borderColor === 'none'
        ? 'none'
        : `2px solid ${palette[borderColor][300]}`,
    background: noBackground && 'none',
  }),
}))

export default styles
