import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable } from 'react-beautiful-dnd'
import { Grid } from '@material-ui/core'
import smoothscroll from 'smoothscroll-polyfill'
import { DragPaper } from '../../..'
import {
  DigitalMoreInfosContainers,
  DigitalPaymentsOptionsContainers,
} from '..'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
})

function DigitalPaymentSection({
  container,
  index,
  editMode,
  internalEditing,
  setInternalEditing,
  statePaymentSections,
  setStatePaymentSections,
  drag,
  setDrag,
  editingMore,
  selectedQuote,
  setEditingMore,
  size,
  paymentOptionsContainers,
  setPaymentOptionsContainers,
  moreInfosContainers,
  setMoreInfosContainers,
  proposal,
  colors,
}) {
  const classes = useStyles()

  return (
    <Draggable
      isDragDisabled={
        editMode !== 'paymentsOptionsContainer'
        || editingMore === 'proposalInfosCard'
          || editingMore === 'paymentOptionsCard'
      }
      key={container.id}
      draggableId={container.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className={classes.relative}
        >
          <div
            style={{ height: '100%' }}
            className={snapshot.isDragging && classes.dragging}
            onClick={(e) => {
              if (editMode === 'paymentsOptionsContainer') {
                internalEditing && internalEditing === container.id
                  ? e.stopPropagation()
                  : // setInternalEditing(container.id)
                  e.stopPropagation()
                setEditingMore('')
              }
            }}
          >
            {container.id === 'paymentsOptionsContainers' && (
              <DigitalPaymentsOptionsContainers
                paymentOptionsContainers={paymentOptionsContainers}
                setPaymentOptionsContainers={setPaymentOptionsContainers}
                editMode={editMode}
                drag={drag}
                internalEditing={internalEditing}
                editingMore={editingMore}
                setEditingMore={setEditingMore}
                setDrag={setDrag}
                selectedQuote={selectedQuote}
                setInternalEditing={setInternalEditing}
                proposal={proposal}
                colors={colors}
              />
            )}
            {container.id === 'moreInfosContainers' && (
              <DigitalMoreInfosContainers
                moreInfosContainers={moreInfosContainers}
                setMoreInfosContainers={setMoreInfosContainers}
                editMode={editMode}
                drag={drag}
                internalEditing={internalEditing}
                editingMore={editingMore}
                setEditingMore={setEditingMore}
                setDrag={setDrag}
                setInternalEditing={setInternalEditing}
                proposal={proposal}
                colors={colors}
              />
            )}
          </div>
          {snapshot.isDragging && <DragPaper />}
        </Grid>
      )}
    </Draggable>
  )
}

export default DigitalPaymentSection
