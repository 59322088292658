import { ScrollTable } from '@77sol-ui/molecules'
import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const Row = styled(ScrollTable.Tr)`
  cursor: pointer;
  background-color: ${colors.white[0]};

  &:last-child {
    > td {
      border-bottom-width: 1px !important;
      border-bottom-color: ${colors.gray[100]};
    }
  }

  &:hover {
    background-color: ${colors.gray[50]};
    > td {
      background-color: ${colors.gray[50]};
    }
  }
`
