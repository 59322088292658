import React from 'react'
import { FilledAlertInfoIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { FeaturedStar, FinancialLabel } from 'components'
import { formatDate } from 'utils'
import { formatReal } from 'utils/format'
import { Box } from '@material-ui/core'
import {
  FINANCING_QUOTATION_COLUMNS,
  FINANCING_PER_VALUE_COLUMNS,
} from '../constants'

export function filterFinancingToId(financing_id, financings = []) {
  if (financing_id) {
    const filteredFinancings = financings.filter(
      ({ id }) => id === parseInt(financing_id),
    )

    return filteredFinancings.length > 0 ? filteredFinancings : financings
  }
  return financings
}

export function formatFinancingPerQuotationList(financings) {
  return financings.map((financing) => {
    const {
      id_origin,
      customer,
      simulations,
      updated_at,
      status,
      potency,
      notification,
      name_requester,
      corporate_name_requester,
    } = financing

    return {
      id: `#${id_origin}`,
      potency: `${Number(potency).toFixed(2)}Kwp`,
      customer,
      requester_name: name_requester || corporate_name_requester || '',
      quotes: simulations,
      request_date: (
        <>
          {parseInt(notification) !== 0 && (
            <FilledAlertInfoIcon
              color={palette.orange[300]}
              width="18"
              style={{ marginRight: '12px' }}
            />
          )}
          {formatDate(updated_at)}
        </>
      ),
      financing_status: <FinancialLabel status={status} />,
      rowData: { ...financing },
    }
  })
}

export function formatFinancingPerValueList(financings) {
  return financings.map((financing) => {
    const {
      financing_id,
      id_financing_origin,
      closer,
      value,
      name_requester,
      name_integrator,
      corporate_name_requester,
      simulations,
      updated_at,
      status_name,
      notification,
    } = financing

    const financingId = financing_id || id_financing_origin

    return {
      id: {
        type: 'component',
        Component: ({ isRowSelected }) => (
          <Box fontSize="17px" display="flex">
            #{financingId}
            {closer && (
              <FeaturedStar
                color={
                  isRowSelected
                    ? 'white'
                    : name_integrator
                    ? 'primary'
                    : 'yellow'
                }
                tooltip={
                  name_integrator
                    ? `Feito para: ${name_integrator}`
                    : 'Time Comercial 77Sol'
                }
              />
            )}
          </Box>
        ),
      },
      value: formatReal(value),
      requester_name: name_requester || corporate_name_requester || '',
      simulations,
      request_date: (
        <>
          {parseInt(notification) !== 0 && (
            <FilledAlertInfoIcon
              color={palette.orange[300]}
              width="18"
              style={{ marginRight: '12px' }}
            />
          )}
          {formatDate(updated_at)}
        </>
      ),
      financing_status: <FinancialLabel status={status_name} />,
      rowData: {
        ...financing,
        financing_id: financingId,
      },
    }
  })
}

function reduceColumnsFinancingPerQuotation(minimun) {
  return ({ id, potency, customer, rowData }) =>
    minimun ? { id, rowData } : { id, potency, customer, rowData }
}

export function getMinTableInfoFinancingPerQuotations(
  values,
  isOpenFinancingDetail,
  isOpenFinancingTabs,
) {
  if (isOpenFinancingDetail || isOpenFinancingTabs) {
    return {
      columns:
        isOpenFinancingDetail && isOpenFinancingTabs
          ? FINANCING_QUOTATION_COLUMNS.ONE
          : FINANCING_QUOTATION_COLUMNS.LESS,
      values: values.map(
        reduceColumnsFinancingPerQuotation(
          isOpenFinancingDetail && isOpenFinancingTabs,
        ),
      ),
    }
  }

  return {
    values,
    columns: FINANCING_QUOTATION_COLUMNS.MORE,
  }
}

function reduceColumnsFinancingPerValue(minimun) {
  return ({ id, value, requester_name, rowData }) =>
    minimun ? { id, rowData } : { id, value, requester_name, rowData }
}

export function getMinTableInfoFinancingPerValue(
  values,
  isOpenFinancingDetail,
  isOpenFinancingTabs,
) {
  if (isOpenFinancingDetail || isOpenFinancingTabs) {
    return {
      columns:
        isOpenFinancingDetail && isOpenFinancingTabs
          ? FINANCING_PER_VALUE_COLUMNS.ONE
          : FINANCING_PER_VALUE_COLUMNS.LESS,
      values: values.map(
        reduceColumnsFinancingPerValue(
          isOpenFinancingDetail && isOpenFinancingTabs,
        ),
      ),
    }
  }

  return {
    values,
    columns: FINANCING_PER_VALUE_COLUMNS.MORE,
  }
}
