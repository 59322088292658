import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import useWindowSize from 'hooks/useWindowSize'
import * as S from './styles'
import { Button } from '@77sol-ui/atoms'
import { ButtonAddQuotation } from './components/ButtonAddQuotation'
import { TAB_ENUM } from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { logEvent } from '@amplitude/analytics-browser'

export function CompareKitHeader() {
  const changeTab = useEquipmentContainerStore((state) => state.changeTab)
  const { width } = useWindowSize()
  const isMobile = width < 768

  function handleChangeTab() {
    logEvent(quotationTracker.actions.exitCompareMode, {
      origin: '/Cotar',
    })
    changeTab(TAB_ENUM.CUSTOMIZE_KIT)
  }

  return (
    <S.HeaderButtonsWrapper className="headerButtonsContainer">
      {!isMobile && (
        <Button variant="outline" onClick={handleChangeTab}>
          Sair do modo comparação
        </Button>
      )}
      <ButtonAddQuotation />
    </S.HeaderButtonsWrapper>
  )
}
