import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  buttonContainerPotencyDiferency: {
    [theme.breakpoints.up('xl')]: {
      minWidth: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      marginTop: '0px',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      marginTop: '16px',
    },
  },
  paperPotencyDiferency: {
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  input: {
    borderRadius: '8px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  noPadding: {
    padding: '0px',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
