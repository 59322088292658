import { useEquipmentList } from 'domains/equipments/list/hooks/useEquipmentList'
import {
  type IEquipmentListParamsKeys,
  type IEquipmentListParams,
} from 'domains/equipments/list/services/types'
import { useState } from 'react'
import { INITIAL_STATE_FILTER } from '../constants/initialState'

export function useEquipmentsStore() {
  const [filters, setFilters] =
    useState<IEquipmentListParams>(INITIAL_STATE_FILTER)

  const {
    isFetching,
    isFetchingNextPage,
    total: totalProducts,
    data: filteredProdutosNovaLoja,
    hasNextPage: hasMoreFilteredProducts,
    fetchNextPage: updatePageFilteredProducts,
  } = useEquipmentList(filters)

  const loadingProdutosNovaLoja = isFetching && !isFetchingNextPage

  function handleFilterProducts(
    field: IEquipmentListParamsKeys,
    value = undefined,
  ) {
    if (field === 'category') {
      setFilters({
        ...filters,
        [field]: value === 'todas' ? undefined : value,
      })

      return
    }

    setFilters({
      ...filters,
      [field]: value,
    })
  }

  return {
    totalProducts,
    filteredProdutosNovaLoja,
    loadingProdutosNovaLoja,
    hasMoreFilteredProducts,
    updatePageFilteredProducts,
    handleFilterProducts,
  }
}
