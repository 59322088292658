import { Header } from '@77sol-ui/organisms'
import { colors } from '@77sol-ui/tokens'
import styled from 'styled-components'

export const ButtonProfile = styled(Header.DropdownTrigger)`
  svg {
    transform: rotate(0deg);
    transition: transform 300ms ease-in-out;
  }
  &[data-state='open'] svg {
    transform: rotate(180deg);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  margin-right: 0.5rem;
  padding-right: 1rem;

  border-right: 1px solid ${colors.gray[300]};

  > button {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
`
