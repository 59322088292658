import { Root, type IRootProps } from './Root'
import { ButtonAdd, type IButtonAddProps } from './ButtonAdd'
import { Content, type IContentProps } from './Content'
import { ScrollContainer, type IScrollContainerProps } from './ScrollContainer'

const CardQueue = {
  Root,
  ButtonAdd,
  Content,
  ScrollContainer,
}

export {
  CardQueue,
  type IRootProps,
  type IContentProps,
  type IButtonAddProps,
  type IScrollContainerProps,
}
