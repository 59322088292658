import { Timeline } from 'components'
import { type OrderTimelineProps } from './OrderTimeline.types'
import { useOrderTimeline } from './hooks/useOrderTimeline'

const OrderTimeline = ({
  currentStatus,
  orderId,
  ...props
}: OrderTimelineProps) => {
  const { timelineOptions, currentStep } = useOrderTimeline({
    currentStatus,
    orderId,
  })

  const orderTimelineJustiyContent =
    timelineOptions.length > 4 ? 'space-between' : 'flex-start'

  return (
    <Timeline
      justifyContent={orderTimelineJustiyContent}
      {...props}
      options={timelineOptions}
      currentStep={currentStep}
    />
  )
}

export default OrderTimeline
