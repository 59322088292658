import { makeStyles } from '@material-ui/core/styles'
import backgroundImage from 'assets/img/77sol_selection.png'
import backgroundImageMobile from 'assets/img/77sol_selection_mobile.png'

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    maxHeight: '79vh',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      maxHeight: '100vh',
      overflowY: 'auto',
    },
    height: '79vh',
    width: '90vw',
    maxWidth: '1680px',
    cursor: 'pointer',
    position: 'relative',
    backgroundImage: `url(${backgroundImage})`,
    [breakpoints.down('sm')]: {
      height: '74vh',
      backgroundImage: `url(${backgroundImageMobile})`,
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  closeModal: {
    position: 'absolute',
    left: '-8px',
    top: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))
