import { palette } from '@77sol/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin: 8px 0;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
  }
`

export const CurrentCalculation = styled.div`
  background: ${palette.primary[300]};
  width: 100%;
  height: 56px;
  border-radius: 12px;
  color: #fff;
  transition: background 250ms;

  display: flex;
  align-items: center;

  @media screen and (min-width: 1280px) {
    flex: 1;
  }

  :hover {
    background: ${palette.primary[400]};
  }

  svg {
    width: 22px;
    margin: 0 8px 0 24px;
  }
`
