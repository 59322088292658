import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@77sol/core'
import styles from './styles'

function TimelineItem({ title, hour, ...props }) {
  const classes = styles()

  return (
    <div {...props} className={classes.timelineItem}>
      <Typography size="xsmall" colorWeight="800">
        {title}
      </Typography>
      <Typography size="xsmall" colorWeight="600">
        {hour}
      </Typography>
    </div>
  )
}

TimelineItem.propTypes = {
  title: PropTypes.string,
  hour: PropTypes.string,
}

TimelineItem.defaultProps = {
  title: '',
  hour: '',
}

export default TimelineItem
