import { type Features } from 'acl/config/features.types'
import { useAcl } from 'acl/hooks/useAcl'
import { useMemo } from 'react'

interface ILockedComponentProps {
  children: React.ReactNode
  feature: Features
}

export function LockedComponent({ children, feature }: ILockedComponentProps) {
  const { checkIfUserHasPermission } = useAcl()

  const hasPermission = useMemo(
    () => checkIfUserHasPermission(feature),
    [checkIfUserHasPermission, feature],
  )

  if (hasPermission) {
    return <>{children}</>
  }

  return null
}
