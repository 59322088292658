import styled from 'styled-components'

interface OrderFiltersProps {
  isOpenSlide: boolean
}

export const FiltersContainer = styled.div<OrderFiltersProps>`
  padding: 24px;

  .mobile-filter {
    display: none;
  }

  @media (max-width: 1280px) {
    padding: 0;

    .mobile-filter {
      padding: ${({ isOpenSlide }) => (isOpenSlide ? '0' : '24px 0 0 24px')};
      display: ${({ isOpenSlide }) => (isOpenSlide ? 'none' : 'block')};
    }

    .form-grid {
      display: none;

      > span {
        display: none;
      }
    }
  }
`
