import { number, string, object } from 'yup'
import { EnumSizingType } from '../constants'
import { isBasicSizing, isDetailedSizing } from '../utils'

const required = 'Campo obrigatório'
const dealershipTariffMessage = 'Valor mínimo para a tarifa é de R$ 0,01'

const monthlyValidator = () =>
  number().when('sizing_type', {
    is: (value: string) => value === EnumSizingType.MonthlyConsumption,
    then: () =>
      number()
        .min(140, 'Consumo mínimo é de 140 kWh')
        .required(required)
        .typeError(required),
    otherwise: () => string().nullable(),
  })

export const schema = object().shape({
  system_potency: number().when('sizing_type', {
    is: (value: string) =>
      value === EnumSizingType.SystemPower ||
      value === EnumSizingType.BasicSystemPower,
    then: () =>
      number()
        .min(1.2, 'Potência mínima é de 1,2 kWp')
        .typeError(required)
        .required(required),
    otherwise: () => string().nullable(),
  }),
  average_consumption_value: number().when('sizing_type', {
    is: (value: string) =>
      value === EnumSizingType.AverageConsumptionValue ||
      value === EnumSizingType.BasicAverageConsumptionValue,
    then: () =>
      number()
        .min(200, 'Valor mínimo é de R$200,00')
        .required(required)
        .typeError(required),
    otherwise: () => string().nullable(),
  }),
  average_consumption_kwh: number().when('sizing_type', {
    is: (value: string) => value === EnumSizingType.ConsumptionAverage,
    then: () =>
      number()
        .min(140, 'Consumo mínimo é de 140 kWh')
        .required(required)
        .typeError(required),
    otherwise: () => string().nullable(),
  }),
  consumption_potency_january: monthlyValidator(),
  consumption_potency_february: monthlyValidator(),
  consumption_potency_march: monthlyValidator(),
  consumption_potency_april: monthlyValidator(),
  consumption_potency_may: monthlyValidator(),
  consumption_potency_june: monthlyValidator(),
  consumption_potency_july: monthlyValidator(),
  consumption_potency_august: monthlyValidator(),
  consumption_potency_september: monthlyValidator(),
  consumption_potency_october: monthlyValidator(),
  consumption_potency_november: monthlyValidator(),
  consumption_december: monthlyValidator(),
  zipcode: string().when('sizing_type', {
    is: (value: EnumSizingType) => isDetailedSizing(value),
    then: () => string().required(required).min(8, 'Insira um CEP válido'),
    otherwise: () => string().nullable(),
  }),
  dealership: number().when('sizing_type', {
    is: (value: EnumSizingType) => isDetailedSizing(value),
    then: () => number().required(required),
    otherwise: () => string().nullable(),
  }),
  dealership_tariff: number().when('sizing_type', {
    is: (value: EnumSizingType) => isDetailedSizing(value),
    then: () =>
      number()
        .min(0.01, dealershipTariffMessage)
        .typeError(dealershipTariffMessage)
        .required(dealershipTariffMessage),
    otherwise: () => string().nullable(),
  }),
  percentage_fio_b: number().when('sizing_type', {
    is: (value: EnumSizingType) => isDetailedSizing(value),
    then: () =>
      number()
        .min(1, 'O valor não pode ser menor que 1%')
        .max(100, 'O valor não pode ser maior que 100%')
        .typeError('Digite um valor considerando o mín: 1% e o máx: 100%')
        .required(required),
    otherwise: () => string().nullable(),
  }),
  structure_type: string().required(required),
  filter: object()
    .shape({
      power_fase: string().when('filter.voltage', {
        is: (value: string) => value,
        then: () => string().required(required),
        otherwise: () => string().nullable(),
      }),
      voltage: string().when('filter.power_fase', {
        is: (value: string) => value,
        then: () => string().required(required),
        otherwise: () => string().nullable(),
      }),
    })
    .notRequired(),
  delivery_state: string().when('sizing_type', {
    is: (value: EnumSizingType) => isBasicSizing(value),
    then: () => string().required(required),
    otherwise: () => string().nullable(),
  }),
  origin_lead: string().nullable(),
})
