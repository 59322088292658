import { useEffect, useState } from 'react'
import API from 'api'

export function useListProduct({ product, filters, quotationId }) {
  const [page, setPage] = useState(1)
  const [productsList, setProductsList] = useState([])
  const [hasMoreProduct, setHasMoreProduct] = useState(false)
  const [isLoadingProduct, setIsLoadingProduct] = useState(false)

  useEffect(() => {
    const firstPage = page === 1

    if (firstPage) {
      setIsLoadingProduct(true)
    }

    API.get('/loja/get-produtos', {
      params: {
        page,
        category: product.categoria,
        quotation_id: quotationId,
        ...filters,
      },
    })
      .then(({ data }) => {
        setHasMoreProduct(data.current_page < data.last_page)

        if (firstPage) {
          setProductsList(data.data)
        } else {
          setProductsList((acc) => [...acc, ...data.data])
        }
      })
      .finally(() => {
        setIsLoadingProduct(false)
      })
  }, [page, filters])

  function handleNextPage() {
    setPage(page + 1)
  }

  function handleResetPage() {
    setPage(1)
  }

  return {
    productsList,
    hasMoreProduct,
    isLoadingProduct,
    handleNextPage,
    handleResetPage,
  }
}
