const { defineState } = require('redux-localstore')

const INITIAL_STATE = {
  structureTypes: [],
  allPaymentMethods: {
    billets: { status: 0 },
    card: { status: 0 },
    financing: { status: 0 },
    bank_transfer: { status: 0 },
  },
}

const initialState = defineState(INITIAL_STATE)('ReducerQuotation')

export function ReducerQuotation(state = initialState, action) {
  switch (action.type) {
    case 'SET_STRUCTURE_TYPES':
      return { ...state, structureTypes: action.structureTypes }
    case 'SET_ALL_PAYMENT_METHODS':
      return { ...state, allPaymentMethods: action.allPaymentMethods }
    default:
      return state
  }
}
