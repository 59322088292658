import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input, Typography } from 'components'
import { Spacer, Button, Paper, useToast } from '@77sol/core'
import { LockIcon, LockUnlockedIcon, EditIcon } from '@77sol/icons/dist'
import { useDispatch, useSelector } from 'react-redux'
import palette from 'app_palette'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import API from 'api'
import { handleUnit } from 'utils'
import * as ProposalAction from 'store/actions'
import { setProModalOpen } from 'store/actions/proModal'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

const useStyles = makeStyles({
  div: {
    height: '100%',
  },
})

function PotenciaCard({
  showButtons,
  proposal,
  locked,
  actualEdit,
  setActualEdit,
  handleChangeLock,
  sizingEdit,
  setOthersLoading,
}) {
  const isProPlan = useSelector((state) => state.ReducerProfile.isProPlan)

  const classes = useStyles()
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const dispatch = useDispatch()

  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newPotency, setNewPotency] = useState(
    parseFloat((proposal.potenciaCC / 1000).toFixed(2)),
  )

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setOthersLoading(loading)
  }, [loading])

  const handleChangePotency = () => {
    setLoading(true)

    const finalValue = newPotency
      ? newPotency.toString().replace(',', '.')
      : parseFloat((proposal.potenciaCC / 1000).toFixed(2))

    API.post('/proposal/dimensioning/update/potency', {
      dimensioning_id: proposal.id,
      potency: finalValue,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        setEditing(false)
        onSuccessOpenToast('Potência alterada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar a potência!')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (editing) {
      setActualEdit('potency')
    } else {
      setActualEdit('')
    }
  }, [editing])

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.div} style={stylesAnimation.bounce}>
        <Paper
          style={{
            border: sizingEdit ? `2px solid ${palette.grayscale[300]}` : 'none',
            boxShadow: sizingEdit ? 'none' : 'rgb(0 0 0 / 4%) 0px 4px 6px',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              type={downSm ? 'link_small' : 'text_medium'}
              color="grayscale"
              colorWeight="700"
            >
              Potência do sistema
            </Typography>
            {showButtons == false ? (
              ''
            ) : (
              <>
                {locked == 'potencia' ? (
                  <LockIcon width="24" color={palette.primary[300]} />
                ) : (
                  <LockUnlockedIcon
                    width="24"
                    color={palette.grayscale[400]}
                    onClick={() => {
                      if (!isProPlan) return dispatch(setProModalOpen())

                      handleChangeLock('potencia')
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </>
            )}
          </div>
          <Spacer size="16" direction="horizontal" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              {editing ? (
                <Input
                  type="number"
                  autoFocus
                  value={newPotency}
                  setValue={setNewPotency}
                  submit={handleChangePotency}
                  onBlur={() => handleChangePotency()}
                  adornmentPosition="right"
                  adornment="kWp"
                  loading={loading}
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography
                    color="grayscale"
                    colorWeight="700"
                    type={
                      downSm ? 'display_x_small_bold' : 'display_small_bold'
                    }
                  >
                    {handleUnit(proposal.potenciaCC / 1000).value}
                  </Typography>
                  <Spacer size="4" direction="vertical" />
                  <Typography
                    color="grayscale"
                    colorWeight="600"
                    type="text_x_small"
                  >
                    {handleUnit(proposal.potenciaCC / 1000).label}
                    Wp
                  </Typography>
                </div>
              )}
            </div>
            {!showButtons ? (
              ''
            ) : (
              <>
                {downSm || !upXl ? (
                  <>
                    {!editing && (
                      <EditIcon
                        width="24"
                        onClick={() => {
                          if (!isProPlan) return dispatch(setProModalOpen())

                          editing ? handleChangePotency() : setEditing(true)
                        }}
                        color={palette.grayscale[800]}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {!editing && (
                      <Button
                        onClick={() => {
                          if (!isProPlan) return dispatch(setProModalOpen())

                          editing ? handleChangePotency() : setEditing(true)
                        }}
                        disabled={actualEdit == 'consumption'}
                        noMargin
                        variant="outlined"
                        size="medium"
                        style={{ marginLeft: '16px' }}
                        isLoading={loading}
                      >
                        {editing ? 'Confirmar' : 'Alterar'}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Paper>
      </div>
    </StyleRoot>
  )
}

export default PotenciaCard
