import { Badge, type IBadgeProps } from '@77sol-ui/atoms'
import { Tooltip } from '@77sol-ui/molecules'
import { useState } from 'react'

export interface ILegendProps {
  text: string
  description: string
  color: IBadgeProps['color']
  maxWidth?: React.CSSProperties['maxWidth']
}

export function Legend({ text, description, color, maxWidth }: ILegendProps) {
  const [open, setOpen] = useState(false)
  return (
    <Tooltip.Provider delayDuration={50}>
      <Tooltip.Root open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger>
          <Badge
            text={text}
            color={color}
            variant="ghost"
            role="button"
            onClick={() => {
              setOpen(true)
            }}
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            align="end"
            maxWidth={maxWidth}
            textAlign="center"
            side="top"
            sideOffset={10}
            variant="white"
          >
            {description}
            <Tooltip.Arrow variant="white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}
