import { Form } from './styles'
import { useCreateFinancingPerValueFormContext } from './hooks/useCreateFinancingPerValueForm'
import { type ICreateFinancingPerValueFormProps } from './types'
import { FormPart } from './components/FormPart'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export function FormManager({
  formId,
  onSubmit,
}: ICreateFinancingPerValueFormProps) {
  const { handleSubmit, getValues } = useCreateFinancingPerValueFormContext()

  const choosedPersonType = getValues('client_type')

  return (
    <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <FormPart.Commom />
      {choosedPersonType === PERSON_TYPE_ENUM.NATURAL && (
        <FormPart.NaturalPerson />
      )}
      {choosedPersonType === PERSON_TYPE_ENUM.JURIDICAL && (
        <FormPart.JuridicalPerson />
      )}
    </Form>
  )
}
