import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
  gap: 24px;

  display: none;

  > h2 {
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media (min-width: 576px) {
    display: flex;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;

  margin-bottom: 24px;

  > .content-left,
  .content-right {
    display: flex;
    align-items: center;
    gap: 10px;

    > button {
      height: 46px;
      display: flex;
      gap: 8px;
    }
  }
`
