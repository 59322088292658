import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  table: {
    '& > div > table > tbody > tr': {
      boxShadow: `inset 0px 0px 2px ${palette.grayscale[500]}`,
    },
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
}))

export default useStyles
