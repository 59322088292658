import { useCallback, useEffect, useState } from 'react'
import { type ISelectQuotationProps } from '../types'
import { useAmplitude } from 'hooks/useAmplitude'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import {
  TAB_ENUM,
  TAB_ENUM_AMPLITUDE,
} from 'containers/EquipmentQuotationContainer/enums/tabEnum'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'

export function useSelectQuotation({
  onAddQuotation,
  quotations,
  onQuotationSelect,
  quotationSelected,
}: ISelectQuotationProps) {
  const [selectedQuotationId, setSelectedQuotationId] = useState('')
  const { logEvent } = useAmplitude()

  const currentTab = useEquipmentContainerStore((state) => state.currentTab)
  const handleChangeTab = useEquipmentContainerStore((state) => state.changeTab)

  const handleValueChange = useCallback(
    (quotationId: string) => {
      if (quotationId === 'create-quotation') {
        logEvent(quotationTracker.actions.newQuotation, {
          origin: '/Cotar',
          tab: TAB_ENUM_AMPLITUDE[currentTab],
        })
        onAddQuotation?.()
        handleChangeTab(TAB_ENUM.CHOOSE_KIT)
        return
      }
      setSelectedQuotationId(quotationId)

      const quotationToSelect = quotations.find(
        (quote) => quote.id === Number(quotationId),
      )

      if (quotationToSelect) {
        onQuotationSelect(quotationToSelect)
      }
    },
    [quotations],
  )

  useEffect(() => {
    if (quotationSelected) {
      setSelectedQuotationId(quotationSelected?.id?.toString())
    }
  }, [quotationSelected])

  return { handleValueChange, selectedQuotationId }
}
