import { useMemo, useState } from 'react'
import { SendModal } from 'components/SendModal'
import { LinkIcon, WhatsappIcon, ExportIcon, MailIcon } from '@77sol/icons/dist'
import { Button } from '@77sol/core'
import {
  ORDER_TRACKING_SHARE_ICONS,
  ORDER_TRACKING_SHARE_TITLES,
} from 'views/Pedidos/constants/orderTracking'
import * as S from './styles'
import { useOrderTrackingModal } from './hooks/useOrderTrackingModal'
import { type IOrderDTO } from 'dto/OrderDTO'
import { SendEmailForm } from 'components/SendModal/components/SendEmailForm'
import { SendWhatsAppForm } from 'components/SendModal/components/SendWhatsAppForm'

interface OrderTrackingModalProps {
  isOpen: boolean
  onClose: () => void
  order: IOrderDTO
}

export function OrderTrackingModal({
  isOpen,
  onClose,
  order,
}: OrderTrackingModalProps) {
  const {
    handleCopyTrackingOrderLink,
    handleEmailSubmit,
    handleOpenTrackingOrderInNewTab,
    handleSendOnWhatsapp,
  } = useOrderTrackingModal({
    order,
  })
  const [shareType, setShareType] = useState<string>('')

  const customer = useMemo(() => {
    return {
      id: order.customer_id,
      email: order.customer_email,
      celular: order.customer_phone,
      nome: order.customer_name,
    }
  }, [order])

  const handleFormCancel = () => {
    setShareType('')
  }

  return (
    <SendModal
      id="order-send-modal"
      open={isOpen}
      onClose={onClose}
      Icon={
        ORDER_TRACKING_SHARE_ICONS[shareType] ||
        ORDER_TRACKING_SHARE_ICONS.default
      }
      title={
        ORDER_TRACKING_SHARE_TITLES[shareType] ||
        ORDER_TRACKING_SHARE_TITLES.default
      }
    >
      {shareType === 'email' && (
        <SendEmailForm
          customer={customer}
          onSubmit={handleEmailSubmit}
          onCancel={handleFormCancel}
        />
      )}
      {shareType === 'whatsapp' && (
        <SendWhatsAppForm
          customer={customer}
          onSubmit={handleSendOnWhatsapp}
          onCancel={handleFormCancel}
        />
      )}
      {!shareType && (
        <S.Buttons>
          <Button
            size="small"
            color="primary"
            startIcon={<MailIcon />}
            onClick={() => {
              setShareType('email')
            }}
          >
            Enviar por e-mail
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<LinkIcon />}
            onClick={handleCopyTrackingOrderLink}
          >
            Copiar link
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<WhatsappIcon />}
            onClick={() => {
              setShareType('whatsapp')
            }}
          >
            Enviar por WhatsApp
          </Button>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            startIcon={<ExportIcon />}
            onClick={handleOpenTrackingOrderInNewTab}
          >
            Abrir rastreio
          </Button>
        </S.Buttons>
      )}
    </SendModal>
  )
}
