export const initialPdf = [
  {
    id: 'LinkPdf',
    content: 'item-linkPdf',
    display: 'Link da proposta',
    visible: true,
  },
  {
    id: 'DescriptionPdf',
    content: 'item-descriptionPdf',
    display: 'Descrição da proposta',
    visible: true,
  },
  {
    id: 'WhoWeArePdf',
    content: 'item-whoWeArePdf',
    display: 'Quem somos',
    visible: true,
  },
  {
    id: 'SchedulesPdf',
    content: 'item-schedulesPdf',
    display: 'Cronograma',
    visible: true,
  },
  {
    id: 'PremisesPdf',
    content: 'item-premisesPdf',
    display: 'Premissas do projeto',
    visible: true,
  },
  {
    id: 'TermsPdf',
    content: 'item-termsPdf',
    display: 'Termos e condições',
    visible: true,
  },
  {
    id: 'ObservationsPdf',
    content: 'item-observationsPdf',
    display: 'Observações',
    visible: true,
  },
]

export const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]
