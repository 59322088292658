import { ADMIN_77_STORAGE_PREFIX } from './constants'

const key = ADMIN_77_STORAGE_PREFIX.concat('v2_api_token')

export const V2_TOKEN_API_STORAGE = {
  get: () => localStorage.getItem(key),
  set: (token: string) => {
    localStorage.setItem(key, token)
  },
}
