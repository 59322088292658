import { useState } from 'react'
import { Grid } from '@material-ui/core'
import { Typography } from 'components'
import { DatePicker, Input, Dropdown } from '@77sol/core'
import { ViewIcon, HideIcon } from '@77sol/icons/dist'
import palette from 'app_palette'
import { useStyles } from '../styles'
import { useAdminStructureTypesProducts } from 'domains/admin/structure-types-products/hooks/useAdminStructureTypesProducts'

function AddForm({
  nomeClientAdd,
  setNomeClientAdd,
  cepClientAdd,
  setCepClientAdd,
  enderecoClientAdd,
  setEnderecoClientAdd,
  numeroClientAdd,
  setNumeroClientAdd,
  complementoClientAdd,
  setComplementoClientAdd,
  bairroClientAdd,
  setBairroClientAdd,
  cidadeClientAdd,
  setCidadeClientAdd,
  estadoClientAdd,
  setEstadoClientAdd,
  emailClientAdd,
  setEmailClientAdd,
  telefoneClientAdd,
  setTelefoneClientAdd,
  cpfClientAdd,
  setCpfClientAdd,
  nascimentoClientAdd,
  setNascimentoClientAdd,
  valorConsumoAdd,
  setValorConsumoAdd,
  tipoEstruturaAdd,
  setTipoEstruturaAdd,
  getEndereco,
  loadingCep,
  triedSave,
  moreDataDisplayed,
  onDisplayMoreData,
}) {
  const classes = useStyles()

  const { structureTypesData } = useAdminStructureTypesProducts()

  const [cepError, setCepError] = useState('')
  const handleInvalidInput = (value) => ({
    state: value?.length < 1 ? 'error' : '',
    message: value?.length < 1 ? 'O campo é obrigatório!' : '',
  })

  function handleValidateCep() {
    if (
      cepClientAdd.length > 1 &&
      cepClientAdd.replace(/[^+\d]+/g, '')?.length < 8
    ) {
      setCepError('invalid')
    } else {
      getEndereco()
      setCepError('')
    }
  }

  function handleDisplayData() {
    onDisplayMoreData(!moreDataDisplayed)
  }

  return (
    <Grid spacing={2} container>
      <Grid className={classes.container} item xs={12} md={12}>
        <Input
          id="addForm_input_nameOrCorporateName"
          value={nomeClientAdd}
          onChange={setNomeClientAdd}
          title="Nome completo / Razão social*"
          validationState={
            triedSave ? handleInvalidInput(nomeClientAdd).state : 'none'
          }
        />
      </Grid>
      <Grid className={classes.container} item xs={12} md={12}>
        <Typography
          id="addForm_button_hideOrShow"
          onClick={handleDisplayData}
          color="primary"
          colorWeight="400"
          type="link_x_small"
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          {!moreDataDisplayed ? (
            <ViewIcon
              color={palette.primary[400]}
              width="16"
              style={{ marginRight: '8px' }}
            />
          ) : (
            <HideIcon
              color={palette.primary[400]}
              width="16"
              style={{ marginRight: '8px' }}
            />
          )}
          {!moreDataDisplayed ? 'Completar dados' : 'Esconder dados'}
        </Typography>
      </Grid>
      {moreDataDisplayed && (
        <>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="addForm_input_cep"
              mask="cep"
              onBlur={handleValidateCep}
              isLoading={loadingCep}
              value={cepClientAdd}
              onChange={setCepClientAdd}
              title="CEP"
              validationState={
                cepError ||
                (triedSave ? handleInvalidInput(cepClientAdd).state : 'none')
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={7}>
            <Input
              id="addForm_input_address"
              value={enderecoClientAdd}
              onChange={setEnderecoClientAdd}
              title="Endereço"
              validationState={
                triedSave ? handleInvalidInput(enderecoClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={2}>
            <Input
              id="addForm_input_addressNumber"
              type="number"
              value={numeroClientAdd}
              onChange={setNumeroClientAdd}
              title="Numero"
              validationState={
                triedSave ? handleInvalidInput(numeroClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="addForm_input_complement"
              value={complementoClientAdd}
              onChange={setComplementoClientAdd}
              title="Complemento"
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={3}>
            <Input
              id="addForm_input_neighborhood"
              value={bairroClientAdd}
              onChange={setBairroClientAdd}
              title="Bairro"
              validationState={
                triedSave ? handleInvalidInput(bairroClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={4}>
            <Input
              id="addForm_input_city"
              value={cidadeClientAdd}
              onChange={setCidadeClientAdd}
              title="Cidade"
              validationState={
                triedSave ? handleInvalidInput(cidadeClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={2}>
            <Input
              id="addForm_input_state"
              value={estadoClientAdd}
              onChange={setEstadoClientAdd}
              title="Estado"
              validationState={
                triedSave ? handleInvalidInput(estadoClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="addForm_input_email"
              value={emailClientAdd}
              onChange={setEmailClientAdd}
              title="E-mail"
              validationState={
                triedSave ? handleInvalidInput(emailClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="addForm_input_phoneNumber"
              mask="phoneNumber"
              value={telefoneClientAdd}
              onChange={setTelefoneClientAdd}
              title="Telefone"
              validationState={
                triedSave ? handleInvalidInput(telefoneClientAdd).state : 'none'
              }
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="addForm_input_cpfOrCnpj"
              value={cpfClientAdd}
              onChange={setCpfClientAdd}
              mask="cpfCnpj"
              title="CPF / CNPJ"
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <DatePicker
              id="addForm_datePicker_birthDate"
              value={nascimentoClientAdd}
              dateChange={setNascimentoClientAdd}
              title="Data de nascimento / Fundação"
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Input
              id="addForm_input_billValue"
              mask="real"
              value={valorConsumoAdd}
              onChange={setValorConsumoAdd}
              title="Valor de consumo médio"
            />
          </Grid>
          <Grid className={classes.container} item xs={12} md={6}>
            <Dropdown
              fullWidth
              id="addForm_input_structureType"
              value={tipoEstruturaAdd}
              onChange={setTipoEstruturaAdd}
              options={structureTypesData}
              title="Tipo de estrutura"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AddForm
