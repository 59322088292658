import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
    backgroundColor: palette.grayscale[100],
    height: 'var(--app-height)',
    overflow: 'hidden',
  },
}))

export default useStyles
