import api from 'api'

import {
  type IGetFinancingAllRequest,
  type IGetFinancingAllResponse,
} from './types'
import { FINANCING_ALL } from '../constants/endpointKeys'

export const getFinancingAll = async ({ params }: IGetFinancingAllRequest) => {
  return await api.get<IGetFinancingAllResponse>(
    `${FINANCING_ALL}${params.search}`,
    {
      params: {
        order: params?.order,
        page: params?.page,
      },
    },
  )
}
