import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paper: {
    '@media (min-width:350px)': {
      backgroundColor: '#F7F7FC',
      padding: '24px',
      borderRadius: '0px',
      border: 'none',
      width: '100vw',
      height: 'var(--app-height)',
      marginTop: '0px',
      display: 'flex',
      position: 'absolute',
      boxShadow: 'none',
    },
    '@media (min-width:780px)': {
      backgroundColor: theme.palette.background.paper,
      padding: '32px 64px',
      borderRadius: '8px',
      border: 'none',
      width: '90vw',
      height: '90vh',
      marginLeft: '5vw',
      marginTop: '5vh',
      display: 'flex',
      position: 'absolute',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    },
  },
}))
