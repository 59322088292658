import { makeStyles } from '@material-ui/core/styles'
import { effects } from '@77sol/styles'

const styles = makeStyles(theme => ({
  container: ({ padding, fullHeight, fullWidth }) => ({
    padding: padding === 'small' ? '16px' : '24px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: 'none',
    boxShadow: effects.boxShadow.small,
    height: fullHeight ? '100%' : 'auto',
    width: fullWidth ? '100%' : 'auto',
    backgroundColor: '#FFFFFF',
  }),
  children: {
    paddingTop: '8px',
    height: '100%',
  },
}))

export default styles
