import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  select: () => ({
    '&.MuiTextField-root': {
      height: '56px',
      fontWeight: 400,
    },
    '& .Mui-Autocomplete-popper': {
      backgroundColor: 'red',
      height: 'var(--app-height)',
    },
    '& .MuiInputBase-root': {
      backgroundColor: palette.grayscale[200],
      borderRadius: '16px',
      padding: '7px 24px',
      height: '56px',
    },
    '& .Mui-focused': {
      border: `2px solid ${palette.primary[300]}`,
      background: 'none',
      // color: `${palette.primary[300]}`,
      fontSize: '15px',
      letterSpacing: '0.75px',
      borderRadius: '16px 16px 0px 0px',
      padding: '7px 24px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-disabled': {
      opacity: 0.4,
    },
    '& .MuiSelect-outlined': {
      borderRadius: '16px !important',
      padding: '16px 24px',
    },
    '& .MuiSelect-iconOutlined': {
      right: '16px',
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: palette.grayscale[200],
      },
    },
  }),
  popper: () => ({
    position: 'absolute',
    zIndex: 10000,
    //background: 'none',
    backgroundColor: palette.grayscale[200],
    border: 'none',
    boxShadow: 'none !important',
    padding: '0px',
    marginTop: '-1px',
    marginLeft: '0.3px',
    top: '-1px',
    //width: 'inherit !important',
    borderRadius: '0px 0px 16px 16px',
    paddingBottom: '6px',
    '& .MuiAutocomplete-listbox': {
      backgroundColor: palette.grayscale[200],
      boxShadow: 'none !important',
      border: 'none',
      '& li:nth-child(even)': { background: 'none', width: '100% !important' },
      '& li:nth-child(odd)': { background: 'none', width: '100% !important' },
    },
    '& .MuiAutocomplete-paper': {
      boxShadow: 'none !important',
      background: palette.grayscale[200],
    },
  }),
}))

export default styles
