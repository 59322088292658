export const DEFAULT_NULL_FILES_VALUE = {
  file: null,
  name_file: null,
  url: null,
}

export const TITLE_MODAL_ALL_FIELDS_FILLED =
  'Deseja enviar pedido para Análise?'
export const TITLE_MODAL_SOME_FIELD_DIRTY = 'Deseja salvar alterações?'

export const DESCRIPTION_MODAL_ALL_FIELDS_FILLED =
  'Você está saindo da tela e não enviou o pedido para análise, deseja enviar as informações antes de sair?'

export const DESCRIPTION_MODAL_SOME_FIELD_DIRTY =
  'Você está saindo da tela e não salvou as alterações, deseja salvar as alterações antes de sair?'

export const INVALID_FIELDS_MESSAGE =
  'Ops, existe um ou mais campos que foram preenchidos de forma inválida. Por favor, corrija antes de prosseguir.'

export const TITLE_BUTTON_ALL_FIELDS_FILLED = 'Enviar'
export const TITLE_BUTTON_SOME_DIRTY_FIELD = 'Salvar'
export const INTERNAL_STATUS_FORECAST = 12
