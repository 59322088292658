import { colors } from '@77sol-ui/tokens'
import { Page } from 'components'
import styled from 'styled-components'

export const Wrapper = styled(Page)`
  background-color: ${colors.white[0]};
`

export const Container = styled.main`
  padding: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`
