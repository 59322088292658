import { BREAKPOINTS } from 'constants/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 12px;

  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${BREAKPOINTS.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${BREAKPOINTS.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${BREAKPOINTS.xxl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`
