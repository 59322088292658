import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import { Paper, Typography } from 'components'
import { Spacer } from '@77sol/core'
import palette from 'app_palette'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import styles from './styles'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

function ResultPaper({
  onClick,
  firstText,
  secondText,
  title,
  type,
  sizeText,
  bordered,
  children,
  autoHeight,
  ...props
}) {
  const classes = styles({ bordered })
  const ResultPaperClass = classNames(classes.resultPaper)
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.divPaper} style={stylesAnimation.bounce}>
        <Paper
          {...props}
          className={ResultPaperClass}
          borderColor={type === 'new' ? 'primary' : 'none'}
          fillDirection="vertical"
          onClick={onClick}
          style={{
            border: bordered ? `2px solid ${palette.grayscale[300]}` : 'none',
            boxShadow: bordered ? 'none' : 'rgb(0 0 0 / 4%) 0px 4px 6px',
            height: autoHeight && '100%',
          }}
        >
          {(type === 'show' || type === 'select') && (
            <>
              <Typography
                color="grayscale"
                colorWeight="700"
                type={downSm ? 'link_small' : 'text_medium'}
              >
                {title}
              </Typography>
              <Spacer size="16" direction="horizontal" />
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Typography
                  color="grayscale"
                  colorWeight="700"
                  type={
                    downSm
                      ? 'display_x_small_bold'
                      : sizeText === 'small'
                        ? 'link_large'
                        : 'display_small_bold'
                  }
                >
                  {firstText}
                </Typography>
                <Spacer size="4" direction="vertical" />
                <Typography
                  color="grayscale"
                  colorWeight="600"
                  type="text_x_small"
                >
                  {secondText}
                </Typography>
              </div>
              {type === 'select' && (
                <>
                  <Spacer size="16" direction="horizontal" />
                  {children}
                </>
              )}
            </>
          )}
        </Paper>
      </div>
    </StyleRoot>
  )
}

ResultPaper.propTypes = {
  title: PropTypes.string,
  firstText: PropTypes.string,
  secondText: PropTypes.string,
  type: PropTypes.oneOf(['show', 'select']),
  sizeText: PropTypes.oneOf(['small', '']),
}

ResultPaper.defaultProps = {
  title: '',
  firstText: '',
  secondText: '',
  type: 'show',
  sizeText: '',
}

export default ResultPaper
