import { EmptyState, TableList, Loading } from 'components'
import { ListContainer } from './styles'
import { InfiniteScrollLoading } from '../../../InfiniteScrollLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Hidden } from '@material-ui/core'
import ResponsiveCard from '../../../ResponsiveCard'
import { useListFinancingValue } from './hooks/useListFinancingValue'

export function ListFinancingValue({
  isOpenSimulations,
  isOpenSimulationDetails,
  onRowClick,
  selectedFinancing,
  financingId,
  defaultTab,
}) {
  const { columns, values, fetchNextPage, hasNextPage, isLoading } =
    useListFinancingValue({
      isOpenSimulations,
      isOpenSimulationDetails,
      onRowClick,
      financingId,
      defaultTab,
    })

  return (
    <Loading isLoading={isLoading} minHeight="250px">
      {values.length === 0 ? (
        <EmptyState text="Não há nenhum financiamento." minHeight="20%" />
      ) : (
        <ListContainer>
          <Hidden smDown={!isOpenSimulations && !isOpenSimulationDetails}>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={values.length}
              height="calc(var(--app-height) - 240px)"
              loader={<InfiniteScrollLoading />}
            >
              <TableList
                onRowClick={onRowClick}
                values={values}
                columns={columns}
                selectedRow={selectedFinancing}
              />
            </InfiniteScroll>
          </Hidden>

          <Hidden mdUp>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              dataLength={values.length}
              height="calc(var(--app-height) - 265px)"
              loader={<InfiniteScrollLoading />}
            >
              {values?.map((items, index) => (
                <ResponsiveCard
                  key={index}
                  values={items}
                  onClick={() => {
                    onRowClick(items)
                  }}
                />
              ))}
            </InfiniteScroll>
          </Hidden>
        </ListContainer>
      )}
    </Loading>
  )
}
