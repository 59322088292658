import { useMutation } from '@tanstack/react-query'
import { rejectLeads } from '../services/api'
import { type IRejectLeadsPayload } from '../services/types'

export function useRejectLeads() {
  return useMutation({
    mutationFn: async (payload: IRejectLeadsPayload) =>
      await rejectLeads(payload),
  })
}
