import React from 'react'
import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Box,
  useMediaQuery,
} from '@material-ui/core'
import { Button, Spacer, Paper, Typography, Title, useToast } from '@77sol/core'
import {
  ArrowChevronBackIcon,
  CircleActionsAlertInfoIcon,
} from '@77sol/icons/dist'
import { QuotationContainer } from 'containers'
import API from 'api'
import { useDispatch, useSelector } from 'react-redux'
import * as ProposalAction from 'store/actions'
import palette from 'app_palette'
import { isEmpty } from 'utils'
import { useStyles } from './styles'

export function ModalOutdatedQuotes({
  outdatedQuotes,
  quoteIdEditing,
  outdatedQuotesModal,
  setOutdatedQuotesModal,
  outdatedQuotesModalStep,
  setOutdatedQuotesModalStep,
  setQuoteIdEditing,
  handleChangeQuantity,
  setSelectedOutdatedQuote,
  selectedOutdatedQuote,
  setModalChangeProduct,
  setProductToChange,
  loadingAttProduct,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { onErrorOpenToast } = useToast()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const proposal = useSelector((state) => state.Reducer1)

  const handleUpdateCurrentQuote = () => {
    dispatch(
      ProposalAction.SaveActiveSizingRedux(
        proposal.quotes.find(
          (quote) => quote.quotation.id == selectedOutdatedQuote.id,
        ).dimensioning.id,
      ),
    )
    setOutdatedQuotesModalStep(2)
    setQuoteIdEditing(selectedOutdatedQuote.id)
  }

  const getDifferenceBetweenEquipementsValue = () =>
    outdatedQuotes
      ?.find((item) => item[0] == quoteIdEditing)?.[1]
      ?.resultValues.reduce(
        (acc, currentValue) => {
          acc.oldKitValue += Number(currentValue.from)
          acc.newKitValue += Number(currentValue.to)
          return acc
        },
        { oldKitValue: 0, newKitValue: 0 },
      )

  const handleChangeProduct = (item) => {
    setModalChangeProduct(true)

    setProductToChange(
      proposal?.quotes
        ?.find((quote) => quote.quotation.id == quoteIdEditing)
        ?.kit?.find((product) => product.id == item),
    )
  }

  const handleRemoveQuote = (quote) => {
    if (proposal.quotes.length === 1) {
      onErrorOpenToast(
        'Não é possível remover essa cotação, pois é a única da sua proposta!',
      )
    } else {
      API.delete(`/proposal/remove/quotation/${quote.id}`)
        .then(() => {
          dispatch(ProposalAction.RemoveQuoteRedux(quote.id))
        })
        .catch(() => {
          onErrorOpenToast('Não foi possível remover essa cotação.')
        })
    }
  }

  function handleUpdate() {
    const findQuotote = outdatedQuotes?.find(
      (item) => item[0] == quoteIdEditing,
    )?.[1]?.resultValues[0]?.product

    const quoteIsEditing = proposal?.quotes?.find(
      (quote) => quote.quotation.id == quoteIdEditing,
    )

    const quantity = quoteIsEditing?.kit?.find(
      (product) =>
        product.id ==
        outdatedQuotes?.find((item) => item[0] == quoteIdEditing)?.[1]
          ?.resultValues[0]?.product,
    )?.qtde

    handleChangeQuantity(quoteIdEditing, findQuotote, quantity)
  }

  const needChangeProduct =
    outdatedQuotes?.find((item) => item[0] == quoteIdEditing)?.[1]
      ?.resultVisible?.length !== 0

  const needUpdateValues =
    outdatedQuotes?.find((item) => item[0] == quoteIdEditing)?.[1]?.resultValues
      ?.length != 0 && !needChangeProduct

  return (
    <Modal
      className={classes.modalSm}
      open={outdatedQuotesModal}
      onClose={() => {
        if (proposal?.isAdmin) {
          setOutdatedQuotesModal(false)
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={outdatedQuotesModal} style={{ border: 'none' }}>
        {outdatedQuotesModalStep == 2 ? (
          <Paper
            style={{
              height: 'auto',
              width: 'auto',
              minWidth: !downSm && '75%',
            }}
          >
            <div
              onClick={() => setOutdatedQuotesModalStep(1)}
              style={{ display: 'flex', cursor: 'pointer' }}
            >
              <ArrowChevronBackIcon
                width="24px"
                color={palette.primary[300]}
                style={{ marginRight: '16px' }}
              />
              <Title size="xsmall" color="primary" colorWeight="300">
                Estes itens estão desatualizados!
              </Title>
            </div>
            <Spacer size="16" direction="both" />
            <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>
              {needUpdateValues && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography colorWeight="500" size="small">
                      Os seguintes itens tiveram uma mudança de valor:
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      isLoading={loadingAttProduct}
                      onClick={() => handleUpdate()}
                    >
                      Atualizar valores
                    </Button>
                  </div>
                  <Spacer size="16" direction="both" />
                  <Paper
                    style={{
                      height: 'auto',
                      width: 'auto',
                      marginBottom: '8px',
                      padding: '12px',
                      display: 'flex',
                    }}
                  >
                    <Grid spacing={2} container>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography
                          size="xsmall"
                          style={{ fontWeight: 'bold' }}
                        >
                          Atualização do valor total da proposta
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography
                          size="xsmall"
                          color="grayscale"
                          colorWeight="300"
                        >
                          Valor Atual
                        </Typography>
                        <Typography
                          size="xsmall"
                          color="black"
                          style={{ fontWeight: 'bold' }}
                        >
                          {getDifferenceBetweenEquipementsValue()?.newKitValue?.toLocaleString(
                            'pt-br',
                            { style: 'currency', currency: 'BRL' },
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Typography
                          size="xsmall"
                          color="grayscale"
                          colorWeight="300"
                        >
                          Valor Anterior
                        </Typography>
                        <Typography
                          size="xsmall"
                          color="grayscale"
                          style={{ fontWeight: 'bold' }}
                        >
                          {getDifferenceBetweenEquipementsValue()?.oldKitValue?.toLocaleString(
                            'pt-br',
                            { style: 'currency', currency: 'BRL' },
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography
                          size="xsmall"
                          color="grayscale"
                          colorWeight="300"
                        >
                          Diferença
                        </Typography>
                        <Typography
                          size="xsmall"
                          style={{
                            fontWeight: 'bold',
                            color:
                              getDifferenceBetweenEquipementsValue()
                                ?.newKitValue /
                                getDifferenceBetweenEquipementsValue()
                                  ?.oldKitValue -
                                1 <
                              0
                                ? '#00cc67'
                                : '#ff0000',
                          }}
                        >
                          {getDifferenceBetweenEquipementsValue()?.newKitValue /
                            getDifferenceBetweenEquipementsValue()
                              ?.oldKitValue -
                            1 <
                          0
                            ? ''
                            : '+'}
                          {(
                            (getDifferenceBetweenEquipementsValue()
                              ?.newKitValue /
                              getDifferenceBetweenEquipementsValue()
                                ?.oldKitValue -
                              1) *
                            100
                          ).toFixed(2)}
                          %
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                  {outdatedQuotes
                    ?.find((item) => item[0] == quoteIdEditing)?.[1]
                    ?.resultValues.map((item, i) => (
                      <Paper
                        key={i}
                        style={{
                          height: 'auto',
                          width: 'auto',
                          border: `1px solid ${palette.grayscale[300]}`,
                          marginBottom: '8px',
                          padding: '12px',
                          display: 'flex',
                        }}
                      >
                        <Grid spacing={2} container>
                          <Grid item xs={12} md={9}>
                            <Typography
                              size="xsmall"
                              color="grayscale"
                              colorWeight="300"
                            >
                              Produto
                            </Typography>
                            <Typography size="xsmall">{item.name}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography
                              size="xsmall"
                              color="grayscale"
                              colorWeight="300"
                            >
                              Diferença
                            </Typography>
                            <Typography
                              color={
                                Number(item?.to) / Number(item?.from) - 1 < 0
                                  ? 'green'
                                  : 'red'
                              }
                              colorWeight="300"
                              size="xsmall"
                            >
                              {Number(item?.to) / Number(item?.from) - 1 < 0
                                ? ''
                                : '+'}
                              {(
                                (Number(item?.to) / Number(item?.from) - 1) *
                                100
                              ).toFixed(2)}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                </div>
              )}
              {outdatedQuotes?.find((item) => item[0] == quoteIdEditing)?.[1]
                ?.resultVisible?.length != 0 && (
                <div>
                  <Spacer size="16" direction="both" />
                  <Typography colorWeight="500" size="small">
                    Os seguintes itens não estão mais disponíveis:
                  </Typography>
                  <Spacer size="16" direction="both" />
                  {outdatedQuotes
                    ?.find((item) => item[0] == quoteIdEditing)?.[1]
                    ?.resultVisible?.map((item, i) => (
                      <Paper
                        key={i}
                        style={{
                          height: 'auto',
                          width: 'auto',
                          border: `1px solid ${palette.grayscale[300]}`,
                          marginBottom: '8px',
                          padding: '12px',
                          display: !downSm && 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography size="small">
                          {
                            proposal?.quotes
                              ?.find(
                                (quote) => quote.quotation.id == quoteIdEditing,
                              )
                              ?.kit?.find((product) => product.id == item)
                              ?.titulo
                          }
                        </Typography>
                        <div style={{ display: !downSm && 'flex' }}>
                          <Spacer
                            size={downSm ? '8' : '48'}
                            direction={downSm ? 'horizontal' : 'vertical'}
                          />
                          <Button
                            size="small"
                            variant="outlined"
                            fullWidth={downSm}
                            onClick={() => handleChangeProduct(item)}
                          >
                            Alterar produto
                          </Button>
                        </div>
                      </Paper>
                    ))}
                </div>
              )}
            </div>
          </Paper>
        ) : (
          <Paper style={{ height: 'auto', width: 'auto' }}>
            <QuotationContainer
              quotations={proposal?.quotes?.filter((item) =>
                outdatedQuotes?.find(
                  (quote) => quote[0] == item?.quotation?.id,
                ),
              )}
              onChange={(e) => setSelectedOutdatedQuote(e)}
              TitleIcon={CircleActionsAlertInfoIcon}
              title="As seguintes cotações estão com itens desatualizados"
              subtitle="Selecione a cotação que deseja alterar."
            />
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              flexDirection={downSm ? 'column' : 'row'}
            >
              <Box display="flex" flexDirection={downSm ? 'column' : 'row'}>
                <Button
                  size="small"
                  disabled={isEmpty(selectedOutdatedQuote)}
                  onClick={() => {
                    dispatch(
                      ProposalAction.SaveActiveSizingRedux(
                        proposal.quotes.find(
                          (quote) =>
                            quote.quotation.id == selectedOutdatedQuote.id,
                        ).dimensioning.id,
                      ),
                    )
                    setOutdatedQuotesModalStep(2)
                    setQuoteIdEditing(selectedOutdatedQuote.id)
                  }}
                >
                  Atualizar esta cotação
                </Button>
                <Spacer size="16" direction="both" />
                <Button
                  variant="outlined"
                  size="small"
                  disabled={
                    proposal?.quotes?.length == 1 ||
                    isEmpty(selectedOutdatedQuote)
                  }
                  color="red"
                  onClick={() => {
                    handleRemoveQuote(selectedOutdatedQuote)
                  }}
                >
                  Deletar
                </Button>
              </Box>
              <Box mt={downSm && 2}>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  color="red"
                  onClick={() => window.history.back()}
                >
                  Voltar
                </Button>
              </Box>
            </Box>
          </Paper>
        )}
      </Fade>
    </Modal>
  )
}
