import { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useFinancingSimulations } from '../components/FinancingSimulations/hooks/useFinancingSimulations'
import { useHistory } from 'react-router-dom'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import {
  FINANCING_TYPE,
  FINANCING_TYPE_ENUM,
  FINANCING_TYPE_SLUG,
} from '../components/Tabs/constants'

export function useFinancingContainer({
  defaultTab,
  financingRequestId,
  simulationId,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const slideStyle = {
    width: downSm ? '100%' : '50%',
    minWidth: '40%',
    position: downSm ? 'absolute' : 'initial',
  }

  const isValidTab = (tabSlug) => {
    return FINANCING_TYPE_SLUG[tabSlug]
  }

  const { VALOR } = FINANCING_TYPE_ENUM

  const [currentTabFinancings, setCurrentTabFinancings] = useState(
    isValidTab(defaultTab) || VALOR,
  )

  useEffect(() => {
    if (defaultTab) {
      setCurrentTabFinancings(isValidTab(defaultTab))
    }
  }, [defaultTab, isValidTab])

  const history = useHistory()
  const { logEvent } = useAmplitude()

  const [selectedSimulation, setSelectedSimulation] = useState({})
  const [selectedFinancing, setSelectedFinancing] = useState({})
  const [openBuyModal, setOpenBuyModal] = useState({
    isOpen: false,
    proposalId: null,
  })

  const [isOpenSimulationDetails, setIsOpenSimulationDetails] = useState(false)

  const [isOpenSimulations, setIsOpenSimulations] = useState(false)

  const { handleInvalidateFinancingQueries, handleCancelFinancingQueries } =
    useFinancingSimulations({
      isFinancingPerValue: true,
      quotationId: selectedFinancing.rowData?.id_origin,
      selectedFinancingId: selectedFinancing.rowData?.financing_id,
    })

  const slideAndDetailOpen = isOpenSimulations && isOpenSimulationDetails

  const handleClearUrl = () => {
    history.push('/financiamentos')
  }

  const handleFinancialDetailClose = () => {
    if (financingRequestId) {
      handleClearUrl()
    }
    setSelectedFinancing({})
    setIsOpenSimulationDetails(false)
    setIsOpenSimulations(false)
  }

  const handleFinancingTabsClose = () => {
    setIsOpenSimulationDetails(false)
    if (simulationId) {
      handleClearUrl()
    }
    downSm && setIsOpenSimulations(true)
  }

  const onRowClick = async (row) => {
    handleCancelFinancingQueries()
    logEvent(financingTracker.actions.expandFinancing, {
      origin: '/financiamentos',
      financingType: FINANCING_TYPE[currentTabFinancings],
    })

    if (isOpenSimulationDetails && !simulationId) {
      handleFinancingTabsClose()
    }

    setSelectedFinancing(row)

    setIsOpenSimulations(true)
  }

  const handleIconClick = async (type, row) => {
    if (type === 'view') {
      onRowClick(row)
    }
    if (type === 'buy') {
      setOpenBuyModal({
        isOpen: true,
        proposalId: row?.rowData?.proposal_id,
      })
    }
  }

  const handleCloseModal = () => {
    setOpenBuyModal({ isOpen: false, proposalId: null })
  }

  function onSelectSimulation(value) {
    setSelectedSimulation(value)
    setIsOpenSimulationDetails(true)
  }

  return {
    downSm,
    slideStyle,
    isFinancingPerValue: true,
    handleIconClick,
    onSelectSimulation,
    handleCloseModal,
    handleInvalidateFinancingQueries,
    selectedSimulation,
    openBuyModal,
    slideAndDetailOpen,
    isOpenSimulations,
    isOpenSimulationDetails,
    onRowClick,
    selectedFinancing,
    handleFinancialDetailClose,
    handleFinancingTabsClose,
    setSelectedSimulation,
    currentTabFinancings,
  }
}
