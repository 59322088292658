import styled from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: blue;
    width: 32px;
    margin-right: 12px;
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 590px) {
    flex-direction: column;

    svg {
      margin-right: 0;
    }

    h4 {
      margin-top: 8px;
      text-align: center;
    }
  }
`
