import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  radio: ({ color }) => ({
    '&.MuiCheckbox-root': {},
    '& .MuiSvgIcon-root': {
      width: '32px',
      height: '32px',
    },
    '& .Mui-disabled': {
      opacity: 0.4,
    },
    '&.MuiRadio-colorPrimary.Mui-checked': {
      color: palette[color][300],
    },
    '&.PrivateRadioButtonIcon': {
      width: '32px',
      height: '32px',
    },
  }),
  icon: ({ color }) => ({
    borderRadius: '50%',
    width: 32,
    height: 32,
    backgroundColor: palette.grayscale[300],
    transition: 'all 0.5s ease-out',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
      transition: 'all 0.5s ease-out',
    },
    'input:hover ~ &': {
      backgroundColor: palette.grayscale[400],
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      opacity: 0.4,
    },
  }),
  checkedIcon: ({ color }) => ({
    backgroundColor: palette[color][300],
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    transition: 'all 0.5s ease-out',
    '&:before': {
      display: 'block',
      width: 32,
      height: 32,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      transition: 'all 0.5s ease-out',
    },
    'input:hover ~ &': {
      backgroundColor: palette[color][400],
    },
  }),
}))

export default styles
