import * as S from './styles'
import { ModalWrapper } from 'components'
import { Filters } from './components/Filters'
import { Header } from './components/Header'
import { List } from './components/List'
import { useListProduct } from './hooks/useListProduct'
import { useChangeProduct } from './hooks/useChangeProduct'
import { useProductFilter } from './hooks/useProductFilter'
import { BREAKPOINT_MOBILE } from './constants/breakpointMobile'
import useWindowSize from 'hooks/useWindowSize'

export default function ModalAlterarProduto({
  isOpen,
  onClose,
  product,
  quotationId,
  onChangeProduct,
}) {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width <= BREAKPOINT_MOBILE

  const { filters, filtersIsVisible, handleApplyFilter, handleDisplayFilters } =
    useProductFilter()

  const {
    productsList,
    hasMoreProduct,
    isLoadingProduct,
    handleNextPage,
    handleResetPage,
  } = useListProduct({ product, filters, quotationId })

  const { handleChangeProduct } = useChangeProduct({
    product,
    quotationId,
    onChangeProduct,
  })

  function handleFilterProducts(filter, value) {
    handleResetPage()
    handleApplyFilter(filter, value)
  }

  return (
    <ModalWrapper id="modal-change-equipment" isOpen={isOpen} onClose={onClose}>
      <S.Container data-filters-visible={filtersIsVisible}>
        <div className="header">
          <Header
            product={product}
            isMobile={isMobile}
            onCancel={onClose}
            openFilters={filtersIsVisible}
            onFilter={handleDisplayFilters}
          />
        </div>
        <div className="filter">
          <Filters
            product={product}
            isMobile={isMobile}
            onFilter={handleFilterProducts}
            onApply={handleDisplayFilters}
          />
        </div>
        <div className="list">
          <List
            products={productsList}
            isLoading={isLoadingProduct}
            hasMoreProducts={hasMoreProduct}
            onNextPage={handleNextPage}
            onChangeProduct={handleChangeProduct}
          />
        </div>
      </S.Container>
    </ModalWrapper>
  )
}
