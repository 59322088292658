import moment from 'moment'

const isNullOrUndefined = value => value === undefined || value === null

const sortDate = (a, b) => moment(a).valueOf() - moment(b).valueOf()

const isToday = date => moment().isSame(moment(date), 'day')

const isSameDay = ({ day, date }) =>
  moment()
    .day(day)
    .isSame(moment(date), 'day')

const isSameMonth = ({ month, date }) =>
  moment()
    .month(month)
    .isSame(moment(date), 'month')

const isSameYear = ({ year, date }) =>
  moment()
    .year(year)
    .isSame(moment(date), 'year')

const isSameYearAndMonth = ({ date, month, year }) =>
  isSameMonth({ month, date }) && isSameYear({ year, date })

const isSameDayMonthAndYear = ({ date, day, month, year }) =>
  isSameDay({ day, date }) && isSameYearAndMonth({ date, month, year })

const isSameDate = ({ currentDate, dateToCompare }) => {
  if (isNullOrUndefined(currentDate) || isNullOrUndefined(dateToCompare)) {
    return false
  }

  return moment(currentDate).isSame(moment(dateToCompare), 'day')
}

const formatDate = (date = '') => {
  return moment(date).isValid() ? moment(date).format('L') : date
}

const formatDateAndHour = date => moment(date).format('DD/MM/YYYY - HH:mm')

export {
  isToday,
  isSameDay,
  isSameMonth,
  isSameYear,
  isSameDate,
  isSameYearAndMonth,
  isSameDayMonthAndYear,
  sortDate,
  formatDate,
  formatDateAndHour,
}
