import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@77sol-ui/molecules'
import { Grid, useMediaQuery, Box, Link } from '@material-ui/core'
import {
  Button,
  Spacer,
  Typography,
  Input,
  Dropdown,
  Checkbox,
  useToast,
} from '@77sol/core'
import { useDispatch, useSelector } from 'react-redux'
import API from 'api'
import {
  setUserProfile,
  setUserProfileCorporateName,
} from 'store/actions/profile'
import { setUserLogged } from 'store/actions/auth'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { ufList } from 'utils/constants'
import { useBlacklist } from 'hooks/useBlacklist'
import { useStyles } from './styles'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'

function ModalCompleteUserData({ userInformation, titleHeader }) {
  const dispatch = useDispatch()

  const { isOpenCompleteRegistrationModal, closeModalCompleteUserData } =
    useUserHasFullRegistration()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const classes = useStyles()
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)

  const [dataNomeInitial, setDataNomeInitial] = useState('')
  const [dataCpfResponsavelInitial, setDataCpfResponsavelInitial] = useState('')

  const [dataNome, setDataNome] = useState('')
  const [dataCpfResponsavel, setDataCpfResponsavel] = useState('')

  const [dataTerms, setDataTerms] = useState({
    checked: false,
    disabled: false,
  })

  useEffect(() => {
    const { nome, cpfResponsavel } = userLogged[0]

    setDataNomeInitial(nome)
    setDataCpfResponsavelInitial(cpfResponsavel)

    setDataNome(nome || '')
    setDataCpfResponsavel(cpfResponsavel || '')

    onErrorOpenToast('Complete o seu cadastro para continuar!')
  }, [])

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const [isLoadingCep, setIsLoadingCep] = useState(false)
  const [isLoadingCnpj, setIsLoadingCnpj] = useState(false)
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const userId = localStorage.getItem('sessionDataId')

  const { userProfile } = useSelector((state) => state.ReducerProfile)
  const { consultBlacklist, existInBlacklist, validBlacklist } = useBlacklist()

  const [jsonSintegra, setJsonSintegra] = useState({})
  const [dataTelefone, setDataTelefone] = useState('')
  const [dataCelular, setDataCelular] = useState('')
  const [dataCPNJ, setDataCNPJ] = useState('')
  const [dataNomeFantasia, setDataNomeFantasia] = useState('')
  const [dataRazaoSocial, setDataRazaoSocial] = useState('')
  const [dataCEP, setDataCEP] = useState('')
  const [dataEndereco, setDataEndereco] = useState('')
  const [dataNumero, setDataNumero] = useState('')
  const [dataComplemento, setDataComplemento] = useState('')
  const [dataBairro, setDataBairro] = useState('')
  const [dataCidade, setDataCidade] = useState('')
  const [dataEstado, setDataEstado] = useState('')
  const [hasFinishedLoading, setHasFinishedLoading] = useState(false)
  const [hasInputErrors, setHasInputErrors] = useState(true)
  const [dataEmail, setDataEmail] = useState('')

  const disableSaveButton =
    hasInputErrors || isLoadingSave || isLoadingCep || isLoadingCnpj

  const updateUser = async (e) => {
    e.preventDefault()

    if (validBlacklist) {
      setIsLoadingSave(true)

      try {
        if (
          (dataCpfResponsavel &&
            userInformation &&
            dataNome !== dataNomeInitial) ||
          dataCpfResponsavel !== dataCpfResponsavelInitial
        ) {
          await API.patch('/user/update-main-info', {
            name: dataNome,
            cpf: dataCpfResponsavel,
          }).then(() => {
            setDataCpfResponsavel(dataCpfResponsavel)
            localStorage.setItem(
              'sessionDataCpfResponsavel',
              dataCpfResponsavel,
            )
          })
        }

        await API.patch(`/user/update/${userId}`, {
          razaoSocial: dataRazaoSocial,
          nomeFantasia: dataNomeFantasia,
          cnpj: dataCPNJ,
          telefone: dataTelefone,
          celular: dataCelular,
          email: dataEmail,
          cep: dataCEP,
          endereco: dataEndereco,
          complemento: dataComplemento,
          numero: dataNumero,
          bairro: dataBairro,
          cidade: dataCidade,
          uf: dataEstado,
          json_sintegra: jsonSintegra,
          ...(currentEnterpriseConfigs.termsUse && {
            accept_terms: dataTerms.checked ? 1 : 0,
          }),
        })

        await API.get('/auth/me').then(({ data }) => {
          dispatch(setUserLogged(data))
        })

        await API.get(`/user/view/${userId}`).then(({ data }) => {
          dispatch(setUserProfile(data[0]))
          dispatch(setUserProfileCorporateName(data[1]))
        })

        localStorage.setItem('sessionDataRazaoSocial', dataRazaoSocial)
        localStorage.setItem('sessionDataNomeFantasia', dataNomeFantasia)
        localStorage.setItem('sessionDataCNPJ', dataCPNJ)
        localStorage.setItem('sessionDataTelefone', dataTelefone)
        localStorage.setItem('sessionDataCelular', dataCelular)
        localStorage.setItem('sessionDataEmail', dataEmail)
        localStorage.setItem('sessionDataCEP', dataCEP)

        onSuccessOpenToast('Dados alterados com sucesso!')
        closeModalCompleteUserData()
      } catch {
        onErrorOpenToast('Erro ao alterar dados!')
      } finally {
        setIsLoadingSave(false)
      }
    }
  }

  useEffect(() => {
    setDataTelefone(userProfile.telefone || '')
    setDataCelular(userProfile.celular || '')
    setDataCNPJ(userProfile.cnpj || '')
    setDataRazaoSocial(userProfile.razaoSocial || '')
    setDataNomeFantasia(userProfile.nomeFantasia || '')
    setDataCEP(userProfile.cep || '')
    setDataEmail(userProfile.email || '')
    setDataEndereco(userProfile.endereco || '')
    setDataComplemento(userProfile.complemento || '')
    setDataNumero(userProfile.numero || '')
    setDataBairro(userProfile.bairro || '')
    setDataCidade(userProfile.cidade || '')
    setDataEstado(userProfile.uf || '')
    setJsonSintegra(
      userProfile.json_sintegra && JSON.parse(userProfile.json_sintegra),
    )
    if (currentEnterpriseConfigs.termsUse) {
      setDataTerms({
        checked: userProfile.accept_terms,
        disabled: false,
      })
    }
    setHasFinishedLoading(true)
  }, [userProfile])

  const handleSearchCep = (cep) => {
    if (cep.length === 8) {
      setIsLoadingCep(true)

      API.get('/showcep', {
        params: { cep },
      })
        .then((res) => {
          setDataEndereco(res.data.logradouro || '')
          setDataNumero('')
          setDataComplemento('')
          setDataBairro(res.data.bairro || '')
          setDataCidade(res.data.cidade || '')
          setDataEstado(res.data.uf || '')
        })
        .finally(() => {
          setIsLoadingCep(false)
        })
    }
  }

  const handleSearchCnpj = async (document) => {
    if (document.length === 14) {
      setIsLoadingCnpj(true)

      const fieldBlocked = await consultBlacklist('cnpj', document)
      if (fieldBlocked) {
        setIsLoadingCnpj(false)
        return
      }

      API.post('/document', { document })
        .then(({ data }) => {
          setDataRazaoSocial(data.nome_empresarial || '')
          setDataNomeFantasia(
            data.nome_fantasia === 'Não informado' ? '' : data.nome_fantasia,
          )
          setDataCEP(data.cep || '')
          setDataEndereco(data.logradouro || '')
          setDataComplemento(data.complemento || '')
          setDataNumero(data.numero || '')
          setDataBairro(data.bairro || '')
          setDataCidade(data.municipio || '')
          setDataEstado(data.uf || '')
          setJsonSintegra(data)
        })
        .finally(() => setIsLoadingCnpj(false))
        .catch(() => setJsonSintegra(null))
    }
  }

  useEffect(() => {
    if (userInformation) {
      if (
        !validBlacklist ||
        dataNome === '' ||
        dataCpfResponsavel?.replace(/[^+\d]+/g, '').length < 11 ||
        dataRazaoSocial === '' ||
        dataCPNJ === '' ||
        dataBairro === '' ||
        dataCelular === '' ||
        dataCEP === '' ||
        dataEstado === '' ||
        dataEstado === '' ||
        dataEndereco === '' ||
        dataTelefone === '' ||
        dataNomeFantasia === '' ||
        dataNumero === '' ||
        (currentEnterpriseConfigs.termsUse && !dataTerms.checked) ||
        dataCEP?.replace(/[^+\d]+/g, '').length < 8 ||
        dataCelular?.replace(/[^+\d]+/g, '').length < 11 ||
        dataTelefone?.replace(/[^+\d]+/g, '').length < 10 ||
        dataCPNJ?.replace(/[^+\d]+/g, '').length < 14
      ) {
        setHasInputErrors(true)
      } else {
        setHasInputErrors(false)
      }
    } else if (
      !validBlacklist ||
      dataRazaoSocial === '' ||
      dataCPNJ === '' ||
      dataBairro === '' ||
      dataCelular === '' ||
      dataCEP === '' ||
      dataEstado === '' ||
      dataEstado === '' ||
      dataEndereco === '' ||
      dataTelefone === '' ||
      dataNomeFantasia === '' ||
      dataNumero === '' ||
      (currentEnterpriseConfigs.termsUse && !dataTerms?.checked) ||
      dataCEP?.replace(/[^+\d]+/g, '').length < 8 ||
      dataCelular?.replace(/[^+\d]+/g, '').length < 11 ||
      dataTelefone?.replace(/[^+\d]+/g, '').length < 10 ||
      dataCPNJ?.replace(/[^+\d]+/g, '').length < 14
    ) {
      setHasInputErrors(true)
    } else {
      setHasInputErrors(false)
    }
  }, [
    validBlacklist,
    dataNome,
    dataCpfResponsavel,
    dataRazaoSocial,
    dataCPNJ,
    dataBairro,
    dataCelular,
    dataCEP,
    dataCidade,
    dataEstado,
    dataEndereco,
    dataTelefone,
    dataNomeFantasia,
    dataNumero,
    dataTerms,
  ])

  return (
    <Modal.Root
      open={isOpenCompleteRegistrationModal}
      onOpenChange={closeModalCompleteUserData}
    >
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper maxHeight="90vh" maxWidth="1100px">
          <Modal.Content>
            <form onSubmit={updateUser}>
              <Typography size="large">{titleHeader}</Typography>
              {userInformation && (
                <div>
                  <Grid className={classes.container} container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Input
                        fullWidth
                        title="Nome Completo"
                        name="nome"
                        onChange={(e) => setDataNome(e)}
                        value={dataNome}
                        InputLabelProps={{ shrink: !!dataNome }}
                        validationState={dataNome === '' && 'invalid'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Input
                        autoTab
                        mask="cpf"
                        title="CPF"
                        value={dataCpfResponsavel}
                        onChange={(e) => setDataCpfResponsavel(e)}
                        onBlur={(value) => consultBlacklist('cpf', value)}
                        validationState={
                          (existInBlacklist('cpf') ||
                            dataCpfResponsavel.length < 11) &&
                          'invalid'
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              <Grid className={classes.container} container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Input
                    disabled
                    fullWidth
                    title="E-mail"
                    name="email"
                    value={dataEmail}
                    onChange={setDataEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Input
                    autoTab
                    mask="cnpj"
                    title="CNPJ"
                    name="cnpj"
                    onChange={(e) => setDataCNPJ(e)}
                    onBlur={handleSearchCnpj}
                    value={dataCPNJ}
                    isLoading={isLoadingCnpj}
                    InputLabelProps={{
                      shrink: !!dataCPNJ,
                    }}
                    validationState={
                      (existInBlacklist('cnpj') ||
                        (hasFinishedLoading && dataCPNJ === '') ||
                        (hasFinishedLoading &&
                          dataCPNJ?.replaceAll('.', '')?.replace('/', '')
                            .length < 14)) &&
                      'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Input
                    autoTab
                    mask="phoneNumber"
                    onChange={(e) => setDataTelefone(e)}
                    value={dataTelefone}
                    InputLabelProps={{
                      shrink: !!dataTelefone,
                    }}
                    fullWidth
                    title="Telefone"
                    name="telefone"
                    validationState={
                      ((hasFinishedLoading && dataTelefone === '') ||
                        (hasFinishedLoading &&
                          dataTelefone?.replace(/[^+\d]+/g, '').length < 10)) &&
                      'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Input
                    autoTab
                    mask="phoneNumber"
                    onChange={(e) => setDataCelular(e)}
                    onBlur={(value) => consultBlacklist('mobile', value)}
                    value={dataCelular}
                    fullWidth
                    title="Celular"
                    name="celular"
                    InputLabelProps={{
                      shrink: !!dataCelular,
                    }}
                    validationState={
                      (existInBlacklist('mobile') ||
                        (hasFinishedLoading && dataCelular === '') ||
                        (hasFinishedLoading &&
                          dataCelular?.replace(/[^+\d]+/g, '').length < 11)) &&
                      'invalid'
                    }
                  />
                </Grid>
                <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                  <Input
                    fullWidth
                    title="Razão Social"
                    name="razaoSocial"
                    onChange={(e) => setDataRazaoSocial(e)}
                    value={dataRazaoSocial}
                    InputLabelProps={{
                      shrink: !!dataRazaoSocial,
                    }}
                    validationState={
                      hasFinishedLoading &&
                      (dataRazaoSocial === '' || dataRazaoSocial?.length < 5) &&
                      'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Input
                    fullWidth
                    title="Nome Fantasia"
                    name="nomeFantasia"
                    InputLabelProps={{
                      shrink: !!dataNomeFantasia,
                    }}
                    onChange={(e) => setDataNomeFantasia(e)}
                    value={dataNomeFantasia}
                    validationState={
                      hasFinishedLoading &&
                      (dataNomeFantasia === '' ||
                        dataNomeFantasia?.length < 5) &&
                      'invalid'
                    }
                  />
                </Grid>
                {upXl ? (
                  <Grid item xs={12} sm={12} md={7} lg={7} xl={3}>
                    <Input
                      fullWidth
                      title="Endereço"
                      name="endereco"
                      InputLabelProps={{
                        shrink: !!dataEndereco,
                      }}
                      onChange={(e) => setDataEndereco(e)}
                      value={dataEndereco}
                      validationState={
                        hasFinishedLoading &&
                        (dataEndereco === '' || dataEndereco?.length < 5) &&
                        'invalid'
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Input
                      autoTab
                      mask="cep"
                      onChange={(e) => setDataCEP(e)}
                      value={dataCEP}
                      onBlur={handleSearchCep}
                      fullWidth
                      title="CEP"
                      name="cep"
                      isLoading={isLoadingCep}
                      InputLabelProps={{
                        shrink: !!dataCEP,
                      }}
                      validationState={
                        ((hasFinishedLoading && dataCEP === '') ||
                          (hasFinishedLoading &&
                            dataCEP?.replace(/[^+\d]+/g, '').length < 8)) &&
                        'invalid'
                      }
                    />
                  </Grid>
                )}
                {!upXl ? (
                  <Grid item xs={12} sm={12} md={7} lg={7} xl={3}>
                    <Input
                      fullWidth
                      title="Endereço"
                      name="endereco"
                      InputLabelProps={{
                        shrink: !!dataEndereco,
                      }}
                      onChange={(e) => setDataEndereco(e)}
                      value={dataEndereco}
                      validationState={
                        hasFinishedLoading &&
                        (dataEndereco === '' || dataEndereco?.length < 3) &&
                        'invalid'
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Input
                      autoTab
                      mask="cep"
                      onChange={(e) => setDataCEP(e)}
                      value={dataCEP}
                      onBlur={handleSearchCep}
                      fullWidth
                      title="CEP"
                      name="cep"
                      InputLabelProps={{
                        shrink: !!dataCEP,
                      }}
                      validationState={
                        ((hasFinishedLoading && dataCEP === '') ||
                          (hasFinishedLoading &&
                            dataCEP?.replace(/[^+\d]+/g, '').length < 8)) &&
                        'invalid'
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2} xl={3}>
                  <Input
                    onChange={(e) => setDataNumero(e)}
                    value={dataNumero}
                    maskChar=""
                    fullWidth
                    title="Número"
                    name="numero"
                    InputLabelProps={{
                      shrink: !!dataNumero,
                    }}
                    validationState={
                      hasFinishedLoading && dataNumero === '' && 'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Input
                    fullWidth
                    title="Complemento"
                    name="complemento"
                    InputLabelProps={{
                      shrink: !!dataComplemento,
                    }}
                    onChange={(e) => setDataComplemento(e)}
                    value={dataComplemento}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Input
                    fullWidth
                    title="Bairro"
                    name="bairro"
                    InputLabelProps={{
                      shrink: !!dataBairro,
                    }}
                    onChange={(e) => setDataBairro(e)}
                    value={dataBairro}
                    validationState={
                      hasFinishedLoading && dataBairro === '' && 'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={4}>
                  <Input
                    fullWidth
                    title="Cidade"
                    name="cidade"
                    InputLabelProps={{
                      shrink: !!dataCidade,
                    }}
                    onChange={(e) => setDataCidade(e)}
                    value={dataCidade}
                    validationState={
                      hasFinishedLoading && dataCidade === '' && 'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={4}>
                  <Dropdown
                    fullWidth
                    title="UF"
                    value={dataEstado}
                    options={ufList}
                    onChange={(e) => {
                      setDataEstado(e)
                    }}
                    validationState={
                      hasFinishedLoading && dataEstado === '' && 'invalid'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={
                      currentEnterpriseConfigs.termsUse
                        ? 'space-between'
                        : 'flex-end'
                    }
                    {...(downSm && {
                      flexDirection: 'column',
                    })}
                  >
                    {currentEnterpriseConfigs.termsUse && (
                      <Box
                        flex="auto"
                        display="flex"
                        alignSelf="start"
                        margin={2}
                      >
                        <Checkbox
                          size="small"
                          checked={dataTerms.checked}
                          disabled={dataTerms.disabled}
                          onChange={() =>
                            dataTerms.disabled ||
                            setDataTerms((state) => ({
                              ...state,
                              checked: !dataTerms.checked,
                            }))
                          }
                        />
                        <Spacer direction="vertical" size="8" />
                        <Typography size="small">
                          Li e aceito os&nbsp;
                          <Link
                            color="primary"
                            target="_blank"
                            href={currentEnterpriseConfigs.terms_of_use}
                          >
                            termos de uso
                          </Link>
                        </Typography>
                      </Box>
                    )}
                    <Button
                      color="red"
                      size="small"
                      variant="outlined"
                      fullWidth={downSm}
                      onClick={closeModalCompleteUserData}
                      style={{
                        margin: 10,
                      }}
                    >
                      Fazer Depois
                    </Button>
                    <Button
                      type="submit"
                      size="small"
                      isLoading={isLoadingSave}
                      fullWidth={downSm}
                      disabled={disableSaveButton}
                      style={{
                        margin: 10,
                      }}
                    >
                      Salvar alterações
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Modal.Content>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}

ModalCompleteUserData.propTypes = {
  userInformation: PropTypes.bool,
  titleHeader: PropTypes.string,
}

ModalCompleteUserData.defaultProps = {
  userInformation: false,
  titleHeader: 'Complete o seu cadastro para continuar',
}

export default ModalCompleteUserData
