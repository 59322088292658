import { Button, useToast } from '@77sol/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as S from './styles'

export function SubmitFinancingDocuments({ selectedFinancing }) {
  const { onSuccessOpenToast } = useToast()

  const handleCopyLink = () => {
    onSuccessOpenToast('Link copiado!')
  }

  return (
    <S.Container>
      <Button
        size="small"
        onClick={() =>
          window.open(selectedFinancing.formalization_link, '__blank')
        }
        variant="outlined"
        color="primary"
      >
        Enviar documento
      </Button>
      <CopyToClipboard text={selectedFinancing.formalization_link}>
        <Button
          size="small"
          onClick={handleCopyLink}
          variant="outlined"
          color="primary"
        >
          Enviar link
        </Button>
      </CopyToClipboard>
    </S.Container>
  )
}
