import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useVerifyQuotationKit } from 'domains/quotation/kit/hooks/useVerifyQuotationKit'

interface IUseCheckQuotationKitProps {
  callbackKitHasNotChanged: (id: number) => void
}

export function useCheckQuotationKit({
  callbackKitHasNotChanged,
}: IUseCheckQuotationKitProps) {
  const setOpenModalContinueWithChanged = useEquipmentContainerStore(
    (state) => state.setOpenModalContinueWithChanged,
  )
  const setContinueQuotationWithChangesCallback = useEquipmentContainerStore(
    (state) => state.setContinueQuotationWithChangesCallback,
  )

  const { validateKit } = useVerifyQuotationKit()

  const validateQuotationKit = async (id: number) => {
    const { added, changed, excluded } = await validateKit(id)
    const hasChangedQuotationKit = added || changed || excluded

    if (hasChangedQuotationKit) {
      setOpenModalContinueWithChanged(true)
      setContinueQuotationWithChangesCallback(() => {
        callbackKitHasNotChanged(id)
      })
    } else {
      callbackKitHasNotChanged(id)
    }
  }

  return {
    validateQuotationKit,
  }
}
