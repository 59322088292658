import React from 'react'
import Popover from '@material-ui/core/Popover'
import { Paper, Typography } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Spacer } from '@77sol/core'
import { CloseXIcon } from '@77sol/icons/dist'
import palette from 'app_palette'

const useStyles = makeStyles({
  popover: {
    borderRadius: '8px',
    border: `1px solid ${palette.grayscale[300]}`,
  },
})

function ProposalPopover(props) {
  const classes = useStyles()
  const {
    anchorRef,
    value,
    setValue,
    title,
    anchorOrigin,
    transformOrigin,
    children,
    onClick,
  } = props

  return (
    <Popover
      open={value}
      anchorEl={anchorRef}
      onClose={() => setValue(false)}
      elevation={0}
      classes={{ paper: classes.popover }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClick={onClick}
    >
      <Paper id="popoverClass" style={{ padding: '8px 16px 24px 16px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography type="text_medium" color="grayscale" colorWeight="700">
            {title}
          </Typography>
          <Spacer size="16" direction="vertical" />
          <div
            style={{
              borderRight: `2px solid ${palette.grayscale[300]}`,
              height: '34px',
            }}
          />
          <Spacer size="16" direction="vertical" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Esconder">
              <CloseXIcon
                onClick={() => setValue(false)}
                width="24"
                color={palette.grayscale[700]}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        </div>
        {children}
      </Paper>
    </Popover>
  )
}

export default ProposalPopover
