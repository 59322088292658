import { type IChartData } from '@77sol-ui/molecules'
import yup from 'libs/yup'
import { type InferType } from 'yup'

const dataSchema = yup.object().shape({
  title: yup.string().required(),
  x_axis: yup
    .array()
    .of(yup.string().required())
    .required('O eixo x é obrigatório.'),
  y_axis: yup
    .array()
    .of(
      yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            values: yup
              .array()
              .of(yup.number().required())
              .required('os valores do eixo Y são obrigatórios.'),
            un: yup.string().required('A unidade é obrigatória.'),
          }),
        )
        .required(),
    )
    .required('O eixo Y é obrigatório.'),
})

export interface YAxis {
  key: string
  values: number[]
  un: string
}

export interface IFormattedDataToChartJs {
  labels: string[]
  data: IChartData[]
  units: string[]
  maxValues: Record<string, number>
}

type IProps = InferType<typeof dataSchema>

export interface IFormatDataToChartJsProps {
  lineStyles: Record<string, Omit<IChartData, 'data' | 'yAxisID'>>
  data?: IProps
}

function formatDataToChartJs({
  lineStyles,
  data,
}: IFormatDataToChartJsProps): IFormattedDataToChartJs {
  try {
    if (!data) {
      throw new Error('O data é obrigatório.')
    }
    dataSchema.validateSync(data)

    const lines: IChartData[] = []

    const units: string[] = []

    const maxValues: Record<string, number> = {}

    data.y_axis.forEach((i, index) => {
      const yAxisID = index === 0 ? 'y' : `y${index}`
      i.forEach((a) => {
        lines.push({
          ...lineStyles[a.key],
          data: a.values,
          yAxisID,
        })

        maxValues[yAxisID] = Math.max(...a.values) || 0

        units.push(a.un)
      })
    })
    return {
      labels: data.x_axis,
      data: lines,
      units,
      maxValues,
    }
  } catch (error) {
    return {
      labels: [],
      data: [],
      units: [],
      maxValues: {},
    }
  }
}

export { formatDataToChartJs }
