/* eslint-disable */ 
import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Typography } from 'components'
import { CloseXIcon, EditIcon, } from '@77sol/icons/dist'
import {
  PaymentFormsPaper, FinancingPaper, Paper, Spacer,
} from '@77sol/core'
import smoothscroll from 'smoothscroll-polyfill'
import palette from 'app_palette'
import DragIcon from '../../../../img/dragGrey.png'
import DragIconBlue from '../../../../img/dragBlue.png'
import { DragPaper, PagamentoEdit } from '../../..'
import API from '../../../../../../../../api'

window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

const useStyles = makeStyles({
  paperRendered: {
    width: '100%',
    padding: '24px',
  },
  paddingSection: {
    padding: '4px',
    position: 'relative',
  },
  dragging: {
    filter: 'blur(1.3px)',
  },
})

function DigitalPaymentsOptionsContainers({
  size,
  paymentOptionsContainers,
  setPaymentOptionsContainers,
  editMode,
  drag,
  internalEditing,
  editingMore,
  setEditingMore,
  setDrag,
  selectedQuote,
  setInternalEditing,
  proposal,
  colors,
}) {
  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const classes = useStyles()
  const tokenRegister = localStorage.getItem('sessionToken')

  const [paymentMethods, setPaymentsMethods] = useState([])
  const [billetNumber, setBilletNumber] = useState()

  const getPayments = useCallback(() => {
    API.get(`/proposal/quotation/payment_forms/${selectedQuote}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: tokenRegister,
      },
    })
      .then((res) => {
        const { methods } = res.data
        if (methods.length === 0) {
          setPaymentsMethods({ financing: [] })
        } else {
          if (methods.financing) {
            setPaymentsMethods({ ...methods })
          } else {
            methods.financing = []
            setPaymentsMethods({ ...methods })
          }
          if (!methods.billet) {
            setBilletNumber(0)
          }
          if (methods.billet.billet_one) {
            setBilletNumber(1)
          }
          if (methods.billet.billet_two) {
            setBilletNumber(2)
          }
          if (methods.billet.billet_three) {
            setBilletNumber(3)
          }
          if (methods.billet.billet_four) {
            setBilletNumber(4)
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (editingMore === '') {
      getPayments()
    }
    // eslint-disable-next-line
  }, [editingMore, selectedQuote, getPayments])

  return (
    <Droppable
      direction="horizontal"
      type="droppablePaymentOptionsContainers"
      droppableId="droppablePaymentOptionsContainers"
    >
      {(provided, snapshot) => (
        <Grid
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={{ height: '100%' }}
          container
        >
          {paymentOptionsContainers.map((container, index) => (
            <Draggable
              isDragDisabled={
                  editMode !== 'paymentsOptionsContainer'
                  || internalEditing !== container.id
                  || editingMore === container.id
                }
              key={container.id}
              draggableId={container.id}
              index={index}
            >
              {(provided, snapshot) => (
                <Grid
                  item
                  className={classes.paddingSection}
                  xs={12}
                  sm={12}
                  md={
                      paymentOptionsContainers.filter(
                        (item) => item.visible === true,
                      ).length === 1
                        ? 12
                        : 6
                    }
                  lg={
                      paymentOptionsContainers.filter(
                        (item) => item.visible === true,
                      ).length === 1
                        ? 12
                        : 6
                    }
                  xl={
                      paymentOptionsContainers.filter(
                        (item) => item.visible === true,
                      ).length === 1
                        ? 12
                        : container.breakpoint
                    }
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  onClick={(e) => {
                    if (editMode === 'paymentsOptionsContainer') {
                      internalEditing && internalEditing === container.id
                        ? setInternalEditing('')
                        : setInternalEditing(container.id)
                      setEditingMore('')
                    }
                  }}
                >
                  {container.visible && (
                  <>
                    {container.id === 'financingCard' && (
                    <FinancingPaper
                      documentType="cpf"
                      colors={colors}
                    />
                    )}
                    {container.id === 'paymentOptionsCard' && (
                    <PaymentFormsPaper
                      disabled
                      colors={colors}
                      paymentMethods={paymentMethods}
                      billetNumber={billetNumber}
                    />
                    )}
                    {snapshot.isDragging && <DragPaper />}
                    {editMode === 'paymentsOptionsContainer'
                          && internalEditing === container.id && (
                            <div
                              onClick={(e) => e.stopPropagation()}
                              style={{
                                position: 'absolute',
                                zIndex: 10,
                                top: '-15px',
                                left: index === 0 && '-15px',
                                right: index === 1 && '-15px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Paper
                                style={{
                                  padding: '16px 24px',
                                  boxShadow: 'none',
                                  cursor: 'auto',
                                  maxHeight: '50vh',
                                  width: 'auto',
                                  border: `1px solid ${palette.grayscale[300]}`,
                                  overflowY: 'auto',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <Typography
                                    type="link_small"
                                    color="grayscale"
                                    colorWeight="700"
                                  >
                                    {container.display}
                                  </Typography>
                                  <Spacer size="24" direction="vertical" />
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {editingMore === container.id ? (
                                      <Tooltip title="Fechar">
                                        <CloseXIcon
                                          width="24"
                                          onClick={() => (editingMore === container.id
                                            ? setEditingMore('')
                                            : setEditingMore(container.id))}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </Tooltip>
                                    ) : (
                                      <>
                                        {(container.id === 'proposalInfosCard'
                                          || container.id
                                            === 'paymentOptionsCard') && (
                                            <Tooltip title="Editar">
                                              <EditIcon
                                                onClick={() => (editingMore === container.id
                                                  ? setEditingMore('')
                                                  : setEditingMore(container.id))}
                                                style={{ cursor: 'pointer' }}
                                                width="24"
                                                color={palette.grayscale[700]}
                                              />
                                            </Tooltip>
                                        )}
                                        <Spacer
                                          size="12"
                                          direction="vertical"
                                        />
                                        <Tooltip title="Arrastar">
                                          <img
                                            onKeyDown={() => {}}
                                            alt="drag"
                                            onClick={() => (drag === container.id
                                              ? setDrag('')
                                              : setDrag(container.id))}
                                            src={
                                              internalEditing === container.id
                                                ? DragIconBlue
                                                : DragIcon
                                            }
                                            style={{
                                              width: '20px',
                                              cursor: 'pointer',
                                              color:
                                                drag === container.id
                                                  ? '#1335c6'
                                                  : 'rgb(112, 112, 114)',
                                            }}
                                          />
                                        </Tooltip>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {editingMore === 'paymentOptionsCard' && (
                                  <div
                                    style={{ minWidth: upXl ? '25vw' : '35vw' }}
                                  >
                                    <PagamentoEdit
                                      proposal={proposal}
                                      selectedQuoteEdit={selectedQuote}
                                      setMethodsQuoteEdit={() => {}}
                                    />
                                  </div>
                                )}
                              </Paper>
                            </div>
                    )}
                  </>
                  )}
                </Grid>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  )
}

export default DigitalPaymentsOptionsContainers
