import API from 'api'
import { GET_INTEGRATORS } from '../constants/endpointKeys'
import { type ISearchIntegratorGETResponse } from './types'

export const integrators = {
  get: async (search: string) => {
    if (!search) return
    return await API.get<ISearchIntegratorGETResponse>(
      `${GET_INTEGRATORS}?search=${search}`,
    ).then((response) => response.data)
  },
}
