import AllFieldsFilled from './components/AllFieldsFilled'
import FieldsWithError from './components/FieldsWithError'
import SomeFieldsFilled from './components/SomeFieldsFilled'

interface ModalSaveFormChangesProps {
  id?: string
  onClickActionButton: () => Promise<void>
  open?: boolean
  onCloseCustomOpen: (discard?: boolean) => void
  allFieldsFilled: boolean
  hasError: boolean
  isLoadingConfirmButton: boolean
  onClose?: () => void
}

export function ModalSaveFormChanges({
  open = true,
  onClose,
  isLoadingConfirmButton,
  allFieldsFilled,
  onClickActionButton,
  hasError,
  onCloseCustomOpen,
  ...props
}: ModalSaveFormChangesProps) {
  if (open && allFieldsFilled) {
    return (
      <AllFieldsFilled
        {...props}
        open={open}
        onCloseCustomOpen={onCloseCustomOpen}
        onClickActionButton={onClickActionButton}
        isLoadingConfirmButton={isLoadingConfirmButton}
        onClose={onClose}
      />
    )
  }

  if (open && !allFieldsFilled && !hasError) {
    return (
      <SomeFieldsFilled
        {...props}
        open={open}
        onCloseCustomOpen={onCloseCustomOpen}
        onClickActionButton={onClickActionButton}
        isLoadingConfirmButton={isLoadingConfirmButton}
        onClose={onClose}
      />
    )
  }

  return (
    <FieldsWithError
      {...props}
      open={open}
      onCloseCustomOpen={onCloseCustomOpen}
      onClose={onClose}
    />
  )
}
