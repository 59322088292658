import { HOME_ACTIVE_ROUTES } from '../constants'

const HOME_REGEX = /(\/+$)/

export function checkIfCurrentPageIsActive(path: string) {
  const isHome = HOME_REGEX.test(path)
  const isIncluded = location.pathname.includes(path)

  if (isHome) {
    return HOME_ACTIVE_ROUTES.includes(location.pathname)
  }

  return isIncluded
}
