import {
  CardIcon,
  RoomServiceHelpIcon,
  PasteIcon,
  TimeIcon,
  BoxIcon,
  TruckIcon,
  CheckIcon,
  CloseXIcon,
} from '@77sol/icons/dist'
import { type OptionType } from 'components/Timeline'
import { ORDER_STATUS_CLIENT_ENUM } from 'enums/order/OderStatusClientEnum'

const timeline: Record<ORDER_STATUS_CLIENT_ENUM, OptionType> = {
  '1': {
    Icon: PasteIcon,
    label: 'Pedido vencido',
  },
  '2': {
    Icon: RoomServiceHelpIcon,
    label: 'Pedido reservado',
  },
  '3': {
    Icon: TimeIcon,
    label: 'Análise de pagamento',
  },
  '4': {
    Icon: CardIcon,
    label: 'Pagamento confirmado',
  },
  '5': {
    Icon: BoxIcon,
    label: 'Expedição',
  },
  '10': {
    Icon: TruckIcon,
    label: 'Transporte',
  },
  '12': {
    Icon: CheckIcon,
    label: 'Entrega realizada',
  },
  '13': {
    Icon: CloseXIcon,
    label: 'Cancelado',
  },
}

const baseTimeline = [
  ORDER_STATUS_CLIENT_ENUM.RESERVADO,
  ORDER_STATUS_CLIENT_ENUM.EM_ANALISE,
  ORDER_STATUS_CLIENT_ENUM.PAGO,
  ORDER_STATUS_CLIENT_ENUM.EM_PREPARACAO,
  ORDER_STATUS_CLIENT_ENUM.EM_TRANSPORTE,
  ORDER_STATUS_CLIENT_ENUM.ENTREGUE,
]

const canceledOrderBaseTimeline = [
  ORDER_STATUS_CLIENT_ENUM.RESERVADO,
  ORDER_STATUS_CLIENT_ENUM.CANCELADO,
]

const timelineBaseOfExpiredOrder = [
  ORDER_STATUS_CLIENT_ENUM.RESERVADO,
  ORDER_STATUS_CLIENT_ENUM.PEDIDO_VENCIDO,
]

export {
  timeline,
  baseTimeline,
  timelineBaseOfExpiredOrder,
  canceledOrderBaseTimeline,
}
