import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette }) => ({
  resultPaper: ({ bordered }) => ({
    '& .MuiPaper-root': {
      height: '100%',
    },
  }),
  divPaper: {
    height: '100%',
  },
}))

export default styles
