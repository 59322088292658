import styled from 'styled-components'
import { palette } from '@77sol/styles'
import { type IEmptyStateProps } from './EmptyState.types'

export const Container = styled.div<
  Pick<IEmptyStateProps, 'action' | 'minHeight'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${({ minHeight }) => minHeight || '100%'};
  color: ${palette.grayscale[400]};

  svg {
    width: 48px;
  }

  span {
    font-size: 0.9rem;
    margin-top: 16px;
    margin-bottom: ${({ action }) => action && '32px'};
  }
`
