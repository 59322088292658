import { useToast } from '@77sol/core'
import API from 'api'
import { useState } from 'react'
import { OTHER_DOCUMENTS } from '../constants/otherDocuments'
import { CNPJ_DOCUMENTS } from '../constants/cnpjDocuments'
import { CPF_DOCUMENTS } from '../constants/cpfDocuments'
import { useQueryClient } from '@tanstack/react-query'
import { GET_FINANCING_DETAILS } from 'domains/financing/requisition/constants/enpointKeys'

export const useDocumentUpload = () => {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const queryClient = useQueryClient()
  const [otherDocumentList, setOtherDocumentList] = useState(OTHER_DOCUMENTS)
  const [documentList, setDocumentList] = useState([
    ...CNPJ_DOCUMENTS,
    ...CPF_DOCUMENTS,
  ])

  const handleUploadDocument = async (
    fileToSend,
    documentSelected,
    selectedFinancingId,
  ) => {
    const formData = new FormData()

    formData.append('file', fileToSend)
    formData.append('type', documentSelected.type)
    formData.append('requisition_id', selectedFinancingId)

    try {
      await API.post('/financing/new-file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      onSuccessOpenToast('Upload feito com sucesso.')

      documentSelected.documentType === 'other_documents'
        ? updateOtherDocumentStatus(documentSelected)
        : updateDocumentStatus(documentSelected)

      queryClient.invalidateQueries(GET_FINANCING_DETAILS)
    } catch (e) {
      onErrorOpenToast('Não foi possível fazer o upload!')
    }
  }

  const updateDocumentStatus = (documentSelected) => {
    const documentUpdated = { ...documentSelected, hasBeenSent: true }
    const updatedList = documentList.map((doc) =>
      checkDocument(doc, documentUpdated),
    )

    setDocumentList(updatedList)
  }

  const updateOtherDocumentStatus = (documentSelected) => {
    const documentUpdated = { ...documentSelected, hasBeenSent: true }

    const updatedList = otherDocumentList.map((doc) =>
      checkDocument(doc, documentUpdated),
    )

    setOtherDocumentList(updatedList)
  }

  const checkDocument = (document, documentUpdated) => {
    const titleIsSame = document.title === documentUpdated.title
    const typeIsSame = document.documentType === documentUpdated.documentType
    return titleIsSame && typeIsSame ? documentUpdated : document
  }

  return { handleUploadDocument, otherDocumentList, documentList }
}
