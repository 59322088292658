import { useEffect, useState } from 'react'
import { ModalLegislationWireB } from 'components'
import { Toggle } from '@77sol/core'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { TextField } from 'components/Controllers/TextField'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { useFormContext, useWatch } from 'react-hook-form'
import API from 'api'
import * as S from './styles'

export function ConcessionaireForm({
  setStatusPercentageFioB,
  statusPercentageFioB,
}) {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const fieldValues = useWatch({ control })

  const [concessionariaOptions, setConcessionariaOptions] = useState([])

  const cepInputed = watch('zipcode')
  const [isDisabledFields, setIsDisabledFields] = useState(true)

  useEffect(() => {
    if (String(cepInputed).length >= 8) {
      API.get('/cep', {
        params: { cep: cepInputed },
      }).then(({ data }) => {
        setConcessionariaOptions(data)

        setValue('dealership', '')
        setValue('dealership_tariff', '')
        setValue('percentage_fio_b', '30')
        setIsDisabledFields(false)
      })
    }
  }, [cepInputed])

  const [modalLegislationOpen, setModalLegislationOpen] = useState(false)

  const concessionairesOptions = [
    { label: 'Selecione uma concessionária', value: '' },
    ...concessionariaOptions.map(({ id: value, concessionaria: label }) => ({
      value,
      label,
    })),
  ]

  const handleConcessionaireChange = (id) => {
    setValue('dealership', id)

    const selectedConcessionaire = concessionariaOptions.find(
      ({ id: idConcessionaire }) => String(idConcessionaire) === id,
    )
    if (selectedConcessionaire) {
      setValue('dealership_tariff', selectedConcessionaire.tarifa.toFixed(2))
      setValue('percentage_fio_b', selectedConcessionaire.percentage_fio_b)
      clearErrors(['dealership_tariff'])
    } else {
      setValue('dealership_tariff', '')
      setValue('percentage_fio_b', '30')
    }
  }

  return (
    <div>
      <S.Container>
        <S.Label>Selecione a concessionária da região</S.Label>
        <DropdownControlled
          control={control}
          id="sizing_select_dealership"
          fullWidth
          label="Selecione uma concessionária"
          name="dealership"
          options={concessionairesOptions}
          value={fieldValues.dealership || ''}
          onChange={handleConcessionaireChange}
          error={errors.dealership?.message}
          disabled={isDisabledFields}
        />
      </S.Container>
      <S.Container>
        <S.Label>Tarifa paga pelo cliente</S.Label>
        <TextField
          id="sizing_select_dealershipFee"
          mask="real"
          label="Tarifa"
          endAdornment="R$/kWh"
          type="tel"
          name="dealership_tariff"
          control={control}
          error={errors.dealership_tariff?.message}
          disabled={isDisabledFields}
        />
      </S.Container>

      {currentEnterpriseConfigs?.tariffRole && (
        <>
          {modalLegislationOpen && (
            <ModalLegislationWireB
              data-testid="wireB-modal"
              open={modalLegislationOpen}
              onClose={() => setModalLegislationOpen(false)}
            />
          )}

          <S.Warning>
            <p>
              Aplicar no cálculo a tributação do fio B decorrente da Lei
              14300/22
            </p>
            <p>
              Confirme o percentual do fio B na TUSD da tarifa da concessionária
              selecionada,&nbsp;
              <span
                data-testid="wireB-link"
                onClick={() => setModalLegislationOpen(true)}
              >
                saiba mais
              </span>
              .
            </p>
            <Toggle
              data-testid="b-wire-toggle"
              checked={!!statusPercentageFioB}
              onChange={() => {
                setStatusPercentageFioB(!statusPercentageFioB)
              }}
            />
          </S.Warning>

          {statusPercentageFioB && (
            <S.Container>
              <TextField
                id="fio_b_value"
                label="Percentual Fio B"
                endAdornment="%"
                type="number"
                name="percentage_fio_b"
                control={control}
                error={errors.percentage_fio_b?.message}
                disabled={isDisabledFields}
              />
            </S.Container>
          )}
        </>
      )}
    </div>
  )
}
