import React, { useState, useEffect } from 'react'
import { CloseXIcon, CheckIcon } from '@77sol/icons/dist'
import { Box, Hidden, IconButton, Tooltip } from '@material-ui/core'
import { Button, Modal, Spacer, Typography, Title, Badge } from '@77sol/core'
import { useSelector } from 'react-redux'
import palette from 'app_palette'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { optionsDifferentialList } from 'utils/optionsDifferentialList'
import { useSettings } from 'hooks/useSettings'
import { Checkout } from './Checkout'
import styles from './styles'
import integratorMobileImage from '../../../assets/img/integratorMobile.png'
import { Modal77SolTeam } from './components/Modal77SolTeam'
import DifferentialList from './components/differentialList'

function ProModal({ isOpen, onClose, showForPayment }) {
  const classes = styles()
  const userLogged = useSelector((state) => state.ReducerAuth.userLogged)
  const userProfile = useSelector((state) => state.ReducerProfile.userProfile)
  const userIsProPlan = useSelector((state) => state.ReducerProfile.isProPlan)

  const [open, setOpen] = useState(false)
  const [nivelLogin, setNivelLogin] = useState(0)
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const { isProPlan } = useSettings()

  const handleClose = () => {
    onClose()
    setOpen(false)
    setIsCheckoutOpen(false)
    if (responseMessage === 'paid_card') {
      isProPlan()
    }
  }

  useEffect(() => {
    if (userLogged.regra) {
      if (userLogged.razaoSocialMaster) {
        setNivelLogin(3)
      } else {
        setNivelLogin(userLogged.regra)
      }
    } else if (userLogged.razaoSocialMaster) {
      setNivelLogin(3)
    } else {
      setNivelLogin(2)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      setOpen(true)
    }
  }, [isOpen])

  useEffect(() => {
    if (showForPayment) {
      setOpen(true)
      setIsCheckoutOpen(true)
    }
  }, [showForPayment])

  const [isSelection77Campaign, setIsSelection77Campaign] = useState(false)
  useEffect(() => {
    if (userProfile?.tags) {
      userProfile.tags.forEach((tag) => {
        if (tag.slug === 'selecao77') {
          setIsSelection77Campaign(true)
        }
      })
    }
  }, [userProfile])

  return (
    <>
      <Modal open={open} onClose={handleClose} noPadding noMargin>
        {userIsProPlan && (
          <Modal open={open} onClose={handleClose} noPadding noMargin>
            <div className={classes.isProContent}>
              <Box mb={2}>
                <CheckIcon width={50} color={palette.green[300]} />
              </Box>
              <Title size="xsmall">Parabéns, você já é PRO!</Title>
              <Typography size="small" color="grayscale" colorWeight="500">
                Clique no botão abaixo para voltar.
              </Typography>
              <Box mt={4}>
                <Button color="primary" onClick={handleClose}>
                  Fechar
                </Button>
              </Box>
            </div>
          </Modal>
        )}
        {isCheckoutOpen ? (
          <Checkout
            onBack={() => setIsCheckoutOpen(false)}
            onClose={handleClose}
            responseMessage={responseMessage}
            setResponseMessage={setResponseMessage}
            valueMonthlyPayment={!isSelection77Campaign ? 145.0 : 77.0}
          />
        ) : (
          <>
            {!isSelection77Campaign ? (
              <Box className={classes.container}>
                <Box className={classes.imageContent}>
                  <Box className={classes.imageBox}>
                    <Hidden lgUp>
                      <img
                        width="100%"
                        src={integratorMobileImage}
                        alt="Imagem de uma pessoa integradora"
                      />
                      <KeyboardArrowLeftIcon
                        onClick={() => {
                          onClose()
                          setOpen(false)
                          setIsCheckoutOpen(false)
                        }}
                        style={{
                          position: 'absolute',
                          left: '16px',
                          top: '16px',
                        }}
                        className={classes.icon}
                      />
                    </Hidden>
                    <Box className={classes.titleBox}>
                      <Hidden mdDown>
                        <Spacer size="8" direction="both" />
                        <Title
                          size="xsmall"
                          weight="xbold"
                          color="grayscale"
                          colorWeight="600"
                        >
                          Funções ainda mais inteligentes que profissionalizam o
                          seu negócio e te garante uma conversão cada vez maior!
                        </Title>
                        <Spacer size="8" direction="horizontal" />
                        <Badge label="77PRO" color="primary" />
                      </Hidden>
                      <Hidden lgUp>
                        <Typography size="xsmall" colorWeight="600">
                          Seja um integrador PRO, oferecemos os melhores
                          equipamentos, financiamentos e profissionais de
                          energia solar espalhados por todo Brasil.
                        </Typography>
                      </Hidden>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.tableContent}>
                  <Hidden lgUp>
                    <>
                      <Box display="flex" alignItems="baseline">
                        <Typography
                          type="link"
                          size="large"
                          color="primary"
                          colorWeight="300"
                        >
                          {parseFloat(
                            !isSelection77Campaign ? 145.0 : 77.0,
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </Typography>
                        <Typography
                          type="link"
                          size="small"
                          color="primary"
                          colorWeight="300"
                        >
                          /mês
                        </Typography>
                      </Box>
                      <Typography
                        type="link"
                        size="medium"
                        color="primary"
                        colorWeight="300"
                      >
                        Plano Pro
                      </Typography>
                      <Spacer size="8" direction="both" />
                      <hr
                        style={{
                          width: '100%',
                          height: '2px',
                          borderColor: palette.primary[300],
                          backgroundColor: palette.primary[300],
                        }}
                      />
                      <Spacer size="8" direction="both" />
                      {optionsDifferentialList.map(({ text }) => (
                        <Box display="flex" paddingTop={1} key={text}>
                          <CheckIcon
                            width="16px"
                            color={palette.primary[300]}
                          />
                          <Spacer size="8" direction="both" />
                          <Typography
                            size="xsmall"
                            color="primary"
                            colorWeight="300"
                          >
                            {text}
                          </Typography>
                        </Box>
                      ))}
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        paddingTop={2}
                      >
                        {nivelLogin < 3 && (
                          <>
                            <Button
                              fullWidth
                              onClick={() => setIsCheckoutOpen(true)}
                            >
                              Contratar plano
                            </Button>
                            <Spacer size="16" direction="both" />
                          </>
                        )}
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() =>
                            window.open(
                              currentEnterpriseConfigs.wantProUserContactUsWhatsapp,
                              '_blank',
                            )
                          }
                        >
                          Fale com nossa equipe
                        </Button>
                      </Box>
                    </>
                  </Hidden>
                  <Hidden mdDown>
                    <Tooltip
                      title="Fechar"
                      style={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                      }}
                    >
                      <IconButton onClick={handleClose}>
                        <CloseXIcon
                          width="24px"
                          color={palette.grayscale[800]}
                        />
                      </IconButton>
                    </Tooltip>
                    <Box style={{ paddingBottom: '16px' }} padding={3}>
                      <DifferentialList
                        valueMonthlyPayment={
                          !isSelection77Campaign ? 145.0 : 77.0
                        }
                        disabledSubtitle
                      />
                    </Box>
                    <div className={classes.buttonsContent}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          window.open(
                            currentEnterpriseConfigs.wantProUserContactUsWhatsapp,
                            '_blank',
                          )
                        }
                      >
                        Fale com nossa equipe
                      </Button>
                      {nivelLogin < 3 && !userProfile?.integrador_id && (
                        <>
                          <Spacer size="16" direction="both" />
                          <Button
                            fullWidth
                            onClick={() => setIsCheckoutOpen(true)}
                          >
                            Contratar plano por R$ 145,00
                          </Button>
                        </>
                      )}
                    </div>
                  </Hidden>
                </Box>
              </Box>
            ) : (
              <Modal77SolTeam
                className={classes.container}
                setIsCheckoutOpen={setIsCheckoutOpen}
                handleClose={handleClose}
              />
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export default ProModal
