export const quotationTracker = {
  actions: {
    confirm: 'Modal Cotação: Botão Confirmar',
    newQuotation: 'Adicionar nova cotação',
    cancelQuotation: 'Cancelar cotação',
    changeQuotationTabs: 'Tabs cotação rápida',
    addNewEquipment: 'Cotação: Adicionar novo equipamento',
    removeQuotation: 'Remover cotação',
    revertQuotationKit: 'Reverter KIT da cotação',
    changeEquipment: 'Cotação: Alterar Equipamento',
    deleteProduct: 'Cotação: Excluir produto do kit',
    exitCompareMode: 'Cotação: Sair do modo comparação',
    editQuotation: 'Cotação: Editar cotação',
    changeQuotationTab: 'Cotação: alterou cotação',
    clickedOnKit: 'Cotação: Clicou em um kit',
  },
}
