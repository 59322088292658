import React, { useState, useEffect, useRef } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Input, Typography } from 'components'
import { Spacer, Button, Paper, useToast } from '@77sol/core'
import { LockIcon, LockUnlockedIcon, EditIcon } from '@77sol/icons/dist'
import { useDispatch, useSelector } from 'react-redux'
import palette from 'app_palette'
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium'
import * as ProposalAction from 'store/actions/index'
import API from 'api'
import { setProModalOpen } from 'store/actions/proModal'
import { useStyles } from './styles'

const stylesAnimation = {
  height: '100%',
  bounce: {
    animation: 'x 0.4s',
    animationName: Radium.keyframes(fadeIn, 'bounce'),
  },
}

function ConsumoCard({
  resumido,
  showButtons,
  proposal,
  locked,
  actualEdit,
  setActualEdit,
  handleChangeLock,
  sizingEdit,
  setOthersLoading,
}) {
  const isProPlan = useSelector((state) => state.ReducerProfile.isProPlan)

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const dispatch = useDispatch()

  const [editing, setEditing] = useState(false)

  const [loading, setLoading] = useState(false)
  const [newConsumption, setNewConsumption] = useState()

  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setOthersLoading(loading)
  }, [loading])

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const handleChangeConsumption = () => {
    setLoading(true)

    const finalValue = newConsumption
      ? newConsumption.replace(',', '.')
      : parseFloat(proposal.consumoMensalMedio)

    API.post('/proposal/dimensioning/update/consumption', {
      dimensioning_id: proposal.id,
      consumption: finalValue,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        setEditing(false)
        onSuccessOpenToast('Consumo alterado com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o consumo!')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (editing) {
      setActualEdit('consumption')
    } else {
      setActualEdit('')
    }
  }, [editing])

  return (
    <StyleRoot style={{ height: '100%' }}>
      <div className={classes.div} style={stylesAnimation.bounce}>
        <Paper
          style={{
            border: sizingEdit ? `2px solid ${palette.grayscale[300]}` : 'none',
            boxShadow: sizingEdit ? 'none' : 'rgb(0 0 0 / 4%) 0px 4px 6px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: resumido ? 'column' : 'row',
            }}
          >
            <div
              className={resumido ? '' : classes.rightBorder}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: resumido ? '100%' : 'fit-content',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  type={downSm ? 'link_small' : 'text_medium'}
                  color="grayscale"
                  colorWeight="700"
                >
                  Média de consumo
                </Typography>
                {showButtons &&
                  (locked == 'consumo' ? (
                    <LockIcon width="24" color={palette.primary[300]} />
                  ) : (
                    <LockUnlockedIcon
                      width="24"
                      color={palette.grayscale[400]}
                      onClick={() => {
                        if (!isProPlan) return dispatch(setProModalOpen())

                        return handleChangeLock('consumo')
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  ))}
              </div>
              <Spacer size="16" direction="horizontal" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {editing ? (
                    <Input
                      value={newConsumption}
                      defaultValue={parseFloat(
                        proposal.consumoMensalMedio.toFixed(0),
                      )}
                      autoFocus
                      type="number"
                      setValue={setNewConsumption}
                      submit={handleChangeConsumption}
                      onBlur={() => handleChangeConsumption()}
                      adornmentPosition="right"
                      adornment="kWh"
                      loading={loading}
                    />
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Typography
                        color="grayscale"
                        colorWeight="700"
                        type={
                          downSm ? 'display_x_small_bold' : 'display_small_bold'
                        }
                      >
                        {proposal.consumoMensalMedio.toFixed(0)}
                      </Typography>
                      <Spacer size="4" direction="vertical" />
                      <Typography
                        color="grayscale"
                        colorWeight="600"
                        type="text_x_small"
                      >
                        kWh/mês
                      </Typography>
                    </div>
                  )}
                </div>
                {showButtons &&
                  (downSm || !upXl
                    ? !editing && (
                        <EditIcon
                          width="24"
                          onClick={() => {
                            if (!isProPlan) return dispatch(setProModalOpen())

                            editing
                              ? handleChangeConsumption()
                              : setEditing(true)
                          }}
                          color={palette.grayscale[800]}
                          style={{
                            cursor: 'pointer',
                            marginLeft: !downSm && '24px',
                          }}
                        />
                      )
                    : !editing && (
                        <Button
                          onClick={() => {
                            if (!isProPlan) return dispatch(setProModalOpen())

                            editing
                              ? handleChangeConsumption()
                              : setEditing(true)
                          }}
                          disabled={actualEdit === 'potency'}
                          noMargin
                          variant="outlined"
                          style={{ marginLeft: '16px' }}
                          size="medium"
                          isLoading={loading}
                        >
                          {editing ? 'Confirmar' : 'Alterar'}
                        </Button>
                      ))}
              </div>
            </div>
            {!resumido && (
              <div
                className={resumido ? '' : classes.paddingLeft}
                style={{ display: 'flex', width: '-webkit-fill-available' }}
              >
                <Grid container justify="space-between">
                  <Grid
                    item
                    xs={resumido ? 12 : 6}
                    md={resumido ? 12 : 6}
                    lg={resumido ? 12 : 6}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        type={downSm ? 'link_small' : 'text_medium'}
                        color="grayscale"
                        colorWeight="700"
                      >
                        Produção anual
                      </Typography>
                    </div>
                    <Spacer size="16" direction="horizontal" />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography
                          color="grayscale"
                          colorWeight="700"
                          type={
                            downSm
                              ? 'display_x_small_bold'
                              : 'display_small_bold'
                          }
                        >
                          {proposal.energiaGerada.toFixed(0)}
                        </Typography>
                        <Spacer size="4" direction="vertical" />
                        <Typography
                          color="grayscale"
                          colorWeight="600"
                          type="text_x_small"
                        >
                          kWh
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={resumido ? 12 : 6}
                    md={resumido ? 12 : 6}
                    lg={resumido ? 12 : 6}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        type={downSm ? 'link_small' : 'text_medium'}
                        color="grayscale"
                        colorWeight="700"
                      >
                        Produção mensal
                      </Typography>
                    </div>
                    <Spacer size="16" direction="horizontal" />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Typography
                          color="grayscale"
                          colorWeight="700"
                          type={
                            downSm
                              ? 'display_x_small_bold'
                              : 'display_small_bold'
                          }
                        >
                          {(proposal.energiaGerada / 12).toFixed(0)}
                        </Typography>
                        <Spacer size="4" direction="vertical" />
                        <Typography
                          color="grayscale"
                          colorWeight="600"
                          type="text_x_small"
                        >
                          kWh
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </Paper>
      </div>
    </StyleRoot>
  )
}

export default ConsumoCard
