import { Tab } from '@77sol-ui/molecules'
import * as S from './styles'
import { FinancingAndProposalTab, LIMIT_OF_ROWS } from './constants'
import { useFinancingAndProposalsFilter } from './hooks/useFinancingAndProposalsFIlter'
import { StatusSelect } from './components/StatusSelect'
import { FinancingList } from './components/FinancingList'
import useGetMaxNumberOfRows from './hooks/useGetMaxNumberOfRows'
import { useGetFinancingRequestsData } from 'domains/home/dashboard-financing-requests/hooks/useGetFinancingRequestsData'
import { ProposalList } from './components/ProposalList'

export function FinancingAndProposalsTable() {
  const {
    selectedStatus,
    selectedTab,
    onChangeTab,
    onChangeFilter,
    FILTER_STATUS,
  } = useFinancingAndProposalsFilter()

  const { data, isLoading, isError } = useGetFinancingRequestsData({
    params: {
      limit: LIMIT_OF_ROWS,
      status:
        selectedTab === FinancingAndProposalTab.FINANCINGS
          ? selectedStatus?.value
          : undefined,
    },
  })

  const alreadyCreatedFinancing = data?.already_has_financing

  const { numberOfRows } = useGetMaxNumberOfRows({
    active: !selectedStatus?.value,
    financingsQty: data?.financings?.length,
    proposalsQty: 0,
    defaultValue: LIMIT_OF_ROWS,
  })

  return (
    <S.TableContainer data-testid="financing-and-proposal-list">
      <S.TableInfos>
        <S.Title>
          <h3>Financiamentos</h3>
          <p>Acompanhe o status de seus financiamentos</p>
        </S.Title>

        <StatusSelect
          options={FILTER_STATUS}
          onChangeFilter={onChangeFilter}
          selectedStatus={selectedStatus}
          isLocked={selectedTab === FinancingAndProposalTab.PROPOSALS}
          key={selectedTab}
        />

        <S.TabRoot
          onValueChange={(value) => {
            onChangeTab(value)
          }}
          value={selectedTab}
        >
          <Tab.List>
            <Tab.Trigger
              value={FinancingAndProposalTab.FINANCINGS}
              data-testid="financing-tab-trigger"
            >
              Financiamentos
            </Tab.Trigger>
            <Tab.Trigger
              value={FinancingAndProposalTab.PROPOSALS}
              data-testid="proposals-tab-trigger"
            >
              Propostas
            </Tab.Trigger>
          </Tab.List>
          <Tab.Content value={FinancingAndProposalTab.FINANCINGS}>
            <FinancingList
              numberOfRows={numberOfRows}
              financings={data?.financings}
              alreadyCreatedFinancing={alreadyCreatedFinancing}
              isLoading={isLoading}
              isError={isError}
              filterApplied={Boolean(selectedStatus?.value)}
            />
          </Tab.Content>
          <Tab.Content value={FinancingAndProposalTab.PROPOSALS}>
            <ProposalList numberOfRows={numberOfRows} />
          </Tab.Content>
        </S.TabRoot>
      </S.TableInfos>
    </S.TableContainer>
  )
}
