import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    backgroundColor: palette.grayscale[100],
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  headerSubtitle: {
    width: '45%',
    minWidth: '160px',
    display: 'flex',
    justifyContent: 'flex-end',
    [breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  content: {
    height: 'calc(var(--app-height) - 135px)',
    overflow: 'auto',
    padding: '24px 32px',
  },
}))

export default styles
