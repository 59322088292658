import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 16px;

  section {
    margin-top: 16px;
  }

  section {
    h3 {
      font-family: 'DM Sans';
      color: ${({ theme }) => theme.colors.gray[900]};
      font-size: 0.875rem;
      font-weight: bold;

      @media (min-width: 768px) {
        margin-left: 0;
      }
    }

    .manage-person-type {
      display: flex;
      justify-content: space-between;

      > button {
        margin-right: 16px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
`
