export const OTHER_DOCUMENTS = [
  {
    title: 'Contrato',
    type: 'contract',
    hasBeenSent: false,
    documentType: 'other_documents',
  },
  {
    title: 'Nota fiscal de produtos',
    type: 'nf_products',
    hasBeenSent: false,
    documentType: 'other_documents',
  },
  {
    title: 'Ordem de compra',
    type: 'purchase_order',
    hasBeenSent: false,
    documentType: 'other_documents',
  },
]
