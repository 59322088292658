import { colors } from '@77sol-ui/tokens'
import { Modal } from 'components/Modal'
import styled from 'styled-components'

export const Wrapper = styled(Modal.Wrapper)`
  max-width: 800px !important;
  max-height: 90vh !important;

  span,
  p {
    font-family: DM Sans !important;
  }
`

export const SucessDefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-x: hidden;

  & > div > .overflow-container {
    overflow-x: auto;
    max-width: 100%;
  }

  & .steppetTitle {
    color: ${colors.blue[300]};
    font-size: 0.875rem;
  }
`

export const StepperContainer = styled.div`
  padding: 28px;
  margin-bottom: 40px;
  min-width: 500px;

  & span {
    font-size: 12px;
  }
`

export const HeaderDescription = styled(Modal.Description)`
  margin-top: 8px;
`
