import { Table } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const TableRow = styled(Table.Row)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  * {
    font-family: DM Sans, sans-serif;
  }

  @media (max-width: 1023px) {
    border: none;
  }
`
