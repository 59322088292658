const initialPdf = [
  {
    id: 'LinkPdf',
    content: 'item-linkPdf',
    display: 'Link da proposta',
    visible: true,
  },
  {
    id: 'SchedulesPdf',
    content: 'item-schedulesPdf',
    display: 'Cronograma',
    visible: true,
  },
]

const initial = [
  {
    id: 'investingContainer',
    content: 'item-investingContainer',
    display: 'Retorno de investimento',
    visible: true,
  },
  {
    id: 'equipmentsContainer',
    content: 'item-equipmentsContainer',
    display: 'Dados de projeto',
    visible: true,
  },
  {
    id: 'paymentsOptionsContainer',
    content: 'item-paymentsOptionsContainer',
    display: 'Financiamento e pagamento',
    visible: true,
  },
  {
    id: 'scheduleCard',
    content: 'item-scheduleCard',
    display: 'Cronograma',
    visible: true,
  },
  {
    id: 'constructionImagesCard',
    content: 'item-constructionImagesCard',
    display: 'Imagens de obras',
    visible: true,
  },
]

const initialRetornoSubCards = [
  {
    id: 'retornoEmContainer',
    content: 'item-retornoEm',
    display: 'Retorno',
    breakpoint: 6,
    visible: true,
  },
  {
    id: '30AnosContainer',
    content: 'item-30Anos',
    display: 'Economia em 30 anos',
    breakpoint: 6,
    visible: true,
  },
]

const initialTirVplContainers = [
  {
    id: 'TirContainer',
    content: 'item-Tir',
    display: 'TIR',
    breakpoint: 6,
    visible: true,
  },
  {
    id: 'VplContainer',
    content: 'item-Vpl',
    display: 'VPL',
    breakpoint: 6,
    visible: true,
  },
]

const initialInvesting = [
  {
    id: 'voceInvesteContainer',
    content: 'item-investing',
    display: 'Investimento',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'retornoSubCards',
    content: 'item-rretornoSubCards',
    display: 'Retorno e economia',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'tirVplContainers',
    content: 'item-tirVplContainers',
    display: 'TIR e VPL',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'RetornoContainer',
    content: 'item-Comparation',
    display: 'Comparação',
    breakpoint: 12,
    visible: true,
  },
]

const initialInvestingSections = [
  {
    id: 'allCards',
    content: 'item-allCards',
    display: 'Cards',
    breakpoint: 6,
    visible: true,
  },
  {
    id: 'retornoGrafico',
    content: 'item-retornoGrafico',
    display: 'Curva de retorno',
    breakpoint: 6,
    visible: true,
  },
]

const initialEqupmentsSections = [
  {
    id: 'equipmentsCard',
    content: 'item-equipmentsCard',
    display: 'Orçamento',
    breakpoint: 4,
    visible: true,
  },
  {
    id: 'ProducaoRetorno',
    content: 'item-ProducaoRetorno',
    display: 'Produção e consumo',
    breakpoint: 8,
    visible: true,
  },
]

const initialPaymentOptionsContainers = [
  {
    id: 'financingCard',
    content: 'item-financingCard',
    display: 'Financiamento',
    breakpoint: 8,
    visible: true,
  },
  {
    id: 'paymentOptionsCard',
    content: 'item-paymentOptionsCard',
    display: 'Forma de pagamento',
    breakpoint: 4,
    visible: true,
  },
]

const initialMoreInfosContainers = [
  {
    id: 'userOptionsCard',
    content: 'item-userOptionsCard',
    display: 'Interação',
    breakpoint: 5,
    visible: true,
  },
  {
    id: 'proposalInfosCard',
    content: 'item-proposalInfosCard',
    display: 'Informações da proposta',
    breakpoint: 7,
    visible: true,
  },
]

const initialPaymentOptionsSections = [
  {
    id: 'paymentsOptionsContainers',
    content: 'item-paymentOptionsContainers',
    display: 'Financiamento e forma de pagamento',
    breakpoint: 12,
    visible: true,
  },
  {
    id: 'moreInfosContainers',
    content: 'item-moreInfosContainers',
    display: 'Interação e informações da proposta',
    breakpoint: 12,
    visible: true,
  },
]

export {
  initial,
  initialPdf,
  initialRetornoSubCards,
  initialTirVplContainers,
  initialInvesting,
  initialInvestingSections,
  initialEqupmentsSections,
  initialPaymentOptionsContainers,
  initialMoreInfosContainers,
  initialPaymentOptionsSections,
}
