import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { Link } from 'react-router-dom'
import { CheckIcon } from 'lucide-react'
import { Modal } from '@77sol-ui/molecules'
import { Button } from '@77sol-ui/atoms'
import { Description } from './styles'

export function SuccessRequestFinancingModal() {
  const { isSuccessFinancingModalOpen, setSuccessFinancingModalOpen } =
    useSuccessFinancingRequestModalContext()

  return (
    <Modal.Root
      open={isSuccessFinancingModalOpen}
      onOpenChange={setSuccessFinancingModalOpen}
      data-testid="success-request-financing-modal"
    >
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Wrapper maxHeight="270px" maxWidth="732px">
          <Modal.Close data-testid="icon-close-success-request-modal" />
          <Modal.Header>
            <Modal.Icon icon={CheckIcon} size={24} />
            <Modal.Title>Simulação foi enviada com sucesso!</Modal.Title>
            <Description>
              Nosso time está dedicado à análise da sua solicitação e você
              poderá acompanhar seu progresso através da aba 'Financiamentos'.
            </Description>
          </Modal.Header>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button
                fullWidth
                variant="outline"
                data-testid="button-close-success-request-modal"
              >
                Fechar
              </Button>
            </Modal.Close>
            <Button fullWidth asChild>
              <Link
                to="/financiamentos"
                data-testid="track-financing-status-button"
                onClick={() => {
                  setSuccessFinancingModalOpen(false)
                }}
              >
                Acompanhar status
              </Link>
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
