import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import MaterialAccordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { ActionPlusIcon, ActionMinusIcon } from '@77sol/icons/dist'
import styles from './styles'

function Accordion({ title, children, size, ...props }) {
  const classes = styles({ title, size })
  const AccordionClass = classNames(classes.accordion)

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <MaterialAccordion {...props} className={AccordionClass}>
      <AccordionSummary
        expanded={isExpanded}
        onClick={() =>
          isExpanded ? setIsExpanded(false) : setIsExpanded(true)
        }
        expandIcon={isExpanded ? <ActionMinusIcon /> : <ActionPlusIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MaterialAccordion>
  )
}

Accordion.propTypes = {
  title: PropTypes.elementType,
  size: PropTypes.oneOf(['small', 'large']),
}

Accordion.defaultProps = {
  title: '',
  size: 'small',
}

export default Accordion
