import { useState } from 'react'
import { type IQuotationProductDTO } from 'dto/QuotationProductDTO'

type IProductDelete = IQuotationProductDTO | null

export function useRemoveProduct() {
  const [productToDelete, setProductToDelete] = useState<IProductDelete>(null)

  function handleOpenDeleteProduct(product: IQuotationProductDTO) {
    setProductToDelete(product)
  }

  function handleCloseDeleteProduct() {
    setProductToDelete(null)
  }

  return {
    productToDelete,
    handleOpenDeleteProduct,
    handleCloseDeleteProduct,
  }
}
