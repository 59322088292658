import { useCallback } from 'react'
import { Header } from 'components'
import { Button, DropdownMenu } from '@77sol/core'
import { useHistory } from 'react-router-dom'
import { useAmplitude } from 'hooks/useAmplitude'
import { headerTracker } from 'services/tracker/events/header/trackers'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import useWindowSize from 'hooks/useWindowSize'
import { ArrowDropDown } from '@material-ui/icons'
import { triggerTypes } from 'services/tracker/properties/triggerType'
import { DROPDOWN_MENU_STATE } from './constants'
import * as S from './styles'

export function HeaderDashboard({ title, noMargin, zIndex = null, noActions }) {
  const history = useHistory()
  const { logEvent } = useAmplitude()
  const { setIsQuotationModalOpen } = useQuotationModalContext()

  const { width } = useWindowSize()
  const downSm = width < 768

  const handleDropDown = (value) => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.quoteEquipmentKey,
      type: triggerTypes.dropdownMenu.trigger,
      state: DROPDOWN_MENU_STATE[value],
    })
  }

  const handleQuoteEquipment = useCallback(() => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.quickQuoteKey,
      type: triggerTypes.dropdownMenu.option,
    })
    setIsQuotationModalOpen(true)
  }, [])

  const handleNewProposal = useCallback(() => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.sizingAndQuoteKey,
      type: triggerTypes.dropdownMenu.option,
    })
    history.push('/dimensionar')
  }, [])

  const { setFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()

  const handleOpenFinancingPerValueModal = () => {
    logEvent(headerTracker.eventName, {
      origin: location.pathname,
      action: headerTracker.actions.requestFinancingKey,
      type: downSm ? triggerTypes.dropdownMenu.option : triggerTypes.button,
    })
    setFinancingPerValueModalOpen()
  }

  const DropdownMenuDynamicOptions = () => {
    const CommomOptions = () => (
      <>
        <DropdownMenu.Item onClick={handleQuoteEquipment}>
          Cotação rápida
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleNewProposal}>
          Dimensionar e cotar
        </DropdownMenu.Item>
      </>
    )

    if (downSm) {
      return (
        <>
          <CommomOptions />
          <DropdownMenu.Item onClick={handleOpenFinancingPerValueModal}>
            Solicitar financiamento
          </DropdownMenu.Item>
        </>
      )
    }

    return <CommomOptions />
  }

  return (
    <Header noMargin={noMargin} title={title} sticky primary zIndex={zIndex}>
      {!noActions && (
        <S.Wrapper>
          {!downSm && (
            <Button
              id="header_button_requestFinancing"
              size="small"
              variant="outlined"
              onClick={handleOpenFinancingPerValueModal}
            >
              Solicitar financiamento
            </Button>
          )}

          <DropdownMenu.Root
            modal={false}
            onOpenChange={(value) => handleDropDown(value)}
          >
            <DropdownMenu.Trigger asChild>
              <Button size="small" endIcon={<ArrowDropDown />}>
                {downSm ? 'Criar' : 'Cotar equipamentos'}
              </Button>
            </DropdownMenu.Trigger>
            <S.DropdownMenuPortal>
              <S.DropdownMenuContent sideOffset={16}>
                <DropdownMenuDynamicOptions />
              </S.DropdownMenuContent>
            </S.DropdownMenuPortal>
          </DropdownMenu.Root>
        </S.Wrapper>
      )}
    </Header>
  )
}
