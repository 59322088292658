export const INITIAL = {
  VALUES: {
    status: 0,
    name_financier: '',
    image_financier: '',
    key_image_financier: '',
    entrie: '',
    monthly_interest_rate: '',
    number_installments: '',
    waiting_period: '',
    config_simulation: '',
    total_value: 0,
    value_installment: 0,
  },
}
