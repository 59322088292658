import { ModalContent, StyledOverlay } from './styles'
import { type AlterDeliveryModalProps } from './AlterDeliveryModal.types'
import { ordersTracker } from 'services/tracker/events/orders/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { Modal } from '@77sol-ui/molecules'
import { AlertCircle } from 'lucide-react'
import { Button } from '@77sol-ui/atoms'

export function AlterDeliveryModal({
  isOpen,
  deliveryValue,
  alterDeliveryValue,
  cancelNewDeliveryValue,
}: AlterDeliveryModalProps): JSX.Element {
  const { logEvent } = useAmplitude()

  const handleCancel = () => {
    logEvent(ordersTracker.actions.cancelShippingChange, {
      origin: '(Modal) Reservar Pedido',
    })

    cancelNewDeliveryValue()
  }

  const handleConfirm = () => {
    logEvent(ordersTracker.actions.confirmShippingChange, {
      origin: '(Modal) Reservar Pedido',
    })

    alterDeliveryValue()
  }

  return (
    <Modal.Root open={isOpen} onOpenChange={handleCancel}>
      <Modal.Portal>
        <StyledOverlay id="modal-alter-delivery-overlay" />
        <Modal.Wrapper
          id="modal-alter-delivery"
          maxWidth="700px"
          maxHeight="95vh"
          zIndex={1500}
          data-testid="container"
        >
          <Modal.Close />
          <Modal.Header>
            <Modal.Icon icon={AlertCircle} />
            <Modal.Title>
              Você realizou uma alteração. Deseja continuar mesmo assim?
            </Modal.Title>
            <Modal.Description>
              Sua alteração gerou uma mudança no custo do frete. Você pode
              continuar, ou voltar e alterar o CEP.
            </Modal.Description>
          </Modal.Header>

          <ModalContent>
            <div className="compare-price-wrapper">
              <div className="current-price">
                <p>Preço Atual</p>
                <p className="price">{deliveryValue.new_value}</p>
              </div>

              <div className="old-price">
                <p>Preço anterior</p>
                <p className="price">{deliveryValue.old_value}</p>
              </div>
            </div>
          </ModalContent>

          <Modal.Footer>
            <Modal.Close asChild>
              <Button fullWidth variant="outline" onClick={handleCancel}>
                Voltar
              </Button>
            </Modal.Close>
            <Button fullWidth onClick={handleConfirm}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal.Wrapper>
      </Modal.Portal>
    </Modal.Root>
  )
}
