import { CATEGORY_ENUM } from 'enums/products/CategoryEnum'

const CATEGORIES = [CATEGORY_ENUM.INVERSOR, CATEGORY_ENUM.MODULO]

const ERROR_MESSAGES = {
  [CATEGORY_ENUM.INVERSOR]: {
    OVERLOAD:
      'O inversor não possui capacidade suficiente para a potência dos módulos. Substitua o inversor ou reduza a quantidade de módulos.',
    LOW_OVERLOAD:
      'A capacidade do inversor está superior à necessidade do kit, o que pode comprometer a eficiência do sistema. Substitua o inversor ou reduza a quantidade de módulos.',
  },
  [CATEGORY_ENUM.MODULO]: {
    OVERLOAD:
      'A potência dos módulos excede a capacidade do inversor. Reduza a quantidade de módulos ou substitua o inversor.',
    LOW_OVERLOAD:
      'A sobrecarga do kit está baixa, o que pode comprometer a eficiência do sistema. Aumente a quantidade de módulos ou substitua o inversor.',
  },
  DIFFERENT_PROVIDER:
    'Este item é de outro estoque e não pode compor o kit gerado.',
}

export { CATEGORIES, CATEGORY_ENUM, ERROR_MESSAGES }
