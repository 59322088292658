import { Spacer, Typography } from '@77sol/core'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Loading, ProdutosCard } from 'components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useStyles } from './styles'

export function Stock({
  loadingProdutosNovaLoja,
  totalProducts,
  filteredProdutosNovaLoja,
  updatePageFilteredProducts,
  hasMoreFilteredProducts,
  setOpenSidebarProduct,
  setProductViewDetail,
}) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      spacing={0}
      style={{
        marginTop: '20px',
        overflowY: downSm ? 'hidden' : 'hidden',
        overflowX: !downSm && 'hidden',
        flex: 'auto',
        minHeight: '0',
      }}
    >
      <Grid
        item
        lg={12}
        sm={12}
        xs={12}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Loading isLoading={loadingProdutosNovaLoja} minHeight="250px">
          <div className={classes.header}>
            <Typography className={classes.title} variant="h5">
              {totalProducts} resultados
            </Typography>
            {downSm && <Spacer size="36" direction="horizontal" />}
          </div>
          <InfiniteScroll
            dataLength={filteredProdutosNovaLoja.length}
            next={updatePageFilteredProducts}
            hasMore={hasMoreFilteredProducts}
            height="100%"
            loader={
              <div>
                <p style={{ textAlign: 'center', padding: '16px' }}>
                  <b> Carregando...</b>
                </p>
              </div>
            }
          >
            <div className={classes.produtosList}>
              <Grid container>
                {filteredProdutosNovaLoja.map((produto) => (
                  <Grid
                    style={{ marginBottom: '20px' }}
                    item
                    key={produto.id}
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <ProdutosCard
                      onClick={() => {
                        setOpenSidebarProduct(true)
                        setProductViewDetail(produto)
                      }}
                      produto={produto}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </InfiniteScroll>
        </Loading>
      </Grid>
    </Grid>
  )
}
