function abbreviateNumber(number: number): string {
  return (
    number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      notation: 'compact',
      compactDisplay: 'short',
    }) || ''
  )
}

export { abbreviateNumber }
