import { type DefaultValuesForm } from '../components/FormManager/types'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const FINANCING_TYPE = 'cotacao'
export const FINANCING_TYPE_TRACKER = 'Cotação'

export const INICIAL_VALUES_FORM: DefaultValuesForm = {
  client_type: PERSON_TYPE_ENUM.NATURAL,
  grace_days: '90',
  input_value: 0,
}

export const CUSTOM_TABLE_SPACING = '0.3fr 0.6fr 0.7fr 1fr 1fr 1fr'

export const TABLE_COLUMN_NAME = [
  '',
  'ID',
  'Potência',
  'Total Equipamentos',
  'Custos Extras',
  'Valor Total',
]

export enum STEPS_ENUM {
  LIST = 1,
  FORM = 2,
}
