import { forwardRef, type InputHTMLAttributes } from 'react'
import { DatePicker } from '@77sol/core'
import { type Control, Controller } from 'react-hook-form'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  control: Control<any>
  title: string
  disabled?: boolean
  className?: string
  DialogProps?: {
    id: string
  }
}

export const DatePickerControlled = forwardRef<HTMLElement, TextFieldProps>(
  ({ name, control, title, disabled, className, DialogProps }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            ref={ref}
            className={className}
            name={name}
            title={title}
            value={field.value}
            disabled={disabled}
            dateChange={field.onChange}
            DialogProps={DialogProps}
            onBlur={field.onChange}
          />
        )}
      />
    )
  },
)
