import { CircleActionsAlertQuestionIcon } from '@77sol/icons/dist'
import { Title as LibTitle } from '@77sol/core'
import * as S from './styles'

export type TitleProps = React.ComponentProps<typeof S.TitleContainer> & {
  title: string
  color?: string
  $colorWeight?: string
}

export const Title = ({
  title,
  color = 'primary',
  $colorWeight = '300',
  ...props
}: TitleProps) => {
  return (
    <S.TitleContainer {...props}>
      <CircleActionsAlertQuestionIcon />
      <LibTitle size="small" color={color} colorWeight={$colorWeight}>
        {title}
      </LibTitle>
    </S.TitleContainer>
  )
}
