import api from 'api'
import { CAMPAIGN_MODALS } from '../constants/endpointKeys'
import { type ICampaignModalsGETResponse } from './types'

export const campaignModals = {
  get: async () =>
    await api
      .get<ICampaignModalsGETResponse>(CAMPAIGN_MODALS)
      .then(({ data }) => data),
}
