import { useQuery } from '@tanstack/react-query'
import { FINANCING_BY_VALUE } from '../constants/endpointKeys'

import {
  type IGetFinancingByValueRequest,
  type IGetFinancingByValueResponse,
} from '../services/types'
import { getFinancingByValue } from '../services/api'

interface IUseFinancingByValueProps {
  onSuccess?: (data: IGetFinancingByValueResponse) => void
  enabled?: boolean
  financingId: Partial<IGetFinancingByValueRequest['financingId']>
  onError?: (data: unknown) => void
}

export function useFinancingByValue({
  financingId,
  onSuccess,
  enabled = true,
  onError,
}: IUseFinancingByValueProps) {
  return useQuery({
    queryKey: [FINANCING_BY_VALUE, financingId],
    queryFn: async () => {
      if (!financingId) throw new Error('FinancingId is required')
      return await getFinancingByValue({
        financingId,
      })
        .then((response) => {
          const { data } = response
          onSuccess?.(data)
          return data?.data
        })
        .catch((error: unknown) => {
          onError?.(error)
          throw new Error(error as any)
        })
    },
    enabled,
  })
}
