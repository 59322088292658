import { makeStyles } from '@material-ui/core/styles'
import integratorImage from '../../../assets/img/integrator.png'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: '90vw',
    maxWidth: '1680px',
    maxHeight: '79vh',
    overflowX: 'hidden',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      maxHeight: '100vh',
      overflowY: 'auto',
      width: '100vw',
    },
  },
  imageContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(228, 235, 255, 0.4)',
    [breakpoints.up('md')]: {
      backgroundImage: `url(${integratorImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'bottom',
    },
  },
  imageBox: {
    padding: '40px',
    [breakpoints.down('md')]: {
      padding: '0px',
      alignItems: 'center',
    },
  },
  titleBox: {
    [breakpoints.down('md')]: {
      padding: '0px 16px',
      alignItems: 'center',
    },
  },
  tableContent: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'auto',
    [breakpoints.down('md')]: {
      flex: 1,
      backgroundColor: palette.primary[100],
      alignItems: 'flex-start',
      marginTop: '16px',
      padding: '16px',
      borderRadius: '8px',
    },
  },
  buttonsContent: {
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'sticky',
    padding: '40px 30px 30px',
    background: 'linear-gradient(to bottom, #FFFFFF50 0%, #FFFFFF 25%)',
  },
  isProContent: {
    maxWidth: '90vw',
    padding: '30px 50px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      padding: '20px 40px',
      '& h5': {
        fontSize: '1rem',
      },
      '& p': {
        fontSize: '.8rem',
      },
    },
  },
  icon: () => ({
    height: '32px',
    width: '32px',
    color: palette.grayscale[700],
    cursor: 'pointer',
  }),
}))

export default useStyles
