import { ChevronDown } from 'lucide-react'
import { Wrapper, Content, Title, SubTitle } from './styles'
import { truncateText } from 'utils/truncateText'

export interface IProfileProps {
  title: string
  subtitle: string
}

export function Profile({ title, subtitle }: IProfileProps) {
  return (
    <Wrapper>
      <Content>
        <Title>{truncateText(title)}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </Content>
      <ChevronDown size={18} />
    </Wrapper>
  )
}
