import styled from 'styled-components'

export const SkeletonWrapper = styled.div`
  margin-top: 30px;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
