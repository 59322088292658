import { Skeleton } from 'components/Skeleton'
import * as S from './styles'

export function CustomizeKitSkeleton() {
  return (
    <S.SkeletonWrapper>
      <div className="quotation-cards">
        <Skeleton height="184px" width="40px" />
        <Skeleton height="185px" width="412px" />
        <Skeleton height="185px" width="412px" />
        <Skeleton height="185px" width="412px" />
      </div>

      <div className="quotation-select">
        <Skeleton height="50px" width="100%" />
      </div>

      <div className="quotation-actions">
        <Skeleton height="30px" width="150px" />

        <div className="buttons">
          <div className="group">
            <Skeleton height="43px" width="60px" />
            <Skeleton height="43px" width="137px" />
          </div>

          <div className="group">
            <Skeleton height="43px" width="137px" />
            <Skeleton height="43px" width="137px" />
          </div>
        </div>
      </div>

      <div className="quotation-products">
        {Array.from({ length: 4 }).map((_, index) => (
          <>
            <div key={index} className="product">
              <div className="content">
                <Skeleton height="72px" width="72px" />
                <Skeleton height="72px" width="500px" />
              </div>

              <div className="content">
                <Skeleton height="50px" width="120px" />
                <Skeleton height="43px" width="60px" />
              </div>
            </div>

            <div key={index} className="product-mobile">
              <Skeleton height="150px" width="100%" />
            </div>
          </>
        ))}
      </div>
    </S.SkeletonWrapper>
  )
}
