import { ImageContainer } from './styles'

export type IImagesContainerProps = React.HtmlHTMLAttributes<HTMLDivElement>

export function ImagesContainer({ children, ...props }: IImagesContainerProps) {
  return (
    <ImageContainer data-testid="images-container" {...props}>
      {children}
    </ImageContainer>
  )
}
