interface IUseCalculateTicksStepSizeProps {
  maxYValue?: number
  maxY1Value?: number
  gap?: number
}

export function useCalculateTicksStepSize({
  maxYValue,
  maxY1Value,
  gap = 5,
}: IUseCalculateTicksStepSizeProps) {
  if (
    typeof maxYValue !== 'number' ||
    typeof maxY1Value !== 'number' ||
    maxYValue <= 0 ||
    maxY1Value <= 0
  ) {
    return { yStepSize: 0, y1StepSize: 0, maxTicksYLimit: 1 }
  }

  const yStepSize = Math.ceil(maxYValue / gap)

  const proportion = maxY1Value / maxYValue

  const y1StepSize = proportion * yStepSize

  return {
    yStepSize,
    y1StepSize,
    maxTicksYLimit: undefined,
  }
}
