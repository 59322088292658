import {
  GET_FINANCING_PER_QUOTATION_SIMULATIONS,
  GET_FINANCING_PER_VALUE_SIMULATIONS,
} from 'domains/financing/constants/endpointKeys'
import {
  useFetchFinancingPerQuotationSimulations,
  useFetchFinancingPerValueSimulations,
} from 'domains/financing/hooks/useFinancing'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { queryClient } from 'services/queryClient'
import { filterFinancingToId } from 'views/Financing/utils'

interface UseFinancingDetailsProps {
  isFinancingPerValue: boolean
  quotationId: string
  selectedFinancingId: string
}
export function useFinancingSimulations({
  selectedFinancingId,
  quotationId,
  isFinancingPerValue,
}: UseFinancingDetailsProps) {
  const location = useLocation()
  const { financingId } = location?.state || {}
  const {
    refetch: refetchSimulationPerValue,
    simulations: financingPerValueSimulations,
    isFetching: isLoadingGetFinancingPerValueSimulations,
  } = useFetchFinancingPerValueSimulations(
    selectedFinancingId,
    isFinancingPerValue,
  )

  const {
    refetch: refetchSimulationPerQuotation,
    simulations: financingPerQuotationSimulations,
    isFetching: isLoadingGetFinancingPerQuotationSimulations,
  } = useFetchFinancingPerQuotationSimulations(quotationId, isFinancingPerValue)

  function handleCancelFinancingQueries() {
    queryClient.cancelQueries([
      GET_FINANCING_PER_VALUE_SIMULATIONS,
      selectedFinancingId,
    ])
    queryClient.cancelQueries([
      GET_FINANCING_PER_QUOTATION_SIMULATIONS,
      quotationId,
    ])
  }

  function refetchSimulationsList() {
    if (isFinancingPerValue) {
      refetchSimulationPerValue()
    } else {
      refetchSimulationPerQuotation()
    }
  }

  function handleInvalidateFinancingQueries() {
    queryClient.invalidateQueries({
      queryKey: [
        GET_FINANCING_PER_VALUE_SIMULATIONS,
        GET_FINANCING_PER_QUOTATION_SIMULATIONS,
      ],
      exact: false,
      refetchType: 'active',
    })
  }

  const financingPerValueSimulationsData = useMemo(() => {
    const bindFinancingFinded =
      financingPerValueSimulations?.detail?.simulations?.find(
        ({ id_origin }: any) => isFinancingPerValue && id_origin,
      )
    const bindedFinancing = {
      isBinded: !!bindFinancingFinded,
      financingId: bindFinancingFinded?.id || null,
      orderId: bindFinancingFinded?.id_origin || null,
      orderCode: bindFinancingFinded?.order_code || null,
      originId: bindFinancingFinded?.financing_id || null,
    }

    return {
      simulations: filterFinancingToId(
        financingId,
        financingPerValueSimulations?.detail?.simulations,
      ),
      financingId,
      bindedFinancing,
    }
  }, [financingPerValueSimulations])

  const financingPerQuotationSimulationsData = useMemo(() => {
    return {
      simulations: filterFinancingToId(
        financingId,
        financingPerQuotationSimulations?.detail?.simulations,
      ),
      quotationId,
    }
  }, [financingPerQuotationSimulations])

  const isLoading =
    isLoadingGetFinancingPerValueSimulations ||
    isLoadingGetFinancingPerQuotationSimulations

  const data = isFinancingPerValue
    ? financingPerValueSimulationsData
    : financingPerQuotationSimulationsData

  return {
    data,
    isLoading,
    refetchSimulationsList,
    handleCancelFinancingQueries,
    handleInvalidateFinancingQueries,
  }
}
