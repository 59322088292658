import React from 'react'
import { TableList } from 'components'
import { Box, Hidden } from '@material-ui/core'
import { BoxIcon, RefreshIcon } from '@77sol/icons/dist'
import { Spacer, Title, Typography, Modal, Button } from '@77sol/core'
import styles from './styles'

function Archive({
  isOpen,
  onClose,
  onConfirmArchive,
  unarchive,
  responsiveCard,
  optionToArchive,
}) {
  const classes = styles()

  const label = unarchive ? 'desarquivar' : 'arquivar'
  const buttonLabel = unarchive ? 'Desarquivar' : 'Arquivar'

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box width="70vw">
        <Hidden mdDown>
          <Title size="xsmall" color="primary" colorWeight="300">
            {unarchive ? (
              <RefreshIcon width="32px" />
            ) : (
              <BoxIcon width="32px" />
            )}
            <Spacer size="16" direction="both" />
            Deseja {label} este projeto?
          </Title>
        </Hidden>
        <Hidden lgUp>
          <Typography
            type="link"
            size="small"
            color="primary"
            colorWeight="300"
          >
            {unarchive ? (
              <RefreshIcon width="24px" />
            ) : (
              <BoxIcon width="24px" />
            )}
            <Spacer size="16" direction="both" />
            Deseja {label} este projeto?
          </Typography>
        </Hidden>
      </Box>
      <Hidden mdDown>
        <Box paddingY={3} className={classes.table}>
          <TableList
            columns={[
              'Projeto',
              'Cliente',
              'Propostas',
              'Cotações',
              'Última modificação',
            ]}
            values={[optionToArchive]}
            noSelectRow
            LastItem={null}
          />
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box paddingY={1}>{responsiveCard}</Box>
      </Hidden>
      <Box className={classes.buttonsBox}>
        <Button
          id={`archiveModal_button_${label}`}
          size="small"
          onClick={() => onConfirmArchive(unarchive ? 'unarchive' : 'archive')}
        >
          {buttonLabel}
        </Button>
        <Spacer size="16" direction="both" />
        <Button size="small" variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  )
}

export default Archive
