import { useEffect, useState } from 'react'

export interface IUseGetMaxNumberOfProps {
  financingsQty?: number
  proposalsQty?: number
  defaultValue?: number
  active: boolean
}

export default function useGetMaxNumberOfRows({
  financingsQty = 0,
  proposalsQty = 0,
  defaultValue = 0,
  active,
}: IUseGetMaxNumberOfProps) {
  const [numberOfRows, setNumberOfRows] = useState(defaultValue)

  useEffect(() => {
    if (active && (financingsQty > 0 || proposalsQty > 0)) {
      setNumberOfRows(Math.max(financingsQty, proposalsQty))
    }
  }, [active, financingsQty, proposalsQty])

  return { numberOfRows }
}
