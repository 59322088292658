import { makeStyles } from '@material-ui/core/styles'
import palette from 'app_palette'

const validationBorder = {
  error: `2px solid ${palette.red[300]} !important`,
  success: `2px solid ${palette.green[300]} !important`,
  none: 'none',
}

const validationBackground = {
  error: `${palette.red[50]} !important`,
  success: `${palette.green[50]} !important`,
  none: 'none',
}

const validationLabelColor = {
  error: `${palette.red[400]} !important`,
  success: `${palette.green[400]} !important`,
  none: 'none',
}

const validationTextColor = {
  error: `${palette.grayscale[800]} !important`,
  success: `${palette.grayscale[800]} !important`,
  none: 'none',
}

const styles = makeStyles(theme => ({
  textArea: ({ validationState }) => ({
    '& .MuiFilledInput-root': {
      backgroundColor:
        validationState === 'none'
          ? `${palette.grayscale[200]}`
          : validationBackground[validationState],
      borderRadius: '16px',
      fontWeight: 400,

      border: validationBorder[validationState],
    },
    '& .MuiFilledInput-input': {
      //padding: '6px 24px',
      color:
        validationState === 'none'
          ? `${palette.grayscale[800]}`
          : validationTextColor[validationState],
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      padding: '0px 20px',
      height: 'auto',
    },
    '& .MuiSelect-root': {
      borderRadius: '16px',
      backgroundColor: 'none',
    },
    '& .MuiInputLabel-filled': {
      border: 'none !important',
      background: 'none !important',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
      top: '10px',
      left: '22px',
      '&.MuiInputLabel-shrink': {
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
      },
    },
    '& .Mui-focused': {
      border:
        validationState === 'none'
          ? `2px solid ${palette.primary[300]}`
          : validationBorder[validationState],
      background:
        validationState === 'none'
          ? 'none !important'
          : validationBackground[validationState],
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationTextColor[validationState],
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.75px',
    },
    '& .Mui-disabled': {
      backgroundColor: `${palette.grayscale[200]}`,
      opacity: 0.5,
    },
    '& .MuiFormHelperText-contained': {
      fontWeight: 700,
      fontSize: '13px',
      lineHeight: '22px',
      letterSpacing: '0.25px',
      border: 'none !important',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
      background: 'none !important',
      marginTop: '8px',
    },
    '& .MuiInputAdornment-root': {
      lineHeight: '22px',
      letterSpacing: '0.25px',
      position: 'absolute',
      top: '27px',
      right: '20px',
      color:
        validationState === 'none'
          ? `${palette.grayscale[500]}`
          : validationLabelColor[validationState],
    },
  }),
  closeIcon: ({ validationState }) => ({
    color:
      validationState === 'none'
        ? `${palette.grayscale[500]}`
        : validationLabelColor[validationState],
    fontiSize: '16px',
    cursor: 'pointer',
  }),
}))

export default styles
