import { Modal } from '@77sol-ui/molecules'
import { Content } from './components/Content'
import { useCreateFinancingPerValueModal } from './hooks/useCreateFinancingPerValueModal'
import { useCreateFinancingPerValueModalContext } from './context/CreateFinancingPerValueModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { Loading } from 'containers/Financing/components/Loading'
import { useFetchFinancingInsurancePercentage } from 'domains/financing/hooks/useFinancing'
import { useEffect } from 'react'

export function CreateFinancingPerValueModal() {
  const {
    isLoading,
    isError,
    handleCreateFinancingByValueRequest,
    requestFallback,
  } = useCreateFinancingPerValueModal()
  const { getInsurancePercentage } = useFetchFinancingInsurancePercentage()

  const { isFinancingPerValueModalOpen, setFinancingPerValueModalClose } =
    useCreateFinancingPerValueModalContext()

  const { logEvent } = useAmplitude()

  const onCloseModal = () => {
    logEvent(financingTracker.actions.financingCanceled, {
      financingType: 'Valor',
    })
    setFinancingPerValueModalClose()
  }

  useEffect(() => {
    getInsurancePercentage()
  }, [])

  return (
    <Modal.Root open={isFinancingPerValueModalOpen} onOpenChange={onCloseModal}>
      <Modal.Portal>
        <Modal.Overlay />

        {!isLoading && (
          <Content
            onCreateFinancing={handleCreateFinancingByValueRequest}
            onCloseModal={onCloseModal}
            requestFallback={requestFallback}
          />
        )}

        {isLoading && !isError && <Loading />}
      </Modal.Portal>
    </Modal.Root>
  )
}
