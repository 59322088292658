import { Box } from '@material-ui/core'
import { formatDate } from 'utils'
import { Input, Typography } from '@77sol/core'
import { GROUP_FIELDS_TITLE } from '../constants'

export function allRequesterFields(fields = {}) {
  return Object.entries(fields).reduce((acc, [nameGroup, fieldGroup]) => {
    return [
      ...acc,
      ...fieldGroup.map((field, i) => {
        return i === 0 ? (
          <Box margin={2}>
            <Typography key={i}>{GROUP_FIELDS_TITLE[nameGroup]}</Typography>
          </Box>
        ) : (
          <Box marginBottom={2}>
            {field.format_type && field.value_input ? (
              {
                date: (
                  <Input
                    key={i}
                    disabled
                    title={field.label}
                    mask={field.format_type}
                    value={formatDate(field.value_input)}
                  />
                ),
              }[field.format_type] || (
                <Input
                  key={i}
                  disabled
                  title={field.label}
                  mask={field.format_type}
                  value={String(field.value_input)}
                />
              )
            ) : (
              <Input
                key={i}
                disabled
                title={field.label}
                value={field.value_input}
              />
            )}
          </Box>
        )
      }),
    ]
  }, [])
}
