import { type ISelectProps as ISimpleSelect } from 'components/forms/Select/types'
import { Select as SimpleSelect } from 'components/forms/Select'
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
} from 'react-hook-form'

export type ISelectProps<T extends FieldValues> = ISimpleSelect & {
  control: Control<T, object>
  name: Path<T>
}

export const Select = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  ...props
}: ISelectProps<T>) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <SimpleSelect
          {...props}
          onValueChange={(value) => {
            onChange(value)
            props?.onValueChange?.(value)
          }}
          value={value}
        />
      )}
      control={control}
      defaultValue={defaultValue as PathValue<T, Path<T>>}
      name={name}
    />
  )
}
