import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Page } from 'components'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { Details } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))

function OrcamentoView({
  frete,
  desconto,
  potencia,
  cliente,
  id,
  valorTotal,
  produtos,
  adminClient,
  criadoData,
  integrador,
  isAdmin,
  delivery_days,
}) {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title={currentEnterpriseConfigs.titlePage('Cotações')}
    >
      <Details
        frete={frete}
        desconto={desconto}
        potencia={potencia}
        cliente={cliente}
        id={id}
        valorTotal={valorTotal}
        produtos={produtos}
        adminClient={adminClient}
        criadoData={criadoData}
        integrador={integrador}
        isAdmin={isAdmin}
        delivery_days={delivery_days}
      />
    </Page>
  )
}

export default OrcamentoView
