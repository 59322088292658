import React, { useRef, useState } from 'react'
import { Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import palette from 'app_palette'
import { Typography } from 'components'
import { Spacer, Paper, Button } from '@77sol/core'
import { EditIcon } from '@77sol/icons/dist'
import { proposalColorsDefault } from 'constants/proposalColorsDefault'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '48px',
  },
}))

function ColorsEdit(props) {
  const {
    handleUpdateColors,
    proposal,
    isFromDragAndDrop,
    isFromProposalList,
  } = props
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const fundoColorRef = useRef(null)
  const primaryColorRef = useRef(null)
  const secondaryColorRef = useRef(null)
  const textColorRef = useRef(null)
  const graphicPrimaryColorRef = useRef(null)
  const graphicSecondaryColorRef = useRef(null)
  const positiveColorRef = useRef(null)
  const negativeColorRef = useRef(null)
  const fundoContentColorRef = useRef(null)

  const [fundoColor, setFundoColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.background
      : proposalColorsDefault.background,
  )
  const [primaryColor, setPrimaryColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.primary_color
      : proposalColorsDefault.primary_color,
  )
  const [secondaryColor, setSecondaryColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.secondary_color
      : proposalColorsDefault.secondary_color,
  )
  const [textColor, setTextColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.texts
      : proposalColorsDefault.texts,
  )
  const [graphicPrimaryColor, setGraphicPrimaryColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.primary_color_graphics
      : proposalColorsDefault.primary_color_graphics,
  )
  const [graphicSecondaryColor, setGraphicSecondaryColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.secondary_color_graphics
      : proposalColorsDefault.secondary_color_graphics,
  )
  const [positiveColor, setPositiveColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.positive_values
      : proposalColorsDefault.positive_values,
  )
  const [negativeColor, setNegativeColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.negative_values
      : proposalColorsDefault.negative_values,
  )
  const [fundoContentColor, setFundoContentColor] = useState(
    proposal.extraConfigs?.color_configs
      ? proposal.extraConfigs.color_configs.background_content
      : proposalColorsDefault.background_content,
  )

  return (
    <div
      style={{
        width: '100%',
        padding: isFromDragAndDrop ? '0px' : '34px 24px',
      }}
    >
      <div>
        <Typography
          type={downSm ? 'text_small' : 'text_medium'}
          color="grayscale"
          colorWeight={downSm ? '800' : '700'}
        >
          Defina as cores dessa proposta
        </Typography>
        <Typography
          type="text_x_small"
          color="grayscale"
          colorWeight={downSm ? '600' : '500'}
        >
          Deixe as cores configuradas para uma proposta mais interessante
        </Typography>
      </div>
      <Spacer size="24" direction="horizontal" />
      <Grid
        container
        spacing={1}
        style={{
          width: isFromDragAndDrop && '100%',
          margin: isFromDragAndDrop && '0px',
        }}
      >
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: fundoColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Fundo
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-fundo"
                  ref={fundoColorRef}
                  value={fundoColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setFundoColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (fundoColorRef.current) {
                      fundoColorRef.current.focus()
                      fundoColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (fundoColorRef.current) {
                    fundoColorRef.current.focus()
                    fundoColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-fundo1"
                  ref={fundoColorRef}
                  value={fundoColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setFundoColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: primaryColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Cor primária (destaque)
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-primary"
                  ref={primaryColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={primaryColor}
                  onChange={(e) => {
                    setPrimaryColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (primaryColorRef.current) {
                      primaryColorRef.current.focus()
                      primaryColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (primaryColorRef.current) {
                    primaryColorRef.current.focus()
                    primaryColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-primary1"
                  ref={primaryColorRef}
                  value={primaryColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setPrimaryColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>

        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: fundoContentColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Cor de fundo conteúdos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-fundo"
                  ref={fundoContentColorRef}
                  value={fundoContentColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setFundoContentColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (fundoContentColorRef.current) {
                      fundoContentColorRef.current.focus()
                      fundoContentColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (fundoContentColorRef.current) {
                    fundoContentColorRef.current.focus()
                    fundoContentColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-fundo1"
                  ref={fundoContentColorRef}
                  value={fundoContentColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setFundoContentColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>

        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: secondaryColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Cor secundária
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-secondary"
                  ref={secondaryColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={secondaryColor}
                  onChange={(e) => {
                    setSecondaryColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (secondaryColorRef.current) {
                      secondaryColorRef.current.focus()
                      secondaryColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (secondaryColorRef.current) {
                    secondaryColorRef.current.focus()
                    secondaryColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-secondary1"
                  ref={secondaryColorRef}
                  value={secondaryColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setSecondaryColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: textColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Textos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-text"
                  ref={textColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={textColor}
                  onChange={(e) => {
                    setTextColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (textColorRef.current) {
                      textColorRef.current.focus()
                      textColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (textColorRef.current) {
                    textColorRef.current.focus()
                    textColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-text1"
                  ref={textColorRef}
                  value={textColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setTextColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: graphicPrimaryColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Cor primária gráficos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-graph-primary"
                  ref={graphicPrimaryColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={graphicPrimaryColor}
                  onChange={(e) => {
                    setGraphicPrimaryColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (graphicPrimaryColorRef.current) {
                      graphicPrimaryColorRef.current.focus()
                      graphicPrimaryColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (graphicPrimaryColorRef.current) {
                    graphicPrimaryColorRef.current.focus()
                    graphicPrimaryColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-graph-primary1"
                  ref={graphicPrimaryColorRef}
                  value={graphicPrimaryColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setGraphicPrimaryColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>

        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: graphicSecondaryColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Cor secundária gráficos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-graph-secondary"
                  ref={graphicSecondaryColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={graphicSecondaryColor}
                  onChange={(e) => {
                    setGraphicSecondaryColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (graphicSecondaryColorRef.current) {
                      graphicSecondaryColorRef.current.focus()
                      graphicSecondaryColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (graphicSecondaryColorRef.current) {
                    graphicSecondaryColorRef.current.focus()
                    graphicSecondaryColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-graph-secondary1"
                  ref={graphicSecondaryColorRef}
                  value={graphicSecondaryColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setGraphicSecondaryColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: positiveColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Valores positivos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-positive"
                  ref={positiveColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={positiveColor}
                  onChange={(e) => {
                    setPositiveColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (positiveColorRef.current) {
                      positiveColorRef.current.focus()
                      positiveColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (positiveColorRef.current) {
                    positiveColorRef.current.focus()
                    positiveColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-positive1"
                  ref={positiveColorRef}
                  value={positiveColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setPositiveColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
        </Grid>
        <Grid
          className={classes.item}
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          md={12}
          sm={12}
          xs={12}
        >
          <Paper
            style={{
              display: 'flex',
              width: '100%',
              padding: isFromDragAndDrop
                ? '0px'
                : downSm
                ? '8px 16px'
                : '12px 32px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '8px',
                  width: '48px',
                  minWidth: '48px',
                  height: '48px',
                  backgroundColor: negativeColor,
                  border: `1px solid ${palette.grayscale[300]}`,
                }}
              />
              <Spacer size={downSm ? '16' : '24'} direction="vertical" />
              <Typography
                type={downSm ? 'text_small' : 'text_medium'}
                color="grayscale"
                colorWeight="600"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Valores negativos
              </Typography>
            </div>
            {downSm || isFromDragAndDrop || isFromProposalList ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="color"
                  style={{ opacity: 0, width: '0px', height: '0px' }}
                  id="raised-button-color-negative"
                  ref={negativeColorRef}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  value={negativeColor}
                  onChange={(e) => {
                    setNegativeColor(e.target.value)
                  }}
                />
                <EditIcon
                  onClick={() => {
                    if (negativeColorRef.current) {
                      negativeColorRef.current.focus()
                      negativeColorRef.current.click()
                    }
                  }}
                  width={isFromDragAndDrop ? '20' : '16'}
                  color={palette.grayscale[700]}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <Button
                noMargin
                size="small"
                variant="outlined"
                onClick={() => {
                  if (negativeColorRef.current) {
                    negativeColorRef.current.focus()
                    negativeColorRef.current.click()
                  }
                }}
              >
                Editar
                <input
                  type="color"
                  style={{ width: '0px', height: '0px', opacity: 0 }}
                  id="raised-button-color-negative1"
                  ref={negativeColorRef}
                  value={negativeColor}
                  onBlur={() =>
                    handleUpdateColors(
                      fundoColor,
                      primaryColor,
                      secondaryColor,
                      textColor,
                      graphicPrimaryColor,
                      graphicSecondaryColor,
                      positiveColor,
                      negativeColor,
                      fundoContentColor,
                    )
                  }
                  onChange={(e) => {
                    setNegativeColor(e.target.value)
                  }}
                />
              </Button>
            )}
          </Paper>
          <Spacer size="24" direction="horizontal" />
        </Grid>
      </Grid>
    </div>
  )
}

export default ColorsEdit
