import API from 'api'
import { GET_ORDERS, GET_ORDERS_STATUS } from '../constants/endpointKeys'
import {
  type IOrderStatusResponse,
  type IGetOrderListParams,
  type IPedidosResponse,
} from './types'

export const ordersService = {
  get: async (params: IGetOrderListParams) => {
    return await API.get<IPedidosResponse>(`${GET_ORDERS}${params.search}`, {
      params: {
        page: params.page,
      },
    }).then(({ data }) => {
      return data.pedidos
    })
  },
  getOrderStatus: async () => {
    return await API.get<IOrderStatusResponse>(GET_ORDERS_STATUS).then(
      ({ data }) => data,
    )
  },
}
