import { makeStyles } from '@material-ui/core/styles'
import { effects } from '@77sol/styles'

const useStyles = makeStyles(({ palette }) => ({
  rootDisabled: {
    cursor: 'pointer',
    height: '64px',
    fontSize: '16px',
    borderRadius: '8px',
    backgroundColor: palette.grayscale[200],
    boxShadow: effects.boxShadow.small,
    '&:hover': {
      backgroundColor: palette.primary[50],
    },
    '& > *:first-child': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& > *:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '& > td': {
      padding: '8px 16px',
    },
  },
  root: ({ hightlight }) => ({
    cursor: 'pointer',
    height: '64px',
    fontSize: '16px',
    borderRadius: '8px',
    backgroundColor: hightlight ? palette.primary[100] : palette.white,
    boxShadow: effects.boxShadow.small,
    '&:hover': {
      backgroundColor: palette.primary[50],
    },
    '& > *:first-child': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& > *:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '& > td': {
      padding: '8px 16px',
    },
  }),
  outlinedTable: {
    border: '3px solid greem',
  },
  selected: ({ rowOutlined, hightlight }) => ({
    backgroundColor: `${palette.primary[300]} !important`,
    color: 'white',
    ...(rowOutlined && {
      backgroundColor: hightlight ? `${palette.primary[200]} !important` : `${palette.white} !important`,
      boxShadow: `inset 0px 0px 2px ${palette.primary[300]} !important`,
    }),
  }),
  errorTable: {
    background: `${palette.red[100]} !important`,
  },
  tableCell: ({ isRowSelected, rowOutlined, headerSticky }) => ({
    color: !rowOutlined && isRowSelected ? palette.white : palette.black,
    border: 'none',
  }),
  lastItem: {
    paddingRight: '0px !important',
  },
  firstItem: {
    paddingRight: '24px !important',
  },
  arrowCell: {
    border: 'none',
    paddingLeft: '0px !important',
    '& > svg': {
      paddingTop: '4px',
      fill: palette.white,
    },
  },
}))

export default useStyles
