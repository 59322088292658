import { getFinancingDetails } from '../services/api'
import { useQuery } from '@tanstack/react-query'
import { GET_FINANCING_DETAILS } from '../constants/enpointKeys'

export const useGetFinancingSimulationDetails = (
  financingId: number,
  { onSuccess }: { onSuccess?: (data: unknown) => void },
) => {
  const response = useQuery({
    queryKey: [GET_FINANCING_DETAILS, financingId],
    queryFn: async () => {
      const response = await getFinancingDetails.get(financingId)
      onSuccess?.(response)
      return response
    },
    enabled: Boolean(financingId),
  })

  return { ...response }
}
