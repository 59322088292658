import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import { type Key } from 'react'
import { useRenderRoutes } from './hooks/useRenderRoutes'
import { Switch } from 'react-router-dom'

export function RenderRoutes() {
  const { routes } = useRenderRoutes()

  return (
    <Switch>
      {routes.map((route) => (
        <RouteWithSubRoutes key={route.path as Key} {...route} />
      ))}
    </Switch>
  )
}
