import React from 'react'
import { Badge, Button } from '@77sol/core'
import { Box, Container, useMediaQuery } from '@material-ui/core'
import Shield77SolTeam from 'assets/img/shield77SolTeam.png'
import { useDispatch } from 'react-redux'
import { setProModalOpen } from 'store/actions/proModal'
import { useStyles } from './styles'

export function Pro77SolTeam({ children, badge, setCurrentModalClose }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleShowProModal = () => {
    dispatch(setProModalOpen())
    setCurrentModalClose(false)
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <div className={classes.row}>
        <Box className={classes.containerImg}>
          <img src={Shield77SolTeam} alt="Seleção 77Sol" />
        </Box>
        <Box className={classes.context}>
          {badge && (
            <div className={classes.badge}>
              <Badge color="tertiary" label="Oferta limitada" />
            </div>
          )}
          <div>{children}</div>
        </Box>
      </div>

      <Button onClick={() => handleShowProModal()} fullWidth={downSm}>
        Entender 77PRO
      </Button>
    </Container>
  )
}
