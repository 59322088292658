export enum TAB_ENUM {
  CHOOSE_KIT = 'choose-kit',
  CUSTOMIZE_KIT = 'customize-kit',
  COMPARE_KIT = 'compare-kit',
}

export enum TAB_ENUM_AMPLITUDE {
  'choose-kit' = 'Escolha seus Kits',
  'customize-kit' = 'Personalize seus Kits',
  'compare-kit' = 'Compare seus Kits',
}
