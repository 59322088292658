import { forwardRef, type InputHTMLAttributes } from 'react'
import { DropdownControlled } from 'components/Controllers/Dropdown'
import { useFormContext } from 'react-hook-form'
import { type paymentType } from './SelectPayment.types'
import {
  ALL_OPTIONS,
  CONSORCIO_OPTIONS,
  FINANCIAMENTO_77SOL_OPTIONS,
  FINANCIAMENTO_CLIENTE_OPTIONS,
  PAYMENT_OPTIONS,
} from './constants/paytmentOptions'

interface SelectPaymentProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  error?: string
  fullWidth?: boolean
}

const SelectPayment = forwardRef<HTMLElement, SelectPaymentProps>(
  ({ label, name, error, disabled, ...props }, ref) => {
    const {
      getValues,
      setValue,
      control,
      formState: { errors },
    } = useFormContext()

    const financialInstitutionOptions = {
      consorcio: CONSORCIO_OPTIONS,
      financiamento_77sol: FINANCIAMENTO_77SOL_OPTIONS,
      financiamento_cliente: FINANCIAMENTO_CLIENTE_OPTIONS,
    }

    const paymentType: paymentType = getValues('payment_type')
    const financialInstitution: paymentType = getValues('financial_institution')

    const findOption = ALL_OPTIONS.find(
      (option) => option.value === paymentType,
    )

    if (findOption) {
      setValue('financial_institution', findOption.value)

      if (CONSORCIO_OPTIONS.includes(findOption)) {
        setValue('payment_type', 'consorcio')
      } else if (FINANCIAMENTO_77SOL_OPTIONS.includes(findOption)) {
        setValue('payment_type', 'financiamento_77sol')
      } else if (FINANCIAMENTO_CLIENTE_OPTIONS.includes(findOption)) {
        setValue('payment_type', 'financiamento_cliente')
      }
    }

    return (
      <>
        <DropdownControlled
          control={control}
          label={label}
          value={paymentType}
          name={name}
          error={error}
          onChange={() => {
            setValue('financial_institution', '')
          }}
          options={PAYMENT_OPTIONS}
          disabled={disabled}
          fullWidth
          {...props}
          ref={ref}
        />

        {financialInstitutionOptions?.[paymentType] && (
          <DropdownControlled
            control={control}
            label={'Instituição financeira'}
            name="financial_institution"
            value={financialInstitution || ''}
            error={errors?.financial_institution?.message as string}
            options={financialInstitutionOptions[paymentType]}
            disabled={disabled}
            fullWidth
            {...props}
          />
        )}
      </>
    )
  },
)

export default SelectPayment
