import styled from 'styled-components'

export const LayoutRoot = styled.div`
  height: 100%;
  display: flex;
`

export const LayoutWrapper = styled.main`
  height: 100dvh;
  flex: 1;
  min-width: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const LayoutContent = styled.div`
  flex: 1;
  min-height: 0;
`
