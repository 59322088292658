import * as yup from 'yup'
import { validateCPF } from './cpf'
import { validateCNPJ } from './cnpj'
import { validateEmail } from './email'

yup.addMethod<yup.StringSchema>(yup.string, 'cpf', function (message: string) {
  return this.test({
    name: 'cpf-validator',
    message,
    test: (value) => validateCPF(String(value)),
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'cnpj', function (message: string) {
  return this.test({
    name: 'cnpj-validator',
    message,
    test: (value) => validateCNPJ(String(value)),
  })
})

yup.addMethod<yup.StringSchema>(
  yup.string,
  'emailDeepValidate',
  function (message: string) {
    return this.test({
      name: 'emailDeepValidate-validator',
      message,
      test: (value) => validateEmail(String(value)),
    })
  },
)

yup.addMethod<yup.StringSchema>(yup.string, 'removeEmptyString', function () {
  return this.transform((value, originalValue) =>
    originalValue === '' ? undefined : value,
  )
})

export default yup
