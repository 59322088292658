import { type IGetFinancingRequest } from '../../dashboard-financing-requests/services/types'
import { type IGetProposalsRequest } from '../../dashboard-proposals/services/types'
import { useGetFinancingRequestsData } from 'domains/home/dashboard-financing-requests/hooks/useGetFinancingRequestsData'
import { useGetProposalsData } from 'domains/home/dashboard-proposals/hooks/useGetProposalsData'

interface IUseGetSustainabilityDataProps {
  enabled?: boolean
  financingParams: IGetFinancingRequest['params']
  proposalParams: IGetProposalsRequest['params']
}

export function useGetFinancingsAndProposalsData({
  enabled,
  financingParams,
  proposalParams,
}: IUseGetSustainabilityDataProps) {
  const financingQuery = useGetFinancingRequestsData({
    params: financingParams,
    enabled,
  })

  const proposalQuery = useGetProposalsData({
    params: proposalParams,
    enabled,
  })

  const isLoading = proposalQuery.isLoading || financingQuery.isLoading

  return { isLoading, financingQuery, proposalQuery }
}
