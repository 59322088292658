import { type ReactNode } from 'react'
import { createPortal } from 'react-dom'

interface PortalProps {
  children: ReactNode
  id: string
}

export function Portal({ children, id }: PortalProps) {
  return createPortal(children, document.getElementById(id) || document.body)
}
