import { SearchIcon } from '@77sol/icons/dist'
import { palette } from '@77sol/styles'
import { Button } from '@77sol/core'
import { DatePickerControlled } from 'components/Controllers/DatePicker'
import { TextField } from 'components/Controllers/TextField'
import * as S from './styles'
import { useFinancingFilters } from './hooks/useFinancingFilters'
import { FinancingStatusDropdown } from './components/FinancingStatusDropdown'

interface FiltersFormProps {
  onFilter?: () => void
  isPerValue: boolean
}

export const FiltersForm: React.FC<FiltersFormProps> = ({
  onFilter,
  isPerValue,
}) => {
  const {
    filters,
    handleSubmit,
    control,
    handleClearFilters,
    handleApplyFilters,
  } = useFinancingFilters({ onFilter, isPerValue })

  const placeholder = isPerValue
    ? 'Pesquise por ID, Solicitante, Valor ou CPF/CNPJ'
    : 'Pesquise por ID, Solicitante, Potência (kWp) ou CPF/CNPJ'

  return (
    <S.FormWrapper onSubmit={handleSubmit(handleApplyFilters)}>
      <TextField
        control={control}
        placeholder={placeholder}
        name="search"
        startAdornment={<SearchIcon color={palette.grayscale[500]} />}
      />

      <FinancingStatusDropdown value={filters.status} control={control} />

      <DatePickerControlled
        control={control}
        name="date_initial"
        title="Data de início"
      />
      <S.ButtonContainer>
        <Button size="small" type="submit">
          Filtrar
        </Button>
        <Button
          variant="text"
          size="small"
          color="red"
          onClick={handleClearFilters}
        >
          Limpar filtros
        </Button>
      </S.ButtonContainer>
    </S.FormWrapper>
  )
}
