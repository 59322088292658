export default status => {
  const labels = {
    'Pré aprovado': {
      ellipseColor: 'green',
      textLabel: 'Pré aprovado',
    },
    'Aprovado': {
      ellipseColor: 'green',
      textLabel: 'Aprovado',
    },
    'Negado': {
      ellipseColor: 'red',
      textLabel: 'Negado',
    },
    'Não solicitado': {
      ellipseColor: 'grayscale',
      textLabel: 'Não solicitado',
    },
    'Solicitado': {
      ellipseColor: 'orange',
      textLabel: 'Solicitado',
    },
    'Cancelado': {
      ellipseColor: 'red',
      textLabel: 'Cancelado',
    },
  }

  return labels[status] || labels['Não solicitado']
}
